[data-theme-version="dark"]{
	.card {
        background-color: $dark-card;
		box-shadow:none;
		border-color:$d-border;
    }
	.dropdown-menu {
        background-color: $dark-card;
		box-shadow: 0rem 0rem 0rem 0.0625rem rgba(255, 255, 255,0.1);
		.dropdown-item {
			color:#777777;
			&.selected,
			&.selected.active,
			&.active,
			&:hover, 
			&:focus {
				background-color: $dark-card;
				color: $white;
			}
		}
    }
	a{
		color:$white!important;
	}
	.btn-link g [fill]{
		fill: #fff;
	}
	.btn-light:active, 
	.btn-light:focus, 
	.btn-light:hover{
		color:#000;
	}
	.form-control {
        background-color: transparent;
        border-color: $d-border;
        color: $white;
    }
	.form-select {
        background-color:$d-bg;
        border-color: $d-border;
        color: $white;
    }
	
	// .deafaut-check .form-check-input{
		// background:$d-bg;
		// &:checked{
			// background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
			// background-color:var(--primary);
			// border-color:$white;
		// }
	// }
	.toggle-switch.form-check .form-check-input{
		background:$d-bg;
		&:checked{
			background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
			background:var(--rgba-primary-1);
		}
	}
	.modal-content{
		background:$dark-card;
	}
	.modal-footer,
	.modal-header{
		border-color: $d-border;
	}
	.close{
	    font-weight: 400;
		color: $white;
		text-shadow: none;
		
		&:hover{
			color: $white;
		}
	}
	.star-rating .product-review,
	.new-arrival-content .item{
		color: $white;
	}
	.custom-dropdown .dropdown-menu{
		border-color: $d-border;
	}
	.widget-stat .media > span{
	    background: $d-border;
	    border-color: $d-border;
		color:$white;
	}
	.plus-minus-input .custom-btn{
		background: $d-bg;
	    border-color: $d-border;
	}
	.dropdown-divider,
	.size-filter ul li{
		border-color: $d-border;
	}
	.custom-select{
	    border-color: $d-border;
		color: $d-ctl;
		background:$d-bg;
	}
	.nav-tabs{
		border-color: var(--primary);
		.nav-link {
		color:$white;
		}
	}
	.mail-list .list-group-item.active i {
		color: $white;
	}
	hr{
		border-color: $d-border;
	}
	.grid-col{
		background:$d-bg;
	}
	
	.noUi-target{
		border-color:$d-border;
		border-radius: 0.5rem;
		box-shadow: none;
		
		.noUi-connects{
			background:$d-border;
		}
	}
	.noUi-marker-large,
	.noUi-marker{
		background:$d-border;
	}
	.input-group-text{
		background: transparent;
		color:$white;
		border-color:$d-border;
	}
	.note-editor.note-frame{
		border-color:$d-border;
		.btn{
			color:$white;
		}
		.note-editing-area .note-editable{
			color:$white;
		}
	}
	.widget-media .timeline .timeline-panel{
		border-color:$d-border; 
	}
	.notification_dropdown .dropdown-menu-right .all-notification{
		border-color:$d-border; 
	}
	#user-activity .nav-tabs .nav-link{
		border-color:$d-border; 
        &.active{
            background:$d-bg;
            color:$white;
        }
	}
	.list-group-item-action{
		color:$body-color;
	}
	.list-group-item-action:focus, 
	.list-group-item-action:hover, 
	.list-group-item-action:focus{
		background-color:$d-bg; 
		border-color:$d-bg; 
	}
	.list-group-item.active{
		color:black!important;
		border-color:var(--primary);
		&:focus, 
		&:hover, 
		&:focus{
			background-color:var(--primary); 
			border-color:var(--primary); 
			color:$white;
		}
	}
	.swal2-popup{
		background:$dark-card;
	}	
	.form-head .btn-outline-primary{
		border-color:$d-border;
	}
	.form-head .btn-outline-primary:hover{
		border-color:var(--primary);
	}
	.review-tab.nav-pills li a.nav-link.active{
		background:transparent;
	}
	.new-arrival-content {
		 h4 {
			a{
				color:$white;
			}
		 }
	}
	.text-black{
		color:$white;
	}
	.abilities-chart .ct-chart .ct-label{
		fill:$white;
	}
	.morris_chart_height text tspan{
		fill:$white;
	}
	.btn-link{
		color:$white;
	}
	.order-bg{
		background:$d-bg;
	}
	.detault-daterange{
			background:$d-bg;
			color:$white;
		.input-group-text{
			background:$dark-card;
			border:0;

		}	
	}
	.dataTablesCard{
		background-color:$dark-card;
	}
	.compose-content .dropzone{
		background:$d-bg!important;
		.dz-message .dz-button{
			color:$white;
		}
	}
	.daterangepicker{
		background:$d-bg;
		border-color:var(--primary);
		.calendar-table{
			border-color:var(--primary);
			background:$d-bg;
			.table-condensed{
				td{
					&:hover{
						background-color:var(--primary);
						color:$white;
					}
				}
			}
		}
        &:after{
            border-bottom: 0.375rem solid $d-bg;
        }
	}
    
    .daterangepicker select.hourselect
    , .daterangepicker select.minuteselect
    , .daterangepicker select.secondselect
    , .daterangepicker select.ampmselect{
            background: $d-bg;
            border: 0.0625rem solid $d-border;
            color:$white;
    }
    
	.daterangepicker td.off, 
	.daterangepicker td.off.in-range,
	.daterangepicker td.off.start-date, 
	.daterangepicker td.off.end-date{
		background-color:$dark-card;
		&:hover{
			background-color:var(--primary);
			color:$white;			
		}
	}
	.app-fullcalendar{
		.fc-button{
			background-color:$d-bg;
			border-color:var(--primary);
			color:$white;
			text-shadow:none;
			&:hover,&.fc-stat-hover{
				background-color:var(--primary);
			}
		}
	}
	
	.dd-handle{
		border-color:$d-border;
		background-color:$d-bg;
	}
	.menu-toggle .deznav .metismenu li > ul{
		background:$dark-card;
	}
	.header-right .notification_dropdown .nav-link{
		border-color:$d-border;
	}
	// .nav-tabs .nav-link{
		// &:hover,&.active{
			// border-color:var(--primary);
			// background: var(--primary);
			// color: white!important;
			// i{
			// color: white!important;
			// }
		// }
	// }
	.clockpicker-popover .popover-content{
		background-color:$dark-card;
	}
	.clockpicker-plate{
		background-color:$d-bg;
	}
	.clockpicker-popover .popover-title{
		background-color:$d-bg;
		color:$white;
	}
	.form-wizard .nav-wizard li .nav-link span{
		background-color:$d-bg;
	}
	.form-wizard .nav-wizard li .nav-link:after{
		background:$d-bg;
	}
	.form-wizard .nav-wizard li .nav-link.active:after,.form-wizard .nav-wizard li .nav-link.done:after{
		background:var(--primary);
	}
	.form-wizard .nav-wizard li .nav-link.active span
	,.form-wizard .nav-wizard li .nav-link.done span{
		background:var(--primary-light);
	}
	.check-switch .custom-control-label:after
    , .check-switch .custom-control-label:before{
        border-color:var(--primary);
    }
    .fc-unthemed .fc-today{
        background:$d-bg;
    }
    .fc-unthemed .fc-divider, .fc-unthemed .fc-list-heading td, .fc-unthemed .fc-popover .fc-header{
        background: $d-border;
    }
    .picker__box{
        background:$d-bg;
        .picker__button--clear
        , .picker__button--close
        , .picker__button--today{
            background:$dark-card;
            color:$white;
            &:hover{
                &:before{
                    color:$white;
                }
            }
        }
    }
    .picker{
        color:#999;
    }
    .dtp > .dtp-content{
        background:$d-bg;
    }
    .dtp table.dtp-picker-days tr > td > a{
       color: #68686a; 
       &.selected{
           color:$white;
       }
    }
	.order-request tbody tr{
		border-color:$d-border;
	}
	.card-list li{
		color:$white;
	}
	.card-bx .change-btn:hover{
		color:var(--primary);
	}
	.invoice-card{
		&.bg-warning{
			background-color:#5b3c1f!important;
		}
		&.bg-success{
			background-color:#2a6729!important;
		}
		&.bg-info{
			background-color:#4c276a!important;
		}
		&.bg-secondary{
			background-color:#1c3e52!important;
		}
	}
	.user-list li{
		border-color:$dark-card;
	}
	.toggle-switch{
		color:$white;
	}
	.bar-chart{
		.apexcharts-text tspan{
			fill:#969ba0;
		}
		line{
			stroke:$d-border;
		}
	}
	.accordion.style-1 .accordion-item,.invoice-list{
		border-color:$d-border;
	}
	.accordion.style-1 .accordion-header.collapsed {
		.user-info a,.user-info,&>span{
			color:$white;
		}
	}
	.ic-card {
		&>a{
			background:#25479f;
			&:first-child{
				border-color:#25479f;
			}
		}
		span{
			color:$white;
		}
	}
	table.dataTable thead th, table.dataTable thead td{
		border-color:$d-border!important;
	}
	.paging_simple_numbers.dataTables_paginate{
		background:$dark-card;
	}
	.dataTables_info{
		color:$white;	
	}
	.dataTables_wrapper .dataTables_paginate span .paginate_button.current,
	.dataTables_wrapper .dataTables_paginate span .paginate_button:hover{
		background:#000!important;
		color:$white!important;
	}
	.dashboard-select{
		background:var(--rgba-primary-1);
		color: white;
		border-color:$d-border;
	}
	.dashboard-select .list{
		background:$dark-card;
		
	}
	.dashboard-select .option:hover, .dashboard-select .option.focus, .dashboard-select .option.selected.focus{
		background:$d-bg;
	}
	.card-tabs.style-1 .nav-tabs{
		background:$dark-card;
	}
	.transaction-details{
		border-color:$d-border;
	}
	.description{
		color:$white;
	}
	.transaction-details .amount-bx{
		background:#3f250d;
		i{
			background:#8d3b0c;			
		}
	}
	.nice-select .option{
		background:$dark-card;
	}
	.nice-select .option:hover, .nice-select .option.focus, .nice-select .option.selected.focus{
		background:$dark-card;
	}
	.card-tabs.style-1{
		border-color:$d-border;
	}
	
	.nice-select .list{
		background:$d-bg;
		// border:1px solid;
		border-color:$d-border;
	}
	.table-search .input-group .input-group-text{
		background:$dark-card;
	}
	.dark-btn{
		svg{
			path{
				stroke: #fff;
			}
				
		}	
	}
	.bar-chart{
		.apexcharts-legend-text{
			color:$white!important;	
		}	
	}
	.Sales{
		tspan{
			fill:$white;	
		}	
	}
	.timeline-chart{
		rect{
			fill:$d-bg;	
		}	
	}
	.header-info{
		span{
			color:$white!important;	
		}	
	}
	.smallipop-instance.blue{
		color:#fff;
	}
	.property-map .smallipop.smallimap-mapicon .circle-marker{
		border-color:$d-border;
	}
	.property-features li{
		color:$white;	
	}
	.review-tabs-1{
		background:$dark-card;	
	}
	.coin-tabs{
		background:#2E2E40;
		.nav-link{
			&.active{
				background:var(--primary)!important;
				color:$white;
			}	
		}
	}
	.separate-row > div{
		border-color:$d-border;	
	}
	.bg-white{
		background:$d-bg!important;	
	}
	.deznav .header-info2 span{
		color:$white;	
	}
	.brand-title{
		path{
			fill:$white;	
		}
		tspan{
			fill:$white;		
		}
	}
	.shapreter-row > div{
		border-color:$d-border;		
	}
	.chartBar{
		line{
			stroke:transparent;	
		}	
	}
	.search-job .search-dropdown:after{
		background:$d-border;	
	}
	.chartjs{
		line{
			stroke:$d-border;	
		}	
	}
	.form-label{
		color:$white!important;	
	}
	.datepicker-switch{
		color:$white;	
	}
	.datepicker.datepicker-dropdown td.day, .datepicker.datepicker-dropdown th.next, .datepicker.datepicker-dropdown th.prev{
		color:$white;	
	}
	.datepicker.datepicker-dropdown th.dow{
		color:$white;	
	}
	.datepicker.datepicker-dropdown th.datepicker-switch, .datepicker.datepicker-dropdown th.next, .datepicker.datepicker-dropdown th.prev{
		color:$white;	
	}
	.btn-close{
		color:$white!important;	
		background:transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23099'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
		
	}
	.btn-close{
		i{
			display:block;
			transform: scale(1.5);
		}
	}
	.bgl-secondary{
		color:$white;	
	}
	.application tr td .btn{
		color:$white;	
	}
	.btn-link{
		svg{
			path{
				stroke:$white;	
			}	
		}	
	}
	.alert.alert-outline-secondary,
	.alert.alert-outline-dark{
		color:$white;	
	}
	.form-control-plaintext{
		color:$white;	
	}
	.timeline-panel{
		.media-body{
			small{
				color:$white;	
			}	
		}	
	}
	.jobs{
		border-color:$d-border;	
	}
	///max-skills
	.apexcharts-gridlines-vertical{
		line{
			stroke:$d-border;
		}	
	}
	.apexcharts-text tspan{
		fill:$white;
	}

	.widget-courses .schedule-icon{
		color: $white;
	}
	.card-schedule .up-comming-schedule,
	.upcomming-task-widget{
		background:$dark-card;
	}
	.card-schedule .up-comming-schedule.style-1{
		background:$d-bg;
		.date-box{
			background:$dark-card;
			color:#fff;
		}
	}
	.instructors-box .instructors-media .custome-badge .badge{
		color:$white;
	}
	.instructors-box .instructors-media .info-box{
		background:$d-bg;
	}
	.course-details-tab .nav-tabs .nav-link{
		color: #cbc3c3;
	}
	.message-box,
	.chat-icon,
	.chat-people,
	.chat-tabs .chat-bx,
	.share-files,
	.type-massage,
	.work-ic{
		border-color:$d-border;
	}	
	.timeline-active .modulel{
		background:$dark-card;
	}
	.timeline-active li .time{
		background: $dark-card;
	}	
	.achievements .achievements-content span,
	.bio .bio-content{
		background:$d-bg;
	}
	.score-active.style-1 .dashboard-select{
		background:$d-bg;
	}
	#activity{
		line{
			stroke:$d-border;
		}
	}
	.pagination-down ul li a{
		background:$d-bg;
		&.active{
			background:var(--primary);
		}
	}
	.custome-accordion .accordion-button{
		background:$dark-card;
	}
	.custome-accordion .acc-courses{
		background:$d-bg;
	}
	.widget-timeline .side-border h4, .widget-timeline .side-border .h4,
	.widget-timeline .side-border .custom-dropdown{
		background:$dark-card;
	}
	.widget-timeline .side-border:after{
		border-color:$d-border;
	}
	#columnChart{
		line{
			stroke:transparent;
		}
	}
	#sellingActivity{
		svg{
			path{
				stroke:$dark-card;
			}
		}
		line{
			stroke:transparent;
		}
	}
	.review-box{
		background:$dark-card;
	}
	#NewCustomers{
		line{
			stroke:transparent;
		}
	}
	.students-list .search-area .form-control,
	.students-list .search-area .input-group-text{
		background:$d-bg;
		border:$d-border;
	}
	.custome-accordion.style-1 .acc-courses{
		background:$dark-card;
		border-color:$d-border;
	}
	.miac,
	.profile-img img, .profile-img span,
	.compose-content .dropzone,
	.fc-theme-standard td, .fc-theme-standard th,
	.fc-theme-standard .fc-scrollgrid.fc-scrollgrid-liquid, .fc-scrollgrid, table{
		border-color:$d-border;
	}
	.btn-icon-end{
		border-color:$d-border;
	}
	.raiting li{
		border-color:$d-border;
	}
	.miac{
		svg{
			path{
				fill:$white;
			}
		}
	}
	.type-massage.style-1{
		background:$d-bg;
		.input-group{
			background:$d-bg;
		}
	}
	.header .header-content:after{
		background:$d-border;
	}
	.search-coundry{
		.dashboard-select{
			color:#fff;
			background:transparent;
			.option{
				color:$white;
			}
			&:after {
				border-bottom: 0.125rem solid #fff;
				border-right: 0.125rem solid #fff;
			}
		}
		
	}
	.sidebar-right{
		.nice-select .option.selected{
			color:#000;
		}
	}
	#redial{
		.apexcharts-datalabels-group{
			text{
				fill:$white;
			}
		}
	}
	.notification_dropdown .dropdown-menu-end .all-notification{
		border-color:$d-border;
	}
	.app-fullcalendar1{
		.fc .fc-button-group > .fc-button{
			border-color:$d-border;
		}
		.fc-direction-ltr .fc-daygrid-event.fc-event-end, .fc-direction-rtl .fc-daygrid-event.fc-event-start{
			background:$dark-card!important;
			border-color:$d-border;
		}
		
	}
	.card-calendar.style-1 .bootstrap-datetimepicker-widget .table-condensed td.day{
		color: #ffffff !important;
	}
	.dz-side-menu{
		background:$dark-card;
	}
	.nice-select .option.selected{
		color:#fff;
	}
	.dz-cource,
	.message-box,
	.type-massage{
		background-color:$dark-card;
		.form-control{
			background-color:transparent;
		}
	}
	.card-calendar.style-1 .bootstrap-datetimepicker-widget table .picker-switch {
		color: #fff;
	}
	.fc .fc-button-group > .fc-button{
		border-color:$d-border;
	}
	.fc-daygrid-dot-event{
		background-color:$d-bg;
		border-color:$d-bg;
	}
	#redial{
		.apexcharts-radialbar-hollow{
			fill:$dark-card;
		}
	}
	.chat-tabs .chat-bx.active:after{
		background-color:$d-bg;
	}
	.bootstrap-datetimepicker-widget table td.day:hover, .bootstrap-datetimepicker-widget table td.hour:hover, .bootstrap-datetimepicker-widget table td.minute:hover, .bootstrap-datetimepicker-widget table td.second:hover{
		background:$d-bg;
	}
	.chat-box-area .message-received p,
	.message-send,
	.type-massage .form-control{
		color:#fff;
		background-color:#171717;
	}
	.chat-icon ul li a,
	.file{
		background-color:rgba(255,255,255,.1);
		svg path{
			fill:#fff;
		}
	}
	.course-dedails-bx .description .user-pic span,
	.course-learn ul li{
		color:#fff;
	}
	.course-details-tab .user-pic2 p{
		color:#828690;
	}
	#columnChart{
		.apexcharts-bar-series{
			path{
				stroke:$dark-card;
			}
		}
	}
	.custome-donut{
		tspan{
			fill:$white;
		}
	}
	#morris_donught.custome-donut svg path{
		stroke-width: 0px;
	}
	.card{
		&.bg-pink{
			background-color:#EB62D0;
		}
	}
	#coin-ponent{
		> div{
			background-color:$d-bg!important;
			color:$white!important;
			border-color:$d-border!important;
			div{
				border-color:$d-border!important;
				background-color:$d-bg;
			}
		}
		div{
			border-color:$d-border!important;
			background-color:$d-bg;
			div{
				border-color:$d-border!important;
				background-color:$d-bg;
			}
		}
		.cp-select-menu-item{
			border-color:$d-border!important;
			background-color:$d-bg;
			&:hover{
				border-color:$d-border!important;
				background-color:$d-bg;
			}
		}
	}
	#coin-chart,
	.revenueMap{
		text{
			fill: white;
		}
		line{
			stroke: $d-border;
		}
	}
	.previews-info-list:hover{
		background-color:$d-bg;
	}
	.sell-element .sell-blance .form-control,
	.limit-sell .nav-tabs{
		background-color:$d-bg;
	}
	.text-start{
		span{
			color:$white!important;
		}
	}
	.text-secondary{
		color:$white!important;
	}
	.card-wiget .reward-earn span{
		color:$white;
	}
	.market_chart,
	#btcStock{
		line{
			stroke:$d-border;
		}
	}
	.apexcharts-datalabels-group{
		stroke:$white;
	}
	.btn{
		&.tp-btn{
			svg{
				path{
					stroke:$white;
				}
			}
		}
	}
	.coin-warpper .nav .nav-item{
		border-color:$d-border;
	}
	.form-wrapper.trade-form .input-group .input-group-text{
		color:$white;
	}
	.progress-bar{
		&.bg-secondary{
			background-color:$white!important;
		}
	} 
	.profile-card{
		border-color:$d-border;
	}
	.profile-card .card-footer,
	.author-profile .info-list li{
		border-color:$d-border;
	}
	.email-left-box{
		border-color:$d-border;
	}
	.email-left-box .mail-list .list-group-item i{
		color:$white;
	}
	.dropzone .dlab-message{
		margin: 5em 0;
	}
	.email-list{
		background:$dark-card;
	}
	.email-list .message{
		border-color:$d-border;
	}
	.email-list .message .col-mail-2 .subject{
		color:$white;
	}
	.email-list .message:hover{
		background:$d-bg;
	}
	.email-right-box .table-pagenation{
		background:$d-bg;
		border-color:$d-border;
	}
	.email-right-box .pagination{
		background:$d-bg;
	}
	.btn.tp-btn-light.btn-secondary,
	.btn.tp-btn.btn-secondary{
		color:white;
	}
	.list-group-item-secondary{
		color:$white;
	}
	.students{
		span{
			color:$white;
		}
	}
	.progress{
		.bg-white{
			background-color:$white!important;
		}
	}
	.border-end{
		border-color:$d-border!important;
	}
	.form-check-input{
		border-color:$d-border;
		background-color:$d-bg;
	}
	.menu-toggle{
		.logo-color{
			display:none!important;
		}
		@include respond ('phone-land'){
			.logo-color{
				display:block!important;
			}
		}
	}
	#overiewChart{
		line{
			stroke:$d-border;
		}
		
	}
	#NewCustomers{
		#SvgjsSvg1006{
			margin-top: 11px;
		}
	}
	.chart-grd:after{
		background:transparent;
	}
	.chat-box-area .message-sent p{
		background:#383838;
		color: white;
	}
	.page-titles,
	.to-dodroup .sub-card,
	.ttl-project,
	.active-country .country-list,
	.active-projects.selling-product tbody tr td,
	.events .event-media,
	.active-projects.style-1 tbody tr td,
	.footer,
	.active-projects.manage-client tbody tr td,
	.task .task-summary,
	.active-projects.task-table tbody td,
	.kanbanPreview-bx .card-body .sub-card{
		border-color:$d-border;
	}
	.avatar-list.avatar-list-stacked .avatar,
	.author-profile .card-footer .form-control,
	.profile-card .card-header,
	.author-profile .upload-link{
		border-color:$d-border;
	}
	.email-left-box .mail-list .list-group-item{
		background: rgba(255,255,255,0.1);
       margin: 13px 0px;
	}
	.inovice-logo .logo-abbr .react-w{
		fill:$white;
		stroke:$white;
	}
	.demo-right-inner{
		background-color:$dark-card;
	}
	.border-start{
		body-color:$d-border;
	}
	.ttl-project .pr-data:after,
	.active-country:after{
		background:$d-border;
	}
	.to-dodroup .sub-card .d-items,
	.kanbanPreview-bx .card-body .sub-card{
		background-color:$dark-card;
	}
	.to-dodroup .sub-card .d-items .d-items-2 label,
	.earning-tab .nav-item .nav-link.active{
		color:$white;
	}
	--secondary:$white;
	
	.active-projects thead tr th,
	.schedules-cal .datepicker-days .dow{
		background-color:transparent;
	}
	.bootstrap-select .btn{
		background:$dark-card!important;
	}
	.sidebar-right-inner{
		.bootstrap-select .btn{
			background:transparent!important;
			border-color:$border!important;
		}
		.dropdown-menu.show{
			background-color:$white;
		}
		.dropdown-menu .dropdown-item.active, .dropdown-menu .dropdown-item:active{
			color: var(--primary)!important;
            background: var(--rgba-primary-1);
		}
		.dropdown-menu .dropdown-item{
			color: #777777!important;
		}
		.dropdown-item:hover, .dropdown-item:focus{
			background-color: var(--bs-dropdown-link-hover-bg);
		}
	}
	.badge-outline-dark,
	.badge-outline-secondary{
		border-color:$d-border;;
	}
	.light.badge-info{
		border-color:$d-border;
	}
	.email-left-box .btn.text-white.btn-block{
		background-color:var(--primary);
	}
	.btn-secondary{
		.text-secondary{
			color:$black!important;
		}
	}
	.list-group-item{
		color:$white;
	}
	#TabWithIcon{
		.nav-link{
			&.active{
				background-color:$d-bg;
				border-color:$d-border;
				border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
			}	
		}
	}
	.ck.ck-editor .ck.ck-button{
		border-color:$d-border;
		color:$white;
		background-color:$d-bg;
	}
	.dark-logo{
		display:block;
	}
	.light-logo{
		display:none
	}
	.deznav,
	.read-content-attachment .attachment > div:not(:last-child){
		border-color:$d-border;
	}
	#AllProject{
		.apexcharts-series{
			path{
				stroke:$d-border;
			}
		}
	}
	.offcanvas{
		background-color:$d-bg;
		.btn-close{
			background: transparent;
		}
	}
	.events h6{
		border-color:$d-border;
		border: 0;
	}
	 .task-tab{
		background-color:$dark-card;
	}
	.kanbanPreview-bx .card-body{
		background-color:$d-bg;
	}
	.custom-tab-1{
		.nav{
			&.nav-tabs{
				border-color:$d-border;
			}
		}
	}
	.login-form{
		background-color:transparent;
	}
	.accordion-primary .accordion-header.collapsed{
		color:var(--primary)!important;
	}
	.accordion-item{
		background-color:$dark-card;
	}
	.mix-chart-tab{
		.nav-link{
			background:#444444;
			color:$white;
			&.active{
				background:var(--primary);
			}
		}
		
	}
	.active-map-main:after{
		background:$d-border;
	}
	.upload-img .dropzone{
		border-color:$d-border;
	}
	.upload-img .dropzone{
		.dlab-message{
			margin: 1em 0;
		}
	}
	.navbar-nav .active .scroll{
		color:var(--primary)!important;
	}
	.accordion-primary,
	.accordion-danger-solid{
		.accordion-header-text{
			color:$white;
		}
	}
	.header-left .search-area{
		background-color: transparent;
	}
	[data-headerbg="color_4"]{
		.search-area{
			background-color: transparent;
		}
	}
	.third-post.style-2,
	.c-details ul li{
		border-color:$d-border;
	}
	.dt-filter .dataTables_filter input[type="search"]{
		background:$d-bg;
	}
	.dt-filter .dataTables_filter label{
		background:$d-bg;
	}
	.card-profile {
		.admin-user {
			background-color: rgba(0,0,0,0.9);
			.img-wrraper {
				a {
					background-color: rgba(0,0,0,1);
					box-shadow: 0 0 6px 3px rgba(255, 255, 255, 0.1);
				}
			}
		}
		.follow-list {
			.follow-num {
				color: black;
			}
		}
	}
	.card-profile .user-social-links a{
		color: black!important;
	}
	.profile-accordion .accordion-item .accordion-header .accordion-button{
		color:$white;
	}
	.c-list .search-area .form-control{
		border:0;
	}
	.chat-left-area.active{
		background:$dark-card;
	}
	.inner-body{
		background-color:#202020;
		border-color:$d-border;
	}
	.header .header-content{
		background-color:$dark-card;
		border-color:$d-border;
	}
	.project-media p,
	.project-media span,
	.revenue-date span,
	.progress-box p,
	.avatar-list.avatar-list-stacked .avatar,
	.schedules-cal .datepicker-days .dow,
	.schedules-cal .datepicker-days .day{
		color:$white;
	}
	.active-projects table.dataTable tbody td{
		border-color:$d-border;
	}
	.header-right .notification_dropdown .nav-link{
		svg{
			path,circle{
				stroke:$white;
					
				
			}
		}
		&:hover{
			background-color:#000;
		}
	}
	.schedules-cal .datepicker-days .picker-switch{
		color:$white;
	}
	.schedules-cal .datepicker-days .prev i, .schedules-cal .datepicker-days .next i{
		background:var(--primary-light);
	}
	.impressionbox:before{
		background:$d-border;
	}
	.events .event-media span,
	.friend-list1 .friend-user p{
		color:$white;
	}
	.friend-list1 .friend-user:hover{
		background:#000;
	}
	--text-dark:$white;
	.apexcharts-legend-text{
		color:$white!important;
	}
	#Statistics{
		line{
			stroke:$d-border;
		}
	}
	#volumeChart,
	#ExtraData,
	#TotalEarning{
		.apexcharts-series{
			path{
				stroke: transparent;
			}
			
		}
	}
	.icon-box.bg-white{
		svg{
			path{
				fill:$white!important;
			}
		}
	}
	.lang-chart li{
		color:$white;
	}
	.analytics-card{
		background-color:$dark-card!important;
	}
	.activity-sale .recent-activity:after{
		background:$d-border;
	}
	.blog-post .post-1{
		border-color:$d-border;
	}
	.chat-p.shaprate,
	.chat-border{
		border-color:$d-border;
	}
	.second-head{
		color:$white;
	}
	.btn-primary{
		&.sharp{
			svg{
				circle{
					fill:black;
				}
			}
		}
	}
	.demo-right-inner{
		border-color:$d-border;
	}
	.btn-close{
		background:none;
	}
	.navbar-nav .active .scroll {
		color: #ffffff !important;
	}	
	.crm-cart-data{
		h6{
			color:black!important;
		}
		.text-black{
			color:black!important;
		}
	}
	.crm-cart{
		.badge{
			&.text-black{
				color:$white!important;
			}
		}
	}
	.c-line:before{
		background:$d-border;
	}
	.sales-bx{
		h4{
			color:black!important;
		}
	}
	.badge{
		i{
			color:black!important;
		}
	}
	.c-list{
		.search-area{
			.form-control{
				background:black;
			}
			.input-group-text{
				background:black;
				svg{
					circle,
					path{
						stroke:$white;
					}
				}
			}
		}
	}
	& [data-sidebarbg="color_1"]{
		.menu-icon{
			svg{
				path{
					stroke:$white;
				}
			}
		}
	}
	.heading-data{
		td{
			svg{
				path{
					fill:$white;
				}
			}
		}
	}
	.notification_dropdown .dropdown-menu-end .all-notification{
		background:$dark-card;
	}
	.accordion{
		--bs-accordion-bg:$dark-card;
	}
	.depostit-card .depostit-card-media h3, .depostit-card .depostit-card-media .h3{
		color: #222B40!important;
	}
	.revenue-date h4, .revenue-date .h4{
		color: #222B40!important;
	}
	.star-rating{
		ul{
			li{
				svg{
					path{
						stroke:white;
					}
				}
			}
		}
	}
	.progress-box .progress .progress-bar{
		border-color:$d-border;
	}
	.bg-warning-light,
	.bg-primary-light,
	.expenses-card{
		.add-visit{
			h6,h3{
				color:black!important;
			}
		}
	}
	.analytics-card .ic-n-bx .icon-box i{
		color:black;
	}
	.post-title{
		.text-black{
			color:$white!important;
		}
	}
	.profile-news .media{
		border-color:$d-border;
	}
	.post-details{
		h3{
			color:$white!important;
		}
	}
	.compose-wrapper{
		.btn{
			span{
				i{
					color:black;
				}
			}
		}
	}
	.fc-h-event .fc-event-title{
		color: black;
	}
	.light.btn-primary{
		span{
			svg{
				circle{
					fill:$black!important;
				}
			}
		}
	}
	.list-group-item{
		&.active{
			h6{
				color:black!important;
			}
		}
	}
	.list-group{
		.active{
			h5{
				color:black;
			}
		}
	}
	.pagination.page-indicator-light .page-item.active .page-link{
		color:black!important;
	}
	.to-dodroup .sub-card .d-items .form-check-input:checked{
		border-color: black;
		background-color: var(--primary);
	}
	.widget-stat .media .media-body h3, .widget-stat .media .media-body .h3{
		color:black;
	}
	.card{
		&.bg-primary-light,
		&.bg-warning-light{
			.list-group-item{
				color: #000;
			}
		}
	}
	.footer .copyright a{
		color: var(--primary)!important;
	}
	.schedules-cal .datepicker-days .day{
		color: white!important;
		&.active,
		&.today{
			color:#111828!important;
		}
	}
	.project-media{
		svg{
			rect{
				fill:$d-bg;
			}
		}
	}
	.revenue-date{
		h6{
			color:black;
		}
	}
	.progress-box p{
		color:black!important;
	}
	.login-form{
		.text-dark{
			color:white!important;
		}
	}
	.login-form .login-title:before, .login-form .login-title:after{
		background-color:$d-border;
	}
	&[data-sidebarbg="color_1"][data-sidebar-style="mini"][data-sidebar-style="full"]{
		.metismenu li > ul{
			background-color:$d-bg!important;
		}
		.deznav .metismenu > li:hover > a .menu-icon{
			background-color:transparent!important;
			svg{
				path{
					stroke:var(--primary);
				}
			}
		}
		.deznav .metismenu > li.mm-active > a .menu-icon svg path{
			stroke:var(--primary);
		}
	}
	&[data-sidebarbg="color_1"][data-sidebar-style="icon-hover"]{
		.deznav .metismenu > li:hover > a .menu-icon{
			background-color:transparent;
			svg{
				path{
					stroke:var(--primary);
				}
			}
		}
		.deznav .metismenu > li.mm-active > a .menu-icon svg path{
			stroke:var(--primary);
		}
	}
	&[data-sidebarbg="color_1"][data-sidebar-style="modern"]{
		.deznav .metismenu > li:hover > a .menu-icon,
		.deznav .metismenu > li.mm-active > a .menu-icon{
			background-color:transparent;
			svg{
				path{
					stroke:var(--primary);
				}
			}
		}
		.deznav .metismenu > li:before{
			background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #ffffff 53.12%, rgba(255, 255, 255, 0) 100%);
		}
	}
	&[data-sidebarbg="color_1"][data-layout="horizontal"]{
		.deznav{
			border-top: 1px solid $d-border;
		}
	}
	&[data-sidebarbg="color_1"][data-sidebar-style="icon-hover"][data-layout="vertical"]{
		.deznav .metismenu > li:hover > a .menu-icon{
			background-color:var(--primary);
			svg{
				path{
					stroke:black;
				}
			}
		}
		.deznav .metismenu > li.mm-active > a .menu-icon svg path{
			stroke:black;
		}
	}
	
	&[data-sidebarbg="color_1"]{

		.menu-icon svg path{
			stroke: white;
		}
		.switch-btn{
			border-color:$d-border;
			svg{
				path{
					stroke:white;
				}
			}
		}
		.menu-toggle .deznav .metismenu li > ul{
			background-color:$d-bg!important;
		}
	}
	&[data-sidebarbg="color_1"][data-sidebar-style="full"][data-layout="vertical"]{
		.deznav .metismenu > li > a .menu-icon{
			background-color: rgba(255, 255, 255, 0.2);
		}
		.deznav .metismenu > li.mm-active > a .menu-icon{
			background-color:var(--primary-light);
			svg{
				path{
					stroke:black;
				}
			}
		}
		.deznav .metismenu li:hover  a .menu-icon{
			background-color:var(--primary-light);
			svg{
				path{
					stroke:black;
				}
			}
		}
	}
	&[data-sidebarbg="color_1"][data-sidebar-style="modern"][data-layout="horizontal"]{
		.deznav .metismenu > li:before{
			    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 53.12%, rgba(255, 255, 255, 0) 100%);
		}
	}
	&[data-primary="color_13"]{
		.text-primary{
			color:white!important;
		}
		.footer .copyright a{
			color:$white!important;
		}
		.active-projects div.dt-buttons .dt-button{
			color:$white;
		}
		.ov-card:hover .icon-box{
			background-color: #fff !important;
		}
		
	}
	.analytics-card p{
		color:$white;
	}
	.schedules-cal .datepicker-days .day{
		&:hover{
			color:black!important;
		}
	}
	.active-map, .sales-map{
		.worldmap__figure-container{
			background-color: transparent !important;
		}
	}
	.react-datepicker{
		background-color: transparent;
		color: $white;
	}
	.dz-calender .react-datepicker__header{
		background-color: transparent;
		color: $white;
	}
	#VisitorsChart {
		line {
			stroke: #444444;
		}
		.apexcharts-text tspan {
			fill: #666666;
		}
	}
	.custom-react-select{		
		& > div:nth-child(4){
			& > div{
				background-color: $dark-card;
				& > div{
					background-color: $dark-card;
					@include transitionMedium;
					&:hover{
						background-color: $dark-card;
						color: $white;
					}
				}
			}
		}
		& > div {
			&:nth-child(3){			
				background-color: $dark-card;			
				border-color: $d-border !important;
				&:hover {
					border-color: var(--primary) !important;
				}
			}
			& > div{
				&:first-child{
					div{		
						color: $white;
					}
				}
				
			}
		}
		
	}
	.email-left-box .mail-list .list-group-item.active {
		color: $white !important;
	}
	.accordion-primary{
		.accordion-button {
			color: #000 !important;
			box-shadow: none;
		}
	}
	.link-secondary {
		color: $white !important;
	}
	.alert.alert-outline-light{
		color: #929696;
	}
	.badge-light{
		color: var(--text-dark) !important;
	}
	.css-b62m3t-container{
		& > div{
			border-color: rgba(255,255,255,0.3);
			background: $d-bg;
		}
		[class*="-singleValue"],
		[class*="-placeholder"]{
			color:#fff;
		}
		[class*="-multiValue"]{
			background: $d-bg;
			div{
				color:#fff;
			}
		}
		[class*="-IndicatorsContainer"]{
			div{color:#fff; }
		}
		[class*="-menu"]{
			background:$d-bg;
			& > div > *{
				&:hover{
					background:var(--primary-light);
				}
			}
		}
	}
	.sidebar-right {		
		.tab-content{
			background: $d-bg;			
			.tab-pane .admin-settings p{
				color:#fff;
			}
		}
		.sidebar-right-inner{
			h4{
				background-color: $d-bg;	
				color: $white !important;
			}
			.admin-title{
				color:#fff !important;
			}
		}
		.card-tabs .nav-tabs {
			 background:$d-bg;
			.nav-item .nav-link{
				background:rgba(160,207,26,0.6);
				color:#fff;
				&.active{
					background:rgba(160,207,26,0.9);
				}
			}
		}
	}
	.filter-pagination{
		background: $d-bg;
	}
	.filter-pagination .previous-button{
		background-color: $dark-card;
	}
	.filter-pagination .next-button {
		background-color: $dark-card;
	}
}
