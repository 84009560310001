@import "../../abstracts/_mixin";
@import "../../abstracts/_variable";

@include custommq($min: 48rem) {

    [data-sidebar-style="mini"] {
        /* .deznav{
			.metismenu{
				.mm-active{
						.has-arrow{
							.menu-icon{
								&:hover{
									border-radius:30px 0 0 30px;
								}	
							}
						}	
					
				}
			}
			
		} */
		.deznav .metismenu > li:hover > .has-arrow  .menu-icon{
			border-radius:30px 0 0 30px;
		}
		.deznav .metismenu ul li:before{
			display:none;
		}
			
        .nav-header {
			width: 6.25rem;

            .nav-control {
                z-index: -1;
                .hamburger{
                    left: 6.25rem!important;
                    .line{
                        background-color: $dark!important;
                    }
                }
            }
            .brand-title {
                display: none;
            }
            .hamburger {
                display: none;
            }
        }
		.header .header-content{
			padding-left: 2.5rem;
			@at-root [direction="rtl"]#{&}{
				padding-right: 1.875rem;
			}
		}
        .deznav {
			width: 6.25rem;
            overflow: visible;
            position: absolute;
			z-index:2;
			//top:5rem;
			.copyright,
			.plus-box{
				display:none;
			}
            .nav-text {
                display: none;
            }

            .slimScrollDiv,
            .deznav-scroll {
                overflow: visible !important;
            }
            .nav-user{
                padding: 0.6875rem;
                .media-body{
                    display: none;
                }
            }
			.header-profile{
				&:hover{
					&> a.nav-link{
						border-radius:3rem;
					}
				}
				img {
					width: 3rem;
					height: 3rem;
				}
				&> a.nav-link{
					border-radius:3rem;
					padding: 0.3125rem 0.3125rem
				}
				.header-info{
					display:none;
				}
				margin-bottom:0;
				margin-top:0.75rem;
			}
            .metismenu {
               li {
					.mini-dashboard{
						position: relative;
						color: #111828;
						font-size: 15px;
						font-weight: 700;
						z-index: 6;
						padding: 13.6px 21px;
						&:after{
							content: "";
							position: absolute;
							top: 0;
							left: -39px;
							height: 50px;
							width: 15rem;
							background: var(--primary-light);
							z-index: -1;
							border-radius: 0 30px 30px 0;
							@include custommq($max:100rem){
								left: -24px;
								width: 13.7rem;
							}
						}
					}
			   
                    a {
                        padding: 0.813rem 0.875rem;
						 svg{
							margin-right:0;
						 }
						 i{
							height:auto;
							width:auto;
							line-height:1;
							margin:0;
						 }
                    }

                    &>ul {
                        position: absolute;
                        left: 6.25rem;
                        top: 2.9375rem;
                        width: 11.875rem;
                        z-index: 1001;
                        display: none;
                        padding-left: 0.0625rem;
						box-shadow: 1rem 0.75rem 1.25rem 0 rgba(82, 63, 105, 0.1);
                        height: auto !important;
						border-radius: 0 2rem 2rem 0;
						background-color: var(--sidebar-nav-bg);
    

                        @at-root [direction="rtl"]:not([data-layout="horizontal"])#{&} {
                            left: auto;
                            right: 6.25rem;
                            box-shadow: 0rem 0rem 2.5rem 0rem rgba(82, 63, 105, 0.1);
                        }
						li{
							ul{
								border-radius: $radius;
							}
						}
                    }
                }
                &>li {
					//padding: 0.125rem 1.25rem;
                    &>a {
						padding: 0.5rem 1.575rem;
						text-align: center;
						line-height: 1;
						transition: all 0.5s;
						-moz-transition: all 0.5s;
						-webkit-transition: all 0.5s;
						-ms-transition: all 0.5s;
						-o-transition: all 0.5s;
						
						& > i{
						padding:0;
						font-size:1.375rem;
						}
                        &.has-arrow {
                            &:after {
                                display: none;
                            }
                        }
						.badge{
							display:none;
						}
                    }
                }
					.menu-title{
						display:none;
					}
                .nav-label,
                .nav-badge {
                    display: none;
                }
				
            }
			.switch-btn{
				padding: 40px 38px;
				button{
					span{
						display:none
					}
				}
			}
			@include custommq($max: 63.9375rem){
				//top:5rem;
			}
			.header-info2{
				padding:0;	
			}
			.sidebar-info{
				display:none!important;	
			}
			.header-profile2{
				margin:0 0.5rem;	
			}
			.help-desk{
				display:none;
			}
        }

        .outer-body {
            padding-left: 6.28rem;
        }

        .footer {
            // padding-left: 6.5rem;
        }
		
		&[data-header-position="fixed"] {
			.content-body{
				padding-top: 5.5rem;
				@include custommq($max: 63.9375rem){
					padding-top:5.4rem;
				}
			}
		}	
		&[data-sidebarbg="color_1"]{
			.metismenu{
				li{
					&>ul {
						background-color: #fff;
					}
				}
			}
			
		}
    }

    [data-sidebar-style="mini"][data-layout="vertical"] {
        .deznav {
			position: absolute !important;
            .metismenu {
				.has-arrow[aria-expanded=true]:after,
				.mm-active>.has-arrow:after {
					-webkit-transform: rotate(-225deg) translateY(-50%)! important;
					transform: rotate(-225deg) translateY(-50%)! important;
					/* margin-right: 2rem; */
				}

                li {
                    &:hover>ul {
                        display: block;
                    }
                }

                &>li {

                    &:nth-last-child(-n + 1) {
                        &>ul {
                            bottom:0;
                            top: auto !important;
							&:after{
								top:auto;
								bottom:1.25rem;
							}
                        }
                    }

                    @include respond('tab-land') {
                        &:nth-last-child(-n + 1) {
                            &>ul {
                                bottom: 0;
                                top: auto !important;
                            }
                        }
                    }

                    &>ul {
                        overflow: visible;
						
						&:after{
							content:none;
						}

                        li:hover {
                            ul {
                                padding: 0.625rem 0;
								width: 13rem;
								left: 13rem;
								top: -0.625rem;
								border: 0;
								margin: 0;
								&:after{
									content:none;
								}			
								@at-root [direction="rtl"]#{&}{
									left: auto;
									right: 13rem;
								}			
                            }
                        }
                    }
					&.mm-active {
						&>a {
							//background: rgba(13, 153, 255, 0.05);
							// background: rgba(255, 255, 255, 0.15) !important;
							color:$white;
							//border-radius: $radius;
							i{
								color:$white;
							}
							//@at-root [data-theme-version="dark"]#{&} {
							//	background: $dark-card;
							//}
						}
					}
                    &:hover {


                        &>a {
                           // background-color: rgba(13, 153, 255, 0.05);
							color:var(--primary);
							/* box-shadow:0 12px 15px 0 var(--rgba-primary-1); */
							border-radius: 0;
							position: unset;
							i{
								color:$white;
							}	
							
                            .nav-text {
								
                                @at-root [direction="rtl"]#{&} {
                                    padding-left: auto;
                                    padding-right: 1.6875rem;
                                }
                            }
                        }
                        &>ul {
                            height: auto !important;
                            overflow: visible;
							border:0;
							margin-left:0;
							left:6.25rem;
							width: 13rem;
							//border-radius:0;
							border:0;
							padding: 0.5rem 0;
							top: 0px;
							//box-shadow:0px 50px 20px rgba(0, 0, 0, 0.1);
							
							@at-root [data-theme-version="dark"]#{&} {
								box-shadow: 0rem 0rem 2.5rem 0rem rgba(82, 63, 105, 0.1);
								background-color:$d-bg;
							}
							@at-root [direction="rtl"]#{&} {
								left: auto;
								right: 6.25rem;
							}
							a {
                                padding: 0.575rem 1.25rem 0.575rem 1.25rem;
                               
							    &:before{
									content:none;
								}
								&.has-arrow:after{
									right: 1.25rem
								}
                            }
							
                            ul {
								
								
								a {
									padding: 0.575rem 1.25rem 0.575rem 1.25rem;
									margin-left: -0.1rem;
									&:before{
										content:none;
									}
								}
                            }
                        }
                    }
                }
            }
        }
    }

    [data-sidebar-style="mini"][data-header-position="fixed"][data-container="boxed"][data-layout="vertical"] {
        .header {
            width: 74.9375rem;
        }
    }
	
	[data-sidebar-style="mini"][data-layout="horizontal"] {
		.deznav{
			.metismenu {
				
				& > li{
					padding: 0;
					
					& > a {
						padding: 1rem 1rem;
						
						i{
							padding: 0;	
							margin:0;
						}
						@at-root [direction="rtl"]#{&} {
							padding: 1.125rem;
							svg {
								margin-left: 0;
							}
						}
						svg{
							margin-right: 0;
							margin-top: 0;
						}
					}
					& > ul li a{
					    padding: 0.5rem 1.25rem 0.5rem 1.25rem;
					}
				}
			}
		}
	}

}

@include respond('tab-land') {
    [data-sidebar-style="mini"] {
        .deznav {

            li {
                &.mm-active {
                    ul {
                        height: auto !important;
                    }
                }

                a.has-arrow {
                    &::after {
                        transform: rotate(-225deg) translateY(-50%);
                    }
                }
            }
        }
    }
}
[data-sidebar-style="mini"][data-sidebar-position="fixed"][data-header-position="static"]{
	.outer-body{
		padding-top: 0;
		.inner-body{
			border-top-right-radius: 0;
			border-top-left-radius: 0;
			border-top: 0;
		}
	}
	.calendar-warpper{
		top: 0;
		height: 100%;
	}
}
[data-header-position="static"][data-sidebar-position="static"][data-layout="horizontal"][data-sidebar-style="mini"]{
	.calendar-warpper{
		top: 175px;
	}
}
[data-header-position="static"][data-sidebar-position="fixed"][data-layout="vertical"]{
	.outer-body{
		position:absolute;
		height: auto;
	}
	.deznav{
		position: fixed!important;
	}
	
}