.dz-demo-panel {
    right: -100%;
    position: fixed;
    top: 0;
    width: 100%;
    //background-color:rgba(0,0,0,0.8);
    height: 100vh;
    transition: all .5s ease-in-out;
	z-index: 9999;
	.note-text{
		position: absolute;
		bottom: 0;
		left: 0;
		padding: 20px;
		font-size: 12px;
		background-color:transprent;
		color: #9b9a9a;
	}
    .dz-demo-trigger {
        position: absolute;
		z-index: 9;
		top: 7.75rem;
		right: 100%;
		background-color: #627EEA;
		color: #fff;
		display: inline-block;
		height: 3rem;
		width: 3rem;
		text-align: center;
		font-size: 1.75rem;
		line-height: 3rem;
		border-radius: 0.3125rem 0 0 0.3125rem;
		box-shadow: -0.3125rem 0.1875rem 0.3125rem 0rem rgba(119, 119, 119, 0.15);
		//display:none;
        @include custommq($max: 63.9375rem){
            display:none;
        }
		
    }
	.dz-demo-close{
		height:1.875rem;
		color:$black!important;
		width:1.875rem;
		border-radius:0.5rem;
		background:$white;
		line-height:1.875rem;
		text-align:center;
	}
	&:after{
		content: "";
		position: absolute;
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
		z-index: -1;
		background: rgba(0,0,0,.85);
		display: block;
		backdrop-filter: blur(4px);
	}
    &.show {
        right: 0;
		box-shadow: 0rem 0rem 3.125rem rgba(0 ,0 ,0 ,0.2);
        z-index: 99999;
		overflow:hidden;
		.sidebar-right-trigger{
			display:none;
		}
		.bg-close{
			position:fixed;
			z-index: -2;
			cursor:pointer;
			width:100%;
			height:100%;
			top:0;
			left:0;
			background:rgba($black,0.15);
		}
    }
	.dz-demo-inner{
		padding: 1.875rem 1.875rem;
	}
	.dz-demo-content{
		height: calc(100vh - 200px);
		padding: 0 15px;
		margin: 0 -15px;
		display: flex;
		//align-items: center;
		overflow-y: scroll;
	}
	.dz-demo-header{
		display:flex;
		justify-content:space-between;
		align-items:center;
		margin-bottom: 3.5rem;
		h4{
			margin-bottom:0;
			color:$white;
		}
	}
	.dz-demo-bx{
		//height: 14.5rem;
		overflow:hidden;
		border:3px solid #efefef;
		box-shadow:0rem 0rem 0.3125rem rgba(0,0,0,0.1);
		margin-bottom: 0.625rem;
		//border-radius:$radius;
		border-radius:$radius;
		&.demo-active{
			border-color:var(--primary);
			.overlay-layer{
				opacity: 1;
			}
		}
	}
	.overlay-bx{
		position:relative;
		.overlay-layer{
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-pack: center;
			-ms-flex-pack: center;
			justify-content: center;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			background-color: rgba(0,0,0,.1);
			-webkit-transition: all .3s ease;
			transition: all .3s ease;
			opacity: 0;
			border:0;
		}
		&:hover{
			.overlay-layer{
				-webkit-transition: all .3s ease;
				transition: all .3s ease;
				opacity: 1;
			}
		}
	}
}
