/////////////////////////////
// Bootstrap validation
/////////////////////////////
.form-control.is-valid {
    border-color: $success !important;
    border-right: 0rem !important;
    &:focus {
        box-shadow: none;
    }
}

.form-control.is-warning {
    border-color: $warning !important;
    border-right: 0rem !important;
    &:focus {
        box-shadow: none;
    }
}

.form-control.is-invalid {
    border-color: $danger !important;
    border-right: 0rem !important;
    &:focus {
        box-shadow: none;
    }
}

////////////////////////
// Jquery Validation 
////////////////////////
.is-valid {
    .input-group-prepend {
        .input-group-text {
            i {
                color: $success;
            }
        }
    }
}

.is-invalid {
    .input-group-prepend {
        .input-group-text {
            i {
                color: var(--rgba-primary-2);
            }
        }
    }
}

// Show Pass
.show-pass{
	cursor:pointer;
	
	// .fa-eye{
	// 	display:none;
	// }
	
	// &.active{
		// .fa-eye-slash{
		// 	display:none;
		// }
		// .fa-eye{
		// 	display:inline-block;
		// }
	// }
}

.bleft {
    border-top-left-radius: 0.625rem !important;
    border-bottom-left-radius: 0.625rem !important;
}