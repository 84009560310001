@import "../../abstracts/variable";
@import "../../abstracts/mixin";
[data-sidebar-style="full"][data-layout="vertical"] {
    .deznav{
		.metismenu{

			& > li{
				& > a{
					font-size:1rem;
					padding: 0.594rem 1.3rem;
					margin:  0px;
					@include transitionMedium;
					
					
				}
				&.mm-active{

					& > a{
					
						i{
							color:$white;
							font-weight: 300;
						}
					}
				}
				.has-arrow:after{
					right: 1.5rem;
					

				}
			}
			.mini-dashboard{
				display:none;
			}
			
		}
		
	}
	.menu-toggle {
		.deznav .metismenu ul li:before{
			display:none;
		}
		.switch-btn{
			padding: 40px 38px;
		}
	.deznav .metismenu > li:hover > .has-arrow .menu-icon {
		border-radius: 30px 0 0 30px;
	}	
		
        .nav-header {
            width: 6.25rem;
            z-index: 999;
            .brand-logo {
                padding-left: 0;
                padding-right: 0;
                justify-content: center;
				.color-title{
					display:none;
				}
            }
            .nav-control {
				right: -5.4rem;
                .hamburger {
                    .line {
                        background-color:$white;
                    }
                }
            }
        }
		.plus-box,
		.help-desk{
			display:none;
		}
        .header {
           padding-left: 6.25rem;
            width: 100%;
            @at-root [direction="rtl"]#{&} {
                padding: 0 0.9375rem;
                padding-right: 7.5rem;
            }
			.header-content{
				padding-left: 6.5rem;
			}
        }
        .deznav {
            width: 6.25rem;
            overflow: visible;
            position: absolute!important;
            .nav-text {
                display: none;
            }
			.deznav-scroll{
				overflow-y: unset;
			}
			.header-info2{
				padding:0;	
			}
			.sidebar-info{
				display:none!important;	
			}
			.header-profile2{
				margin: 0 0.5rem;	
			}
            .metismenu {
                li {
					.mini-dashboard{
						display:block;
						font-size: 15px;
						font-weight: 700;
						z-index: 6;
						color: #111828;
						padding: 14.7px 21px;
						&:after{
							content: "";
							position: absolute;
							top: 0;
							left: -39px;
							height: 50px;
							width: 15rem;
							background: var(--primary-light);
							z-index: -1;
							border-radius: 0 30px 30px 0;
							@include custommq($max:100rem){
								left: -24px;
								width: 13.7rem;
							}
						}
					}
                    position: relative;
                    a {
						background:transparent;
						margin: 0.125rem 0;
						
						svg{
						    max-width: 1.5rem;
							max-height: 1.5rem;
							margin-right: 0;
						}
						&:before{
							content:none;
						}
						i{
							margin: 0;
						}
                    }
                    &>ul {
                        position: absolute;
						left: 6.25rem;
						top: 3px;
						width: 13rem;
						z-index: 1001;
						display: none;
						padding-left: 0.0625rem;
						height: auto !important;
						box-shadow: 1rem 0.75rem 1.25rem 0 rgba(82, 63, 105, 0.1);
						margin-left: 0;
						border:0;
						border-radius: 0 2rem 2rem 0;
						background: var(--sidebar-nav-bg);
						

                        @at-root [direction="rtl"]#{&} {
                            left: auto;
                            right: 5rem;
                            // box-shadow: -0.375rem 0.375rem 0.625rem rgba(0, 0, 0, 0.15);
                        }
                        li:hover {
                            ul {
                                // display: block;
                                left: 12.9125rem;
                                top: -10rem;
								border-radius:$radius;
								&:after{
									content:none;
								}
                            }
                        }
                    }
                    &:hover>ul {
                        display: block;
                        height: auto;
                        overflow: visible;
                    }
                }
                &>li {
                    transition: all 0.4s ease-in-out;
					//padding: 0.125rem 1.25rem;
					
                    &>a {
						padding: 0.594rem 1.475rem;
						margin: 3px 0px;
						text-align:center;
						.badge{
							display:none;
						}
                        &.has-arrow {
                            &:after {
                                display: none;
                            }
                        }
                    }
                    &.mm-active > a{
						i{
							color:$white;
							padding:0;
						}
					}
                    &:hover{
                        &:nth-last-child(-n + 1) {
                            &>ul {
                                bottom: 0;
                                top: auto;
                            }
                        }
                        &>a {
							
							//background-color: rgba(13, 153, 255, 0.05);
							color:$white;
							@at-root [data-theme-version="dark"]#{&} {
								//background:$dark-card;
							}
							i{
								color:$white;
							}
							
                        }
                        &>ul {
                            height: auto !important;
							padding: 0.625rem 0;						
							
                            a {
                                padding: 0.375rem 1.25rem 0.375rem 1.25rem;
                                margin-left: -.1rem;
                            }
                            ul{
								padding: 0.625rem 0;
								a {
									padding: 0.375rem 1.25rem 0.375rem 1.25rem;
									margin-left: -.1rem;
								}
                            }
                        }
                    }
                }
			}	
                .nav-label,
                .nav-badge {
                    display: none;
					
                }
				.menu-title{
					display:none;
				}
			.switch-btn{
				a{
					span{
						display:none;
					}
				}
			}
			
        }
        .outer-body {
			padding-left: 6.25rem;
            @at-root [direction="rtl"]#{&} {
                margin-right: 5.7rem;
                margin-left: auto;
				border: 0;
            }
        }
        &+.footer {
            padding-left: 5.7rem;
            @at-root [direction="rtl"]#{&} {
                padding-left: 0;
                padding-right: 5.7rem;
            }
        }
	}	
[data-sidebar-style="full"][data-layout="horizontal"] {
	.header .header-content{
		padding-left: 1.875rem;
	}
}
&[data-sidebarbg="color_1"]{
	.menu-toggle .deznav .metismenu li > ul{
		background-color:$white;
	}
}
[data-nav-headerbg="color_1"]{
	.menu-toggle {
		.color-title{
			display:none! important;
		}
	}
}
.show-more-btn{
	padding: 0.75rem 1.5rem;
	font-size: 0.813rem!important;
	color: var(--primary)!important;
	&:before{
		display:none;
	}
	&:active{
		border:0!important;
	}	
	
	&.collapsed{
		border:0;
	}
}
}

[data-header-position="static"][data-sidebar-position="fixed"][data-layout="vertical"][data-sidebar-style="full"]{
	.outer-body{
		top: 0px;
		padding-top: 99px;
		.inner-body{
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}
	}	
	.calendar-warpper{
		top: 0;
		padding-top: 99px;
		height: 100%;
	}	
}
[data-header-position="static"][data-sidebar-position="static"][data-layout="vertical"]{
	.outer-body{
		position: absolute;
		height: auto;
		top:89px;
		.inner-body{
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}
		@include custommq($max:100rem){
			top:77px;
		}
	}
	.calendar-warpper{
		top: 0px;
		height: 100%;
	}
}	
[data-header-position="static"][data-sidebar-position="fixed"][data-layout="vertical"]{
	.outer-body{
		@include custommq($max:100rem){
			top: 76px!important;
		}
			
	}
	.calendar-warpper{
		height: calc(100vh - 4.2rem);
	}
	

}

