// .authincation{
//     height: 100%;

// }

.flex-row-fluid{
	-webkit-box-flex: 1;
    flex: 1 auto;
    -ms-flex: 1 0 0px;
    min-width: 0;
}


.authincation{
	//background:var(--rgba-primary-1);
	display:flex;
	min-height: 100vh;
	.login-aside{
		background: $white;
		padding-top: 80px;
		max-width:560px;
		width: 100%;
		z-index:1;
		position:relative;
		&:after{
			content:"";
			clip-path: polygon(0% 100%, 100% 0%, 0% 0%);
			width:140px;
			height:100%;
			position:absolute;
			right:-140px;
			z-index:-1;
			top:0;
			background: $white;
			box-shadow: 2px 0px 30px rgba(0, 0, 0,0.15);
		}
		.aside-image{
			min-height:450px;
			margin: auto 0;
			min-width: 0;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
		}
	}
	@include respond ('laptop'){
		.login-aside{
			max-width:360px;
		}
	}
	@include respond ('tab-port'){
		.login-aside{
			max-width:100%;
			padding-top: 0;
			&:after{
				content:none;
			}
		}
	}
	@include respond ('phone'){
		.login-aside{
			.aside-image{
				min-height:300px;
			}
		}
	}
}

.auth-form{
    padding: 50px 50px;
	@include respond('phone') {
		padding: 30px;
	}	
    .btn{
        height: 50px;
        font-weight: 700;
    }
    .page-back{
        display: inline-block;
        margin-bottom: 15px;
    }
}

.pages-left{
	background-color:#fff;
}
.login-media{
	margin-top:80px;
	img{
		width:90%;
		
		@include respond ('tab-port'){
			width:80%;
			
		}
	}
	@include respond ('phone'){
		margin-top:20px;
	}
}
.login-content{
		// padding-left:70px;
		padding-top:70px;
		text-align:center;
	p{
		
		font-size:20px;
		color:$black;
		line-height:1.5;
		margin-left: auto;
		margin-right: auto;
		max-width: 500px;
		
		@include respond ('tab-land'){
			//width:100%;
			font-size:20px;
		}
		@include respond ('tab-port'){
			//width:90%;
		}
		@include respond ('phone'){
			//width:100%;
			font-size:1rem;
		}
	}
	
	@include respond ('tab-port'){
		// padding-left:51px;
		padding-top:40px;
		
	}
	@include respond ('phone'){
		// padding-left:14px;
		padding-top:14px;
	}
} 
.login-form {
    padding: 50px 50px;
    max-width: 600px;
    margin: 0 auto;
	
	
	 .login-title {
		text-align: center;
		position: relative;
		margin-bottom: 48px;
		z-index: 1;
		display: flex;
		align-items: center;
		&:before,
		&:after{
			content: "";
			height: 1px;
			flex: 1 1;
			left: 0;
			background-color: #E1E1F0;
			top: 50%;
			z-index: -1;
			margin: 0;
			padding: 0;
		}
	}
	.btn-facebook,
	.btn-google-plus,
	 .btn-linkedin,
	 .btn-twitter{
		width:2.5rem;
		height:2.5rem;
		display:inline-block;
		text-align:center;
		line-height:2.5rem;
		border-radius:100%;
	}

	@include respond ('tab-land'){
		 padding:25px 45px;
	}
	@include respond ('tab-port'){
		padding:45px;
	}
	
	@include respond ('phone'){
		padding:30px;
	}
}
.eye{
	position:absolute;
	top: 38px;
	right:20px;
	@include custommq($max:100rem){
		top:34px;
	}
}