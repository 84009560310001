//customize bootstrap
@import './bs-custom';

$strong:#646c9a;
$border: #E6E6E6;
$shadow: 0rem 0rem 2.5rem 0rem rgba(82,63,105,0.1);
// $body-bg: #C7EBFC;
$body-bg: #F4F4F5;
$card-border:#000000;


///////////////
// Solid Color
///////////////
$white: #fff;
$black: #000;
$charade: #23252F;

/////////////////////////
// Dark Version Color
/////////////////////////
$d-ctd: #ddd;
$d-ctl: #828690;
$d-border: #444444;
$d-bg: #1E1E1E;
$dark-card: #2c2c2c;
$dark_bg_lighter: #1E2A4A;

/////////////////////////
// Primary Light
/////////////////////////
/* $primary-light: lighten($primary, 43%);
$secondary-light: lighten($secondary, 10%);
$success-light: lighten($success, 15%);
$warning-light: lighten($warning, 20%);
$danger-light: lighten($danger, 10%);
$info-light: lighten($info, 20%);
$dark-light: lighten($dark, 50%); */

$primary-light: #CFF859;
$secondary-light: #DCDFE5;
$success-light: #A7FFEA;
$warning-light: #FCEF73;
$danger-light: #FFCCC0;
$info-light: #D7E3FF;
$dark-light: #999999;

/////////////////////////
// Opacity 
/////////////////////////
$primary-opacity: rgba($primary, 0.1);
$secondary-opacity: rgba($secondary, 0.5);
$success-opacity: rgba($success, 0.1);
$warning-opacity: rgba($warning, 0.1);
$danger-opacity: rgba($danger, 0.15);
$info-opacity: rgba($info, 0.1);
$dark-opacity: rgba($dark, 0.35);

////////////////////////
// Light Version Color
////////////////////////
$l-ctd: #464a53;
$l-ctl: #828690;
$l-border: #eaeaea;
$l-bg: #f2f4fa;
$heading: #333;

///////////////////
// Material Colors
///////////////////
$dusty-gray: #999999;
$gallery: #EEEEEE;
$gray: #898989;
$input-border-color: $border;


///////////////////
// Social Colors
///////////////////
$facebook: #3b5998;
$twitter: #1da1f2;
$youtube: #FF0000;
$google-plus: #db4439;
$linkedin: #007bb6;
$instagram: #c32aa3;
$pinterest: #bd081c;
$google: #4285f4;
$snapchat: #fffc00;
$whatsapp: #25d366;
$tumblr: #35465d;
$reddit: #ff4500;
$spotify: #1ed760;
$yahoo: #430297;
$dribbble: #ea4c89;
$skype: #00aff0;
$quora: #aa2200;
$riverBed: #4C585C;
$vimeo: #1ab7ea;


///////////////////
// Misc Colors
///////////////////
$iron: #DDDFE1;
$grey: #D2D6DE;
$pale-sky: #6a707e;
$athensGray: #F0F0F2;
$sky: #2290FF;





$color_pallate_1: $white;
$color_pallate_2: $primary;
$color_pallate_3: #FF7C7C;
$color_pallate_4: #81A4F9;
$color_pallate_5: #53D0B2;
$color_pallate_6: #F4CF0C;
$color_pallate_7: #50D5DB;
$color_pallate_8: #D58FE5;
$color_pallate_9: #7FB9EE;
$color_pallate_10:#75D089;
$color_pallate_11:#F6B466;
$color_pallate_12:#A6BB68;
$color_pallate_13:$secondary;

$color_pallate_light_1: $light;
$color_pallate_light_2: $primary-light;
$color_pallate_light_3: #FFCCC0;
$color_pallate_light_4: #D7E2FF;
$color_pallate_light_5: #A7FFEA;
$color_pallate_light_6: #FCEF73;
$color_pallate_light_7: #B6FAFD;
$color_pallate_light_8: #F4C7FF;
$color_pallate_light_9: #AFD9FF;
$color_pallate_light_10:#CBFFD6;
$color_pallate_light_11:#FFD8AB;
$color_pallate_light_12:#D6DDC0;
$color_pallate_light_13:#DCDFE5;

//utility variables
$radius: 0.625rem; 
$radius-lg:0.625rem;
$radius-sm:0.375rem;
$radius-xs:0.25rem;


$loader--width: 15.625rem;
$loader-dot--size: 1.25rem;




















