/*
    Table of Contents

    Name                 : W3Admin
    Author               : Dexignzone
    Author Portfolio     : https://themeforest.net/user/dexignzone/portfolio
    


    Table of Sass Contents

    1. Abstracts
        _bs-custom
        _inheritance
        _maps
        _mixin
        _variable

    2. bootstrap 
        -Here goes all official bootstrap scss files

    3. Global/Base
        _color
        _custom-grid
        _fonts
        _helper
        _reset

    4. layout
        +footer
        +header
        +rtl
        +sidebar
        +tables
        +theme
        +version-dark

    5. Conponents
        +app
        +charts
        +forms
        +map
        +uc
        +ui
        +widget

    6. Page
        _index-1
        _page-error
        _page-register
        _page-signin
        _page-user-lock
        _page-user-lock

*/
/* //action button color
$add-btn:blue
$edit-btn:
$delete-btn: */
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700");
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700");
@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700");
@import url("https://fonts.googleapis.com/css?family=Nunito:400,600,700");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
/* @import url("./../icons/simple-line-icons/css/simple-line-icons.css");
@import url("./../icons/fontawesome6/css/all.min.css");
@import url("./../icons/fontawesome/css/all.min.css");
@import url("./../icons/material-design-iconic-font/css/materialdesignicons.min.css");
@import url("./../icons/themify-icons/css/themify-icons.css");
@import url("./../icons/line-awesome/css/line-awesome.min.css");
@import url("./../icons/avasta/css/style.css");
@import url("./../icons/flaticon/flaticon.css");
@import url("./../icons/flaticon_1/flaticon_1.css");
@import url("./../icons/icomoon/icomoon.css");
@import url("./../icons/bootstrap-icons/font/bootstrap-icons.css"); */
@import url(./../other/animate/animate.min.css);
@import url(./../other/aos/css/aos.min.css);
@import url(./../other/perfect-scrollbar/css/perfect-scrollbar.css);
@import url(./../other/metismenu/css/metisMenu.min.css);
:root {
  --primary: #A0CF1A;
  --secondary: #111828;
  --secondary-dark: #020304;
  --primary-hover: #7da214;
  --primary-dark: #374709;
  --rgba-primary-1: rgba(160, 207, 26, 0.1);
  --rgba-primary-2: rgba(160, 207, 26, 0.2);
  --rgba-primary-3: rgba(160, 207, 26, 0.3);
  --rgba-primary-4: rgba(160, 207, 26, 0.4);
  --rgba-primary-5: rgba(160, 207, 26, 0.5);
  --rgba-primary-6: rgba(160, 207, 26, 0.6);
  --rgba-primary-7: rgba(160, 207, 26, 0.7);
  --rgba-primary-8: rgba(160, 207, 26, 0.8);
  --rgba-primary-9: rgba(160, 207, 26, 0.9);
  --rgba-secondary-1: rgba(17, 24, 40, 0.1);
  --rgba-secondary-2: rgba(17, 24, 40, 0.2);
  --rgba-secondary-3: rgba(17, 24, 40, 0.3);
  --rgba-secondary-4: rgba(17, 24, 40, 0.4);
  --rgba-secondary-5: rgba(17, 24, 40, 0.5);
  --rgba-secondary-6: rgba(17, 24, 40, 0.6);
  --rgba-secondary-7: rgba(17, 24, 40, 0.7);
  --rgba-secondary-8: rgba(17, 24, 40, 0.8);
  --rgba-secondary-9: rgba(17, 24, 40, 0.9);
  --font-family-base: DM Sans, sans-serif, sans-serif;
  --font-family-title: DM Sans, sans-serif, sans-serif;
  --title: #111828;
  --text-dark: #111828; }

/* $primary-light: lighten($primary, 43%);
$secondary-light: lighten($secondary, 10%);
$success-light: lighten($success, 15%);
$warning-light: lighten($warning, 20%);
$danger-light: lighten($danger, 10%);
$info-light: lighten($info, 20%);
$dark-light: lighten($dark, 50%); */

/* styles.css */
.flex-container {
  /* max-width: 50vw; Limite la largeur à 100% de la vue */
  overflow-x: auto; /* Permet le défilement horizontal */
  display: flex; /* Assurez-vous que le conteneur utilise flexbox */
}

.flex-container .col-2 {
  flex: 0 0 auto;
}

.table-average td {
  padding: 0.15rem 0.625rem!important;
  /* color: black; */
}

.table * {
  color: black !important;
}

.custom-table {
  border-radius: 20px;
  border: solid 1px black;
  overflow: hidden; /* Pour s'assurer que le contenu de la table respecte le border-radius */
}

/* Pour s'assurer que le border-radius est appliqué sur les <th> et <td> dans les coins */
.custom-table thead tr:first-child th:first-child {
  border-top-left-radius: 20px;
}

.custom-table thead tr:first-child th:last-child {
  border-top-right-radius: 20px;
}

.custom-table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 20px;
}

.custom-table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 20px;
}


.gradient_one {
  background-image: linear-gradient(to right, rgba(186, 1, 181, 0.85) 0%, rgba(103, 25, 255, 0.85) 100%); }

.gradient-1 {
  background: #f0a907;
  background: -moz-linear-gradient(top, #f0a907 0%, #f53c79 100%);
  background: -webkit-linear-gradient(top, #f0a907 0%, #f53c79 100%);
  background: linear-gradient(to bottom, #f0a907 0%, #f53c79 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f0a907', endColorstr='#f53c79',GradientType=0 ); }

.gradient-2 {
  background: #4dedf5;
  background: -moz-linear-gradient(top, #4dedf5 0%, #480ceb 100%);
  background: -webkit-linear-gradient(top, #4dedf5 0%, #480ceb 100%);
  background: linear-gradient(to bottom, #4dedf5 0%, #480ceb 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4dedf5', endColorstr='#480ceb',GradientType=0 ); }

.gradient-3 {
  background: #51f5ae;
  background: -moz-linear-gradient(top, #51f5ae 0%, #3fbcda 100%);
  background: -webkit-linear-gradient(top, #51f5ae 0%, #3fbcda 100%);
  background: linear-gradient(to bottom, #51f5ae 0%, #3fbcda 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#51f5ae', endColorstr='#3fbcda',GradientType=0 ); }

.gradient-4 {
  background: #f25521;
  background: -moz-linear-gradient(left, #f25521 0%, #f9c70a 100%);
  background: -webkit-linear-gradient(left, #f25521 0%, #f9c70a 100%);
  background: linear-gradient(to right, #f25521 0%, #f9c70a 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f25521', endColorstr='#f9c70a',GradientType=1 ); }

.gradient-5 {
  background: #f53c79;
  background: -moz-linear-gradient(left, #f53c79 0%, #f0a907 100%);
  background: -webkit-linear-gradient(left, #f53c79 0%, #f0a907 100%);
  background: linear-gradient(to right, #f53c79 0%, #f0a907 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f53c79', endColorstr='#f0a907',GradientType=1 ); }

.gradient-6 {
  background: #36b9d8;
  background: -moz-linear-gradient(left, #36b9d8 0%, #4bffa2 100%);
  background: -webkit-linear-gradient(left, #36b9d8 0%, #4bffa2 100%);
  background: linear-gradient(to right, #36b9d8 0%, #4bffa2 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#36b9d8', endColorstr='#4bffa2',GradientType=1 ); }

.gradient-7 {
  background: #4400eb;
  background: -moz-linear-gradient(left, #4400eb 0%, #44e7f5 100%);
  background: -webkit-linear-gradient(left, #4400eb 0%, #44e7f5 100%);
  background: linear-gradient(to right, #4400eb 0%, #44e7f5 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4400eb', endColorstr='#44e7f5',GradientType=1 ); }

.gradient-8 {
  background: #F7B00F;
  background: -moz-linear-gradient(top, #F7B00F 0%, #F25521 100%);
  background: -webkit-linear-gradient(top, #F7B00F 0%, #F25521 100%);
  background: linear-gradient(to bottom, #F7B00F 0%, #F25521 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#F7B00F', endColorstr='#F25521',GradientType=1 ); }

.gradient-9 {
  background: #f31e7a !important;
  background: -moz-linear-gradient(left, #f31e7a 0%, #fd712c 100%);
  background: -webkit-linear-gradient(left, #f31e7a 0%, #fd712c 100%);
  background: linear-gradient(to right, #f31e7a 0%, #fd712c 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f31e7a', endColorstr='#fd712c',GradientType=1 ); }

.gradient-10 {
  background: #f25521 !important;
  background: -moz-linear-gradient(left, #f25521 0%, #f9c70a 100%);
  background: -webkit-linear-gradient(left, #f25521 0%, #f9c70a 100%);
  background: linear-gradient(to top, #f25521 0%, #f9c70a 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f25521', endColorstr='#f9c70a',GradientType=1 ); }

.gradient-11 {
  background: #3398fb;
  background: -moz-linear-gradient(left, #3398fb 0%, #8553ee 100%);
  background: -webkit-linear-gradient(left, #3398fb 0%, #8553ee 100%);
  background: linear-gradient(to right, #3398fb 0%, #8553ee 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3398fb', endColorstr='#8553ee',GradientType=1 ); }

.gradient-12 {
  background: #36e1b4;
  background: -moz-linear-gradient(left, #36e1b4 0%, #11cae7 100%);
  background: -webkit-linear-gradient(left, #36e1b4 0%, #11cae7 100%);
  background: linear-gradient(to right, #36e1b4 0%, #11cae7 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#36e1b4', endColorstr='#11cae7',GradientType=1 ); }

.gradient-13 {
  background: #ffbf31;
  background: -moz-linear-gradient(left, #ffbf31 0%, #ff890e 100%);
  background: -webkit-linear-gradient(left, #ffbf31 0%, #ff890e 100%);
  background: linear-gradient(to right, #ffbf31 0%, #ff890e 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffbf31', endColorstr='#ff890e',GradientType=1 ); }

.gradient-14 {
  background: #23bdb8;
  background: -moz-linear-gradient(-45deg, #23bdb8 0%, #43e794 100%);
  background: -webkit-linear-gradient(-45deg, #23bdb8 0%, #43e794 100%);
  background: linear-gradient(135deg, #23bdb8 0%, #43e794 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#23bdb8', endColorstr='#43e794',GradientType=1 ); }

.gradient-15 {
  background: #9a56ff;
  background: -moz-linear-gradient(-45deg, #9a56ff 0%, #e36cd9 100%);
  background: -webkit-linear-gradient(-45deg, #9a56ff 0%, #e36cd9 100%);
  background: linear-gradient(135deg, #9a56ff 0%, #e36cd9 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#9a56ff', endColorstr='#e36cd9',GradientType=1 ); }

.gradient-16 {
  background: #f48665;
  background: -moz-linear-gradient(-45deg, #f48665 0%, #fda23f 100%);
  background: -webkit-linear-gradient(-45deg, #f48665 0%, #fda23f 100%);
  background: linear-gradient(135deg, #f48665 0%, #fda23f 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f48665', endColorstr='#fda23f',GradientType=1 ); }

.gradient-17 {
  background: #e36cd9;
  background: -moz-linear-gradient(-45deg, #e36cd9 0%, #fe60ae 100%);
  background: -webkit-linear-gradient(-45deg, #e36cd9 0%, #fe60ae 100%);
  background: linear-gradient(135deg, #e36cd9 0%, #fe60ae 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e36cd9', endColorstr='#fe60ae',GradientType=1 ); }

.gradient-18 {
  background: #a15cff;
  background: -moz-linear-gradient(left, #a15cff 0%, #ce82fd 100%);
  background: -webkit-linear-gradient(left, #a15cff 0%, #ce82fd 100%);
  background: linear-gradient(to right, #a15cff 0%, #ce82fd 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a15cff', endColorstr='#ce82fd',GradientType=1 ); }

/*
0 - 600: Phone
600 - 900: Tablet portrait
900 - 1200: Tablet landscape
1200 - 1800: Normal styles
1800+ : Big Desktop
1em = 16px
The smaller device rules always should write below the bigger device rules
Fixing Order => Base + Typography >> General Layout + Grid >> Page Layout + Component
*/
/*!
   * Bootstrap  v5.2.3 (https://getbootstrap.com/)
   * Copyright 2011-2022 The Bootstrap Authors
   * Copyright 2011-2022 Twitter, Inc.
   * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
   */
:root {
  --bs-blue: #5e72e4;
  --bs-indigo: #6610f2;
  --bs-purple: #BB6BD9;
  --bs-pink: #e83e8c;
  --bs-red: #EE3232;
  --bs-orange: #ff9900;
  --bs-yellow: #FFFA6F;
  --bs-green: #297F00;
  --bs-teal: #20c997;
  --bs-cyan: #3065D0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #A0CF1A;
  --bs-secondary: #111828;
  --bs-success: #53D0B2;
  --bs-info: #81A5F9;
  --bs-warning: #F4CF0C;
  --bs-danger: #FF7C7C;
  --bs-light: #EEEEEE;
  --bs-dark: #333333;
  --bs-primary-rgb: 160, 207, 26;
  --bs-secondary-rgb: 17, 24, 40;
  --bs-success-rgb: 83, 208, 178;
  --bs-info-rgb: 129, 165, 249;
  --bs-warning-rgb: 244, 207, 12;
  --bs-danger-rgb: 255, 124, 124;
  --bs-light-rgb: 238, 238, 238;
  --bs-dark-rgb: 51, 51, 51;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 136, 136, 136;
  --bs-body-bg-rgb: 244, 244, 245;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: DM Sans, sans-serif, sans-serif;
  --bs-body-font-size: 0.875rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #888888;
  --bs-body-bg: #F4F4F5;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #cdcdcd;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.75rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #A0CF1A;
  --bs-link-hover-color: #80a615;
  --bs-code-color: #e83e8c;
  --bs-highlight-bg: #fffee2; }

*,
*::before,
*::after {
  box-sizing: border-box; }

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth; } }


.bg-primary-light {
  background-color: var(--primary-light) !important;
}
.bg-secondary-light {
  background-color: #dcdfe5 !important;
}
.bg-success-light {
  background-color: #a7ffea !important;
}
.bg-warning-light {
  background-color: #fcef73 !important;
}
.bg-turquoise-light {
  background-color: #d3fbd6 !important;
}
.bg-purple-light {
  background-color: #efc0ff !important;
}
.bg-grey-light{
  background-color: #dcdfe5 !important;
}
.table-support td{
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.tableau-supp tr td{
  padding: 5px 0px;
}
.bg-red-desk1{
  background-color: rgb(255, 173, 173, 0.4) !important;
}
.text-grey-target{
  color: #737B8B !important;
}
.bg-red-desk1-bar{
  background-color: rgb(255, 173, 173, 0.6) !important;
}
.bg-orange-desk2{
  background-color: rgb(255, 190, 11, 0.4) !important;
}
.bg-orange-desk2-bar{
  background-color: rgb(255, 190, 11, 0.6) !important;
}
.bg-greenl-desk3{
  background-color: rgb(202, 255, 191, 0.5) !important;
}
.bg-greenl-desk3-bar{
  background-color: rgb(202, 255, 191, 0.7) !important;
}
.bg-blue-desk4{
  background-color: rgb(155, 246, 255, 0.4) !important;
}
.bg-blue-desk4-bar{
  background-color: rgb(155, 246, 255, 0.6) !important;
}
.bg-yellow-desk5{
  background-color: rgb(255, 250, 111, 0.4) !important;
}
.bg-yellow-desk5-bar{
  background-color: rgb(255, 250, 111, 0.6) !important;
}
.bg-green-desk6{
  background-color: rgb(32, 201, 151, 0.4) !important;
}
.bg-green-desk6-bar{
  background-color: rgb(32, 201, 151, 0.6) !important;
}
.bg-purple-desk7{
  /* background-color: #BEA6FF; */
  background-color: rgb(190, 166, 255, 0.4) !important;
}
.bg-purple-desk7-bar{
  /* background-color: #BEA6FF; */
  background-color: rgb(190, 166, 255, 0.6) !important;
}
.badge-desk1 {
  color: #ffadad !important;
}
.badge-desk2 {
  color: #ffbe0b !important;
}
.badge-desk3 {
  color: #caffbf !important;
}
.badge-desk4 {
  color: #9bf6ff !important;
}
.badge-purple {
  background-color: rgb(190, 166, 255, 0.6);
}
.badge-red-desk2 {
  background-color: rgb(255, 190, 11, 0.6);
}
.badge-gris{
  background-color: #dcdfe5!important;
  color: #111828;
}
.badge-turquoise{
  background-color: #a7ffea!important;
  color: #111828;
}
.badge-rose{
  background-color: #ffccc0!important;
  color: #111828;
}
.badge-warning {
  background-color: #f4cf0c!important;
}
/* .progress-bar {
  background: linear-gradient(to right, #069952, #13eb8a);
} */
.underline {
  border-bottom: 3px solid #10d078;
}
.underline-black {
  border-bottom: 2px solid black;
  margin-bottom: 15px;
}
.card-admin-params:hover {
  /* border-color: #25e87f !important; */
  cursor: pointer;
  background-color: rgba(50, 168, 82,0.1);
}
.badge-list-admin{
  width: 94px;
  text-align: center;
  margin-left: 5px;
  border-radius: 5px;
}
.badge-sell{
  /* background-color: #25d1e8!important; */
  background-color: #258ae8!important;
}
.badge-tlead{
  /* background-color: #25E87F!important; */
  background-color: #25e866!important;
}
.badge-ass_mng{
  /* background-color: #F571C0!important; */
  background-color: #f1a2d2!important;
}
.badge-manager{
  background-color: #E89D25!important;
}
.badge-boss_supp{
  background-color: #e74d4d!important;
}
.badge-mng_supp{
  background-color: #e5e825!important;
}
.badge-agt_supp{
  /* background-color: #d125e8!important;*/
    background-color: #da6ce9!important; 
}
.badge-admin{
  background-color: #000000!important;
}
.badge-sadmin{
  background-color: #000000!important;
}

.badge-Vendeur{
  background-color: #258ae8!important;
}
.badge-TeamLeader{
  background-color: #25e866!important;
}
.badge-Secretaire{
  background-color: #f1a2d2!important;
}
.badge-Manager{
  background-color: #E89D25!important;
}
.badge-BossSupport{
  background-color: #e74d4d!important;
}
.badge-MgSupport{
  background-color: #e8d525!important;
}
.badge-Support{
  background-color: #da6ce9!important;
}
.badge-Admin{
  background-color: #000000!important;
}
.badge-Sadmin{
  background-color: #000000!important;
}
.list-info-perso div div:first-child{
  text-align: end;
  margin-right: 40px;
  padding: 7px 7px;
}
.list-info-perso div div:nth-child(2){
color: black;
font-weight: 600;
}
.btn-modal-footer div {
  cursor: pointer;
}
.table-no-vertical-borders td,
.table-no-vertical-borders th {
  border-left: none;
  border-right: none;
  color: black;
  text-align: center;
}
.entre-deux{
  height: 10px !important;
}
.bg-green-target{
background-color: #10D078;
/* border:1px black solid */
}
.bg-green-target-trait{
  background-color: #10D078;
  border:1px black solid;
}
.bg-green-input{
  border:1px #10D078 solid;
}
.bg-green-login{
  background-color: #10D078 !important;
}
.bg-grey-target{
 background-color: #d9d9d9;
}
.bg-grey-target-trait{
 background-color: #D9D9D9;
}
.sidebar-title{
  font-size: 16px !important;
  color: black;
  cursor: pointer;
}
.sidebar-title-sub{
  font-size: 14px !important;
  color: black;
  cursor: pointer;
}
.sidebar-config{
  font-size: 15px !important;
  margin-left: 67px;
  cursor: pointer;
}
.selected {
  background-color: rgb(217, 217, 217, 0.4);
  border:1px solid #25E87F !important;
  border-radius: 15px;
  color: #737B8B;
  padding-left: 15px;
  font-weight: 600;
}
.sidebar-desks{
  font-size: 16px !important;
  margin-left: 67px;
  padding-left: 15px;
  cursor: pointer;
}
.active {
  background-color: rgb(217, 217, 217, 0.4);
  border:1px solid #25E87F !important;
  border-radius: 15px;
  color: #737B8B;
  padding-left: 15px;
  font-weight: 600;
  font-size: 15px !important;
}
.css-hlgwow{
  padding:0px 1px !important
}
.pageleads-search-input-container {
  display: flex;
  justify-content: start;
  margin-top: 20px;
}

.pageleads-input-group {
  display: flex;
  width: 50%;
}

.pageleads-form-control {
  border-radius: 5px 0 0 5px;
  border: 0px solid #10D078;
  border-right:none;
  height: 29px;
  padding: 5px 10px;
  font-size: 14px;
}

.pageleads-form-control:focus {
  outline: none;
  border-color: #10D078;
  box-shadow: none;
}

.pageleads-input-group-text {
  /* border: 1px solid #10D078; */
  border-left: none;
  background-color: #fff;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 29px;
  padding: 0 10px;
}

.pageleads-search-icon i {
  color: #10D078;
}
.sidebar-desks-title{
  cursor: pointer;
  margin-left: 20px;
  font-size: 15px;
  color: #000;
}
.sidebar-desk-config{
  cursor: pointer;
  margin-left: 120px;
  padding-left: 15px;
}
.active-desk-config{
  background-color: rgb(217, 217, 217, 0.4);
  border:1px solid #25E87F !important;
  border-radius: 15px;
  color: #737B8B;
  padding-left: 15px;
  font-weight: 600;
  font-size: 15px !important;
}
.css-13cymwt-control{
  height: 40px !important;
  border-radius: 0.625rem !important;
}
.select__control--is-focused,
.select__control--menu-is-open {
  height: 40px !important;
}

.bg-grey-lead{
background: #f1f1f1;
}
thead th {
  position: sticky;
  top: 0;
  background: white;
  /* z-index: 10; */
  background-color: white !important;
}
.basic-multi-select{
  height: 45px !important;
}
.css-13cymwt-control{
  height: 45px !important;
  border-radius: 0.625rem !important;
}
.btn-update-target{
  background-color: #ffffff !important;
  color: #25E87F !important;
  border: 1px solid #25E87F !important;
}
.btn-search-date{
  cursor: pointer;
}
.btn-search-date:hover{
  cursor: pointer;
  background-color: #e2e2e2;
}
.vertical-text{
  writing-mode: vertical-rl; /* or vertical-lr depending on the desired direction */
  transform: rotate(180deg);
}

.floating-div {
  display: none; /* Caché par défaut */
  position: absolute;
  width: 400px;
  max-height: 400px;
  overflow: scroll;
  background-color: white; /* Ou toute autre couleur de fond */
  border: 1px solid #ccc; /* Bordure légère */
  box-shadow: 0 2px 4px rgba(0,0,0,0.4); /* Ombre légère pour un effet flottant */
  /* Ajuster pour centrer et décaler à gauche */
  transform: translate(-100%, -50%);
  border-radius: 10px;
}
.pointer{
  cursor: pointer;
}
.table-scroll {
  overflow-y: auto;
  height: 900px; /* Vous pouvez ajuster la hauteur selon vos besoins */
}

.table-scroll thead th {
  position: sticky;
  top: 0;
  /* background: white; */
}

.titre-sel {
  color: #0051ff;
}

.title-Modif div {
  cursor: pointer;
}
.bg-info-top{
  background-color: #2d54c0 !important;
}

.bg-bluefonce{
  background-color: #0d56b4;
}
.color_bluefonce{
  color: #0d56b4 !important;
}
.message-textarea::placeholder {
  color: rgb(0, 0, 0); /* Remplacez #VotreCouleur par le code hexadécimal de la couleur souhaitée */
}
.scrollable-div {
  scrollbar-color: #0d56b4 #f1f1f1; /* Couleur du pouce et de la piste */
  scrollbar-width: thin; /* Largeur de la scrollbar */
}
.bg-purple{
  background-color: #b990e0 !important;
}
.custom-tooltip {
  max-height: 100px; /* Hauteur maximale du tooltip */
  overflow-y: auto;  /* Active le défilement vertical si le contenu dépasse la hauteur maximale */
}
/* .custom-tooltip-comments{
  max-height: 100px;
  overflow-y: auto;
  background-color: #007bb6 !important;
}
.comment{
  min-height: "250px" !important;
 background-color: #007bb6;
}
.comment-content{
  min-height: "250px" !important;
  background-color: #01C854;
} */
.tooltip-content {
  max-height: 200px; /* Ajustez cette valeur en fonction de vos besoins */
  overflow-y: auto;
}
.titre-tableau th {
  padding: 10px 4px !important;
  z-index: 30;
  /* background-color: white !important; */
}
.tableau-content td{
  padding: 10px 4px !important;
}
.css-1mv0kan-indicatorContainer{
  padding: 0!important;
}
@keyframes scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
.text-secondary{
  color:#6e7781 !important
}
.alertsScrollContainer {
  display: flex;
  animation: scroll 50s linear infinite;
}


.btn-non-clic-create{
  border: 1px #81A5F9 solid;
  border-radius: 10px;
  text-align: center;
  color: #81A5F9;
}
.btn-clic-create{
  border-radius: 10px;
  text-align: center;
  background-color: #81A5F9;
  color: white
}
.btn-create-retrait div{
  cursor: pointer;
}

.notification-popup {
  position: fixed;
  bottom: -100px;
  right: 20px;
  background-color: #333;
  color: #fff;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: bottom 0.5s ease-in-out;
  z-index: 1000;
  width: 300px;
}

.notification-popup.show-popup {
  bottom: 20px;
}

.message-popup {
  font-size: 16px;
  font-weight: bold;
}

.info-popup {
  font-size: 14px;
  margin-top: 5px;
}
.bg-confirm{
background-color: #10D078
}
.bg-error{
  background-color: #FF7C7C;
}
.btn-sidebar-title-hover:hover{
  background-color: #f1f1f1;
  cursor: pointer;
}
.btn-sidebar-title:hover{
  background-color: #f1f1f1 !important;
  cursor: pointer;
}
.crm-cart{
  height: 170px !important;
}
.table-container {
  overflow-x: auto;
  position: relative;
}
Table {
  border-collapse: collapse;
  width: 100%;
}
th, td {
  border: 1px solid #ddd;
  padding: 8px;
  white-space: nowrap;
}
th {
  background-color: #f2f2f2;
}
.fixed-column-td {
  position: sticky;
  left: 0;
  background-color: #fff !important;
  z-index: 2;
  min-width: 45px !important;
}
.fixed-column-td-2 {
  position: sticky;
  left: 45px;
  background-color: #fff !important;
  z-index: 3;
  min-width: 45px !important;
}
.fixed-column-td-3 {
  position: sticky;
  left: 90px;
  background-color: #fff !important;
  z-index: 4;
  min-width: 90px !important;
}
.fixed-column-td-4 {
  position: sticky;
  left: 180px;
  background-color: #fff !important;
  z-index: 5;
  min-width: 180px !important;
}
.fixed-column-td-5 {
  position: sticky;
  left: 280px;
  background-color: #fff !important;
  z-index: 6;
  min-width: 100px !important;
}
.fixed-column-td-6 {
  position: sticky;
  left: 380px;
  background-color: #fff !important;
  z-index: 7;
  min-width: 100px !important;
}
.fixed-column {
  position: sticky;
  left: 0;
  background-color: #fff !important;
  z-index: 150 !important;
  width: 45px !important;
  height: 90px !important;
}
.fixed-column-2 {
  position: sticky;
  left: 45px;
  background-color: #fff !important;
  z-index: 151 !important;
  min-width: 45px !important;
  height: 90px !important;
}
.fixed-column-3 {
  position: sticky;
  left: 90px;
  background-color: #fff !important;
  z-index: 152 !important;
  min-width: 90px !important;
  height: 90px !important;
}
.fixed-column-4 {
  position: sticky;
  left: 180px;
  background-color: #fff !important;
  z-index: 153 !important;
  min-width: 100px !important;
  height: 90px !important;
}
.fixed-column-5 {
  position: sticky;
  left: 280px;
  background-color: #fff !important;
  z-index: 153 !important;
  min-width: 100px !important;
  height: 90px !important;
}
.fixed-column-6 {
  position: sticky;
  left: 380px;
  background-color: #fff !important;
  min-width: 100px !important;
  z-index: 153 !important;
  height: 90px !important;
}





.custom-switch-drop .form-check-input {
  width: 31px !important;
  height: 15px;
  background-color: #ccc; /* Default gray background when deactivated */
  border-radius: 25px;
  position: relative;
  appearance: none;
  outline: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Styles for the activated switch */
.custom-switch-drop .form-check-input:checked {
  background-color: #28a745; /* Green background when activated */
}

/* Styles for the switch knob */
.custom-switch-drop .form-check-input::before {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 21px;
  height: 21px;
  background-color: transparent;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

/* Move the knob to the right when checked */
.custom-switch-drop .form-check-input:checked::before {
  transform: translateX(25px);
}

/* Rounded container with padding */
.custom-switch-drop.rounded-3 {
  border-radius: 25px;
  padding-bottom: 0.25rem; 
}

/* Additional styles for the custom "bg-green-input" class */
.bg-green-input {
  background-color: #d4edda !important; /* Light green background for the container when active */
  border-color: #c3e6cb !important; /* Matching border color */
}
.custom-switch-drop .form-check-input:checked {
  background-color: #10D078; 
  border-color: #10D078; 
}
.date-notif{
  font-size: 10px!important;
  color: black;
  font-weight: 700;
}
.goal-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px white solid;
  background: transparent; /* Ajout d'un fond noir pour mieux voir l'animation */
}

.goal-text {
  font-size: 7rem;
  font-weight: 700;
  color: white;
  opacity: 0;
  transition: all 4s ease-in-out;
  position: absolute;
  white-space: nowrap; /* Empêche le texte de se wrapper */
}

.animate-goal {
  animation: goalAnimation 20s ease-in-out infinite; /* Changé en infinite pour une boucle continue */
}

/* Updated keyframes with increased translateX for more space and faster movement */
/* @keyframes goalAnimation {
  0%, 100% {
    transform: translate(30%, -10%) rotate(0deg) translateX(0) translateY(0px);
    opacity: 1;
  }
  10% {
    transform: translate(30%, -10%) rotate(36deg) translateX(80px) translateY(80px);
    opacity: 1;
  }
  20% {
    transform: translate(30%, -10%) rotate(72deg) translateX(100px) translateY(160px);
    opacity: 1;
  }
  30% {
    transform: translate(30%, -10%) rotate(108deg) translateX(220px) translateY(240px);
    opacity: 1;
  }
  40% {
    transform: translate(30%, -10%) rotate(144deg) translateX(150px) translateY(160px);
    opacity: 1;
  }
  50% {
    transform: translate(30%, -10%) rotate(180deg) translateX(220px) translateY(80px);
    opacity: 1;
  }
  60% {
    transform: translate(30%, -10%) rotate(216deg) translateX(300px) translateY(0px);
    opacity: 1;
  }
  70% {
    transform: translate(30%, -10%) rotate(252deg) translateX(150px) translateY(80px);
    opacity: 1;
  }
  80% {
    transform: translate(30%, -10%) rotate(288deg) translateX(220px) translateY(160px);
    opacity: 1;
  }
  90% {
    transform: translate(30%, -10%) rotate(324deg) translateX(300px) translateY(0px);
    opacity: 1;
  }
} */

@keyframes goalAnimation {
  0%, 100% {
    transform: translate(0%, 0%) translateX(0px) translateY(0px);
    opacity: 1;
  }
  10% {
    transform: translate(0%, 0%) translateX(-450px) translateY(-250px);
    opacity: 1;
  }
  20% {
    transform: translate(0%, 0%) translateX(-225px) translateY(250px);
    opacity: 1;
  }
  30% {
    transform: translate(0%, 0%) translateX(500px) translateY(-250px);
    opacity: 1;
  }
  40% {
    transform: translate(0%, 0%) translateX(225px) translateY(250px);
    opacity: 1;
  }
  50% {
    transform: translate(0%, -50%) translateX(-450px) translateY(-250px);
    opacity: 1;
  }
  60% {
    transform: translate(0%, 0%) translateX(450px) translateY(250px);
    opacity: 1;
  }
  70% {
    transform: translate(0%, 0%) translateX(-225px) translateY(-250px);
    opacity: 1;
  }
  80% {
    transform: translate(0%, 0%) translateX(500px) translateY(-250px);
    opacity: 1;
  }
  85% {
    transform: translate(0%, 0%) translateX(500px) translateY(-250px);
    opacity: 1;
  }
  90% {
    transform: translate(0%, 0%) translateX(225px) translateY(250px) rotate(360deg);
    opacity: 1;
  }
}



/* .element {
  animation: goalAnimation 20s infinite ease-in-out;
} */






/* .goal-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}
.goal-text {
  font-size: 15vw; 
  font-weight: 900;
  text-transform: uppercase;
  opacity: 0;
}
.goal-text span {
  display: inline-block;


  transform: scale(0) rotate(180deg);
  transition: all 0.5s ease-out;
}
@keyframes goalAnimation {
  0% { opacity: 0; transform: scale(0.5) translateY(-100vh); }
  20% { opacity: 1; transform: scale(1.2) translateY(0); }
  40% { transform: scale(1) translateX(-10vw) rotate(-10deg); }
  60% { transform: scale(1.5) translateX(10vw) rotate(10deg); }
  80% { transform: scale(1.2) translateY(-5vh); }
  100% { opacity: 0; transform: scale(2) translateY(100vh); }
}
.animate-goal {
  animation: goalAnimation 21s ease-in-out forwards;
}
.animate-goal span {
  animation: letterAnimation 21s ease-in-out forwards;
}
@keyframes letterAnimation {
  0% { transform: scale(0) rotate(180deg); }
  20% { transform: scale(1) rotate(0deg); }
  80% { transform: scale(1) rotate(0deg); }
  100% { transform: scale(0) rotate(-180deg); }
}
.animate-goal span:nth-child(2) { animation-delay: 0.1s; }
.animate-goal span:nth-child(3) { animation-delay: 0.2s; }
.animate-goal span:nth-child(4) { animation-delay: 0.3s; }
.animate-goal span:nth-child(5) { animation-delay: 0.4s; }

@keyframes goalAnimation {
  0% { transform: scale(0); opacity: 0; }
  50% { transform: scale(1.2); opacity: 1; }
  100% { transform: scale(1); opacity: 1; }
}

.goal-overlay {
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
 */




















.custom-switch .form-check-input {
  width: 53px !important;
  height: 27px;
  background-color: #ccc; /* Default gray background when deactivated */
  border-radius: 25px;
  position: relative;
  appearance: none;
  outline: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Styles for the activated switch */
.custom-switch .form-check-input:checked {
  background-color: #28a745; /* Green background when activated */
}

/* Styles for the switch knob */
.custom-switch .form-check-input::before {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 21px;
  height: 21px;
  background-color: #fff;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

/* Move the knob to the right when checked */
.custom-switch .form-check-input:checked::before {
  transform: translateX(25px);
}

/* Rounded container with padding */
.custom-switch.rounded-3 {
  border-radius: 25px;
  padding-bottom: 0.25rem; 
}

/* Additional styles for the custom "bg-green-input" class */
.bg-green-input {
  background-color: #d4edda !important; /* Light green background for the container when active */
  border-color: #c3e6cb !important; /* Matching border color */
}
.custom-switch .form-check-input:checked {
  background-color: #10D078; 
  border-color: #10D078; 
}
.bg-light-update{
  background-color: #ECECEC;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Deux colonnes de largeur égale */
  gap: 10px; /* Espace entre les éléments */
}

.grid-item {
  padding: 10px; /* Ajoutez du padding si nécessaire */
  box-sizing: border-box; /* Inclut les bordures et le padding dans la largeur totale */
}
.grid-item.full-width {
  grid-column: span 2; /* Prend la largeur entière (les deux colonnes) */
}
.btn-green{
  background-color: #25E87F !important;
}
.b-bottom-green{
  /* background-color: #25E87F !important; */
  border-bottom: 3px #10D078 solid;
}
.input-add{
  height: 45px;
}
.bg-red-table{
background-color: #b7e1cd !important;
}
.bg-green-table{
  background-color: #f8888a !important;
}
input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid #ccc;
  outline: none;
  cursor: pointer;
  background-color: rgb(25, 110, 36);
}

/* Couleur par défaut pour une case non cochée */
input[type="checkbox"]:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgb(255, 255, 255);
  display: flex;
  align-items: center; /* Centre verticalement */
  justify-content: center;
}


input[type="cequejeveu"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid #831919;
  outline: none;
  cursor: pointer;
}

/* Couleur par défaut pour une case non cochée */
input[type="cequejeveu"]:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(158, 27, 27, 0.616);
  display: flex;
  align-items: center; /* Centre verticalement */
  justify-content: center;
}

.custom-checkbox-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.custom-checkbox {
  width: 20px;
  height: 20px;
  border: 1px solid #bbbbbb;
  border-radius: 4px;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s;
}

.custom-checkbox.checked {
  background-color: #10d078;
  border-color: #10d078;
}

.custom-checkbox.checked::after {
  content: "✔";
  color: white;
  font-size: 14px;
}

/* Couleurs dynamiques en fonction de l'ID */
#checkbox2-1:checked:before {
  content: "✓";
  color: white;
  font-size: 14px;
  font-weight: bold;
  background-color: rgb(150, 95, 13);
}
/* Checkbox 2 : bleu quand cochée */
#checkbox2-2:checked:before {
  content: "✓";
  color: white;
  font-size: 14px;
  font-weight: bold;
  background-color: rgb(209, 209, 48);
}

/* Checkbox 3 : jaune quand cochée */
#checkbox2-3:checked:before {
  content: "✓";
  color: white;
  font-size: 14px;
  font-weight: bold;
  background-color: rgb(49, 172, 12);
}

/* Ajout d'autres règles pour d'autres cases à cocher */
#checkbox2-4:checked:before {
  content: "✓";
  color: white;
  font-size: 14px;
  font-weight: bold;
  background-color: rgb(113, 2, 131);
}

#checkbox2-5:checked:before {
  content: "✓";
  color: white;
  font-size: 14px;
  font-weight: bold;
  background-color: red;
}


.button-login {
  position: relative;
  padding: 12px 30px;
  background: #10d078;
  font-size: 17px;
  font-weight: 500;
  color: #181818;
  border: 3px solid #10d078;
  border-radius: 8px;
  box-shadow: 0 0 0 #10d0788c;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  width: 100%;
}

.star-1 {
  position: absolute;
  top: 20%;
  left: 20%;
  width: 25px;
  height: auto;
  filter: drop-shadow(0 0 0 #a3e4c2);
  z-index: -5;
  transition: all 1s cubic-bezier(0.05, 0.83, 0.43, 0.96);
}

.star-2 {
  position: absolute;
  top: 45%;
  left: 45%;
  width: 15px;
  height: auto;
  filter: drop-shadow(0 0 0 #8fd4b0);
  z-index: -5;
  transition: all 1s cubic-bezier(0, 0.4, 0, 1.01);
}

.star-3 {
  position: absolute;
  top: 40%;
  left: 40%;
  width: 5px;
  height: auto;
  filter: drop-shadow(0 0 0 #7bc49e);
  z-index: -5;
  transition: all 1s cubic-bezier(0, 0.4, 0, 1.01);
}

.star-4 {
  position: absolute;
  top: 20%;
  left: 40%;
  width: 8px;
  height: auto;
  filter: drop-shadow(0 0 0 #67b48c);
  z-index: -5;
  transition: all 0.8s cubic-bezier(0, 0.4, 0, 1.01);
}

.star-5 {
  position: absolute;
  top: 25%;
  left: 45%;
  width: 15px;
  height: auto;
  filter: drop-shadow(0 0 0 #53a47a);
  z-index: -5;
  transition: all 0.6s cubic-bezier(0, 0.4, 0, 1.01);
}

.star-6 {
  position: absolute;
  top: 5%;
  left: 50%;
  width: 5px;
  height: auto;
  filter: drop-shadow(0 0 0 #3f9468);
  z-index: -5;
  transition: all 0.8s ease;
}

.button-login:hover {
  background: transparent;
  color: #10d078;
  box-shadow: 0 0 25px #10d0788c;
}

.button-login:hover .star-1 {
  position: absolute;
  top: -80%;
  left: -30%;
  width: 25px;
  height: auto;
  filter: drop-shadow(0 0 10px #a3e4c2);
  z-index: 2;
}

.button-login:hover .star-2 {
  position: absolute;
  top: -25%;
  left: 10%;
  width: 15px;
  height: auto;
  filter: drop-shadow(0 0 10px #8fd4b0);
  z-index: 2;
}

.button-login:hover .star-3 {
  position: absolute;
  top: 55%;
  left: 25%;
  width: 5px;
  height: auto;
  filter: drop-shadow(0 0 10px #7bc49e);
  z-index: 2;
}

.button-login:hover .star-4 {
  position: absolute;
  top: 30%;
  left: 80%;
  width: 8px;
  height: auto;
  filter: drop-shadow(0 0 10px #67b48c);
  z-index: 2;
}

.button-login:hover .star-5 {
  position: absolute;
  top: 25%;
  left: 115%;
  width: 15px;
  height: auto;
  filter: drop-shadow(0 0 10px #53a47a);
  z-index: 2;
}

.button-login:hover .star-6 {
  position: absolute;
  top: 5%;
  left: 60%;
  width: 5px;
  height: auto;
  filter: drop-shadow(0 0 10px #3f9468);
  z-index: 2;
}

.fil0 {
  fill: #fffdef;
}

.corner-image {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px; /* Ajustez la taille selon vos besoins */
  height: auto;
}











.bg-Preuve{
  background-color: #fefeae;
}
.bg-Signé{
  background-color: #ffa500;
}
.bg-Encaissé{
  background-color: #10d078;
}
.bg-Report{
  background-color: #c393d6;
}
.bg-Annulé{
  background-color: #f78c8c;
}

.bg-dash-very-dark-green {
  background-color: #57bb8a !important; /* Exemple de vert très foncé */
}

.bg-dash-dark-green {
  background-color: #84cda9 !important; /* Exemple de vert foncé */
}

.bg-dash-light-green {
  background-color: #e0f2e9 !important; /* Exemple de vert clair */
}

.bg-dash-white {
  background-color: #FFFFFF !important; /* Blanc */
}

.bg-dash-light-red {
  background-color: #Ffd4d4 !important; /* Rouge clair */
}

.bg-dash-medium-red {
  background-color: #fd9090 !important; /* Rouge moyen */
}

.bg-dash-very-dark-red {
  background-color: #e16868 !important; /* Rouge très foncé */
}
.bg-signer-stats{
  background-color: #f9cb9c !important;
}

.bg-report-stats{
  background-color: #d5a6bd !important;
}
.bg-preuve-stats{
  background-color: #fff2cc !important;
}
.bg-encaisse-stats{
  background-color: #e2efda !important;
}
.bg-signer-stats-agent {
  background-color: rgba(255, 165, 0, 0.5) !important;
}
.bg-preuve-stats-agent {
  background-color: rgba(254, 254, 174, 0.5) !important;
}
.bg-encaisse-stats-agent {
  background-color: rgba(16, 208, 120, 0.5) !important;
}
.bg-total-stats{
  background-color: #6d9eeb !important;
}
.bg-danger-seller {
  background-color: rgba(254, 176, 25, 0.3) !important;
}
.bg-warning-seller {
  background-color: rgba(70, 135, 221, 0.3) !important; 
}
.bg-success-seller {
  background-color: rgba(92, 255, 166, 0.3) !important;
} 
.text-pbar {
font-size: 10px !important;
}
.active-link {
  background-color: #a51b1b; /* Active link background color */
  color: #333; /* Optional: change text color */
}
.sidebar-bot-title{
  font-size: 15px !important;
  margin-left: 67px;
  cursor: pointer;
  background-color: lightblue;
}
.custom-datepicker-popper {
  z-index: 1050;
  padding: 10px;
}
.custom-date-picker {
  z-index: 1000; /* ou tout autre valeur souhaitée */
}
.input_date_filter{
  height: 40px;
  width: 162px;
  font-size: 0.8rem;
  border:1px rgb(211, 211, 211) solid;
  padding-left: 5px;
  border-radius: 5px;
}
.datepicker-wrapper {
  position: relative;
  display: inline-block;
}

.input_date_filter {
  padding-right: 30px; /* Pour laisser de l'espace pour le SVG à droite */
}
.input-adapt{
  font-size: 0.7vw;
}
.input-search-utilisateurs{
  border-radius: 10px;
  line-height: 2px;
  padding: 0 15px ;
}
.input_date_filter::after {
  content: '';
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg xmlns%3D%22http%3A//www.w3.org/2000/svg%22 fill%3D%22%23000%22 viewBox%3D%220 0 16 16%22%3E%3Cpath d%3D%22M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1zm1-3a1 1 0 0 0-1 1v1h14V2a1 1 0 0 0-1-1H2z%22/%3E%3C/svg%3E');
  background-size: contain;
  pointer-events: none;
}
.trait-filtre{
  height: 1px;
  background-color: rgb(219, 219, 219);
}
.circle-tableau{
  font-size: 10px!important;
  padding: 3px;
}
.border-vertical-progressbar{
  background-color: black;
  height: 10px!important;
  width: 3px!important;
}
.target-progressbar{
  font-size: 14x !important;
}
.chargement-graph-agent{
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-responsive {
  flex-grow: 1;
  overflow-y: auto;
}
.no-border tr {
  border-bottom: none;
}
.no-border th, .no-border td {
  border-bottom: none !important;
}

.input-add{
  height: 45px !important;
}
.hover-effect {
  transition: transform 0.3s linear, box-shadow 0.3s linear;
}

.hover-effect:hover {
  transform: scale(0.95); /* Agrandit légèrement la div */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* Ajoute une ombre portée */
}


.apexcharts-bar-area{
  cursor: pointer!important
}
.circle-activite{
  background-color: #0051ff;

}
.textarea-container {
  position: relative;
  display: inline-block;
}

.auto-resizing-textarea {
  width: 100%;
  box-sizing: border-box;
  resize: none; /* Empêche l'utilisateur de redimensionner manuellement */
  overflow: hidden; /* Cache les barres de défilement */
  font-size: 16px;
  font-family: Arial, sans-serif;
  transition: height 0.2s;
  position: relative;
}

.textarea-button {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 30px;
  border: none;
  background-color: #007BFF;
  color: white;
  cursor: pointer;
  border-radius: 4px;
}

.textarea-button:hover {
  background-color: #0056b3;
}

.hidden-div {
  visibility: hidden;
  white-space: pre-wrap; /* Permet de prendre en compte les sauts de ligne */
  word-wrap: break-word;
  font-size: 16px;
  font-family: Arial, sans-serif;
  position: absolute;
  top: 0;
  left: -9999px;
  width: 100%;
  padding: 8px; /* Assurez-vous que le padding correspond à celui de la textarea */
  box-sizing: border-box;
}
.auto-resizing-textarea::placeholder {
  color: #999999; /* Couleur du placeholder */
  font-style: italic; /* Style de la police */
  opacity: 1; /* Pour certains navigateurs, ajustez l'opacité */
}
.bg-vert-whatsapp{
  background-color: #d9fdd3;
}
.conv-container {
  height: 100%; /* Ajustez selon vos besoins */
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  overflow: hidden; /* `react-scroll-to-bottom` gère le défilement */
}

.message {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  word-wrap: break-word; /* Permet de casser les mots longs */
  word-break: break-word; /* Support supplémentaire pour casser les mots */
  white-space: normal; /* S'assurer que le texte s'enroule normalement */
  max-width: 100%; /* Empêche les messages de dépasser la largeur du conteneur */
}

.bg-vert-whatsapp {
  background-color: #DCF8C6; /* Couleur verte typique de WhatsApp */
}

.bg-white {
  background-color: #FFFFFF;
}

/* Optionnel : styles supplémentaires pour les messages */
.message .comment-content {
  word-wrap: break-word;
  word-break: break-word;
  white-space: normal;
}

/* Adaptation pour les messages de l'utilisateur */
.ms-auto {
  margin-left: auto;
}
.border-orange{
  border: 1px solid #e8ca72
}
.btn-notes-update:hover{
  background-color: #e0e0e0;
  border-radius: 50%;
}
.btn-header:hover{
  background-color: white;
}
.color-vert-progress{
  color: #191a19;
}
.color-orange-progress{
  color: #191a19;
}
.color-sell{
  color: #258ae8!important;
}
.color-tlead{
  color: #25e866!important;
}
.color-ass_mng{
  color: #f1a2d2!important;
}
.color-manager{
  color: #E89D25!important;
}
.color-agt_supp{
  color: #da6ce9!important;
} 
.color-mng_supp{
  color: #e5e825!important;
}
.color-boss_supp{
  color: #e74d4d!important;
}
.color-admin{
  color: #000000!important;
}
.margintop-1{
  margin-top: 50px!important;
}
.margintop-2{
  margin-top: 10px!important;
}
.margintop-3{
  margin-top: 20px!important;
}
.margintop-4{
  margin-top: 30px!important;
}
.margintop-5{
  margin-top: 40px!important;
}
.tooltip-bar {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.graph-background {
  position: relative; /* Assurez-vous que le conteneur parent est positionné */
}

.graph-background::before,
.graph-background::after {
  content: "";
  position: absolute;
  width: 150px;
  height: 100px;
  background-repeat: no-repeat;
  background-size: contain;
}

.graph-background::before {
  background-image: url('https://imgairforcecrm.fra1.digitaloceanspaces.com/background/75fbe7e8-c111-45d7-9776-6ac80aecb98f.gif');
  top: 0;
  left: 70%;
  transform: translate(-100%, 50%) rotate(5deg);
  z-index: 0;
}

/* .graph-background::after {
  background-image: url('https://imgairforcecrm.fra1.digitaloceanspaces.com/background/ed0c897f-39cb-4db6-be45-f302f6acee4b.gif');
  top: 0;
  left: 50%;
  transform: translateX(50%) rotate(15deg);
  z-index: 0; 
} */
.first-div {
  border-top-left-radius: 10px; /* Ajustez la valeur selon vos besoins */
  border-bottom-left-radius: 10px; /* Ajustez la valeur selon vos besoins */
}
.first-div-top {
  border-top-left-radius: 10px; /* Ajustez la valeur selon vos besoins */
}
.last-div {
  border-top-right-radius: 10px; /* Ajustez la valeur selon vos besoins */
  border-bottom-right-radius: 10px; /* Ajustez la valeur selon vos besoins */
}
.form-check-input {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  cursor: pointer;
}
.border-bot-filter{
  border-bottom: 1px #b3b3b3 solid;
}
.text-archived{
  color: rgb(128, 128, 128)!important;
  font-size: small;
  font-weight: 400;
}

.btn-classique {
  background-color: white; /* Couleur de fond pour le bouton classique */
  color: black; /* Couleur du texte */
  border: none; /* Pas de bordure */
  padding: 10px 20px; /* Padding pour ajouter de l'espace à l'intérieur du bouton */
  margin: 5px; /* Margin pour ajouter de l'espace autour du bouton */
  border-radius: 5px; /* Bordure arrondie */
  font-size: 16px; /* Taille de la police */
  transition: background-color 0.3s ease; /* Transition pour l'effet de survol */
}

.btn-classique:hover {
  background-color: rgb(102, 102, 102);
  color:white /* Couleur de fond au survol */
}

.btn-pes {
  background-color: white; /* Couleur de fond pour le bouton PES */
  color: black; /* Couleur du texte */
  border: none; /* Pas de bordure */
  padding: 10px 20px; /* Padding pour ajouter de l'espace à l'intérieur du bouton */
  margin: 5px; /* Margin pour ajouter de l'espace autour du bouton */
  border-radius: 5px; /* Bordure arrondie */
  font-size: 16px; /* Taille de la police */
  transition: background-color 0.3s ease; /* Transition pour l'effet de survol */
}

.btn-pes:hover {
  background-color: rgb(102, 102, 102);
  color:white /* Couleur de fond au survol */
}
.list-pes li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  padding: 3px;
}
/* .list-pes li div:first-child {
  padding-right: 15px;
} */
.list-pes-hover li{
  display: flex;
  align-items: center;
  justify-content: end;
  border-bottom: 1px solid #ccc;
  padding: 3px;
}
.refresh-lead{
  border-radius: 7px;
  background-color: #ececec;
  padding: 5px 5px;
}
.text-pes-sel-2 li div:nth-child(2) {
  /* color: #01d737 !important; */
  font-weight: 700;
}
.text-pes-sel-2 li {
display: flex;
justify-content: space-between;
border-left: #ffffff 1px solid;
}
.pes-selected-seller {
  box-shadow: 0 0 10px 5px rgba(0, 143, 251, 1);
}

.pes-hover-seller {
  box-shadow: 0 0 10px 5px rgba(0, 210, 60, 1);
}

.zoom-icon {
  transition: transform 0.1s;
}

.zoom-icon:hover {
  transform: scale(1.5);
}


.list-pes li div:nth-child(2) {
  /* color: rgba(0, 143, 251); */
  font-weight: 700;
}
.text-sel-fifa {
  color: rgba(0, 143, 251);
  text-shadow: 0 0 5px rgba(0, 143, 251);
}

.text-hover-fifa {
  color: #01d737;
  text-shadow: 0 0 5px #01d737;
}
.border-selected {
  border: 2px solid green;
  box-shadow: 0 0 10px 5px rgba(0, 210, 60, 1);
  border-radius: 10px;
}
.bg-gradient-vert{
  background: linear-gradient(90deg, #02ff8d 0%, #10d078 100%);

}
.bg-gardient-vertlight{
  background: linear-gradient(90deg, #02ff8d 0%, #10d078 100%);
}
.bg-vertlight{
  background-color: #a5ffd7!important;
}

.modal-content {
  position: relative; /* Ensure positioning context */
  z-index: 1050; /* Default Bootstrap modal z-index */
}

.message-sent-container {
  position: relative;
  z-index: 1060; /* Higher than modal to appear on top */
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 180px;
}
.message-sent {
  text-align: center;
  font-weight: bold;
  animation: shrinkFade 2s forwards;
}


@keyframes shrinkFade {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.5);
  }
}
.center-flex{
  display: flex;
  justify-content: center;
  align-items: center;
}
.actual-ip-container {
  transition: opacity 1.5s ease, height 1.5s ease;
  overflow: hidden;
}

.actual-ip-container.show {
  opacity: 1;
  height: auto;
}

.actual-ip-container.hide {
  opacity: 0;
  height: 0;
}
.animated-div {
  transition: opacity 0.5s ease, height 0.5s ease;
  opacity: 1;
  height: auto;
}

.show-history{
  height: 240px;
  /* border: 1px grey solid; */
  border-radius: 5px;
  margin: 5px;
  background-color: white;
  max-height: 240px; /* Ajustez la hauteur selon vos besoins */
  overflow-y: auto;
}
.card-history{
  /* border: 1px grey solid; */
  border-radius: 5px;
  margin: 5px;
  background-color: white;
}

.card-history div {
  margin: 0;
  padding: 2px;
  /* border-bottom: 1px solid #ccc; */
  display: flex;
  background-color: #dcffef!important;
  color: #10d078;
}

.card-history div div{
  margin: 0 5px;
  padding: 0;
  /* border-bottom: 1px solid #ccc; */
  /* display: flex; */
  /* font-weight: 600; */
  color: #000;
}


.grid-container-up-lead{
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 16px; /* Ajustez l'espacement entre les éléments selon vos besoins */
  padding: 4px;
}

.grid-container-up-lead div {
  /* border: 1px solid #ccc; */
  padding: 5px;
  text-align: center;
  border-radius: 4px;
  font-weight: bold;
}


.rounded-circle-lead{
  height: 75px!important;
  width: 75px!important;
  border-radius: 50px;
  margin: auto;
}

.profil-lead.selected-lead{
  border-bottom: 4px solid #10d078;
}

.grid-item-lead.selected-lead{
  border-bottom: 4px solid #10d078;
}


.profil-lead.selected-lead:hover {
  background-color: rgba(16, 208, 120, 0.1);
}

.grid-item-lead:hover {
  background-color: rgba(16, 208, 120, 0.1);
}


.border-icon-call{
  cursor: pointer;
  background-color: white;
  height: 60px;
  width: 60px;
  border-radius: 10px;
}
.border-icon-call-2{
  cursor: pointer;
  background-color: #f7f7f7;
  height: 50px;
  width: 50px;
  border-radius: 10px;
}
.btn-icon-call-bottom{
  background-color: white;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-icon-call-red{
  background-color: #f16163;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.textearea-call{
  min-height: 150px!important;
}
.textearea-call::placeholder {
  color: red!important;
}


.custom-modal {
  width: 300px; /* Fixe la largeur */
  margin: auto; /* Centre horizontalement */
}

.modal-content{
  width: 300px;
}





.border-hover {
  border: 2px solid blue;
  box-shadow: 0 0 10px 5px rgba(0, 143, 251, 1);
  border-radius: 10px;
}
.bg-list-remplacent{
  background-color: #313432;
}
.text-card-1 {
  color: #d8d3a3;
  font-size: 14px;
  font-weight: 900;
  text-transform: uppercase;
  /* border-right: 1px solid #d8d3a3; */
}
 .text-card-2{
  color: #05ff71;
  font-size: 14px;
  font-weight: 900;
  text-transform: uppercase;
  /* border-right: 1px solid #05ff71; */
 }
 .text-card-3{
  color: black;
  font-size: 14px;
  font-weight: 900;
  text-transform: uppercase;
  /* border-right: 1px solid #173ed9; */
 }
 .text-card-4{
  color: #d8d3a3;
  font-size: 14px;
  font-weight: 900;
  text-transform: uppercase;
  /* border-right: 1px solid #d8d3a3; */
 }
 .text-card-5{
  color: #dfea93;
  font-size: 14px;
  font-weight: 900;
  text-transform: uppercase;
  /* border-right: 1px solid #dfea93; */
 }
 .text-card-reserve{
  color: #305f77;
  font-size: 12px;
  font-weight: 900;
  text-transform: uppercase;
  /* border-right: 1px solid #305f77; */
 }
 .text-card-remplacent{
  color: #fff6d0;
  font-size: 12px;
  font-weight: 900;
  text-transform: uppercase;
  /* border-right: 1px solid #fff6d0; */
 }
.bg-btn-retrait{
  background-color: #10d078!important;
  border-radius: 5px;
}
 .border-right-1{
  border-right: 1px solid #d8d3a3;
 }
  .border-right-2{
    border-right: 1px solid #05ff71;
  }
  .border-right-3{
    border-right: 1px solid #173ed9;
  }
  .border-right-4{
    border-right: 1px solid #d8d3a3;
  }
  .border-right-5{
    border-right: 1px solid #dfea93;
  }
  .hover-bg-black:hover {
    background-color: black;
    background: white;
  }
  
  .hover-svg-white:hover {
    fill: white;
    background: #000;
  }

  @keyframes rotateX {
    0% {
      transform: rotateX(0deg);
    }
    100% {
      transform: rotateX(360deg);
    }
  }
  
  /* .card-rotate > div {
    animation: rotateX 2s linear;
  } */


  .p-fifa-1{
    border-bottom: 1px solid #d8d3a3;
   }
    .p-fifa-2{
      border-bottom: 1px solid #05ff71;
    }
    .p-fifa-3{
      border-bottom: 1px solid #173ed9;
    }
    .p-fifa-4{
      border-bottom: 1px solid #d8d3a3;
    }
    .p-fifa-5{
      border-bottom: 1px solid #dfea93;
    }

    .m-fifa-1{
      border-top: 1px solid #d8d3a3;
     }
      .m-fifa-2{
        border-top: 1px solid #05ff71;
      }
      .m-fifa-3{
        border-top: 1px solid #173ed9;
      }
      .m-fifa-4{
        border-top: 1px solid #d8d3a3;
      }
      .m-fifa-5{
        border-top: 1px solid #dfea93;
      }
 
.p-fifa{
  border-bottom: 1px solid #d8d3a3;
}

.m-fifa{
  border-bottom: 1px solid #d8d3a3;
}

/* .border-card-bottom{
  border: #ffffff 1px solid;
  margin-top: 5pxx;
} */
.text-vertical-fifa {
  writing-mode: vertical-rl;

  transform: rotate(180deg);
  font-weight: bold;
  /* height: 100%;
  width: 100%; */
}
/* From Uiverse.io by xXJollyHAKERXx */ 
.spinner-fifa {
  background-image: linear-gradient(rgb(186, 66, 255) 35%,rgb(0, 225, 255));
  width: 100px;
  height: 100px;
  /* animation: spinning82341 1.7s linear infinite; */

  border-radius: 50px;
  filter: blur(1px);
  box-shadow: 0px -5px 20px 0px rgb(186, 66, 255), 0px 5px 20px 0px rgb(0, 225, 255);
}

.spinner1-fifa {
  background-color: rgb(36, 36, 36);
  width: 100px;
  height: 100px;
  border-radius: 50px;
  filter: blur(10px);
}

@keyframes spinning82341 {
  to {
    transform: rotate(360deg);
  }
}
/* Pour les navigateurs Webkit (Chrome, Safari, Edge) */
.bg-list-remplacent::-webkit-scrollbar {
  width: 10px; /* Largeur de la scrollbar */
}

.bg-list-remplacent::-webkit-scrollbar-track {
  background: #000000; /* Couleur de l'arrière-plan de la scrollbar */
}

.bg-list-remplacent::-webkit-scrollbar-thumb {
  background-color: #888; /* Couleur de la partie défilante */
  border-radius: 10px; /* Arrondir les coins de la barre */
  border: 2px solid #f1f1f1; /* Ajouter une bordure autour de la barre */
}

.bg-list-remplacent::-webkit-scrollbar-thumb:hover {
  background: #555; /* Couleur lors du survol */
}

/* Pour Firefox */
.bg-list-remplacent {
  scrollbar-width: thin; /* Largeur fine de la scrollbar */
  scrollbar-color: #888 #313432;
}
.card-container-fifa {
  position: relative;
}

.badge-top-right {
  position: absolute;
  top: -15px; /* Adjust this value to position the badge */
  left: -50px; /* Adjust this value to position the badge */
  background-color: transparent; /* Example background for the badge */
  border-radius: 50%;
  /* padding: 5px 10px; */
  font-weight: bold;
  font-size: 1.2em;
}
@keyframes slide-in {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.animate-slide-in {
  animation: slide-in 0.5s ease-in forwards;
}

.person-item {
  transition: transform 1.5s ease-in-out;
  position: absolute;
  width: 100%;
  height: 50px;
  left: 0;
}

.circle-fifa {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #05ff71;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
















/* ///////////////////////////////////////////////////////// */
body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: #111828; }

h1, .h1 {
  font-size: calc(1.35rem + 1.2vw); }
  @media (min-width: 1200px) {
    h1, .h1 {
      font-size: 2.25rem; } }

h2, .h2 {
  font-size: calc(1.3125rem + 0.75vw); }
  @media (min-width: 1200px) {
    h2, .h2 {
      font-size: 1.875rem; } }

h3, .h3 {
  font-size: calc(1.275rem + 0.3vw); }
  @media (min-width: 1200px) {
    h3, .h3 {
      font-size: 1.5rem; } }

h4, .h4 {
  font-size: 1.125rem; }

h5, .h5 {
  font-size: 1rem; }

h6, .h6 {
  font-size: 0.938rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul {
  padding-left: 2rem; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small, .small {
  font-size: 0.875em; }

mark, .mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg); }

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: var(--bs-link-color);
  text-decoration: underline; }
  a:hover {
    color: var(--bs-link-hover-color); }

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em; }

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem; }
  kbd kbd {
    padding: 0;
    font-size: 1em; }

figure {
  margin: 0 0 1rem; }

img,
svg {
  vertical-align: middle; }

table {
  caption-side: bottom;
  border-collapse: collapse; }

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #89879f;
  text-align: left; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0; }

label {
  display: inline-block; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }
  select:disabled {
    opacity: 1; }

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }
  button:not(:disabled),
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled) {
    cursor: pointer; }

::-moz-focus-inner {
  padding: 0;
  border-style: none; }

textarea {
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit; }
  @media (min-width: 1200px) {
    legend {
      font-size: 1.5rem; } }
  legend + * {
    clear: left; }

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0; }

::-webkit-inner-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield; }

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-color-swatch-wrapper {
  padding: 0; }

::file-selector-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

iframe {
  border: 0; }

summary {
  display: list-item;
  cursor: pointer; }

progress {
  vertical-align: baseline; }

[hidden] {
  display: none !important; }

.lead {
  font-size: 1.09375rem;
  font-weight: 300; }

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-1 {
      font-size: 5rem; } }

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-2 {
      font-size: 4.5rem; } }

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-3 {
      font-size: 4rem; } }

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-4 {
      font-size: 3.5rem; } }

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-5 {
      font-size: 3rem; } }

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-6 {
      font-size: 2.5rem; } }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 0.875em;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.09375rem; }
  .blockquote > :last-child {
    margin-bottom: 0; }

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #F4F4F5;
  border: 1px solid var(--bs-border-color);
  border-radius: 0.75rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 0.875em;
  color: #6c757d; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  --bs-gutter-x: 20px;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

@media (min-width: 1440) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1320px; } }

.row {
  --bs-gutter-x: 20px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x)); }
  .row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y); }

.col {
  flex: 1 0 0%; }

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto; }

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%; }

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%; }

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%; }

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%; }

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto; }

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%; }

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-3 {
  flex: 0 0 auto;
  width: 25%; }

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%; }

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%; }

.col-6 {
  flex: 0 0 auto;
  width: 50%; }

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%; }

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%; }

.col-9 {
  flex: 0 0 auto;
  width: 75%; }

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%; }

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%; }

.col-12 {
  flex: 0 0 auto;
  width: 100%; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

.g-0,
.gx-0 {
  --bs-gutter-x: 0; }

.g-0,
.gy-0 {
  --bs-gutter-y: 0; }

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem; }

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem; }

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem; }

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem; }

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem; }

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem; }

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem; }

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem; }

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem; }

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem; }

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%; }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0; }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0; }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem; }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem; }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem; }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem; }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem; }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem; }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem; }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem; }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem; }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%; }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0; }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0; }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem; }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem; }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem; }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem; }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem; }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem; }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem; }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem; }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem; }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%; }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0; }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0; }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem; }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem; }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem; }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem; }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem; }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem; }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem; }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem; }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem; }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%; }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0; }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0; }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem; }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem; }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem; }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 1440) {
  .col-xxl {
    flex: 1 0 0%; }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0; }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0; }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem; }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem; }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem; }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem; } }

.table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color); }
  .table > :not(caption) > * > * {
    padding: 0.5rem 0.5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg); }
  .table > tbody {
    vertical-align: inherit; }
  .table > thead {
    vertical-align: center; }

.table-group-divider {
  border-top: 2px solid currentcolor; }

.caption-top {
  caption-side: top; }

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem; }

.table-bordered > :not(caption) > * {
  border-width: 1px 0; }
  .table-bordered > :not(caption) > * > * {
    border-width: 0 1px; }

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0; }

.table-borderless > :not(:first-child) {
  border-top-width: 0; }

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color); }

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color); }

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color); }

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color); }

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #ecf5d1;
  --bs-table-border-color: #d4ddbc;
  --bs-table-striped-bg: #e0e9c7;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #d4ddbc;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #dae3c1;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #cfd1d4;
  --bs-table-border-color: #babcbf;
  --bs-table-striped-bg: #c5c7c9;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #babcbf;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfc1c4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #ddf6f0;
  --bs-table-border-color: #c7ddd8;
  --bs-table-striped-bg: #d2eae4;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c7ddd8;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #cce4de;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #e6edfe;
  --bs-table-border-color: #cfd5e5;
  --bs-table-striped-bg: #dbe1f1;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cfd5e5;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d5dbeb;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fdf5ce;
  --bs-table-border-color: #e4ddb9;
  --bs-table-striped-bg: #f0e9c4;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e4ddb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #eae3bf;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #ffe5e5;
  --bs-table-border-color: #e6cece;
  --bs-table-striped-bg: #f2dada;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6cece;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ecd4d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #EEEEEE;
  --bs-table-border-color: #d6d6d6;
  --bs-table-striped-bg: #e2e2e2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #d6d6d6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: gainsboro;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #333333;
  --bs-table-border-color: #474747;
  --bs-table-striped-bg: #3d3d3d;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #474747;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #424242;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1439.98) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

.form-label {
  margin-bottom: 0.5rem; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.09375rem; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.76563rem; }
  @media (min-width: 1200px) {
    .col-form-label-sm {
      font-size: 0.76562rem; } }

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #89879f; }

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #888888;
  background-color: #F4F4F5;
  background-clip: padding-box;
  border: 1px solid #E6E6E6;
  appearance: none;
  border-radius: 0.75rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control[type="file"] {
    overflow: hidden; }
    .form-control[type="file"]:not(:disabled):not([readonly]) {
      cursor: pointer; }
  .form-control:focus {
    color: #888888;
    background-color: #F4F4F5;
    border-color: #d0e78d;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(160, 207, 26, 0.25); }
  .form-control::-webkit-date-and-time-value {
    height: 1.5em; }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled {
    background-color: #e9ecef;
    opacity: 1; }
  .form-control::file-selector-button {
    padding: 0.375rem 0.75rem;
    margin: -0.375rem -0.75rem;
    margin-inline-end: 0.75rem;
    color: #888888;
    background-color: #e9ecef;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-control::file-selector-button {
        transition: none; } }
  .form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: #dde0e3; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #888888;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext:focus {
    outline: 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.76563rem;
  border-radius: 0.25rem; }
  @media (min-width: 1200px) {
    .form-control-sm {
      font-size: 0.76562rem; } }
  .form-control-sm::file-selector-button {
    padding: 0.25rem 0.5rem;
    margin: -0.25rem -0.5rem;
    margin-inline-end: 0.5rem; }

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  border-radius: 0.5rem; }
  .form-control-lg::file-selector-button {
    padding: 0.5rem 1rem;
    margin: -0.5rem -1rem;
    margin-inline-end: 1rem; }

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px); }

textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px); }

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px); }

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem; }
  .form-control-color:not(:disabled):not([readonly]) {
    cursor: pointer; }
  .form-control-color::-moz-color-swatch {
    border: 0 !important;
    border-radius: 0.75rem; }
  .form-control-color::-webkit-color-swatch {
    border-radius: 0.75rem; }
  .form-control-color.form-control-sm {
    height: calc(1.5em + 0.5rem + 2px); }
  .form-control-color.form-control-lg {
    height: calc(1.5em + 1rem + 2px); }

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #888888;
  background-color: #F4F4F5;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #E6E6E6;
  border-radius: 0.75rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none; }
  @media (prefers-reduced-motion: reduce) {
    .form-select {
      transition: none; } }
  .form-select:focus {
    border-color: #d0e78d;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(160, 207, 26, 0.25); }
  .form-select[multiple], .form-select[size]:not([size="1"]) {
    padding-right: 0.75rem;
    background-image: none; }
  .form-select:disabled {
    background-color: #e9ecef; }
  .form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #888888; }

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.76563rem;
  border-radius: 0.25rem; }
  @media (min-width: 1200px) {
    .form-select-sm {
      font-size: 0.76562rem; } }

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.09375rem;
  border-radius: 0.5rem; }

.form-check {
  display: block;
  min-height: 1.3125rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem; }
  .form-check .form-check-input {
    float: left;
    margin-left: -1.5em; }

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right; }
  .form-check-reverse .form-check-input {
    float: right;
    margin-right: -1.5em;
    margin-left: 0; }

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #F4F4F5;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  print-color-adjust: exact; }
  .form-check-input[type="checkbox"] {
    border-radius: 0.25em; }
  .form-check-input[type="radio"] {
    border-radius: 50%; }
  .form-check-input:active {
    filter: brightness(90%); }
  .form-check-input:focus {
    border-color: #d0e78d;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(160, 207, 26, 0.25); }
  .form-check-input:checked {
    background-color: #A0CF1A;
    border-color: #A0CF1A; }
    .form-check-input:checked[type="checkbox"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e"); }
    .form-check-input:checked[type="radio"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e"); }
  .form-check-input[type="checkbox"]:indeterminate {
    background-color: #A0CF1A;
    border-color: #A0CF1A;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e"); }
  .form-check-input:disabled {
    pointer-events: none;
    filter: none;
    opacity: 0.5; }
  .form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
    cursor: default;
    opacity: 0.5; }

.form-switch {
  padding-left: 2.5em; }
  .form-switch .form-check-input {
    width: 2em;
    margin-left: -2.5em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    background-position: left center;
    border-radius: 2em;
    transition: background-position 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-switch .form-check-input {
        transition: none; } }
    .form-switch .form-check-input:focus {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23d0e78d'/%3e%3c/svg%3e"); }
    .form-switch .form-check-input:checked {
      background-position: right center;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }
  .form-switch.form-check-reverse {
    padding-right: 2.5em;
    padding-left: 0; }
    .form-switch.form-check-reverse .form-check-input {
      margin-right: -2.5em;
      margin-left: 0; }

.form-check-inline {
  display: inline-block;
  margin-right: 1rem; }

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }
  .btn-check[disabled] + .btn, .btn-check:disabled + .btn {
    pointer-events: none;
    filter: none;
    opacity: 0.65; }

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .form-range:focus {
    outline: 0; }
    .form-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #F4F4F5, 0 0 0 0.25rem rgba(160, 207, 26, 0.25); }
    .form-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #F4F4F5, 0 0 0 0.25rem rgba(160, 207, 26, 0.25); }
  .form-range::-moz-focus-outer {
    border: 0; }
  .form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #A0CF1A;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-webkit-slider-thumb {
        transition: none; } }
    .form-range::-webkit-slider-thumb:active {
      background-color: #e3f1ba; }
  .form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #A0CF1A;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-moz-range-thumb {
        transition: none; } }
    .form-range::-moz-range-thumb:active {
      background-color: #e3f1ba; }
  .form-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .form-range:disabled {
    pointer-events: none; }
    .form-range:disabled::-webkit-slider-thumb {
      background-color: #adb5bd; }
    .form-range:disabled::-moz-range-thumb {
      background-color: #adb5bd; }

.form-floating {
  position: relative; }
  .form-floating > .form-control,
  .form-floating > .form-control-plaintext,
  .form-floating > .form-select {
    height: calc(3.5rem + 2px);
    line-height: 1.25; }
  .form-floating > label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1rem 0.75rem;
    overflow: hidden;
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-floating > label {
        transition: none; } }
  .form-floating > .form-control,
  .form-floating > .form-control-plaintext {
    padding: 1rem 0.75rem; }
    .form-floating > .form-control::placeholder,
    .form-floating > .form-control-plaintext::placeholder {
      color: transparent; }
    .form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
    .form-floating > .form-control-plaintext:focus,
    .form-floating > .form-control-plaintext:not(:placeholder-shown) {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
    .form-floating > .form-control:-webkit-autofill,
    .form-floating > .form-control-plaintext:-webkit-autofill {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
  .form-floating > .form-select {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem; }
  .form-floating > .form-control:focus ~ label,
  .form-floating > .form-control:not(:placeholder-shown) ~ label,
  .form-floating > .form-control-plaintext ~ label,
  .form-floating > .form-select ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
  .form-floating > .form-control:-webkit-autofill ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
  .form-floating > .form-control-plaintext ~ label {
    border-width: 1px 0; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-select,
  .input-group > .form-floating {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0; }
  .input-group > .form-control:focus,
  .input-group > .form-select:focus,
  .input-group > .form-floating:focus-within {
    z-index: 5; }
  .input-group .btn {
    position: relative;
    z-index: 2; }
    .input-group .btn:focus {
      z-index: 5; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #888888;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #E6E6E6;
  border-radius: 0.75rem; }

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  border-radius: 0.5rem; }

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.76563rem;
  border-radius: 0.25rem; }
  @media (min-width: 1200px) {
    .input-group-sm > .form-control,
    .input-group-sm > .form-select,
    .input-group-sm > .input-group-text,
    .input-group-sm > .btn {
      font-size: 0.76562rem; } }

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem; }

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group.has-validation > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4),
.input-group.has-validation > .form-floating:nth-last-child(n + 3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n + 3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #53D0B2; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.76563rem;
  color: #000;
  background-color: rgba(83, 208, 178, 0.9);
  border-radius: 0.75rem; }
  @media (min-width: 1200px) {
    .valid-tooltip {
      font-size: 0.76562rem; } }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #53D0B2;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2353D0B2' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #53D0B2;
    box-shadow: 0 0 0 0.25rem rgba(83, 208, 178, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #53D0B2; }
  .was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
    padding-right: 4.125rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2353D0B2' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-position: right 0.75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-select:valid:focus, .form-select.is-valid:focus {
    border-color: #53D0B2;
    box-shadow: 0 0 0 0.25rem rgba(83, 208, 178, 0.25); }

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem)); }

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #53D0B2; }
  .was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
    background-color: #53D0B2; }
  .was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
    box-shadow: 0 0 0 0.25rem rgba(83, 208, 178, 0.25); }
  .was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
    color: #53D0B2; }

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em; }

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid, .was-validated
.input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid, .was-validated
.input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3; }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #FF7C7C; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.76563rem;
  color: #000;
  background-color: rgba(255, 124, 124, 0.9);
  border-radius: 0.75rem; }
  @media (min-width: 1200px) {
    .invalid-tooltip {
      font-size: 0.76562rem; } }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #FF7C7C;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23FF7C7C'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23FF7C7C' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #FF7C7C;
    box-shadow: 0 0 0 0.25rem rgba(255, 124, 124, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #FF7C7C; }
  .was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
    padding-right: 4.125rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23FF7C7C'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23FF7C7C' stroke='none'/%3e%3c/svg%3e");
    background-position: right 0.75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
    border-color: #FF7C7C;
    box-shadow: 0 0 0 0.25rem rgba(255, 124, 124, 0.25); }

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem)); }

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #FF7C7C; }
  .was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
    background-color: #FF7C7C; }
  .was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 124, 124, 0.25); }
  .was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
    color: #FF7C7C; }

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em; }

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid, .was-validated
.input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid, .was-validated
.input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4; }

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #888888;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.75rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color); }
  .btn-check + .btn:hover {
    color: var(--bs-btn-color);
    background-color: var(--bs-btn-bg);
    border-color: var(--bs-btn-border-color); }
  .btn:focus-visible {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
    outline: 0;
    box-shadow: var(--bs-btn-focus-box-shadow); }
  .btn-check:focus-visible + .btn {
    border-color: var(--bs-btn-hover-border-color);
    outline: 0;
    box-shadow: var(--bs-btn-focus-box-shadow); }
  .btn-check:checked + .btn,
  :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
    color: var(--bs-btn-active-color);
    background-color: var(--bs-btn-active-bg);
    border-color: var(--bs-btn-active-border-color); }
    .btn-check:checked + .btn:focus-visible,
    :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
      box-shadow: var(--bs-btn-focus-box-shadow); }
  .btn:disabled, .btn.disabled,
  fieldset:disabled .btn {
    color: var(--bs-btn-disabled-color);
    pointer-events: none;
    background-color: var(--bs-btn-disabled-bg);
    border-color: var(--bs-btn-disabled-border-color);
    opacity: var(--bs-btn-disabled-opacity); }

.btn-primary {
  --bs-btn-color: #000;
  --bs-btn-bg: #A0CF1A;
  --bs-btn-border-color: #A0CF1A;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #aed63c;
  --bs-btn-hover-border-color: #aad431;
  --bs-btn-focus-shadow-rgb: 136, 176, 22;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #b3d948;
  --bs-btn-active-border-color: #aad431;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #A0CF1A;
  --bs-btn-disabled-border-color: #A0CF1A; }

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #111828;
  --bs-btn-border-color: #111828;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0e1422;
  --bs-btn-hover-border-color: #0e1320;
  --bs-btn-focus-shadow-rgb: 53, 59, 72;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0e1320;
  --bs-btn-active-border-color: #0d121e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #111828;
  --bs-btn-disabled-border-color: #111828; }

.btn-success {
  --bs-btn-color: #000;
  --bs-btn-bg: #53D0B2;
  --bs-btn-border-color: #53D0B2;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #6dd7be;
  --bs-btn-hover-border-color: #64d5ba;
  --bs-btn-focus-shadow-rgb: 71, 177, 151;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #75d9c1;
  --bs-btn-active-border-color: #64d5ba;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #53D0B2;
  --bs-btn-disabled-border-color: #53D0B2; }

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #81A5F9;
  --bs-btn-border-color: #81A5F9;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #94b3fa;
  --bs-btn-hover-border-color: #8eaefa;
  --bs-btn-focus-shadow-rgb: 110, 140, 212;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #9ab7fa;
  --bs-btn-active-border-color: #8eaefa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #81A5F9;
  --bs-btn-disabled-border-color: #81A5F9; }

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #F4CF0C;
  --bs-btn-border-color: #F4CF0C;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f6d630;
  --bs-btn-hover-border-color: #f5d424;
  --bs-btn-focus-shadow-rgb: 207, 176, 10;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f6d93d;
  --bs-btn-active-border-color: #f5d424;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #F4CF0C;
  --bs-btn-disabled-border-color: #F4CF0C; }

.btn-danger {
  --bs-btn-color: #000;
  --bs-btn-bg: #FF7C7C;
  --bs-btn-border-color: #FF7C7C;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ff9090;
  --bs-btn-hover-border-color: #ff8989;
  --bs-btn-focus-shadow-rgb: 217, 105, 105;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ff9696;
  --bs-btn-active-border-color: #ff8989;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #FF7C7C;
  --bs-btn-disabled-border-color: #FF7C7C; }

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #EEEEEE;
  --bs-btn-border-color: #EEEEEE;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #cacaca;
  --bs-btn-hover-border-color: #bebebe;
  --bs-btn-focus-shadow-rgb: 202, 202, 202;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #bebebe;
  --bs-btn-active-border-color: #b3b3b3;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #EEEEEE;
  --bs-btn-disabled-border-color: #EEEEEE; }

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #333333;
  --bs-btn-border-color: #333333;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #525252;
  --bs-btn-hover-border-color: #474747;
  --bs-btn-focus-shadow-rgb: 82, 82, 82;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #5c5c5c;
  --bs-btn-active-border-color: #474747;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #333333;
  --bs-btn-disabled-border-color: #333333; }

.btn-outline-primary {
  --bs-btn-color: #A0CF1A;
  --bs-btn-border-color: #A0CF1A;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #A0CF1A;
  --bs-btn-hover-border-color: #A0CF1A;
  --bs-btn-focus-shadow-rgb: 160, 207, 26;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #A0CF1A;
  --bs-btn-active-border-color: #A0CF1A;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #A0CF1A;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #A0CF1A;
  --bs-gradient: none; }

.btn-outline-secondary {
  --bs-btn-color: #111828;
  --bs-btn-border-color: #111828;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #111828;
  --bs-btn-hover-border-color: #111828;
  --bs-btn-focus-shadow-rgb: 17, 24, 40;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #111828;
  --bs-btn-active-border-color: #111828;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #111828;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #111828;
  --bs-gradient: none; }

.btn-outline-success {
  --bs-btn-color: #53D0B2;
  --bs-btn-border-color: #53D0B2;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #53D0B2;
  --bs-btn-hover-border-color: #53D0B2;
  --bs-btn-focus-shadow-rgb: 83, 208, 178;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #53D0B2;
  --bs-btn-active-border-color: #53D0B2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #53D0B2;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #53D0B2;
  --bs-gradient: none; }

.btn-outline-info {
  --bs-btn-color: #81A5F9;
  --bs-btn-border-color: #81A5F9;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #81A5F9;
  --bs-btn-hover-border-color: #81A5F9;
  --bs-btn-focus-shadow-rgb: 129, 165, 249;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #81A5F9;
  --bs-btn-active-border-color: #81A5F9;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #81A5F9;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #81A5F9;
  --bs-gradient: none; }

.btn-outline-warning {
  --bs-btn-color: #F4CF0C;
  --bs-btn-border-color: #F4CF0C;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #F4CF0C;
  --bs-btn-hover-border-color: #F4CF0C;
  --bs-btn-focus-shadow-rgb: 244, 207, 12;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #F4CF0C;
  --bs-btn-active-border-color: #F4CF0C;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #F4CF0C;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #F4CF0C;
  --bs-gradient: none; }

.btn-outline-danger {
  --bs-btn-color: #FF7C7C;
  --bs-btn-border-color: #FF7C7C;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #FF7C7C;
  --bs-btn-hover-border-color: #FF7C7C;
  --bs-btn-focus-shadow-rgb: 255, 124, 124;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #FF7C7C;
  --bs-btn-active-border-color: #FF7C7C;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FF7C7C;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #FF7C7C;
  --bs-gradient: none; }

.btn-outline-light {
  --bs-btn-color: #EEEEEE;
  --bs-btn-border-color: #EEEEEE;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #EEEEEE;
  --bs-btn-hover-border-color: #EEEEEE;
  --bs-btn-focus-shadow-rgb: 238, 238, 238;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #EEEEEE;
  --bs-btn-active-border-color: #EEEEEE;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #EEEEEE;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #EEEEEE;
  --bs-gradient: none; }

.btn-outline-dark {
  --bs-btn-color: #333333;
  --bs-btn-border-color: #333333;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #333333;
  --bs-btn-hover-border-color: #333333;
  --bs-btn-focus-shadow-rgb: 51, 51, 51;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #333333;
  --bs-btn-active-border-color: #333333;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #333333;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #333333;
  --bs-gradient: none; }

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 136, 176, 22;
  text-decoration: underline; }
  .btn-link:focus-visible {
    color: var(--bs-btn-color); }
  .btn-link:hover {
    color: var(--bs-btn-hover-color); }

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.09375rem;
  --bs-btn-border-radius: 0.5rem; }

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.76563rem;
  --bs-btn-border-radius: 0.25rem; }
  @media (min-width: 1200px) {
    .btn-sm, .btn-group-sm > .btn {
      --bs-btn-font-size: 0.76562rem; } }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }
  .collapsing.collapse-horizontal {
    width: 0;
    height: auto;
    transition: width 0.35s ease; }
    @media (prefers-reduced-motion: reduce) {
      .collapsing.collapse-horizontal {
        transition: none; } }

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 0.875rem;
  --bs-dropdown-color: #888888;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: 0.75rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(0.75rem - 1px);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-dropdown-link-color: #212529;
  --bs-dropdown-link-hover-color: #1e2125;
  --bs-dropdown-link-hover-bg: #e9ecef;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #A0CF1A;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius); }
  .dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: var(--bs-dropdown-spacer); }

.dropdown-menu-start {
  --bs-position: start; }
  .dropdown-menu-start[data-bs-popper] {
    right: auto;
    left: 0; }

.dropdown-menu-end {
  --bs-position: end; }
  .dropdown-menu-end[data-bs-popper] {
    right: 0;
    left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start; }
    .dropdown-menu-sm-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-sm-end {
    --bs-position: end; }
    .dropdown-menu-sm-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start; }
    .dropdown-menu-md-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-md-end {
    --bs-position: end; }
    .dropdown-menu-md-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start; }
    .dropdown-menu-lg-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-lg-end {
    --bs-position: end; }
    .dropdown-menu-lg-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start; }
    .dropdown-menu-xl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xl-end {
    --bs-position: end; }
    .dropdown-menu-xl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1440) {
  .dropdown-menu-xxl-start {
    --bs-position: start; }
    .dropdown-menu-xxl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xxl-end {
    --bs-position: end; }
    .dropdown-menu-xxl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer); }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer); }

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropend .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-toggle::after {
  vertical-align: 0; }

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer); }

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropstart .dropdown-toggle::after {
  display: none; }

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropstart .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: var(--bs-dropdown-link-hover-color);
    background-color: var(--bs-dropdown-link-hover-bg); }
  .dropdown-item.active, .dropdown-item:active {
    color: var(--bs-dropdown-link-active-color);
    text-decoration: none;
    background-color: var(--bs-dropdown-link-active-bg); }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: var(--bs-dropdown-link-disabled-color);
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.76563rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap; }
  @media (min-width: 1200px) {
    .dropdown-header {
      font-size: 0.76562rem; } }

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color); }

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #A0CF1A;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
  .btn-group > .btn-check:checked + .btn,
  .btn-group > .btn-check:focus + .btn,
  .btn-group > .btn:hover,
  .btn-group > .btn:focus,
  .btn-group > .btn:active,
  .btn-group > .btn.active,
  .btn-group-vertical > .btn-check:checked + .btn,
  .btn-group-vertical > .btn-check:focus + .btn,
  .btn-group-vertical > .btn:hover,
  .btn-group-vertical > .btn:focus,
  .btn-group-vertical > .btn:active,
  .btn-group-vertical > .btn.active {
    z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group {
  border-radius: 0.75rem; }
  .btn-group > :not(.btn-check:first-child) + .btn,
  .btn-group > .btn-group:not(:first-child) {
    margin-left: -1px; }
  .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group > .btn.dropdown-toggle-split:first-child,
  .btn-group > .btn-group:not(:last-child) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .btn-group > .btn:nth-child(n + 3),
  .btn-group > :not(.btn-check) + .btn,
  .btn-group > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropend .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropstart .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn ~ .btn,
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .nav-link {
      transition: none; } }
  .nav-link:hover, .nav-link:focus {
    color: var(--bs-nav-link-hover-color); }
  .nav-link.disabled {
    color: var(--bs-nav-link-disabled-color);
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #dee2e6;
  --bs-nav-tabs-border-radius: 0.75rem;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #F4F4F5;
  --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #F4F4F5;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color); }
  .nav-tabs .nav-link {
    margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
    background: none;
    border: var(--bs-nav-tabs-border-width) solid transparent;
    border-top-left-radius: var(--bs-nav-tabs-border-radius);
    border-top-right-radius: var(--bs-nav-tabs-border-radius); }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      isolation: isolate;
      border-color: var(--bs-nav-tabs-link-hover-border-color); }
    .nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
      color: var(--bs-nav-link-disabled-color);
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: var(--bs-nav-tabs-link-active-color);
    background-color: var(--bs-nav-tabs-link-active-bg);
    border-color: var(--bs-nav-tabs-link-active-border-color); }
  .nav-tabs .dropdown-menu {
    margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills {
  --bs-nav-pills-border-radius: 0.75rem;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #A0CF1A; }
  .nav-pills .nav-link {
    background: none;
    border: 0;
    border-radius: var(--bs-nav-pills-border-radius); }
    .nav-pills .nav-link:disabled {
      color: var(--bs-nav-link-disabled-color);
      background-color: transparent;
      border-color: transparent; }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: var(--bs-nav-pills-link-active-color);
    background-color: var(--bs-nav-pills-link-active-bg); }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(0, 0, 0, 0.55);
  --bs-navbar-hover-color: rgba(0, 0, 0, 0.7);
  --bs-navbar-disabled-color: rgba(0, 0, 0, 0.3);
  --bs-navbar-active-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-padding-y: 0.33594rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.09375rem;
  --bs-navbar-brand-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-hover-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.09375rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(0, 0, 0, 0.1);
  --bs-navbar-toggler-border-radius: 0.75rem;
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x); }
  .navbar > .container,
  .navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    color: var(--bs-navbar-brand-hover-color); }

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .show > .nav-link,
  .navbar-nav .nav-link.active {
    color: var(--bs-navbar-active-color); }
  .navbar-nav .dropdown-menu {
    position: static; }

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color); }
  .navbar-text a,
  .navbar-text a:hover,
  .navbar-text a:focus {
    color: var(--bs-navbar-active-color); }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition); }
  @media (prefers-reduced-motion: reduce) {
    .navbar-toggler {
      transition: none; } }
  .navbar-toggler:hover {
    text-decoration: none; }
  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width); }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%; }

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto; }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; }
    .navbar-expand-sm .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-sm .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-sm .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; }
    .navbar-expand-md .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-md .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-md .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; }
    .navbar-expand-lg .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-lg .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-lg .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; }
    .navbar-expand-xl .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-xl .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-xl .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 1440) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-xxl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; }
    .navbar-expand-xxl .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-xxl .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-xxl .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: var(--bs-navbar-nav-link-padding-x);
      padding-left: var(--bs-navbar-nav-link-padding-x); }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }
  .navbar-expand .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none; }
    .navbar-expand .offcanvas .offcanvas-header {
      display: none; }
    .navbar-expand .offcanvas .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; }

.navbar-dark {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-border-width: 1px;
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: 0.75rem;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(0.75rem - 1px);
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(0, 0, 0, 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius); }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: var(--bs-card-inner-border-radius);
      border-top-right-radius: var(--bs-card-inner-border-radius); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: var(--bs-card-inner-border-radius);
      border-bottom-left-radius: var(--bs-card-inner-border-radius); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color); }

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y); }

.card-subtitle {
  margin-top: calc(-.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x); }

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color); }
  .card-header:first-child {
    border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0; }

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color); }
  .card-footer:last-child {
    border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius); }

.card-header-tabs {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0; }
  .card-header-tabs .nav-link.active {
    background-color: var(--bs-card-bg);
    border-bottom-color: var(--bs-card-bg); }

.card-header-pills {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x)); }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius); }

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius); }

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin); }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.accordion {
  --bs-accordion-color: #888888;
  --bs-accordion-bg: #F4F4F5;
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: 0.75rem;
  --bs-accordion-inner-border-radius: calc(0.75rem - 1px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: #888888;
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23888888'%3e%3cpath fillRule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2390ba17'%3e%3cpath fillRule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #d0e78d;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(160, 207, 26, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #90ba17;
  --bs-accordion-active-bg: #f6fae8; }

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 0.875rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition); }
  @media (prefers-reduced-motion: reduce) {
    .accordion-button {
      transition: none; } }
  .accordion-button:not(.collapsed) {
    color: var(--bs-accordion-active-color);
    background-color: var(--bs-accordion-active-bg);
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color); }
    .accordion-button:not(.collapsed)::after {
      background-image: var(--bs-accordion-btn-active-icon);
      transform: var(--bs-accordion-btn-icon-transform); }
  .accordion-button::after {
    flex-shrink: 0;
    width: var(--bs-accordion-btn-icon-width);
    height: var(--bs-accordion-btn-icon-width);
    margin-left: auto;
    content: "";
    background-image: var(--bs-accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-icon-width);
    transition: var(--bs-accordion-btn-icon-transition); }
    @media (prefers-reduced-motion: reduce) {
      .accordion-button::after {
        transition: none; } }
  .accordion-button:hover {
    z-index: 2; }
  .accordion-button:focus {
    z-index: 3;
    border-color: var(--bs-accordion-btn-focus-border-color);
    outline: 0;
    box-shadow: var(--bs-accordion-btn-focus-box-shadow); }

.accordion-header {
  margin-bottom: 0; }

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color); }
  .accordion-item:first-of-type {
    border-top-left-radius: var(--bs-accordion-border-radius);
    border-top-right-radius: var(--bs-accordion-border-radius); }
    .accordion-item:first-of-type .accordion-button {
      border-top-left-radius: var(--bs-accordion-inner-border-radius);
      border-top-right-radius: var(--bs-accordion-inner-border-radius); }
  .accordion-item:not(:first-of-type) {
    border-top: 0; }
  .accordion-item:last-of-type {
    border-bottom-right-radius: var(--bs-accordion-border-radius);
    border-bottom-left-radius: var(--bs-accordion-border-radius); }
    .accordion-item:last-of-type .accordion-button.collapsed {
      border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
      border-bottom-left-radius: var(--bs-accordion-inner-border-radius); }
    .accordion-item:last-of-type .accordion-collapse {
      border-bottom-right-radius: var(--bs-accordion-border-radius);
      border-bottom-left-radius: var(--bs-accordion-border-radius); }

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x); }

.accordion-flush .accordion-collapse {
  border-width: 0; }

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .accordion-flush .accordion-item:first-child {
    border-top: 0; }
  .accordion-flush .accordion-item:last-child {
    border-bottom: 0; }
  .accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
    border-radius: 0; }

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: #6c757d;
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius); }

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x); }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: var(--bs-breadcrumb-item-padding-x);
    color: var(--bs-breadcrumb-divider-color);
    content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */; }

.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color); }

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: #fff;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: #dee2e6;
  --bs-pagination-border-radius: 0.75rem;
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: #e9ecef;
  --bs-pagination-hover-border-color: #dee2e6;
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: #e9ecef;
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(160, 207, 26, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #A0CF1A;
  --bs-pagination-active-border-color: #A0CF1A;
  --bs-pagination-disabled-color: #6c757d;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: #dee2e6;
  display: flex;
  padding-left: 0;
  list-style: none; }

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .page-link {
      transition: none; } }
  .page-link:hover {
    z-index: 2;
    color: var(--bs-pagination-hover-color);
    background-color: var(--bs-pagination-hover-bg);
    border-color: var(--bs-pagination-hover-border-color); }
  .page-link:focus {
    z-index: 3;
    color: var(--bs-pagination-focus-color);
    background-color: var(--bs-pagination-focus-bg);
    outline: 0;
    box-shadow: var(--bs-pagination-focus-box-shadow); }
  .page-link.active,
  .active > .page-link {
    z-index: 3;
    color: var(--bs-pagination-active-color);
    background-color: var(--bs-pagination-active-bg);
    border-color: var(--bs-pagination-active-border-color); }
  .page-link.disabled,
  .disabled > .page-link {
    color: var(--bs-pagination-disabled-color);
    pointer-events: none;
    background-color: var(--bs-pagination-disabled-bg);
    border-color: var(--bs-pagination-disabled-border-color); }

.page-item:not(:first-child) .page-link {
  margin-left: -1px; }

.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius); }

.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius); }

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.09375rem;
  --bs-pagination-border-radius: 0.5rem; }

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.76563rem;
  --bs-pagination-border-radius: 0.25rem; }
  @media (min-width: 1200px) {
    .pagination-sm {
      --bs-pagination-font-size: 0.76562rem; } }

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: 0.75rem;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius); }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: 0.75rem;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius); }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 3rem; }
  .alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 1.25rem 1rem; }

.alert-primary {
  --bs-alert-color: #40530a;
  --bs-alert-bg: #ecf5d1;
  --bs-alert-border-color: #e3f1ba; }
  .alert-primary .alert-link {
    color: #334208; }

.alert-secondary {
  --bs-alert-color: #0a0e18;
  --bs-alert-bg: #cfd1d4;
  --bs-alert-border-color: #b8babf; }
  .alert-secondary .alert-link {
    color: #080b13; }

.alert-success {
  --bs-alert-color: #215347;
  --bs-alert-bg: #ddf6f0;
  --bs-alert-border-color: #cbf1e8; }
  .alert-success .alert-link {
    color: #1a4239; }

.alert-info {
  --bs-alert-color: #4d6395;
  --bs-alert-bg: #e6edfe;
  --bs-alert-border-color: #d9e4fd; }
  .alert-info .alert-link {
    color: #3e4f77; }

.alert-warning {
  --bs-alert-color: #625305;
  --bs-alert-bg: #fdf5ce;
  --bs-alert-border-color: #fcf1b6; }
  .alert-warning .alert-link {
    color: #4e4204; }

.alert-danger {
  --bs-alert-color: #994a4a;
  --bs-alert-bg: #ffe5e5;
  --bs-alert-border-color: #ffd8d8; }
  .alert-danger .alert-link {
    color: #7a3b3b; }

.alert-light {
  --bs-alert-color: #5f5f5f;
  --bs-alert-bg: #fcfcfc;
  --bs-alert-border-color: #fafafa; }
  .alert-light .alert-link {
    color: #4c4c4c; }

.alert-dark {
  --bs-alert-color: #1f1f1f;
  --bs-alert-bg: #d6d6d6;
  --bs-alert-border-color: #c2c2c2; }
  .alert-dark .alert-link {
    color: #191919; }

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem; } }

.progress {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.65625rem;
  --bs-progress-bg: #e9ecef;
  --bs-progress-border-radius: 0.75rem;
  --bs-progress-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #A0CF1A;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius); }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition); }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height); }

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.list-group {
  --bs-list-group-color: #212529;
  --bs-list-group-bg: #fff;
  --bs-list-group-border-color: rgba(0, 0, 0, 0.125);
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: 0.75rem;
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: #495057;
  --bs-list-group-action-hover-color: #495057;
  --bs-list-group-action-hover-bg: #f8f9fa;
  --bs-list-group-action-active-color: #888888;
  --bs-list-group-action-active-bg: #e9ecef;
  --bs-list-group-disabled-color: #6c757d;
  --bs-list-group-disabled-bg: #fff;
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #A0CF1A;
  --bs-list-group-active-border-color: #A0CF1A;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius); }

.list-group-numbered {
  list-style-type: none;
  counter-reset: section; }
  .list-group-numbered > .list-group-item::before {
    content: counters(section, ".") ". ";
    counter-increment: section; }

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: var(--bs-list-group-action-hover-color);
    text-decoration: none;
    background-color: var(--bs-list-group-action-hover-bg); }
  .list-group-item-action:active {
    color: var(--bs-list-group-action-active-color);
    background-color: var(--bs-list-group-action-active-bg); }

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: var(--bs-list-group-disabled-color);
    pointer-events: none;
    background-color: var(--bs-list-group-disabled-bg); }
  .list-group-item.active {
    z-index: 2;
    color: var(--bs-list-group-active-color);
    background-color: var(--bs-list-group-active-bg);
    border-color: var(--bs-list-group-active-border-color); }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: calc(-1 * var(--bs-list-group-border-width));
      border-top-width: var(--bs-list-group-border-width); }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: calc(-1 * var(--bs-list-group-border-width));
      border-left-width: var(--bs-list-group-border-width); }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 1440) {
  .list-group-horizontal-xxl {
    flex-direction: row; }
    .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xxl > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 var(--bs-list-group-border-width); }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #40530a;
  background-color: #ecf5d1; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #40530a;
    background-color: #d4ddbc; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #40530a;
    border-color: #40530a; }

.list-group-item-secondary {
  color: #0a0e18;
  background-color: #cfd1d4; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #0a0e18;
    background-color: #babcbf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #0a0e18;
    border-color: #0a0e18; }

.list-group-item-success {
  color: #215347;
  background-color: #ddf6f0; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #215347;
    background-color: #c7ddd8; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #215347;
    border-color: #215347; }

.list-group-item-info {
  color: #4d6395;
  background-color: #e6edfe; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #4d6395;
    background-color: #cfd5e5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #4d6395;
    border-color: #4d6395; }

.list-group-item-warning {
  color: #625305;
  background-color: #fdf5ce; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #625305;
    background-color: #e4ddb9; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #625305;
    border-color: #625305; }

.list-group-item-danger {
  color: #994a4a;
  background-color: #ffe5e5; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #994a4a;
    background-color: #e6cece; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #994a4a;
    border-color: #994a4a; }

.list-group-item-light {
  color: #5f5f5f;
  background-color: #fcfcfc; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #5f5f5f;
    background-color: #e3e3e3; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #5f5f5f;
    border-color: #5f5f5f; }

.list-group-item-dark {
  color: #1f1f1f;
  background-color: #d6d6d6; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1f1f1f;
    background-color: #c1c1c1; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1f1f1f;
    border-color: #1f1f1f; }

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.75rem;
  opacity: 0.5; }
  .btn-close:hover {
    color: #000;
    text-decoration: none;
    opacity: 0.75; }
  .btn-close:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(160, 207, 26, 0.25);
    opacity: 1; }
  .btn-close:disabled, .btn-close.disabled {
    pointer-events: none;
    user-select: none;
    opacity: 0.25; }

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%); }

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 20px;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-border-width: 1px;
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: 0.75rem;
  --bs-toast-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-toast-header-color: #6c757d;
  --bs-toast-header-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-header-border-color: rgba(0, 0, 0, 0.05);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius); }
  .toast.showing {
    opacity: 0; }
  .toast:not(.show) {
    display: none; }

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none; }
  .toast-container > :not(:last-child) {
    margin-bottom: var(--bs-toast-spacing); }

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width)); }
  .toast-header .btn-close {
    margin-right: calc(-.5 * var(--bs-toast-padding-x));
    margin-left: var(--bs-toast-padding-x); }

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word; }

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 600px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: 0.5rem;
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-modal-inner-border-radius: calc(0.5rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2); }
  .modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0; }

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg); }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: var(--bs-backdrop-opacity); }

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius); }
  .modal-header .btn-close {
    padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
    margin: calc(-.5 * var(--bs-modal-header-padding-y)) calc(-.5 * var(--bs-modal-header-padding-x)) calc(-.5 * var(--bs-modal-header-padding-y)) auto; }

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height); }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding); }

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * .5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius); }
  .modal-footer > * {
    margin: calc(var(--bs-modal-footer-gap) * .5); }

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15); }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto; }
  .modal-sm {
    --bs-modal-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px; } }

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0; }
  .modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .modal-fullscreen .modal-header,
  .modal-fullscreen .modal-footer {
    border-radius: 0; }
  .modal-fullscreen .modal-body {
    overflow-y: auto; }

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-sm-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-sm-down .modal-header,
    .modal-fullscreen-sm-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-sm-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-md-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-md-down .modal-header,
    .modal-fullscreen-md-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-md-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-lg-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-lg-down .modal-header,
    .modal-fullscreen-lg-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-lg-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xl-down .modal-header,
    .modal-fullscreen-xl-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-xl-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 1439.98) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xxl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xxl-down .modal-header,
    .modal-fullscreen-xxl-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-xxl-down .modal-body {
      overflow-y: auto; } }

.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.76563rem;
  --bs-tooltip-color: #fff;
  --bs-tooltip-bg: #000;
  --bs-tooltip-border-radius: 0.75rem;
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  padding: var(--bs-tooltip-arrow-height);
  margin: var(--bs-tooltip-margin);
  font-family: "DM Sans", sans-serif, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0; }
  @media (min-width: 1200px) {
    .tooltip {
      --bs-tooltip-font-size: 0.76562rem; } }
  .tooltip.show {
    opacity: var(--bs-tooltip-opacity); }
  .tooltip .tooltip-arrow {
    display: block;
    width: var(--bs-tooltip-arrow-width);
    height: var(--bs-tooltip-arrow-height); }
    .tooltip .tooltip-arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: 0; }
  .bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
    top: -1px;
    border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
    border-top-color: var(--bs-tooltip-bg); }

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  left: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width); }
  .bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
    right: -1px;
    border-width: calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
    border-right-color: var(--bs-tooltip-bg); }

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: 0; }
  .bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
    bottom: -1px;
    border-width: 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
    border-bottom-color: var(--bs-tooltip-bg); }

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  right: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width); }
  .bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
    left: -1px;
    border-width: calc(var(--bs-tooltip-arrow-width) * .5) 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
    border-left-color: var(--bs-tooltip-bg); }

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius); }

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.76563rem;
  --bs-popover-bg: #fff;
  --bs-popover-border-width: 1px;
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: 0.5rem;
  --bs-popover-inner-border-radius: calc(0.5rem - 1px);
  --bs-popover-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 0.875rem;
  --bs-popover-header-color: #111828;
  --bs-popover-header-bg: #f0f0f0;
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: #888888;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: "DM Sans", sans-serif, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius); }
  @media (min-width: 1200px) {
    .popover {
      --bs-popover-font-size: 0.76562rem; } }
  .popover .popover-arrow {
    display: block;
    width: var(--bs-popover-arrow-width);
    height: var(--bs-popover-arrow-height); }
    .popover .popover-arrow::before, .popover .popover-arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid;
      border-width: 0; }

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width)); }
  .bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
    border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0; }
  .bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
    bottom: 0;
    border-top-color: var(--bs-popover-arrow-border); }
  .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
    bottom: var(--bs-popover-border-width);
    border-top-color: var(--bs-popover-bg); }

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width); }
  .bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
    border-width: calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0; }
  .bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
    left: 0;
    border-right-color: var(--bs-popover-arrow-border); }
  .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
    left: var(--bs-popover-border-width);
    border-right-color: var(--bs-popover-bg); }

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width)); }
  .bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
    border-width: 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height); }
  .bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
    top: 0;
    border-bottom-color: var(--bs-popover-arrow-border); }
  .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
    top: var(--bs-popover-border-width);
    border-bottom-color: var(--bs-popover-bg); }

.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg); }

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width); }
  .bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
    border-width: calc(var(--bs-popover-arrow-width) * .5) 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height); }
  .bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
    right: 0;
    border-left-color: var(--bs-popover-arrow-border); }
  .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
    right: var(--bs-popover-border-width);
    border-left-color: var(--bs-popover-bg); }

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color); }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-start,
    .carousel-fade .active.carousel-item-end {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%; }

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators [data-bs-target] {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center; }

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100); }

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000; }

.carousel-dark .carousel-caption {
  color: #000; }

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name); }

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */; } }

.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent; }

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0; }

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s; } }

.offcanvas-sm, .offcanvas-md, .offcanvas-lg, .offcanvas-xl, .offcanvas-xxl, .offcanvas {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: ;
  --bs-offcanvas-bg: #fff;
  --bs-offcanvas-border-width: 1px;
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075); }

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out; } }
  @media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-sm {
      transition: none; } }

@media (max-width: 575.98px) {
    .offcanvas-sm.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-sm.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-sm.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-sm.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
      transform: none; }
    .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
      visibility: visible; } }

@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-sm .offcanvas-header {
      display: none; }
    .offcanvas-sm .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out; } }
  @media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-md {
      transition: none; } }

@media (max-width: 767.98px) {
    .offcanvas-md.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-md.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-md.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-md.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
      transform: none; }
    .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
      visibility: visible; } }

@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-md .offcanvas-header {
      display: none; }
    .offcanvas-md .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out; } }
  @media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-lg {
      transition: none; } }

@media (max-width: 991.98px) {
    .offcanvas-lg.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-lg.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-lg.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-lg.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
      transform: none; }
    .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
      visibility: visible; } }

@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-lg .offcanvas-header {
      display: none; }
    .offcanvas-lg .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out; } }
  @media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-xl {
      transition: none; } }

@media (max-width: 1199.98px) {
    .offcanvas-xl.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-xl.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-xl.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-xl.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
      transform: none; }
    .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
      visibility: visible; } }

@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-xl .offcanvas-header {
      display: none; }
    .offcanvas-xl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 1439.98) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out; } }
  @media (max-width: 1439.98) and (prefers-reduced-motion: reduce) {
    .offcanvas-xxl {
      transition: none; } }

@media (max-width: 1439.98) {
    .offcanvas-xxl.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-xxl.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-xxl.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-xxl.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
      transform: none; }
    .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
      visibility: visible; } }

@media (min-width: 1440) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-xxl .offcanvas-header {
      display: none; }
    .offcanvas-xxl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .offcanvas {
      transition: none; } }
  .offcanvas.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%); }
  .offcanvas.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%); }
  .offcanvas.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%); }
  .offcanvas.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%); }
  .offcanvas.showing, .offcanvas.show:not(.hiding) {
    transform: none; }
  .offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
    visibility: visible; }

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .offcanvas-backdrop.fade {
    opacity: 0; }
  .offcanvas-backdrop.show {
    opacity: 0.5; }

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x); }
  .offcanvas-header .btn-close {
    padding: calc(var(--bs-offcanvas-padding-y) * .5) calc(var(--bs-offcanvas-padding-x) * .5);
    margin-top: calc(-.5 * var(--bs-offcanvas-padding-y));
    margin-right: calc(-.5 * var(--bs-offcanvas-padding-x));
    margin-bottom: calc(-.5 * var(--bs-offcanvas-padding-y)); }

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5; }

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto; }

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5; }
  .placeholder.btn::before {
    display: inline-block;
    content: ""; }

.placeholder-xs {
  min-height: .6em; }

.placeholder-sm {
  min-height: .8em; }

.placeholder-lg {
  min-height: 1.2em; }

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite; }

@keyframes placeholder-glow {
  50% {
    opacity: 0.2; } }

.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite; }

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%; } }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.text-bg-primary {
  color: #000 !important;
  background-color: RGBA(160, 207, 26, var(--bs-bg-opacity, 1)) !important; }

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(17, 24, 40, var(--bs-bg-opacity, 1)) !important; }

.text-bg-success {
  color: #000 !important;
  background-color: RGBA(83, 208, 178, var(--bs-bg-opacity, 1)) !important; }

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(129, 165, 249, var(--bs-bg-opacity, 1)) !important; }

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(244, 207, 12, var(--bs-bg-opacity, 1)) !important; }

.text-bg-danger {
  color: #000 !important;
  background-color: RGBA(255, 124, 124, var(--bs-bg-opacity, 1)) !important; }

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(238, 238, 238, var(--bs-bg-opacity, 1)) !important; }

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(51, 51, 51, var(--bs-bg-opacity, 1)) !important; }

.link-primary {
  color: #A0CF1A !important; }
  .link-primary:hover, .link-primary:focus {
    color: #b3d948 !important; }

.link-secondary {
  color: #111828 !important; }
  .link-secondary:hover, .link-secondary:focus {
    color: #0e1320 !important; }

.link-success {
  color: #53D0B2 !important; }
  .link-success:hover, .link-success:focus {
    color: #75d9c1 !important; }

.link-info {
  color: #81A5F9 !important; }
  .link-info:hover, .link-info:focus {
    color: #9ab7fa !important; }

.link-warning {
  color: #F4CF0C !important; }
  .link-warning:hover, .link-warning:focus {
    color: #f6d93d !important; }

.link-danger {
  color: #FF7C7C !important; }
  .link-danger:hover, .link-danger:focus {
    color: #ff9696 !important; }

.link-light {
  color: #EEEEEE !important; }
  .link-light:hover, .link-light:focus {
    color: #f1f1f1 !important; }

.link-dark {
  color: #333333 !important; }
  .link-dark:hover, .link-dark:focus {
    color: #292929 !important; }

.ratio {
  position: relative;
  width: 100%; }
  .ratio::before {
    display: block;
    padding-top: var(--bs-aspect-ratio);
    content: ""; }
  .ratio > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.ratio-1x1 {
  --bs-aspect-ratio: 100%; }

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%); }

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%); }

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%); }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020; }

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020; }

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 1440) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch; }

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch; }

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: ""; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.float-start {
  float: left !important; }

.float-end {
  float: right !important; }

.float-none {
  float: none !important; }

.opacity-0 {
  opacity: 0 !important; }

.opacity-25 {
  opacity: 0.25 !important; }

.opacity-50 {
  opacity: 0.5 !important; }

.opacity-75 {
  opacity: 0.75 !important; }

.opacity-100 {
  opacity: 1 !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.overflow-visible {
  overflow: visible !important; }

.overflow-scroll {
  overflow: scroll !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-grid {
  display: grid !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

.d-none {
  display: none !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.top-0 {
  top: 0 !important; }

.top-50 {
  top: 50% !important; }

.top-100 {
  top: 100% !important; }

.bottom-0 {
  bottom: 0 !important; }

.bottom-50 {
  bottom: 50% !important; }

.bottom-100 {
  bottom: 100% !important; }

.start-0 {
  left: 0 !important; }

.start-50 {
  left: 50% !important; }

.start-100 {
  left: 100% !important; }

.end-0 {
  right: 0 !important; }

.end-50 {
  right: 50% !important; }

.end-100 {
  right: 100% !important; }

.translate-middle {
  transform: translate(-50%, -50%) !important; }

.translate-middle-x {
  transform: translateX(-50%) !important; }

.translate-middle-y {
  transform: translateY(-50%) !important; }

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-0 {
  border: 0 !important; }

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-end-0 {
  border-right: 0 !important; }

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-start-0 {
  border-left: 0 !important; }

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important; }

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important; }

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important; }

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important; }

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important; }

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important; }

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important; }

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important; }

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important; }

.border-1 {
  --bs-border-width: 1px; }

.border-2 {
  --bs-border-width: 2px; }

.border-3 {
  --bs-border-width: 3px; }

.border-4 {
  --bs-border-width: 4px; }

.border-5 {
  --bs-border-width: 5px; }

.border-opacity-10 {
  --bs-border-opacity: 0.1; }

.border-opacity-25 {
  --bs-border-opacity: 0.25; }

.border-opacity-50 {
  --bs-border-opacity: 0.5; }

.border-opacity-75 {
  --bs-border-opacity: 0.75; }

.border-opacity-100 {
  --bs-border-opacity: 1; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.vw-100 {
  width: 100vw !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mh-100 {
  max-height: 100% !important; }

.vh-100 {
  height: 100vh !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.justify-content-evenly {
  justify-content: space-evenly !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

.order-first {
  order: -1 !important; }

.order-0 {
  order: 0 !important; }

.order-1 {
  order: 1 !important; }

.order-2 {
  order: 2 !important; }

.order-3 {
  order: 3 !important; }

.order-4 {
  order: 4 !important; }

.order-5 {
  order: 5 !important; }

.order-last {
  order: 6 !important; }

.m-0 {
  margin: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important; }

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important; }

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important; }

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important; }

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important; }

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

.mt-0 {
  margin-top: 0 !important; }

.mt-1 {
  margin-top: 0.25rem !important; }

.mt-2 {
  margin-top: 0.5rem !important; }

.mt-3 {
  margin-top: 1rem !important; }

.mt-4 {
  margin-top: 1.5rem !important; }

.mt-5 {
  margin-top: 3rem !important; }

.mt-auto {
  margin-top: auto !important; }

.me-0 {
  margin-right: 0 !important; }

.me-1 {
  margin-right: 0.25rem !important; }

.me-2 {
  margin-right: 0.5rem !important; }

.me-3 {
  margin-right: 1rem !important; }

.me-4 {
  margin-right: 1.5rem !important; }

.me-5 {
  margin-right: 3rem !important; }

.me-auto {
  margin-right: auto !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-1 {
  margin-bottom: 0.25rem !important; }

.mb-2 {
  margin-bottom: 0.5rem !important; }

.mb-3 {
  margin-bottom: 1rem !important; }

.mb-4 {
  margin-bottom: 1.5rem !important; }

.mb-5 {
  margin-bottom: 3rem !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ms-0 {
  margin-left: 0 !important; }

.ms-1 {
  margin-left: 0.25rem !important; }

.ms-2 {
  margin-left: 0.5rem !important; }

.ms-3 {
  margin-left: 1rem !important; }

.ms-4 {
  margin-left: 1.5rem !important; }

.ms-5 {
  margin-left: 3rem !important; }

.ms-auto {
  margin-left: auto !important; }

.p-0 {
  padding: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important; }

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important; }

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important; }

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important; }

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important; }

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.pt-0 {
  padding-top: 0 !important; }

.pt-1 {
  padding-top: 0.25rem !important; }

.pt-2 {
  padding-top: 0.5rem !important; }

.pt-3 {
  padding-top: 1rem !important; }

.pt-4 {
  padding-top: 1.5rem !important; }

.pt-5 {
  padding-top: 3rem !important; }

.pe-0 {
  padding-right: 0 !important; }

.pe-1 {
  padding-right: 0.25rem !important; }

.pe-2 {
  padding-right: 0.5rem !important; }

.pe-3 {
  padding-right: 1rem !important; }

.pe-4 {
  padding-right: 1.5rem !important; }

.pe-5 {
  padding-right: 3rem !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pb-1 {
  padding-bottom: 0.25rem !important; }

.pb-2 {
  padding-bottom: 0.5rem !important; }

.pb-3 {
  padding-bottom: 1rem !important; }

.pb-4 {
  padding-bottom: 1.5rem !important; }

.pb-5 {
  padding-bottom: 3rem !important; }

.ps-0 {
  padding-left: 0 !important; }

.ps-1 {
  padding-left: 0.25rem !important; }

.ps-2 {
  padding-left: 0.5rem !important; }

.ps-3 {
  padding-left: 1rem !important; }

.ps-4 {
  padding-left: 1.5rem !important; }

.ps-5 {
  padding-left: 3rem !important; }

.gap-0 {
  gap: 0 !important; }

.gap-1 {
  gap: 0.25rem !important; }

.gap-2 {
  gap: 0.5rem !important; }

.gap-3 {
  gap: 1rem !important; }

.gap-4 {
  gap: 1.5rem !important; }

.gap-5 {
  gap: 3rem !important; }

.font-monospace {
  font-family: var(--bs-font-monospace) !important; }

.fs-1 {
  font-size: calc(1.35rem + 1.2vw) !important; }

.fs-2 {
  font-size: calc(1.3125rem + 0.75vw) !important; }

.fs-3 {
  font-size: calc(1.275rem + 0.3vw) !important; }

.fs-4 {
  font-size: 1.125rem !important; }

.fs-5 {
  font-size: 1rem !important; }

.fs-6 {
  font-size: 0.938rem !important; }

.fst-italic {
  font-style: italic !important; }

.fst-normal {
  font-style: normal !important; }

.fw-light {
  font-weight: 300 !important; }

.fw-lighter {
  font-weight: lighter !important; }

.fw-normal {
  font-weight: 400 !important; }

.fw-bold {
  font-weight: 700 !important; }

.fw-semibold {
  font-weight: 600 !important; }

.fw-bolder {
  font-weight: bolder !important; }

.lh-1 {
  line-height: 1 !important; }

.lh-sm {
  line-height: 1.25 !important; }

.lh-base {
  line-height: 1.5 !important; }

.lh-lg {
  line-height: 2 !important; }

.text-start {
  text-align: left !important; }

.text-end {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.text-decoration-none {
  text-decoration: none !important; }

.text-decoration-underline {
  text-decoration: underline !important; }

.text-decoration-line-through {
  text-decoration: line-through !important; }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important; }

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important; }

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important; }

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important; }

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important; }

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important; }

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important; }

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important; }

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important; }

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important; }

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important; }

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important; }

.text-muted {
  --bs-text-opacity: 1;
  color: #89879f !important; }

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important; }

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important; }

.text-opacity-25 {
  --bs-text-opacity: 0.25; }

.text-opacity-50 {
  --bs-text-opacity: 0.5; }

.text-opacity-75 {
  --bs-text-opacity: 0.75; }

.text-opacity-100 {
  --bs-text-opacity: 1; }

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important; }

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important; }

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important; }

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important; }

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important; }

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important; }

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important; }

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important; }

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important; }

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important; }

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important; }

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important; }

.bg-opacity-10 {
  --bs-bg-opacity: 0.1; }

.bg-opacity-25 {
  --bs-bg-opacity: 0.25; }

.bg-opacity-50 {
  --bs-bg-opacity: 0.5; }

.bg-opacity-75 {
  --bs-bg-opacity: 0.75; }

.bg-opacity-100 {
  --bs-bg-opacity: 1; }

.bg-gradient {
  background-image: var(--bs-gradient) !important; }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.pe-none {
  pointer-events: none !important; }

.pe-auto {
  pointer-events: auto !important; }

.rounded {
  border-radius: var(--bs-border-radius) !important; }

.rounded-0 {
  border-radius: 0 !important; }

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important; }

.rounded-2 {
  border-radius: var(--bs-border-radius) !important; }

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important; }

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important; }

.rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important; }

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important; }

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important; }

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important; }

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important; }
  .float-sm-end {
    float: right !important; }
  .float-sm-none {
    float: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-grid {
    display: grid !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; }
  .d-sm-none {
    display: none !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; }
  .order-sm-first {
    order: -1 !important; }
  .order-sm-0 {
    order: 0 !important; }
  .order-sm-1 {
    order: 1 !important; }
  .order-sm-2 {
    order: 2 !important; }
  .order-sm-3 {
    order: 3 !important; }
  .order-sm-4 {
    order: 4 !important; }
  .order-sm-5 {
    order: 5 !important; }
  .order-sm-last {
    order: 6 !important; }
  .m-sm-0 {
    margin: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mt-sm-1 {
    margin-top: 0.25rem !important; }
  .mt-sm-2 {
    margin-top: 0.5rem !important; }
  .mt-sm-3 {
    margin-top: 1rem !important; }
  .mt-sm-4 {
    margin-top: 1.5rem !important; }
  .mt-sm-5 {
    margin-top: 3rem !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .me-sm-0 {
    margin-right: 0 !important; }
  .me-sm-1 {
    margin-right: 0.25rem !important; }
  .me-sm-2 {
    margin-right: 0.5rem !important; }
  .me-sm-3 {
    margin-right: 1rem !important; }
  .me-sm-4 {
    margin-right: 1.5rem !important; }
  .me-sm-5 {
    margin-right: 3rem !important; }
  .me-sm-auto {
    margin-right: auto !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important; }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important; }
  .mb-sm-3 {
    margin-bottom: 1rem !important; }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important; }
  .mb-sm-5 {
    margin-bottom: 3rem !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ms-sm-0 {
    margin-left: 0 !important; }
  .ms-sm-1 {
    margin-left: 0.25rem !important; }
  .ms-sm-2 {
    margin-left: 0.5rem !important; }
  .ms-sm-3 {
    margin-left: 1rem !important; }
  .ms-sm-4 {
    margin-left: 1.5rem !important; }
  .ms-sm-5 {
    margin-left: 3rem !important; }
  .ms-sm-auto {
    margin-left: auto !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pt-sm-1 {
    padding-top: 0.25rem !important; }
  .pt-sm-2 {
    padding-top: 0.5rem !important; }
  .pt-sm-3 {
    padding-top: 1rem !important; }
  .pt-sm-4 {
    padding-top: 1.5rem !important; }
  .pt-sm-5 {
    padding-top: 3rem !important; }
  .pe-sm-0 {
    padding-right: 0 !important; }
  .pe-sm-1 {
    padding-right: 0.25rem !important; }
  .pe-sm-2 {
    padding-right: 0.5rem !important; }
  .pe-sm-3 {
    padding-right: 1rem !important; }
  .pe-sm-4 {
    padding-right: 1.5rem !important; }
  .pe-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pb-sm-3 {
    padding-bottom: 1rem !important; }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pb-sm-5 {
    padding-bottom: 3rem !important; }
  .ps-sm-0 {
    padding-left: 0 !important; }
  .ps-sm-1 {
    padding-left: 0.25rem !important; }
  .ps-sm-2 {
    padding-left: 0.5rem !important; }
  .ps-sm-3 {
    padding-left: 1rem !important; }
  .ps-sm-4 {
    padding-left: 1.5rem !important; }
  .ps-sm-5 {
    padding-left: 3rem !important; }
  .gap-sm-0 {
    gap: 0 !important; }
  .gap-sm-1 {
    gap: 0.25rem !important; }
  .gap-sm-2 {
    gap: 0.5rem !important; }
  .gap-sm-3 {
    gap: 1rem !important; }
  .gap-sm-4 {
    gap: 1.5rem !important; }
  .gap-sm-5 {
    gap: 3rem !important; }
  .text-sm-start {
    text-align: left !important; }
  .text-sm-end {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .float-md-start {
    float: left !important; }
  .float-md-end {
    float: right !important; }
  .float-md-none {
    float: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-grid {
    display: grid !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; }
  .d-md-none {
    display: none !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .justify-content-md-evenly {
    justify-content: space-evenly !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; }
  .order-md-first {
    order: -1 !important; }
  .order-md-0 {
    order: 0 !important; }
  .order-md-1 {
    order: 1 !important; }
  .order-md-2 {
    order: 2 !important; }
  .order-md-3 {
    order: 3 !important; }
  .order-md-4 {
    order: 4 !important; }
  .order-md-5 {
    order: 5 !important; }
  .order-md-last {
    order: 6 !important; }
  .m-md-0 {
    margin: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mt-md-1 {
    margin-top: 0.25rem !important; }
  .mt-md-2 {
    margin-top: 0.5rem !important; }
  .mt-md-3 {
    margin-top: 1rem !important; }
  .mt-md-4 {
    margin-top: 1.5rem !important; }
  .mt-md-5 {
    margin-top: 3rem !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .me-md-0 {
    margin-right: 0 !important; }
  .me-md-1 {
    margin-right: 0.25rem !important; }
  .me-md-2 {
    margin-right: 0.5rem !important; }
  .me-md-3 {
    margin-right: 1rem !important; }
  .me-md-4 {
    margin-right: 1.5rem !important; }
  .me-md-5 {
    margin-right: 3rem !important; }
  .me-md-auto {
    margin-right: auto !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .mb-md-1 {
    margin-bottom: 0.25rem !important; }
  .mb-md-2 {
    margin-bottom: 0.5rem !important; }
  .mb-md-3 {
    margin-bottom: 1rem !important; }
  .mb-md-4 {
    margin-bottom: 1.5rem !important; }
  .mb-md-5 {
    margin-bottom: 3rem !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ms-md-0 {
    margin-left: 0 !important; }
  .ms-md-1 {
    margin-left: 0.25rem !important; }
  .ms-md-2 {
    margin-left: 0.5rem !important; }
  .ms-md-3 {
    margin-left: 1rem !important; }
  .ms-md-4 {
    margin-left: 1.5rem !important; }
  .ms-md-5 {
    margin-left: 3rem !important; }
  .ms-md-auto {
    margin-left: auto !important; }
  .p-md-0 {
    padding: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pt-md-1 {
    padding-top: 0.25rem !important; }
  .pt-md-2 {
    padding-top: 0.5rem !important; }
  .pt-md-3 {
    padding-top: 1rem !important; }
  .pt-md-4 {
    padding-top: 1.5rem !important; }
  .pt-md-5 {
    padding-top: 3rem !important; }
  .pe-md-0 {
    padding-right: 0 !important; }
  .pe-md-1 {
    padding-right: 0.25rem !important; }
  .pe-md-2 {
    padding-right: 0.5rem !important; }
  .pe-md-3 {
    padding-right: 1rem !important; }
  .pe-md-4 {
    padding-right: 1.5rem !important; }
  .pe-md-5 {
    padding-right: 3rem !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pb-md-1 {
    padding-bottom: 0.25rem !important; }
  .pb-md-2 {
    padding-bottom: 0.5rem !important; }
  .pb-md-3 {
    padding-bottom: 1rem !important; }
  .pb-md-4 {
    padding-bottom: 1.5rem !important; }
  .pb-md-5 {
    padding-bottom: 3rem !important; }
  .ps-md-0 {
    padding-left: 0 !important; }
  .ps-md-1 {
    padding-left: 0.25rem !important; }
  .ps-md-2 {
    padding-left: 0.5rem !important; }
  .ps-md-3 {
    padding-left: 1rem !important; }
  .ps-md-4 {
    padding-left: 1.5rem !important; }
  .ps-md-5 {
    padding-left: 3rem !important; }
  .gap-md-0 {
    gap: 0 !important; }
  .gap-md-1 {
    gap: 0.25rem !important; }
  .gap-md-2 {
    gap: 0.5rem !important; }
  .gap-md-3 {
    gap: 1rem !important; }
  .gap-md-4 {
    gap: 1.5rem !important; }
  .gap-md-5 {
    gap: 3rem !important; }
  .text-md-start {
    text-align: left !important; }
  .text-md-end {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important; }
  .float-lg-end {
    float: right !important; }
  .float-lg-none {
    float: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-grid {
    display: grid !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; }
  .d-lg-none {
    display: none !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; }
  .order-lg-first {
    order: -1 !important; }
  .order-lg-0 {
    order: 0 !important; }
  .order-lg-1 {
    order: 1 !important; }
  .order-lg-2 {
    order: 2 !important; }
  .order-lg-3 {
    order: 3 !important; }
  .order-lg-4 {
    order: 4 !important; }
  .order-lg-5 {
    order: 5 !important; }
  .order-lg-last {
    order: 6 !important; }
  .m-lg-0 {
    margin: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mt-lg-1 {
    margin-top: 0.25rem !important; }
  .mt-lg-2 {
    margin-top: 0.5rem !important; }
  .mt-lg-3 {
    margin-top: 1rem !important; }
  .mt-lg-4 {
    margin-top: 1.5rem !important; }
  .mt-lg-5 {
    margin-top: 3rem !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .me-lg-0 {
    margin-right: 0 !important; }
  .me-lg-1 {
    margin-right: 0.25rem !important; }
  .me-lg-2 {
    margin-right: 0.5rem !important; }
  .me-lg-3 {
    margin-right: 1rem !important; }
  .me-lg-4 {
    margin-right: 1.5rem !important; }
  .me-lg-5 {
    margin-right: 3rem !important; }
  .me-lg-auto {
    margin-right: auto !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important; }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important; }
  .mb-lg-3 {
    margin-bottom: 1rem !important; }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important; }
  .mb-lg-5 {
    margin-bottom: 3rem !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ms-lg-0 {
    margin-left: 0 !important; }
  .ms-lg-1 {
    margin-left: 0.25rem !important; }
  .ms-lg-2 {
    margin-left: 0.5rem !important; }
  .ms-lg-3 {
    margin-left: 1rem !important; }
  .ms-lg-4 {
    margin-left: 1.5rem !important; }
  .ms-lg-5 {
    margin-left: 3rem !important; }
  .ms-lg-auto {
    margin-left: auto !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pt-lg-1 {
    padding-top: 0.25rem !important; }
  .pt-lg-2 {
    padding-top: 0.5rem !important; }
  .pt-lg-3 {
    padding-top: 1rem !important; }
  .pt-lg-4 {
    padding-top: 1.5rem !important; }
  .pt-lg-5 {
    padding-top: 3rem !important; }
  .pe-lg-0 {
    padding-right: 0 !important; }
  .pe-lg-1 {
    padding-right: 0.25rem !important; }
  .pe-lg-2 {
    padding-right: 0.5rem !important; }
  .pe-lg-3 {
    padding-right: 1rem !important; }
  .pe-lg-4 {
    padding-right: 1.5rem !important; }
  .pe-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pb-lg-3 {
    padding-bottom: 1rem !important; }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pb-lg-5 {
    padding-bottom: 3rem !important; }
  .ps-lg-0 {
    padding-left: 0 !important; }
  .ps-lg-1 {
    padding-left: 0.25rem !important; }
  .ps-lg-2 {
    padding-left: 0.5rem !important; }
  .ps-lg-3 {
    padding-left: 1rem !important; }
  .ps-lg-4 {
    padding-left: 1.5rem !important; }
  .ps-lg-5 {
    padding-left: 3rem !important; }
  .gap-lg-0 {
    gap: 0 !important; }
  .gap-lg-1 {
    gap: 0.25rem !important; }
  .gap-lg-2 {
    gap: 0.5rem !important; }
  .gap-lg-3 {
    gap: 1rem !important; }
  .gap-lg-4 {
    gap: 1.5rem !important; }
  .gap-lg-5 {
    gap: 3rem !important; }
  .text-lg-start {
    text-align: left !important; }
  .text-lg-end {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important; }
  .float-xl-end {
    float: right !important; }
  .float-xl-none {
    float: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-grid {
    display: grid !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; }
  .d-xl-none {
    display: none !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; }
  .order-xl-first {
    order: -1 !important; }
  .order-xl-0 {
    order: 0 !important; }
  .order-xl-1 {
    order: 1 !important; }
  .order-xl-2 {
    order: 2 !important; }
  .order-xl-3 {
    order: 3 !important; }
  .order-xl-4 {
    order: 4 !important; }
  .order-xl-5 {
    order: 5 !important; }
  .order-xl-last {
    order: 6 !important; }
  .m-xl-0 {
    margin: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mt-xl-1 {
    margin-top: 0.25rem !important; }
  .mt-xl-2 {
    margin-top: 0.5rem !important; }
  .mt-xl-3 {
    margin-top: 1rem !important; }
  .mt-xl-4 {
    margin-top: 1.5rem !important; }
  .mt-xl-5 {
    margin-top: 3rem !important; }
  .mt-xl-auto {
    margin-top: auto !important; }
  .me-xl-0 {
    margin-right: 0 !important; }
  .me-xl-1 {
    margin-right: 0.25rem !important; }
  .me-xl-2 {
    margin-right: 0.5rem !important; }
  .me-xl-3 {
    margin-right: 1rem !important; }
  .me-xl-4 {
    margin-right: 1.5rem !important; }
  .me-xl-5 {
    margin-right: 3rem !important; }
  .me-xl-auto {
    margin-right: auto !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xl-3 {
    margin-bottom: 1rem !important; }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xl-5 {
    margin-bottom: 3rem !important; }
  .mb-xl-auto {
    margin-bottom: auto !important; }
  .ms-xl-0 {
    margin-left: 0 !important; }
  .ms-xl-1 {
    margin-left: 0.25rem !important; }
  .ms-xl-2 {
    margin-left: 0.5rem !important; }
  .ms-xl-3 {
    margin-left: 1rem !important; }
  .ms-xl-4 {
    margin-left: 1.5rem !important; }
  .ms-xl-5 {
    margin-left: 3rem !important; }
  .ms-xl-auto {
    margin-left: auto !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pt-xl-1 {
    padding-top: 0.25rem !important; }
  .pt-xl-2 {
    padding-top: 0.5rem !important; }
  .pt-xl-3 {
    padding-top: 1rem !important; }
  .pt-xl-4 {
    padding-top: 1.5rem !important; }
  .pt-xl-5 {
    padding-top: 3rem !important; }
  .pe-xl-0 {
    padding-right: 0 !important; }
  .pe-xl-1 {
    padding-right: 0.25rem !important; }
  .pe-xl-2 {
    padding-right: 0.5rem !important; }
  .pe-xl-3 {
    padding-right: 1rem !important; }
  .pe-xl-4 {
    padding-right: 1.5rem !important; }
  .pe-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xl-3 {
    padding-bottom: 1rem !important; }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xl-5 {
    padding-bottom: 3rem !important; }
  .ps-xl-0 {
    padding-left: 0 !important; }
  .ps-xl-1 {
    padding-left: 0.25rem !important; }
  .ps-xl-2 {
    padding-left: 0.5rem !important; }
  .ps-xl-3 {
    padding-left: 1rem !important; }
  .ps-xl-4 {
    padding-left: 1.5rem !important; }
  .ps-xl-5 {
    padding-left: 3rem !important; }
  .gap-xl-0 {
    gap: 0 !important; }
  .gap-xl-1 {
    gap: 0.25rem !important; }
  .gap-xl-2 {
    gap: 0.5rem !important; }
  .gap-xl-3 {
    gap: 1rem !important; }
  .gap-xl-4 {
    gap: 1.5rem !important; }
  .gap-xl-5 {
    gap: 3rem !important; }
  .text-xl-start {
    text-align: left !important; }
  .text-xl-end {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1440) {
  .float-xxl-start {
    float: left !important; }
  .float-xxl-end {
    float: right !important; }
  .float-xxl-none {
    float: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-grid {
    display: grid !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; }
  .d-xxl-none {
    display: none !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; }
  .order-xxl-first {
    order: -1 !important; }
  .order-xxl-0 {
    order: 0 !important; }
  .order-xxl-1 {
    order: 1 !important; }
  .order-xxl-2 {
    order: 2 !important; }
  .order-xxl-3 {
    order: 3 !important; }
  .order-xxl-4 {
    order: 4 !important; }
  .order-xxl-5 {
    order: 5 !important; }
  .order-xxl-last {
    order: 6 !important; }
  .m-xxl-0 {
    margin: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .m-xxl-5 {
    margin: 3rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xxl-0 {
    margin-top: 0 !important; }
  .mt-xxl-1 {
    margin-top: 0.25rem !important; }
  .mt-xxl-2 {
    margin-top: 0.5rem !important; }
  .mt-xxl-3 {
    margin-top: 1rem !important; }
  .mt-xxl-4 {
    margin-top: 1.5rem !important; }
  .mt-xxl-5 {
    margin-top: 3rem !important; }
  .mt-xxl-auto {
    margin-top: auto !important; }
  .me-xxl-0 {
    margin-right: 0 !important; }
  .me-xxl-1 {
    margin-right: 0.25rem !important; }
  .me-xxl-2 {
    margin-right: 0.5rem !important; }
  .me-xxl-3 {
    margin-right: 1rem !important; }
  .me-xxl-4 {
    margin-right: 1.5rem !important; }
  .me-xxl-5 {
    margin-right: 3rem !important; }
  .me-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-0 {
    margin-bottom: 0 !important; }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xxl-3 {
    margin-bottom: 1rem !important; }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xxl-5 {
    margin-bottom: 3rem !important; }
  .mb-xxl-auto {
    margin-bottom: auto !important; }
  .ms-xxl-0 {
    margin-left: 0 !important; }
  .ms-xxl-1 {
    margin-left: 0.25rem !important; }
  .ms-xxl-2 {
    margin-left: 0.5rem !important; }
  .ms-xxl-3 {
    margin-left: 1rem !important; }
  .ms-xxl-4 {
    margin-left: 1.5rem !important; }
  .ms-xxl-5 {
    margin-left: 3rem !important; }
  .ms-xxl-auto {
    margin-left: auto !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .p-xxl-5 {
    padding: 3rem !important; }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xxl-0 {
    padding-top: 0 !important; }
  .pt-xxl-1 {
    padding-top: 0.25rem !important; }
  .pt-xxl-2 {
    padding-top: 0.5rem !important; }
  .pt-xxl-3 {
    padding-top: 1rem !important; }
  .pt-xxl-4 {
    padding-top: 1.5rem !important; }
  .pt-xxl-5 {
    padding-top: 3rem !important; }
  .pe-xxl-0 {
    padding-right: 0 !important; }
  .pe-xxl-1 {
    padding-right: 0.25rem !important; }
  .pe-xxl-2 {
    padding-right: 0.5rem !important; }
  .pe-xxl-3 {
    padding-right: 1rem !important; }
  .pe-xxl-4 {
    padding-right: 1.5rem !important; }
  .pe-xxl-5 {
    padding-right: 3rem !important; }
  .pb-xxl-0 {
    padding-bottom: 0 !important; }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xxl-3 {
    padding-bottom: 1rem !important; }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xxl-5 {
    padding-bottom: 3rem !important; }
  .ps-xxl-0 {
    padding-left: 0 !important; }
  .ps-xxl-1 {
    padding-left: 0.25rem !important; }
  .ps-xxl-2 {
    padding-left: 0.5rem !important; }
  .ps-xxl-3 {
    padding-left: 1rem !important; }
  .ps-xxl-4 {
    padding-left: 1.5rem !important; }
  .ps-xxl-5 {
    padding-left: 3rem !important; }
  .gap-xxl-0 {
    gap: 0 !important; }
  .gap-xxl-1 {
    gap: 0.25rem !important; }
  .gap-xxl-2 {
    gap: 0.5rem !important; }
  .gap-xxl-3 {
    gap: 1rem !important; }
  .gap-xxl-4 {
    gap: 1.5rem !important; }
  .gap-xxl-5 {
    gap: 3rem !important; }
  .text-xxl-start {
    text-align: left !important; }
  .text-xxl-end {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.25rem !important; }
  .fs-2 {
    font-size: 1.875rem !important; }
  .fs-3 {
    font-size: 1.5rem !important; } }

@media print {
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-grid {
    display: grid !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; }
  .d-print-none {
    display: none !important; } }

.c-pointer {
  cursor: pointer; }

* {
  outline: none;
  padding: 0; }
  *::after {
    margin: 0;
    padding: 0; }
  *::before {
    margin: 0;
    padding: 0; }

.required {
  color: #FF7C7C; }

::selection {
  color: #fff;
  background: var(--primary); }

@media only screen and (max-width: 100rem) {
  :root {
    font-size: 14px; } }

body {
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: #F4F4F5; }

body::-webkit-scrollbar {
  display: none; }

p {
  line-height: 1.8; }

div,
span,
ol,
ul {
  scrollbar-width: thin; }

.box-shadow-none {
  box-shadow: none !important; }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

#main-wrapper {
  opacity: 0;
  transition: all 0.25s ease-in;
  position: relative; }
  #main-wrapper.show {
    opacity: 1; }

.rounded-lg {
  border-radius: 0.625rem; }

ul {
  padding: 0;
  margin: 0; }

li {
  list-style: none; }

a {
  color: #888888;
  text-decoration: none; }
  a:hover, a:focus, a.active {
    text-decoration: none; }

.btn-link:hover, .btn-link:focus, .btn-link.active {
  text-decoration: none; }

.bg-primary {
  background-color: var(--primary) !important; }

.bg-secondary {
  background-color: var(--secondary) !important; }

.text-primary {
  color: var(--primary) !important; }

.fs-12 {
  font-size: 0.75rem !important;
  line-height: 1.5; }

.fs-13 {
  font-size: 0.8125rem !important;
  line-height: 1.5; }

.fs-14 {
  font-size: 0.875rem !important;
  line-height: 1.6; }

.fs-15 {
  font-size: 0.875rem !important;
  line-height: 1.5; }

.fs-16 {
  font-size: 1rem !important;
  line-height: 1.6; }
  @media only screen and (max-width: 35.9375rem) {
    .fs-16 {
      font-size: 0.875rem !important; } }

.fs-18 {
  font-size: 1.125rem !important;
  line-height: 1.5; }
  @media only screen and (max-width: 35.9375rem) {
    .fs-18 {
      font-size: 1rem !important; } }

.fs-20 {
  font-size: 1.25rem !important;
  line-height: 1.5; }

.fs-22 {
  font-size: 1.375rem !important;
  line-height: 1.5; }

.fs-24 {
  font-size: 1.5rem !important;
  line-height: 1.4; }

.fs-26 {
  font-size: 1.625rem !important;
  line-height: 1.4; }

.fs-28 {
  font-size: 1.75rem !important;
  line-height: 1.4; }
  @media only screen and (max-width: 35.9375rem) {
    .fs-28 {
      font-size: 1.5rem !important; } }

.fs-30 {
  font-size: 1.875rem !important;
  line-height: 1.4; }

.fs-32 {
  font-size: 2rem !important;
  line-height: 1.25; }

.fs-34 {
  font-size: 2.125rem !important;
  line-height: 1.25; }

.fs-35 {
  font-size: 2.1875rem !important;
  line-height: 1.25; }

.fs-36 {
  font-size: 2.25rem !important;
  line-height: 1.25; }

.fs-38 {
  font-size: 2.375rem !important;
  line-height: 1.25; }

.fs-46 {
  font-size: 2.875rem !important;
  line-height: 1.25; }

.fs-48 {
  font-size: 3rem !important;
  line-height: 1.25; }

.font-w100 {
  font-weight: 100; }

.font-w200 {
  font-weight: 200; }

.font-w300 {
  font-weight: 300; }

.font-w400 {
  font-weight: 400; }

.font-w500 {
  font-weight: 500; }

.font-w600 {
  font-weight: 600; }

.font-w700 {
  font-weight: 700; }

.font-w800 {
  font-weight: 800; }

.font-w900 {
  font-weight: 900; }

h1, .h1, .h1, h2, .h2, .h2, h3, .h3, .h3, h4, .h4, .h4, h5, .h5, .h5, h6, .h6, .h6 {
  line-height: 1.5;
  font-weight: 500;
  color: #111828; }

.w-space-no {
  white-space: nowrap; }

.content-body .container {
  margin-top: 2.5rem; }

.content-body .container-fluid, .content-body .container-sm, .content-body .container-md, .content-body .container-lg, .content-body .container-xl, .content-body .container-xxl {
  padding-top: 2.5rem;
  padding-right: 2.5rem;
  padding-left: 2.5rem; }
  @media only screen and (max-width: 47.9375rem) {
    .content-body .container-fluid, .content-body .container-sm, .content-body .container-md, .content-body .container-lg, .content-body .container-xl, .content-body .container-xxl {
      padding-top: 1.25rem;
      padding-right: 1.25rem;
      padding-left: 1.25rem; } }
  @media only screen and (max-width: 47.9375rem) {
    .content-body .container-fluid, .content-body .container-sm, .content-body .container-md, .content-body .container-lg, .content-body .container-xl, .content-body .container-xxl {
      padding-top: 1.45rem;
      padding-right: 1.45rem;
      padding-left: 1.45rem; } }
  @media only screen and (max-width: 35.9375rem) {
    .content-body .container-fluid, .content-body .container-sm, .content-body .container-md, .content-body .container-lg, .content-body .container-xl, .content-body .container-xxl {
      padding-top: 15px;
      padding-right: 15px;
      padding-left: 15px; } }

/*sp*/
.row.sp4,
.sp4 {
  margin-left: -0.125rem;
  margin-right: -0.125rem; }
  .row.sp4 [class*="col-"],
  .sp4 [class*="col-"] {
    padding-left: 0.125rem;
    padding-right: 0.125rem; }

.op1 {
  opacity: 0.1; }

.op2 {
  opacity: 0.2; }

.op3 {
  opacity: 0.3; }

.op4 {
  opacity: 0.4; }

.op5 {
  opacity: 0.5; }

.op6 {
  opacity: 0.6; }

.op7 {
  opacity: 0.7; }

.op8 {
  opacity: 0.8; }

.op9 {
  opacity: 0.9; }

.content-heading {
  font-size: 1rem;
  margin-bottom: 1.875rem;
  margin-top: 3.125rem;
  border-bottom: 0.0625rem solid #E6E6E6;
  padding-bottom: 0.625rem; }
  [direction="rtl"] .content-heading {
    text-align: right; }

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus {
  box-shadow: none; }

.dropzone .dz-message {
  text-align: center;
  margin: 5rem 0; }

.vh-100 {
  height: 100vh !important; }

.support-ticket {
  position: fixed;
  bottom: 1.875rem;
  right: 0.9375rem;
  z-index: 999999; }

.support-ticket-btn {
  width: 6.25rem;
  background: #7CB442;
  animation: 0.7s ease-in-out 0s infinite alternate none running crescendo;
  border-radius: 3.125rem;
  color: #fff;
  font-size: 0.5rem;
  font-size: 1rem;
  padding: 0.3125rem 0.625rem 0.4375rem;
  text-align: center;
  display: inline-block;
  box-shadow: rgba(124, 180, 66, 0.7) 0rem 0.5rem 2.1875rem 0rem; }
  .support-ticket-btn:hover, .support-ticket-btn:focus {
    color: #fff; }

.text-blue {
  color: #5e72e4; }

.text-indigo {
  color: #6610f2; }

.text-purple {
  color: #BB6BD9; }

.text-pink {
  color: #e83e8c; }

.text-red {
  color: #EE3232; }

.text-orange {
  color: #ff9900; }

.text-yellow {
  color: #FFFA6F; }

.text-green {
  color: #297F00; }

.text-teal {
  color: #20c997; }

.text-cyan {
  color: #3065D0; }

.primary-text {
  color: #A0CF1A; }

.secondary-text {
  color: #F58869; }

.success-text {
  color: #53D0B2; }

.info-text {
  color: #81A4F9; }

.warning-text {
  color: #F4CF0C; }

.danger-text {
  color: #E95656; }

.light-text {
  color: #c8c8c8; }

.dark-text {
  color: #6e6e6e; }

/* .bg-gray-dark{
	background:$color_pallate_2 !important;
}
.bg-purpal{
	background:$color_pallate_4 !important;
}
.bg-red{
	background:$color_pallate_5 !important;
}
.bg-blue-dark{
	background:$color_pallate_6 !important;
} */
.bg-blue {
  background: #496ecc !important; }

.bg-orange {
  background: #ed8030 !important; }

.bg-green {
  background: #299e4a !important; }

.bg-purpel {
  background: #9517c1 !important; }

.bg-dark-blue {
  background: #251e71 !important; }

/* .bg-blue-light{
	background:$color_pallate_8 !important;
}
.bg-green-light{
	background:$color_pallate_9 !important;
}
.bg-green{
	background:$color_pallate_10 !important;
} */
.bg-black {
  background: #000; }

.text-black {
  color: #000 !important; }

.dz-scroll {
  position: relative; }

.scale1 {
  transform: scale(1.1);
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  display: inline-block; }

.scale2 {
  transform: scale(1.2);
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  display: inline-block; }

.scale3 {
  transform: scale(1.3);
  -moz-transform: scale(1.3);
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  -o-transform: scale(1.3);
  display: inline-block; }

.scale4 {
  transform: scale(1.4);
  -moz-transform: scale(1.4);
  -webkit-transform: scale(1.4);
  -ms-transform: scale(1.4);
  -o-transform: scale(1.4);
  display: inline-block; }

.scale5 {
  transform: scale(1.5);
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  -o-transform: scale(1.5);
  display: inline-block; }

.scale-2 {
  transform: scale(2);
  -moz-transform: scale(2);
  -webkit-transform: scale(2);
  -ms-transform: scale(2);
  -o-transform: scale(2);
  display: inline-block; }

@-webkit-keyframes crescendo {
  from {
    -webkit-transform: translateY(5px) scale(0.8);
    -ms-transform: translateY(5px) scale(0.8);
    transform: translateY(5px) scale(0.8); }
  to {
    -webkit-transform: translateY(0px) scale(1);
    -ms-transform: translateY(0px) scale(1);
    transform: translateY(0px) scale(1); } }

.height10 {
  height: 0.625rem; }

.height20 {
  height: 1.25rem; }

.height30 {
  height: 1.875rem; }

.height40 {
  height: 2.5rem; }

.height50 {
  height: 3.125rem; }

.height60 {
  height: 3.75rem; }

.height70 {
  height: 4.375rem; }

.height80 {
  height: 5rem; }

.height90 {
  height: 5.625rem; }

.height100 {
  height: 6.25rem; }

.height110 {
  height: 6.875rem; }

.height120 {
  height: 7.5rem; }

.height130 {
  height: 8.125rem; }

.height140 {
  height: 8.75rem; }

.height150 {
  height: 9.375rem; }

.height160 {
  height: 10rem; }

.height170 {
  height: 10.625rem; }

.height180 {
  height: 11.25rem; }

.height190 {
  height: 11.875rem; }

.height200 {
  height: 12.5rem; }

.height210 {
  height: 13.125rem; }

.height220 {
  height: 13.75rem; }

.height230 {
  height: 14.375rem; }

.height240 {
  height: 15rem; }

.height250 {
  height: 15.625rem; }

.height260 {
  height: 16.25rem; }

.height270 {
  height: 16.875rem; }

.height280 {
  height: 17.5rem; }

.height290 {
  height: 18.125rem; }

.height300 {
  height: 18.75rem; }

.height310 {
  height: 19.375rem; }

.height320 {
  height: 20rem; }

.height330 {
  height: 20.625rem; }

.height340 {
  height: 21.25rem; }

.height350 {
  height: 21.875rem; }

.height360 {
  height: 22.5rem; }

.height370 {
  height: 23.125rem; }

.height380 {
  height: 23.75rem; }

.height390 {
  height: 24.375rem; }

.height400 {
  height: 25rem; }

.height415 {
  height: 25.9375rem; }

.height430 {
  height: 27.1000rem; }

.height450 {
  height: 28.1000rem; }

.height500 {
  height: 31.25rem; }

.height550 {
  height: 34.375rem; }

.height600 {
  height: 37.5rem; }

.height630 {
  height: 39.375rem; }

.height720 {
  height: 45rem; }

.height750 {
  height: 46.875rem; }

.height800 {
  height: 50rem; }

.width10 {
  width: 0.625rem; }

.width20 {
  width: 1.25rem; }

.width30 {
  width: 1.875rem; }

.width40 {
  width: 2.5rem; }

.width50 {
  width: 3.125rem; }

.width60 {
  width: 3.75rem; }

.width70 {
  width: 4.375rem; }

.width80 {
  width: 5rem; }

.width90 {
  width: 5.625rem; }

.width100 {
  width: 6.25rem; }

.width110 {
  width: 6.875rem; }

.width120 {
  width: 7.5rem; }

.width130 {
  width: 8.125rem; }

.width140 {
  width: 8.75rem; }

.width150 {
  width: 9.375rem; }

.width160 {
  width: 10rem; }

.width170 {
  width: 10.625rem; }

.width180 {
  width: 11.25rem; }

.width190 {
  width: 11.875rem; }

.width200 {
  width: 12.5rem; }

.width210 {
  width: 13.125rem; }

.width220 {
  width: 13.75rem; }

.width230 {
  width: 14.375rem; }

.width240 {
  width: 15rem; }

.width250 {
  width: 15.625rem; }

.width260 {
  width: 16.25rem; }

.width270 {
  width: 16.875rem; }

.width280 {
  width: 17.5rem; }

.width290 {
  width: 18.125rem; }

.width300 {
  width: 18.75rem; }

.width310 {
  width: 19.375rem; }

.width320 {
  width: 20rem; }

.width330 {
  width: 20.625rem; }

.width340 {
  width: 21.25rem; }

.width350 {
  width: 21.875rem; }

.width360 {
  width: 22.5rem; }

.width370 {
  width: 23.125rem; }

.width380 {
  width: 23.75rem; }

.width390 {
  width: 24.375rem; }

.width400 {
  width: 25rem; }

.rounded {
  border-radius: 0.625rem !important; }

label {
  margin-bottom: 0.5rem; }

@keyframes gXGDoR {
  from {
    -webkit-transform: translateY(5px) scale(0.8);
    -ms-transform: translateY(5px) scale(0.8);
    transform: translateY(5px) scale(0.8); }
  to {
    -webkit-transform: translateY(0px) scale(1);
    -ms-transform: translateY(0px) scale(1);
    transform: translateY(0px) scale(1); } }

@keyframes crescendo {
  from {
    -webkit-transform: translateY(5px) scale(0.8);
    -ms-transform: translateY(5px) scale(0.8);
    transform: translateY(5px) scale(0.8); }
  to {
    -webkit-transform: translateY(0px) scale(1);
    -ms-transform: translateY(0px) scale(1);
    transform: translateY(0px) scale(1); } }

@keyframes gXGDoR {
  from {
    -webkit-transform: translateY(5px) scale(0.8);
    -ms-transform: translateY(5px) scale(0.8);
    transform: translateY(5px) scale(0.8); }
  to {
    -webkit-transform: translateY(0px) scale(1);
    -ms-transform: translateY(0px) scale(1);
    transform: translateY(0px) scale(1); } }

.body {
  font-size: 0.813rem; }

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important; } }

/* Editable */
#preloader {
  background-color: #fff;
  padding: 0;
  margin: 0;
  height: 100%;
  position: fixed;
  z-index: 99999;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }
  #preloader .dz-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px; }
  #preloader .dz-ripple div {
    position: absolute;
    border: 4px solid var(--primary);
    opacity: 1;
    border-radius: 50%;
    animation: dz-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite; }
  #preloader .dz-ripple div:nth-child(2) {
    animation-delay: -0.5s; }

@keyframes dz-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1; }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0; } }

[data-theme-version="dark"] #preloader {
  background-color: #1E1E1E; }

.footer {
  padding-right: 0;
  font-size: 0.813rem;
  font-weight: 400;
  margin-top: 1rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }
  .footer .copyright {
    padding: 0.9375rem; }
    .footer .copyright p {
      text-align: center;
      margin: 0;
      color: #918f8f; }
    .footer .copyright a {
      color: var(--primary); }
  .footer.home-footer {
    padding-right: 19.5rem; }
    @media only screen and (max-width: 87.5rem) {
      .footer.home-footer {
        padding-right: 0; } }

[data-sidebar-style="mini"] .nav-control,
[data-layout="horizontal"] .nav-control {
  display: none; }

[data-header-position="fixed"] .nav-header {
  position: fixed; }

.nav-header {
  /* height: 6.25rem;
  width: 15.75rem; */
  display: inline-block;
  text-align: left;
  position: absolute;
  top: 0;
  background-color: var(--nav-headbg);
  transition: all .2s ease;
  z-index: 5; }
  .nav-header .logo-compact {
    display: none; }
  .nav-header .brand-title-color {
    display: none; }
  .nav-header .brand-logo {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.125rem;
    color: #fff;
    text-decoration: none;
    padding-left: 1.3rem;
    padding-right: 1.3rem;
    font-weight: 700;
    padding-top: 11px; }
    @media only screen and (max-width: 87.5rem) {
      .nav-header .brand-logo {
        padding-left: 1.25rem;
        padding-right: 1.25rem; } }
    [data-sidebar-style="compact"] .nav-header .brand-logo,
    [data-sidebar-style="mini"] .nav-header .brand-logo {
      padding-left: 0;
      padding-right: 0;
      justify-content: center; }
    @media only screen and (max-width: 47.9375rem) {
      .nav-header .brand-logo {
        padding-left: 0;
        padding-right: 0;
        justify-content: center; } }
  .nav-header .logo-abbr {
    /* height:45px;
		width:45px; */ }
  .nav-header .logo-mini {
    display: none; }
  .nav-header .logo-color {
    display: none; }
    @media only screen and (max-width: 47.9375rem) {
      .nav-header .logo-color {
        display: block;
        /* height:45px;
			width:45px; */ } }
  .nav-header .brand-title {
    margin-left: 0.6375rem;
    max-width: 10.15rem;
    margin-top: 0.2rem;
    font-size: 2.375rem;
    color: #000; }
    [data-theme-version="dark"] .nav-header .brand-title {
      background-position: 0 120%; }
  @media only screen and (max-width: 47.9375rem) {
    .nav-header {
      top: 0;
      background: transparent; } }
  .nav-header .rect-primary-rect {
    fill: var(--primary); }

@media only screen and (max-width: 63.9375rem) {
  .nav-header {
    width: 5rem; }
    .nav-header .brand-title {
      display: none; } }

.nav-control {
  cursor: pointer;
  position: absolute;
  right: -5.4rem;
  text-align: center;
  top: 33px;
  z-index: 9999;
  font-size: 1.4rem;
  border-radius: 0.625rem;
  background-color: var(--primary);
  width: 2.813rem;
  height: 2.813rem; }
  @media only screen and (max-width: 100rem) {
    .nav-control {
      top: 29px; } }
  @media only screen and (max-width: 47.9375rem) {
    .nav-control {
      right: -4rem; } }

.hamburger {
  display: inline-block;
  left: 0.1rem;
  position: relative;
  top: 0.5rem;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  width: 1.625rem;
  z-index: 999; }

.hamburger .line {
  background: #fff;
  display: block;
  height: 1px;
  border-radius: 0.1875rem;
  margin-top: 0.375rem;
  margin-bottom: 0.375rem;
  margin-right: auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.hamburger svg {
  position: absolute;
  top: 9px;
  left: 0;
  z-index: 1; }

.hamburger.is-active .line {
  opacity: 1; }

.hamburger.is-active svg {
  opacity: 0; }

.hamburger .line:nth-child(1) {
  width: 1.25rem; }

.hamburger .line:nth-child(2) {
  width: 1.25rem; }

.hamburger .line:nth-child(3) {
  width: 1.25rem; }

.hamburger:hover {
  cursor: pointer; }

.hamburger.is-active .line:nth-child(1),
.hamburger.is-active .line:nth-child(3) {
  width: 0.625rem;
  height: 0.125rem; }

.hamburger.is-active .line:nth-child(2) {
  -webkit-transform: translateX(0px);
  transform: translateX(0px);
  width: 1.375rem;
  height: 0.125rem; }

.hamburger.is-active .line:nth-child(1) {
  -webkit-transform: translateY(4px) translateX(12px) rotate(45deg);
  transform: translateY(4px) translateX(12px) rotate(45deg); }

.hamburger.is-active .line:nth-child(3) {
  -webkit-transform: translateY(-4px) translateX(12px) rotate(-45deg);
  transform: translateY(-4px) translateX(12px) rotate(-45deg); }

@media (min-width: 47.9375rem) {
  [data-sidebar-style="compact"] .nav-control {
    display: none; }
  [data-sidebar-style="compact"] .nav-header {
    width: 11.25rem; } }

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .brand-title {
  display: none; }

[data-sidebar-style="full"][data-layout="horizontal"] .logo-compact {
  display: none; }

[data-sidebar-style="compact"][data-layout="vertical"] .nav-header .brand-title {
  display: none; }

[data-sidebar-style="compact"][data-layout="vertical"] .nav-header .logo-compact {
  max-width: 4.6875rem; }

[data-sidebar-style="compact"][data-layout="horizontal"] .nav-header .brand-logo {
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  justify-content: start; }

[data-sidebar-style="modern"][data-layout="vertical"] .nav-header {
  width: 10.625rem; }
  [data-sidebar-style="modern"][data-layout="vertical"] .nav-header .brand-title {
    display: none; }
  [data-sidebar-style="modern"][data-layout="vertical"] .nav-header .logo-compact {
    display: none; }

.outer-body {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: var(--sidebar-bg);
  padding-left: 15.75rem; }
  .outer-body .content-body {
    margin-left: 0; }

.inner-body {
  background-color: #F4F4F5;
  /* border-radius: 20px; */
  overflow-y: auto;
  height: 100%;
  border: 1px solid #000000;
margin-right: 10px; }

.navbar-expand .navbar-nav {
  align-items: center; }

.header {
  height: 6.25rem;
  z-index: 1;
  position: relative;
  padding: 0rem;
  background-color: var(--headerbg);
  border-bottom: 1px black solid;
  z-index: 3;
  padding-left: 15.75rem;
  padding-top: 10px;
  padding-right: 10px;
  transition: all .2s ease;
  /* &.is-fixed{
		background-color: #36246514;
		backdrop-filter: blur(6px);
	} */ }
  .header .header-content {
    height: 100%;
    padding-left: 6.75rem;
    padding-right: 2.5rem;
    align-items: center;
    display: flex;
    background-color: #F4F4F5;
    position: relative;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-top: 1px solid black !important;
    border-left: 1px solid black !important;
    border-right: 1px solid black !important; }
    @media only screen and (max-width: 47.9375rem) {
      .header .header-content {
        padding-left: 3.75rem;
        padding-right: 0.938rem; }
        .header .header-content:after {
          left: -5rem;
          width: calc(100% + 5rem); } }
  .header .navbar {
    padding: 0;
    height: 100%;
    width: 100%; }
    .header .navbar .navbar-collapse {
      height: 100%;
      width: 100%; }
  @media only screen and (max-width: 47.9375rem) {
    .header {
      padding-top: 10px; } }

[data-headerbg="color_2"] .header {
  background: var(--headerbg); }

[data-headerbg="color_3"] .header {
  background: var(--headerbg); }

[data-headerbg="color_4"] .header {
  background: var(--headerbg); }

[data-headerbg="color_5"] .header {
  background: var(--headerbg); }

[data-headerbg="color_6"] .header {
  background: var(--headerbg); }

[data-headerbg="color_7"] .header {
  background: var(--headerbg); }

[data-headerbg="color_8"] .header {
  background: var(--headerbg); }

[data-headerbg="color_9"] .header {
  background: var(--headerbg); }

[data-headerbg="color_10"] .header {
  background: var(--headerbg); }

[data-headerbg="color_11"] .header {
  background: var(--headerbg); }

[data-headerbg="color_12"] .header {
  background: var(--headerbg); }

[data-headerbg="color_13"] .header {
  background: var(--headerbg); }

[data-headerbg="color_14"] .header {
  background: var(--headerbg); }

[data-headerbg="color_15"] .header {
  background: var(--headerbg); }

/* pulse in SVG */
svg.pulse-svg {
  overflow: visible; }
  svg.pulse-svg .first-circle, svg.pulse-svg .second-circle, svg.pulse-svg .third-circle {
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation: pulse-me 3s linear infinite;
    animation: pulse-me 3s linear infinite;
    fill: var(--primary); }
  svg.pulse-svg .second-circle {
    -webkit-animation-delay: 1s;
    animation-delay: 1s; }
  svg.pulse-svg .third-circle {
    -webkit-animation-delay: 2s;
    animation-delay: 2s; }

/* pulse in CSS */
.pulse-css {
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
  border-radius: 3.5rem;
  height: 1.25rem;
  position: absolute;
  background: #fe8630;
  right: 0.375rem;
  top: 0.3125rem;
  border: 0.25rem solid #fff;
  width: 1.25rem; }
  .pulse-css:after, .pulse-css:before {
    content: '';
    width: 1rem;
    height: 1rem;
    border-radius: 0.5rem;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: -.2rem;
    background-color: #D8B9C3;
    margin: auto;
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation: pulse-me 3s linear infinite;
    animation: pulse-me 3s linear infinite; }
    [direction="rtl"] .pulse-css:after, [direction="rtl"] .pulse-css:before {
      left: auto;
      right: -.2rem; }
  @media only screen and (max-width: 87.5rem) {
    .pulse-css {
      height: 1rem;
      width: 1rem; } }

@-webkit-keyframes pulse-me {
  0% {
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
    opacity: 0; }
  50% {
    opacity: 0.1; }
  70% {
    opacity: 0.09; }
  100% {
    -webkit-transform: scale(3);
    transform: scale(3);
    opacity: 0; } }

@keyframes pulse-me {
  0% {
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
    opacity: 0; }
  50% {
    opacity: 0.1; }
  70% {
    opacity: 0.09; }
  100% {
    -webkit-transform: scale(3);
    transform: scale(3);
    opacity: 0; } }

[data-sidebar-style="full"] .header,
[data-sidebar-style="overlay"] .header {
  width: 100%; }
  @media only screen and (max-width: 87.5rem) {
    [data-sidebar-style="full"] .header,
    [data-sidebar-style="overlay"] .header {
      width: 100%;
      padding-left: 18.8rem; } }
  @media only screen and (max-width: 63.9375rem) {
    [data-sidebar-style="full"] .header,
    [data-sidebar-style="overlay"] .header {
      width: 100%;
      padding-left: 5rem; } }

[data-sidebar-style="mini"] .header {
  width: 100%;
  padding-left: 6.25rem; }
  @media only screen and (max-width: 63.9375rem) {
    [data-sidebar-style="mini"] .header {
      padding-left: 6.3rem; } }

[data-sidebar-style="compact"] .header {
  width: 100%;
  padding-left: 11.3rem; }
  [data-sidebar-style="compact"] .header .header-content {
    padding-left: 2.5rem; }

[data-header-position="fixed"] .header {
  position: fixed;
  top: 0;
  width: 100%; }

[data-header-position="fixed"] .content-body {
  padding-top: 5.65rem;
  /* @include respond('tab-land'){
			padding-top: 5rem;
		} */ }
  @media only screen and (max-width: 47.9375rem) {
    [data-header-position="fixed"] .content-body {
      padding-top: 5.5rem; } }

[data-header-position="fixed"] .deznav {
  margin-top: 0; }

[data-sidebar-style="compact"][data-header-position="fixed"][data-container="boxed"][data-layout="vertical"] .header {
  width: 74.9375rem; }

[data-sidebar-style="modern"] .header {
  width: 100%;
  padding-left: 10.6rem; }

[data-sidebar-style="modern"][data-layout="horizontal"] .nav-header .brand-logo {
  justify-content: start; }

[data-sidebar-style="modern"][data-layout="horizontal"] .header .header-content {
  padding-left: 1.875rem; }

.header-left {
  height: 100%;
  display: flex;
  align-items: center; }
  .header-left .breadcrumb {
    margin-bottom: 0; }
  .header-left .agenda a {
    border-radius: 1.75rem; }
    .header-left .agenda a i {
      margin-right: 0.75rem; }
      @media only screen and (max-width: 87.5rem) {
        .header-left .agenda a i {
          margin-right: 0rem; } }
    @media only screen and (max-width: 93.75rem) {
      .header-left .agenda a {
        font-size: 0;
        border-radius: 0.75rem;
        padding: 0.8125rem 0.9375rem; }
        .header-left .agenda a i {
          font-size: 1.25rem;
          margin-right: 0; } }
  @media only screen and (max-width: 35.9375rem) {
    .header-left .agenda {
      display: none; } }
  .header-left .dashboard_bar {
    font-size: 1.75rem;
    font-weight: 600;
    color: var(--secondary); }
    @media only screen and (max-width: 74.9375rem) {
      .header-left .dashboard_bar {
        font-size: 2.125rem; } }
    @media only screen and (max-width: 47.9375rem) {
      .header-left .dashboard_bar {
        display: none; } }
    .header-left .dashboard_bar.sub-bar {
      font-size: 1.875rem; }
  .header-left .search-area {
    width: 20.938rem;
    background-color: #fff;
    border-radius: 0.625rem; }
    @media only screen and (max-width: 100rem) {
      .header-left .search-area {
        width: 18.75rem; } }
    @media only screen and (max-width: 74.9375rem) {
      .header-left .search-area {
        width: 10.75rem; } }
    @media only screen and (max-width: 47.9375rem) {
      .header-left .search-area {
        display: none; } }
    .header-left .search-area .form-control {
      border: 0;
      background: transparent;
      font-size: 0.875rem;
      font-weight: 400;
      padding-left: 4px; }
      @media only screen and (max-width: 87.5rem) {
        .header-left .search-area .form-control {
          height: 3rem; } }
    .header-left .search-area .input-group-text {
      background-color: transparent;
      border: 0;
      padding-right: 0; }
      .header-left .search-area .input-group-text a i {
        font-size: 1.5rem; }
      @media only screen and (max-width: 87.5rem) {
        .header-left .search-area .input-group-text {
          height: 3rem; } }

[data-headerbg="color_4"] .search-area {
  background-color: #202020 !important; }

[data-sidebar-style="compact"] .header-left {
  margin-left: 0; }

.header-right {
  height: 100%; }
  .header-right .header-profile {
    position: relative !important; }
    .header-right .header-profile .dropdown-menu:after {
      content: none; }
    .header-right .header-profile img {
      width: 45px; }
      @media only screen and (max-width: 87.5rem) {
        .header-right .header-profile img {
          width: unset; } }
  .header-right .nav-item {
    display: flex;
    justify-content: center;
    position: relative; }
    .header-right .nav-item .nav-link {
      color: #464a53;
      font-size: 1.125rem; }
    @media only screen and (max-width: 35.9375rem) {
      .header-right .nav-item {
        padding-left: 0 !important; } }
  .header-right .right-sidebar {
    margin-right: -1.875rem; }
    .header-right .right-sidebar a {
      height: 5rem;
      width: 5rem;
      text-align: center;
      justify-content: center;
      display: flex;
      align-items: center;
      border-left: 0.0625rem solid #EEEEEE; }
  @media only screen and (max-width: 47.9375rem) {
    .header-right .notification_dropdown {
      position: relative; } }
  .header-right .notification_dropdown .nav-link {
    position: relative;
    line-height: 1.6;
    height: 45px;
    width: 45px;
    margin: 0 6px;
    text-align: center;
    border-radius: 0.625rem; }
    @media only screen and (max-width: 35.9375rem) {
      .header-right .notification_dropdown .nav-link {
        height: 40px;
        width: 40px;
        margin: 0 7px;
        margin-left: 0px; } }
    .header-right .notification_dropdown .nav-link i {
      font-size: 1.5rem; }
      @media only screen and (max-width: 87.5rem) {
        .header-right .notification_dropdown .nav-link i {
          font-size: 1.125rem; } }
    @media only screen and (max-width: 35.9375rem) {
      .header-right .notification_dropdown .nav-link svg {
        width: 1.25rem;
        height: 1.25rem; } }
    .header-right .notification_dropdown .nav-link:hover {
      background: white; }
  .header-right .notification_dropdown .dropdown-item:focus a, .header-right .notification_dropdown .dropdown-item:active a {
    color: #fff; }
  .header-right .notification_dropdown .dropdown-item a {
    color: #333333; }
    .header-right .notification_dropdown .dropdown-item a:hover {
      text-decoration: none; }
  [data-theme-version="dark"] .header-right .dropdown-menu {
    box-shadow: none; }
  @media only screen and (max-width: 74.9375rem) {
    .header-right .dropdown-menu:after {
      content: none; } }
  .header-right .search-area {
    width: 25rem; }
    .header-right .search-area .form-control {
      background: #fff;
      border: 0;
      padding-left: 0;
      border-radius: 0.75rem; }
    .header-right .search-area ::placeholder {
      color: #A098AE;
      font-size: 16px;
      font-weight: 400; }
    .header-right .search-area .input-group-text {
      border-radius: 0.75rem;
      background: #fff;
      padding: 0px 5px;
      border: 0;
      padding-right: 0; }
      .header-right .search-area .input-group-text i {
        font-size: 1.5rem; }
      .header-right .search-area .input-group-text svg path {
        fill: var(--primary); }
    @media only screen and (max-width: 100rem) {
      .header-right .search-area {
        width: 15.625rem; } }
    @media only screen and (max-width: 74.9375rem) {
      .header-right .search-area {
        display: none; } }
  .header-right .header-media {
    width: 2.813rem;
    height: 2.813rem;
    margin-right: 7px; }
    .header-right .header-media img {
      border-radius: 100%; }
  .header-right .header-info h6, .header-right .header-info .h6 {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 4px;
    line-height: 1; }
    @media only screen and (max-width: 87.5rem) {
      .header-right .header-info h6, .header-right .header-info .h6 {
        margin-bottom: 4px;
        font-size: 13px; } }
  .header-right .header-info p {
    font-size: 12px;
    font-weight: 400;
    line-height: 1;
    margin-bottom: 4px; }
  .header-right .header-border {
    margin-left: 2.5rem;
    padding-right: 1.25rem; }
    .header-right .header-border:hover {
      background: transparent; }
    @media only screen and (max-width: 87.5rem) {
      .header-right .header-border {
        margin-left: 1.875rem; } }
    @media only screen and (max-width: 35.9375rem) {
      .header-right .header-border {
        display: none; } }
  .header-right .blur-img {
    position: absolute;
    left: 27%;
    top: 0;
    filter: blur(554px); }

.dz-fullscreen #icon-minimize {
  display: none; }

.dz-fullscreen.active #icon-full {
  display: none; }

.dz-fullscreen.active #icon-minimize {
  display: inline-block; }

.notification_dropdown .dropdown-menu-end {
  min-width: 18.5rem;
  padding: 1rem 0 0rem;
  top: 100%; }
  .notification_dropdown .dropdown-menu-end.style-1 {
    min-width: 18.5rem; }
  .notification_dropdown .dropdown-menu-end.show {
    overflow: unset; }
  .notification_dropdown .dropdown-menu-end .notification_title {
    background: var(--primary);
    color: #fff;
    padding: 0.625rem 1.25rem; }
    .notification_dropdown .dropdown-menu-end .notification_title h5, .notification_dropdown .dropdown-menu-end .notification_title .h5 {
      color: #fff;
      margin-bottom: 0.1875rem; }
  .notification_dropdown .dropdown-menu-end .media {
    width: 2.8125rem;
    height: 2.8125rem;
    font-size: 1.125rem; }
    [data-theme-version="dark"] .notification_dropdown .dropdown-menu-end .media {
      border-color: #444444; }
    .notification_dropdown .dropdown-menu-end .media > span {
      width: 2.1875rem;
      height: 2.1875rem;
      border-radius: 3.125rem;
      display: inline-block;
      padding: 0.4375rem 0.5625rem;
      margin-right: 0.625rem; }
      [direction="rtl"].notification_dropdown .dropdown-menu-end .media > span {
        margin-right: 0;
        margin-left: 0.625rem; }
      .notification_dropdown .dropdown-menu-end .media > span.success {
        background: #A7FFEA;
        color: #53D0B2; }
      .notification_dropdown .dropdown-menu-end .media > span.primary {
        background: var(--rgba-primary-1);
        color: var(--primary); }
      .notification_dropdown .dropdown-menu-end .media > span.danger {
        background: #FFCCC0;
        color: #FF7C7C; }
    .notification_dropdown .dropdown-menu-end .media .notify-time {
      width: 100%;
      margin-right: 0;
      color: #828690; }
    .notification_dropdown .dropdown-menu-end .media p {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 12.5rem;
      margin-bottom: 0;
      margin-top: 0.3125rem; }
      @media only screen and (max-width: 35.9375rem) {
        .notification_dropdown .dropdown-menu-end .media p {
          max-width: 6.25rem; } }
  .notification_dropdown .dropdown-menu-end .all-notification {
    display: block;
    padding: 0.6375rem 1.775rem 0.6375rem;
    color: var(--primary);
    text-align: center;
    border-top: 0.0625rem solid #f1f1f1;
    font-size: 0.813rem;
    border-radius: 0 0 0.625rem 0.625rem;
    background: white; }
    .notification_dropdown .dropdown-menu-end .all-notification i {
      margin-left: 0.625rem; }
  @media only screen and (max-width: 47.9375rem) {
    .notification_dropdown .dropdown-menu-end {
      right: 0;
      height: 29rem;
      top: 45px;
      transform: translateX(0);
      padding-right: 0;
      width: 18rem;
      display: none !important;
      border-radius: 0.625rem; }
      .notification_dropdown .dropdown-menu-end.show {
        display: block !important; } }
  @media only screen and (max-width: 35.9375rem) {
    .notification_dropdown .dropdown-menu-end {
      min-width: 18.5rem;
      right: -6rem;
      top: 58px; } }

.header-profile > a.nav-link {
  padding: 0;
  display: flex;
  align-items: center; }
  .header-profile > a.nav-link i {
    font-weight: 700; }
  .header-profile > a.nav-link .header-info {
    padding-left: 0rem;
    text-align: left; }
    .header-profile > a.nav-link .header-info span {
      font-size: 1rem;
      color: #000;
      display: block;
      font-weight: 600; }
    .header-profile > a.nav-link .header-info strong {
      color: #333333; }
    .header-profile > a.nav-link .header-info small, .header-profile > a.nav-link .header-info .small {
      display: block;
      font-size: 0.8125rem;
      color: #89879f;
      font-weight: 400;
      line-height: 1.2; }
    @media only screen and (max-width: 35.9375rem) {
      .header-profile > a.nav-link .header-info {
        display: none; } }
  @media only screen and (max-width: 87.5rem) {
    .header-profile > a.nav-link {
      margin-left: 0rem;
      padding-left: 0rem; }
      .header-profile > a.nav-link .header-info span {
        font-size: 1rem; } }
  @media only screen and (max-width: 35.9375rem) {
    .header-profile > a.nav-link {
      margin-left: 0rem;
      padding-left: 0rem;
      border-left: 0; } }

.header-profile .dropdown-menu {
  padding: 0.9375rem 0;
  min-width: 12.5rem;
  display: block;
  transform: scale(0.01);
  transform-origin: center top;
  opacity: 0;
  transition: transform 0.3s ease, opacity 0.3s ease, box-shadow 0.3s ease 0.15s; }
  .header-profile .dropdown-menu a:hover, .header-profile .dropdown-menu a:focus, .header-profile .dropdown-menu a.active {
    color: var(--primary); }

.header-profile .dropdown-menu.dropdown-menu-end.show {
  transform: scale(1);
  opacity: 1;
  box-shadow: 3px 3px 30px rgba(118, 96, 168, 0.15); }

.header-profile img {
  border: 3px solid var(--primary);
  border-radius: 0.938rem; }
  @media only screen and (max-width: 87.5rem) {
    .header-profile img {
      width: 2.4375rem;
      height: 2.4375rem; } }
  @media only screen and (max-width: 47.9375rem) {
    .header-profile img {
      border: 2px solid var(--primary);
      border-radius: 0.625rem; } }

.header-profile .dropdown-toggle i {
  font-size: 1.25rem; }

@media only screen and (max-width: 35.9375rem) {
  .header-profile .dropdown-toggle span {
    display: none; } }

.header-profile .profile_title {
  background: var(--primary);
  color: #fff;
  padding: 0.625rem 1.25rem; }
  .header-profile .profile_title h5, .header-profile .profile_title .h5 {
    color: #fff;
    margin-bottom: 0.1875rem; }

.header-profile .dropdown-item {
  padding: 0.5rem 1.5rem; }

[data-container="boxed"] .search-area {
  display: none !important; }

@media only screen and (max-width: 35.9375rem) {
  .header-profile2 .header-info {
    display: none; } }

.rtl {
  text-align: right;
  direction: rtl; }
  .rtl .nav {
    padding-right: 0; }
  .rtl .navbar-nav .nav-item {
    float: right; }
  .rtl .navbar-nav .nav-item + .nav-item {
    margin-right: 1rem;
    margin-left: inherit; }
  .rtl th {
    text-align: right; }
  .rtl .alert-dismissible {
    padding-right: 1.25rem;
    padding-left: 4rem; }
  .rtl .dropdown-menu {
    right: 0;
    text-align: right; }
  .rtl .checkbox label {
    padding-right: 1.25rem;
    padding-left: inherit; }
  .rtl .btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-radius: 0 0.75rem 0.75rem 0; }
  .rtl .btn-group > .btn:last-child:not(:first-child),
  .rtl .btn-group > .dropdown-toggle:not(:first-child) {
    border-radius: 0.75rem 0 0 0.75rem; }
  .rtl .btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child {
    border-radius: 0.75rem 0 0 0.75rem; }
  .rtl .custom-control-label::after,
  .rtl .custom-control-label::before {
    right: 0;
    left: inherit; }
  .rtl .custom-select {
    padding: 0.375rem 0.75rem 0.375rem 1.75rem;
    background: #F4F4F5 url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat left 0.75rem center;
    background-size: 8px 10px; }
  .rtl .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
  .rtl .input-group > .input-group-append:last-child > .input-group-text:not(:last-child),
  .rtl .input-group > .input-group-append:not(:last-child) > .btn,
  .rtl .input-group > .input-group-append:not(:last-child) > .input-group-text,
  .rtl .input-group > .input-group-prepend > .btn,
  .rtl .input-group > .input-group-prepend > .input-group-text {
    border-radius: 0 0.75rem 0.75rem 0; }
  .rtl .input-group > .input-group-append > .btn,
  .rtl .input-group > .input-group-append > .input-group-text,
  .rtl .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
  .rtl .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child),
  .rtl .input-group > .input-group-prepend:not(:first-child) > .btn,
  .rtl .input-group > .input-group-prepend:not(:first-child) > .input-group-text {
    border-radius: 0.75rem 0 0 0.75rem; }
  .rtl .input-group > .custom-select:not(:first-child),
  .rtl .input-group > .form-control:not(:first-child) {
    border-radius: 0.75rem 0 0 0.75rem; }
  .rtl .input-group > .custom-select:not(:last-child),
  .rtl .input-group > .form-control:not(:last-child) {
    border-radius: 0 0.75rem 0.75rem 0; }
  .rtl .input-group > .custom-select:not(:last-child):not(:first-child),
  .rtl .input-group > .form-control:not(:last-child):not(:first-child) {
    border-radius: 0; }
  .rtl .custom-control {
    padding-right: 1.5rem;
    padding-left: inherit;
    margin-right: inherit;
    margin-left: 1rem; }
  .rtl .custom-control-indicator {
    right: 0;
    left: inherit; }
  .rtl .custom-file-label::after {
    right: initial;
    left: -1px;
    border-radius: .25rem 0 0 .25rem; }
  .rtl .radio input,
  .rtl .radio-inline,
  .rtl .checkbox input,
  .rtl .checkbox-inline input {
    margin-right: -1.25rem;
    margin-left: inherit; }
  .rtl .list-group {
    padding-right: 0;
    padding-left: 40px; }
  .rtl .close {
    float: left; }
  .rtl .modal-header .close {
    margin: -15px auto -15px -15px; }
  .rtl .modal-footer > :not(:first-child) {
    margin-right: .25rem; }
  .rtl .alert-dismissible .close {
    right: inherit;
    left: 0; }
  .rtl .dropdown-toggle::after {
    margin-right: .255em;
    margin-left: 0; }
  .rtl .form-check-input {
    margin-right: -1.25rem;
    margin-left: inherit; }
  .rtl .form-check-label {
    padding-right: 1.25rem;
    padding-left: inherit; }
  .rtl .offset-1 {
    margin-right: 8.33333%;
    margin-left: 0; }
  .rtl .offset-2 {
    margin-right: 16.66667%;
    margin-left: 0; }
  .rtl .offset-3 {
    margin-right: 25%;
    margin-left: 0; }
  .rtl .offset-4 {
    margin-right: 33.33333%;
    margin-left: 0; }
  .rtl .offset-5 {
    margin-right: 41.66667%;
    margin-left: 0; }
  .rtl .offset-6 {
    margin-right: 50%;
    margin-left: 0; }
  .rtl .offset-7 {
    margin-right: 58.33333%;
    margin-left: 0; }
  .rtl .offset-8 {
    margin-right: 66.66667%;
    margin-left: 0; }
  .rtl .offset-9 {
    margin-right: 75%;
    margin-left: 0; }
  .rtl .offset-10 {
    margin-right: 83.33333%;
    margin-left: 0; }
  .rtl .offset-11 {
    margin-right: 91.66667%;
    margin-left: 0; }
  @media (min-width: 576px) {
    .rtl .offset-sm-0 {
      margin-right: 0;
      margin-left: 0; }
    .rtl .offset-sm-1 {
      margin-right: 8.33333%;
      margin-left: 0; }
    .rtl .offset-sm-2 {
      margin-right: 16.66667%;
      margin-left: 0; }
    .rtl .offset-sm-3 {
      margin-right: 25%;
      margin-left: 0; }
    .rtl .offset-sm-4 {
      margin-right: 33.33333%;
      margin-left: 0; }
    .rtl .offset-sm-5 {
      margin-right: 41.66667%;
      margin-left: 0; }
    .rtl .offset-sm-6 {
      margin-right: 50%;
      margin-left: 0; }
    .rtl .offset-sm-7 {
      margin-right: 58.33333%;
      margin-left: 0; }
    .rtl .offset-sm-8 {
      margin-right: 66.66667%;
      margin-left: 0; }
    .rtl .offset-sm-9 {
      margin-right: 75%;
      margin-left: 0; }
    .rtl .offset-sm-10 {
      margin-right: 83.33333%;
      margin-left: 0; }
    .rtl .offset-sm-11 {
      margin-right: 91.66667%;
      margin-left: 0; } }
  @media (min-width: 768px) {
    .rtl .offset-md-0 {
      margin-right: 0;
      margin-left: 0; }
    .rtl .offset-md-1 {
      margin-right: 8.33333%;
      margin-left: 0; }
    .rtl .offset-md-2 {
      margin-right: 16.66667%;
      margin-left: 0; }
    .rtl .offset-md-3 {
      margin-right: 25%;
      margin-left: 0; }
    .rtl .offset-md-4 {
      margin-right: 33.33333%;
      margin-left: 0; }
    .rtl .offset-md-5 {
      margin-right: 41.66667%;
      margin-left: 0; }
    .rtl .offset-md-6 {
      margin-right: 50%;
      margin-left: 0; }
    .rtl .offset-md-7 {
      margin-right: 58.33333%;
      margin-left: 0; }
    .rtl .offset-md-8 {
      margin-right: 66.66667%;
      margin-left: 0; }
    .rtl .offset-md-9 {
      margin-right: 75%;
      margin-left: 0; }
    .rtl .offset-md-10 {
      margin-right: 83.33333%;
      margin-left: 0; }
    .rtl .offset-md-11 {
      margin-right: 91.66667%;
      margin-left: 0; } }
  @media (min-width: 992px) {
    .rtl .offset-lg-0 {
      margin-right: 0;
      margin-left: 0; }
    .rtl .offset-lg-1 {
      margin-right: 8.33333%;
      margin-left: 0; }
    .rtl .offset-lg-2 {
      margin-right: 16.66667%;
      margin-left: 0; }
    .rtl .offset-lg-3 {
      margin-right: 25%;
      margin-left: 0; }
    .rtl .offset-lg-4 {
      margin-right: 33.33333%;
      margin-left: 0; }
    .rtl .offset-lg-5 {
      margin-right: 41.66667%;
      margin-left: 0; }
    .rtl .offset-lg-6 {
      margin-right: 50%;
      margin-left: 0; }
    .rtl .offset-lg-7 {
      margin-right: 58.33333%;
      margin-left: 0; }
    .rtl .offset-lg-8 {
      margin-right: 66.66667%;
      margin-left: 0; }
    .rtl .offset-lg-9 {
      margin-right: 75%;
      margin-left: 0; }
    .rtl .offset-lg-10 {
      margin-right: 83.33333%;
      margin-left: 0; }
    .rtl .offset-lg-11 {
      margin-right: 91.66667%;
      margin-left: 0; } }
  @media (min-width: 1200px) {
    .rtl .offset-xl-0 {
      margin-right: 0;
      margin-left: 0; }
    .rtl .offset-xl-1 {
      margin-right: 8.33333%;
      margin-left: 0; }
    .rtl .offset-xl-2 {
      margin-right: 16.66667%;
      margin-left: 0; }
    .rtl .offset-xl-3 {
      margin-right: 25%;
      margin-left: 0; }
    .rtl .offset-xl-4 {
      margin-right: 33.33333%;
      margin-left: 0; }
    .rtl .offset-xl-5 {
      margin-right: 41.66667%;
      margin-left: 0; }
    .rtl .offset-xl-6 {
      margin-right: 50%;
      margin-left: 0; }
    .rtl .offset-xl-7 {
      margin-right: 58.33333%;
      margin-left: 0; }
    .rtl .offset-xl-8 {
      margin-right: 66.66667%;
      margin-left: 0; }
    .rtl .offset-xl-9 {
      margin-right: 75%;
      margin-left: 0; }
    .rtl .offset-xl-10 {
      margin-right: 83.33333%;
      margin-left: 0; }
    .rtl .offset-xl-11 {
      margin-right: 91.66667%;
      margin-left: 0; } }
  @media (min-width: 1440) {
    .rtl .offset-xxl-0 {
      margin-right: 0;
      margin-left: 0; }
    .rtl .offset-xxl-1 {
      margin-right: 8.33333%;
      margin-left: 0; }
    .rtl .offset-xxl-2 {
      margin-right: 16.66667%;
      margin-left: 0; }
    .rtl .offset-xxl-3 {
      margin-right: 25%;
      margin-left: 0; }
    .rtl .offset-xxl-4 {
      margin-right: 33.33333%;
      margin-left: 0; }
    .rtl .offset-xxl-5 {
      margin-right: 41.66667%;
      margin-left: 0; }
    .rtl .offset-xxl-6 {
      margin-right: 50%;
      margin-left: 0; }
    .rtl .offset-xxl-7 {
      margin-right: 58.33333%;
      margin-left: 0; }
    .rtl .offset-xxl-8 {
      margin-right: 66.66667%;
      margin-left: 0; }
    .rtl .offset-xxl-9 {
      margin-right: 75%;
      margin-left: 0; }
    .rtl .offset-xxl-10 {
      margin-right: 83.33333%;
      margin-left: 0; }
    .rtl .offset-xxl-11 {
      margin-right: 91.66667%;
      margin-left: 0; } }
  .rtl .mr-0,
  .rtl .mx-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .rtl .ml-0,
  .rtl .mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .rtl .mr-1,
  .rtl .mx-1 {
    margin-right: 0 !important;
    margin-left: 0.25rem !important; }
  .rtl .ml-1,
  .rtl .mx-1 {
    margin-left: 0 !important;
    margin-right: 0.25rem !important; }
  .rtl .mr-2,
  .rtl .mx-2 {
    margin-right: 0 !important;
    margin-left: 0.5rem !important; }
  .rtl .ml-2,
  .rtl .mx-2 {
    margin-left: 0 !important;
    margin-right: 0.5rem !important; }
  .rtl .mr-3,
  .rtl .mx-3 {
    margin-right: 0 !important;
    margin-left: 1rem !important; }
  .rtl .ml-3,
  .rtl .mx-3 {
    margin-left: 0 !important;
    margin-right: 1rem !important; }
  .rtl .mr-4,
  .rtl .mx-4 {
    margin-right: 0 !important;
    margin-left: 1.5rem !important; }
  .rtl .ml-4,
  .rtl .mx-4 {
    margin-left: 0 !important;
    margin-right: 1.5rem !important; }
  .rtl .mr-5,
  .rtl .mx-5 {
    margin-right: 0 !important;
    margin-left: 3rem !important; }
  .rtl .ml-5,
  .rtl .mx-5 {
    margin-left: 0 !important;
    margin-right: 3rem !important; }
  .rtl .pr-0,
  .rtl .px-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .rtl .pl-0,
  .rtl .px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .rtl .pr-1,
  .rtl .px-1 {
    padding-right: 0 !important;
    padding-left: 0.25rem !important; }
  .rtl .pl-1,
  .rtl .px-1 {
    padding-left: 0 !important;
    padding-right: 0.25rem !important; }
  .rtl .pr-2,
  .rtl .px-2 {
    padding-right: 0 !important;
    padding-left: 0.5rem !important; }
  .rtl .pl-2,
  .rtl .px-2 {
    padding-left: 0 !important;
    padding-right: 0.5rem !important; }
  .rtl .pr-3,
  .rtl .px-3 {
    padding-right: 0 !important;
    padding-left: 1rem !important; }
  .rtl .pl-3,
  .rtl .px-3 {
    padding-left: 0 !important;
    padding-right: 1rem !important; }
  .rtl .pr-4,
  .rtl .px-4 {
    padding-right: 0 !important;
    padding-left: 1.5rem !important; }
  .rtl .pl-4,
  .rtl .px-4 {
    padding-left: 0 !important;
    padding-right: 1.5rem !important; }
  .rtl .pr-5,
  .rtl .px-5 {
    padding-right: 0 !important;
    padding-left: 3rem !important; }
  .rtl .pl-5,
  .rtl .px-5 {
    padding-left: 0 !important;
    padding-right: 3rem !important; }
  .rtl .mr-auto {
    margin-right: 0 !important;
    margin-left: auto !important; }
  .rtl .ml-auto {
    margin-right: auto !important;
    margin-left: 0 !important; }
  .rtl .mx-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  @media (min-width: 576px) {
    .rtl .mr-sm-0,
    .rtl .mx-sm-0 {
      margin-right: 0 !important;
      margin-left: 0 !important; }
    .rtl .ml-sm-0,
    .rtl .mx-sm-0 {
      margin-left: 0 !important;
      margin-right: 0 !important; }
    .rtl .mr-sm-1,
    .rtl .mx-sm-1 {
      margin-right: 0 !important;
      margin-left: 0.25rem !important; }
    .rtl .ml-sm-1,
    .rtl .mx-sm-1 {
      margin-left: 0 !important;
      margin-right: 0.25rem !important; }
    .rtl .mr-sm-2,
    .rtl .mx-sm-2 {
      margin-right: 0 !important;
      margin-left: 0.5rem !important; }
    .rtl .ml-sm-2,
    .rtl .mx-sm-2 {
      margin-left: 0 !important;
      margin-right: 0.5rem !important; }
    .rtl .mr-sm-3,
    .rtl .mx-sm-3 {
      margin-right: 0 !important;
      margin-left: 1rem !important; }
    .rtl .ml-sm-3,
    .rtl .mx-sm-3 {
      margin-left: 0 !important;
      margin-right: 1rem !important; }
    .rtl .mr-sm-4,
    .rtl .mx-sm-4 {
      margin-right: 0 !important;
      margin-left: 1.5rem !important; }
    .rtl .ml-sm-4,
    .rtl .mx-sm-4 {
      margin-left: 0 !important;
      margin-right: 1.5rem !important; }
    .rtl .mr-sm-5,
    .rtl .mx-sm-5 {
      margin-right: 0 !important;
      margin-left: 3rem !important; }
    .rtl .ml-sm-5,
    .rtl .mx-sm-5 {
      margin-left: 0 !important;
      margin-right: 3rem !important; }
    .rtl .pr-sm-0,
    .rtl .px-sm-0 {
      padding-right: 0 !important;
      padding-left: 0 !important; }
    .rtl .pl-sm-0,
    .rtl .px-sm-0 {
      padding-left: 0 !important;
      padding-right: 0 !important; }
    .rtl .pr-sm-1,
    .rtl .px-sm-1 {
      padding-right: 0 !important;
      padding-left: 0.25rem !important; }
    .rtl .pl-sm-1,
    .rtl .px-sm-1 {
      padding-left: 0 !important;
      padding-right: 0.25rem !important; }
    .rtl .pr-sm-2,
    .rtl .px-sm-2 {
      padding-right: 0 !important;
      padding-left: 0.5rem !important; }
    .rtl .pl-sm-2,
    .rtl .px-sm-2 {
      padding-left: 0 !important;
      padding-right: 0.5rem !important; }
    .rtl .pr-sm-3,
    .rtl .px-sm-3 {
      padding-right: 0 !important;
      padding-left: 1rem !important; }
    .rtl .pl-sm-3,
    .rtl .px-sm-3 {
      padding-left: 0 !important;
      padding-right: 1rem !important; }
    .rtl .pr-sm-4,
    .rtl .px-sm-4 {
      padding-right: 0 !important;
      padding-left: 1.5rem !important; }
    .rtl .pl-sm-4,
    .rtl .px-sm-4 {
      padding-left: 0 !important;
      padding-right: 1.5rem !important; }
    .rtl .pr-sm-5,
    .rtl .px-sm-5 {
      padding-right: 0 !important;
      padding-left: 3rem !important; }
    .rtl .pl-sm-5,
    .rtl .px-sm-5 {
      padding-left: 0 !important;
      padding-right: 3rem !important; }
    .rtl .mr-sm-auto {
      margin-right: 0 !important;
      margin-left: auto !important; }
    .rtl .ml-sm-auto {
      margin-right: auto !important;
      margin-left: 0 !important; }
    .rtl .mx-sm-auto {
      margin-right: auto !important;
      margin-left: auto !important; } }
  @media (min-width: 768px) {
    .rtl .mr-md-0,
    .rtl .mx-md-0 {
      margin-right: 0 !important;
      margin-left: 0 !important; }
    .rtl .ml-md-0,
    .rtl .mx-md-0 {
      margin-left: 0 !important;
      margin-right: 0 !important; }
    .rtl .mr-md-1,
    .rtl .mx-md-1 {
      margin-right: 0 !important;
      margin-left: 0.25rem !important; }
    .rtl .ml-md-1,
    .rtl .mx-md-1 {
      margin-left: 0 !important;
      margin-right: 0.25rem !important; }
    .rtl .mr-md-2,
    .rtl .mx-md-2 {
      margin-right: 0 !important;
      margin-left: 0.5rem !important; }
    .rtl .ml-md-2,
    .rtl .mx-md-2 {
      margin-left: 0 !important;
      margin-right: 0.5rem !important; }
    .rtl .mr-md-3,
    .rtl .mx-md-3 {
      margin-right: 0 !important;
      margin-left: 1rem !important; }
    .rtl .ml-md-3,
    .rtl .mx-md-3 {
      margin-left: 0 !important;
      margin-right: 1rem !important; }
    .rtl .mr-md-4,
    .rtl .mx-md-4 {
      margin-right: 0 !important;
      margin-left: 1.5rem !important; }
    .rtl .ml-md-4,
    .rtl .mx-md-4 {
      margin-left: 0 !important;
      margin-right: 1.5rem !important; }
    .rtl .mr-md-5,
    .rtl .mx-md-5 {
      margin-right: 0 !important;
      margin-left: 3rem !important; }
    .rtl .ml-md-5,
    .rtl .mx-md-5 {
      margin-left: 0 !important;
      margin-right: 3rem !important; }
    .rtl .pr-md-0,
    .rtl .px-md-0 {
      padding-right: 0 !important;
      padding-left: 0 !important; }
    .rtl .pl-md-0,
    .rtl .px-md-0 {
      padding-left: 0 !important;
      padding-right: 0 !important; }
    .rtl .pr-md-1,
    .rtl .px-md-1 {
      padding-right: 0 !important;
      padding-left: 0.25rem !important; }
    .rtl .pl-md-1,
    .rtl .px-md-1 {
      padding-left: 0 !important;
      padding-right: 0.25rem !important; }
    .rtl .pr-md-2,
    .rtl .px-md-2 {
      padding-right: 0 !important;
      padding-left: 0.5rem !important; }
    .rtl .pl-md-2,
    .rtl .px-md-2 {
      padding-left: 0 !important;
      padding-right: 0.5rem !important; }
    .rtl .pr-md-3,
    .rtl .px-md-3 {
      padding-right: 0 !important;
      padding-left: 1rem !important; }
    .rtl .pl-md-3,
    .rtl .px-md-3 {
      padding-left: 0 !important;
      padding-right: 1rem !important; }
    .rtl .pr-md-4,
    .rtl .px-md-4 {
      padding-right: 0 !important;
      padding-left: 1.5rem !important; }
    .rtl .pl-md-4,
    .rtl .px-md-4 {
      padding-left: 0 !important;
      padding-right: 1.5rem !important; }
    .rtl .pr-md-5,
    .rtl .px-md-5 {
      padding-right: 0 !important;
      padding-left: 3rem !important; }
    .rtl .pl-md-5,
    .rtl .px-md-5 {
      padding-left: 0 !important;
      padding-right: 3rem !important; }
    .rtl .mr-md-auto {
      margin-right: 0 !important;
      margin-left: auto !important; }
    .rtl .ml-md-auto {
      margin-right: auto !important;
      margin-left: 0 !important; }
    .rtl .mx-md-auto {
      margin-right: auto !important;
      margin-left: auto !important; } }
  @media (min-width: 992px) {
    .rtl .mr-lg-0,
    .rtl .mx-lg-0 {
      margin-right: 0 !important;
      margin-left: 0 !important; }
    .rtl .ml-lg-0,
    .rtl .mx-lg-0 {
      margin-left: 0 !important;
      margin-right: 0 !important; }
    .rtl .mr-lg-1,
    .rtl .mx-lg-1 {
      margin-right: 0 !important;
      margin-left: 0.25rem !important; }
    .rtl .ml-lg-1,
    .rtl .mx-lg-1 {
      margin-left: 0 !important;
      margin-right: 0.25rem !important; }
    .rtl .mr-lg-2,
    .rtl .mx-lg-2 {
      margin-right: 0 !important;
      margin-left: 0.5rem !important; }
    .rtl .ml-lg-2,
    .rtl .mx-lg-2 {
      margin-left: 0 !important;
      margin-right: 0.5rem !important; }
    .rtl .mr-lg-3,
    .rtl .mx-lg-3 {
      margin-right: 0 !important;
      margin-left: 1rem !important; }
    .rtl .ml-lg-3,
    .rtl .mx-lg-3 {
      margin-left: 0 !important;
      margin-right: 1rem !important; }
    .rtl .mr-lg-4,
    .rtl .mx-lg-4 {
      margin-right: 0 !important;
      margin-left: 1.5rem !important; }
    .rtl .ml-lg-4,
    .rtl .mx-lg-4 {
      margin-left: 0 !important;
      margin-right: 1.5rem !important; }
    .rtl .mr-lg-5,
    .rtl .mx-lg-5 {
      margin-right: 0 !important;
      margin-left: 3rem !important; }
    .rtl .ml-lg-5,
    .rtl .mx-lg-5 {
      margin-left: 0 !important;
      margin-right: 3rem !important; }
    .rtl .pr-lg-0,
    .rtl .px-lg-0 {
      padding-right: 0 !important;
      padding-left: 0 !important; }
    .rtl .pl-lg-0,
    .rtl .px-lg-0 {
      padding-left: 0 !important;
      padding-right: 0 !important; }
    .rtl .pr-lg-1,
    .rtl .px-lg-1 {
      padding-right: 0 !important;
      padding-left: 0.25rem !important; }
    .rtl .pl-lg-1,
    .rtl .px-lg-1 {
      padding-left: 0 !important;
      padding-right: 0.25rem !important; }
    .rtl .pr-lg-2,
    .rtl .px-lg-2 {
      padding-right: 0 !important;
      padding-left: 0.5rem !important; }
    .rtl .pl-lg-2,
    .rtl .px-lg-2 {
      padding-left: 0 !important;
      padding-right: 0.5rem !important; }
    .rtl .pr-lg-3,
    .rtl .px-lg-3 {
      padding-right: 0 !important;
      padding-left: 1rem !important; }
    .rtl .pl-lg-3,
    .rtl .px-lg-3 {
      padding-left: 0 !important;
      padding-right: 1rem !important; }
    .rtl .pr-lg-4,
    .rtl .px-lg-4 {
      padding-right: 0 !important;
      padding-left: 1.5rem !important; }
    .rtl .pl-lg-4,
    .rtl .px-lg-4 {
      padding-left: 0 !important;
      padding-right: 1.5rem !important; }
    .rtl .pr-lg-5,
    .rtl .px-lg-5 {
      padding-right: 0 !important;
      padding-left: 3rem !important; }
    .rtl .pl-lg-5,
    .rtl .px-lg-5 {
      padding-left: 0 !important;
      padding-right: 3rem !important; }
    .rtl .mr-lg-auto {
      margin-right: 0 !important;
      margin-left: auto !important; }
    .rtl .ml-lg-auto {
      margin-right: auto !important;
      margin-left: 0 !important; }
    .rtl .mx-lg-auto {
      margin-right: auto !important;
      margin-left: auto !important; } }
  @media (min-width: 1200px) {
    .rtl .mr-xl-0,
    .rtl .mx-xl-0 {
      margin-right: 0 !important;
      margin-left: 0 !important; }
    .rtl .ml-xl-0,
    .rtl .mx-xl-0 {
      margin-left: 0 !important;
      margin-right: 0 !important; }
    .rtl .mr-xl-1,
    .rtl .mx-xl-1 {
      margin-right: 0 !important;
      margin-left: 0.25rem !important; }
    .rtl .ml-xl-1,
    .rtl .mx-xl-1 {
      margin-left: 0 !important;
      margin-right: 0.25rem !important; }
    .rtl .mr-xl-2,
    .rtl .mx-xl-2 {
      margin-right: 0 !important;
      margin-left: 0.5rem !important; }
    .rtl .ml-xl-2,
    .rtl .mx-xl-2 {
      margin-left: 0 !important;
      margin-right: 0.5rem !important; }
    .rtl .mr-xl-3,
    .rtl .mx-xl-3 {
      margin-right: 0 !important;
      margin-left: 1rem !important; }
    .rtl .ml-xl-3,
    .rtl .mx-xl-3 {
      margin-left: 0 !important;
      margin-right: 1rem !important; }
    .rtl .mr-xl-4,
    .rtl .mx-xl-4 {
      margin-right: 0 !important;
      margin-left: 1.5rem !important; }
    .rtl .ml-xl-4,
    .rtl .mx-xl-4 {
      margin-left: 0 !important;
      margin-right: 1.5rem !important; }
    .rtl .mr-xl-5,
    .rtl .mx-xl-5 {
      margin-right: 0 !important;
      margin-left: 3rem !important; }
    .rtl .ml-xl-5,
    .rtl .mx-xl-5 {
      margin-left: 0 !important;
      margin-right: 3rem !important; }
    .rtl .pr-xl-0,
    .rtl .px-xl-0 {
      padding-right: 0 !important;
      padding-left: 0 !important; }
    .rtl .pl-xl-0,
    .rtl .px-xl-0 {
      padding-left: 0 !important;
      padding-right: 0 !important; }
    .rtl .pr-xl-1,
    .rtl .px-xl-1 {
      padding-right: 0 !important;
      padding-left: 0.25rem !important; }
    .rtl .pl-xl-1,
    .rtl .px-xl-1 {
      padding-left: 0 !important;
      padding-right: 0.25rem !important; }
    .rtl .pr-xl-2,
    .rtl .px-xl-2 {
      padding-right: 0 !important;
      padding-left: 0.5rem !important; }
    .rtl .pl-xl-2,
    .rtl .px-xl-2 {
      padding-left: 0 !important;
      padding-right: 0.5rem !important; }
    .rtl .pr-xl-3,
    .rtl .px-xl-3 {
      padding-right: 0 !important;
      padding-left: 1rem !important; }
    .rtl .pl-xl-3,
    .rtl .px-xl-3 {
      padding-left: 0 !important;
      padding-right: 1rem !important; }
    .rtl .pr-xl-4,
    .rtl .px-xl-4 {
      padding-right: 0 !important;
      padding-left: 1.5rem !important; }
    .rtl .pl-xl-4,
    .rtl .px-xl-4 {
      padding-left: 0 !important;
      padding-right: 1.5rem !important; }
    .rtl .pr-xl-5,
    .rtl .px-xl-5 {
      padding-right: 0 !important;
      padding-left: 3rem !important; }
    .rtl .pl-xl-5,
    .rtl .px-xl-5 {
      padding-left: 0 !important;
      padding-right: 3rem !important; }
    .rtl .mr-xl-auto {
      margin-right: 0 !important;
      margin-left: auto !important; }
    .rtl .ml-xl-auto {
      margin-right: auto !important;
      margin-left: 0 !important; }
    .rtl .mx-xl-auto {
      margin-right: auto !important;
      margin-left: auto !important; } }
  @media (min-width: 1440) {
    .rtl .mr-xxl-0,
    .rtl .mx-xxl-0 {
      margin-right: 0 !important;
      margin-left: 0 !important; }
    .rtl .ml-xxl-0,
    .rtl .mx-xxl-0 {
      margin-left: 0 !important;
      margin-right: 0 !important; }
    .rtl .mr-xxl-1,
    .rtl .mx-xxl-1 {
      margin-right: 0 !important;
      margin-left: 0.25rem !important; }
    .rtl .ml-xxl-1,
    .rtl .mx-xxl-1 {
      margin-left: 0 !important;
      margin-right: 0.25rem !important; }
    .rtl .mr-xxl-2,
    .rtl .mx-xxl-2 {
      margin-right: 0 !important;
      margin-left: 0.5rem !important; }
    .rtl .ml-xxl-2,
    .rtl .mx-xxl-2 {
      margin-left: 0 !important;
      margin-right: 0.5rem !important; }
    .rtl .mr-xxl-3,
    .rtl .mx-xxl-3 {
      margin-right: 0 !important;
      margin-left: 1rem !important; }
    .rtl .ml-xxl-3,
    .rtl .mx-xxl-3 {
      margin-left: 0 !important;
      margin-right: 1rem !important; }
    .rtl .mr-xxl-4,
    .rtl .mx-xxl-4 {
      margin-right: 0 !important;
      margin-left: 1.5rem !important; }
    .rtl .ml-xxl-4,
    .rtl .mx-xxl-4 {
      margin-left: 0 !important;
      margin-right: 1.5rem !important; }
    .rtl .mr-xxl-5,
    .rtl .mx-xxl-5 {
      margin-right: 0 !important;
      margin-left: 3rem !important; }
    .rtl .ml-xxl-5,
    .rtl .mx-xxl-5 {
      margin-left: 0 !important;
      margin-right: 3rem !important; }
    .rtl .pr-xxl-0,
    .rtl .px-xxl-0 {
      padding-right: 0 !important;
      padding-left: 0 !important; }
    .rtl .pl-xxl-0,
    .rtl .px-xxl-0 {
      padding-left: 0 !important;
      padding-right: 0 !important; }
    .rtl .pr-xxl-1,
    .rtl .px-xxl-1 {
      padding-right: 0 !important;
      padding-left: 0.25rem !important; }
    .rtl .pl-xxl-1,
    .rtl .px-xxl-1 {
      padding-left: 0 !important;
      padding-right: 0.25rem !important; }
    .rtl .pr-xxl-2,
    .rtl .px-xxl-2 {
      padding-right: 0 !important;
      padding-left: 0.5rem !important; }
    .rtl .pl-xxl-2,
    .rtl .px-xxl-2 {
      padding-left: 0 !important;
      padding-right: 0.5rem !important; }
    .rtl .pr-xxl-3,
    .rtl .px-xxl-3 {
      padding-right: 0 !important;
      padding-left: 1rem !important; }
    .rtl .pl-xxl-3,
    .rtl .px-xxl-3 {
      padding-left: 0 !important;
      padding-right: 1rem !important; }
    .rtl .pr-xxl-4,
    .rtl .px-xxl-4 {
      padding-right: 0 !important;
      padding-left: 1.5rem !important; }
    .rtl .pl-xxl-4,
    .rtl .px-xxl-4 {
      padding-left: 0 !important;
      padding-right: 1.5rem !important; }
    .rtl .pr-xxl-5,
    .rtl .px-xxl-5 {
      padding-right: 0 !important;
      padding-left: 3rem !important; }
    .rtl .pl-xxl-5,
    .rtl .px-xxl-5 {
      padding-left: 0 !important;
      padding-right: 3rem !important; }
    .rtl .mr-xxl-auto {
      margin-right: 0 !important;
      margin-left: auto !important; }
    .rtl .ml-xxl-auto {
      margin-right: auto !important;
      margin-left: 0 !important; }
    .rtl .mx-xxl-auto {
      margin-right: auto !important;
      margin-left: auto !important; } }
  .rtl .text-right {
    text-align: left !important; }
  .rtl .text-left {
    text-align: right !important; }
  @media (min-width: 576px) {
    .rtl .text-sm-right {
      text-align: left !important; }
    .rtl .text-sm-left {
      text-align: right !important; } }
  @media (min-width: 768px) {
    .rtl .text-md-right {
      text-align: left !important; }
    .rtl .text-md-left {
      text-align: right !important; } }
  @media (min-width: 992px) {
    .rtl .text-lg-right {
      text-align: left !important; }
    .rtl .text-lg-left {
      text-align: right !important; } }
  @media (min-width: 1200px) {
    .rtl .text-xl-right {
      text-align: left !important; }
    .rtl .text-xl-left {
      text-align: right !important; } }
  @media (min-width: 1440) {
    .rtl .text-xxl-right {
      text-align: left !important; }
    .rtl .text-xxl-left {
      text-align: right !important; } }

.rtl .mx-0 {
  margin-right: auto;
  margin-left: 0 !important; }

.rtl .mx-0 {
  margin-left: auto;
  margin-right: 0 !important; }

.rtl .mx-1 {
  margin-right: auto;
  margin-left: 0.25rem !important; }

.rtl .mx-1 {
  margin-left: auto;
  margin-right: 0.25rem !important; }

.rtl .mx-2 {
  margin-right: auto;
  margin-left: 0.5rem !important; }

.rtl .mx-2 {
  margin-left: auto;
  margin-right: 0.5rem !important; }

.rtl .mx-3 {
  margin-right: auto;
  margin-left: 1rem !important; }

.rtl .mx-3 {
  margin-left: auto;
  margin-right: 1rem !important; }

.rtl .mx-4 {
  margin-right: auto;
  margin-left: 1.5rem !important; }

.rtl .mx-4 {
  margin-left: auto;
  margin-right: 1.5rem !important; }

.rtl .mx-5 {
  margin-right: auto;
  margin-left: 3rem !important; }

.rtl .mx-5 {
  margin-left: auto;
  margin-right: 3rem !important; }

.rtl .px-0 {
  padding-right: auto;
  padding-left: 0 !important; }

.rtl .px-0 {
  padding-left: auto;
  padding-right: 0 !important; }

.rtl .px-1 {
  padding-right: auto;
  padding-left: 0.25rem !important; }

.rtl .px-1 {
  padding-left: auto;
  padding-right: 0.25rem !important; }

.rtl .px-2 {
  padding-right: auto;
  padding-left: 0.5rem !important; }

.rtl .px-2 {
  padding-left: auto;
  padding-right: 0.5rem !important; }

.rtl .px-3 {
  padding-right: auto;
  padding-left: 1rem !important; }

.rtl .px-3 {
  padding-left: auto;
  padding-right: 1rem !important; }

.rtl .px-4 {
  padding-right: auto;
  padding-left: 1.5rem !important; }

.rtl .px-4 {
  padding-left: auto;
  padding-right: 1.5rem !important; }

.rtl .px-5 {
  padding-right: auto;
  padding-left: 3rem !important; }

.rtl .px-5 {
  padding-left: auto;
  padding-right: 3rem !important; }

.rtl .mr-auto {
  margin-right: 0 !important;
  margin-left: auto !important; }

.rtl .mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.rtl .ml-auto {
  margin-right: auto !important;
  margin-left: 0 !important; }

.rtl .mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

@media (min-width: 576px) {
  .rtl .mx-sm-0 {
    margin-right: auto;
    margin-left: 0 !important; }
  .rtl .mx-sm-0 {
    margin-left: auto;
    margin-right: 0 !important; }
  .rtl .mx-sm-1 {
    margin-right: auto;
    margin-left: 0.25rem !important; }
  .rtl .mx-sm-1 {
    margin-left: auto;
    margin-right: 0.25rem !important; }
  .rtl .mx-sm-2 {
    margin-right: auto;
    margin-left: 0.5rem !important; }
  .rtl .mx-sm-2 {
    margin-left: auto;
    margin-right: 0.5rem !important; }
  .rtl .mx-sm-3 {
    margin-right: auto;
    margin-left: 1rem !important; }
  .rtl .mx-sm-3 {
    margin-left: auto;
    margin-right: 1rem !important; }
  .rtl .mx-sm-4 {
    margin-right: auto;
    margin-left: 1.5rem !important; }
  .rtl .mx-sm-4 {
    margin-left: auto;
    margin-right: 1.5rem !important; }
  .rtl .mx-sm-5 {
    margin-right: auto;
    margin-left: 3rem !important; }
  .rtl .mx-sm-5 {
    margin-left: auto;
    margin-right: 3rem !important; }
  .rtl .px-sm-0 {
    padding-right: auto;
    padding-left: 0 !important; }
  .rtl .px-sm-0 {
    padding-left: auto;
    padding-right: 0 !important; }
  .rtl .px-sm-1 {
    padding-right: auto;
    padding-left: 0.25rem !important; }
  .rtl .px-sm-1 {
    padding-left: auto;
    padding-right: 0.25rem !important; }
  .rtl .px-sm-2 {
    padding-right: auto;
    padding-left: 0.5rem !important; }
  .rtl .px-sm-2 {
    padding-left: auto;
    padding-right: 0.5rem !important; }
  .rtl .px-sm-3 {
    padding-right: auto;
    padding-left: 1rem !important; }
  .rtl .px-sm-3 {
    padding-left: auto;
    padding-right: 1rem !important; }
  .rtl .px-sm-4 {
    padding-right: auto;
    padding-left: 1.5rem !important; }
  .rtl .px-sm-4 {
    padding-left: auto;
    padding-right: 1.5rem !important; }
  .rtl .px-sm-5 {
    padding-right: auto;
    padding-left: 3rem !important; }
  .rtl .px-sm-5 {
    padding-left: auto;
    padding-right: 3rem !important; }
  .rtl .mr-sm-auto {
    margin-right: 0 !important;
    margin-left: auto !important; }
  .rtl .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .rtl .ml-sm-auto {
    margin-right: auto !important;
    margin-left: 0 !important; }
  .rtl .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .rtl .mx-md-0 {
    margin-right: auto;
    margin-left: 0 !important; }
  .rtl .mx-md-0 {
    margin-left: auto;
    margin-right: 0 !important; }
  .rtl .mx-md-1 {
    margin-right: auto;
    margin-left: 0.25rem !important; }
  .rtl .mx-md-1 {
    margin-left: auto;
    margin-right: 0.25rem !important; }
  .rtl .mx-md-2 {
    margin-right: auto;
    margin-left: 0.5rem !important; }
  .rtl .mx-md-2 {
    margin-left: auto;
    margin-right: 0.5rem !important; }
  .rtl .mx-md-3 {
    margin-right: auto;
    margin-left: 1rem !important; }
  .rtl .mx-md-3 {
    margin-left: auto;
    margin-right: 1rem !important; }
  .rtl .mx-md-4 {
    margin-right: auto;
    margin-left: 1.5rem !important; }
  .rtl .mx-md-4 {
    margin-left: auto;
    margin-right: 1.5rem !important; }
  .rtl .mx-md-5 {
    margin-right: auto;
    margin-left: 3rem !important; }
  .rtl .mx-md-5 {
    margin-left: auto;
    margin-right: 3rem !important; }
  .rtl .px-md-0 {
    padding-right: auto;
    padding-left: 0 !important; }
  .rtl .px-md-0 {
    padding-left: auto;
    padding-right: 0 !important; }
  .rtl .px-md-1 {
    padding-right: auto;
    padding-left: 0.25rem !important; }
  .rtl .px-md-1 {
    padding-left: auto;
    padding-right: 0.25rem !important; }
  .rtl .px-md-2 {
    padding-right: auto;
    padding-left: 0.5rem !important; }
  .rtl .px-md-2 {
    padding-left: auto;
    padding-right: 0.5rem !important; }
  .rtl .px-md-3 {
    padding-right: auto;
    padding-left: 1rem !important; }
  .rtl .px-md-3 {
    padding-left: auto;
    padding-right: 1rem !important; }
  .rtl .px-md-4 {
    padding-right: auto;
    padding-left: 1.5rem !important; }
  .rtl .px-md-4 {
    padding-left: auto;
    padding-right: 1.5rem !important; }
  .rtl .px-md-5 {
    padding-right: auto;
    padding-left: 3rem !important; }
  .rtl .px-md-5 {
    padding-left: auto;
    padding-right: 3rem !important; }
  .rtl .mr-md-auto {
    margin-right: 0 !important;
    margin-left: auto !important; }
  .rtl .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .rtl .ml-md-auto {
    margin-right: auto !important;
    margin-left: 0 !important; }
  .rtl .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .rtl .mx-lg-0 {
    margin-right: auto;
    margin-left: 0 !important; }
  .rtl .mx-lg-0 {
    margin-left: auto;
    margin-right: 0 !important; }
  .rtl .mx-lg-1 {
    margin-right: auto;
    margin-left: 0.25rem !important; }
  .rtl .mx-lg-1 {
    margin-left: auto;
    margin-right: 0.25rem !important; }
  .rtl .mx-lg-2 {
    margin-right: auto;
    margin-left: 0.5rem !important; }
  .rtl .mx-lg-2 {
    margin-left: auto;
    margin-right: 0.5rem !important; }
  .rtl .mx-lg-3 {
    margin-right: auto;
    margin-left: 1rem !important; }
  .rtl .mx-lg-3 {
    margin-left: auto;
    margin-right: 1rem !important; }
  .rtl .mx-lg-4 {
    margin-right: auto;
    margin-left: 1.5rem !important; }
  .rtl .mx-lg-4 {
    margin-left: auto;
    margin-right: 1.5rem !important; }
  .rtl .mx-lg-5 {
    margin-right: auto;
    margin-left: 3rem !important; }
  .rtl .mx-lg-5 {
    margin-left: auto;
    margin-right: 3rem !important; }
  .rtl .px-lg-0 {
    padding-right: auto;
    padding-left: 0 !important; }
  .rtl .px-lg-0 {
    padding-left: auto;
    padding-right: 0 !important; }
  .rtl .px-lg-1 {
    padding-right: auto;
    padding-left: 0.25rem !important; }
  .rtl .px-lg-1 {
    padding-left: auto;
    padding-right: 0.25rem !important; }
  .rtl .px-lg-2 {
    padding-right: auto;
    padding-left: 0.5rem !important; }
  .rtl .px-lg-2 {
    padding-left: auto;
    padding-right: 0.5rem !important; }
  .rtl .px-lg-3 {
    padding-right: auto;
    padding-left: 1rem !important; }
  .rtl .px-lg-3 {
    padding-left: auto;
    padding-right: 1rem !important; }
  .rtl .px-lg-4 {
    padding-right: auto;
    padding-left: 1.5rem !important; }
  .rtl .px-lg-4 {
    padding-left: auto;
    padding-right: 1.5rem !important; }
  .rtl .px-lg-5 {
    padding-right: auto;
    padding-left: 3rem !important; }
  .rtl .px-lg-5 {
    padding-left: auto;
    padding-right: 3rem !important; }
  .rtl .mr-lg-auto {
    margin-right: 0 !important;
    margin-left: auto !important; }
  .rtl .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .rtl .ml-lg-auto {
    margin-right: auto !important;
    margin-left: 0 !important; }
  .rtl .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .rtl .mx-xl-0 {
    margin-right: auto;
    margin-left: 0 !important; }
  .rtl .mx-xl-0 {
    margin-left: auto;
    margin-right: 0 !important; }
  .rtl .mx-xl-1 {
    margin-right: auto;
    margin-left: 0.25rem !important; }
  .rtl .mx-xl-1 {
    margin-left: auto;
    margin-right: 0.25rem !important; }
  .rtl .mx-xl-2 {
    margin-right: auto;
    margin-left: 0.5rem !important; }
  .rtl .mx-xl-2 {
    margin-left: auto;
    margin-right: 0.5rem !important; }
  .rtl .mx-xl-3 {
    margin-right: auto;
    margin-left: 1rem !important; }
  .rtl .mx-xl-3 {
    margin-left: auto;
    margin-right: 1rem !important; }
  .rtl .mx-xl-4 {
    margin-right: auto;
    margin-left: 1.5rem !important; }
  .rtl .mx-xl-4 {
    margin-left: auto;
    margin-right: 1.5rem !important; }
  .rtl .mx-xl-5 {
    margin-right: auto;
    margin-left: 3rem !important; }
  .rtl .mx-xl-5 {
    margin-left: auto;
    margin-right: 3rem !important; }
  .rtl .px-xl-0 {
    padding-right: auto;
    padding-left: 0 !important; }
  .rtl .px-xl-0 {
    padding-left: auto;
    padding-right: 0 !important; }
  .rtl .px-xl-1 {
    padding-right: auto;
    padding-left: 0.25rem !important; }
  .rtl .px-xl-1 {
    padding-left: auto;
    padding-right: 0.25rem !important; }
  .rtl .px-xl-2 {
    padding-right: auto;
    padding-left: 0.5rem !important; }
  .rtl .px-xl-2 {
    padding-left: auto;
    padding-right: 0.5rem !important; }
  .rtl .px-xl-3 {
    padding-right: auto;
    padding-left: 1rem !important; }
  .rtl .px-xl-3 {
    padding-left: auto;
    padding-right: 1rem !important; }
  .rtl .px-xl-4 {
    padding-right: auto;
    padding-left: 1.5rem !important; }
  .rtl .px-xl-4 {
    padding-left: auto;
    padding-right: 1.5rem !important; }
  .rtl .px-xl-5 {
    padding-right: auto;
    padding-left: 3rem !important; }
  .rtl .px-xl-5 {
    padding-left: auto;
    padding-right: 3rem !important; }
  .rtl .mr-xl-auto {
    margin-right: 0 !important;
    margin-left: auto !important; }
  .rtl .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .rtl .ml-xl-auto {
    margin-right: auto !important;
    margin-left: 0 !important; }
  .rtl .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; } }

@media (min-width: 1440) {
  .rtl .mx-xxl-0 {
    margin-right: auto;
    margin-left: 0 !important; }
  .rtl .mx-xxl-0 {
    margin-left: auto;
    margin-right: 0 !important; }
  .rtl .mx-xxl-1 {
    margin-right: auto;
    margin-left: 0.25rem !important; }
  .rtl .mx-xxl-1 {
    margin-left: auto;
    margin-right: 0.25rem !important; }
  .rtl .mx-xxl-2 {
    margin-right: auto;
    margin-left: 0.5rem !important; }
  .rtl .mx-xxl-2 {
    margin-left: auto;
    margin-right: 0.5rem !important; }
  .rtl .mx-xxl-3 {
    margin-right: auto;
    margin-left: 1rem !important; }
  .rtl .mx-xxl-3 {
    margin-left: auto;
    margin-right: 1rem !important; }
  .rtl .mx-xxl-4 {
    margin-right: auto;
    margin-left: 1.5rem !important; }
  .rtl .mx-xxl-4 {
    margin-left: auto;
    margin-right: 1.5rem !important; }
  .rtl .mx-xxl-5 {
    margin-right: auto;
    margin-left: 3rem !important; }
  .rtl .mx-xxl-5 {
    margin-left: auto;
    margin-right: 3rem !important; }
  .rtl .px-xxl-0 {
    padding-right: auto;
    padding-left: 0 !important; }
  .rtl .px-xxl-0 {
    padding-left: auto;
    padding-right: 0 !important; }
  .rtl .px-xxl-1 {
    padding-right: auto;
    padding-left: 0.25rem !important; }
  .rtl .px-xxl-1 {
    padding-left: auto;
    padding-right: 0.25rem !important; }
  .rtl .px-xxl-2 {
    padding-right: auto;
    padding-left: 0.5rem !important; }
  .rtl .px-xxl-2 {
    padding-left: auto;
    padding-right: 0.5rem !important; }
  .rtl .px-xxl-3 {
    padding-right: auto;
    padding-left: 1rem !important; }
  .rtl .px-xxl-3 {
    padding-left: auto;
    padding-right: 1rem !important; }
  .rtl .px-xxl-4 {
    padding-right: auto;
    padding-left: 1.5rem !important; }
  .rtl .px-xxl-4 {
    padding-left: auto;
    padding-right: 1.5rem !important; }
  .rtl .px-xxl-5 {
    padding-right: auto;
    padding-left: 3rem !important; }
  .rtl .px-xxl-5 {
    padding-left: auto;
    padding-right: 3rem !important; }
  .rtl .mr-xxl-auto {
    margin-right: 0 !important;
    margin-left: auto !important; }
  .rtl .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .rtl .ml-xxl-auto {
    margin-right: auto !important;
    margin-left: 0 !important; }
  .rtl .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important; } }

.rtl .float-left {
  float: right !important; }

.rtl .float-right {
  float: left !important; }

.rtl .float-none {
  float: none !important; }

@media (min-width: 576px) {
  .rtl .float-sm-left {
    float: right !important; }
  .rtl .float-sm-right {
    float: left !important; }
  .rtl .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .rtl .float-md-left {
    float: right !important; }
  .rtl .float-md-right {
    float: left !important; }
  .rtl .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .rtl .float-lg-left {
    float: right !important; }
  .rtl .float-lg-right {
    float: left !important; }
  .rtl .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .rtl .float-xl-left {
    float: right !important; }
  .rtl .float-xl-right {
    float: left !important; }
  .rtl .float-xl-none {
    float: none !important; } }

@media (min-width: 1440) {
  .rtl .float-xxl-left {
    float: right !important; }
  .rtl .float-xxl-right {
    float: left !important; }
  .rtl .float-xxl-none {
    float: none !important; } }

[direction="rtl"][data-theme-version="dark"] .border, [direction="rtl"][data-theme-version="dark"] .border-left, [direction="rtl"][data-theme-version="dark"] .border-right {
  border-color: #444444 !important; }

[direction="rtl"] .text-right {
  text-align: left !important; }

[direction="rtl"] .text-left {
  text-align: right !important; }

[direction="rtl"] .border-right {
  border-left: 0.0625rem solid #E6E6E6 !important;
  border-right: 0 !important; }

[direction="rtl"] .border-left {
  border-right: 0.0625rem solid #E6E6E6 !important;
  border-left: 0 !important; }

[direction="rtl"] .dropdown-menu {
  left: auto; }

[direction="rtl"] .dropdown-menu-right {
  left: 0;
  right: auto; }
  @media only screen and (max-width: 35.9375rem) {
    [direction="rtl"] .dropdown-menu-right {
      left: 0.9375rem; } }

[direction="rtl"] .notification_dropdown .dropdown-menu-right .media > span {
  margin-left: 0.625rem;
  margin-right: 0; }

[direction="rtl"]:not([data-container="boxed"]) .nav-header {
  left: auto;
  right: 0; }

[direction="rtl"][data-container="wide-boxed"] .nav-header {
  left: auto;
  right: auto; }

[direction="rtl"] .nav-header {
  text-align: right;
  right: auto; }
  [direction="rtl"] .nav-header .brand-title {
    margin-left: 0;
    margin-right: 0.9375rem; }
  [direction="rtl"] .nav-header .brand-logo {
    padding-left: 0;
    padding-right: 1.75rem; }
    [data-sidebar-style="compact"][direction="rtl"] .nav-header .brand-logo {
      padding-right: 0; }
    [data-sidebar-style="compact"][direction="rtl"] .nav-header .brand-logo[data-layout="horizontal"] {
      padding-right: 1.875rem; }
    [data-sidebar-style="mini"][direction="rtl"] .nav-header .brand-logo {
      padding-right: 0; }
    [data-sidebar-style="modern"][direction="rtl"] .nav-header .brand-logo {
      padding-right: 0; }
      [data-layout="horizontal"][data-sidebar-style="modern"][direction="rtl"] .nav-header .brand-logo {
        padding-right: 1.875rem; }
    @media (max-width: 767.98px) {
      [direction="rtl"] .nav-header .brand-logo {
        padding-right: 0; } }

[direction="rtl"] .nav-control {
  right: auto;
  left: -4.0625rem; }
  @media (max-width: 767.98px) {
    [direction="rtl"] .nav-control {
      left: -4.0625rem; } }
  @media (max-width: 575.98px) {
    [direction="rtl"] .nav-control {
      left: -2.0625rem; } }

[direction="rtl"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active {
  right: 0; }

[direction="rtl"][data-sidebar-style="compact"][data-layout="horizontal"] .nav-header .brand-logo {
  padding-right: 2.5rem; }

[direction="rtl"][data-sidebar-style="modern"][data-layout="horizontal"] .nav-header {
  width: 16rem; }

[direction="rtl"] .header {
  padding: 0;
  padding-right: 21.563rem; }
  @media (max-width: 767.98px) {
    [direction="rtl"] .header {
      padding-right: 5rem;
      padding-left: 0; } }
  [direction="rtl"] .header .header-content {
    padding-left: 1.875rem;
    padding-right: 5.3125rem; }
    @media only screen and (max-width: 35.9375rem) {
      [direction="rtl"] .header .header-content {
        padding-right: 3.5rem;
        padding-left: 0.938rem; } }
    [data-sidebar-style="compact"][direction="rtl"] .header .header-content {
      padding-right: 1.875rem; }
    [data-sidebar-style="modern"][direction="rtl"] .header .header-content {
      padding-right: 5.3125rem; }
    [data-sidebar-style="overlay"][direction="rtl"] .header .header-content {
      padding-right: 5.3125rem; }
      @media only screen and (max-width: 35.9375rem) {
        [data-sidebar-style="overlay"][direction="rtl"] .header .header-content {
          padding-right: 0.5rem; } }
  [direction="rtl"] .header .nav-control {
    right: 0.4375rem;
    left: auto; }

[direction="rtl"] .header-right > li:not(:first-child) {
  padding-left: 0;
  padding-right: 1.25rem;
  margin-right: 0 !important; }
  @media only screen and (max-width: 47.9375rem) {
    [direction="rtl"] .header-right > li:not(:first-child) {
      padding-right: 0.5rem; } }

[direction="rtl"] .header-right .search-area .input-group-append .input-group-text {
  padding-right: auto;
  padding-left: 1.25rem; }

[direction="rtl"] .header-right .search-area .form-control {
  padding-left: auto;
  padding-right: 1.25rem; }

[direction="rtl"] .header-right .header-profile > a.nav-link {
  margin-left: auto;
  padding-left: auto;
  margin-right: 0.9375rem;
  padding-right: 1.875rem;
  border-right: 0.0625rem solid #cdcdcd;
  border-left: 0; }
  [direction="rtl"] .header-right .header-profile > a.nav-link .header-info {
    padding-right: 1.25rem;
    padding-left: auto;
    text-align: right; }
    @media only screen and (max-width: 87.5rem) {
      [direction="rtl"] .header-right .header-profile > a.nav-link .header-info {
        padding-right: 0.625rem; } }
  @media only screen and (max-width: 87.5rem) {
    [direction="rtl"] .header-right .header-profile > a.nav-link {
      margin-right: 0.625rem;
      padding-right: 1.25rem; } }
  @media only screen and (max-width: 35.9375rem) {
    [direction="rtl"] .header-right .header-profile > a.nav-link {
      margin-right: 0rem;
      padding-right: 0rem;
      border-right: 0; } }

[direction="rtl"] .header-left .search_bar .dropdown-menu,
[direction="rtl"] .header-left .search_bar .dropdown-menu.show {
  right: 2.5rem !important; }
  @media only screen and (max-width: 35.9375rem) {
    [direction="rtl"] .header-left .search_bar .dropdown-menu,
    [direction="rtl"] .header-left .search_bar .dropdown-menu.show {
      right: -6.25rem !important; } }

[direction="rtl"] .header-left .search_bar .search_icon {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5rem;
  border-bottom-right-radius: 5rem; }
  @media only screen and (max-width: 35.9375rem) {
    [direction="rtl"] .header-left .search_bar .search_icon {
      border-radius: 5rem; } }

[direction="rtl"][data-layout="horizontal"] .header {
  padding: 0;
  padding-right: 21.563rem; }
  [direction="rtl"][data-layout="horizontal"] .header .header-content {
    padding-right: 2.5rem;
    padding-left: 2.5rem; }

[direction="rtl"][data-layout="horizontal"][data-sidebar-style="full"] .nav-header .brand-logo {
  padding-right: 2.5rem; }

[direction="rtl"][data-layout="horizontal"][data-sidebar-style="mini"] .header {
  padding-right: 7.75rem; }

[direction="rtl"][data-sidebar-style="mini"] .header {
  padding-right: 6.25rem; }

[direction="rtl"][data-sidebar-style="compact"] .header {
  padding: 0 0.9375rem;
  padding-right: 11.25rem; }

[direction="rtl"][data-sidebar-style="compact"][data-layout="horizontal"] .header {
  padding: 0;
  padding-right: 21.563rem; }

[direction="rtl"][data-sidebar-style="modern"] .header {
  padding: 0 0.9375rem;
  padding-right: 10.625rem; }

[direction="rtl"][data-sidebar-style="modern"][data-layout="horizontal"] .header {
  padding: 0;
  padding-right: 16rem; }

[direction="rtl"] {
  text-align: right; }
  [direction="rtl"] .deznav {
    text-align: right; }
    [direction="rtl"] .deznav .metismenu ul:after {
      left: auto;
      right: 1.5625rem; }
    [direction="rtl"] .deznav .metismenu ul a:before {
      left: auto;
      right: 2.625rem; }
    [data-sidebar-style="full"][direction="rtl"] .deznav .metismenu li > a i {
      padding: 0 0 0 0;
      margin-right: 0;
      margin-left: 0.625rem; }
    [direction="rtl"] .deznav .metismenu li > a svg {
      margin-left: 0.3125rem;
      margin-right: 0; }
      [data-sidebar-style="compact"][direction="rtl"] .deznav .metismenu li > a svg {
        left: auto;
        margin-left: auto;
        margin-right: auto; }
      [data-sidebar-style="icon-hover"][direction="rtl"] .deznav .metismenu li > a svg {
        margin-left: 0; }
    [direction="rtl"] .deznav .metismenu li ul a {
      padding-right: 6rem;
      padding-left: 0.625rem; }
    [direction="rtl"] .deznav .metismenu li.active > .has-arrow:after {
      transform: rotate(45deg) translateY(-50%); }
    [direction="rtl"] .deznav .metismenu .has-arrow:after {
      left: 1.5625rem;
      right: auto; }
      [data-layout="horizontal"][direction="rtl"] .deznav .metismenu .has-arrow:after {
        left: 1.125rem; }
      [data-sidebar-style="modern"][direction="rtl"] .deznav .metismenu .has-arrow:after {
        -webkit-transform: rotate(-45deg) translateY(-50%);
        transform: rotate(-45deg) translateY(-50%); }
  [direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu > li .has-arrow:after {
    left: 1.5rem;
    right: auto; }
  [direction="rtl"][data-sidebar-style="mini"] .deznav .metismenu > li > a > i {
    padding: 0; }
  [direction="rtl"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li > ul a.has-arrow:after {
    left: 1.5625rem;
    right: auto; }
  [direction="rtl"][data-sidebar-style="compact"] .deznav .metismenu li > a i {
    padding: 0;
    margin-left: auto;
    margin-right: auto; }
  [direction="rtl"][data-sidebar-style="compact"] .deznav .metismenu li ul a {
    padding-right: 0.625rem;
    padding-left: 0.625rem; }
  [direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li > ul li:hover ul {
    right: 11.8125rem;
    left: 0; }

[direction="rtl"] {
  /* ===== Light gallery ===== */ }
  [direction="rtl"] .select2-container--default .select2-selection--single .select2-selection__arrow {
    left: 15px;
    right: auto; }
  [direction="rtl"] .input-group > .bootstrap-select:not(:first-child) .dropdown-toggle {
    border-radius: 0.75rem 0   0 0.75rem; }
  [direction="rtl"] .list-group {
    padding-left: 0; }
  [direction="rtl"] .form-check-input {
    margin-left: -1.25rem;
    margin-right: inherit; }
  [direction="rtl"] .form-check-inline .form-check-input {
    margin-right: 0;
    margin-left: 10px; }
  [direction="rtl"] .radio input,
  [direction="rtl"] .radio-inline,
  [direction="rtl"] .checkbox input,
  [direction="rtl"] .checkbox-inline input {
    margin-left: 0;
    margin-right: 0; }
  [direction="rtl"] .content-body {
    margin-right: 21.563rem;
    margin-left: auto; }
    [data-sidebar-style="modern"][direction="rtl"] .content-body {
      margin-right: 9.375rem; }
    [direction="rtl"] .content-body .page-titles {
      text-align: right; }
  [direction="rtl"] .doctor-info-details .media-body span i,
  [direction="rtl"] .recovered-chart-deta .col [class*="bg-"] {
    margin-right: 0;
    margin-left: 10px; }
  [direction="rtl"] .patients-chart-deta .col,
  [direction="rtl"] .patients-chart-deta .col [class*="bg-"],
  [direction="rtl"] .recovered-chart-deta .col {
    margin-right: 0;
    margin-left: 15px; }
  [direction="rtl"] .best-doctor .timeline .timeline-panel .media .number {
    left: auto;
    right: -13px; }
  [direction="rtl"] .doctor-info-details .media i {
    right: 0;
    left: -15px; }
  [direction="rtl"] .review-table .disease {
    border-left: 0;
    border-right: 1px solid #eee;
    padding-left: 0;
    padding-right: 20px; }
  [direction="rtl"] .apexcharts-legend-text {
    margin: 4px; }
  [direction="rtl"] .doctor-info-details .media-body {
    padding-left: 0;
    padding-right: 40px; }
  [direction="rtl"] .custom-control {
    margin-left: 0; }
  [direction="rtl"] .review-tab.nav-pills li:first-child a.nav-link {
    border-radius: 0 0.75rem 0 0; }
  [direction="rtl"] .review-tab.nav-pills li:last-child a.nav-link {
    border-radius: 0.75rem 0 0 0; }
  [direction="rtl"] .form-head .btn i {
    margin-left: 5px;
    margin-right: 0; }
  [direction="rtl"] .iconbox {
    padding-left: 0;
    padding-right: 70px; }
    [direction="rtl"] .iconbox i {
      left: auto;
      right: 0; }
  [direction="rtl"] .table.tr-rounded tr td:first-child, [direction="rtl"] .table.tr-rounded tr th:first-child {
    border-radius: 0 0.625rem 0.625rem 0; }
  [direction="rtl"] .table.tr-rounded tr td:last-child, [direction="rtl"] .table.tr-rounded tr th:last-child {
    border-radius: 0.625rem 0 0 0.625rem; }
  [direction="rtl"] .custom-switch.toggle-switch.text-right {
    padding-left: 48px;
    padding-right: 0; }
  [direction="rtl"] .toggle-switch.text-right .custom-control-label:before {
    right: auto !important;
    left: -47px; }
  [direction="rtl"] .toggle-switch.text-right .custom-control-label:after {
    right: auto !important;
    left: -28px; }
  [direction="rtl"] .toggle-switch.text-right .custom-control-input:checked ~ .custom-control-label:after {
    left: -62px;
    right: auto !important; }
  [direction="rtl"] .check-switch {
    padding-right: 40px; }
    [direction="rtl"] .check-switch .custom-control-label:after, [direction="rtl"] .check-switch .custom-control-label:before {
      right: -35px !important; }
  [direction="rtl"] .bar-chart .apexcharts-yaxis {
    transform: translatex(101%); }
  [direction="rtl"] .detault-daterange .input-group-text {
    padding: 0.532rem 0.75rem;
    padding-right: auto;
    padding-left: 0; }
  [direction="rtl"] .form-wrapper .input-group .form-control {
    text-align: left; }
  [direction="rtl"] .timeline-chart .apexcharts-yaxis {
    transform: translateX(0); }
  [direction="rtl"] .card-table td:first-child {
    padding-right: 30px;
    padding-left: 10px; }
  [direction="rtl"] .card-table td:last-child {
    padding-left: 30px;
    padding-right: 10px; }
  [direction="rtl"] .chatbox .img_cont {
    margin-right: 0px;
    margin-left: 10px; }
  [direction="rtl"] .profile-tab .nav-item .nav-link {
    margin-right: 0;
    margin-left: 30px; }
    @media only screen and (max-width: 35.9375rem) {
      [direction="rtl"] .profile-tab .nav-item .nav-link {
        margin-left: 0px; } }
  [direction="rtl"] .widget-timeline .timeline > li > .timeline-panel {
    margin-left: 0px;
    margin-right: 40px; }
  [direction="rtl"] .widget-timeline.style-1 .timeline .timeline-badge.timeline-badge + .timeline-panel {
    padding: 5px 15px 5px 10px;
    border-width: 0px 5px 0px 0px; }
  [direction="rtl"] .widget-timeline.style-1 .timeline .timeline-badge.timeline-badge + .timeline-panel:after {
    border: 0;
    right: -9px;
    width: 7px;
    left: auto;
    height: 7px; }
  [direction="rtl"] .widget-timeline .timeline > li > .timeline-badge {
    left: auto;
    right: 0px; }
  [direction="rtl"] .widget-timeline .timeline > li > .timeline-panel:after {
    left: auto;
    right: -5px; }
  [direction="rtl"] .chatbox .msg_cotainer {
    margin-left: 0;
    margin-right: 10px; }
  [direction="rtl"] .new-arrival-content .price {
    float: left; }
  [direction="rtl"] .input-group > .bootstrap-select:not(:first-child) .dropdown-toggle {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0.625rem;
    border-bottom-left-radius: 0.625rem; }
  [direction="rtl"] .input-group > .bootstrap-select:not(:last-child) .dropdown-toggle {
    border-top-right-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  [direction="rtl"] .breadcrumb-item + .breadcrumb-item {
    padding-right: 0.5rem;
    padding-left: 0; }
  [direction="rtl"] .breadcrumb-item + .breadcrumb-item::before {
    padding-right: 0;
    padding-left: 0.5rem; }
  [direction="rtl"] .chatbox .chatbox-close {
    left: 340px;
    right: auto; }
    @media only screen and (max-width: 35.9375rem) {
      [direction="rtl"] .chatbox .chatbox-close {
        left: 280px; } }
  [direction="rtl"] .separator {
    margin-right: 0;
    margin-left: 9px; }
  [direction="rtl"] .card-tabs .nav-tabs {
    padding-right: 5px; }
  [direction="rtl"] .chatbox .msg_cotainer {
    margin-left: 0;
    margin-right: 10px;
    border-radius: 1.375rem 0 1.375rem 1.375rem; }
    [direction="rtl"] .chatbox .msg_cotainer:after {
      left: auto;
      right: -10px;
      transform: rotate(-90deg); }
  [direction="rtl"] .chatbox .type_msg .input-group .input-group-append .btn {
    border-top-right-radius: 38px !important;
    border-bottom-right-radius: 38px !important; }
  [direction="rtl"] .chatbox .msg_cotainer_send {
    margin-right: 0px;
    margin-left: 10px;
    border-radius: 0 6px 6px 6px; }
    [direction="rtl"] .chatbox .msg_cotainer_send:after {
      right: auto;
      left: -10px;
      transform: rotate(90deg); }
  [direction="rtl"] .new-arrival-content .price {
    float: left; }
  [direction="rtl"] .trending-menus .tr-row .num {
    margin-right: 0;
    margin-left: 15px; }
  [direction="rtl"] .default-select.style-2 .btn:after {
    margin-left: 0;
    margin-right: 0.5em; }
  [direction="rtl"] .widget-timeline-icon li .icon {
    left: auto;
    right: -2px; }
  [direction="rtl"] .widget-timeline-icon li a {
    padding: 25px 0px 0px 50px; }
  @media only screen and (max-width: 35.9375rem) {
    [direction="rtl"] .widget-timeline-icon .timeline {
      margin-left: 0;
      margin-right: 10px; }
    [direction="rtl"] .widget-timeline-icon li {
      border-left: 0;
      border-right: 6px solid var(--primary); }
      [direction="rtl"] .widget-timeline-icon li a {
        padding: 0 30px 30px 0; }
      [direction="rtl"] .widget-timeline-icon li .icon {
        right: -12px; }
      [direction="rtl"] .widget-timeline-icon li:last-child {
        border-color: transparent; } }
  [direction="rtl"] #revenueMap .apexcharts-yaxis, [direction="rtl"] #customerMapkm .apexcharts-yaxis {
    transform: translateX(0px); }
  [direction="rtl"] .mail-list .list-group-item i {
    padding-right: 0;
    padding-left: 0.625rem; }
  [direction="rtl"] .dz-demo-panel {
    right: auto;
    left: -380px; }
    [direction="rtl"] .dz-demo-panel.show {
      right: unset;
      left: 0; }
    [direction="rtl"] .dz-demo-panel .dz-demo-trigger {
      left: 100%;
      right: auto;
      border-radius: 0 5px 5px 0;
      box-shadow: 5px 3px 5px 0px rgba(119, 119, 119, 0.15); }
  [direction="rtl"][data-layout="horizontal"] .content-body {
    margin-right: 0; }
  [direction="rtl"][data-layout="horizontal"] .deznav .metismenu li li .has-arrow:after {
    -webkit-transform: rotate(-4deg) translateY(-50%);
    transform: rotate(-45deg) translateY(-50%); }
  [direction="rtl"][data-sidebar-style="mini"]:not([data-layout="horizontal"]) .content-body {
    margin-right: 6.25rem; }
  [direction="rtl"][data-sidebar-style="compact"]:not([data-layout="horizontal"]) .content-body {
    margin-right: 11.25rem; }
  [direction="rtl"][data-sidebar-style="overlay"] .content-body {
    margin-right: 0; }
  [direction="rtl"] #external-events .external-event:before {
    margin-right: 0;
    margin-left: .9rem; }
  [direction="rtl"] .post-input a i {
    margin-left: 15px;
    margin-right: 0; }
  [direction="rtl"][data-sidebar-style="compact"] .deznav .metismenu .has-arrow:after {
    -webkit-transform: rotate(-45deg) translateY(-50%);
    transform: rotate(-45deg) translateY(-50%); }
  [direction="rtl"] .deznav .metismenu .has-arrow:after {
    -webkit-transform: rotate(-135deg) translateY(-50%);
    transform: rotate(-135deg) translateY(-50%); }
  [direction="rtl"] .deznav .metismenu .has-arrow[aria-expanded=true]:after,
  [direction="rtl"] .deznav .metismenu .mm-active > .has-arrow:after {
    -webkit-transform: rotate(-135deg) translateY(-50%);
    transform: rotate(-135deg); }
  [direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu .has-arrow[aria-expanded=true]:after,
  [direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu .mm-active > .has-arrow:after {
    -webkit-transform: rotate(-45deg) translateY(-50%);
    transform: rotate(-45deg); }
  [direction="rtl"] .chatbox {
    left: -500px;
    right: auto; }
  [direction="rtl"] .chatbox.active {
    left: 0;
    right: auto; }
  @media only screen and (max-width: 575px) {
    [direction="rtl"] .best-doctor .timeline .timeline-panel .media {
      float: right;
      margin-right: 0 !important;
      margin-left: 15px !important; } }
  [direction="rtl"] .default-select.style-1 .btn:after {
    margin-left: 0;
    margin-right: 0.5em; }
  [direction="rtl"] .pagination .page-indicator {
    transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg); }
  [direction="rtl"] .lg-outer.lg-visible {
    direction: ltr; }
  [direction="rtl"] .chart-point .chart-point-list {
    margin: 0;
    padding-right: 20px; }
  [direction="rtl"] .noUi-target {
    direction: rtl; }
  [direction="rtl"] .noUi-vertical .noUi-pips-vertical {
    left: -20px; }
  [direction="rtl"] .noUi-vertical .noUi-value-vertical {
    padding-left: 0;
    padding-right: 25px; }
  [direction="rtl"] .sidebar-right .ps--active-x > .ps__rail-x {
    display: none; }
  [direction="rtl"] .form-wizard .nav-wizard li .nav-link:after {
    right: 50%;
    left: auto; }
  [direction="rtl"] .dtp > .dtp-content {
    right: 50%;
    left: auto; }
  [direction="rtl"] .modal-header .close {
    margin: 0;
    left: 0;
    top: 0px;
    right: auto; }
  [direction="rtl"] .input-group-prepend .btn + .btn {
    border-radius: 0 !important; }
  [direction="rtl"] .form-control + .input-group-append .btn:first-child {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important; }
  [direction="rtl"] .input-group > .custom-file:not(:first-child) .custom-file-label {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0.75rem;
    border-top-left-radius: 0.75rem; }
  [direction="rtl"] .custom-file-label::after {
    border-radius: .75rem 0 0 .75rem; }
  [direction="rtl"] .input-group > .custom-file:not(:last-child) .custom-file-label {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem; }
  [direction="rtl"] .input-group > .custom-file:not(:last-child) .custom-file-label::after {
    border-radius: 0; }

@media only screen and (max-width: 1350px) and (min-width: 1200px) {
  [direction="rtl"] .content-body {
    margin-right: 17rem; } }

[direction="rtl"] .sidebar-right {
  right: auto;
  left: -50rem; }
  [direction="rtl"] .sidebar-right.show {
    left: 5.25rem;
    right: unset; }
  [direction="rtl"] .sidebar-right .sidebar-right-trigger {
    left: 100%;
    right: auto;
    border-radius: 0 5px 5px 0;
    box-shadow: 5px 3px 5px 0px rgba(119, 119, 119, 0.15); }
  [direction="rtl"] .sidebar-right .sidebar-close-trigger {
    right: auto;
    left: -48px; }

[direction="rtl"] .bootstrap-select .dropdown-toggle .filter-option {
  text-align: right; }

html[dir="rtl"] [direction="rtl"] .footer {
  padding-right: 17.1875rem;
  padding-left: 0; }
  @media (max-width: 767.98px) {
    html[dir="rtl"] [direction="rtl"] .footer {
      padding-right: 0; } }

html[dir="rtl"] [direction="rtl"][data-sidebar-style="overlay"] .footer {
  padding-right: 0; }

html[dir="rtl"] [direction="rtl"] .menu-toggle .footer {
  padding-right: 3.75rem; }

html[dir="rtl"] [direction="rtl"][data-container="boxed"] .footer {
  padding-right: 0; }

html[dir="rtl"] [direction="rtl"][data-sidebar-style="mini"]:not([data-layout="horizontal"]) .footer {
  padding-right: 3.75rem; }

html[dir="rtl"] [direction="rtl"][data-sidebar-style="compact"]:not([data-layout="horizontal"]) .footer {
  padding-right: 9.375rem; }

.nav-label {
  margin: 0.625rem 1.875rem 0;
  padding: 1.5625rem 0 0.625rem;
  text-transform: uppercase;
  font-size: 0.75rem;
  letter-spacing: 0.05rem;
  border-top: 0.0625rem solid #eaeaea;
  color: #999999; }
  [data-theme-version="dark"] .nav-label {
    border-color: #444444; }
  .nav-label.first {
    border: 0rem;
    margin-top: 0rem; }

.nav-badge {
  position: absolute;
  right: 2.8125rem;
  top: 0.625rem; }

.content-body {
  margin-left: 18.75rem;
  z-index: 0;
  transition: all .2s ease; }
  @media only screen and (max-width: 47.9375rem) {
    .content-body {
      padding-right: 0rem; } }

.bell img {
  -webkit-animation: ring 8s .7s ease-in-out infinite;
  -webkit-transform-origin: 50% 0.25rem;
  -moz-animation: ring 8s .7s ease-in-out infinite;
  -moz-transform-origin: 50% 0.25rem;
  animation: ring 8s .7s ease-in-out infinite; }

@-webkit-keyframes ring {
  0% {
    -webkit-transform: rotateZ(0); }
  1% {
    -webkit-transform: rotateZ(30deg); }
  3% {
    -webkit-transform: rotateZ(-28deg); }
  5% {
    -webkit-transform: rotateZ(34deg); }
  7% {
    -webkit-transform: rotateZ(-32deg); }
  9% {
    -webkit-transform: rotateZ(30deg); }
  11% {
    -webkit-transform: rotateZ(-28deg); }
  13% {
    -webkit-transform: rotateZ(26deg); }
  15% {
    -webkit-transform: rotateZ(-24deg); }
  17% {
    -webkit-transform: rotateZ(22deg); }
  19% {
    -webkit-transform: rotateZ(-20deg); }
  21% {
    -webkit-transform: rotateZ(18deg); }
  23% {
    -webkit-transform: rotateZ(-16deg); }
  25% {
    -webkit-transform: rotateZ(14deg); }
  27% {
    -webkit-transform: rotateZ(-12deg); }
  29% {
    -webkit-transform: rotateZ(10deg); }
  31% {
    -webkit-transform: rotateZ(-8deg); }
  33% {
    -webkit-transform: rotateZ(6deg); }
  35% {
    -webkit-transform: rotateZ(-4deg); }
  37% {
    -webkit-transform: rotateZ(2deg); }
  39% {
    -webkit-transform: rotateZ(-1deg); }
  41% {
    -webkit-transform: rotateZ(1deg); }
  43% {
    -webkit-transform: rotateZ(0); }
  100% {
    -webkit-transform: rotateZ(0); } }

@-moz-keyframes ring {
  0% {
    -moz-transform: rotate(0); }
  1% {
    -moz-transform: rotate(30deg); }
  3% {
    -moz-transform: rotate(-28deg); }
  5% {
    -moz-transform: rotate(34deg); }
  7% {
    -moz-transform: rotate(-32deg); }
  9% {
    -moz-transform: rotate(30deg); }
  11% {
    -moz-transform: rotate(-28deg); }
  13% {
    -moz-transform: rotate(26deg); }
  15% {
    -moz-transform: rotate(-24deg); }
  17% {
    -moz-transform: rotate(22deg); }
  19% {
    -moz-transform: rotate(-20deg); }
  21% {
    -moz-transform: rotate(18deg); }
  23% {
    -moz-transform: rotate(-16deg); }
  25% {
    -moz-transform: rotate(14deg); }
  27% {
    -moz-transform: rotate(-12deg); }
  29% {
    -moz-transform: rotate(10deg); }
  31% {
    -moz-transform: rotate(-8deg); }
  33% {
    -moz-transform: rotate(6deg); }
  35% {
    -moz-transform: rotate(-4deg); }
  37% {
    -moz-transform: rotate(2deg); }
  39% {
    -moz-transform: rotate(-1deg); }
  41% {
    -moz-transform: rotate(1deg); }
  43% {
    -moz-transform: rotate(0); }
  100% {
    -moz-transform: rotate(0); } }

@keyframes ring {
  0% {
    transform: rotate(0); }
  1% {
    transform: rotate(30deg); }
  3% {
    transform: rotate(-28deg); }
  5% {
    transform: rotate(34deg); }
  7% {
    transform: rotate(-32deg); }
  9% {
    transform: rotate(30deg); }
  11% {
    transform: rotate(-28deg); }
  13% {
    transform: rotate(26deg); }
  15% {
    transform: rotate(-24deg); }
  17% {
    transform: rotate(22deg); }
  19% {
    transform: rotate(-20deg); }
  21% {
    transform: rotate(18deg); }
  23% {
    transform: rotate(-16deg); }
  25% {
    transform: rotate(14deg); }
  27% {
    transform: rotate(-12deg); }
  29% {
    transform: rotate(10deg); }
  31% {
    transform: rotate(-8deg); }
  33% {
    transform: rotate(6deg); }
  35% {
    transform: rotate(-4deg); }
  37% {
    transform: rotate(2deg); }
  39% {
    transform: rotate(-1deg); }
  41% {
    transform: rotate(1deg); }
  43% {
    transform: rotate(0); }
  100% {
    transform: rotate(0); } }

.deznav {
  width: 15.75rem;
  padding-bottom: 10px;
  height: calc(100% - 6.25rem);
  position: absolute;
  top: 6.25rem;
  padding-top: 30px;
  z-index: 6;
  background-color: var(--sidebar-bg);
  transition: all .2s ease; }
  .deznav .deznav-scroll {
    position: relative;
    height: 100%;
    overflow-y: scroll; }
  .deznav ul {
    padding: 0;
    margin: 0;
    list-style: none; }
  .deznav .metismenu {
    display: flex;
    flex-direction: column;
    padding-bottom: 25px; }
    .deznav .metismenu.fixed {
      position: fixed;
      top: 0;
      width: 100%;
      left: 0; }
    .deznav .metismenu > li {
      display: flex;
      flex-direction: column; }
      .deznav .metismenu > li a > i {
        font-size: 1.375rem;
        display: inline-block;
        vertical-align: middle;
        position: relative;
        top: 0;
        height: auto;
        width: auto;
        text-align: center;
        margin-right: 0.65rem;
        border-radius: 0.125rem;
        line-height: 1;
        border-radius: 0.625rem;
        font-family: 'Material Icons';
        font-style: normal;
        -webkit-font-feature-settings: 'liga';
        -webkit-font-smoothing: antialiased;
        font-weight: normal; }
        [data-sidebar-style="compact"] .deznav .metismenu > li a > i {
          display: block;
          padding: 0;
          background: rgba(0, 0, 0, 0.05);
          color: rgba(0, 0, 0, 0.3);
          width: 3.25rem;
          height: 3.25rem;
          border-radius: 0.625rem;
          line-height: 3.45rem;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 0.3125rem; }
          [data-sidebar-style="compact"] .deznav .metismenu > li a > i[data-theme-version="dark"] {
            color: #fff; }
        @media only screen and (max-width: 84.375rem) {
          .deznav .metismenu > li a > i {
            height: auto;
            line-height: 0.0625rem;
            width: auto;
            font-size: 1.5rem;
            padding: 0;
            color: #969BA0; } }
      .deznav .metismenu > li > a {
        font-weight: 400;
        display: inline-block;
        font-size: 16px;
        color: #252525; }
        .deznav .metismenu > li > a i {
          color: #96A0AF; }
        .deznav .metismenu > li > a svg {
          height: 100%;
          margin-top: -0.0875rem; }
        .deznav .metismenu > li > a .menu-icon {
          display: inline-block;
          vertical-align: middle;
          position: relative;
          height: 50px;
          width: 50px;
          text-align: center;
          line-height: 3.1;
          border-radius: 50%;
          background-color: rgba(33, 44, 69, 0.05);
          margin-right: 17px; }
          @media only screen and (min-width: 1200px) and (max-width: 1600px) {
            .deznav .metismenu > li > a .menu-icon {
              line-height: 3.3; } }
        .deznav .metismenu > li > a g [fill] {
          fill: #8088a1; }
      .deznav .metismenu > li:hover > a, .deznav .metismenu > li:focus > a {
        /*  background-color: lighten($color: $primary, $amount: 40%); */
        color: var(--primary); }
        .deznav .metismenu > li:hover > a g [fill], .deznav .metismenu > li:focus > a g [fill] {
          fill: var(--primary); }
        .deznav .metismenu > li:hover > a .menu-icon, .deznav .metismenu > li:focus > a .menu-icon {
          background-color: var(--primary-light); }
          .deznav .metismenu > li:hover > a .menu-icon svg path, .deznav .metismenu > li:focus > a .menu-icon svg path {
            stroke: black; }
      .deznav .metismenu > li.mm-active > a {
        color: var(--primary);
        font-weight: 400; }
        .deznav .metismenu > li.mm-active > a i {
          font-weight: 900; }
        .deznav .metismenu > li.mm-active > a .menu-icon {
          background-color: var(--primary-light); }
          .deznav .metismenu > li.mm-active > a .menu-icon svg path {
            stroke: black; }
        .deznav .metismenu > li.mm-active > a g [fill] {
          fill: var(--primary); }
        .deznav .metismenu > li.mm-active > a:after {
          border-top: 0.3125rem solid #888888;
          border-left: 0.3125rem solid #888888;
          top: 44%;
          border-width: 1px; }
      [data-sidebar-style="compact"] .deznav .metismenu > li.mm-active > a i {
        background: var(--primary);
        color: #fff; }
    .deznav .metismenu li {
      position: relative; }
    .deznav .metismenu .menu-title {
      font-size: 0.75rem;
      font-weight: 400;
      padding: 8px 0px 10px 20.6px;
      color: var(--primary); }
      .deznav .metismenu .menu-title:not(:first-child) {
        margin-top: 25px; }
    .deznav .metismenu ul {
      transition: all .2s ease-in-out;
      position: relative;
      z-index: 1;
      padding: 0.5rem 0; }
      .deznav .metismenu ul li {
        position: relative; }
        .deznav .metismenu ul li:before {
          content: "";
          height: 7px;
          width: 7px;
          background: var(--primary-light);
          position: absolute;
          left: 2.5rem;
          top: 15px;
          transition: all 0.5s;
          transform: rotate(45deg); }
      .deznav .metismenu ul a {
        padding-top: .5rem;
        padding-bottom: .5rem;
        position: relative;
        font-size: 15px;
        padding-left: 5.75rem;
        -webkit-transition: all 0.5s;
        -ms-transition: all 0.5s;
        transition: all 0.5s; }
        @media only screen and (max-width: 84.375rem) {
          .deznav .metismenu ul a {
            padding-left: 6.4rem; } }
        @media only screen and (max-width: 47.9375rem) {
          .deznav .metismenu ul a {
            padding-left: 3.4rem;
            font-size: 0.875rem; } }
        [data-sidebar-style="full"] .deznav .metismenu ul a:before {
          content: "";
          height: 0.125rem;
          width: 0.375rem;
          background: var(--primary-light);
          position: absolute;
          left: 4.6rem;
          top: 50%;
          transform: translateY(-50%);
          -webkit-transition: all 0.5s;
          -ms-transition: all 0.5s;
          transition: all 0.5s; }
        [data-sidebar-style="full"] .deznav .metismenu ul a:hover {
          padding-left: 5.75rem; }
          @media only screen and (max-width: 78.125rem) {
            [data-sidebar-style="full"] .deznav .metismenu ul a:hover {
              padding-left: 3.3rem; } }
          [data-sidebar-style="full"] .deznav .metismenu ul a:hover:before {
            width: 0.75rem; }
        .deznav .metismenu ul a:hover, .deznav .metismenu ul a:focus, .deznav .metismenu ul a.mm-active {
          text-decoration: none;
          color: var(--primary); }
        .deznav .metismenu ul a:before {
          position: absolute;
          content: none;
          height: 0.125rem;
          width: 0.5rem;
          top: 50%;
          left: 1.75rem;
          border-radius: 3.75rem;
          transform: translateY(-50%); }
          @media only screen and (max-width: 84.375rem) {
            .deznav .metismenu ul a:before {
              left: 2.8125rem; } }
    .deznav .metismenu a {
      position: relative;
      display: block;
      padding: 0.625rem 1.875rem;
      outline-width: 0;
      color: #737B8B;
      text-decoration: none; }
      @media only screen and (max-width: 47.9375rem) {
        .deznav .metismenu a {
          padding: 0.625rem 1.25rem; } }
    .deznav .metismenu .has-arrow:after {
      border-top: 0.3125rem solid #C8C8C8;
      border-left: 0.3125rem solid #C8C8C8;
      border-bottom: 0.3125rem solid transparent;
      border-right: 0.3125rem solid transparent;
      right: 15px;
      top: 49%;
      border-width: 1px;
      -webkit-transform: rotate(-225deg) translateY(-50%);
      transform: rotate(-225deg) translateY(-50%); }
    .deznav .metismenu .has-arrow[aria-expanded=true]:after,
    .deznav .metismenu .mm-active > .has-arrow:after {
      -webkit-transform: rotate(-135deg) translateY(-50%);
      transform: rotate(-135deg) translateY(-50%);
      /* margin-right: 2rem; */ }
  .deznav .main-menu {
    padding: 0.625rem 1.875rem; }
  .deznav .header-info2 {
    padding: 0 20px; }
    .deznav .header-info2 img {
      height: 3.75rem;
      width: 3.75rem;
      border-radius: 1.25rem;
      margin-right: 1.2rem; }
    .deznav .header-info2 span {
      font-size: 1.25rem;
      font-weight: 500;
      color: #393939; }
      @media only screen and (max-width: 87.5rem) {
        .deznav .header-info2 span {
          font-size: 0.9rem; } }
    .deznav .header-info2 i {
      margin-left: 1rem;
      font-size: 1.2rem; }
    @media only screen and (max-width: 87.5rem) {
      .deznav .header-info2 {
        padding: 0; } }
  .deznav .ps__rail-y {
    left: 0 !important;
    opacity: 0.1; }
    .deznav .ps__rail-y:hover {
      opacity: 0.1; }

.copyright {
  padding: 0 1.875rem; }
  .copyright p {
    font-size: 0.875rem; }
  .copyright strong {
    display: block;
    font-size: 0.875rem;
    font-weight: 500; }

@media only screen and (max-width: 63.9375rem) {
  .nav-header {
    width: 5rem; } }

@media (max-width: 47.9375rem) {
  .brand-title {
    display: none; }
  .footer {
    padding-left: 0; }
  .deznav {
    left: 0;
    top: 6.2rem; } }

.extra-menu-area + .show-more-btn .show-less {
  display: none; }

.extra-menu-area.show + .show-more-btn .show-less {
  display: block; }

.extra-menu-area.show + .show-more-btn .show-more {
  display: none; }

[data-sidebar-style="compact"] .mini-dashboard, [data-sidebar-style="modern"] .mini-dashboard, [data-sidebar-style="overlay"] .mini-dashboard, [data-sidebar-style="icon-hover"] .mini-dashboard {
  display: none; }

[data-sidebar-position="static"] .deznav {
  height: auto; }

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mega-menu > ul.collapse:not(.in),
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mega-menu > ul.collapse:not(.in) {
  height: 15.75rem !important; }

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mega-menu:hover > a,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mega-menu:hover > a {
  width: calc(70vw + 3.75rem); }

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mega-menu:hover > ul,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mega-menu:hover > ul {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-height: 13.75rem;
  width: 70vw; }
  [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mega-menu:hover > ul ul a,
  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mega-menu:hover > ul ul a {
    width: 101%; }

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mega-menu-xl:hover > a,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mega-menu-xl:hover > a {
  width: calc(70vw + 3rem); }

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mega-menu-xl:hover > ul,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mega-menu-xl:hover > ul {
  max-height: 12.5rem;
  width: 70vw; }
  [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mega-menu-xl:hover > ul ul a,
  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mega-menu-xl:hover > ul ul a {
    width: 101%; }

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mega-menu-lg:hover > a,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mega-menu-lg:hover > a {
  width: calc(55vw + 3rem); }

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mega-menu-lg:hover > ul,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mega-menu-lg:hover > ul {
  max-height: 12.5rem;
  width: 55vw; }
  [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mega-menu-lg:hover > ul ul a,
  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mega-menu-lg:hover > ul ul a {
    width: 101%; }

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mega-menu-md:hover > a,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mega-menu-md:hover > a {
  width: calc(45vw + 3); }

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mega-menu-md:hover > ul,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mega-menu-md:hover > ul {
  max-height: 18.75rem;
  width: 45vw; }
  [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mega-menu-md:hover > ul ul a,
  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mega-menu-md:hover > ul ul a {
    width: 101%; }

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mega-menu-sm:hover > a,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mega-menu-sm:hover > a {
  width: calc(30vw + 3); }

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mega-menu-sm:hover > ul,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mega-menu-sm:hover > ul {
  max-height: 18.125rem;
  width: 30vw; }
  [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mega-menu-sm:hover > ul ul a,
  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mega-menu-sm:hover > ul ul a {
    width: 101%; }

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu > li.mega-menu:hover > a {
  width: calc(60vw + 3.75rem); }

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu > li.mega-menu:hover > ul {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-height: 25rem;
  width: 60vw; }
  [data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu > li.mega-menu:hover > ul ul a {
    width: 101%; }

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu > li.mega-menu-xl:hover > a {
  width: calc(60vw + 3.75rem); }

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu > li.mega-menu-xl:hover > ul {
  max-height: 25.625rem;
  width: 60vw; }

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu > li.mega-menu-lg:hover > a {
  width: calc(50vw + 3.75rem); }

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu > li.mega-menu-lg:hover > ul {
  max-height: 16.25rem;
  width: 50vw; }

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu > li.mega-menu-md:hover > a {
  width: calc(40vw + 3.75rem); }

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu > li.mega-menu-md:hover > ul {
  max-height: 18.75rem;
  width: 40vw; }

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu > li.mega-menu-sm:hover > a {
  width: calc(22vw + 3.75rem); }

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu > li.mega-menu-sm:hover > ul {
  max-height: 18.125rem;
  width: 22vw; }

[data-layout="horizontal"] .deznav .metismenu > li.mega-menu:not(:last-child) {
  position: static; }

[data-layout="horizontal"] .deznav .metismenu > li.mega-menu ul {
  left: 0;
  right: 0; }
  [data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li.mega-menu ul li {
    border-color: #444444; }
  [data-sibebarbg="color_2"][data-layout="horizontal"] .deznav .metismenu > li.mega-menu ul li {
    border-color: #5a740f; }
  [data-sibebarbg="color_3"][data-layout="horizontal"] .deznav .metismenu > li.mega-menu ul li {
    border-color: #ff1616; }
  [data-sibebarbg="color_4"][data-layout="horizontal"] .deznav .metismenu > li.mega-menu ul li {
    border-color: #205ef4; }
  [data-sibebarbg="color_5"][data-layout="horizontal"] .deznav .metismenu > li.mega-menu ul li {
    border-color: #29947b; }
  [data-sibebarbg="color_6"][data-layout="horizontal"] .deznav .metismenu > li.mega-menu ul li {
    border-color: #937d07; }
  [data-sibebarbg="color_7"][data-layout="horizontal"] .deznav .metismenu > li.mega-menu ul li {
    border-color: #229ea3; }
  [data-sibebarbg="color_8"][data-layout="horizontal"] .deznav .metismenu > li.mega-menu ul li {
    border-color: #b63cd2; }
  [data-sibebarbg="color_9"][data-layout="horizontal"] .deznav .metismenu > li.mega-menu ul li {
    border-color: #2588e2; }
  [data-sibebarbg="color_10"][data-layout="horizontal"] .deznav .metismenu > li.mega-menu ul li {
    border-color: #39a651; }
  [data-layout="horizontal"] .deznav .metismenu > li.mega-menu ul li a {
    transition: all .4s ease-in-out; }
    [data-layout="horizontal"] .deznav .metismenu > li.mega-menu ul li a:hover {
      border-radius: 0.25rem; }

[data-layout="horizontal"] .deznav .metismenu > li.mega-menu:hover > ul {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-height: 13.75rem;
  width: 70vw;
  z-index: 99; }
  [data-layout="horizontal"] .deznav .metismenu > li.mega-menu:hover > ul::after {
    content: "";
    height: 100%;
    width: 0.0625rem;
    position: absolute;
    background-color: #fff;
    right: 2.8125rem;
    top: 0; }
    [data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li.mega-menu:hover > ul::after {
      background-color: #262626; }
    [data-sibebarbg="color_2"][data-layout="horizontal"] .deznav .metismenu > li.mega-menu:hover > ul::after {
      background-color: #7da214; }
    [data-sibebarbg="color_3"][data-layout="horizontal"] .deznav .metismenu > li.mega-menu:hover > ul::after {
      background-color: #ff4949; }
    [data-sibebarbg="color_4"][data-layout="horizontal"] .deznav .metismenu > li.mega-menu:hover > ul::after {
      background-color: #5081f7; }
    [data-sibebarbg="color_5"][data-layout="horizontal"] .deznav .metismenu > li.mega-menu:hover > ul::after {
      background-color: #34bc9c; }
    [data-sibebarbg="color_6"][data-layout="horizontal"] .deznav .metismenu > li.mega-menu:hover > ul::after {
      background-color: #c4a609; }
    [data-sibebarbg="color_7"][data-layout="horizontal"] .deznav .metismenu > li.mega-menu:hover > ul::after {
      background-color: #2ac7ce; }
    [data-sibebarbg="color_8"][data-layout="horizontal"] .deznav .metismenu > li.mega-menu:hover > ul::after {
      background-color: #c566db; }
    [data-sibebarbg="color_9"][data-layout="horizontal"] .deznav .metismenu > li.mega-menu:hover > ul::after {
      background-color: #52a0e8; }
    [data-sibebarbg="color_10"][data-layout="horizontal"] .deznav .metismenu > li.mega-menu:hover > ul::after {
      background-color: #4fc368; }
  [data-layout="horizontal"] .deznav .metismenu > li.mega-menu:hover > ul ul a {
    width: 101%; }

[data-layout="horizontal"] .deznav .metismenu > li.mega-menu-xl:hover > ul {
  max-height: 13.125rem;
  width: 70vw; }

[data-layout="horizontal"] .deznav .metismenu > li.mega-menu-lg:hover > ul {
  max-height: 13.125rem;
  width: 43.75rem;
  height: 13.125rem !important; }
  @media only screen and (min-width: 75rem) and (max-width: 93.75rem) {
    [data-layout="horizontal"] .deznav .metismenu > li.mega-menu-lg:hover > ul {
      width: 43.75rem; } }

[data-layout="horizontal"] .deznav .metismenu > li.mega-menu-md:hover > ul {
  max-height: 20rem;
  width: 54vw; }
  @media only screen and (min-width: 75rem) and (max-width: 93.75rem) {
    [data-layout="horizontal"] .deznav .metismenu > li.mega-menu-md:hover > ul {
      width: 60vw; } }

[data-layout="horizontal"] .deznav .metismenu > li.mega-menu-sm:hover > ul {
  max-height: 20rem;
  width: 25vw; }
  @media only screen and (min-width: 75rem) and (max-width: 93.75rem) {
    [data-layout="horizontal"] .deznav .metismenu > li.mega-menu-sm:hover > ul {
      width: 35vw; } }

[data-layout="horizontal"][data-container="boxed"] .deznav .metismenu > li.mega-menu:hover > ul {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column; }

[data-layout="horizontal"][data-container="boxed"] .deznav .metismenu > li.mega-menu-xl:hover > ul {
  max-height: 21.875rem;
  width: 100%; }

[data-layout="horizontal"][data-container="boxed"] .deznav .metismenu > li.mega-menu-lg:hover > ul {
  max-height: 21.875rem;
  width: 55vw; }

[data-layout="horizontal"][data-container="boxed"] .deznav .metismenu > li.mega-menu-md:hover > ul {
  max-height: 18.75rem;
  width: 45vw; }

[data-layout="horizontal"][data-container="boxed"] .deznav .metismenu > li.mega-menu-sm:hover > ul {
  max-height: 18.125rem;
  width: 50vw; }

/* //action button color
$add-btn:blue
$edit-btn:
$delete-btn: */
:root {
  --primary: #A0CF1A;
  --secondary: #111828;
  --secondary-dark: #020304;
  --primary-hover: #7da214;
  --primary-dark: #374709;
  --rgba-primary-1: rgba(160, 207, 26, 0.1);
  --rgba-primary-2: rgba(160, 207, 26, 0.2);
  --rgba-primary-3: rgba(160, 207, 26, 0.3);
  --rgba-primary-4: rgba(160, 207, 26, 0.4);
  --rgba-primary-5: rgba(160, 207, 26, 0.5);
  --rgba-primary-6: rgba(160, 207, 26, 0.6);
  --rgba-primary-7: rgba(160, 207, 26, 0.7);
  --rgba-primary-8: rgba(160, 207, 26, 0.8);
  --rgba-primary-9: rgba(160, 207, 26, 0.9);
  --rgba-secondary-1: rgba(17, 24, 40, 0.1);
  --rgba-secondary-2: rgba(17, 24, 40, 0.2);
  --rgba-secondary-3: rgba(17, 24, 40, 0.3);
  --rgba-secondary-4: rgba(17, 24, 40, 0.4);
  --rgba-secondary-5: rgba(17, 24, 40, 0.5);
  --rgba-secondary-6: rgba(17, 24, 40, 0.6);
  --rgba-secondary-7: rgba(17, 24, 40, 0.7);
  --rgba-secondary-8: rgba(17, 24, 40, 0.8);
  --rgba-secondary-9: rgba(17, 24, 40, 0.9);
  --font-family-base: DM Sans, sans-serif, sans-serif;
  --font-family-title: DM Sans, sans-serif, sans-serif;
  --title: #111828;
  --text-dark: #111828; }

/* $primary-light: lighten($primary, 43%);
$secondary-light: lighten($secondary, 10%);
$success-light: lighten($success, 15%);
$warning-light: lighten($warning, 20%);
$danger-light: lighten($danger, 10%);
$info-light: lighten($info, 20%);
$dark-light: lighten($dark, 50%); */
/*
0 - 600: Phone
600 - 900: Tablet portrait
900 - 1200: Tablet landscape
1200 - 1800: Normal styles
1800+ : Big Desktop
1em = 16px
The smaller device rules always should write below the bigger device rules
Fixing Order => Base + Typography >> General Layout + Grid >> Page Layout + Component
*/
[data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu > li > a {
  font-size: 1rem;
  padding: 0.594rem 1.3rem;
  margin: 0px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s; }

[data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a i {
  color: #fff;
  font-weight: 300; }

[data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu > li .has-arrow:after {
  right: 1.5rem; }

[data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu .mini-dashboard {
  display: none; }

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu ul li:before {
  display: none; }

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .switch-btn {
  padding: 40px 38px; }

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li:hover > .has-arrow .menu-icon {
  border-radius: 30px 0 0 30px; }

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header {
  width: 6.25rem;
  z-index: 999; }
  [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .brand-logo {
    padding-left: 0;
    padding-right: 0;
    justify-content: center; }
    [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .brand-logo .color-title {
      display: none; }
  [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control {
    right: -5.4rem; }
    [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
      background-color: #fff; }

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .plus-box,
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .help-desk {
  display: none; }

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .header {
  padding-left: 6.25rem;
  width: 100%; }
  [direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .header {
    padding: 0 0.9375rem;
    padding-right: 7.5rem; }
  [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .header .header-content {
    padding-left: 6.5rem; }

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav {
  width: 6.25rem;
  overflow: visible;
  position: absolute !important; }
  [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .nav-text {
    display: none; }
  [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .deznav-scroll {
    overflow-y: unset; }
  [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .header-info2 {
    padding: 0; }
  [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .sidebar-info {
    display: none !important; }
  [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .header-profile2 {
    margin: 0 0.5rem; }
  [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li {
    position: relative; }
    [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li .mini-dashboard {
      display: block;
      font-size: 15px;
      font-weight: 700;
      z-index: 6;
      color: #111828;
      padding: 14.7px 21px; }
      [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li .mini-dashboard:after {
        content: "";
        position: absolute;
        top: 0;
        left: -39px;
        height: 50px;
        width: 15rem;
        background: var(--primary-light);
        z-index: -1;
        border-radius: 0 30px 30px 0; }
        @media only screen and (max-width: 100rem) {
          [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li .mini-dashboard:after {
            left: -24px;
            width: 13.7rem; } }
    [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li a {
      background: transparent;
      margin: 0.125rem 0; }
      [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li a svg {
        max-width: 1.5rem;
        max-height: 1.5rem;
        margin-right: 0; }
      [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li a:before {
        content: none; }
      [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li a i {
        margin: 0; }
    [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li > ul {
      position: absolute;
      left: 6.25rem;
      top: 3px;
      width: 13rem;
      z-index: 1001;
      display: none;
      padding-left: 0.0625rem;
      height: auto !important;
      box-shadow: 1rem 0.75rem 1.25rem 0 rgba(82, 63, 105, 0.1);
      margin-left: 0;
      border: 0;
      border-radius: 0 2rem 2rem 0;
      background: var(--sidebar-nav-bg); }
      [direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li > ul {
        left: auto;
        right: 5rem; }
      [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li > ul li:hover ul {
        left: 12.9125rem;
        top: -10rem;
        border-radius: 0.625rem; }
        [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li > ul li:hover ul:after {
          content: none; }
    [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li:hover > ul {
      display: block;
      height: auto;
      overflow: visible; }
  [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li {
    transition: all 0.4s ease-in-out; }
    [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li > a {
      padding: 0.594rem 1.475rem;
      margin: 3px 0px;
      text-align: center; }
      [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li > a .badge {
        display: none; }
      [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li > a.has-arrow:after {
        display: none; }
    [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mm-active > a i {
      color: #fff;
      padding: 0; }
    [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li:hover:nth-last-child(-n + 1) > ul {
      bottom: 0;
      top: auto; }
    [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li:hover > a {
      color: #fff; }
      [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li:hover > a i {
        color: #fff; }
    [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li:hover > ul {
      height: auto !important;
      padding: 0.625rem 0; }
      [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li:hover > ul a {
        padding: 0.375rem 1.25rem 0.375rem 1.25rem;
        margin-left: -.1rem; }
      [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li:hover > ul ul {
        padding: 0.625rem 0; }
        [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li:hover > ul ul a {
          padding: 0.375rem 1.25rem 0.375rem 1.25rem;
          margin-left: -.1rem; }
  [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .nav-label,
  [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .nav-badge {
    display: none; }
  [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .menu-title {
    display: none; }
  [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .switch-btn a span {
    display: none; }

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .outer-body {
  padding-left: 6.25rem; }
  [direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .outer-body {
    margin-right: 5.7rem;
    margin-left: auto;
    border: 0; }

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle + .footer {
  padding-left: 5.7rem; }
  [direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle + .footer {
    padding-left: 0;
    padding-right: 5.7rem; }

[data-sidebar-style="full"][data-layout="vertical"] [data-sidebar-style="full"][data-layout="horizontal"] .header .header-content {
  padding-left: 1.875rem; }

[data-sidebar-style="full"][data-layout="vertical"][data-sidebarbg="color_1"] .menu-toggle .deznav .metismenu li > ul {
  background-color: #fff; }

[data-sidebar-style="full"][data-layout="vertical"] [data-nav-headerbg="color_1"] .menu-toggle .color-title {
  display: none !important; }

[data-sidebar-style="full"][data-layout="vertical"] .show-more-btn {
  padding: 0.75rem 1.5rem;
  font-size: 0.813rem !important;
  color: var(--primary) !important; }
  [data-sidebar-style="full"][data-layout="vertical"] .show-more-btn:before {
    display: none; }
  [data-sidebar-style="full"][data-layout="vertical"] .show-more-btn:active {
    border: 0 !important; }
  [data-sidebar-style="full"][data-layout="vertical"] .show-more-btn.collapsed {
    border: 0; }

[data-header-position="static"][data-sidebar-position="fixed"][data-layout="vertical"][data-sidebar-style="full"] .outer-body {
  top: 0px;
  padding-top: 99px; }
  [data-header-position="static"][data-sidebar-position="fixed"][data-layout="vertical"][data-sidebar-style="full"] .outer-body .inner-body {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

[data-header-position="static"][data-sidebar-position="fixed"][data-layout="vertical"][data-sidebar-style="full"] .calendar-warpper {
  top: 0;
  padding-top: 99px;
  height: 100%; }

[data-header-position="static"][data-sidebar-position="static"][data-layout="vertical"] .outer-body {
  position: absolute;
  height: auto;
  top: 89px; }
  [data-header-position="static"][data-sidebar-position="static"][data-layout="vertical"] .outer-body .inner-body {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  @media only screen and (max-width: 100rem) {
    [data-header-position="static"][data-sidebar-position="static"][data-layout="vertical"] .outer-body {
      top: 77px; } }

[data-header-position="static"][data-sidebar-position="static"][data-layout="vertical"] .calendar-warpper {
  top: 0px;
  height: 100%; }

@media only screen and (max-width: 100rem) {
  [data-header-position="static"][data-sidebar-position="fixed"][data-layout="vertical"] .outer-body {
    top: 76px !important; } }

[data-header-position="static"][data-sidebar-position="fixed"][data-layout="vertical"] .calendar-warpper {
  height: calc(100vh - 4.2rem); }

/*
0 - 600: Phone
600 - 900: Tablet portrait
900 - 1200: Tablet landscape
1200 - 1800: Normal styles
1800+ : Big Desktop
1em = 16px
The smaller device rules always should write below the bigger device rules
Fixing Order => Base + Typography >> General Layout + Grid >> Page Layout + Component
*/
/* //action button color
$add-btn:blue
$edit-btn:
$delete-btn: */
:root {
  --primary: #A0CF1A;
  --secondary: #111828;
  --secondary-dark: #020304;
  --primary-hover: #7da214;
  --primary-dark: #374709;
  --rgba-primary-1: rgba(160, 207, 26, 0.1);
  --rgba-primary-2: rgba(160, 207, 26, 0.2);
  --rgba-primary-3: rgba(160, 207, 26, 0.3);
  --rgba-primary-4: rgba(160, 207, 26, 0.4);
  --rgba-primary-5: rgba(160, 207, 26, 0.5);
  --rgba-primary-6: rgba(160, 207, 26, 0.6);
  --rgba-primary-7: rgba(160, 207, 26, 0.7);
  --rgba-primary-8: rgba(160, 207, 26, 0.8);
  --rgba-primary-9: rgba(160, 207, 26, 0.9);
  --rgba-secondary-1: rgba(17, 24, 40, 0.1);
  --rgba-secondary-2: rgba(17, 24, 40, 0.2);
  --rgba-secondary-3: rgba(17, 24, 40, 0.3);
  --rgba-secondary-4: rgba(17, 24, 40, 0.4);
  --rgba-secondary-5: rgba(17, 24, 40, 0.5);
  --rgba-secondary-6: rgba(17, 24, 40, 0.6);
  --rgba-secondary-7: rgba(17, 24, 40, 0.7);
  --rgba-secondary-8: rgba(17, 24, 40, 0.8);
  --rgba-secondary-9: rgba(17, 24, 40, 0.9);
  --font-family-base: DM Sans, sans-serif, sans-serif;
  --font-family-title: DM Sans, sans-serif, sans-serif;
  --title: #111828;
  --text-dark: #111828; }

/* $primary-light: lighten($primary, 43%);
$secondary-light: lighten($secondary, 10%);
$success-light: lighten($success, 15%);
$warning-light: lighten($warning, 20%);
$danger-light: lighten($danger, 10%);
$info-light: lighten($info, 20%);
$dark-light: lighten($dark, 50%); */
@media only screen and (min-width: 48rem) {
  [data-sidebar-style="mini"] {
    /* .deznav{
			.metismenu{
				.mm-active{
						.has-arrow{
							.menu-icon{
								&:hover{
									border-radius:30px 0 0 30px;
								}	
							}
						}	
					
				}
			}
			
		} */ }
    [data-sidebar-style="mini"] .deznav .metismenu > li:hover > .has-arrow .menu-icon {
      border-radius: 30px 0 0 30px; }
    [data-sidebar-style="mini"] .deznav .metismenu ul li:before {
      display: none; }
    [data-sidebar-style="mini"] .nav-header {
      width: 6.25rem; }
      [data-sidebar-style="mini"] .nav-header .nav-control {
        z-index: -1; }
        [data-sidebar-style="mini"] .nav-header .nav-control .hamburger {
          left: 6.25rem !important; }
          [data-sidebar-style="mini"] .nav-header .nav-control .hamburger .line {
            background-color: #333333 !important; }
      [data-sidebar-style="mini"] .nav-header .brand-title {
        display: none; }
      [data-sidebar-style="mini"] .nav-header .hamburger {
        display: none; }
    [data-sidebar-style="mini"] .header .header-content {
      padding-left: 2.5rem; }
      [direction="rtl"][data-sidebar-style="mini"] .header .header-content {
        padding-right: 1.875rem; }
    [data-sidebar-style="mini"] .deznav {
      width: 6.25rem;
      overflow: visible;
      position: absolute;
      z-index: 2; }
      [data-sidebar-style="mini"] .deznav .copyright,
      [data-sidebar-style="mini"] .deznav .plus-box {
        display: none; }
      [data-sidebar-style="mini"] .deznav .nav-text {
        display: none; }
      [data-sidebar-style="mini"] .deznav .slimScrollDiv,
      [data-sidebar-style="mini"] .deznav .deznav-scroll {
        overflow: visible !important; }
      [data-sidebar-style="mini"] .deznav .nav-user {
        padding: 0.6875rem; }
        [data-sidebar-style="mini"] .deznav .nav-user .media-body {
          display: none; }
      [data-sidebar-style="mini"] .deznav .header-profile {
        margin-bottom: 0;
        margin-top: 0.75rem; }
        [data-sidebar-style="mini"] .deznav .header-profile:hover > a.nav-link {
          border-radius: 3rem; }
        [data-sidebar-style="mini"] .deznav .header-profile img {
          width: 3rem;
          height: 3rem; }
        [data-sidebar-style="mini"] .deznav .header-profile > a.nav-link {
          border-radius: 3rem;
          padding: 0.3125rem 0.3125rem; }
        [data-sidebar-style="mini"] .deznav .header-profile .header-info {
          display: none; }
      [data-sidebar-style="mini"] .deznav .metismenu li .mini-dashboard {
        position: relative;
        color: #111828;
        font-size: 15px;
        font-weight: 700;
        z-index: 6;
        padding: 13.6px 21px; }
        [data-sidebar-style="mini"] .deznav .metismenu li .mini-dashboard:after {
          content: "";
          position: absolute;
          top: 0;
          left: -39px;
          height: 50px;
          width: 15rem;
          background: var(--primary-light);
          z-index: -1;
          border-radius: 0 30px 30px 0; } }
        @media only screen and (min-width: 48rem) and (max-width: 100rem) {
          [data-sidebar-style="mini"] .deznav .metismenu li .mini-dashboard:after {
            left: -24px;
            width: 13.7rem; } }

@media only screen and (min-width: 48rem) {
      [data-sidebar-style="mini"] .deznav .metismenu li a {
        padding: 0.813rem 0.875rem; }
        [data-sidebar-style="mini"] .deznav .metismenu li a svg {
          margin-right: 0; }
        [data-sidebar-style="mini"] .deznav .metismenu li a i {
          height: auto;
          width: auto;
          line-height: 1;
          margin: 0; }
      [data-sidebar-style="mini"] .deznav .metismenu li > ul {
        position: absolute;
        left: 6.25rem;
        top: 2.9375rem;
        width: 11.875rem;
        z-index: 1001;
        display: none;
        padding-left: 0.0625rem;
        box-shadow: 1rem 0.75rem 1.25rem 0 rgba(82, 63, 105, 0.1);
        height: auto !important;
        border-radius: 0 2rem 2rem 0;
        background-color: var(--sidebar-nav-bg); }
        [direction="rtl"]:not([data-layout="horizontal"])[data-sidebar-style="mini"] .deznav .metismenu li > ul {
          left: auto;
          right: 6.25rem;
          box-shadow: 0rem 0rem 2.5rem 0rem rgba(82, 63, 105, 0.1); }
        [data-sidebar-style="mini"] .deznav .metismenu li > ul li ul {
          border-radius: 0.625rem; }
      [data-sidebar-style="mini"] .deznav .metismenu > li > a {
        padding: 0.5rem 1.575rem;
        text-align: center;
        line-height: 1;
        transition: all 0.5s;
        -moz-transition: all 0.5s;
        -webkit-transition: all 0.5s;
        -ms-transition: all 0.5s;
        -o-transition: all 0.5s; }
        [data-sidebar-style="mini"] .deznav .metismenu > li > a > i {
          padding: 0;
          font-size: 1.375rem; }
        [data-sidebar-style="mini"] .deznav .metismenu > li > a.has-arrow:after {
          display: none; }
        [data-sidebar-style="mini"] .deznav .metismenu > li > a .badge {
          display: none; }
      [data-sidebar-style="mini"] .deznav .metismenu .menu-title {
        display: none; }
      [data-sidebar-style="mini"] .deznav .metismenu .nav-label,
      [data-sidebar-style="mini"] .deznav .metismenu .nav-badge {
        display: none; }
      [data-sidebar-style="mini"] .deznav .switch-btn {
        padding: 40px 38px; }
        [data-sidebar-style="mini"] .deznav .switch-btn button span {
          display: none; }
      [data-sidebar-style="mini"] .deznav .header-info2 {
        padding: 0; }
      [data-sidebar-style="mini"] .deznav .sidebar-info {
        display: none !important; }
      [data-sidebar-style="mini"] .deznav .header-profile2 {
        margin: 0 0.5rem; }
      [data-sidebar-style="mini"] .deznav .help-desk {
        display: none; }
    [data-sidebar-style="mini"] .outer-body {
      padding-left: 6.28rem; }
    [data-sidebar-style="mini"][data-header-position="fixed"] .content-body {
      padding-top: 5.5rem; } }
    @media only screen and (min-width: 48rem) and (max-width: 63.9375rem) {
      [data-sidebar-style="mini"][data-header-position="fixed"] .content-body {
        padding-top: 5.4rem; } }

@media only screen and (min-width: 48rem) {
    [data-sidebar-style="mini"][data-sidebarbg="color_1"] .metismenu li > ul {
      background-color: #fff; }
  [data-sidebar-style="mini"][data-layout="vertical"] .deznav {
    position: absolute !important; }
    [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu .has-arrow[aria-expanded=true]:after,
    [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu .mm-active > .has-arrow:after {
      -webkit-transform: rotate(-225deg) translateY(-50%) !important;
      transform: rotate(-225deg) translateY(-50%) !important;
      /* margin-right: 2rem; */ }
    [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li:hover > ul {
      display: block; }
    [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li:nth-last-child(-n + 1) > ul {
      bottom: 0;
      top: auto !important; }
      [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li:nth-last-child(-n + 1) > ul:after {
        top: auto;
        bottom: 1.25rem; } }
  @media only screen and (min-width: 48rem) and (max-width: 74.9375rem) {
    [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li:nth-last-child(-n + 1) > ul {
      bottom: 0;
      top: auto !important; } }

@media only screen and (min-width: 48rem) {
    [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li > ul {
      overflow: visible; }
      [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li > ul:after {
        content: none; }
      [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li > ul li:hover ul {
        padding: 0.625rem 0;
        width: 13rem;
        left: 13rem;
        top: -0.625rem;
        border: 0;
        margin: 0; }
        [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li > ul li:hover ul:after {
          content: none; }
        [direction="rtl"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li > ul li:hover ul {
          left: auto;
          right: 13rem; }
    [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a {
      color: #fff; }
      [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a i {
        color: #fff; }
    [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li:hover > a {
      color: var(--primary);
      /* box-shadow:0 12px 15px 0 var(--rgba-primary-1); */
      border-radius: 0;
      position: unset; }
      [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li:hover > a i {
        color: #fff; }
      [direction="rtl"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li:hover > a .nav-text {
        padding-left: auto;
        padding-right: 1.6875rem; }
    [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li:hover > ul {
      height: auto !important;
      overflow: visible;
      border: 0;
      margin-left: 0;
      left: 6.25rem;
      width: 13rem;
      border: 0;
      padding: 0.5rem 0;
      top: 0px; }
      [data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li:hover > ul {
        box-shadow: 0rem 0rem 2.5rem 0rem rgba(82, 63, 105, 0.1);
        background-color: #1E1E1E; }
      [direction="rtl"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li:hover > ul {
        left: auto;
        right: 6.25rem; }
      [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li:hover > ul a {
        padding: 0.575rem 1.25rem 0.575rem 1.25rem; }
        [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li:hover > ul a:before {
          content: none; }
        [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li:hover > ul a.has-arrow:after {
          right: 1.25rem; }
      [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li:hover > ul ul a {
        padding: 0.575rem 1.25rem 0.575rem 1.25rem;
        margin-left: -0.1rem; }
        [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li:hover > ul ul a:before {
          content: none; }
  [data-sidebar-style="mini"][data-header-position="fixed"][data-container="boxed"][data-layout="vertical"] .header {
    width: 74.9375rem; }
  [data-sidebar-style="mini"][data-layout="horizontal"] .deznav .metismenu > li {
    padding: 0; }
    [data-sidebar-style="mini"][data-layout="horizontal"] .deznav .metismenu > li > a {
      padding: 1rem 1rem; }
      [data-sidebar-style="mini"][data-layout="horizontal"] .deznav .metismenu > li > a i {
        padding: 0;
        margin: 0; }
      [direction="rtl"][data-sidebar-style="mini"][data-layout="horizontal"] .deznav .metismenu > li > a {
        padding: 1.125rem; }
        [direction="rtl"][data-sidebar-style="mini"][data-layout="horizontal"] .deznav .metismenu > li > a svg {
          margin-left: 0; }
      [data-sidebar-style="mini"][data-layout="horizontal"] .deznav .metismenu > li > a svg {
        margin-right: 0;
        margin-top: 0; }
    [data-sidebar-style="mini"][data-layout="horizontal"] .deznav .metismenu > li > ul li a {
      padding: 0.5rem 1.25rem 0.5rem 1.25rem; } }

@media only screen and (max-width: 74.9375rem) {
  [data-sidebar-style="mini"] .deznav li.mm-active ul {
    height: auto !important; }
  [data-sidebar-style="mini"] .deznav li a.has-arrow::after {
    transform: rotate(-225deg) translateY(-50%); } }

[data-sidebar-style="mini"][data-sidebar-position="fixed"][data-header-position="static"] .outer-body {
  padding-top: 0; }
  [data-sidebar-style="mini"][data-sidebar-position="fixed"][data-header-position="static"] .outer-body .inner-body {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-top: 0; }

[data-sidebar-style="mini"][data-sidebar-position="fixed"][data-header-position="static"] .calendar-warpper {
  top: 0;
  height: 100%; }

[data-header-position="static"][data-sidebar-position="static"][data-layout="horizontal"][data-sidebar-style="mini"] .calendar-warpper {
  top: 175px; }

[data-header-position="static"][data-sidebar-position="fixed"][data-layout="vertical"] .outer-body {
  position: absolute;
  height: auto; }

[data-header-position="static"][data-sidebar-position="fixed"][data-layout="vertical"] .deznav {
  position: fixed !important; }

/* //action button color
$add-btn:blue
$edit-btn:
$delete-btn: */
:root {
  --primary: #A0CF1A;
  --secondary: #111828;
  --secondary-dark: #020304;
  --primary-hover: #7da214;
  --primary-dark: #374709;
  --rgba-primary-1: rgba(160, 207, 26, 0.1);
  --rgba-primary-2: rgba(160, 207, 26, 0.2);
  --rgba-primary-3: rgba(160, 207, 26, 0.3);
  --rgba-primary-4: rgba(160, 207, 26, 0.4);
  --rgba-primary-5: rgba(160, 207, 26, 0.5);
  --rgba-primary-6: rgba(160, 207, 26, 0.6);
  --rgba-primary-7: rgba(160, 207, 26, 0.7);
  --rgba-primary-8: rgba(160, 207, 26, 0.8);
  --rgba-primary-9: rgba(160, 207, 26, 0.9);
  --rgba-secondary-1: rgba(17, 24, 40, 0.1);
  --rgba-secondary-2: rgba(17, 24, 40, 0.2);
  --rgba-secondary-3: rgba(17, 24, 40, 0.3);
  --rgba-secondary-4: rgba(17, 24, 40, 0.4);
  --rgba-secondary-5: rgba(17, 24, 40, 0.5);
  --rgba-secondary-6: rgba(17, 24, 40, 0.6);
  --rgba-secondary-7: rgba(17, 24, 40, 0.7);
  --rgba-secondary-8: rgba(17, 24, 40, 0.8);
  --rgba-secondary-9: rgba(17, 24, 40, 0.9);
  --font-family-base: DM Sans, sans-serif, sans-serif;
  --font-family-title: DM Sans, sans-serif, sans-serif;
  --title: #111828;
  --text-dark: #111828; }

/* $primary-light: lighten($primary, 43%);
$secondary-light: lighten($secondary, 10%);
$success-light: lighten($success, 15%);
$warning-light: lighten($warning, 20%);
$danger-light: lighten($danger, 10%);
$info-light: lighten($info, 20%);
$dark-light: lighten($dark, 50%); */
@media (min-width: 64rem) {
  [data-layout="horizontal"] {
    --nav-headbg:#f4f4f5!important; }
    [data-theme-version="dark"][data-layout="horizontal"] {
      --nav-headbg:#2c2c2c!important; }
      [data-theme-version="dark"][data-layout="horizontal"] .nav-header .brand-title path {
        fill: #fff !important; }
    [data-layout="horizontal"] .deznav .metismenu ul li:before {
      display: none; }
    [data-layout="horizontal"][data-sidebar-style="compact"] .deznav .metismenu li:hover .menu-icon {
      stroke: black !important; }
    [data-layout="horizontal"][data-sidebar-style="compact"] .deznav .metismenu > li.mm-active > a .menu-icon svg path {
      stroke: black !important; }
    [data-layout="horizontal"] .calendar-warpper {
      top: 11.025rem;
      height: calc(100vh - 10.6rem); }
    [data-layout="horizontal"] .deznav .metismenu > li > a .menu-icon {
      margin-right: 0px;
      background: transparent;
      height: 30px;
      width: 30px;
      line-height: 25px; }
    [data-layout="horizontal"] .deznav .metismenu > li > ul .extra-menu-li:hover .extra-menu-links {
      display: block !important;
      position: relative !important;
      top: 0px !important;
      left: 0 !important;
      right: auto !important; }
    [data-layout="horizontal"] .deznav .metismenu > li > ul .extra-menu-li .extra-menu-links {
      position: relative;
      top: 0px !important;
      left: 0 !important;
      padding: 0 !important;
      overflow: hidden;
      right: auto !important;
      box-shadow: none;
      display: block !important; }
      [data-layout="horizontal"] .deznav .metismenu > li > ul .extra-menu-li .extra-menu-links:not(.show) {
        height: 0; }
      [data-layout="horizontal"] .deznav .metismenu > li > ul .extra-menu-li .extra-menu-links li a {
        padding: 0.5rem 1.35rem 0.5rem 1.25rem !important; }
    [data-layout="horizontal"] .outer-body {
      padding-left: 10px !important;
      padding-top: 10px;
      top: 0; }
    [data-layout="horizontal"] .nav-header {
      height: 6.25rem;
      width: 18.563rem;
      top: 10px;
      z-index: 7;
      border: 0;
      left: 10px;
      border-top-left-radius: 20px;
      border-left: 1px solid black;
      border: 1px solid black;
      border-right: 0;
      border-bottom: 0; }
      [data-layout="horizontal"] .nav-header .brand-title path {
        fill: black !important; }
      [data-layout="horizontal"] .nav-header .nav-control {
        display: none; }
      [data-layout="horizontal"] .nav-header .brand-logo {
        padding-left: 2.5rem;
        padding-right: 2.5rem;
        padding-top: 0; }
    [data-layout="horizontal"] .header {
      width: 100%;
      padding-left: 18.563rem;
      z-index: 6;
      padding-right: 11px !important;
      top: 10px;
      padding-top: 0;
      border-top-left-radius: 20px;
      background-color: unset; }
      [data-layout="horizontal"] .header .header-content {
        border: 0;
        border-top-left-radius: 0px;
        border-top: 1px solid black; }
    [data-layout="horizontal"] .deznav {
      width: 100%;
      position: relative;
      height: auto;
      padding-bottom: 0;
      top: 0;
      z-index: 5;
      padding-top: 0; }
      [data-layout="horizontal"] .deznav .slimScrollDiv {
        overflow: visible !important; }
        [data-layout="horizontal"] .deznav .slimScrollDiv .deznav-scroll {
          overflow: visible !important; }
      [data-layout="horizontal"] .deznav .deznav-scroll {
        overflow: visible !important; }
      [data-layout="horizontal"] .deznav .slimScrollBar {
        display: none !important; }
      [data-layout="horizontal"] .deznav .header-profile {
        margin-right: 0.9375rem;
        margin-bottom: 0;
        display: none; }
        [data-layout="horizontal"] .deznav .header-profile:hover > a.nav-link {
          border-radius: 3rem; }
        [data-layout="horizontal"] .deznav .header-profile img {
          height: 2.8125rem;
          width: 2.8125rem; }
        [data-layout="horizontal"] .deznav .header-profile > a.nav-link {
          border-radius: 3rem;
          padding: 0.3125rem 0.3125rem; }
        [data-layout="horizontal"] .deznav .header-profile .header-info {
          display: none; }
      [data-layout="horizontal"] .deznav .header-profile2 {
        display: none; }
      [data-layout="horizontal"] .deznav .nav-user,
      [data-layout="horizontal"] .deznav .nav-label {
        display: none; }
      [data-layout="horizontal"] .deznav .metismenu {
        flex-direction: row;
        padding: 0 1.25rem;
        margin-bottom: 0;
        display: inline-flex;
        flex-wrap: wrap; }
        [data-layout="horizontal"] .deznav .metismenu .collapse.in {
          display: none; }
        [data-layout="horizontal"] .deznav .metismenu ul {
          border-left: 0; }
          [data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu ul {
            box-shadow: 0rem 0rem 2.5rem 0rem rgba(82, 63, 105, 0.1); }
        [data-layout="horizontal"] .deznav .metismenu li {
          flex-direction: column;
          position: relative; }
          [data-layout="horizontal"] .deznav .metismenu li:hover > ul {
            display: block; }
          [data-layout="horizontal"] .deznav .metismenu li > ul {
            position: absolute;
            top: 100%;
            width: 100%;
            min-width: 13.75rem;
            z-index: 999;
            left: auto;
            right: auto;
            padding: 0.5rem 0 0.5rem;
            display: none;
            box-shadow: 0rem 0rem 2.5rem 0rem rgba(82, 63, 105, 0.1);
            margin: 0;
            background: var(--sidebar-nav-bg);
            border-radius: 0.625rem; }
            [data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu li > ul {
              box-shadow: 0rem 0rem 2.5rem 0rem rgba(82, 63, 105, 0.1);
              background: #2c2c2c; }
            [data-layout="horizontal"] .deznav .metismenu li > ul li {
              padding: 0;
              -webkit-transition: all 0.5s;
              -ms-transition: all 0.5s;
              transition: all 0.5s; }
              [data-layout="horizontal"] .deznav .metismenu li > ul li a {
                transition: all .4s ease-in-out;
                padding: 0.5rem 1.25rem 0.5rem 1.25rem;
                margin-left: -.1rem; }
                [direction="rtl"][data-layout="horizontal"] .deznav .metismenu li > ul li a {
                  padding: 0.5rem 1.25rem 0.5rem 1.25rem;
                  text-align: right; }
                [data-layout="horizontal"] .deznav .metismenu li > ul li a:hover {
                  border-radius: .4rem;
                  padding-left: 1.25rem;
                  color: white !important; }
                [data-layout="horizontal"] .deznav .metismenu li > ul li a:before {
                  content: none;
                  left: 1.375rem; }
                  [direction="rtl"][data-layout="horizontal"] .deznav .metismenu li > ul li a:before {
                    left: auto;
                    right: 0.375rem; }
              [data-layout="horizontal"] .deznav .metismenu li > ul li svg {
                width: 0.938rem;
                height: 1rem; }
            [data-layout="horizontal"] .deznav .metismenu li > ul .menu-title {
              display: none; }
            [data-layout="horizontal"] .deznav .metismenu li > ul ul {
              left: 100%;
              top: 0;
              box-shadow: 0rem 0rem 2.5rem 0rem rgba(82, 63, 105, 0.1);
              background: white; }
              [direction="rtl"][data-layout="horizontal"] .deznav .metismenu li > ul ul {
                left: auto;
                right: 100%; }
        [data-layout="horizontal"] .deznav .metismenu > li {
          flex: 0 0 auto;
          position: relative; }
          [data-layout="horizontal"] .deznav .metismenu > li > a i {
            margin-right: 0.3125rem; }
          [data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li {
            border-color: rgba(255, 255, 255, 0.07); }
            [data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li.mm-active {
              border-color: transparent; }
          [data-layout="horizontal"] .deznav .metismenu > li:hover, [data-layout="horizontal"] .deznav .metismenu > li.mm-active {
            padding: 0; }
            [data-layout="horizontal"] .deznav .metismenu > li:hover > a, [data-layout="horizontal"] .deznav .metismenu > li.mm-active > a {
              color: var(--primary); }
              [data-layout="horizontal"] .deznav .metismenu > li:hover > a i, [data-layout="horizontal"] .deznav .metismenu > li.mm-active > a i {
                color: #fff;
                background: transparent;
                box-shadow: none; }
              [data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon, [data-layout="horizontal"] .deznav .metismenu > li.mm-active > a .menu-icon {
                background-color: transparent; }
                [data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon svg path, [data-layout="horizontal"] .deznav .metismenu > li.mm-active > a .menu-icon svg path {
                  stroke: var(--primary); }
            [data-layout="horizontal"] .deznav .metismenu > li:hover ul li > a:after, [data-layout="horizontal"] .deznav .metismenu > li.mm-active ul li > a:after {
              transform: rotate(-225deg) translateY(-50%); }
            [data-layout="horizontal"] .deznav .metismenu > li:hover ul li:hover .menu-icon, [data-layout="horizontal"] .deznav .metismenu > li.mm-active ul li:hover .menu-icon {
              background-color: transparent !important; }
            [data-layout="horizontal"] .deznav .metismenu > li:hover .mini-dashboard, [data-layout="horizontal"] .deznav .metismenu > li.mm-active .mini-dashboard {
              display: none; }
          [direction="rtl"][data-layout="horizontal"] .deznav .metismenu > li:first-child {
            border-right: 0; }
          [data-theme-version="dark"][direction="rtl"][data-layout="horizontal"] .deznav .metismenu > li {
            border-color: #444444; }
          [data-layout="horizontal"] .deznav .metismenu > li > a {
            padding: 1rem 0.6375rem 1rem 0.6375rem;
            margin: 0.125rem 0.125rem; }
            [direction="rtl"][data-layout="horizontal"] .deznav .metismenu > li > a {
              padding: 0.9375rem 0.9375rem 0.9375rem 2.5rem; }
              [direction="rtl"][data-layout="horizontal"] .deznav .metismenu > li > a .badge {
                display: none; }
            [data-layout="horizontal"] .deznav .metismenu > li > a i {
              padding: 0 0.4375rem 0 0;
              height: auto;
              width: auto;
              line-height: 1; }
              [direction="rtl"][data-layout="horizontal"] .deznav .metismenu > li > a i {
                padding: 0 0 0 0.4375rem; }
            [data-layout="horizontal"] .deznav .metismenu > li > a .nav-badge {
              display: none; }
            [data-layout="horizontal"] .deznav .metismenu > li > a:after {
              right: 1.25rem;
              transform: rotate(-135deg) translateY(-50%);
              display: none; }
          [data-layout="horizontal"] .deznav .metismenu > li:hover {
            border-color: transparent; }
            [data-layout="horizontal"] .deznav .metismenu > li:hover > ul {
              display: flex !important;
              flex-direction: column;
              flex-wrap: wrap;
              height: auto !important;
              box-shadow: 0.3125rem 0.3125rem 1.875rem 0rem rgba(20, 0, 30, 0.1);
              border-radius: 0 0 0.625rem 0.625rem;
              background-color: white; }
              [data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:hover > ul {
                box-shadow: 0.3125rem 0.3125rem 1.875rem 0rem rgba(0, 0, 0, 0.1);
                background: #2c2c2c; }
          [data-layout="horizontal"] .deznav .metismenu > li > ul > li:hover ul.collapse {
            display: block !important;
            position: absolute;
            left: auto !important;
            right: -100% !important;
            top: 0 !important; }
          [data-layout="horizontal"] .deznav .metismenu > li:nth-last-child(-n + 5) > ul {
            left: auto;
            right: 0; }
            [data-layout="horizontal"] .deznav .metismenu > li:nth-last-child(-n + 5) > ul > li:hover ul.collapse {
              right: auto !important;
              left: -100% !important; }
            [data-layout="horizontal"] .deznav .metismenu > li:nth-last-child(-n + 5) > ul.left {
              left: 0; }
              [data-layout="horizontal"] .deznav .metismenu > li:nth-last-child(-n + 5) > ul.left > li:hover ul.collapse {
                left: 100% !important; }
              [direction="rtl"][data-layout="horizontal"] .deznav .metismenu > li:nth-last-child(-n + 5) > ul.left {
                left: auto;
                right: 0; }
          [data-layout="horizontal"] .deznav .metismenu > li:last-child > ul ul {
            left: -100%; }
          [data-layout="horizontal"] .deznav .metismenu > li:last-child > ul.left ul {
            -webkit-transition: all 0.5s;
            -ms-transition: all 0.5s;
            transition: all 0.5s;
            left: 100%; }
            [direction="rtl"][data-layout="horizontal"] .deznav .metismenu > li:last-child > ul.left ul {
              left: auto;
              right: 100%; }
          [direction="rtl"][data-layout="horizontal"] .deznav .metismenu > li:nth-last-child(-n + 3) > ul {
            left: 0;
            right: auto; }
            [direction="rtl"][data-layout="horizontal"] .deznav .metismenu > li:nth-last-child(-n + 3) > ul > li:hover ul.collapse {
              right: -100% !important;
              left: auto !important; }
        [data-layout="horizontal"] .deznav .metismenu .menu-title {
          display: none; }
      [data-layout="horizontal"] .deznav .copyright,
      [data-layout="horizontal"] .deznav .plus-box,
      [data-layout="horizontal"] .deznav .help-desk {
        display: none; }
      [data-layout="horizontal"] .deznav .switch-btn {
        display: none; }
    [data-layout="horizontal"] .content-body {
      margin-left: 0; }
      [data-layout="horizontal"] .content-body .container-fluid, [data-layout="horizontal"] .content-body .container-sm, [data-layout="horizontal"] .content-body .container-md, [data-layout="horizontal"] .content-body .container-lg, [data-layout="horizontal"] .content-body .container-xl, [data-layout="horizontal"] .content-body .container-xxl {
        padding-top: 2.5rem; }
      [data-layout="horizontal"] .content-body .page-titles {
        margin-left: 0 !important;
        margin-right: 0 !important;
        margin-bottom: 0;
        margin-top: 1.5rem; }
    [data-layout="horizontal"] .footer {
      margin-left: 0;
      margin: 0 auto;
      padding-left: 0; }
  [data-sidebarbg="color_1"] [data-layout="horizontal"] .deznav .metismenu li > ul {
    background-color: #fff; }
  [data-header-position="fixed"][data-layout="horizontal"] .deznav {
    top: 6.85rem;
    left: 11px; }
  [data-header-position="fixed"][data-layout="horizontal"] .header {
    height: 6.25rem;
    padding-right: 0; }
  [data-header-position="fixed"][data-layout="horizontal"] .content-body {
    padding-top: 5.625rem; }
  [data-sidebar-position="static"][data-layout="horizontal"] .content-body .page-titles {
    margin-top: 0; }
  [data-header-position="fixed"][data-sidebar-position="fixed"] .deznav {
    position: fixed; }
  [data-header-position="fixed"][data-layout="horizontal"][data-sidebar-position="fixed"] .content-body {
    padding-top: 10.325rem;
    /* @include custommq($max: 1400px , $min:1024px) {
				padding-top: 16.025rem;
				.calendar-warpper{
					top: 16.025rem;
				}
			} */ }
  [data-header-position="fixed"][data-layout="horizontal"][data-sidebar-position="fixed"][data-sidebar-style="modern"] .content-body {
    padding-top: 11.325rem; }
  [data-layout="horizontal"][data-container="boxed"] .content-body {
    padding-top: 12rem !important; }
  [data-layout="horizontal"][data-container="boxed"] .footer {
    margin-left: 0;
    max-width: 74.9375rem;
    margin: 0 auto; }
  [data-layout="horizontal"][data-sidebar-style="modern"] .deznav .header-profile {
    margin-bottom: 0; }
    [data-layout="horizontal"][data-sidebar-style="modern"] .deznav .header-profile img {
      height: 3.75rem;
      width: 3.75rem;
      margin-bottom: 0 !important; }
    [data-layout="horizontal"][data-sidebar-style="modern"] .deznav .header-profile > a.nav-link {
      border: 0.0625rem solid #cdcdcd;
      padding: 0.25rem 0.25rem;
      border-radius: 3rem; }
  [data-layout="horizontal"][data-sidebar-style="mini"] .deznav .metismenu > li:hover > .has-arrow .menu-icon {
    border-radius: 50%; }
  [data-layout="horizontal"][data-sidebar-style="compact"] .page-titles {
    margin-top: 1rem; }
  [data-layout="horizontal"][data-sidebar-style="compact"] .deznav .header-profile {
    margin-bottom: 0; }
    [data-layout="horizontal"][data-sidebar-style="compact"] .deznav .header-profile img {
      height: 3.75rem;
      width: 3.75rem;
      margin-bottom: 0 !important; }
    [data-layout="horizontal"][data-sidebar-style="compact"] .deznav .header-profile > a.nav-link {
      border: 0.0625rem solid #cdcdcd; }
  [data-layout="horizontal"][data-sidebar-style="compact"] .deznav .metismenu > li > a {
    padding: 1.125rem 1.25rem 0.625rem 1.25rem; }
    [data-layout="horizontal"][data-sidebar-style="compact"] .deznav .metismenu > li > a .nav-text {
      margin-top: 0.3125rem; }
    [data-layout="horizontal"][data-sidebar-style="compact"] .deznav .metismenu > li > a > i {
      width: auto;
      margin: 0;
      height: auto;
      line-height: 1;
      padding: 0;
      background: transparent;
      border-radius: 0;
      margin-bottom: 0; }
  [data-layout="horizontal"][data-sidebar-style="compact"] .deznav .metismenu > li li {
    text-align: left; }
  [data-layout="horizontal"][data-sidebar-style="compact"] .deznav .metismenu > li.mm-active a {
    background: transparent !important; }
  [data-sidebar-style="mini"][data-layout="horizontal"] .nav-header {
    width: 7.75rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem; }
    [data-sidebar-style="mini"][data-layout="horizontal"] .nav-header .brand-logo {
      justify-content: start;
      padding-left: 0;
      padding-right: 0; }
  [data-sidebar-style="mini"][data-layout="horizontal"] .header {
    width: 100%;
    padding-left: 7.75rem; }
  [data-sidebar-style="mini"][data-layout="horizontal"] .metismenu > li a {
    width: auto; }
  [data-sidebar-style="mini"][data-layout="horizontal"] .metismenu > li:hover a .nav-text {
    display: none; }
  [data-header-position="fixed"][data-layout="horizontal"][data-sidebar-position="fixed"][data-sidebar-style="compact"] .content-body {
    padding-top: 13.125rem; }
    [data-header-position="fixed"][data-layout="horizontal"][data-sidebar-position="fixed"][data-sidebar-style="compact"] .content-body .calendar-warpper {
      top: 13.75rem; }
  [data-sidebar-position="fixed"][data-layout="horizontal"] .deznav.fixed {
    position: fixed;
    left: 0;
    top: 0;
    border-radius: 0;
    width: 100%; }
  [data-sidebar-position="fixed"][data-header-position="fixed"][data-layout="horizontal"] .deznav.fixed {
    position: fixed;
    left: 0;
    top: 80px;
    border-radius: 0;
    width: 100%; } }

[data-header-position="static"][data-sidebar-position="static"][data-layout="horizontal"] .deznav {
  top: 10px;
  left: 11px;
  width: 98.8%; }

[data-header-position="static"][data-sidebar-position="static"][data-layout="horizontal"] .outer-body {
  top: 0;
  padding-top: 9px;
  position: absolute;
  height: auto; }
  [data-header-position="static"][data-sidebar-position="static"][data-layout="horizontal"] .outer-body .content-body {
    padding-top: 164px; }

[data-header-position="static"][data-sidebar-position="static"][data-layout="horizontal"] .header {
  padding-right: 0; }
  [data-header-position="static"][data-sidebar-position="static"][data-layout="horizontal"] .header .header-content {
    border: 0; }

[data-header-position="static"][data-sidebar-position="static"][data-layout="horizontal"] .nav-header {
  border: 0;
  left: 11px; }

[data-header-position="static"][data-sidebar-position="static"][data-layout="horizontal"] .calendar-warpper {
  top: 11px;
  height: 87.6%; }

[data-header-position="static"][data-sidebar-position="fixed"][data-layout="horizontal"] .deznav {
  top: 10px; }

[data-header-position="static"][data-sidebar-position="fixed"][data-layout="horizontal"] .header {
  padding-right: 0; }

[data-header-position="static"][data-sidebar-position="fixed"][data-layout="horizontal"] .outer-body {
  padding-top: 10px; }
  [data-header-position="static"][data-sidebar-position="fixed"][data-layout="horizontal"] .outer-body .content-body {
    padding-top: 167px; }

[data-header-position="static"][data-sidebar-position="fixed"][data-layout="horizontal"] .calendar-warpper {
  top: 168px; }

[data-header-position="fixed"][data-sidebar-position="static"][data-layout="horizontal"] .header {
  padding-right: 0; }

[data-header-position="fixed"][data-sidebar-position="static"][data-layout="horizontal"] .calendar-warpper {
  top: 11rem; }

[data-header-position="fixed"][data-sidebar-position="static"][data-layout="horizontal"] .content-body {
  padding-top: 10.325rem; }

[data-header-position="static"][data-sidebar-position="static"][data-layout="horizontal"][data-sidebar-style="icon-hover"] .calendar-warpper {
  top: 175px; }

[data-header-position="static"][data-sidebar-position="static"][data-layout="horizontal"][data-sidebar-style="full"] .calendar-warpper {
  top: 177px; }

@media (min-width: 47.9375rem) {
  [data-sidebar-style="compact"] .nav-header {
    width: 11.25rem; }
  [data-sidebar-style="compact"] .switch-btn a svg {
    margin-right: 7px; }
  [data-sidebar-style="compact"] .deznav .metismenu ul li:before {
    display: none; }
  [data-sidebar-style="compact"] .deznav .metismenu .has-arrow[aria-expanded=true]:after, [data-sidebar-style="compact"] .deznav .metismenu .mm-active > .has-arrow:after {
    margin-right: 0; }
  [data-sidebar-style="compact"] .deznav .header-profile {
    margin-bottom: 0.3125rem; }
    [data-sidebar-style="compact"] .deznav .header-profile > a.nav-link {
      display: block;
      text-align: center;
      border: 0; }
      [data-sidebar-style="compact"] .deznav .header-profile > a.nav-link img {
        margin-bottom: 0.3125rem; }
      [data-sidebar-style="compact"] .deznav .header-profile > a.nav-link .header-info {
        margin-left: 0 !important;
        text-align: center;
        display: none; }
        [data-sidebar-style="compact"] .deznav .header-profile > a.nav-link .header-info small, [data-sidebar-style="compact"] .deznav .header-profile > a.nav-link .header-info .small {
          text-align: center !important; }
    [data-sidebar-style="compact"] .deznav .header-profile .dropdown-menu {
      min-width: 11rem; }
    [data-sidebar-style="compact"] .deznav .header-profile a svg {
      display: unset !important; }
  [data-sidebar-style="compact"] .deznav .nav-user {
    display: none; }
  [data-sidebar-style="compact"] .deznav .metismenu .menu-title {
    display: none; }
  [data-sidebar-style="compact"] .deznav .metismenu li {
    text-align: center; }
    [data-sidebar-style="compact"] .deznav .metismenu li a {
      padding: 0.7rem 0.5rem; }
      [data-sidebar-style="compact"] .deznav .metismenu li a svg {
        margin-right: 0.0125rem;
        margin-top: -1.3875rem; }
      [data-sidebar-style="compact"] .deznav .metismenu li a .menu-icon {
        display: block;
        padding: 0;
        background-color: rgba(33, 44, 69, 0.05);
        color: rgba(0, 0, 0, 0.3);
        width: 3.125rem;
        height: 3.125rem;
        border-radius: 50%;
        line-height: 4.45rem;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0.3125rem; }
      [data-sidebar-style="compact"] .deznav .metismenu li a i {
        -webkit-transition: all 0.5s;
        -ms-transition: all 0.5s;
        transition: all 0.5s; }
      [data-sidebar-style="compact"] .deznav .metismenu li a .badge {
        display: none; }
    [data-sidebar-style="compact"] .deznav .metismenu li ul:after {
      content: none; }
    [data-sidebar-style="compact"] .deznav .metismenu li > a {
      box-shadow: none;
      font-size: 0.813rem; }
    [data-sidebar-style="compact"] .deznav .metismenu li.mm-active .menu-icon, [data-sidebar-style="compact"] .deznav .metismenu li:hover .menu-icon {
      background-color: var(--primary-light) !important; }
      [data-sidebar-style="compact"] .deznav .metismenu li.mm-active .menu-icon svg path, [data-sidebar-style="compact"] .deznav .metismenu li:hover .menu-icon svg path {
        stroke: black !important; }
  [data-sidebar-style="compact"] .deznav .plus-box, [data-sidebar-style="compact"] .deznav .copyright {
    display: none; }
  [data-sidebar-style="compact"] .deznav .copyright {
    padding: 0 1.25rem;
    margin-top: 1.25rem; }
  [data-sidebar-style="compact"] .deznav .header-info2 {
    padding: 0; }
    [data-sidebar-style="compact"] .deznav .header-info2 img {
      margin-left: -1rem; }
  [data-sidebar-style="compact"] .deznav .sidebar-info {
    display: none !important; }
  [data-sidebar-style="compact"] .deznav .header-profile2 {
    margin: 0 3rem; }
  [data-sidebar-style="compact"] .deznav .feature-box,
  [data-sidebar-style="compact"] .deznav .support-box,
  [data-sidebar-style="compact"] .deznav .help-desk {
    display: none; }
  [data-sidebar-style="compact"] .nav-text {
    display: inline-block;
    margin-top: 0.3125rem;
    font-size: 0.813rem; }
  [data-sidebar-style="compact"] .nav-label.first {
    display: none; }
  [data-sidebar-style="compact"] .nav-badge {
    display: none; }
  [data-sidebar-style="compact"] .footer {
    padding-left: 12.5rem; }
  [data-sidebar-style="compact"] .content-body {
    margin-left: 0rem; }
  [data-sidebar-style="compact"] .outer-body {
    padding-left: 11.3rem; }
  [data-sidebar-style="compact"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu li > a i {
    color: #fff; }
  [data-sidebar-style="compact"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu li.mm-active > a i {
    background: var(--primary);
    color: #fff; }
  [data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu li:hover > a i {
    background: var(--rgba-primary-1);
    color: var(--primary); } }

[data-layout="horizontal"][data-sidebar-style="compact"] .footer {
  padding-left: 0; }

[data-layout="horizontal"][data-sidebar-style="compact"] .content-body {
  margin-left: 0; }

[data-layout="horizontal"][data-sidebar-style="compact"] .deznav {
  margin-bottom: 0; }

[data-layout="horizontal"][data-sidebar-style="compact"] .header {
  padding-left: 18.563rem; }

[data-layout="horizontal"][data-sidebar-style="compact"] .nav-header {
  width: 18.563rem; }
  [data-layout="horizontal"][data-sidebar-style="compact"] .nav-header .brand-logo {
    padding-left: 2.5rem;
    padding-right: 2.5rem; }

[data-layout="vertical"][data-sidebar-style="compact"] .deznav {
  width: 11.25rem;
  padding-top: 1rem; }
  [data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu ul {
    margin-left: 0;
    border: 0;
    background: rgba(0, 0, 0, 0.02);
    padding: 0.625rem 0; }
    [data-theme-version="dark"][data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu ul {
      background: 255, 255, 255, 0.05; }
    [data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu ul a:before {
      content: none; }
    [data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu ul ul a {
      padding: 0.625rem 0.9375rem; }
  [data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu > li > a {
    padding: 0.9rem 0.5rem; }
  [data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu > li > a.has-arrow::after {
    top: 0.0625rem;
    display: inline-block;
    right: auto;
    margin-left: 0.3125rem;
    position: relative;
    width: 0.4375rem;
    height: 0.4375rem;
    border-width: 0.125rem 0rem 0rem 0.125rem; }
    [direction="rtl"][data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu > li > a.has-arrow::after {
      left: auto;
      margin-left: 0;
      margin-right: 0.3125rem; }
  [data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu > li.mm-active > a {
    background: transparent;
    color: var(--primary); }

[data-sidebar-style="compact"][data-sidebar-position="fixed"][data-header-position="static"] .outer-body {
  padding-top: 0; }
  [data-sidebar-style="compact"][data-sidebar-position="fixed"][data-header-position="static"] .outer-body .inner-body {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border: 0; }

[data-sidebar-style="compact"][data-sidebar-position="fixed"][data-header-position="static"] .calendar-warpper {
  top: 0; }

[data-sidebar-style="compact"][data-sidebar-position="static"][data-header-position="static"][data-layout="horizontal"] .outer-body {
  padding-top: 45px; }

[data-sidebar-style="compact"][data-sidebar-position="static"][data-header-position="static"][data-layout="horizontal"] .calendar-warpper {
  top: 222px;
  height: 88.4%; }

[data-sidebar-style="compact"][data-sidebar-position="fixed"][data-header-position="static"][data-layout="horizontal"] .outer-body {
  padding-top: 48px; }

[data-sidebar-style="compact"][data-sidebar-position="fixed"][data-header-position="static"][data-layout="horizontal"] .calendar-warpper {
  top: 221px;
  height: calc(100vh - 13.2rem); }

[data-sidebar-style="compact"][data-sidebar-position="static"][data-header-position="fixed"][data-layout="horizontal"] .content-body {
  padding-top: 13.125rem; }

[data-sidebar-style="compact"][data-sidebar-position="static"][data-header-position="fixed"][data-layout="horizontal"] .calendar-warpper {
  top: 13.8rem; }

[data-sidebar-style="icon-hover"][data-sidebar-position="static"][data-header-position="fixed"][data-layout="horizontal"] .content-body {
  padding-top: 10.325rem; }

[data-sidebar-style="icon-hover"][data-sidebar-position="static"][data-header-position="fixed"][data-layout="horizontal"] .calendar-warpper {
  top: 10.9rem; }

@media (min-width: 47.9375rem) {
  [data-sidebar-style="icon-hover"][data-layout="horizontal"] .header .header-content {
    padding-left: 1.875rem; }
  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu li.mm-active > ul {
    display: none; }
  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .header-info2 {
    justify-content: space-between; }
    [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .header-info2 img {
      order: 1;
      margin-right: 0; }
  [data-sidebar-style="icon-hover"][data-layout="vertical"] .iconhover-toggle .deznav .metismenu li.mm-active > ul {
    display: block; }
  [data-sidebar-style="icon-hover"][data-layout="vertical"] .nav-header {
    width: 7rem; }
    [data-sidebar-style="icon-hover"][data-layout="vertical"] .nav-header .brand-logo {
      padding-left: 0.875rem;
      padding-right: 0.875rem;
      justify-content: center; }
      [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .nav-header .brand-logo {
        padding-left: 1.875rem;
        padding-right: 1.875rem; }
      [data-sidebar-style="icon-hover"][data-layout="vertical"] .nav-header .brand-logo .logo-abbr {
        display: block; }
      [data-sidebar-style="icon-hover"][data-layout="vertical"] .nav-header .brand-logo .brand-title {
        display: none; }
    [data-sidebar-style="icon-hover"][data-layout="vertical"] .nav-header .nav-control {
      display: none; }
  [data-sidebar-style="icon-hover"][data-layout="vertical"] .header {
    padding-left: 7rem; }
    [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .header {
      padding-right: 7rem;
      padding-left: 0.9375rem; }
    [data-sidebar-style="icon-hover"][data-layout="vertical"] .header .header-content {
      padding-left: 2.4rem; }
      [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .header .header-content {
        padding-right: 1.375rem;
        padding-left: 0; }
  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav {
    overflow: visible;
    position: absolute;
    left: -11.76rem;
    z-index: 4;
    margin-top: -10px; } }
  @media only screen and (min-width: 47.9375rem) and (min-width: 47.9375rem) and (max-width: 87.5rem) {
    [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav {
      left: -11.7rem; } }

@media (min-width: 47.9375rem) {
    [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav {
      left: auto;
      right: -14.463rem; }
    [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .nav-label {
      display: none; }
    [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .header-profile img {
      order: 1; }
    [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .header-profile .header-info {
      margin-left: 0 !important;
      padding-left: 0 !important;
      margin-right: 0.625rem; }
    [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu > li > a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 0.625rem; }
      [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu > li > a > svg,
      [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu > li > a > i,
      [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu > li > a .menu-icon {
        order: 1;
        margin-right: 0;
        margin-top: 0;
        padding-right: 0;
        line-height: 1; }
        [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu > li > a > svg, [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu > li > a > i, [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu > li > a .menu-icon {
          padding-left: 0;
          padding-right: 0; }
    [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu > li:hover > a,
    [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a {
      color: black; }
      [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu > li:hover > a i,
      [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a i {
        color: #fff; }
    [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul {
      border-left: 0;
      padding-left: 0;
      padding-right: 0; }
      [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul {
        padding-right: 0;
        padding-left: 0; }
      [data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul {
        border-color: #444444; }
      [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul li:before {
        left: 1.875rem; }
      [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul:after {
        left: auto;
        right: 1.75rem; }
        [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul:after {
          left: 1.75rem;
          right: auto; }
      [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul a {
        position: relative;
        padding-left: 3rem; }
        [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul a {
          padding-right: 1.25rem; }
        [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul a::before {
          left: 1.25rem;
          right: auto; }
          [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul a::before {
            right: auto;
            left: -0.3125rem; }
    [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu .has-arrow::after {
      right: 6rem;
      opacity: 0; }
      [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu .has-arrow::after {
        right: auto;
        left: 5rem; }
    [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu .menu-title {
      display: none; }
    [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .copyright,
    [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .plus-box {
      display: none; }
    [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav.mm-show {
      display: none; }
  [data-sidebar-style="icon-hover"][data-layout="vertical"] .outer-body {
    padding-left: 6.99rem; }
  [data-sidebar-style="icon-hover"][data-layout="vertical"] .iconhover-toggle .nav-header {
    width: 18.75rem; }
    [data-sidebar-style="icon-hover"][data-layout="vertical"] .iconhover-toggle .nav-header .brand-logo {
      padding-left: 1.6rem;
      justify-content: start; }
      [data-sidebar-style="icon-hover"][data-layout="vertical"] .iconhover-toggle .nav-header .brand-logo .brand-title {
        display: block; }
      [data-sidebar-style="icon-hover"][data-layout="vertical"] .iconhover-toggle .nav-header .brand-logo .color-title {
        display: none; } }
  @media only screen and (min-width: 47.9375rem) and (max-width: 87.5rem) {
    [data-sidebar-style="icon-hover"][data-layout="vertical"] .iconhover-toggle .nav-header {
      width: 18.8rem; } }

@media (min-width: 47.9375rem) {
  [data-sidebar-style="icon-hover"][data-layout="vertical"] .iconhover-toggle.mm-show {
    display: block; }
  [data-sidebar-style="icon-hover"][data-layout="vertical"] .iconhover-toggle .deznav {
    left: 0; }
    [data-sidebar-style="icon-hover"][data-layout="vertical"] .iconhover-toggle .deznav .metismenu .has-arrow:after {
      opacity: 1; }
    [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .iconhover-toggle .deznav {
      left: auto;
      right: 0; }
    [data-sidebar-style="icon-hover"][data-layout="vertical"] .iconhover-toggle .deznav .menu-title {
      display: block; }
  [data-sidebar-style="icon-hover"][data-layout="vertical"] .content-body {
    margin-left: 0rem; }
    [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .content-body {
      margin-left: 0;
      margin-right: 7rem; }
  [data-sidebar-style="icon-hover"][data-layout="vertical"] .footer {
    padding-left: 7rem; }
    [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .footer {
      margin-left: 0;
      margin-right: 7rem; } }

[data-sidebar-style="icon-hover"][data-layout="vertical"][data-sidebar-position="fixed"][data-header-position="static"] .outer-body {
  padding-top: 0; }
  [data-sidebar-style="icon-hover"][data-layout="vertical"][data-sidebar-position="fixed"][data-header-position="static"] .outer-body .inner-body {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-top: 0; }

[data-sidebar-style="icon-hover"][data-layout="vertical"][data-sidebar-position="fixed"][data-header-position="static"] .calendar-warpper {
  top: 0; }

[data-sidebar-style="icon-hover"][data-layout="vertical"][data-sidebar-position="fixed"][data-header-position="static"] .deznav {
  height: calc(100% - 5.25rem); }

[data-sidebar-style="icon-hover"][data-layout="vertical"][data-sidebar-position="static"][data-header-position="static"] .outer-body {
  position: absolute;
  height: auto; }

[data-sidebar-style="icon-hover"][data-layout="vertical"][data-sidebar-position="static"][data-header-position="static"] .calendar-warpper {
  height: 100%; }

/* [data-sidebar-style="icon-hover"][data-layout="horizontal"][data-sidebar-position="static"][data-header-position="static"]{
	.calendar-warppe{
		top: 176px;
	}
} */
[data-sidebar-style="full"][data-layout="horizontal"][data-sidebarbg="color_2"][data-primary="color_13"] .deznav .metismenu > li:hover > a .menu-icon svg path {
  stroke: white !important; }

[data-sidebar-style="mini"][data-layout="horizontal"][data-sidebarbg="color_2"][data-primary="color_13"] .deznav .metismenu > li:hover > a .menu-icon svg path {
  stroke: white !important; }

[data-sidebar-style="modern"][data-layout="horizontal"][data-sidebarbg="color_2"][data-primary="color_13"] .deznav .metismenu > li:hover > a .menu-icon svg path {
  stroke: white !important; }

[data-sidebar-style="icon-hover"][data-layout="horizontal"][data-sidebarbg="color_2"][data-primary="color_13"] .deznav .metismenu > li:hover > a .menu-icon svg path {
  stroke: white !important; }

@media (min-width: 47.9375rem) {
  [data-sidebar-style="modern"] .deznav .metismenu > li > a .menu-icon {
    margin-right: 0;
    margin-bottom: 0px;
    background: transparent; }
  [data-sidebar-style="modern"] .deznav .metismenu ul li:before {
    display: none; }
  [data-sidebar-style="modern"] .nav-header {
    width: 10.625rem; }
    [data-sidebar-style="modern"] .nav-header .brand-logo {
      justify-content: center; }
  [data-sidebar-style="modern"] .deznav .header-profile {
    margin-bottom: 0.3125rem; }
    [data-sidebar-style="modern"] .deznav .header-profile > a.nav-link {
      display: block;
      text-align: center;
      margin: 0 -0.625rem 0.9375rem;
      padding: 0.9375rem 0.625rem;
      border-radius: 0.625rem; }
      [data-sidebar-style="modern"] .deznav .header-profile > a.nav-link img {
        margin-bottom: 0.3125rem; }
      [data-sidebar-style="modern"] .deznav .header-profile > a.nav-link .header-info {
        margin-left: 0 !important;
        text-align: center;
        display: none; }
        [data-sidebar-style="modern"] .deznav .header-profile > a.nav-link .header-info small, [data-sidebar-style="modern"] .deznav .header-profile > a.nav-link .header-info .small {
          text-align: center !important; }
  [data-sidebar-style="modern"] .deznav .metismenu > li {
    text-align: center; }
    [data-sidebar-style="modern"] .deznav .metismenu > li > a {
      padding: 1.25rem 0.9375rem 1.25rem 0.9375rem;
      margin: 0.125rem 0;
      -webkit-transition: all 0.5s;
      -ms-transition: all 0.5s;
      transition: all 0.5s; }
      [data-sidebar-style="modern"] .deznav .metismenu > li > a::after {
        display: none; }
      [data-sidebar-style="modern"] .deznav .metismenu > li > a:hover > a, [data-sidebar-style="modern"] .deznav .metismenu > li > a:focus > a, [data-sidebar-style="modern"] .deznav .metismenu > li > a:active > a, [data-sidebar-style="modern"] .deznav .metismenu > li > a.mm-active > a {
        background-color: var(--primary-dark); }
    [data-sidebar-style="modern"] .deznav .metismenu > li:hover, [data-sidebar-style="modern"] .deznav .metismenu > li.mm-active {
      padding: 0; }
      [data-sidebar-style="modern"] .deznav .metismenu > li:hover > a, [data-sidebar-style="modern"] .deznav .metismenu > li.mm-active > a {
        color: black;
        box-shadow: none; }
        [data-sidebar-style="modern"] .deznav .metismenu > li:hover > a i, [data-sidebar-style="modern"] .deznav .metismenu > li.mm-active > a i {
          color: #fff; }
      [data-sidebar-style="modern"] .deznav .metismenu > li:hover ul li a, [data-sidebar-style="modern"] .deznav .metismenu > li.mm-active ul li a {
        padding: 0.5rem 1rem; }
    [data-sidebar-style="modern"] .deznav .metismenu > li li {
      text-align: left; }
      [direction="rtl"][data-sidebar-style="modern"] .deznav .metismenu > li li {
        text-align: right; }
  [data-sidebar-style="modern"] .deznav .metismenu li a {
    padding: 0.625rem 0.9375rem 0.625rem 0.9375rem;
    font-size: 1rem; }
    [data-sidebar-style="modern"] .deznav .metismenu li a .badge {
      display: none; }
  [data-sidebar-style="modern"] .deznav .metismenu li ul:after {
    content: none; }
  [data-sidebar-style="modern"] .deznav .metismenu li > ul {
    height: auto !important; }
  [data-sidebar-style="modern"] .deznav .metismenu .nav-label {
    display: none; }
  [data-sidebar-style="modern"] .deznav .nav-label {
    display: none; }
  [data-sidebar-style="modern"] .deznav .nav-text {
    display: block;
    margin-top: 0.3125rem; }
  [data-sidebar-style="modern"] .deznav .plus-box,
  [data-sidebar-style="modern"] .deznav .copyright,
  [data-sidebar-style="modern"] .deznav .help-desk {
    display: none; }
  [data-sidebar-style="modern"] .deznav .sidebar-info {
    display: none !important; }
  [data-sidebar-style="modern"] .deznav .menu-title {
    display: none; }
  [data-sidebar-style="modern"] .switch-btn {
    padding: 40px 74px; }
    [data-sidebar-style="modern"] .switch-btn span {
      display: none; }
  [data-sidebar-style="modern"] .footer {
    padding-left: 11.9rem; }
  [data-sidebar-style="modern"] .content-body {
    margin-left: 0rem; }
  [data-sidebar-style="modern"] .outer-body {
    padding-left: 10.65rem; } }
  @media only screen and (min-width: 47.9375rem) and (max-width: 87.5rem) {
    [data-sidebar-style="modern"] .outer-body {
      padding-left: 10.55rem; } }

@media (min-width: 47.9375rem) {
  [data-sidebar-style="modern"][data-layout="horizontal"] .deznav .metismenu li > a i {
    padding: 0;
    margin: 0; }
  [data-sidebar-style="modern"][data-layout="vertical"] .deznav {
    width: 10.625rem;
    left: 0;
    z-index: 3;
    position: absolute !important; }
    [direction="rtl"][data-sidebar-style="modern"][data-layout="vertical"] .deznav {
      left: auto;
      right: 0; }
    [data-sidebar-style="modern"][data-layout="vertical"] .deznav .slimScrollDiv,
    [data-sidebar-style="modern"][data-layout="vertical"] .deznav .deznav-scroll {
      overflow: visible !important; }
    [data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu {
      padding: 0.625rem 0; }
      [data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li {
        position: relative; }
        [data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li > a {
          padding: 1.375rem 0.6em 0.9375rem; }
          [data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li > a i {
            padding: 0;
            height: auto;
            width: auto;
            margin: 0;
            line-height: 1;
            margin-bottom: 0.3125rem; }
        [data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li > ul {
          display: none;
          padding: 1.875rem 0.9375rem; }
        [data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:before {
          content: "";
          position: absolute;
          bottom: 0;
          right: 0;
          background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #1A2439 53.12%, rgba(255, 255, 255, 0) 100%);
          width: 100%;
          height: 1px;
          opacity: 0.3; }
        [data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:last-child:before {
          display: none; }
      [data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu li {
        position: relative; }
        [data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu li a {
          padding: 1rem 1rem; }
          [data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu li a:before {
            content: none; }
          [data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu li a .badge {
            display: none; }
        [data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu li ul {
          position: absolute;
          left: 105%;
          top: 0;
          bottom: auto;
          background-color: white;
          border: 0.0625rem solid #E6E6E6;
          width: 12.5rem;
          box-shadow: 1rem 0.75rem 1.25rem 0 rgba(82, 63, 105, 0.1); }
          [data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu li ul {
            background: #2c2c2c;
            box-shadow: 0rem 0rem 0.8125rem 0rem rgba(0, 0, 0, 0.1); }
          [direction="rtl"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu li ul {
            left: auto;
            right: 105%; }
          [data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu li ul li ul {
            border-radius: 0.625rem !important; }
        [data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu li:hover > ul {
          display: block;
          left: 100%;
          padding: 1rem 0;
          margin-left: 0;
          border: 0;
          box-shadow: 1rem 0.75rem 1.25rem 0 rgba(82, 63, 105, 0.1);
          border-radius: 0 2rem 2rem 0; }
          [data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu li:hover > ul {
            box-shadow: 0.3125rem 0.3125rem 1.875rem 0rem rgba(20, 0, 30, 0.1); }
          [direction="rtl"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu li:hover > ul {
            left: auto;
            right: 100%;
            box-shadow: -0.3125rem 0.3125rem 1.875rem 0rem rgba(20, 0, 30, 0.1); }
    [data-sidebar-style="modern"][data-layout="vertical"] .deznav .nav-label {
      display: none; }
    [data-sidebar-style="modern"][data-layout="vertical"] .deznav .nav-text {
      display: block;
      margin-top: 0; }
    [data-sidebar-style="modern"][data-layout="vertical"] .deznav .header-profile2 {
      padding: 0 2.5rem; }
  [data-sidebar-style="modern"][data-layout="vertical"] .nav-control {
    right: -5.35rem; }
  [data-sidebar-style="modern"][data-layout="vertical"] .header .header-content {
    padding-left: 6rem; }
  [data-sidebar-style="modern"][data-layout="vertical"] .menu-toggle .deznav, [data-sidebar-style="modern"][data-layout="vertical"] .menu-toggle .nav-header {
    left: -10.625rem; }
  [data-sidebar-style="modern"][data-layout="vertical"] .menu-toggle .header {
    padding-left: 0; }
  [data-sidebar-style="modern"][data-layout="vertical"] .menu-toggle .outer-body {
    padding-left: 1px; }
  [data-sidebar-style="modern"][data-layout="horizontal"] .nav-header {
    width: 18.563rem; }
  [data-sidebar-style="modern"][data-layout="horizontal"] .header {
    padding-left: 18.563rem; }
  [data-sidebar-style="modern"][data-layout="horizontal"] .footer,
  [data-sidebar-style="modern"][data-layout="horizontal"] .content-body {
    margin-left: 0; }
  [data-sidebar-style="modern"][data-layout="horizontal"] .calendar-warpper {
    top: 11.95rem;
    height: calc(100vh - 11.4rem); }
  [data-sidebar-style="modern"][data-layout="horizontal"] .deznav .metismenu > li {
    position: relative; }
    [data-sidebar-style="modern"][data-layout="horizontal"] .deznav .metismenu > li > a {
      padding: 0.7375rem 1.875rem;
      margin: 0 0.125rem; }
      [data-sidebar-style="modern"][data-layout="horizontal"] .deznav .metismenu > li > a .menu-icon {
        margin-right: 0;
        background-color: transparent; }
    [data-sidebar-style="modern"][data-layout="horizontal"] .deznav .metismenu > li:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #1A2439 53.12%, rgba(255, 255, 255, 0) 100%);
      width: 1px;
      height: 100%;
      opacity: 0.3; }
  [data-sidebar-style="modern"][data-layout="horizontal"][data-container="boxed"] .deznav .metismenu > li > a {
    padding: 0.8125rem 1.25rem; } }

[data-nav-headerbg="color_1"][data-headerbg="color_1"][data-sidebar-style="modern"][data-layout="horizontal"] .nav-header .color-title {
  display: block !important; }

[data-sidebar-style="modern"][data-layout="horizontal"][data-sidebar-position="static"][data-header-position="static"] .outer-body {
  padding-top: 10px; }
  [data-sidebar-style="modern"][data-layout="horizontal"][data-sidebar-position="static"][data-header-position="static"] .outer-body .content-body {
    padding-top: 182px; }

[data-sidebar-style="modern"][data-layout="horizontal"][data-sidebar-position="static"][data-header-position="static"] .calendar-warpper {
  top: 192px; }

[data-sidebar-style="modern"][data-layout="horizontal"][data-sidebar-position="static"][data-header-position="fixed"] .outer-body {
  padding-top: 10px;
  top: 0; }
  [data-sidebar-style="modern"][data-layout="horizontal"][data-sidebar-position="static"][data-header-position="fixed"] .outer-body .content-body {
    padding-top: 180px; }

[data-sidebar-style="modern"][data-layout="horizontal"][data-sidebar-position="static"][data-header-position="fixed"] .calendar-warpper {
  top: 11.9rem; }

[data-sidebar-style="modern"][data-header-position="static"][data-sidebar-position="fixed"][data-layout="horizontal"] .outer-body {
  top: 0;
  padding-top: 10px; }
  [data-sidebar-style="modern"][data-header-position="static"][data-sidebar-position="fixed"][data-layout="horizontal"] .outer-body .content-body {
    padding-top: 11.2rem; }

[data-sidebar-style="modern"][data-header-position="static"][data-sidebar-position="fixed"][data-layout="horizontal"] .calendar-warpper {
  top: 11.2rem; }

[data-sidebar-style="overlay"][data-layout="vertical"] .deznav {
  border-radius: 0 0 0 0 !important; }
  @media only screen and (max-width: 47.9375rem) {
    [data-sidebar-style="overlay"][data-layout="vertical"] .deznav {
      border-radius: 0 0 0 0 !important; } }

[data-sidebar-style="overlay"][data-layout="vertical"] .nav-header {
  border-radius: 0; }

[data-sidebar-style="overlay"] .deznav {
  left: -100%;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s; }
  [data-sidebar-style="overlay"] .deznav .metismenu > li > a {
    font-size: 1rem;
    padding: 0.75rem 1.5rem;
    color: #7f7184;
    border-radius: 0.625rem;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s; }
    [data-sidebar-style="overlay"] .deznav .metismenu > li > a i {
      height: auto;
      width: auto;
      line-height: 1; }
  [data-sidebar-style="overlay"] .deznav .metismenu > li:hover > a {
    color: var(--primary); }
    [data-sidebar-style="overlay"] .deznav .metismenu > li:hover > a i {
      color: var(--primary); }
  [data-sidebar-style="overlay"] .deznav .metismenu > li.mm-active > a {
    /* background: var(--rgba-primary-1); */
    color: var(--primary); }
    [data-sidebar-style="overlay"] .deznav .metismenu > li.mm-active > a i {
      color: #fff; }
  @media only screen and (max-width: 35.9375rem) {
    [data-sidebar-style="overlay"] .deznav .metismenu > li {
      padding: 0rem 0.9375rem; } }
  [data-sidebar-style="overlay"] .deznav .metismenu ul a {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    position: relative;
    font-size: 15px;
    padding-left: 5.7rem; }
    [data-sidebar-style="overlay"] .deznav .metismenu ul a:before {
      left: 1.5625rem;
      content: "";
      height: 0.125rem;
      width: 0.375rem;
      background: var(--rgba-primary-5);
      position: absolute;
      left: 4.5rem;
      top: 50%;
      transform: translateY(-50%);
      -webkit-transition: all 0.5s;
      -ms-transition: all 0.5s;
      transition: all 0.5s; }
  [direction="rtl"][data-sidebar-style="overlay"] .deznav {
    left: auto;
    right: -100%; }
  @media only screen and (max-width: 47.9375rem) {
    [data-sidebar-style="overlay"] .deznav .metismenu > li > a {
      font-size: 1rem;
      padding: 0.75rem 0.875rem; }
      [data-sidebar-style="overlay"] .deznav .metismenu > li > a i {
        font-size: 1.125rem; }
    [data-sidebar-style="overlay"] .deznav .metismenu ul li a {
      padding-left: 5.9rem;
      font-size: 14px; } }

[data-sidebar-style="overlay"] .outer-body {
  padding-left: 10px; }

[data-sidebar-style="overlay"] .nav-header {
  position: absolute; }
  [data-sidebar-style="overlay"] .nav-header .hamburger.is-active {
    left: 0; }
    [data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line {
      background-color: #fff; }

[data-sidebar-style="overlay"] .menu-toggle .nav-header {
  position: absolute;
  left: auto; }

[data-sidebar-style="overlay"] .menu-toggle .deznav {
  left: 1px;
  z-index: 3;
  margin-top: -10px; }
  [direction="rtl"][data-sidebar-style="overlay"] .menu-toggle .deznav {
    left: auto;
    right: 0; }
  @media only screen and (max-width: 47.9375rem) {
    [data-sidebar-style="overlay"] .menu-toggle .deznav {
      margin-top: 0; } }

[data-sidebar-style="overlay"] .footer {
  padding-left: 0; }

[data-sidebar-style="overlay"][data-header-position="fixed"] .nav-header {
  position: fixed; }

[data-sidebar-position="fixed"][data-header-position="fixed"] .nav-header {
  position: fixed; }

[data-sidebar-style="overlay"][data-nav-headerbg="color_1"] .menu-toggle .nav-header .color-title {
  display: block !important; }

[data-sidebar-style="overlay"] .out-footer {
  width: calc(100% - 9.125rem);
  margin-right: 7.375rem;
  margin-left: auto; }
  @media only screen and (max-width: 35.9375rem) {
    [data-sidebar-style="overlay"] .out-footer {
      width: calc(100% - 2.125rem);
      margin-right: 0.975rem; } }

[data-sidebar-style="overlay"][data-header-position="static"][data-sidebar-position="fixed"] .outer-body {
  top: 89px; }
  [data-sidebar-style="overlay"][data-header-position="static"][data-sidebar-position="fixed"] .outer-body .inner-body {
    border-top-right-radius: 0; }

[data-sidebar-style="overlay"][data-header-position="static"][data-sidebar-position="fixed"] .calendar-warpper {
  top: 0; }

[data-sidebar-style="overlay"][data-header-position="static"][data-sidebar-position="fixed"] .deznav {
  height: calc(100% - 5.25rem); }

[data-sidebar-style="overlay"][data-header-position="static"][data-sidebar-position="static"] .outer-body {
  position: absolute;
  height: auto; }

[data-sidebar-style="overlay"][data-header-position="static"][data-sidebar-position="static"] .calendar-warpper {
  height: 100%; }

[data-sidebar-position="fixed"][data-layout="vertical"] .nav-header {
  position: fixed;
  border-top-left-radius: 0; }

[data-sidebar-position="fixed"][data-layout="vertical"] .deznav {
  position: fixed; }
  [data-sidebar-position="fixed"][data-layout="vertical"] .deznav .deznav-scroll {
    border-top-left-radius: 0rem;
    border-top-right-radius: 0rem; }

[data-sidebar-position="fixed"][data-layout="vertical"] .menu-toggle .deznav {
  position: fixed; }

[data-layout="vertical"] .nav-header {
  border-top-left-radius: 0; }

[data-layout="vertical"] .deznav {
  border-bottom-left-radius: 0; }

[data-sidebar-style="icon-hover"][data-layout="vertical"][data-container="wide-boxed"] .deznav,
[data-sidebar-style="icon-hover"][data-layout="vertical"][data-container="boxed"] .deznav,
[data-header-position="fixed"][data-sidebar-position="fixed"][data-sidebar-style="icon-hover"][data-layout="vertical"][data-container="wide-boxed"] .deznav,
[data-header-position="fixed"][data-sidebar-position="fixed"][data-sidebar-style="icon-hover"][data-layout="vertical"][data-container="boxed"] .deznav,
[data-sidebar-style="overlay"][data-layout="vertical"][data-container="wide-boxed"] .deznav,
[data-sidebar-style="overlay"][data-layout="vertical"][data-container="boxed"] .deznav,
[data-header-position="fixed"][data-sidebar-position="fixed"][data-sidebar-style="overlay"][data-layout="vertical"][data-container="wide-boxed"] .deznav,
[data-header-position="fixed"][data-sidebar-position="fixed"][data-sidebar-style="overlay"][data-layout="vertical"][data-container="boxed"] .deznav {
  position: absolute; }

.sidebar-right-trigger {
  position: absolute;
  z-index: 9;
  top: 7.75rem;
  right: 100%;
  background-color: var(--primary);
  color: #fff;
  display: inline-block;
  height: 3rem;
  width: 3rem;
  text-align: center;
  font-size: 1.75rem;
  line-height: 3rem;
  border-radius: 0.3125rem 0 0 0.3125rem;
  box-shadow: -0.3125rem 0.1875rem 0.3125rem 0rem rgba(119, 119, 119, 0.15); }
  [data-theme-version="dark"] .sidebar-right-trigger {
    color: #fff; }
  @media only screen and (max-width: 64rem) {
    .sidebar-right-trigger {
      display: none; } }
  .sidebar-right-trigger:hover {
    color: #fff; }

.sidebar-right {
  right: -50rem;
  position: fixed;
  top: 0;
  width: 50rem;
  background-color: #fff;
  margin-top: 3.5rem;
  transition: all .5s ease-in-out;
  border-radius: 0.625rem;
  z-index: 9999; }
  .sidebar-right .bg-label-pattern {
    background: transparent;
    background-image: url("../images/pattern/pattern5.png");
    background-size: 130%; }
  .sidebar-right .bootstrap-select {
    height: 3rem;
    border-radius: 0.375rem; }
    .sidebar-right .bootstrap-select .btn {
      padding: 0.75rem 0.9375rem;
      font-size: 0.9375rem;
      border-color: #d1d1d1;
      border-radius: 0.375rem; }
  [direction="rtl"] .sidebar-right .slimScrollDiv {
    overflow: visible !important; }
  .sidebar-right .note-text {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 10px;
    opacity: 0.5;
    font-size: 12px; }
  .sidebar-right .sidebar-close-trigger {
    position: absolute;
    z-index: 2;
    font-size: 1.75rem;
    top: 0;
    right: -3rem;
    height: 3rem;
    width: 3rem;
    line-height: 3rem;
    text-align: center;
    background: var(--primary);
    color: #fff; }
  .sidebar-right.show {
    right: 5.25rem;
    box-shadow: 0rem 0rem 3.125rem rgba(0, 0, 0, 0.2);
    z-index: 9999; }
    @media only screen and (max-width: 47.9375rem) {
      .sidebar-right.show {
        display: none; } }
    .sidebar-right.show .bg-overlay {
      position: fixed;
      width: 100%;
      cursor: pointer;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.2); }
  .sidebar-right .card-tabs .nav-tabs {
    justify-content: space-between;
    position: sticky;
    top: 0;
    width: 100%;
    background-color: #fff;
    z-index: 2;
    border-bottom: 4px solid var(--rgba-primary-1); }
    .sidebar-right .card-tabs .nav-tabs .nav-item {
      margin-bottom: 0;
      flex: 1; }
      .sidebar-right .card-tabs .nav-tabs .nav-item .nav-link {
        border: 0;
        font-size: 1.125rem;
        position: relative;
        text-align: center;
        background-color: #fff;
        border-radius: 0;
        margin: 0;
        color: var(--primary); }
        .sidebar-right .card-tabs .nav-tabs .nav-item .nav-link.active {
          background: var(--rgba-primary-1);
          color: #000; }
          [data-theme-version="dark"] .sidebar-right .card-tabs .nav-tabs .nav-item .nav-link.active {
            border-right: none;
            border-left: none;
            border-top: none; }
  .sidebar-right .sidebar-right-inner > h4, .sidebar-right .sidebar-right-inner > .h4 {
    padding: 0.625rem 1.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000;
    background: #fff;
    margin: 0rem 0rem 0rem 0rem; }
  .sidebar-right .tab-content {
    padding: 1.25rem 1.25rem 0;
    min-height: 480px;
    background: #fff; }
    .sidebar-right .tab-content .tab-pane .admin-settings .row > div {
      margin-bottom: 1.25rem; }
    .sidebar-right .tab-content .tab-pane .admin-settings p {
      color: #353535;
      font-weight: 500;
      margin-bottom: 0.5rem;
      font-size: 1rem; }
    .sidebar-right .tab-content .tab-pane .admin-settings input[type="radio"] {
      display: none; }
      .sidebar-right .tab-content .tab-pane .admin-settings input[type="radio"] + label {
        display: inline-block;
        width: 2.1875rem;
        height: 2.1875rem;
        cursor: pointer;
        transition: all .1s ease;
        border-radius: 0.25rem;
        margin-right: 0.3125rem;
        margin-bottom: 0.1875rem; }
      .sidebar-right .tab-content .tab-pane .admin-settings input[type="radio"]:checked + label {
        position: relative; }
        .sidebar-right .tab-content .tab-pane .admin-settings input[type="radio"]:checked + label::after {
          height: 2.0625rem;
          width: 2.0625rem;
          left: -0.25rem;
          top: -0.25rem;
          content: "";
          position: absolute;
          background-color: inherit;
          border-radius: 0.375rem;
          opacity: 0.4; }
  .sidebar-right #nav_header_color_1 + label,
  .sidebar-right #header_color_1 + label,
  .sidebar-right #sidebar_color_1 + label,
  .sidebar-right #secondary_color_1 + label,
  .sidebar-right #primary_color_1 + label {
    background-color: #fff; }
  .sidebar-right #nav_header_color_2 + label,
  .sidebar-right #header_color_2 + label,
  .sidebar-right #sidebar_color_2 + label,
  .sidebar-right #secondary_color_2 + label,
  .sidebar-right #primary_color_2 + label {
    background-color: #A0CF1A; }
  .sidebar-right #nav_header_color_3 + label,
  .sidebar-right #header_color_3 + label,
  .sidebar-right #sidebar_color_3 + label,
  .sidebar-right #secondary_color_3 + label,
  .sidebar-right #primary_color_3 + label {
    background-color: #FF7C7C; }
  .sidebar-right #nav_header_color_4 + label,
  .sidebar-right #header_color_4 + label,
  .sidebar-right #sidebar_color_4 + label,
  .sidebar-right #secondary_color_4 + label,
  .sidebar-right #primary_color_4 + label {
    background-color: #81A4F9; }
  .sidebar-right #nav_header_color_5 + label,
  .sidebar-right #header_color_5 + label,
  .sidebar-right #sidebar_color_5 + label,
  .sidebar-right #secondary_color_5 + label,
  .sidebar-right #primary_color_5 + label {
    background-color: #53D0B2; }
  .sidebar-right #nav_header_color_6 + label,
  .sidebar-right #header_color_6 + label,
  .sidebar-right #sidebar_color_6 + label,
  .sidebar-right #secondary_color_6 + label,
  .sidebar-right #primary_color_6 + label {
    background-color: #F4CF0C; }
  .sidebar-right #nav_header_color_7 + label,
  .sidebar-right #header_color_7 + label,
  .sidebar-right #sidebar_color_7 + label,
  .sidebar-right #secondary_color_7 + label,
  .sidebar-right #primary_color_7 + label {
    background-color: #50D5DB; }
  .sidebar-right #nav_header_color_8 + label,
  .sidebar-right #header_color_8 + label,
  .sidebar-right #sidebar_color_8 + label,
  .sidebar-right #secondary_color_8 + label,
  .sidebar-right #primary_color_8 + label {
    background-color: #D58FE5; }
  .sidebar-right #nav_header_color_9 + label,
  .sidebar-right #header_color_9 + label,
  .sidebar-right #sidebar_color_9 + label,
  .sidebar-right #secondary_color_9 + label,
  .sidebar-right #primary_color_9 + label {
    background-color: #7FB9EE; }
  .sidebar-right #nav_header_color_10 + label,
  .sidebar-right #header_color_10 + label,
  .sidebar-right #sidebar_color_10 + label,
  .sidebar-right #secondary_color_10 + label,
  .sidebar-right #primary_color_10 + label {
    background-color: #75D089; }
  .sidebar-right #nav_header_color_11 + label,
  .sidebar-right #header_color_11 + label,
  .sidebar-right #sidebar_color_11 + label,
  .sidebar-right #secondary_color_11 + label,
  .sidebar-right #primary_color_11 + label {
    background-color: #F6B466; }
  .sidebar-right #nav_header_color_12 + label,
  .sidebar-right #header_color_12 + label,
  .sidebar-right #sidebar_color_12 + label,
  .sidebar-right #secondary_color_12 + label,
  .sidebar-right #primary_color_12 + label {
    background-color: #A6BB68; }
  .sidebar-right #nav_header_color_13 + label,
  .sidebar-right #header_color_13 + label,
  .sidebar-right #sidebar_color_13 + label,
  .sidebar-right #secondary_color_13 + label,
  .sidebar-right #primary_color_13 + label {
    background-color: #111828; }
  .sidebar-right #nav_header_color_1 + label,
  .sidebar-right #header_color_1 + label,
  .sidebar-right #sidebar_color_1 + label,
  .sidebar-right #secondary_color_1 + label,
  .sidebar-right #primary_color_1 + label {
    border: 0.0625rem solid #c4c4c4; }
  .sidebar-right.style-1 {
    height: 100vh;
    width: 15.625rem;
    margin-top: 0;
    right: -15.625rem; }
    .sidebar-right.style-1 .sidebar-right-inner {
      background: #fff; }
    .sidebar-right.style-1 .sidebar-right-trigger {
      top: 12.4rem; }
    .sidebar-right.style-1.show {
      right: 0; }
      .sidebar-right.style-1.show .sidebar-right-trigger {
        display: block; }
  .sidebar-right [for=header_color_1],
  .sidebar-right [for=nav_header_color_1],
  .sidebar-right [for=primary_color_1],
  .sidebar-right [for=sidebar_color_1] {
    background: transparent;
    background-size: 130%;
    background-image: url("../images/pattern/pattern5.png"); }

.nice-select.wide {
  line-height: 2.1125rem; }
  .nice-select.wide.form-control-sm {
    height: 35px;
    line-height: 1rem; }

@keyframes bounce {
  0% {
    transform: translateX(-8%);
    -webkit-transform: translateX(-8%); }
  50% {
    transform: translateX(8%);
    -webkit-transform: translateX(8%); }
  100% {
    transform: translateX(-8%);
    -webkit-transform: translateX(-8%); } }

@-webkit-keyframes bounce {
  0% {
    transform: translateX(-8%);
    -webkit-transform: translateX(-8%); }
  50% {
    transform: translateX(8%);
    -webkit-transform: translateX(8%); }
  100% {
    transform: translateY(-8%);
    -webkit-transform: translateY(-8%); } }

@media only screen and (max-width: 87.5rem) {
  .nice-select.wide {
    line-height: 2rem; } }

.nav-user {
  background: var(--primary);
  margin-bottom: 0.625rem;
  padding: 1.25rem 1.5625rem 0.9375rem; }
  @media only screen and (min-width: 48rem) and (max-width: 74.9375rem) {
    .nav-user {
      padding: 1.25rem 0.9375rem 0.9375rem; } }
  .nav-user img {
    width: 2.1875rem;
    height: 2.1875rem; }
    @media only screen and (min-width: 48rem) and (max-width: 74.9375rem) {
      .nav-user img {
        width: 2.1875rem;
        height: 2.1875rem;
        margin-bottom: 0.625rem; } }
  .nav-user h5, .nav-user .h5 {
    margin-left: 0.625rem;
    margin-bottom: 0.1875rem;
    color: #fff; }
    @media only screen and (min-width: 48rem) and (max-width: 74.9375rem) {
      .nav-user h5, .nav-user .h5 {
        display: none; } }
    [data-sibebarbg="color_2"] .nav-user h5, [data-sibebarbg="color_2"] .nav-user .h5 {
      color: #fff; }
  .nav-user p {
    margin-left: 0.625rem;
    margin-bottom: 0.5rem;
    color: #afcff7; }
    @media only screen and (min-width: 48rem) and (max-width: 74.9375rem) {
      .nav-user p {
        display: none; } }
  @media only screen and (min-width: 48rem) and (max-width: 74.9375rem) {
    .nav-user i {
      margin-top: 0.9375rem;
      display: block; } }

.menu-toggle .nav-user {
  padding: 1.25rem 0.9375rem 0.9375rem; }
  .menu-toggle .nav-user img {
    width: 2.1875rem;
    height: 2.1875rem;
    margin-bottom: 0.625rem; }
  .menu-toggle .nav-user h5, .menu-toggle .nav-user .h5,
  .menu-toggle .nav-user p {
    display: none; }
  .menu-toggle .nav-user i {
    margin-top: 0.9375rem;
    display: block; }
  .menu-toggle .nav-user .dropdown-menu {
    left: 2.8125rem !important;
    top: 1.375rem !important; }

.chatbox {
  width: 21.25rem;
  height: 100vh;
  position: fixed;
  right: -31.25rem;
  top: 0;
  z-index: 999;
  background: #fff;
  box-shadow: 0rem 0rem 1.875rem 0rem rgba(82, 63, 105, 0.15);
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s; }
  [data-theme-version="dark"] .chatbox {
    background: #2c2c2c; }
  .chatbox .chatbox-close {
    position: absolute;
    -webkit-transition: all 0.2s;
    -ms-transition: all 0.2s;
    transition: all 0.2s;
    width: 0;
    height: 100%;
    right: 21.25rem;
    background: #000;
    z-index: 1;
    opacity: 0.1;
    cursor: pointer; }
  .chatbox .card-fotter {
    padding: 0.75rem 1rem; }
  .chatbox .card-body {
    padding: 1rem; }
  .chatbox.active {
    right: 0; }
    .chatbox.active .chatbox-close {
      width: 100vw; }
  .chatbox .type_msg {
    padding-top: 0.625rem; }
  .chatbox .nav {
    padding: 1rem 1rem 0 1rem;
    background: var(--rgba-primary-1);
    border: 0;
    justify-content: space-between; }
    .chatbox .nav .nav-link {
      color: var(--primary);
      opacity: 0.7;
      text-transform: uppercase; }
      .chatbox .nav .nav-link:hover, .chatbox .nav .nav-link.active {
        background: transparent;
        color: var(--primary);
        opacity: 1;
        border-color: var(--primary); }
  .chatbox .img_cont {
    width: 2.5rem;
    border-radius: 2.5rem;
    margin-right: 0.625rem;
    position: relative;
    height: 2.5rem;
    background: #eee;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 0.875rem;
    min-width: 2.5rem;
    min-height: 2.5rem; }
    .chatbox .img_cont .icon {
      color: #fff; }
    .chatbox .img_cont.primary {
      background: var(--rgba-primary-1);
      color: var(--primary); }
      [data-theme-version="dark"] .chatbox .img_cont.primary {
        background: var(--rgba-primary-1); }
    .chatbox .img_cont.warning {
      background: #FCEF73;
      color: #F4CF0C; }
      [data-theme-version="dark"] .chatbox .img_cont.warning {
        background: rgba(244, 207, 12, 0.1); }
    .chatbox .img_cont.success {
      background: #A7FFEA;
      color: #53D0B2; }
      [data-theme-version="dark"] .chatbox .img_cont.success {
        background: rgba(83, 208, 178, 0.1); }
    .chatbox .img_cont.info {
      background: #D7E3FF;
      color: #81A5F9; }
      [data-theme-version="dark"] .chatbox .img_cont.info {
        background: rgba(129, 165, 249, 0.1); }
    .chatbox .img_cont img {
      width: 100%; }
    .chatbox .img_cont .online_icon {
      background: #53D0B2;
      position: absolute;
      width: 0.75rem;
      height: 0.75rem;
      border-radius: 0.9375rem;
      right: -0.0625rem;
      bottom: 0rem;
      border: 0.125rem solid #fff; }
      .chatbox .img_cont .online_icon.offline {
        background: #FF7C7C; }
  .chatbox .card {
    box-shadow: none;
    border: 0; }
  .chatbox .search {
    height: 2.5rem; }
  .chatbox .user_info span {
    font-size: 0.9375rem;
    color: #000;
    display: block;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 0.3125rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    max-width: 10.625rem; }
    [data-theme-version="dark"] .chatbox .user_info span {
      color: #fff; }
  .chatbox .user_info p {
    font-size: 0.8125rem;
    margin-bottom: 0;
    line-height: 1.2;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    max-width: 10.625rem; }
  .chatbox .contacts li {
    padding: 0.75rem 1rem;
    border-bottom: 0.0625rem solid #eee;
    cursor: pointer; }
    [data-theme-version="dark"] .chatbox .contacts li {
      border-color: #444444; }
    .chatbox .contacts li > div {
      display: flex;
      align-items: center; }
    .chatbox .contacts li:hover {
      background: var(--primary-light); }
      [data-theme-version="dark"] .chatbox .contacts li:hover {
        background-color: #1E1E1E; }
  .chatbox .contacts .name-first-letter {
    background: var(--primary-light);
    padding: 0.25rem 1rem;
    font-weight: 700;
    color: #000;
    position: sticky;
    top: 0;
    z-index: 1; }
    [data-theme-version="dark"] .chatbox .contacts .name-first-letter {
      color: #fff;
      background: #1E1E1E; }
  .chatbox .contacts_body {
    height: calc(100vh - 130px); }
  .chatbox .card-header {
    background: #f4f7ff;
    padding: 0.9375rem 1.25rem;
    justify-content: center; }
    .chatbox .card-header h6, .chatbox .card-header .h6 {
      font-size: 0.9375rem; }
    .chatbox .card-header p {
      line-height: 1.2;
      font-size: 0.75rem;
      color: #888888; }
  .chatbox .chat-list-header {
    justify-content: space-between;
    background: #fff; }
    [data-theme-version="dark"] .chatbox .chat-list-header {
      background: #2c2c2c; }
    .chatbox .chat-list-header a {
      text-align: center;
      width: 1.875rem;
      height: 1.875rem;
      background: var(--rgba-primary-1);
      border-radius: 0.375rem;
      line-height: 1.675rem;
      display: block; }
      [data-theme-version="dark"] .chatbox .chat-list-header a {
        background: var(--rgba-primary-1); }
        [data-theme-version="dark"] .chatbox .chat-list-header a svg g [fill] {
          fill: #fff; }
  .chatbox .img_cont_msg {
    width: 1.875rem;
    height: 1.875rem;
    display: block;
    max-width: 1.875rem;
    min-width: 1.875rem; }
    .chatbox .img_cont_msg img {
      width: 100%; }
  .chatbox .msg_cotainer {
    background: var(--primary);
    margin-left: 0.625rem;
    border-radius: 0 0.625rem 0.625rem 0.625rem;
    padding: 0.625rem 0.9375rem;
    color: #fff;
    position: relative; }
    .chatbox .msg_cotainer .msg_time {
      display: block;
      font-size: 0.6875rem;
      color: #fff;
      margin-top: 0.3125rem;
      opacity: 0.5; }
    .chatbox .msg_cotainer:after {
      content: "";
      position: absolute;
      left: -0.625rem;
      border-right: 0.625rem solid var(--primary);
      border-bottom: 0.625rem solid transparent;
      border-top: 0rem solid;
      top: 0; }
  .chatbox .msg_cotainer_send {
    background: #F4F4F5;
    padding: 0.625rem 0.9375rem;
    border-radius: 0.375rem 0rem 0.375rem 0.375rem;
    margin-right: 0.625rem;
    color: #222;
    position: relative;
    text-align: right; }
    [data-theme-version="dark"] .chatbox .msg_cotainer_send {
      background: #1E1E1E;
      color: #fff; }
    .chatbox .msg_cotainer_send .msg_time_send {
      display: block;
      font-size: 0.6875rem;
      text-align: right;
      margin-top: 0.3125rem;
      opacity: 0.6; }
    .chatbox .msg_cotainer_send:after {
      content: "";
      position: absolute;
      right: -0.625rem;
      border-left: 0.625rem solid #F4F4F5;
      border-bottom: 0.625rem solid transparent;
      border-top: 0rem solid;
      top: 0; }
      [data-theme-version="dark"] .chatbox .msg_cotainer_send:after {
        border-left: 0.625rem solid #1E1E1E; }
  .chatbox .type_msg .form-control {
    padding: 0.625rem 0;
    height: 3.125rem;
    border: 0;
    resize: none; }
    .chatbox .type_msg .form-control:focus {
      z-index: 0; }
  .chatbox .type_msg .btn {
    font-size: 1.125rem;
    border-radius: 2.375rem !important;
    width: 2.375rem;
    height: 2.375rem;
    padding: 0;
    margin-top: 0.375rem; }
  .chatbox .video_cam {
    margin-left: 0.9375rem; }
    .chatbox .video_cam span {
      width: 2.1875rem;
      height: 2.1875rem;
      background: #10ca93;
      text-align: center;
      line-height: 2.1875rem;
      border-radius: 2.1875rem;
      color: #fff;
      margin-right: 0.3125rem;
      align-self: center;
      font-size: 1rem;
      padding: 0 0.1875rem;
      display: inline-block; }
  .chatbox .note_card .contacts li {
    padding: 0.75rem 1rem; }

@media only screen and (max-width: 36rem) {
  .chatbox {
    width: 17.5rem; }
    .chatbox .chatbox-close {
      right: 17.5rem; } }

.dz-demo-panel {
  right: -100%;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  transition: all .5s ease-in-out;
  z-index: 9999; }
  .dz-demo-panel .note-text {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 20px;
    font-size: 12px;
    background-color: transprent;
    color: #9b9a9a; }
  .dz-demo-panel .dz-demo-trigger {
    position: absolute;
    z-index: 9;
    top: 7.75rem;
    right: 100%;
    background-color: #627EEA;
    color: #fff;
    display: inline-block;
    height: 3rem;
    width: 3rem;
    text-align: center;
    font-size: 1.75rem;
    line-height: 3rem;
    border-radius: 0.3125rem 0 0 0.3125rem;
    box-shadow: -0.3125rem 0.1875rem 0.3125rem 0rem rgba(119, 119, 119, 0.15); }
    @media only screen and (max-width: 63.9375rem) {
      .dz-demo-panel .dz-demo-trigger {
        display: none; } }
  .dz-demo-panel .dz-demo-close {
    height: 1.875rem;
    color: #000 !important;
    width: 1.875rem;
    border-radius: 0.5rem;
    background: #fff;
    line-height: 1.875rem;
    text-align: center; }
  .dz-demo-panel:after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background: rgba(0, 0, 0, 0.85);
    display: block;
    backdrop-filter: blur(4px); }
  .dz-demo-panel.show {
    right: 0;
    box-shadow: 0rem 0rem 3.125rem rgba(0, 0, 0, 0.2);
    z-index: 99999;
    overflow: hidden; }
    .dz-demo-panel.show .sidebar-right-trigger {
      display: none; }
    .dz-demo-panel.show .bg-close {
      position: fixed;
      z-index: -2;
      cursor: pointer;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.15); }
  .dz-demo-panel .dz-demo-inner {
    padding: 1.875rem 1.875rem; }
  .dz-demo-panel .dz-demo-content {
    height: calc(100vh - 200px);
    padding: 0 15px;
    margin: 0 -15px;
    display: flex;
    overflow-y: scroll; }
  .dz-demo-panel .dz-demo-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3.5rem; }
    .dz-demo-panel .dz-demo-header h4, .dz-demo-panel .dz-demo-header .h4 {
      margin-bottom: 0;
      color: #fff; }
  .dz-demo-panel .dz-demo-bx {
    overflow: hidden;
    border: 3px solid #efefef;
    box-shadow: 0rem 0rem 0.3125rem rgba(0, 0, 0, 0.1);
    margin-bottom: 0.625rem;
    border-radius: 0.625rem; }
    .dz-demo-panel .dz-demo-bx.demo-active {
      border-color: var(--primary); }
      .dz-demo-panel .dz-demo-bx.demo-active .overlay-layer {
        opacity: 1; }
  .dz-demo-panel .overlay-bx {
    position: relative; }
    .dz-demo-panel .overlay-bx .overlay-layer {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.1);
      -webkit-transition: all .3s ease;
      transition: all .3s ease;
      opacity: 0;
      border: 0; }
    .dz-demo-panel .overlay-bx:hover .overlay-layer {
      -webkit-transition: all .3s ease;
      transition: all .3s ease;
      opacity: 1; }

:root {
  --nav-headbg: #FFFFFF;
  --sidebar-bg: #FFFFFF;
  --headerbg: #FFFFFF; }

[data-nav-headerbg="color_2"][data-theme-version="dark"],
[data-nav-headerbg="color_2"] {
  --nav-headbg: #A0CF1A;
  /* .nav-control{
				background-color:transparent;
			} */
  /* &[data-nav-headerbg="color_8"]{
				.nav-header{
					.brand-logo{
						.brand-title{
							path{
								fill:$black;		
							}
							tspan{
								fill:$black;	
							}
						}	
					}
					.hamburger .line{
						background:$black;
					}
				}
			} */ }
  [data-nav-headerbg="color_2"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path.react-w, [data-nav-headerbg="color_2"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path.w3,
  [data-nav-headerbg="color_2"] .nav-header .brand-logo .logo-abbr path.react-w,
  [data-nav-headerbg="color_2"] .nav-header .brand-logo .logo-abbr path.w3 {
    fill: #fff; }
  [data-nav-headerbg="color_2"][data-theme-version="dark"] .nav-header .brand-logo .brand-title path,
  [data-nav-headerbg="color_2"] .nav-header .brand-logo .brand-title path {
    fill: #fff; }
  [data-nav-headerbg="color_2"][data-theme-version="dark"] .nav-header .hamburger .line,
  [data-nav-headerbg="color_2"] .nav-header .hamburger .line {
    background: #000; }

[data-nav-headerbg="color_3"][data-theme-version="dark"],
[data-nav-headerbg="color_3"] {
  --nav-headbg: #FF7C7C;
  /* .nav-control{
				background-color:transparent;
			} */
  /* &[data-nav-headerbg="color_8"]{
				.nav-header{
					.brand-logo{
						.brand-title{
							path{
								fill:$black;		
							}
							tspan{
								fill:$black;	
							}
						}	
					}
					.hamburger .line{
						background:$black;
					}
				}
			} */ }
  [data-nav-headerbg="color_3"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path.react-w, [data-nav-headerbg="color_3"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path.w3,
  [data-nav-headerbg="color_3"] .nav-header .brand-logo .logo-abbr path.react-w,
  [data-nav-headerbg="color_3"] .nav-header .brand-logo .logo-abbr path.w3 {
    fill: #fff; }
  [data-nav-headerbg="color_3"][data-theme-version="dark"] .nav-header .brand-logo .brand-title path,
  [data-nav-headerbg="color_3"] .nav-header .brand-logo .brand-title path {
    fill: #fff; }
  [data-nav-headerbg="color_3"][data-theme-version="dark"] .nav-header .hamburger .line,
  [data-nav-headerbg="color_3"] .nav-header .hamburger .line {
    background: #000; }

[data-nav-headerbg="color_4"][data-theme-version="dark"],
[data-nav-headerbg="color_4"] {
  --nav-headbg: #81A4F9;
  /* .nav-control{
				background-color:transparent;
			} */
  /* &[data-nav-headerbg="color_8"]{
				.nav-header{
					.brand-logo{
						.brand-title{
							path{
								fill:$black;		
							}
							tspan{
								fill:$black;	
							}
						}	
					}
					.hamburger .line{
						background:$black;
					}
				}
			} */ }
  [data-nav-headerbg="color_4"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path.react-w, [data-nav-headerbg="color_4"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path.w3,
  [data-nav-headerbg="color_4"] .nav-header .brand-logo .logo-abbr path.react-w,
  [data-nav-headerbg="color_4"] .nav-header .brand-logo .logo-abbr path.w3 {
    fill: #fff; }
  [data-nav-headerbg="color_4"][data-theme-version="dark"] .nav-header .brand-logo .brand-title path,
  [data-nav-headerbg="color_4"] .nav-header .brand-logo .brand-title path {
    fill: #fff; }
  [data-nav-headerbg="color_4"][data-theme-version="dark"] .nav-header .hamburger .line,
  [data-nav-headerbg="color_4"] .nav-header .hamburger .line {
    background: #000; }

[data-nav-headerbg="color_5"][data-theme-version="dark"],
[data-nav-headerbg="color_5"] {
  --nav-headbg: #53D0B2;
  /* .nav-control{
				background-color:transparent;
			} */
  /* &[data-nav-headerbg="color_8"]{
				.nav-header{
					.brand-logo{
						.brand-title{
							path{
								fill:$black;		
							}
							tspan{
								fill:$black;	
							}
						}	
					}
					.hamburger .line{
						background:$black;
					}
				}
			} */ }
  [data-nav-headerbg="color_5"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path.react-w, [data-nav-headerbg="color_5"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path.w3,
  [data-nav-headerbg="color_5"] .nav-header .brand-logo .logo-abbr path.react-w,
  [data-nav-headerbg="color_5"] .nav-header .brand-logo .logo-abbr path.w3 {
    fill: #fff; }
  [data-nav-headerbg="color_5"][data-theme-version="dark"] .nav-header .brand-logo .brand-title path,
  [data-nav-headerbg="color_5"] .nav-header .brand-logo .brand-title path {
    fill: #fff; }
  [data-nav-headerbg="color_5"][data-theme-version="dark"] .nav-header .hamburger .line,
  [data-nav-headerbg="color_5"] .nav-header .hamburger .line {
    background: #000; }

[data-nav-headerbg="color_6"][data-theme-version="dark"],
[data-nav-headerbg="color_6"] {
  --nav-headbg: #F4CF0C;
  /* .nav-control{
				background-color:transparent;
			} */
  /* &[data-nav-headerbg="color_8"]{
				.nav-header{
					.brand-logo{
						.brand-title{
							path{
								fill:$black;		
							}
							tspan{
								fill:$black;	
							}
						}	
					}
					.hamburger .line{
						background:$black;
					}
				}
			} */ }
  [data-nav-headerbg="color_6"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path.react-w, [data-nav-headerbg="color_6"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path.w3,
  [data-nav-headerbg="color_6"] .nav-header .brand-logo .logo-abbr path.react-w,
  [data-nav-headerbg="color_6"] .nav-header .brand-logo .logo-abbr path.w3 {
    fill: #fff; }
  [data-nav-headerbg="color_6"][data-theme-version="dark"] .nav-header .brand-logo .brand-title path,
  [data-nav-headerbg="color_6"] .nav-header .brand-logo .brand-title path {
    fill: #fff; }
  [data-nav-headerbg="color_6"][data-theme-version="dark"] .nav-header .hamburger .line,
  [data-nav-headerbg="color_6"] .nav-header .hamburger .line {
    background: #000; }

[data-nav-headerbg="color_7"][data-theme-version="dark"],
[data-nav-headerbg="color_7"] {
  --nav-headbg: #50D5DB;
  /* .nav-control{
				background-color:transparent;
			} */
  /* &[data-nav-headerbg="color_8"]{
				.nav-header{
					.brand-logo{
						.brand-title{
							path{
								fill:$black;		
							}
							tspan{
								fill:$black;	
							}
						}	
					}
					.hamburger .line{
						background:$black;
					}
				}
			} */ }
  [data-nav-headerbg="color_7"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path.react-w, [data-nav-headerbg="color_7"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path.w3,
  [data-nav-headerbg="color_7"] .nav-header .brand-logo .logo-abbr path.react-w,
  [data-nav-headerbg="color_7"] .nav-header .brand-logo .logo-abbr path.w3 {
    fill: #fff; }
  [data-nav-headerbg="color_7"][data-theme-version="dark"] .nav-header .brand-logo .brand-title path,
  [data-nav-headerbg="color_7"] .nav-header .brand-logo .brand-title path {
    fill: #fff; }
  [data-nav-headerbg="color_7"][data-theme-version="dark"] .nav-header .hamburger .line,
  [data-nav-headerbg="color_7"] .nav-header .hamburger .line {
    background: #000; }

[data-nav-headerbg="color_8"][data-theme-version="dark"],
[data-nav-headerbg="color_8"] {
  --nav-headbg: #D58FE5;
  /* .nav-control{
				background-color:transparent;
			} */
  /* &[data-nav-headerbg="color_8"]{
				.nav-header{
					.brand-logo{
						.brand-title{
							path{
								fill:$black;		
							}
							tspan{
								fill:$black;	
							}
						}	
					}
					.hamburger .line{
						background:$black;
					}
				}
			} */ }
  [data-nav-headerbg="color_8"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path.react-w, [data-nav-headerbg="color_8"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path.w3,
  [data-nav-headerbg="color_8"] .nav-header .brand-logo .logo-abbr path.react-w,
  [data-nav-headerbg="color_8"] .nav-header .brand-logo .logo-abbr path.w3 {
    fill: #fff; }
  [data-nav-headerbg="color_8"][data-theme-version="dark"] .nav-header .brand-logo .brand-title path,
  [data-nav-headerbg="color_8"] .nav-header .brand-logo .brand-title path {
    fill: #fff; }
  [data-nav-headerbg="color_8"][data-theme-version="dark"] .nav-header .hamburger .line,
  [data-nav-headerbg="color_8"] .nav-header .hamburger .line {
    background: #000; }

[data-nav-headerbg="color_9"][data-theme-version="dark"],
[data-nav-headerbg="color_9"] {
  --nav-headbg: #7FB9EE;
  /* .nav-control{
				background-color:transparent;
			} */
  /* &[data-nav-headerbg="color_8"]{
				.nav-header{
					.brand-logo{
						.brand-title{
							path{
								fill:$black;		
							}
							tspan{
								fill:$black;	
							}
						}	
					}
					.hamburger .line{
						background:$black;
					}
				}
			} */ }
  [data-nav-headerbg="color_9"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path.react-w, [data-nav-headerbg="color_9"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path.w3,
  [data-nav-headerbg="color_9"] .nav-header .brand-logo .logo-abbr path.react-w,
  [data-nav-headerbg="color_9"] .nav-header .brand-logo .logo-abbr path.w3 {
    fill: #fff; }
  [data-nav-headerbg="color_9"][data-theme-version="dark"] .nav-header .brand-logo .brand-title path,
  [data-nav-headerbg="color_9"] .nav-header .brand-logo .brand-title path {
    fill: #fff; }
  [data-nav-headerbg="color_9"][data-theme-version="dark"] .nav-header .hamburger .line,
  [data-nav-headerbg="color_9"] .nav-header .hamburger .line {
    background: #000; }

[data-nav-headerbg="color_10"][data-theme-version="dark"],
[data-nav-headerbg="color_10"] {
  --nav-headbg: #75D089;
  /* .nav-control{
				background-color:transparent;
			} */
  /* &[data-nav-headerbg="color_8"]{
				.nav-header{
					.brand-logo{
						.brand-title{
							path{
								fill:$black;		
							}
							tspan{
								fill:$black;	
							}
						}	
					}
					.hamburger .line{
						background:$black;
					}
				}
			} */ }
  [data-nav-headerbg="color_10"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path.react-w, [data-nav-headerbg="color_10"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path.w3,
  [data-nav-headerbg="color_10"] .nav-header .brand-logo .logo-abbr path.react-w,
  [data-nav-headerbg="color_10"] .nav-header .brand-logo .logo-abbr path.w3 {
    fill: #fff; }
  [data-nav-headerbg="color_10"][data-theme-version="dark"] .nav-header .brand-logo .brand-title path,
  [data-nav-headerbg="color_10"] .nav-header .brand-logo .brand-title path {
    fill: #fff; }
  [data-nav-headerbg="color_10"][data-theme-version="dark"] .nav-header .hamburger .line,
  [data-nav-headerbg="color_10"] .nav-header .hamburger .line {
    background: #000; }

[data-nav-headerbg="color_11"][data-theme-version="dark"],
[data-nav-headerbg="color_11"] {
  --nav-headbg: #F6B466;
  /* .nav-control{
				background-color:transparent;
			} */
  /* &[data-nav-headerbg="color_8"]{
				.nav-header{
					.brand-logo{
						.brand-title{
							path{
								fill:$black;		
							}
							tspan{
								fill:$black;	
							}
						}	
					}
					.hamburger .line{
						background:$black;
					}
				}
			} */ }
  [data-nav-headerbg="color_11"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path.react-w, [data-nav-headerbg="color_11"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path.w3,
  [data-nav-headerbg="color_11"] .nav-header .brand-logo .logo-abbr path.react-w,
  [data-nav-headerbg="color_11"] .nav-header .brand-logo .logo-abbr path.w3 {
    fill: #fff; }
  [data-nav-headerbg="color_11"][data-theme-version="dark"] .nav-header .brand-logo .brand-title path,
  [data-nav-headerbg="color_11"] .nav-header .brand-logo .brand-title path {
    fill: #fff; }
  [data-nav-headerbg="color_11"][data-theme-version="dark"] .nav-header .hamburger .line,
  [data-nav-headerbg="color_11"] .nav-header .hamburger .line {
    background: #000; }

[data-nav-headerbg="color_12"][data-theme-version="dark"],
[data-nav-headerbg="color_12"] {
  --nav-headbg: #A6BB68;
  /* .nav-control{
				background-color:transparent;
			} */
  /* &[data-nav-headerbg="color_8"]{
				.nav-header{
					.brand-logo{
						.brand-title{
							path{
								fill:$black;		
							}
							tspan{
								fill:$black;	
							}
						}	
					}
					.hamburger .line{
						background:$black;
					}
				}
			} */ }
  [data-nav-headerbg="color_12"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path.react-w, [data-nav-headerbg="color_12"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path.w3,
  [data-nav-headerbg="color_12"] .nav-header .brand-logo .logo-abbr path.react-w,
  [data-nav-headerbg="color_12"] .nav-header .brand-logo .logo-abbr path.w3 {
    fill: #fff; }
  [data-nav-headerbg="color_12"][data-theme-version="dark"] .nav-header .brand-logo .brand-title path,
  [data-nav-headerbg="color_12"] .nav-header .brand-logo .brand-title path {
    fill: #fff; }
  [data-nav-headerbg="color_12"][data-theme-version="dark"] .nav-header .hamburger .line,
  [data-nav-headerbg="color_12"] .nav-header .hamburger .line {
    background: #000; }

[data-nav-headerbg="color_13"][data-theme-version="dark"],
[data-nav-headerbg="color_13"] {
  --nav-headbg: #111828;
  /* .nav-control{
				background-color:transparent;
			} */
  /* &[data-nav-headerbg="color_8"]{
				.nav-header{
					.brand-logo{
						.brand-title{
							path{
								fill:$black;		
							}
							tspan{
								fill:$black;	
							}
						}	
					}
					.hamburger .line{
						background:$black;
					}
				}
			} */ }
  [data-nav-headerbg="color_13"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path.react-w, [data-nav-headerbg="color_13"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path.w3,
  [data-nav-headerbg="color_13"] .nav-header .brand-logo .logo-abbr path.react-w,
  [data-nav-headerbg="color_13"] .nav-header .brand-logo .logo-abbr path.w3 {
    fill: #fff; }
  [data-nav-headerbg="color_13"][data-theme-version="dark"] .nav-header .brand-logo .brand-title path,
  [data-nav-headerbg="color_13"] .nav-header .brand-logo .brand-title path {
    fill: #fff; }
  [data-nav-headerbg="color_13"][data-theme-version="dark"] .nav-header .hamburger .line,
  [data-nav-headerbg="color_13"] .nav-header .hamburger .line {
    background: #000; }

#sidebar_color_2 + label {
  background-color: #141a03 !important; }

#sidebar_color_3 + label {
  background-color: #af0000 !important; }

#sidebar_color_4 + label {
  background-color: #0836a6 !important; }

#sidebar_color_5 + label {
  background-color: #134438 !important; }

#sidebar_color_6 + label {
  background-color: #322a02 !important; }

#sidebar_color_7 + label {
  background-color: #104c4f !important; }

#sidebar_color_8 + label {
  background-color: #752088 !important; }

#sidebar_color_9 + label {
  background-color: #13538e !important; }

#sidebar_color_10 + label {
  background-color: #1f5a2c !important; }

#sidebar_color_11 + label {
  background-color: #884d08 !important; }

#sidebar_color_12 + label {
  background-color: #343c1b !important; }

#sidebar_color_13 + label {
  background-color: black !important; }

[data-sidebarbg="color_2"][data-theme-version="dark"],
[data-sidebarbg="color_2"] {
  --sidebar-bg: #141a03;
  --nav-headbg: #141a03;
  --headerbg:   #141a03;
  --sidebar-nav-bg:#253006;
  /* &[data-sidebar-style="full"][data-layout="vertical"]{
				.deznav .metismenu > li.mm-active > a,
				.deznav .metismenu > li:hover > a{
						background:transparent;
				}	
			} */ }
  [data-sidebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu > li:hover > a .menu-icon svg path, [data-sidebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu > li:focus > a .menu-icon svg path,
  [data-sidebarbg="color_2"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_2"] .deznav .metismenu > li:focus > a .menu-icon svg path {
    stroke: white; }
  [data-sidebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a .menu-icon svg path,
  [data-sidebarbg="color_2"] .deznav .metismenu > li.mm-active > a .menu-icon svg path {
    stroke: white; }
  [data-sidebarbg="color_2"][data-theme-version="dark"] .switch-btn,
  [data-sidebarbg="color_2"] .switch-btn {
    border-color: rgba(255, 255, 255, 0.2); }
    [data-sidebarbg="color_2"][data-theme-version="dark"] .switch-btn svg path,
    [data-sidebarbg="color_2"] .switch-btn svg path {
      stroke: #fff; }
    [data-sidebarbg="color_2"][data-theme-version="dark"] .switch-btn span,
    [data-sidebarbg="color_2"] .switch-btn span {
      color: #fff; }
  [data-sidebarbg="color_2"][data-theme-version="dark"] .nav-header .brand-title path,
  [data-sidebarbg="color_2"] .nav-header .brand-title path {
    fill: #fff; }
  [data-sidebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu ul a:before,
  [data-sidebarbg="color_2"] .deznav .metismenu ul a:before {
    background: rgba(255, 255, 255, 0.5); }
  [data-sidebarbg="color_2"][data-theme-version="dark"] .deznav .menu-icon svg path,
  [data-sidebarbg="color_2"][data-theme-version="dark"] .deznav .menu-icon svg ellipse,
  [data-sidebarbg="color_2"] .deznav .menu-icon svg path,
  [data-sidebarbg="color_2"] .deznav .menu-icon svg ellipse {
    stroke: #fff; }
  [data-sidebarbg="color_2"][data-theme-version="dark"][data-layout="horizontal"][data-primary="color_13"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_2"][data-layout="horizontal"][data-primary="color_13"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: white !important; }
  [data-sidebarbg="color_2"][data-theme-version="dark"][data-layout="horizontal"],
  [data-sidebarbg="color_2"][data-layout="horizontal"] {
    --headerbg:#f4f4f5; }
  [data-sidebarbg="color_2"][data-theme-version="dark"][data-layout="vertical"] .deznav .menu-title,
  [data-sidebarbg="color_2"][data-layout="vertical"] .deznav .menu-title {
    color: #fff; }
  [data-sidebarbg="color_2"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a i,
  [data-sidebarbg="color_2"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a i {
    color: #fff; }
  [data-sidebarbg="color_2"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box h5, [data-sidebarbg="color_2"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box .h5,
  [data-sidebarbg="color_2"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box p,
  [data-sidebarbg="color_2"][data-layout="vertical"] .deznav .plus-box h5,
  [data-sidebarbg="color_2"][data-layout="vertical"] .deznav .plus-box .h5,
  [data-sidebarbg="color_2"][data-layout="vertical"] .deznav .plus-box p {
    color: #fff; }
  [data-sidebarbg="color_2"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li > a .menu-icon,
  [data-sidebarbg="color_2"][data-layout="vertical"] .deznav .metismenu > li > a .menu-icon {
    background-color: rgba(255, 255, 255, 0.1); }
  [data-sidebarbg="color_2"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon,
  [data-sidebarbg="color_2"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon {
    background-color: var(--primary-light); }
    [data-sidebarbg="color_2"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon svg path,
    [data-sidebarbg="color_2"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon svg path {
      stroke: black; }
  [data-sidebarbg="color_2"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon,
  [data-sidebarbg="color_2"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon {
    background-color: var(--primary-light); }
    [data-sidebarbg="color_2"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon svg path,
    [data-sidebarbg="color_2"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon svg path {
      stroke: black; }
  [data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="nini"][data-layout="horizontal"] .deznav .metismenu > li.mm-active > a .menu-icon,
  [data-sidebarbg="color_2"][data-sidebar-style="nini"][data-layout="horizontal"] .deznav .metismenu > li.mm-active > a .menu-icon {
    background-color: transparent; }
  [data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="nini"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon,
  [data-sidebarbg="color_2"][data-sidebar-style="nini"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon {
    background-color: var(--primary-light); }
  [data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="nini"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon,
  [data-sidebarbg="color_2"][data-sidebar-style="nini"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon {
    background-color: var(--primary-light); }
  [data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu > li:before,
  [data-sidebarbg="color_2"][data-sidebar-style="modern"] .deznav .metismenu > li:before {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 53.12%, rgba(255, 255, 255, 0) 100%); }
  [data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu > li.mm-active > a .menu-icon,
  [data-sidebarbg="color_2"][data-sidebar-style="modern"] .deznav .metismenu > li.mm-active > a .menu-icon {
    background-color: transparent !important; }
    [data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu > li.mm-active > a .menu-icon path fill,
    [data-sidebarbg="color_2"][data-sidebar-style="modern"] .deznav .metismenu > li.mm-active > a .menu-icon path fill {
      stroke: white !important; }
  [data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu li ul,
  [data-sidebarbg="color_2"][data-sidebar-style="modern"] .deznav .metismenu li ul {
    background-color: var(--sidebar-nav-bg); }
  [data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu > li:hover > a .menu-icon svg path, [data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu > li:focus > a .menu-icon svg path,
  [data-sidebarbg="color_2"][data-sidebar-style="modern"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_2"][data-sidebar-style="modern"] .deznav .metismenu > li:focus > a .menu-icon svg path {
    stroke: var(--primary) !important; }
  [data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:before,
  [data-sidebarbg="color_2"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:before {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 53.12%, rgba(255, 255, 255, 0) 100%); }
  [data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li > a .menu-icon,
  [data-sidebarbg="color_2"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li > a .menu-icon {
    background-color: transparent; }
  [data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon svg path,
  [data-sidebarbg="color_2"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon svg path {
    stroke: white; }
  [data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon,
  [data-sidebarbg="color_2"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon {
    background-color: transparent; }
    [data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon svg path,
    [data-sidebarbg="color_2"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon svg path {
      stroke: white; }
  [data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > a,
  [data-sidebarbg="color_2"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > a {
    color: var(--primary); }
    [data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > a .menu-icon svg path,
    [data-sidebarbg="color_2"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > a .menu-icon svg path {
      stroke: white; }
  [data-sidebarbg="color_2"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:hover > ul,
  [data-sidebarbg="color_2"][data-layout="horizontal"] .deznav .metismenu > li:hover > ul {
    background-color: var(--sidebar-nav-bg); }
  [data-sidebarbg="color_2"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu li > ul ul,
  [data-sidebarbg="color_2"][data-layout="horizontal"] .deznav .metismenu li > ul ul {
    background-color: var(--sidebar-nav-bg); }
  [data-sidebarbg="color_2"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon, [data-sidebarbg="color_2"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:focus > a .menu-icon,
  [data-sidebarbg="color_2"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon,
  [data-sidebarbg="color_2"][data-layout="horizontal"] .deznav .metismenu > li:focus > a .menu-icon {
    background-color: transparent !important; }
    [data-sidebarbg="color_2"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon svg path, [data-sidebarbg="color_2"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:focus > a .menu-icon svg path,
    [data-sidebarbg="color_2"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon svg path,
    [data-sidebarbg="color_2"][data-layout="horizontal"] .deznav .metismenu > li:focus > a .menu-icon svg path {
      stroke: var(--primary) !important; }
  [data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li a:hover,
  [data-sidebarbg="color_2"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li a:hover {
    color: #fff; }
  [data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li > a > i,
  [data-sidebarbg="color_2"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li > a > i {
    background: #abdd1c;
    color: rgba(255, 255, 255, 0.7); }
  [data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a, [data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li:hover > a,
  [data-sidebarbg="color_2"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a,
  [data-sidebarbg="color_2"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li:hover > a {
    box-shadow: none;
    background: transparent !important;
    color: #fff !important; }
    [data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a i, [data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li:hover > a i,
    [data-sidebarbg="color_2"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a i,
    [data-sidebarbg="color_2"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li:hover > a i {
      background: var(--primary);
      color: #fff !important; }
  [data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="modern"][data-sidebarbg="color_13"][data-primary="color_13"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_2"][data-sidebar-style="modern"][data-sidebarbg="color_13"][data-primary="color_13"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: white !important; }
  [data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li > a .menu-icon,
  [data-sidebarbg="color_2"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li > a .menu-icon {
    background-color: rgba(255, 255, 255, 0.1); }
  [data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li:hover .menu-icon,
  [data-sidebarbg="color_2"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li:hover .menu-icon {
    background-color: var(--primary-light) !important; }
    [data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li:hover .menu-icon svg path,
    [data-sidebarbg="color_2"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li:hover .menu-icon svg path {
      stroke: black !important; }
  [data-sidebarbg="color_2"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_2"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: black; }
  [data-sidebarbg="color_2"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu li > ul li a,
  [data-sidebarbg="color_2"][data-layout="horizontal"] .deznav .metismenu li > ul li a {
    padding: 0.5rem 1.25rem 0.5rem 1.25rem; }
  [data-sidebarbg="color_2"][data-theme-version="dark"][data-layout="horizontal"][data-sidebar-style="full"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_2"][data-layout="horizontal"][data-sidebar-style="full"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: var(--primary) !important; }
  [data-sidebarbg="color_2"][data-theme-version="dark"] [data-layout="horizontal"][data-sidebar-style="icon-hover"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_2"] [data-layout="horizontal"][data-sidebar-style="icon-hover"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: var(--primary); }
  [data-sidebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu > li:hover > a .menu-icon, [data-sidebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu > li:focus > a .menu-icon,
  [data-sidebarbg="color_2"] .deznav .metismenu > li:hover > a .menu-icon,
  [data-sidebarbg="color_2"] .deznav .metismenu > li:focus > a .menu-icon {
    background-color: var(--primary-light); }
    [data-sidebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu > li:hover > a .menu-icon svg path, [data-sidebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu > li:focus > a .menu-icon svg path,
    [data-sidebarbg="color_2"] .deznav .metismenu > li:hover > a .menu-icon svg path,
    [data-sidebarbg="color_2"] .deznav .metismenu > li:focus > a .menu-icon svg path {
      stroke: black !important; }
  [data-sidebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu a,
  [data-sidebarbg="color_2"] .deznav .metismenu a {
    color: rgba(255, 255, 255, 0.8) !important; }
  [data-sidebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu li ul a:hover, [data-sidebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu li ul a:focus, [data-sidebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu li ul a.mm-active,
  [data-sidebarbg="color_2"] .deznav .metismenu li ul a:hover,
  [data-sidebarbg="color_2"] .deznav .metismenu li ul a:focus,
  [data-sidebarbg="color_2"] .deznav .metismenu li ul a.mm-active {
    color: #fff !important; }
  [data-sidebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_2"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: var(--primary); }
  [data-sidebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu > li > a,
  [data-sidebarbg="color_2"] .deznav .metismenu > li > a {
    color: rgba(255, 255, 255, 0.85) !important; }
    [data-sidebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu > li > a i,
    [data-sidebarbg="color_2"] .deznav .metismenu > li > a i {
      color: rgba(255, 255, 255, 0.85) !important; }
  [data-sidebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a,
  [data-sidebarbg="color_2"] .deznav .metismenu > li.mm-active > a {
    color: #fff; }
    [data-sidebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a i,
    [data-sidebarbg="color_2"] .deznav .metismenu > li.mm-active > a i {
      color: #fff !important; }
    [data-sidebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a:before,
    [data-sidebarbg="color_2"] .deznav .metismenu > li.mm-active > a:before {
      background: #fff !important; }
  [data-sidebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu .has-arrow:after,
  [data-sidebarbg="color_2"] .deznav .metismenu .has-arrow:after {
    border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85) !important; }
  [data-sidebarbg="color_2"][data-theme-version="dark"] .deznav .help-desk .btn,
  [data-sidebarbg="color_2"] .deznav .help-desk .btn {
    background-color: #b8e636;
    border-color: #b8e636; }
  [data-sidebarbg="color_2"][data-theme-version="dark"] .deznav .header-info2 span,
  [data-sidebarbg="color_2"] .deznav .header-info2 span {
    color: #fff; }
  [data-sidebarbg="color_2"][data-theme-version="dark"] .deznav .sidebar-info,
  [data-sidebarbg="color_2"] .deznav .sidebar-info {
    color: #fff; }
    [data-sidebarbg="color_2"][data-theme-version="dark"] .deznav .sidebar-info i,
    [data-sidebarbg="color_2"] .deznav .sidebar-info i {
      color: #fff; }
  [data-sidebarbg="color_2"][data-theme-version="dark"] .plus-box,
  [data-sidebarbg="color_2"] .plus-box {
    background-color: #b8e636; }
  [data-sidebarbg="color_2"][data-theme-version="dark"] .copyright,
  [data-sidebarbg="color_2"] .copyright {
    color: #fff; }

[data-sidebarbg="color_3"][data-theme-version="dark"],
[data-sidebarbg="color_3"] {
  --sidebar-bg: #af0000;
  --nav-headbg: #af0000;
  --headerbg:   #af0000;
  --sidebar-nav-bg:#c90000;
  /* &[data-sidebar-style="full"][data-layout="vertical"]{
				.deznav .metismenu > li.mm-active > a,
				.deznav .metismenu > li:hover > a{
						background:transparent;
				}	
			} */ }
  [data-sidebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu > li:hover > a .menu-icon svg path, [data-sidebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu > li:focus > a .menu-icon svg path,
  [data-sidebarbg="color_3"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_3"] .deznav .metismenu > li:focus > a .menu-icon svg path {
    stroke: white; }
  [data-sidebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a .menu-icon svg path,
  [data-sidebarbg="color_3"] .deznav .metismenu > li.mm-active > a .menu-icon svg path {
    stroke: white; }
  [data-sidebarbg="color_3"][data-theme-version="dark"] .switch-btn,
  [data-sidebarbg="color_3"] .switch-btn {
    border-color: rgba(255, 255, 255, 0.2); }
    [data-sidebarbg="color_3"][data-theme-version="dark"] .switch-btn svg path,
    [data-sidebarbg="color_3"] .switch-btn svg path {
      stroke: #fff; }
    [data-sidebarbg="color_3"][data-theme-version="dark"] .switch-btn span,
    [data-sidebarbg="color_3"] .switch-btn span {
      color: #fff; }
  [data-sidebarbg="color_3"][data-theme-version="dark"] .nav-header .brand-title path,
  [data-sidebarbg="color_3"] .nav-header .brand-title path {
    fill: #fff; }
  [data-sidebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu ul a:before,
  [data-sidebarbg="color_3"] .deznav .metismenu ul a:before {
    background: rgba(255, 255, 255, 0.5); }
  [data-sidebarbg="color_3"][data-theme-version="dark"] .deznav .menu-icon svg path,
  [data-sidebarbg="color_3"][data-theme-version="dark"] .deznav .menu-icon svg ellipse,
  [data-sidebarbg="color_3"] .deznav .menu-icon svg path,
  [data-sidebarbg="color_3"] .deznav .menu-icon svg ellipse {
    stroke: #fff; }
  [data-sidebarbg="color_3"][data-theme-version="dark"][data-layout="horizontal"][data-primary="color_13"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_3"][data-layout="horizontal"][data-primary="color_13"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: white !important; }
  [data-sidebarbg="color_3"][data-theme-version="dark"][data-layout="horizontal"],
  [data-sidebarbg="color_3"][data-layout="horizontal"] {
    --headerbg:#f4f4f5; }
  [data-sidebarbg="color_3"][data-theme-version="dark"][data-layout="vertical"] .deznav .menu-title,
  [data-sidebarbg="color_3"][data-layout="vertical"] .deznav .menu-title {
    color: #fff; }
  [data-sidebarbg="color_3"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a i,
  [data-sidebarbg="color_3"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a i {
    color: #fff; }
  [data-sidebarbg="color_3"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box h5, [data-sidebarbg="color_3"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box .h5,
  [data-sidebarbg="color_3"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box p,
  [data-sidebarbg="color_3"][data-layout="vertical"] .deznav .plus-box h5,
  [data-sidebarbg="color_3"][data-layout="vertical"] .deznav .plus-box .h5,
  [data-sidebarbg="color_3"][data-layout="vertical"] .deznav .plus-box p {
    color: #fff; }
  [data-sidebarbg="color_3"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li > a .menu-icon,
  [data-sidebarbg="color_3"][data-layout="vertical"] .deznav .metismenu > li > a .menu-icon {
    background-color: rgba(255, 255, 255, 0.1); }
  [data-sidebarbg="color_3"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon,
  [data-sidebarbg="color_3"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon {
    background-color: var(--primary-light); }
    [data-sidebarbg="color_3"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon svg path,
    [data-sidebarbg="color_3"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon svg path {
      stroke: black; }
  [data-sidebarbg="color_3"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon,
  [data-sidebarbg="color_3"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon {
    background-color: var(--primary-light); }
    [data-sidebarbg="color_3"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon svg path,
    [data-sidebarbg="color_3"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon svg path {
      stroke: black; }
  [data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="nini"][data-layout="horizontal"] .deznav .metismenu > li.mm-active > a .menu-icon,
  [data-sidebarbg="color_3"][data-sidebar-style="nini"][data-layout="horizontal"] .deznav .metismenu > li.mm-active > a .menu-icon {
    background-color: transparent; }
  [data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="nini"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon,
  [data-sidebarbg="color_3"][data-sidebar-style="nini"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon {
    background-color: var(--primary-light); }
  [data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="nini"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon,
  [data-sidebarbg="color_3"][data-sidebar-style="nini"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon {
    background-color: var(--primary-light); }
  [data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu > li:before,
  [data-sidebarbg="color_3"][data-sidebar-style="modern"] .deznav .metismenu > li:before {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 53.12%, rgba(255, 255, 255, 0) 100%); }
  [data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu > li.mm-active > a .menu-icon,
  [data-sidebarbg="color_3"][data-sidebar-style="modern"] .deznav .metismenu > li.mm-active > a .menu-icon {
    background-color: transparent !important; }
    [data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu > li.mm-active > a .menu-icon path fill,
    [data-sidebarbg="color_3"][data-sidebar-style="modern"] .deznav .metismenu > li.mm-active > a .menu-icon path fill {
      stroke: white !important; }
  [data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu li ul,
  [data-sidebarbg="color_3"][data-sidebar-style="modern"] .deznav .metismenu li ul {
    background-color: var(--sidebar-nav-bg); }
  [data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu > li:hover > a .menu-icon svg path, [data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu > li:focus > a .menu-icon svg path,
  [data-sidebarbg="color_3"][data-sidebar-style="modern"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_3"][data-sidebar-style="modern"] .deznav .metismenu > li:focus > a .menu-icon svg path {
    stroke: var(--primary) !important; }
  [data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:before,
  [data-sidebarbg="color_3"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:before {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 53.12%, rgba(255, 255, 255, 0) 100%); }
  [data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li > a .menu-icon,
  [data-sidebarbg="color_3"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li > a .menu-icon {
    background-color: transparent; }
  [data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon svg path,
  [data-sidebarbg="color_3"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon svg path {
    stroke: white; }
  [data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon,
  [data-sidebarbg="color_3"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon {
    background-color: transparent; }
    [data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon svg path,
    [data-sidebarbg="color_3"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon svg path {
      stroke: white; }
  [data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > a,
  [data-sidebarbg="color_3"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > a {
    color: var(--primary); }
    [data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > a .menu-icon svg path,
    [data-sidebarbg="color_3"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > a .menu-icon svg path {
      stroke: white; }
  [data-sidebarbg="color_3"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:hover > ul,
  [data-sidebarbg="color_3"][data-layout="horizontal"] .deznav .metismenu > li:hover > ul {
    background-color: var(--sidebar-nav-bg); }
  [data-sidebarbg="color_3"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu li > ul ul,
  [data-sidebarbg="color_3"][data-layout="horizontal"] .deznav .metismenu li > ul ul {
    background-color: var(--sidebar-nav-bg); }
  [data-sidebarbg="color_3"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon, [data-sidebarbg="color_3"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:focus > a .menu-icon,
  [data-sidebarbg="color_3"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon,
  [data-sidebarbg="color_3"][data-layout="horizontal"] .deznav .metismenu > li:focus > a .menu-icon {
    background-color: transparent !important; }
    [data-sidebarbg="color_3"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon svg path, [data-sidebarbg="color_3"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:focus > a .menu-icon svg path,
    [data-sidebarbg="color_3"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon svg path,
    [data-sidebarbg="color_3"][data-layout="horizontal"] .deznav .metismenu > li:focus > a .menu-icon svg path {
      stroke: var(--primary) !important; }
  [data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li a:hover,
  [data-sidebarbg="color_3"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li a:hover {
    color: #fff; }
  [data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li > a > i,
  [data-sidebarbg="color_3"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li > a > i {
    background: #ff8b8b;
    color: rgba(255, 255, 255, 0.7); }
  [data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a, [data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li:hover > a,
  [data-sidebarbg="color_3"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a,
  [data-sidebarbg="color_3"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li:hover > a {
    box-shadow: none;
    background: transparent !important;
    color: #fff !important; }
    [data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a i, [data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li:hover > a i,
    [data-sidebarbg="color_3"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a i,
    [data-sidebarbg="color_3"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li:hover > a i {
      background: var(--primary);
      color: #fff !important; }
  [data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="modern"][data-sidebarbg="color_13"][data-primary="color_13"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_3"][data-sidebar-style="modern"][data-sidebarbg="color_13"][data-primary="color_13"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: white !important; }
  [data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li > a .menu-icon,
  [data-sidebarbg="color_3"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li > a .menu-icon {
    background-color: rgba(255, 255, 255, 0.1); }
  [data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li:hover .menu-icon,
  [data-sidebarbg="color_3"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li:hover .menu-icon {
    background-color: var(--primary-light) !important; }
    [data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li:hover .menu-icon svg path,
    [data-sidebarbg="color_3"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li:hover .menu-icon svg path {
      stroke: black !important; }
  [data-sidebarbg="color_3"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_3"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: black; }
  [data-sidebarbg="color_3"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu li > ul li a,
  [data-sidebarbg="color_3"][data-layout="horizontal"] .deznav .metismenu li > ul li a {
    padding: 0.5rem 1.25rem 0.5rem 1.25rem; }
  [data-sidebarbg="color_3"][data-theme-version="dark"][data-layout="horizontal"][data-sidebar-style="full"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_3"][data-layout="horizontal"][data-sidebar-style="full"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: var(--primary) !important; }
  [data-sidebarbg="color_3"][data-theme-version="dark"] [data-layout="horizontal"][data-sidebar-style="icon-hover"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_3"] [data-layout="horizontal"][data-sidebar-style="icon-hover"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: var(--primary); }
  [data-sidebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu > li:hover > a .menu-icon, [data-sidebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu > li:focus > a .menu-icon,
  [data-sidebarbg="color_3"] .deznav .metismenu > li:hover > a .menu-icon,
  [data-sidebarbg="color_3"] .deznav .metismenu > li:focus > a .menu-icon {
    background-color: var(--primary-light); }
    [data-sidebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu > li:hover > a .menu-icon svg path, [data-sidebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu > li:focus > a .menu-icon svg path,
    [data-sidebarbg="color_3"] .deznav .metismenu > li:hover > a .menu-icon svg path,
    [data-sidebarbg="color_3"] .deznav .metismenu > li:focus > a .menu-icon svg path {
      stroke: black !important; }
  [data-sidebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu a,
  [data-sidebarbg="color_3"] .deznav .metismenu a {
    color: rgba(255, 255, 255, 0.8) !important; }
  [data-sidebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu li ul a:hover, [data-sidebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu li ul a:focus, [data-sidebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu li ul a.mm-active,
  [data-sidebarbg="color_3"] .deznav .metismenu li ul a:hover,
  [data-sidebarbg="color_3"] .deznav .metismenu li ul a:focus,
  [data-sidebarbg="color_3"] .deznav .metismenu li ul a.mm-active {
    color: #fff !important; }
  [data-sidebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_3"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: var(--primary); }
  [data-sidebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu > li > a,
  [data-sidebarbg="color_3"] .deznav .metismenu > li > a {
    color: rgba(255, 255, 255, 0.85) !important; }
    [data-sidebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu > li > a i,
    [data-sidebarbg="color_3"] .deznav .metismenu > li > a i {
      color: rgba(255, 255, 255, 0.85) !important; }
  [data-sidebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a,
  [data-sidebarbg="color_3"] .deznav .metismenu > li.mm-active > a {
    color: #fff; }
    [data-sidebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a i,
    [data-sidebarbg="color_3"] .deznav .metismenu > li.mm-active > a i {
      color: #fff !important; }
    [data-sidebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a:before,
    [data-sidebarbg="color_3"] .deznav .metismenu > li.mm-active > a:before {
      background: #fff !important; }
  [data-sidebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu .has-arrow:after,
  [data-sidebarbg="color_3"] .deznav .metismenu .has-arrow:after {
    border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85) !important; }
  [data-sidebarbg="color_3"][data-theme-version="dark"] .deznav .help-desk .btn,
  [data-sidebarbg="color_3"] .deznav .help-desk .btn {
    background-color: #ffafaf;
    border-color: #ffafaf; }
  [data-sidebarbg="color_3"][data-theme-version="dark"] .deznav .header-info2 span,
  [data-sidebarbg="color_3"] .deznav .header-info2 span {
    color: #fff; }
  [data-sidebarbg="color_3"][data-theme-version="dark"] .deznav .sidebar-info,
  [data-sidebarbg="color_3"] .deznav .sidebar-info {
    color: #fff; }
    [data-sidebarbg="color_3"][data-theme-version="dark"] .deznav .sidebar-info i,
    [data-sidebarbg="color_3"] .deznav .sidebar-info i {
      color: #fff; }
  [data-sidebarbg="color_3"][data-theme-version="dark"] .plus-box,
  [data-sidebarbg="color_3"] .plus-box {
    background-color: #ffafaf; }
  [data-sidebarbg="color_3"][data-theme-version="dark"] .copyright,
  [data-sidebarbg="color_3"] .copyright {
    color: #fff; }

[data-sidebarbg="color_4"][data-theme-version="dark"],
[data-sidebarbg="color_4"] {
  --sidebar-bg: #0836a6;
  --nav-headbg: #0836a6;
  --headerbg:   #0836a6;
  --sidebar-nav-bg:#093ebe;
  /* &[data-sidebar-style="full"][data-layout="vertical"]{
				.deznav .metismenu > li.mm-active > a,
				.deznav .metismenu > li:hover > a{
						background:transparent;
				}	
			} */ }
  [data-sidebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu > li:hover > a .menu-icon svg path, [data-sidebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu > li:focus > a .menu-icon svg path,
  [data-sidebarbg="color_4"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_4"] .deznav .metismenu > li:focus > a .menu-icon svg path {
    stroke: white; }
  [data-sidebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a .menu-icon svg path,
  [data-sidebarbg="color_4"] .deznav .metismenu > li.mm-active > a .menu-icon svg path {
    stroke: white; }
  [data-sidebarbg="color_4"][data-theme-version="dark"] .switch-btn,
  [data-sidebarbg="color_4"] .switch-btn {
    border-color: rgba(255, 255, 255, 0.2); }
    [data-sidebarbg="color_4"][data-theme-version="dark"] .switch-btn svg path,
    [data-sidebarbg="color_4"] .switch-btn svg path {
      stroke: #fff; }
    [data-sidebarbg="color_4"][data-theme-version="dark"] .switch-btn span,
    [data-sidebarbg="color_4"] .switch-btn span {
      color: #fff; }
  [data-sidebarbg="color_4"][data-theme-version="dark"] .nav-header .brand-title path,
  [data-sidebarbg="color_4"] .nav-header .brand-title path {
    fill: #fff; }
  [data-sidebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu ul a:before,
  [data-sidebarbg="color_4"] .deznav .metismenu ul a:before {
    background: rgba(255, 255, 255, 0.5); }
  [data-sidebarbg="color_4"][data-theme-version="dark"] .deznav .menu-icon svg path,
  [data-sidebarbg="color_4"][data-theme-version="dark"] .deznav .menu-icon svg ellipse,
  [data-sidebarbg="color_4"] .deznav .menu-icon svg path,
  [data-sidebarbg="color_4"] .deznav .menu-icon svg ellipse {
    stroke: #fff; }
  [data-sidebarbg="color_4"][data-theme-version="dark"][data-layout="horizontal"][data-primary="color_13"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_4"][data-layout="horizontal"][data-primary="color_13"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: white !important; }
  [data-sidebarbg="color_4"][data-theme-version="dark"][data-layout="horizontal"],
  [data-sidebarbg="color_4"][data-layout="horizontal"] {
    --headerbg:#f4f4f5; }
  [data-sidebarbg="color_4"][data-theme-version="dark"][data-layout="vertical"] .deznav .menu-title,
  [data-sidebarbg="color_4"][data-layout="vertical"] .deznav .menu-title {
    color: #fff; }
  [data-sidebarbg="color_4"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a i,
  [data-sidebarbg="color_4"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a i {
    color: #fff; }
  [data-sidebarbg="color_4"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box h5, [data-sidebarbg="color_4"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box .h5,
  [data-sidebarbg="color_4"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box p,
  [data-sidebarbg="color_4"][data-layout="vertical"] .deznav .plus-box h5,
  [data-sidebarbg="color_4"][data-layout="vertical"] .deznav .plus-box .h5,
  [data-sidebarbg="color_4"][data-layout="vertical"] .deznav .plus-box p {
    color: #fff; }
  [data-sidebarbg="color_4"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li > a .menu-icon,
  [data-sidebarbg="color_4"][data-layout="vertical"] .deznav .metismenu > li > a .menu-icon {
    background-color: rgba(255, 255, 255, 0.1); }
  [data-sidebarbg="color_4"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon,
  [data-sidebarbg="color_4"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon {
    background-color: var(--primary-light); }
    [data-sidebarbg="color_4"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon svg path,
    [data-sidebarbg="color_4"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon svg path {
      stroke: black; }
  [data-sidebarbg="color_4"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon,
  [data-sidebarbg="color_4"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon {
    background-color: var(--primary-light); }
    [data-sidebarbg="color_4"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon svg path,
    [data-sidebarbg="color_4"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon svg path {
      stroke: black; }
  [data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="nini"][data-layout="horizontal"] .deznav .metismenu > li.mm-active > a .menu-icon,
  [data-sidebarbg="color_4"][data-sidebar-style="nini"][data-layout="horizontal"] .deznav .metismenu > li.mm-active > a .menu-icon {
    background-color: transparent; }
  [data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="nini"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon,
  [data-sidebarbg="color_4"][data-sidebar-style="nini"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon {
    background-color: var(--primary-light); }
  [data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="nini"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon,
  [data-sidebarbg="color_4"][data-sidebar-style="nini"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon {
    background-color: var(--primary-light); }
  [data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu > li:before,
  [data-sidebarbg="color_4"][data-sidebar-style="modern"] .deznav .metismenu > li:before {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 53.12%, rgba(255, 255, 255, 0) 100%); }
  [data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu > li.mm-active > a .menu-icon,
  [data-sidebarbg="color_4"][data-sidebar-style="modern"] .deznav .metismenu > li.mm-active > a .menu-icon {
    background-color: transparent !important; }
    [data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu > li.mm-active > a .menu-icon path fill,
    [data-sidebarbg="color_4"][data-sidebar-style="modern"] .deznav .metismenu > li.mm-active > a .menu-icon path fill {
      stroke: white !important; }
  [data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu li ul,
  [data-sidebarbg="color_4"][data-sidebar-style="modern"] .deznav .metismenu li ul {
    background-color: var(--sidebar-nav-bg); }
  [data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu > li:hover > a .menu-icon svg path, [data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu > li:focus > a .menu-icon svg path,
  [data-sidebarbg="color_4"][data-sidebar-style="modern"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_4"][data-sidebar-style="modern"] .deznav .metismenu > li:focus > a .menu-icon svg path {
    stroke: var(--primary) !important; }
  [data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:before,
  [data-sidebarbg="color_4"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:before {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 53.12%, rgba(255, 255, 255, 0) 100%); }
  [data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li > a .menu-icon,
  [data-sidebarbg="color_4"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li > a .menu-icon {
    background-color: transparent; }
  [data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon svg path,
  [data-sidebarbg="color_4"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon svg path {
    stroke: white; }
  [data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon,
  [data-sidebarbg="color_4"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon {
    background-color: transparent; }
    [data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon svg path,
    [data-sidebarbg="color_4"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon svg path {
      stroke: white; }
  [data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > a,
  [data-sidebarbg="color_4"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > a {
    color: var(--primary); }
    [data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > a .menu-icon svg path,
    [data-sidebarbg="color_4"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > a .menu-icon svg path {
      stroke: white; }
  [data-sidebarbg="color_4"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:hover > ul,
  [data-sidebarbg="color_4"][data-layout="horizontal"] .deznav .metismenu > li:hover > ul {
    background-color: var(--sidebar-nav-bg); }
  [data-sidebarbg="color_4"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu li > ul ul,
  [data-sidebarbg="color_4"][data-layout="horizontal"] .deznav .metismenu li > ul ul {
    background-color: var(--sidebar-nav-bg); }
  [data-sidebarbg="color_4"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon, [data-sidebarbg="color_4"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:focus > a .menu-icon,
  [data-sidebarbg="color_4"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon,
  [data-sidebarbg="color_4"][data-layout="horizontal"] .deznav .metismenu > li:focus > a .menu-icon {
    background-color: transparent !important; }
    [data-sidebarbg="color_4"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon svg path, [data-sidebarbg="color_4"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:focus > a .menu-icon svg path,
    [data-sidebarbg="color_4"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon svg path,
    [data-sidebarbg="color_4"][data-layout="horizontal"] .deznav .metismenu > li:focus > a .menu-icon svg path {
      stroke: var(--primary) !important; }
  [data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li a:hover,
  [data-sidebarbg="color_4"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li a:hover {
    color: #fff; }
  [data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li > a > i,
  [data-sidebarbg="color_4"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li > a > i {
    background: #90affa;
    color: rgba(255, 255, 255, 0.7); }
  [data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a, [data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li:hover > a,
  [data-sidebarbg="color_4"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a,
  [data-sidebarbg="color_4"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li:hover > a {
    box-shadow: none;
    background: transparent !important;
    color: #fff !important; }
    [data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a i, [data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li:hover > a i,
    [data-sidebarbg="color_4"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a i,
    [data-sidebarbg="color_4"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li:hover > a i {
      background: var(--primary);
      color: #fff !important; }
  [data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="modern"][data-sidebarbg="color_13"][data-primary="color_13"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_4"][data-sidebar-style="modern"][data-sidebarbg="color_13"][data-primary="color_13"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: white !important; }
  [data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li > a .menu-icon,
  [data-sidebarbg="color_4"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li > a .menu-icon {
    background-color: rgba(255, 255, 255, 0.1); }
  [data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li:hover .menu-icon,
  [data-sidebarbg="color_4"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li:hover .menu-icon {
    background-color: var(--primary-light) !important; }
    [data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li:hover .menu-icon svg path,
    [data-sidebarbg="color_4"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li:hover .menu-icon svg path {
      stroke: black !important; }
  [data-sidebarbg="color_4"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_4"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: black; }
  [data-sidebarbg="color_4"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu li > ul li a,
  [data-sidebarbg="color_4"][data-layout="horizontal"] .deznav .metismenu li > ul li a {
    padding: 0.5rem 1.25rem 0.5rem 1.25rem; }
  [data-sidebarbg="color_4"][data-theme-version="dark"][data-layout="horizontal"][data-sidebar-style="full"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_4"][data-layout="horizontal"][data-sidebar-style="full"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: var(--primary) !important; }
  [data-sidebarbg="color_4"][data-theme-version="dark"] [data-layout="horizontal"][data-sidebar-style="icon-hover"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_4"] [data-layout="horizontal"][data-sidebar-style="icon-hover"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: var(--primary); }
  [data-sidebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu > li:hover > a .menu-icon, [data-sidebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu > li:focus > a .menu-icon,
  [data-sidebarbg="color_4"] .deznav .metismenu > li:hover > a .menu-icon,
  [data-sidebarbg="color_4"] .deznav .metismenu > li:focus > a .menu-icon {
    background-color: var(--primary-light); }
    [data-sidebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu > li:hover > a .menu-icon svg path, [data-sidebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu > li:focus > a .menu-icon svg path,
    [data-sidebarbg="color_4"] .deznav .metismenu > li:hover > a .menu-icon svg path,
    [data-sidebarbg="color_4"] .deznav .metismenu > li:focus > a .menu-icon svg path {
      stroke: black !important; }
  [data-sidebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu a,
  [data-sidebarbg="color_4"] .deznav .metismenu a {
    color: rgba(255, 255, 255, 0.8) !important; }
  [data-sidebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu li ul a:hover, [data-sidebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu li ul a:focus, [data-sidebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu li ul a.mm-active,
  [data-sidebarbg="color_4"] .deznav .metismenu li ul a:hover,
  [data-sidebarbg="color_4"] .deznav .metismenu li ul a:focus,
  [data-sidebarbg="color_4"] .deznav .metismenu li ul a.mm-active {
    color: #fff !important; }
  [data-sidebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_4"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: var(--primary); }
  [data-sidebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu > li > a,
  [data-sidebarbg="color_4"] .deznav .metismenu > li > a {
    color: rgba(255, 255, 255, 0.85) !important; }
    [data-sidebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu > li > a i,
    [data-sidebarbg="color_4"] .deznav .metismenu > li > a i {
      color: rgba(255, 255, 255, 0.85) !important; }
  [data-sidebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a,
  [data-sidebarbg="color_4"] .deznav .metismenu > li.mm-active > a {
    color: #fff; }
    [data-sidebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a i,
    [data-sidebarbg="color_4"] .deznav .metismenu > li.mm-active > a i {
      color: #fff !important; }
    [data-sidebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a:before,
    [data-sidebarbg="color_4"] .deznav .metismenu > li.mm-active > a:before {
      background: #fff !important; }
  [data-sidebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu .has-arrow:after,
  [data-sidebarbg="color_4"] .deznav .metismenu .has-arrow:after {
    border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85) !important; }
  [data-sidebarbg="color_4"][data-theme-version="dark"] .deznav .help-desk .btn,
  [data-sidebarbg="color_4"] .deznav .help-desk .btn {
    background-color: #b2c7fb;
    border-color: #b2c7fb; }
  [data-sidebarbg="color_4"][data-theme-version="dark"] .deznav .header-info2 span,
  [data-sidebarbg="color_4"] .deznav .header-info2 span {
    color: #fff; }
  [data-sidebarbg="color_4"][data-theme-version="dark"] .deznav .sidebar-info,
  [data-sidebarbg="color_4"] .deznav .sidebar-info {
    color: #fff; }
    [data-sidebarbg="color_4"][data-theme-version="dark"] .deznav .sidebar-info i,
    [data-sidebarbg="color_4"] .deznav .sidebar-info i {
      color: #fff; }
  [data-sidebarbg="color_4"][data-theme-version="dark"] .plus-box,
  [data-sidebarbg="color_4"] .plus-box {
    background-color: #b2c7fb; }
  [data-sidebarbg="color_4"][data-theme-version="dark"] .copyright,
  [data-sidebarbg="color_4"] .copyright {
    color: #fff; }

[data-sidebarbg="color_5"][data-theme-version="dark"],
[data-sidebarbg="color_5"] {
  --sidebar-bg: #134438;
  --nav-headbg: #134438;
  --headerbg:   #134438;
  --sidebar-nav-bg:#185849;
  /* &[data-sidebar-style="full"][data-layout="vertical"]{
				.deznav .metismenu > li.mm-active > a,
				.deznav .metismenu > li:hover > a{
						background:transparent;
				}	
			} */ }
  [data-sidebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu > li:hover > a .menu-icon svg path, [data-sidebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu > li:focus > a .menu-icon svg path,
  [data-sidebarbg="color_5"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_5"] .deznav .metismenu > li:focus > a .menu-icon svg path {
    stroke: white; }
  [data-sidebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a .menu-icon svg path,
  [data-sidebarbg="color_5"] .deznav .metismenu > li.mm-active > a .menu-icon svg path {
    stroke: white; }
  [data-sidebarbg="color_5"][data-theme-version="dark"] .switch-btn,
  [data-sidebarbg="color_5"] .switch-btn {
    border-color: rgba(255, 255, 255, 0.2); }
    [data-sidebarbg="color_5"][data-theme-version="dark"] .switch-btn svg path,
    [data-sidebarbg="color_5"] .switch-btn svg path {
      stroke: #fff; }
    [data-sidebarbg="color_5"][data-theme-version="dark"] .switch-btn span,
    [data-sidebarbg="color_5"] .switch-btn span {
      color: #fff; }
  [data-sidebarbg="color_5"][data-theme-version="dark"] .nav-header .brand-title path,
  [data-sidebarbg="color_5"] .nav-header .brand-title path {
    fill: #fff; }
  [data-sidebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu ul a:before,
  [data-sidebarbg="color_5"] .deznav .metismenu ul a:before {
    background: rgba(255, 255, 255, 0.5); }
  [data-sidebarbg="color_5"][data-theme-version="dark"] .deznav .menu-icon svg path,
  [data-sidebarbg="color_5"][data-theme-version="dark"] .deznav .menu-icon svg ellipse,
  [data-sidebarbg="color_5"] .deznav .menu-icon svg path,
  [data-sidebarbg="color_5"] .deznav .menu-icon svg ellipse {
    stroke: #fff; }
  [data-sidebarbg="color_5"][data-theme-version="dark"][data-layout="horizontal"][data-primary="color_13"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_5"][data-layout="horizontal"][data-primary="color_13"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: white !important; }
  [data-sidebarbg="color_5"][data-theme-version="dark"][data-layout="horizontal"],
  [data-sidebarbg="color_5"][data-layout="horizontal"] {
    --headerbg:#f4f4f5; }
  [data-sidebarbg="color_5"][data-theme-version="dark"][data-layout="vertical"] .deznav .menu-title,
  [data-sidebarbg="color_5"][data-layout="vertical"] .deznav .menu-title {
    color: #fff; }
  [data-sidebarbg="color_5"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a i,
  [data-sidebarbg="color_5"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a i {
    color: #fff; }
  [data-sidebarbg="color_5"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box h5, [data-sidebarbg="color_5"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box .h5,
  [data-sidebarbg="color_5"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box p,
  [data-sidebarbg="color_5"][data-layout="vertical"] .deznav .plus-box h5,
  [data-sidebarbg="color_5"][data-layout="vertical"] .deznav .plus-box .h5,
  [data-sidebarbg="color_5"][data-layout="vertical"] .deznav .plus-box p {
    color: #fff; }
  [data-sidebarbg="color_5"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li > a .menu-icon,
  [data-sidebarbg="color_5"][data-layout="vertical"] .deznav .metismenu > li > a .menu-icon {
    background-color: rgba(255, 255, 255, 0.1); }
  [data-sidebarbg="color_5"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon,
  [data-sidebarbg="color_5"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon {
    background-color: var(--primary-light); }
    [data-sidebarbg="color_5"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon svg path,
    [data-sidebarbg="color_5"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon svg path {
      stroke: black; }
  [data-sidebarbg="color_5"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon,
  [data-sidebarbg="color_5"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon {
    background-color: var(--primary-light); }
    [data-sidebarbg="color_5"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon svg path,
    [data-sidebarbg="color_5"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon svg path {
      stroke: black; }
  [data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="nini"][data-layout="horizontal"] .deznav .metismenu > li.mm-active > a .menu-icon,
  [data-sidebarbg="color_5"][data-sidebar-style="nini"][data-layout="horizontal"] .deznav .metismenu > li.mm-active > a .menu-icon {
    background-color: transparent; }
  [data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="nini"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon,
  [data-sidebarbg="color_5"][data-sidebar-style="nini"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon {
    background-color: var(--primary-light); }
  [data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="nini"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon,
  [data-sidebarbg="color_5"][data-sidebar-style="nini"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon {
    background-color: var(--primary-light); }
  [data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu > li:before,
  [data-sidebarbg="color_5"][data-sidebar-style="modern"] .deznav .metismenu > li:before {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 53.12%, rgba(255, 255, 255, 0) 100%); }
  [data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu > li.mm-active > a .menu-icon,
  [data-sidebarbg="color_5"][data-sidebar-style="modern"] .deznav .metismenu > li.mm-active > a .menu-icon {
    background-color: transparent !important; }
    [data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu > li.mm-active > a .menu-icon path fill,
    [data-sidebarbg="color_5"][data-sidebar-style="modern"] .deznav .metismenu > li.mm-active > a .menu-icon path fill {
      stroke: white !important; }
  [data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu li ul,
  [data-sidebarbg="color_5"][data-sidebar-style="modern"] .deznav .metismenu li ul {
    background-color: var(--sidebar-nav-bg); }
  [data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu > li:hover > a .menu-icon svg path, [data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu > li:focus > a .menu-icon svg path,
  [data-sidebarbg="color_5"][data-sidebar-style="modern"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_5"][data-sidebar-style="modern"] .deznav .metismenu > li:focus > a .menu-icon svg path {
    stroke: var(--primary) !important; }
  [data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:before,
  [data-sidebarbg="color_5"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:before {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 53.12%, rgba(255, 255, 255, 0) 100%); }
  [data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li > a .menu-icon,
  [data-sidebarbg="color_5"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li > a .menu-icon {
    background-color: transparent; }
  [data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon svg path,
  [data-sidebarbg="color_5"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon svg path {
    stroke: white; }
  [data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon,
  [data-sidebarbg="color_5"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon {
    background-color: transparent; }
    [data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon svg path,
    [data-sidebarbg="color_5"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon svg path {
      stroke: white; }
  [data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > a,
  [data-sidebarbg="color_5"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > a {
    color: var(--primary); }
    [data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > a .menu-icon svg path,
    [data-sidebarbg="color_5"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > a .menu-icon svg path {
      stroke: white; }
  [data-sidebarbg="color_5"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:hover > ul,
  [data-sidebarbg="color_5"][data-layout="horizontal"] .deznav .metismenu > li:hover > ul {
    background-color: var(--sidebar-nav-bg); }
  [data-sidebarbg="color_5"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu li > ul ul,
  [data-sidebarbg="color_5"][data-layout="horizontal"] .deznav .metismenu li > ul ul {
    background-color: var(--sidebar-nav-bg); }
  [data-sidebarbg="color_5"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon, [data-sidebarbg="color_5"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:focus > a .menu-icon,
  [data-sidebarbg="color_5"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon,
  [data-sidebarbg="color_5"][data-layout="horizontal"] .deznav .metismenu > li:focus > a .menu-icon {
    background-color: transparent !important; }
    [data-sidebarbg="color_5"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon svg path, [data-sidebarbg="color_5"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:focus > a .menu-icon svg path,
    [data-sidebarbg="color_5"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon svg path,
    [data-sidebarbg="color_5"][data-layout="horizontal"] .deznav .metismenu > li:focus > a .menu-icon svg path {
      stroke: var(--primary) !important; }
  [data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li a:hover,
  [data-sidebarbg="color_5"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li a:hover {
    color: #fff; }
  [data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li > a > i,
  [data-sidebarbg="color_5"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li > a > i {
    background: #5fd3b7;
    color: rgba(255, 255, 255, 0.7); }
  [data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a, [data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li:hover > a,
  [data-sidebarbg="color_5"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a,
  [data-sidebarbg="color_5"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li:hover > a {
    box-shadow: none;
    background: transparent !important;
    color: #fff !important; }
    [data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a i, [data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li:hover > a i,
    [data-sidebarbg="color_5"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a i,
    [data-sidebarbg="color_5"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li:hover > a i {
      background: var(--primary);
      color: #fff !important; }
  [data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="modern"][data-sidebarbg="color_13"][data-primary="color_13"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_5"][data-sidebar-style="modern"][data-sidebarbg="color_13"][data-primary="color_13"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: white !important; }
  [data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li > a .menu-icon,
  [data-sidebarbg="color_5"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li > a .menu-icon {
    background-color: rgba(255, 255, 255, 0.1); }
  [data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li:hover .menu-icon,
  [data-sidebarbg="color_5"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li:hover .menu-icon {
    background-color: var(--primary-light) !important; }
    [data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li:hover .menu-icon svg path,
    [data-sidebarbg="color_5"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li:hover .menu-icon svg path {
      stroke: black !important; }
  [data-sidebarbg="color_5"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_5"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: black; }
  [data-sidebarbg="color_5"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu li > ul li a,
  [data-sidebarbg="color_5"][data-layout="horizontal"] .deznav .metismenu li > ul li a {
    padding: 0.5rem 1.25rem 0.5rem 1.25rem; }
  [data-sidebarbg="color_5"][data-theme-version="dark"][data-layout="horizontal"][data-sidebar-style="full"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_5"][data-layout="horizontal"][data-sidebar-style="full"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: var(--primary) !important; }
  [data-sidebarbg="color_5"][data-theme-version="dark"] [data-layout="horizontal"][data-sidebar-style="icon-hover"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_5"] [data-layout="horizontal"][data-sidebar-style="icon-hover"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: var(--primary); }
  [data-sidebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu > li:hover > a .menu-icon, [data-sidebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu > li:focus > a .menu-icon,
  [data-sidebarbg="color_5"] .deznav .metismenu > li:hover > a .menu-icon,
  [data-sidebarbg="color_5"] .deznav .metismenu > li:focus > a .menu-icon {
    background-color: var(--primary-light); }
    [data-sidebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu > li:hover > a .menu-icon svg path, [data-sidebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu > li:focus > a .menu-icon svg path,
    [data-sidebarbg="color_5"] .deznav .metismenu > li:hover > a .menu-icon svg path,
    [data-sidebarbg="color_5"] .deznav .metismenu > li:focus > a .menu-icon svg path {
      stroke: black !important; }
  [data-sidebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu a,
  [data-sidebarbg="color_5"] .deznav .metismenu a {
    color: rgba(255, 255, 255, 0.8) !important; }
  [data-sidebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu li ul a:hover, [data-sidebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu li ul a:focus, [data-sidebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu li ul a.mm-active,
  [data-sidebarbg="color_5"] .deznav .metismenu li ul a:hover,
  [data-sidebarbg="color_5"] .deznav .metismenu li ul a:focus,
  [data-sidebarbg="color_5"] .deznav .metismenu li ul a.mm-active {
    color: #fff !important; }
  [data-sidebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_5"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: var(--primary); }
  [data-sidebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu > li > a,
  [data-sidebarbg="color_5"] .deznav .metismenu > li > a {
    color: rgba(255, 255, 255, 0.85) !important; }
    [data-sidebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu > li > a i,
    [data-sidebarbg="color_5"] .deznav .metismenu > li > a i {
      color: rgba(255, 255, 255, 0.85) !important; }
  [data-sidebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a,
  [data-sidebarbg="color_5"] .deznav .metismenu > li.mm-active > a {
    color: #fff; }
    [data-sidebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a i,
    [data-sidebarbg="color_5"] .deznav .metismenu > li.mm-active > a i {
      color: #fff !important; }
    [data-sidebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a:before,
    [data-sidebarbg="color_5"] .deznav .metismenu > li.mm-active > a:before {
      background: #fff !important; }
  [data-sidebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu .has-arrow:after,
  [data-sidebarbg="color_5"] .deznav .metismenu .has-arrow:after {
    border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85) !important; }
  [data-sidebarbg="color_5"][data-theme-version="dark"] .deznav .help-desk .btn,
  [data-sidebarbg="color_5"] .deznav .help-desk .btn {
    background-color: #7bdbc4;
    border-color: #7bdbc4; }
  [data-sidebarbg="color_5"][data-theme-version="dark"] .deznav .header-info2 span,
  [data-sidebarbg="color_5"] .deznav .header-info2 span {
    color: #fff; }
  [data-sidebarbg="color_5"][data-theme-version="dark"] .deznav .sidebar-info,
  [data-sidebarbg="color_5"] .deznav .sidebar-info {
    color: #fff; }
    [data-sidebarbg="color_5"][data-theme-version="dark"] .deznav .sidebar-info i,
    [data-sidebarbg="color_5"] .deznav .sidebar-info i {
      color: #fff; }
  [data-sidebarbg="color_5"][data-theme-version="dark"] .plus-box,
  [data-sidebarbg="color_5"] .plus-box {
    background-color: #7bdbc4; }
  [data-sidebarbg="color_5"][data-theme-version="dark"] .copyright,
  [data-sidebarbg="color_5"] .copyright {
    color: #fff; }

[data-sidebarbg="color_6"][data-theme-version="dark"],
[data-sidebarbg="color_6"] {
  --sidebar-bg: #322a02;
  --nav-headbg: #322a02;
  --headerbg:   #322a02;
  --sidebar-nav-bg:#4a3f03;
  /* &[data-sidebar-style="full"][data-layout="vertical"]{
				.deznav .metismenu > li.mm-active > a,
				.deznav .metismenu > li:hover > a{
						background:transparent;
				}	
			} */ }
  [data-sidebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu > li:hover > a .menu-icon svg path, [data-sidebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu > li:focus > a .menu-icon svg path,
  [data-sidebarbg="color_6"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_6"] .deznav .metismenu > li:focus > a .menu-icon svg path {
    stroke: white; }
  [data-sidebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a .menu-icon svg path,
  [data-sidebarbg="color_6"] .deznav .metismenu > li.mm-active > a .menu-icon svg path {
    stroke: white; }
  [data-sidebarbg="color_6"][data-theme-version="dark"] .switch-btn,
  [data-sidebarbg="color_6"] .switch-btn {
    border-color: rgba(255, 255, 255, 0.2); }
    [data-sidebarbg="color_6"][data-theme-version="dark"] .switch-btn svg path,
    [data-sidebarbg="color_6"] .switch-btn svg path {
      stroke: #fff; }
    [data-sidebarbg="color_6"][data-theme-version="dark"] .switch-btn span,
    [data-sidebarbg="color_6"] .switch-btn span {
      color: #fff; }
  [data-sidebarbg="color_6"][data-theme-version="dark"] .nav-header .brand-title path,
  [data-sidebarbg="color_6"] .nav-header .brand-title path {
    fill: #fff; }
  [data-sidebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu ul a:before,
  [data-sidebarbg="color_6"] .deznav .metismenu ul a:before {
    background: rgba(255, 255, 255, 0.5); }
  [data-sidebarbg="color_6"][data-theme-version="dark"] .deznav .menu-icon svg path,
  [data-sidebarbg="color_6"][data-theme-version="dark"] .deznav .menu-icon svg ellipse,
  [data-sidebarbg="color_6"] .deznav .menu-icon svg path,
  [data-sidebarbg="color_6"] .deznav .menu-icon svg ellipse {
    stroke: #fff; }
  [data-sidebarbg="color_6"][data-theme-version="dark"][data-layout="horizontal"][data-primary="color_13"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_6"][data-layout="horizontal"][data-primary="color_13"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: white !important; }
  [data-sidebarbg="color_6"][data-theme-version="dark"][data-layout="horizontal"],
  [data-sidebarbg="color_6"][data-layout="horizontal"] {
    --headerbg:#f4f4f5; }
  [data-sidebarbg="color_6"][data-theme-version="dark"][data-layout="vertical"] .deznav .menu-title,
  [data-sidebarbg="color_6"][data-layout="vertical"] .deznav .menu-title {
    color: #fff; }
  [data-sidebarbg="color_6"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a i,
  [data-sidebarbg="color_6"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a i {
    color: #fff; }
  [data-sidebarbg="color_6"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box h5, [data-sidebarbg="color_6"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box .h5,
  [data-sidebarbg="color_6"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box p,
  [data-sidebarbg="color_6"][data-layout="vertical"] .deznav .plus-box h5,
  [data-sidebarbg="color_6"][data-layout="vertical"] .deznav .plus-box .h5,
  [data-sidebarbg="color_6"][data-layout="vertical"] .deznav .plus-box p {
    color: #fff; }
  [data-sidebarbg="color_6"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li > a .menu-icon,
  [data-sidebarbg="color_6"][data-layout="vertical"] .deznav .metismenu > li > a .menu-icon {
    background-color: rgba(255, 255, 255, 0.1); }
  [data-sidebarbg="color_6"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon,
  [data-sidebarbg="color_6"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon {
    background-color: var(--primary-light); }
    [data-sidebarbg="color_6"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon svg path,
    [data-sidebarbg="color_6"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon svg path {
      stroke: black; }
  [data-sidebarbg="color_6"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon,
  [data-sidebarbg="color_6"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon {
    background-color: var(--primary-light); }
    [data-sidebarbg="color_6"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon svg path,
    [data-sidebarbg="color_6"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon svg path {
      stroke: black; }
  [data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="nini"][data-layout="horizontal"] .deznav .metismenu > li.mm-active > a .menu-icon,
  [data-sidebarbg="color_6"][data-sidebar-style="nini"][data-layout="horizontal"] .deznav .metismenu > li.mm-active > a .menu-icon {
    background-color: transparent; }
  [data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="nini"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon,
  [data-sidebarbg="color_6"][data-sidebar-style="nini"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon {
    background-color: var(--primary-light); }
  [data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="nini"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon,
  [data-sidebarbg="color_6"][data-sidebar-style="nini"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon {
    background-color: var(--primary-light); }
  [data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu > li:before,
  [data-sidebarbg="color_6"][data-sidebar-style="modern"] .deznav .metismenu > li:before {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 53.12%, rgba(255, 255, 255, 0) 100%); }
  [data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu > li.mm-active > a .menu-icon,
  [data-sidebarbg="color_6"][data-sidebar-style="modern"] .deznav .metismenu > li.mm-active > a .menu-icon {
    background-color: transparent !important; }
    [data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu > li.mm-active > a .menu-icon path fill,
    [data-sidebarbg="color_6"][data-sidebar-style="modern"] .deznav .metismenu > li.mm-active > a .menu-icon path fill {
      stroke: white !important; }
  [data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu li ul,
  [data-sidebarbg="color_6"][data-sidebar-style="modern"] .deznav .metismenu li ul {
    background-color: var(--sidebar-nav-bg); }
  [data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu > li:hover > a .menu-icon svg path, [data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu > li:focus > a .menu-icon svg path,
  [data-sidebarbg="color_6"][data-sidebar-style="modern"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_6"][data-sidebar-style="modern"] .deznav .metismenu > li:focus > a .menu-icon svg path {
    stroke: var(--primary) !important; }
  [data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:before,
  [data-sidebarbg="color_6"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:before {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 53.12%, rgba(255, 255, 255, 0) 100%); }
  [data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li > a .menu-icon,
  [data-sidebarbg="color_6"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li > a .menu-icon {
    background-color: transparent; }
  [data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon svg path,
  [data-sidebarbg="color_6"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon svg path {
    stroke: white; }
  [data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon,
  [data-sidebarbg="color_6"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon {
    background-color: transparent; }
    [data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon svg path,
    [data-sidebarbg="color_6"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon svg path {
      stroke: white; }
  [data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > a,
  [data-sidebarbg="color_6"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > a {
    color: var(--primary); }
    [data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > a .menu-icon svg path,
    [data-sidebarbg="color_6"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > a .menu-icon svg path {
      stroke: white; }
  [data-sidebarbg="color_6"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:hover > ul,
  [data-sidebarbg="color_6"][data-layout="horizontal"] .deznav .metismenu > li:hover > ul {
    background-color: var(--sidebar-nav-bg); }
  [data-sidebarbg="color_6"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu li > ul ul,
  [data-sidebarbg="color_6"][data-layout="horizontal"] .deznav .metismenu li > ul ul {
    background-color: var(--sidebar-nav-bg); }
  [data-sidebarbg="color_6"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon, [data-sidebarbg="color_6"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:focus > a .menu-icon,
  [data-sidebarbg="color_6"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon,
  [data-sidebarbg="color_6"][data-layout="horizontal"] .deznav .metismenu > li:focus > a .menu-icon {
    background-color: transparent !important; }
    [data-sidebarbg="color_6"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon svg path, [data-sidebarbg="color_6"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:focus > a .menu-icon svg path,
    [data-sidebarbg="color_6"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon svg path,
    [data-sidebarbg="color_6"][data-layout="horizontal"] .deznav .metismenu > li:focus > a .menu-icon svg path {
      stroke: var(--primary) !important; }
  [data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li a:hover,
  [data-sidebarbg="color_6"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li a:hover {
    color: #fff; }
  [data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li > a > i,
  [data-sidebarbg="color_6"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li > a > i {
    background: #f5d21b;
    color: rgba(255, 255, 255, 0.7); }
  [data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a, [data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li:hover > a,
  [data-sidebarbg="color_6"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a,
  [data-sidebarbg="color_6"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li:hover > a {
    box-shadow: none;
    background: transparent !important;
    color: #fff !important; }
    [data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a i, [data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li:hover > a i,
    [data-sidebarbg="color_6"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a i,
    [data-sidebarbg="color_6"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li:hover > a i {
      background: var(--primary);
      color: #fff !important; }
  [data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="modern"][data-sidebarbg="color_13"][data-primary="color_13"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_6"][data-sidebar-style="modern"][data-sidebarbg="color_13"][data-primary="color_13"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: white !important; }
  [data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li > a .menu-icon,
  [data-sidebarbg="color_6"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li > a .menu-icon {
    background-color: rgba(255, 255, 255, 0.1); }
  [data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li:hover .menu-icon,
  [data-sidebarbg="color_6"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li:hover .menu-icon {
    background-color: var(--primary-light) !important; }
    [data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li:hover .menu-icon svg path,
    [data-sidebarbg="color_6"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li:hover .menu-icon svg path {
      stroke: black !important; }
  [data-sidebarbg="color_6"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_6"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: black; }
  [data-sidebarbg="color_6"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu li > ul li a,
  [data-sidebarbg="color_6"][data-layout="horizontal"] .deznav .metismenu li > ul li a {
    padding: 0.5rem 1.25rem 0.5rem 1.25rem; }
  [data-sidebarbg="color_6"][data-theme-version="dark"][data-layout="horizontal"][data-sidebar-style="full"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_6"][data-layout="horizontal"][data-sidebar-style="full"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: var(--primary) !important; }
  [data-sidebarbg="color_6"][data-theme-version="dark"] [data-layout="horizontal"][data-sidebar-style="icon-hover"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_6"] [data-layout="horizontal"][data-sidebar-style="icon-hover"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: var(--primary); }
  [data-sidebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu > li:hover > a .menu-icon, [data-sidebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu > li:focus > a .menu-icon,
  [data-sidebarbg="color_6"] .deznav .metismenu > li:hover > a .menu-icon,
  [data-sidebarbg="color_6"] .deznav .metismenu > li:focus > a .menu-icon {
    background-color: var(--primary-light); }
    [data-sidebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu > li:hover > a .menu-icon svg path, [data-sidebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu > li:focus > a .menu-icon svg path,
    [data-sidebarbg="color_6"] .deznav .metismenu > li:hover > a .menu-icon svg path,
    [data-sidebarbg="color_6"] .deznav .metismenu > li:focus > a .menu-icon svg path {
      stroke: black !important; }
  [data-sidebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu a,
  [data-sidebarbg="color_6"] .deznav .metismenu a {
    color: rgba(255, 255, 255, 0.8) !important; }
  [data-sidebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu li ul a:hover, [data-sidebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu li ul a:focus, [data-sidebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu li ul a.mm-active,
  [data-sidebarbg="color_6"] .deznav .metismenu li ul a:hover,
  [data-sidebarbg="color_6"] .deznav .metismenu li ul a:focus,
  [data-sidebarbg="color_6"] .deznav .metismenu li ul a.mm-active {
    color: #fff !important; }
  [data-sidebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_6"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: var(--primary); }
  [data-sidebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu > li > a,
  [data-sidebarbg="color_6"] .deznav .metismenu > li > a {
    color: rgba(255, 255, 255, 0.85) !important; }
    [data-sidebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu > li > a i,
    [data-sidebarbg="color_6"] .deznav .metismenu > li > a i {
      color: rgba(255, 255, 255, 0.85) !important; }
  [data-sidebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a,
  [data-sidebarbg="color_6"] .deznav .metismenu > li.mm-active > a {
    color: #fff; }
    [data-sidebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a i,
    [data-sidebarbg="color_6"] .deznav .metismenu > li.mm-active > a i {
      color: #fff !important; }
    [data-sidebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a:before,
    [data-sidebarbg="color_6"] .deznav .metismenu > li.mm-active > a:before {
      background: #fff !important; }
  [data-sidebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu .has-arrow:after,
  [data-sidebarbg="color_6"] .deznav .metismenu .has-arrow:after {
    border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85) !important; }
  [data-sidebarbg="color_6"][data-theme-version="dark"] .deznav .help-desk .btn,
  [data-sidebarbg="color_6"] .deznav .help-desk .btn {
    background-color: #f6d93d;
    border-color: #f6d93d; }
  [data-sidebarbg="color_6"][data-theme-version="dark"] .deznav .header-info2 span,
  [data-sidebarbg="color_6"] .deznav .header-info2 span {
    color: #fff; }
  [data-sidebarbg="color_6"][data-theme-version="dark"] .deznav .sidebar-info,
  [data-sidebarbg="color_6"] .deznav .sidebar-info {
    color: #fff; }
    [data-sidebarbg="color_6"][data-theme-version="dark"] .deznav .sidebar-info i,
    [data-sidebarbg="color_6"] .deznav .sidebar-info i {
      color: #fff; }
  [data-sidebarbg="color_6"][data-theme-version="dark"] .plus-box,
  [data-sidebarbg="color_6"] .plus-box {
    background-color: #f6d93d; }
  [data-sidebarbg="color_6"][data-theme-version="dark"] .copyright,
  [data-sidebarbg="color_6"] .copyright {
    color: #fff; }

[data-sidebarbg="color_7"][data-theme-version="dark"],
[data-sidebarbg="color_7"] {
  --sidebar-bg: #104c4f;
  --nav-headbg: #104c4f;
  --headerbg:   #104c4f;
  --sidebar-nav-bg:#156164;
  /* &[data-sidebar-style="full"][data-layout="vertical"]{
				.deznav .metismenu > li.mm-active > a,
				.deznav .metismenu > li:hover > a{
						background:transparent;
				}	
			} */ }
  [data-sidebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu > li:hover > a .menu-icon svg path, [data-sidebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu > li:focus > a .menu-icon svg path,
  [data-sidebarbg="color_7"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_7"] .deznav .metismenu > li:focus > a .menu-icon svg path {
    stroke: white; }
  [data-sidebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a .menu-icon svg path,
  [data-sidebarbg="color_7"] .deznav .metismenu > li.mm-active > a .menu-icon svg path {
    stroke: white; }
  [data-sidebarbg="color_7"][data-theme-version="dark"] .switch-btn,
  [data-sidebarbg="color_7"] .switch-btn {
    border-color: rgba(255, 255, 255, 0.2); }
    [data-sidebarbg="color_7"][data-theme-version="dark"] .switch-btn svg path,
    [data-sidebarbg="color_7"] .switch-btn svg path {
      stroke: #fff; }
    [data-sidebarbg="color_7"][data-theme-version="dark"] .switch-btn span,
    [data-sidebarbg="color_7"] .switch-btn span {
      color: #fff; }
  [data-sidebarbg="color_7"][data-theme-version="dark"] .nav-header .brand-title path,
  [data-sidebarbg="color_7"] .nav-header .brand-title path {
    fill: #fff; }
  [data-sidebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu ul a:before,
  [data-sidebarbg="color_7"] .deznav .metismenu ul a:before {
    background: rgba(255, 255, 255, 0.5); }
  [data-sidebarbg="color_7"][data-theme-version="dark"] .deznav .menu-icon svg path,
  [data-sidebarbg="color_7"][data-theme-version="dark"] .deznav .menu-icon svg ellipse,
  [data-sidebarbg="color_7"] .deznav .menu-icon svg path,
  [data-sidebarbg="color_7"] .deznav .menu-icon svg ellipse {
    stroke: #fff; }
  [data-sidebarbg="color_7"][data-theme-version="dark"][data-layout="horizontal"][data-primary="color_13"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_7"][data-layout="horizontal"][data-primary="color_13"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: white !important; }
  [data-sidebarbg="color_7"][data-theme-version="dark"][data-layout="horizontal"],
  [data-sidebarbg="color_7"][data-layout="horizontal"] {
    --headerbg:#f4f4f5; }
  [data-sidebarbg="color_7"][data-theme-version="dark"][data-layout="vertical"] .deznav .menu-title,
  [data-sidebarbg="color_7"][data-layout="vertical"] .deznav .menu-title {
    color: #fff; }
  [data-sidebarbg="color_7"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a i,
  [data-sidebarbg="color_7"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a i {
    color: #fff; }
  [data-sidebarbg="color_7"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box h5, [data-sidebarbg="color_7"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box .h5,
  [data-sidebarbg="color_7"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box p,
  [data-sidebarbg="color_7"][data-layout="vertical"] .deznav .plus-box h5,
  [data-sidebarbg="color_7"][data-layout="vertical"] .deznav .plus-box .h5,
  [data-sidebarbg="color_7"][data-layout="vertical"] .deznav .plus-box p {
    color: #fff; }
  [data-sidebarbg="color_7"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li > a .menu-icon,
  [data-sidebarbg="color_7"][data-layout="vertical"] .deznav .metismenu > li > a .menu-icon {
    background-color: rgba(255, 255, 255, 0.1); }
  [data-sidebarbg="color_7"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon,
  [data-sidebarbg="color_7"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon {
    background-color: var(--primary-light); }
    [data-sidebarbg="color_7"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon svg path,
    [data-sidebarbg="color_7"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon svg path {
      stroke: black; }
  [data-sidebarbg="color_7"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon,
  [data-sidebarbg="color_7"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon {
    background-color: var(--primary-light); }
    [data-sidebarbg="color_7"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon svg path,
    [data-sidebarbg="color_7"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon svg path {
      stroke: black; }
  [data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="nini"][data-layout="horizontal"] .deznav .metismenu > li.mm-active > a .menu-icon,
  [data-sidebarbg="color_7"][data-sidebar-style="nini"][data-layout="horizontal"] .deznav .metismenu > li.mm-active > a .menu-icon {
    background-color: transparent; }
  [data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="nini"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon,
  [data-sidebarbg="color_7"][data-sidebar-style="nini"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon {
    background-color: var(--primary-light); }
  [data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="nini"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon,
  [data-sidebarbg="color_7"][data-sidebar-style="nini"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon {
    background-color: var(--primary-light); }
  [data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu > li:before,
  [data-sidebarbg="color_7"][data-sidebar-style="modern"] .deznav .metismenu > li:before {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 53.12%, rgba(255, 255, 255, 0) 100%); }
  [data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu > li.mm-active > a .menu-icon,
  [data-sidebarbg="color_7"][data-sidebar-style="modern"] .deznav .metismenu > li.mm-active > a .menu-icon {
    background-color: transparent !important; }
    [data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu > li.mm-active > a .menu-icon path fill,
    [data-sidebarbg="color_7"][data-sidebar-style="modern"] .deznav .metismenu > li.mm-active > a .menu-icon path fill {
      stroke: white !important; }
  [data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu li ul,
  [data-sidebarbg="color_7"][data-sidebar-style="modern"] .deznav .metismenu li ul {
    background-color: var(--sidebar-nav-bg); }
  [data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu > li:hover > a .menu-icon svg path, [data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu > li:focus > a .menu-icon svg path,
  [data-sidebarbg="color_7"][data-sidebar-style="modern"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_7"][data-sidebar-style="modern"] .deznav .metismenu > li:focus > a .menu-icon svg path {
    stroke: var(--primary) !important; }
  [data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:before,
  [data-sidebarbg="color_7"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:before {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 53.12%, rgba(255, 255, 255, 0) 100%); }
  [data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li > a .menu-icon,
  [data-sidebarbg="color_7"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li > a .menu-icon {
    background-color: transparent; }
  [data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon svg path,
  [data-sidebarbg="color_7"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon svg path {
    stroke: white; }
  [data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon,
  [data-sidebarbg="color_7"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon {
    background-color: transparent; }
    [data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon svg path,
    [data-sidebarbg="color_7"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon svg path {
      stroke: white; }
  [data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > a,
  [data-sidebarbg="color_7"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > a {
    color: var(--primary); }
    [data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > a .menu-icon svg path,
    [data-sidebarbg="color_7"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > a .menu-icon svg path {
      stroke: white; }
  [data-sidebarbg="color_7"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:hover > ul,
  [data-sidebarbg="color_7"][data-layout="horizontal"] .deznav .metismenu > li:hover > ul {
    background-color: var(--sidebar-nav-bg); }
  [data-sidebarbg="color_7"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu li > ul ul,
  [data-sidebarbg="color_7"][data-layout="horizontal"] .deznav .metismenu li > ul ul {
    background-color: var(--sidebar-nav-bg); }
  [data-sidebarbg="color_7"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon, [data-sidebarbg="color_7"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:focus > a .menu-icon,
  [data-sidebarbg="color_7"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon,
  [data-sidebarbg="color_7"][data-layout="horizontal"] .deznav .metismenu > li:focus > a .menu-icon {
    background-color: transparent !important; }
    [data-sidebarbg="color_7"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon svg path, [data-sidebarbg="color_7"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:focus > a .menu-icon svg path,
    [data-sidebarbg="color_7"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon svg path,
    [data-sidebarbg="color_7"][data-layout="horizontal"] .deznav .metismenu > li:focus > a .menu-icon svg path {
      stroke: var(--primary) !important; }
  [data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li a:hover,
  [data-sidebarbg="color_7"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li a:hover {
    color: #fff; }
  [data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li > a > i,
  [data-sidebarbg="color_7"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li > a > i {
    background: #5dd8de;
    color: rgba(255, 255, 255, 0.7); }
  [data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a, [data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li:hover > a,
  [data-sidebarbg="color_7"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a,
  [data-sidebarbg="color_7"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li:hover > a {
    box-shadow: none;
    background: transparent !important;
    color: #fff !important; }
    [data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a i, [data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li:hover > a i,
    [data-sidebarbg="color_7"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a i,
    [data-sidebarbg="color_7"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li:hover > a i {
      background: var(--primary);
      color: #fff !important; }
  [data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="modern"][data-sidebarbg="color_13"][data-primary="color_13"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_7"][data-sidebar-style="modern"][data-sidebarbg="color_13"][data-primary="color_13"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: white !important; }
  [data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li > a .menu-icon,
  [data-sidebarbg="color_7"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li > a .menu-icon {
    background-color: rgba(255, 255, 255, 0.1); }
  [data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li:hover .menu-icon,
  [data-sidebarbg="color_7"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li:hover .menu-icon {
    background-color: var(--primary-light) !important; }
    [data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li:hover .menu-icon svg path,
    [data-sidebarbg="color_7"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li:hover .menu-icon svg path {
      stroke: black !important; }
  [data-sidebarbg="color_7"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_7"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: black; }
  [data-sidebarbg="color_7"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu li > ul li a,
  [data-sidebarbg="color_7"][data-layout="horizontal"] .deznav .metismenu li > ul li a {
    padding: 0.5rem 1.25rem 0.5rem 1.25rem; }
  [data-sidebarbg="color_7"][data-theme-version="dark"][data-layout="horizontal"][data-sidebar-style="full"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_7"][data-layout="horizontal"][data-sidebar-style="full"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: var(--primary) !important; }
  [data-sidebarbg="color_7"][data-theme-version="dark"] [data-layout="horizontal"][data-sidebar-style="icon-hover"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_7"] [data-layout="horizontal"][data-sidebar-style="icon-hover"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: var(--primary); }
  [data-sidebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu > li:hover > a .menu-icon, [data-sidebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu > li:focus > a .menu-icon,
  [data-sidebarbg="color_7"] .deznav .metismenu > li:hover > a .menu-icon,
  [data-sidebarbg="color_7"] .deznav .metismenu > li:focus > a .menu-icon {
    background-color: var(--primary-light); }
    [data-sidebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu > li:hover > a .menu-icon svg path, [data-sidebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu > li:focus > a .menu-icon svg path,
    [data-sidebarbg="color_7"] .deznav .metismenu > li:hover > a .menu-icon svg path,
    [data-sidebarbg="color_7"] .deznav .metismenu > li:focus > a .menu-icon svg path {
      stroke: black !important; }
  [data-sidebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu a,
  [data-sidebarbg="color_7"] .deznav .metismenu a {
    color: rgba(255, 255, 255, 0.8) !important; }
  [data-sidebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu li ul a:hover, [data-sidebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu li ul a:focus, [data-sidebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu li ul a.mm-active,
  [data-sidebarbg="color_7"] .deznav .metismenu li ul a:hover,
  [data-sidebarbg="color_7"] .deznav .metismenu li ul a:focus,
  [data-sidebarbg="color_7"] .deznav .metismenu li ul a.mm-active {
    color: #fff !important; }
  [data-sidebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_7"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: var(--primary); }
  [data-sidebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu > li > a,
  [data-sidebarbg="color_7"] .deznav .metismenu > li > a {
    color: rgba(255, 255, 255, 0.85) !important; }
    [data-sidebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu > li > a i,
    [data-sidebarbg="color_7"] .deznav .metismenu > li > a i {
      color: rgba(255, 255, 255, 0.85) !important; }
  [data-sidebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a,
  [data-sidebarbg="color_7"] .deznav .metismenu > li.mm-active > a {
    color: #fff; }
    [data-sidebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a i,
    [data-sidebarbg="color_7"] .deznav .metismenu > li.mm-active > a i {
      color: #fff !important; }
    [data-sidebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a:before,
    [data-sidebarbg="color_7"] .deznav .metismenu > li.mm-active > a:before {
      background: #fff !important; }
  [data-sidebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu .has-arrow:after,
  [data-sidebarbg="color_7"] .deznav .metismenu .has-arrow:after {
    border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85) !important; }
  [data-sidebarbg="color_7"][data-theme-version="dark"] .deznav .help-desk .btn,
  [data-sidebarbg="color_7"] .deznav .help-desk .btn {
    background-color: #7adfe4;
    border-color: #7adfe4; }
  [data-sidebarbg="color_7"][data-theme-version="dark"] .deznav .header-info2 span,
  [data-sidebarbg="color_7"] .deznav .header-info2 span {
    color: #fff; }
  [data-sidebarbg="color_7"][data-theme-version="dark"] .deznav .sidebar-info,
  [data-sidebarbg="color_7"] .deznav .sidebar-info {
    color: #fff; }
    [data-sidebarbg="color_7"][data-theme-version="dark"] .deznav .sidebar-info i,
    [data-sidebarbg="color_7"] .deznav .sidebar-info i {
      color: #fff; }
  [data-sidebarbg="color_7"][data-theme-version="dark"] .plus-box,
  [data-sidebarbg="color_7"] .plus-box {
    background-color: #7adfe4; }
  [data-sidebarbg="color_7"][data-theme-version="dark"] .copyright,
  [data-sidebarbg="color_7"] .copyright {
    color: #fff; }

[data-sidebarbg="color_8"][data-theme-version="dark"],
[data-sidebarbg="color_8"] {
  --sidebar-bg: #752088;
  --nav-headbg: #752088;
  --headerbg:   #752088;
  --sidebar-nav-bg:#87249d;
  /* &[data-sidebar-style="full"][data-layout="vertical"]{
				.deznav .metismenu > li.mm-active > a,
				.deznav .metismenu > li:hover > a{
						background:transparent;
				}	
			} */ }
  [data-sidebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu > li:hover > a .menu-icon svg path, [data-sidebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu > li:focus > a .menu-icon svg path,
  [data-sidebarbg="color_8"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_8"] .deznav .metismenu > li:focus > a .menu-icon svg path {
    stroke: white; }
  [data-sidebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a .menu-icon svg path,
  [data-sidebarbg="color_8"] .deznav .metismenu > li.mm-active > a .menu-icon svg path {
    stroke: white; }
  [data-sidebarbg="color_8"][data-theme-version="dark"] .switch-btn,
  [data-sidebarbg="color_8"] .switch-btn {
    border-color: rgba(255, 255, 255, 0.2); }
    [data-sidebarbg="color_8"][data-theme-version="dark"] .switch-btn svg path,
    [data-sidebarbg="color_8"] .switch-btn svg path {
      stroke: #fff; }
    [data-sidebarbg="color_8"][data-theme-version="dark"] .switch-btn span,
    [data-sidebarbg="color_8"] .switch-btn span {
      color: #fff; }
  [data-sidebarbg="color_8"][data-theme-version="dark"] .nav-header .brand-title path,
  [data-sidebarbg="color_8"] .nav-header .brand-title path {
    fill: #fff; }
  [data-sidebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu ul a:before,
  [data-sidebarbg="color_8"] .deznav .metismenu ul a:before {
    background: rgba(255, 255, 255, 0.5); }
  [data-sidebarbg="color_8"][data-theme-version="dark"] .deznav .menu-icon svg path,
  [data-sidebarbg="color_8"][data-theme-version="dark"] .deznav .menu-icon svg ellipse,
  [data-sidebarbg="color_8"] .deznav .menu-icon svg path,
  [data-sidebarbg="color_8"] .deznav .menu-icon svg ellipse {
    stroke: #fff; }
  [data-sidebarbg="color_8"][data-theme-version="dark"][data-layout="horizontal"][data-primary="color_13"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_8"][data-layout="horizontal"][data-primary="color_13"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: white !important; }
  [data-sidebarbg="color_8"][data-theme-version="dark"][data-layout="horizontal"],
  [data-sidebarbg="color_8"][data-layout="horizontal"] {
    --headerbg:#f4f4f5; }
  [data-sidebarbg="color_8"][data-theme-version="dark"][data-layout="vertical"] .deznav .menu-title,
  [data-sidebarbg="color_8"][data-layout="vertical"] .deznav .menu-title {
    color: #fff; }
  [data-sidebarbg="color_8"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a i,
  [data-sidebarbg="color_8"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a i {
    color: #fff; }
  [data-sidebarbg="color_8"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box h5, [data-sidebarbg="color_8"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box .h5,
  [data-sidebarbg="color_8"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box p,
  [data-sidebarbg="color_8"][data-layout="vertical"] .deznav .plus-box h5,
  [data-sidebarbg="color_8"][data-layout="vertical"] .deznav .plus-box .h5,
  [data-sidebarbg="color_8"][data-layout="vertical"] .deznav .plus-box p {
    color: #fff; }
  [data-sidebarbg="color_8"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li > a .menu-icon,
  [data-sidebarbg="color_8"][data-layout="vertical"] .deznav .metismenu > li > a .menu-icon {
    background-color: rgba(255, 255, 255, 0.1); }
  [data-sidebarbg="color_8"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon,
  [data-sidebarbg="color_8"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon {
    background-color: var(--primary-light); }
    [data-sidebarbg="color_8"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon svg path,
    [data-sidebarbg="color_8"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon svg path {
      stroke: black; }
  [data-sidebarbg="color_8"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon,
  [data-sidebarbg="color_8"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon {
    background-color: var(--primary-light); }
    [data-sidebarbg="color_8"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon svg path,
    [data-sidebarbg="color_8"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon svg path {
      stroke: black; }
  [data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="nini"][data-layout="horizontal"] .deznav .metismenu > li.mm-active > a .menu-icon,
  [data-sidebarbg="color_8"][data-sidebar-style="nini"][data-layout="horizontal"] .deznav .metismenu > li.mm-active > a .menu-icon {
    background-color: transparent; }
  [data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="nini"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon,
  [data-sidebarbg="color_8"][data-sidebar-style="nini"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon {
    background-color: var(--primary-light); }
  [data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="nini"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon,
  [data-sidebarbg="color_8"][data-sidebar-style="nini"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon {
    background-color: var(--primary-light); }
  [data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu > li:before,
  [data-sidebarbg="color_8"][data-sidebar-style="modern"] .deznav .metismenu > li:before {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 53.12%, rgba(255, 255, 255, 0) 100%); }
  [data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu > li.mm-active > a .menu-icon,
  [data-sidebarbg="color_8"][data-sidebar-style="modern"] .deznav .metismenu > li.mm-active > a .menu-icon {
    background-color: transparent !important; }
    [data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu > li.mm-active > a .menu-icon path fill,
    [data-sidebarbg="color_8"][data-sidebar-style="modern"] .deznav .metismenu > li.mm-active > a .menu-icon path fill {
      stroke: white !important; }
  [data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu li ul,
  [data-sidebarbg="color_8"][data-sidebar-style="modern"] .deznav .metismenu li ul {
    background-color: var(--sidebar-nav-bg); }
  [data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu > li:hover > a .menu-icon svg path, [data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu > li:focus > a .menu-icon svg path,
  [data-sidebarbg="color_8"][data-sidebar-style="modern"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_8"][data-sidebar-style="modern"] .deznav .metismenu > li:focus > a .menu-icon svg path {
    stroke: var(--primary) !important; }
  [data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:before,
  [data-sidebarbg="color_8"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:before {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 53.12%, rgba(255, 255, 255, 0) 100%); }
  [data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li > a .menu-icon,
  [data-sidebarbg="color_8"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li > a .menu-icon {
    background-color: transparent; }
  [data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon svg path,
  [data-sidebarbg="color_8"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon svg path {
    stroke: white; }
  [data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon,
  [data-sidebarbg="color_8"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon {
    background-color: transparent; }
    [data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon svg path,
    [data-sidebarbg="color_8"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon svg path {
      stroke: white; }
  [data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > a,
  [data-sidebarbg="color_8"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > a {
    color: var(--primary); }
    [data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > a .menu-icon svg path,
    [data-sidebarbg="color_8"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > a .menu-icon svg path {
      stroke: white; }
  [data-sidebarbg="color_8"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:hover > ul,
  [data-sidebarbg="color_8"][data-layout="horizontal"] .deznav .metismenu > li:hover > ul {
    background-color: var(--sidebar-nav-bg); }
  [data-sidebarbg="color_8"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu li > ul ul,
  [data-sidebarbg="color_8"][data-layout="horizontal"] .deznav .metismenu li > ul ul {
    background-color: var(--sidebar-nav-bg); }
  [data-sidebarbg="color_8"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon, [data-sidebarbg="color_8"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:focus > a .menu-icon,
  [data-sidebarbg="color_8"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon,
  [data-sidebarbg="color_8"][data-layout="horizontal"] .deznav .metismenu > li:focus > a .menu-icon {
    background-color: transparent !important; }
    [data-sidebarbg="color_8"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon svg path, [data-sidebarbg="color_8"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:focus > a .menu-icon svg path,
    [data-sidebarbg="color_8"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon svg path,
    [data-sidebarbg="color_8"][data-layout="horizontal"] .deznav .metismenu > li:focus > a .menu-icon svg path {
      stroke: var(--primary) !important; }
  [data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li a:hover,
  [data-sidebarbg="color_8"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li a:hover {
    color: #fff; }
  [data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li > a > i,
  [data-sidebarbg="color_8"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li > a > i {
    background: #da9be8;
    color: rgba(255, 255, 255, 0.7); }
  [data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a, [data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li:hover > a,
  [data-sidebarbg="color_8"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a,
  [data-sidebarbg="color_8"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li:hover > a {
    box-shadow: none;
    background: transparent !important;
    color: #fff !important; }
    [data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a i, [data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li:hover > a i,
    [data-sidebarbg="color_8"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a i,
    [data-sidebarbg="color_8"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li:hover > a i {
      background: var(--primary);
      color: #fff !important; }
  [data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="modern"][data-sidebarbg="color_13"][data-primary="color_13"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_8"][data-sidebar-style="modern"][data-sidebarbg="color_13"][data-primary="color_13"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: white !important; }
  [data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li > a .menu-icon,
  [data-sidebarbg="color_8"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li > a .menu-icon {
    background-color: rgba(255, 255, 255, 0.1); }
  [data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li:hover .menu-icon,
  [data-sidebarbg="color_8"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li:hover .menu-icon {
    background-color: var(--primary-light) !important; }
    [data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li:hover .menu-icon svg path,
    [data-sidebarbg="color_8"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li:hover .menu-icon svg path {
      stroke: black !important; }
  [data-sidebarbg="color_8"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_8"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: black; }
  [data-sidebarbg="color_8"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu li > ul li a,
  [data-sidebarbg="color_8"][data-layout="horizontal"] .deznav .metismenu li > ul li a {
    padding: 0.5rem 1.25rem 0.5rem 1.25rem; }
  [data-sidebarbg="color_8"][data-theme-version="dark"][data-layout="horizontal"][data-sidebar-style="full"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_8"][data-layout="horizontal"][data-sidebar-style="full"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: var(--primary) !important; }
  [data-sidebarbg="color_8"][data-theme-version="dark"] [data-layout="horizontal"][data-sidebar-style="icon-hover"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_8"] [data-layout="horizontal"][data-sidebar-style="icon-hover"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: var(--primary); }
  [data-sidebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu > li:hover > a .menu-icon, [data-sidebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu > li:focus > a .menu-icon,
  [data-sidebarbg="color_8"] .deznav .metismenu > li:hover > a .menu-icon,
  [data-sidebarbg="color_8"] .deznav .metismenu > li:focus > a .menu-icon {
    background-color: var(--primary-light); }
    [data-sidebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu > li:hover > a .menu-icon svg path, [data-sidebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu > li:focus > a .menu-icon svg path,
    [data-sidebarbg="color_8"] .deznav .metismenu > li:hover > a .menu-icon svg path,
    [data-sidebarbg="color_8"] .deznav .metismenu > li:focus > a .menu-icon svg path {
      stroke: black !important; }
  [data-sidebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu a,
  [data-sidebarbg="color_8"] .deznav .metismenu a {
    color: rgba(255, 255, 255, 0.8) !important; }
  [data-sidebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu li ul a:hover, [data-sidebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu li ul a:focus, [data-sidebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu li ul a.mm-active,
  [data-sidebarbg="color_8"] .deznav .metismenu li ul a:hover,
  [data-sidebarbg="color_8"] .deznav .metismenu li ul a:focus,
  [data-sidebarbg="color_8"] .deznav .metismenu li ul a.mm-active {
    color: #fff !important; }
  [data-sidebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_8"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: var(--primary); }
  [data-sidebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu > li > a,
  [data-sidebarbg="color_8"] .deznav .metismenu > li > a {
    color: rgba(255, 255, 255, 0.85) !important; }
    [data-sidebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu > li > a i,
    [data-sidebarbg="color_8"] .deznav .metismenu > li > a i {
      color: rgba(255, 255, 255, 0.85) !important; }
  [data-sidebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a,
  [data-sidebarbg="color_8"] .deznav .metismenu > li.mm-active > a {
    color: #fff; }
    [data-sidebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a i,
    [data-sidebarbg="color_8"] .deznav .metismenu > li.mm-active > a i {
      color: #fff !important; }
    [data-sidebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a:before,
    [data-sidebarbg="color_8"] .deznav .metismenu > li.mm-active > a:before {
      background: #fff !important; }
  [data-sidebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu .has-arrow:after,
  [data-sidebarbg="color_8"] .deznav .metismenu .has-arrow:after {
    border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85) !important; }
  [data-sidebarbg="color_8"][data-theme-version="dark"] .deznav .help-desk .btn,
  [data-sidebarbg="color_8"] .deznav .help-desk .btn {
    background-color: #e5b8ef;
    border-color: #e5b8ef; }
  [data-sidebarbg="color_8"][data-theme-version="dark"] .deznav .header-info2 span,
  [data-sidebarbg="color_8"] .deznav .header-info2 span {
    color: #fff; }
  [data-sidebarbg="color_8"][data-theme-version="dark"] .deznav .sidebar-info,
  [data-sidebarbg="color_8"] .deznav .sidebar-info {
    color: #fff; }
    [data-sidebarbg="color_8"][data-theme-version="dark"] .deznav .sidebar-info i,
    [data-sidebarbg="color_8"] .deznav .sidebar-info i {
      color: #fff; }
  [data-sidebarbg="color_8"][data-theme-version="dark"] .plus-box,
  [data-sidebarbg="color_8"] .plus-box {
    background-color: #e5b8ef; }
  [data-sidebarbg="color_8"][data-theme-version="dark"] .copyright,
  [data-sidebarbg="color_8"] .copyright {
    color: #fff; }

[data-sidebarbg="color_9"][data-theme-version="dark"],
[data-sidebarbg="color_9"] {
  --sidebar-bg: #13538e;
  --nav-headbg: #13538e;
  --headerbg:   #13538e;
  --sidebar-nav-bg:#1660a5;
  /* &[data-sidebar-style="full"][data-layout="vertical"]{
				.deznav .metismenu > li.mm-active > a,
				.deznav .metismenu > li:hover > a{
						background:transparent;
				}	
			} */ }
  [data-sidebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu > li:hover > a .menu-icon svg path, [data-sidebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu > li:focus > a .menu-icon svg path,
  [data-sidebarbg="color_9"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_9"] .deznav .metismenu > li:focus > a .menu-icon svg path {
    stroke: white; }
  [data-sidebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a .menu-icon svg path,
  [data-sidebarbg="color_9"] .deznav .metismenu > li.mm-active > a .menu-icon svg path {
    stroke: white; }
  [data-sidebarbg="color_9"][data-theme-version="dark"] .switch-btn,
  [data-sidebarbg="color_9"] .switch-btn {
    border-color: rgba(255, 255, 255, 0.2); }
    [data-sidebarbg="color_9"][data-theme-version="dark"] .switch-btn svg path,
    [data-sidebarbg="color_9"] .switch-btn svg path {
      stroke: #fff; }
    [data-sidebarbg="color_9"][data-theme-version="dark"] .switch-btn span,
    [data-sidebarbg="color_9"] .switch-btn span {
      color: #fff; }
  [data-sidebarbg="color_9"][data-theme-version="dark"] .nav-header .brand-title path,
  [data-sidebarbg="color_9"] .nav-header .brand-title path {
    fill: #fff; }
  [data-sidebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu ul a:before,
  [data-sidebarbg="color_9"] .deznav .metismenu ul a:before {
    background: rgba(255, 255, 255, 0.5); }
  [data-sidebarbg="color_9"][data-theme-version="dark"] .deznav .menu-icon svg path,
  [data-sidebarbg="color_9"][data-theme-version="dark"] .deznav .menu-icon svg ellipse,
  [data-sidebarbg="color_9"] .deznav .menu-icon svg path,
  [data-sidebarbg="color_9"] .deznav .menu-icon svg ellipse {
    stroke: #fff; }
  [data-sidebarbg="color_9"][data-theme-version="dark"][data-layout="horizontal"][data-primary="color_13"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_9"][data-layout="horizontal"][data-primary="color_13"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: white !important; }
  [data-sidebarbg="color_9"][data-theme-version="dark"][data-layout="horizontal"],
  [data-sidebarbg="color_9"][data-layout="horizontal"] {
    --headerbg:#f4f4f5; }
  [data-sidebarbg="color_9"][data-theme-version="dark"][data-layout="vertical"] .deznav .menu-title,
  [data-sidebarbg="color_9"][data-layout="vertical"] .deznav .menu-title {
    color: #fff; }
  [data-sidebarbg="color_9"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a i,
  [data-sidebarbg="color_9"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a i {
    color: #fff; }
  [data-sidebarbg="color_9"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box h5, [data-sidebarbg="color_9"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box .h5,
  [data-sidebarbg="color_9"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box p,
  [data-sidebarbg="color_9"][data-layout="vertical"] .deznav .plus-box h5,
  [data-sidebarbg="color_9"][data-layout="vertical"] .deznav .plus-box .h5,
  [data-sidebarbg="color_9"][data-layout="vertical"] .deznav .plus-box p {
    color: #fff; }
  [data-sidebarbg="color_9"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li > a .menu-icon,
  [data-sidebarbg="color_9"][data-layout="vertical"] .deznav .metismenu > li > a .menu-icon {
    background-color: rgba(255, 255, 255, 0.1); }
  [data-sidebarbg="color_9"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon,
  [data-sidebarbg="color_9"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon {
    background-color: var(--primary-light); }
    [data-sidebarbg="color_9"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon svg path,
    [data-sidebarbg="color_9"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon svg path {
      stroke: black; }
  [data-sidebarbg="color_9"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon,
  [data-sidebarbg="color_9"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon {
    background-color: var(--primary-light); }
    [data-sidebarbg="color_9"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon svg path,
    [data-sidebarbg="color_9"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon svg path {
      stroke: black; }
  [data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="nini"][data-layout="horizontal"] .deznav .metismenu > li.mm-active > a .menu-icon,
  [data-sidebarbg="color_9"][data-sidebar-style="nini"][data-layout="horizontal"] .deznav .metismenu > li.mm-active > a .menu-icon {
    background-color: transparent; }
  [data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="nini"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon,
  [data-sidebarbg="color_9"][data-sidebar-style="nini"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon {
    background-color: var(--primary-light); }
  [data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="nini"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon,
  [data-sidebarbg="color_9"][data-sidebar-style="nini"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon {
    background-color: var(--primary-light); }
  [data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu > li:before,
  [data-sidebarbg="color_9"][data-sidebar-style="modern"] .deznav .metismenu > li:before {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 53.12%, rgba(255, 255, 255, 0) 100%); }
  [data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu > li.mm-active > a .menu-icon,
  [data-sidebarbg="color_9"][data-sidebar-style="modern"] .deznav .metismenu > li.mm-active > a .menu-icon {
    background-color: transparent !important; }
    [data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu > li.mm-active > a .menu-icon path fill,
    [data-sidebarbg="color_9"][data-sidebar-style="modern"] .deznav .metismenu > li.mm-active > a .menu-icon path fill {
      stroke: white !important; }
  [data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu li ul,
  [data-sidebarbg="color_9"][data-sidebar-style="modern"] .deznav .metismenu li ul {
    background-color: var(--sidebar-nav-bg); }
  [data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu > li:hover > a .menu-icon svg path, [data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu > li:focus > a .menu-icon svg path,
  [data-sidebarbg="color_9"][data-sidebar-style="modern"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_9"][data-sidebar-style="modern"] .deznav .metismenu > li:focus > a .menu-icon svg path {
    stroke: var(--primary) !important; }
  [data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:before,
  [data-sidebarbg="color_9"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:before {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 53.12%, rgba(255, 255, 255, 0) 100%); }
  [data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li > a .menu-icon,
  [data-sidebarbg="color_9"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li > a .menu-icon {
    background-color: transparent; }
  [data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon svg path,
  [data-sidebarbg="color_9"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon svg path {
    stroke: white; }
  [data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon,
  [data-sidebarbg="color_9"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon {
    background-color: transparent; }
    [data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon svg path,
    [data-sidebarbg="color_9"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon svg path {
      stroke: white; }
  [data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > a,
  [data-sidebarbg="color_9"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > a {
    color: var(--primary); }
    [data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > a .menu-icon svg path,
    [data-sidebarbg="color_9"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > a .menu-icon svg path {
      stroke: white; }
  [data-sidebarbg="color_9"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:hover > ul,
  [data-sidebarbg="color_9"][data-layout="horizontal"] .deznav .metismenu > li:hover > ul {
    background-color: var(--sidebar-nav-bg); }
  [data-sidebarbg="color_9"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu li > ul ul,
  [data-sidebarbg="color_9"][data-layout="horizontal"] .deznav .metismenu li > ul ul {
    background-color: var(--sidebar-nav-bg); }
  [data-sidebarbg="color_9"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon, [data-sidebarbg="color_9"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:focus > a .menu-icon,
  [data-sidebarbg="color_9"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon,
  [data-sidebarbg="color_9"][data-layout="horizontal"] .deznav .metismenu > li:focus > a .menu-icon {
    background-color: transparent !important; }
    [data-sidebarbg="color_9"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon svg path, [data-sidebarbg="color_9"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:focus > a .menu-icon svg path,
    [data-sidebarbg="color_9"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon svg path,
    [data-sidebarbg="color_9"][data-layout="horizontal"] .deznav .metismenu > li:focus > a .menu-icon svg path {
      stroke: var(--primary) !important; }
  [data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li a:hover,
  [data-sidebarbg="color_9"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li a:hover {
    color: #fff; }
  [data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li > a > i,
  [data-sidebarbg="color_9"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li > a > i {
    background: #8dc0f0;
    color: rgba(255, 255, 255, 0.7); }
  [data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a, [data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li:hover > a,
  [data-sidebarbg="color_9"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a,
  [data-sidebarbg="color_9"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li:hover > a {
    box-shadow: none;
    background: transparent !important;
    color: #fff !important; }
    [data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a i, [data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li:hover > a i,
    [data-sidebarbg="color_9"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a i,
    [data-sidebarbg="color_9"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li:hover > a i {
      background: var(--primary);
      color: #fff !important; }
  [data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="modern"][data-sidebarbg="color_13"][data-primary="color_13"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_9"][data-sidebar-style="modern"][data-sidebarbg="color_13"][data-primary="color_13"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: white !important; }
  [data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li > a .menu-icon,
  [data-sidebarbg="color_9"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li > a .menu-icon {
    background-color: rgba(255, 255, 255, 0.1); }
  [data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li:hover .menu-icon,
  [data-sidebarbg="color_9"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li:hover .menu-icon {
    background-color: var(--primary-light) !important; }
    [data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li:hover .menu-icon svg path,
    [data-sidebarbg="color_9"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li:hover .menu-icon svg path {
      stroke: black !important; }
  [data-sidebarbg="color_9"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_9"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: black; }
  [data-sidebarbg="color_9"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu li > ul li a,
  [data-sidebarbg="color_9"][data-layout="horizontal"] .deznav .metismenu li > ul li a {
    padding: 0.5rem 1.25rem 0.5rem 1.25rem; }
  [data-sidebarbg="color_9"][data-theme-version="dark"][data-layout="horizontal"][data-sidebar-style="full"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_9"][data-layout="horizontal"][data-sidebar-style="full"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: var(--primary) !important; }
  [data-sidebarbg="color_9"][data-theme-version="dark"] [data-layout="horizontal"][data-sidebar-style="icon-hover"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_9"] [data-layout="horizontal"][data-sidebar-style="icon-hover"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: var(--primary); }
  [data-sidebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu > li:hover > a .menu-icon, [data-sidebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu > li:focus > a .menu-icon,
  [data-sidebarbg="color_9"] .deznav .metismenu > li:hover > a .menu-icon,
  [data-sidebarbg="color_9"] .deznav .metismenu > li:focus > a .menu-icon {
    background-color: var(--primary-light); }
    [data-sidebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu > li:hover > a .menu-icon svg path, [data-sidebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu > li:focus > a .menu-icon svg path,
    [data-sidebarbg="color_9"] .deznav .metismenu > li:hover > a .menu-icon svg path,
    [data-sidebarbg="color_9"] .deznav .metismenu > li:focus > a .menu-icon svg path {
      stroke: black !important; }
  [data-sidebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu a,
  [data-sidebarbg="color_9"] .deznav .metismenu a {
    color: rgba(255, 255, 255, 0.8) !important; }
  [data-sidebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu li ul a:hover, [data-sidebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu li ul a:focus, [data-sidebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu li ul a.mm-active,
  [data-sidebarbg="color_9"] .deznav .metismenu li ul a:hover,
  [data-sidebarbg="color_9"] .deznav .metismenu li ul a:focus,
  [data-sidebarbg="color_9"] .deznav .metismenu li ul a.mm-active {
    color: #fff !important; }
  [data-sidebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_9"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: var(--primary); }
  [data-sidebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu > li > a,
  [data-sidebarbg="color_9"] .deznav .metismenu > li > a {
    color: rgba(255, 255, 255, 0.85) !important; }
    [data-sidebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu > li > a i,
    [data-sidebarbg="color_9"] .deznav .metismenu > li > a i {
      color: rgba(255, 255, 255, 0.85) !important; }
  [data-sidebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a,
  [data-sidebarbg="color_9"] .deznav .metismenu > li.mm-active > a {
    color: #fff; }
    [data-sidebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a i,
    [data-sidebarbg="color_9"] .deznav .metismenu > li.mm-active > a i {
      color: #fff !important; }
    [data-sidebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a:before,
    [data-sidebarbg="color_9"] .deznav .metismenu > li.mm-active > a:before {
      background: #fff !important; }
  [data-sidebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu .has-arrow:after,
  [data-sidebarbg="color_9"] .deznav .metismenu .has-arrow:after {
    border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85) !important; }
  [data-sidebarbg="color_9"][data-theme-version="dark"] .deznav .help-desk .btn,
  [data-sidebarbg="color_9"] .deznav .help-desk .btn {
    background-color: #acd2f4;
    border-color: #acd2f4; }
  [data-sidebarbg="color_9"][data-theme-version="dark"] .deznav .header-info2 span,
  [data-sidebarbg="color_9"] .deznav .header-info2 span {
    color: #fff; }
  [data-sidebarbg="color_9"][data-theme-version="dark"] .deznav .sidebar-info,
  [data-sidebarbg="color_9"] .deznav .sidebar-info {
    color: #fff; }
    [data-sidebarbg="color_9"][data-theme-version="dark"] .deznav .sidebar-info i,
    [data-sidebarbg="color_9"] .deznav .sidebar-info i {
      color: #fff; }
  [data-sidebarbg="color_9"][data-theme-version="dark"] .plus-box,
  [data-sidebarbg="color_9"] .plus-box {
    background-color: #acd2f4; }
  [data-sidebarbg="color_9"][data-theme-version="dark"] .copyright,
  [data-sidebarbg="color_9"] .copyright {
    color: #fff; }

[data-sidebarbg="color_10"][data-theme-version="dark"],
[data-sidebarbg="color_10"] {
  --sidebar-bg: #1f5a2c;
  --nav-headbg: #1f5a2c;
  --headerbg:   #1f5a2c;
  --sidebar-nav-bg:#256d35;
  /* &[data-sidebar-style="full"][data-layout="vertical"]{
				.deznav .metismenu > li.mm-active > a,
				.deznav .metismenu > li:hover > a{
						background:transparent;
				}	
			} */ }
  [data-sidebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu > li:hover > a .menu-icon svg path, [data-sidebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu > li:focus > a .menu-icon svg path,
  [data-sidebarbg="color_10"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_10"] .deznav .metismenu > li:focus > a .menu-icon svg path {
    stroke: white; }
  [data-sidebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a .menu-icon svg path,
  [data-sidebarbg="color_10"] .deznav .metismenu > li.mm-active > a .menu-icon svg path {
    stroke: white; }
  [data-sidebarbg="color_10"][data-theme-version="dark"] .switch-btn,
  [data-sidebarbg="color_10"] .switch-btn {
    border-color: rgba(255, 255, 255, 0.2); }
    [data-sidebarbg="color_10"][data-theme-version="dark"] .switch-btn svg path,
    [data-sidebarbg="color_10"] .switch-btn svg path {
      stroke: #fff; }
    [data-sidebarbg="color_10"][data-theme-version="dark"] .switch-btn span,
    [data-sidebarbg="color_10"] .switch-btn span {
      color: #fff; }
  [data-sidebarbg="color_10"][data-theme-version="dark"] .nav-header .brand-title path,
  [data-sidebarbg="color_10"] .nav-header .brand-title path {
    fill: #fff; }
  [data-sidebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu ul a:before,
  [data-sidebarbg="color_10"] .deznav .metismenu ul a:before {
    background: rgba(255, 255, 255, 0.5); }
  [data-sidebarbg="color_10"][data-theme-version="dark"] .deznav .menu-icon svg path,
  [data-sidebarbg="color_10"][data-theme-version="dark"] .deznav .menu-icon svg ellipse,
  [data-sidebarbg="color_10"] .deznav .menu-icon svg path,
  [data-sidebarbg="color_10"] .deznav .menu-icon svg ellipse {
    stroke: #fff; }
  [data-sidebarbg="color_10"][data-theme-version="dark"][data-layout="horizontal"][data-primary="color_13"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_10"][data-layout="horizontal"][data-primary="color_13"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: white !important; }
  [data-sidebarbg="color_10"][data-theme-version="dark"][data-layout="horizontal"],
  [data-sidebarbg="color_10"][data-layout="horizontal"] {
    --headerbg:#f4f4f5; }
  [data-sidebarbg="color_10"][data-theme-version="dark"][data-layout="vertical"] .deznav .menu-title,
  [data-sidebarbg="color_10"][data-layout="vertical"] .deznav .menu-title {
    color: #fff; }
  [data-sidebarbg="color_10"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a i,
  [data-sidebarbg="color_10"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a i {
    color: #fff; }
  [data-sidebarbg="color_10"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box h5, [data-sidebarbg="color_10"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box .h5,
  [data-sidebarbg="color_10"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box p,
  [data-sidebarbg="color_10"][data-layout="vertical"] .deznav .plus-box h5,
  [data-sidebarbg="color_10"][data-layout="vertical"] .deznav .plus-box .h5,
  [data-sidebarbg="color_10"][data-layout="vertical"] .deznav .plus-box p {
    color: #fff; }
  [data-sidebarbg="color_10"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li > a .menu-icon,
  [data-sidebarbg="color_10"][data-layout="vertical"] .deznav .metismenu > li > a .menu-icon {
    background-color: rgba(255, 255, 255, 0.1); }
  [data-sidebarbg="color_10"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon,
  [data-sidebarbg="color_10"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon {
    background-color: var(--primary-light); }
    [data-sidebarbg="color_10"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon svg path,
    [data-sidebarbg="color_10"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon svg path {
      stroke: black; }
  [data-sidebarbg="color_10"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon,
  [data-sidebarbg="color_10"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon {
    background-color: var(--primary-light); }
    [data-sidebarbg="color_10"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon svg path,
    [data-sidebarbg="color_10"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon svg path {
      stroke: black; }
  [data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="nini"][data-layout="horizontal"] .deznav .metismenu > li.mm-active > a .menu-icon,
  [data-sidebarbg="color_10"][data-sidebar-style="nini"][data-layout="horizontal"] .deznav .metismenu > li.mm-active > a .menu-icon {
    background-color: transparent; }
  [data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="nini"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon,
  [data-sidebarbg="color_10"][data-sidebar-style="nini"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon {
    background-color: var(--primary-light); }
  [data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="nini"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon,
  [data-sidebarbg="color_10"][data-sidebar-style="nini"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon {
    background-color: var(--primary-light); }
  [data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu > li:before,
  [data-sidebarbg="color_10"][data-sidebar-style="modern"] .deznav .metismenu > li:before {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 53.12%, rgba(255, 255, 255, 0) 100%); }
  [data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu > li.mm-active > a .menu-icon,
  [data-sidebarbg="color_10"][data-sidebar-style="modern"] .deznav .metismenu > li.mm-active > a .menu-icon {
    background-color: transparent !important; }
    [data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu > li.mm-active > a .menu-icon path fill,
    [data-sidebarbg="color_10"][data-sidebar-style="modern"] .deznav .metismenu > li.mm-active > a .menu-icon path fill {
      stroke: white !important; }
  [data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu li ul,
  [data-sidebarbg="color_10"][data-sidebar-style="modern"] .deznav .metismenu li ul {
    background-color: var(--sidebar-nav-bg); }
  [data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu > li:hover > a .menu-icon svg path, [data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu > li:focus > a .menu-icon svg path,
  [data-sidebarbg="color_10"][data-sidebar-style="modern"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_10"][data-sidebar-style="modern"] .deznav .metismenu > li:focus > a .menu-icon svg path {
    stroke: var(--primary) !important; }
  [data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:before,
  [data-sidebarbg="color_10"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:before {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 53.12%, rgba(255, 255, 255, 0) 100%); }
  [data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li > a .menu-icon,
  [data-sidebarbg="color_10"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li > a .menu-icon {
    background-color: transparent; }
  [data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon svg path,
  [data-sidebarbg="color_10"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon svg path {
    stroke: white; }
  [data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon,
  [data-sidebarbg="color_10"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon {
    background-color: transparent; }
    [data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon svg path,
    [data-sidebarbg="color_10"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon svg path {
      stroke: white; }
  [data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > a,
  [data-sidebarbg="color_10"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > a {
    color: var(--primary); }
    [data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > a .menu-icon svg path,
    [data-sidebarbg="color_10"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > a .menu-icon svg path {
      stroke: white; }
  [data-sidebarbg="color_10"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:hover > ul,
  [data-sidebarbg="color_10"][data-layout="horizontal"] .deznav .metismenu > li:hover > ul {
    background-color: var(--sidebar-nav-bg); }
  [data-sidebarbg="color_10"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu li > ul ul,
  [data-sidebarbg="color_10"][data-layout="horizontal"] .deznav .metismenu li > ul ul {
    background-color: var(--sidebar-nav-bg); }
  [data-sidebarbg="color_10"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon, [data-sidebarbg="color_10"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:focus > a .menu-icon,
  [data-sidebarbg="color_10"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon,
  [data-sidebarbg="color_10"][data-layout="horizontal"] .deznav .metismenu > li:focus > a .menu-icon {
    background-color: transparent !important; }
    [data-sidebarbg="color_10"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon svg path, [data-sidebarbg="color_10"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:focus > a .menu-icon svg path,
    [data-sidebarbg="color_10"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon svg path,
    [data-sidebarbg="color_10"][data-layout="horizontal"] .deznav .metismenu > li:focus > a .menu-icon svg path {
      stroke: var(--primary) !important; }
  [data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li a:hover,
  [data-sidebarbg="color_10"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li a:hover {
    color: #fff; }
  [data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li > a > i,
  [data-sidebarbg="color_10"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li > a > i {
    background: #80d493;
    color: rgba(255, 255, 255, 0.7); }
  [data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a, [data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li:hover > a,
  [data-sidebarbg="color_10"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a,
  [data-sidebarbg="color_10"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li:hover > a {
    box-shadow: none;
    background: transparent !important;
    color: #fff !important; }
    [data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a i, [data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li:hover > a i,
    [data-sidebarbg="color_10"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a i,
    [data-sidebarbg="color_10"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li:hover > a i {
      background: var(--primary);
      color: #fff !important; }
  [data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="modern"][data-sidebarbg="color_13"][data-primary="color_13"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_10"][data-sidebar-style="modern"][data-sidebarbg="color_13"][data-primary="color_13"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: white !important; }
  [data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li > a .menu-icon,
  [data-sidebarbg="color_10"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li > a .menu-icon {
    background-color: rgba(255, 255, 255, 0.1); }
  [data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li:hover .menu-icon,
  [data-sidebarbg="color_10"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li:hover .menu-icon {
    background-color: var(--primary-light) !important; }
    [data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li:hover .menu-icon svg path,
    [data-sidebarbg="color_10"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li:hover .menu-icon svg path {
      stroke: black !important; }
  [data-sidebarbg="color_10"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_10"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: black; }
  [data-sidebarbg="color_10"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu li > ul li a,
  [data-sidebarbg="color_10"][data-layout="horizontal"] .deznav .metismenu li > ul li a {
    padding: 0.5rem 1.25rem 0.5rem 1.25rem; }
  [data-sidebarbg="color_10"][data-theme-version="dark"][data-layout="horizontal"][data-sidebar-style="full"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_10"][data-layout="horizontal"][data-sidebar-style="full"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: var(--primary) !important; }
  [data-sidebarbg="color_10"][data-theme-version="dark"] [data-layout="horizontal"][data-sidebar-style="icon-hover"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_10"] [data-layout="horizontal"][data-sidebar-style="icon-hover"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: var(--primary); }
  [data-sidebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu > li:hover > a .menu-icon, [data-sidebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu > li:focus > a .menu-icon,
  [data-sidebarbg="color_10"] .deznav .metismenu > li:hover > a .menu-icon,
  [data-sidebarbg="color_10"] .deznav .metismenu > li:focus > a .menu-icon {
    background-color: var(--primary-light); }
    [data-sidebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu > li:hover > a .menu-icon svg path, [data-sidebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu > li:focus > a .menu-icon svg path,
    [data-sidebarbg="color_10"] .deznav .metismenu > li:hover > a .menu-icon svg path,
    [data-sidebarbg="color_10"] .deznav .metismenu > li:focus > a .menu-icon svg path {
      stroke: black !important; }
  [data-sidebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu a,
  [data-sidebarbg="color_10"] .deznav .metismenu a {
    color: rgba(255, 255, 255, 0.8) !important; }
  [data-sidebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu li ul a:hover, [data-sidebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu li ul a:focus, [data-sidebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu li ul a.mm-active,
  [data-sidebarbg="color_10"] .deznav .metismenu li ul a:hover,
  [data-sidebarbg="color_10"] .deznav .metismenu li ul a:focus,
  [data-sidebarbg="color_10"] .deznav .metismenu li ul a.mm-active {
    color: #fff !important; }
  [data-sidebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_10"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: var(--primary); }
  [data-sidebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu > li > a,
  [data-sidebarbg="color_10"] .deznav .metismenu > li > a {
    color: rgba(255, 255, 255, 0.85) !important; }
    [data-sidebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu > li > a i,
    [data-sidebarbg="color_10"] .deznav .metismenu > li > a i {
      color: rgba(255, 255, 255, 0.85) !important; }
  [data-sidebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a,
  [data-sidebarbg="color_10"] .deznav .metismenu > li.mm-active > a {
    color: #fff; }
    [data-sidebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a i,
    [data-sidebarbg="color_10"] .deznav .metismenu > li.mm-active > a i {
      color: #fff !important; }
    [data-sidebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a:before,
    [data-sidebarbg="color_10"] .deznav .metismenu > li.mm-active > a:before {
      background: #fff !important; }
  [data-sidebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu .has-arrow:after,
  [data-sidebarbg="color_10"] .deznav .metismenu .has-arrow:after {
    border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85) !important; }
  [data-sidebarbg="color_10"][data-theme-version="dark"] .deznav .help-desk .btn,
  [data-sidebarbg="color_10"] .deznav .help-desk .btn {
    background-color: #9bddaa;
    border-color: #9bddaa; }
  [data-sidebarbg="color_10"][data-theme-version="dark"] .deznav .header-info2 span,
  [data-sidebarbg="color_10"] .deznav .header-info2 span {
    color: #fff; }
  [data-sidebarbg="color_10"][data-theme-version="dark"] .deznav .sidebar-info,
  [data-sidebarbg="color_10"] .deznav .sidebar-info {
    color: #fff; }
    [data-sidebarbg="color_10"][data-theme-version="dark"] .deznav .sidebar-info i,
    [data-sidebarbg="color_10"] .deznav .sidebar-info i {
      color: #fff; }
  [data-sidebarbg="color_10"][data-theme-version="dark"] .plus-box,
  [data-sidebarbg="color_10"] .plus-box {
    background-color: #9bddaa; }
  [data-sidebarbg="color_10"][data-theme-version="dark"] .copyright,
  [data-sidebarbg="color_10"] .copyright {
    color: #fff; }

[data-sidebarbg="color_11"][data-theme-version="dark"],
[data-sidebarbg="color_11"] {
  --sidebar-bg: #884d08;
  --nav-headbg: #884d08;
  --headerbg:   #884d08;
  --sidebar-nav-bg:#a05b09;
  /* &[data-sidebar-style="full"][data-layout="vertical"]{
				.deznav .metismenu > li.mm-active > a,
				.deznav .metismenu > li:hover > a{
						background:transparent;
				}	
			} */ }
  [data-sidebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu > li:hover > a .menu-icon svg path, [data-sidebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu > li:focus > a .menu-icon svg path,
  [data-sidebarbg="color_11"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_11"] .deznav .metismenu > li:focus > a .menu-icon svg path {
    stroke: white; }
  [data-sidebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a .menu-icon svg path,
  [data-sidebarbg="color_11"] .deznav .metismenu > li.mm-active > a .menu-icon svg path {
    stroke: white; }
  [data-sidebarbg="color_11"][data-theme-version="dark"] .switch-btn,
  [data-sidebarbg="color_11"] .switch-btn {
    border-color: rgba(255, 255, 255, 0.2); }
    [data-sidebarbg="color_11"][data-theme-version="dark"] .switch-btn svg path,
    [data-sidebarbg="color_11"] .switch-btn svg path {
      stroke: #fff; }
    [data-sidebarbg="color_11"][data-theme-version="dark"] .switch-btn span,
    [data-sidebarbg="color_11"] .switch-btn span {
      color: #fff; }
  [data-sidebarbg="color_11"][data-theme-version="dark"] .nav-header .brand-title path,
  [data-sidebarbg="color_11"] .nav-header .brand-title path {
    fill: #fff; }
  [data-sidebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu ul a:before,
  [data-sidebarbg="color_11"] .deznav .metismenu ul a:before {
    background: rgba(255, 255, 255, 0.5); }
  [data-sidebarbg="color_11"][data-theme-version="dark"] .deznav .menu-icon svg path,
  [data-sidebarbg="color_11"][data-theme-version="dark"] .deznav .menu-icon svg ellipse,
  [data-sidebarbg="color_11"] .deznav .menu-icon svg path,
  [data-sidebarbg="color_11"] .deznav .menu-icon svg ellipse {
    stroke: #fff; }
  [data-sidebarbg="color_11"][data-theme-version="dark"][data-layout="horizontal"][data-primary="color_13"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_11"][data-layout="horizontal"][data-primary="color_13"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: white !important; }
  [data-sidebarbg="color_11"][data-theme-version="dark"][data-layout="horizontal"],
  [data-sidebarbg="color_11"][data-layout="horizontal"] {
    --headerbg:#f4f4f5; }
  [data-sidebarbg="color_11"][data-theme-version="dark"][data-layout="vertical"] .deznav .menu-title,
  [data-sidebarbg="color_11"][data-layout="vertical"] .deznav .menu-title {
    color: #fff; }
  [data-sidebarbg="color_11"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a i,
  [data-sidebarbg="color_11"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a i {
    color: #fff; }
  [data-sidebarbg="color_11"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box h5, [data-sidebarbg="color_11"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box .h5,
  [data-sidebarbg="color_11"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box p,
  [data-sidebarbg="color_11"][data-layout="vertical"] .deznav .plus-box h5,
  [data-sidebarbg="color_11"][data-layout="vertical"] .deznav .plus-box .h5,
  [data-sidebarbg="color_11"][data-layout="vertical"] .deznav .plus-box p {
    color: #fff; }
  [data-sidebarbg="color_11"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li > a .menu-icon,
  [data-sidebarbg="color_11"][data-layout="vertical"] .deznav .metismenu > li > a .menu-icon {
    background-color: rgba(255, 255, 255, 0.1); }
  [data-sidebarbg="color_11"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon,
  [data-sidebarbg="color_11"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon {
    background-color: var(--primary-light); }
    [data-sidebarbg="color_11"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon svg path,
    [data-sidebarbg="color_11"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon svg path {
      stroke: black; }
  [data-sidebarbg="color_11"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon,
  [data-sidebarbg="color_11"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon {
    background-color: var(--primary-light); }
    [data-sidebarbg="color_11"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon svg path,
    [data-sidebarbg="color_11"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon svg path {
      stroke: black; }
  [data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="nini"][data-layout="horizontal"] .deznav .metismenu > li.mm-active > a .menu-icon,
  [data-sidebarbg="color_11"][data-sidebar-style="nini"][data-layout="horizontal"] .deznav .metismenu > li.mm-active > a .menu-icon {
    background-color: transparent; }
  [data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="nini"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon,
  [data-sidebarbg="color_11"][data-sidebar-style="nini"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon {
    background-color: var(--primary-light); }
  [data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="nini"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon,
  [data-sidebarbg="color_11"][data-sidebar-style="nini"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon {
    background-color: var(--primary-light); }
  [data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu > li:before,
  [data-sidebarbg="color_11"][data-sidebar-style="modern"] .deznav .metismenu > li:before {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 53.12%, rgba(255, 255, 255, 0) 100%); }
  [data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu > li.mm-active > a .menu-icon,
  [data-sidebarbg="color_11"][data-sidebar-style="modern"] .deznav .metismenu > li.mm-active > a .menu-icon {
    background-color: transparent !important; }
    [data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu > li.mm-active > a .menu-icon path fill,
    [data-sidebarbg="color_11"][data-sidebar-style="modern"] .deznav .metismenu > li.mm-active > a .menu-icon path fill {
      stroke: white !important; }
  [data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu li ul,
  [data-sidebarbg="color_11"][data-sidebar-style="modern"] .deznav .metismenu li ul {
    background-color: var(--sidebar-nav-bg); }
  [data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu > li:hover > a .menu-icon svg path, [data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu > li:focus > a .menu-icon svg path,
  [data-sidebarbg="color_11"][data-sidebar-style="modern"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_11"][data-sidebar-style="modern"] .deznav .metismenu > li:focus > a .menu-icon svg path {
    stroke: var(--primary) !important; }
  [data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:before,
  [data-sidebarbg="color_11"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:before {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 53.12%, rgba(255, 255, 255, 0) 100%); }
  [data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li > a .menu-icon,
  [data-sidebarbg="color_11"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li > a .menu-icon {
    background-color: transparent; }
  [data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon svg path,
  [data-sidebarbg="color_11"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon svg path {
    stroke: white; }
  [data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon,
  [data-sidebarbg="color_11"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon {
    background-color: transparent; }
    [data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon svg path,
    [data-sidebarbg="color_11"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon svg path {
      stroke: white; }
  [data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > a,
  [data-sidebarbg="color_11"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > a {
    color: var(--primary); }
    [data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > a .menu-icon svg path,
    [data-sidebarbg="color_11"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > a .menu-icon svg path {
      stroke: white; }
  [data-sidebarbg="color_11"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:hover > ul,
  [data-sidebarbg="color_11"][data-layout="horizontal"] .deznav .metismenu > li:hover > ul {
    background-color: var(--sidebar-nav-bg); }
  [data-sidebarbg="color_11"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu li > ul ul,
  [data-sidebarbg="color_11"][data-layout="horizontal"] .deznav .metismenu li > ul ul {
    background-color: var(--sidebar-nav-bg); }
  [data-sidebarbg="color_11"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon, [data-sidebarbg="color_11"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:focus > a .menu-icon,
  [data-sidebarbg="color_11"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon,
  [data-sidebarbg="color_11"][data-layout="horizontal"] .deznav .metismenu > li:focus > a .menu-icon {
    background-color: transparent !important; }
    [data-sidebarbg="color_11"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon svg path, [data-sidebarbg="color_11"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:focus > a .menu-icon svg path,
    [data-sidebarbg="color_11"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon svg path,
    [data-sidebarbg="color_11"][data-layout="horizontal"] .deznav .metismenu > li:focus > a .menu-icon svg path {
      stroke: var(--primary) !important; }
  [data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li a:hover,
  [data-sidebarbg="color_11"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li a:hover {
    color: #fff; }
  [data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li > a > i,
  [data-sidebarbg="color_11"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li > a > i {
    background: #f7bb74;
    color: rgba(255, 255, 255, 0.7); }
  [data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a, [data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li:hover > a,
  [data-sidebarbg="color_11"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a,
  [data-sidebarbg="color_11"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li:hover > a {
    box-shadow: none;
    background: transparent !important;
    color: #fff !important; }
    [data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a i, [data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li:hover > a i,
    [data-sidebarbg="color_11"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a i,
    [data-sidebarbg="color_11"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li:hover > a i {
      background: var(--primary);
      color: #fff !important; }
  [data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="modern"][data-sidebarbg="color_13"][data-primary="color_13"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_11"][data-sidebar-style="modern"][data-sidebarbg="color_13"][data-primary="color_13"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: white !important; }
  [data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li > a .menu-icon,
  [data-sidebarbg="color_11"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li > a .menu-icon {
    background-color: rgba(255, 255, 255, 0.1); }
  [data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li:hover .menu-icon,
  [data-sidebarbg="color_11"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li:hover .menu-icon {
    background-color: var(--primary-light) !important; }
    [data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li:hover .menu-icon svg path,
    [data-sidebarbg="color_11"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li:hover .menu-icon svg path {
      stroke: black !important; }
  [data-sidebarbg="color_11"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_11"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: black; }
  [data-sidebarbg="color_11"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu li > ul li a,
  [data-sidebarbg="color_11"][data-layout="horizontal"] .deznav .metismenu li > ul li a {
    padding: 0.5rem 1.25rem 0.5rem 1.25rem; }
  [data-sidebarbg="color_11"][data-theme-version="dark"][data-layout="horizontal"][data-sidebar-style="full"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_11"][data-layout="horizontal"][data-sidebar-style="full"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: var(--primary) !important; }
  [data-sidebarbg="color_11"][data-theme-version="dark"] [data-layout="horizontal"][data-sidebar-style="icon-hover"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_11"] [data-layout="horizontal"][data-sidebar-style="icon-hover"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: var(--primary); }
  [data-sidebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu > li:hover > a .menu-icon, [data-sidebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu > li:focus > a .menu-icon,
  [data-sidebarbg="color_11"] .deznav .metismenu > li:hover > a .menu-icon,
  [data-sidebarbg="color_11"] .deznav .metismenu > li:focus > a .menu-icon {
    background-color: var(--primary-light); }
    [data-sidebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu > li:hover > a .menu-icon svg path, [data-sidebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu > li:focus > a .menu-icon svg path,
    [data-sidebarbg="color_11"] .deznav .metismenu > li:hover > a .menu-icon svg path,
    [data-sidebarbg="color_11"] .deznav .metismenu > li:focus > a .menu-icon svg path {
      stroke: black !important; }
  [data-sidebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu a,
  [data-sidebarbg="color_11"] .deznav .metismenu a {
    color: rgba(255, 255, 255, 0.8) !important; }
  [data-sidebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu li ul a:hover, [data-sidebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu li ul a:focus, [data-sidebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu li ul a.mm-active,
  [data-sidebarbg="color_11"] .deznav .metismenu li ul a:hover,
  [data-sidebarbg="color_11"] .deznav .metismenu li ul a:focus,
  [data-sidebarbg="color_11"] .deznav .metismenu li ul a.mm-active {
    color: #fff !important; }
  [data-sidebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_11"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: var(--primary); }
  [data-sidebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu > li > a,
  [data-sidebarbg="color_11"] .deznav .metismenu > li > a {
    color: rgba(255, 255, 255, 0.85) !important; }
    [data-sidebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu > li > a i,
    [data-sidebarbg="color_11"] .deznav .metismenu > li > a i {
      color: rgba(255, 255, 255, 0.85) !important; }
  [data-sidebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a,
  [data-sidebarbg="color_11"] .deznav .metismenu > li.mm-active > a {
    color: #fff; }
    [data-sidebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a i,
    [data-sidebarbg="color_11"] .deznav .metismenu > li.mm-active > a i {
      color: #fff !important; }
    [data-sidebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a:before,
    [data-sidebarbg="color_11"] .deznav .metismenu > li.mm-active > a:before {
      background: #fff !important; }
  [data-sidebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu .has-arrow:after,
  [data-sidebarbg="color_11"] .deznav .metismenu .has-arrow:after {
    border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85) !important; }
  [data-sidebarbg="color_11"][data-theme-version="dark"] .deznav .help-desk .btn,
  [data-sidebarbg="color_11"] .deznav .help-desk .btn {
    background-color: #f9cc96;
    border-color: #f9cc96; }
  [data-sidebarbg="color_11"][data-theme-version="dark"] .deznav .header-info2 span,
  [data-sidebarbg="color_11"] .deznav .header-info2 span {
    color: #fff; }
  [data-sidebarbg="color_11"][data-theme-version="dark"] .deznav .sidebar-info,
  [data-sidebarbg="color_11"] .deznav .sidebar-info {
    color: #fff; }
    [data-sidebarbg="color_11"][data-theme-version="dark"] .deznav .sidebar-info i,
    [data-sidebarbg="color_11"] .deznav .sidebar-info i {
      color: #fff; }
  [data-sidebarbg="color_11"][data-theme-version="dark"] .plus-box,
  [data-sidebarbg="color_11"] .plus-box {
    background-color: #f9cc96; }
  [data-sidebarbg="color_11"][data-theme-version="dark"] .copyright,
  [data-sidebarbg="color_11"] .copyright {
    color: #fff; }

[data-sidebarbg="color_12"][data-theme-version="dark"],
[data-sidebarbg="color_12"] {
  --sidebar-bg: #343c1b;
  --nav-headbg: #343c1b;
  --headerbg:   #343c1b;
  --sidebar-nav-bg:#434e23;
  /* &[data-sidebar-style="full"][data-layout="vertical"]{
				.deznav .metismenu > li.mm-active > a,
				.deznav .metismenu > li:hover > a{
						background:transparent;
				}	
			} */ }
  [data-sidebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu > li:hover > a .menu-icon svg path, [data-sidebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu > li:focus > a .menu-icon svg path,
  [data-sidebarbg="color_12"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_12"] .deznav .metismenu > li:focus > a .menu-icon svg path {
    stroke: white; }
  [data-sidebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a .menu-icon svg path,
  [data-sidebarbg="color_12"] .deznav .metismenu > li.mm-active > a .menu-icon svg path {
    stroke: white; }
  [data-sidebarbg="color_12"][data-theme-version="dark"] .switch-btn,
  [data-sidebarbg="color_12"] .switch-btn {
    border-color: rgba(255, 255, 255, 0.2); }
    [data-sidebarbg="color_12"][data-theme-version="dark"] .switch-btn svg path,
    [data-sidebarbg="color_12"] .switch-btn svg path {
      stroke: #fff; }
    [data-sidebarbg="color_12"][data-theme-version="dark"] .switch-btn span,
    [data-sidebarbg="color_12"] .switch-btn span {
      color: #fff; }
  [data-sidebarbg="color_12"][data-theme-version="dark"] .nav-header .brand-title path,
  [data-sidebarbg="color_12"] .nav-header .brand-title path {
    fill: #fff; }
  [data-sidebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu ul a:before,
  [data-sidebarbg="color_12"] .deznav .metismenu ul a:before {
    background: rgba(255, 255, 255, 0.5); }
  [data-sidebarbg="color_12"][data-theme-version="dark"] .deznav .menu-icon svg path,
  [data-sidebarbg="color_12"][data-theme-version="dark"] .deznav .menu-icon svg ellipse,
  [data-sidebarbg="color_12"] .deznav .menu-icon svg path,
  [data-sidebarbg="color_12"] .deznav .menu-icon svg ellipse {
    stroke: #fff; }
  [data-sidebarbg="color_12"][data-theme-version="dark"][data-layout="horizontal"][data-primary="color_13"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_12"][data-layout="horizontal"][data-primary="color_13"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: white !important; }
  [data-sidebarbg="color_12"][data-theme-version="dark"][data-layout="horizontal"],
  [data-sidebarbg="color_12"][data-layout="horizontal"] {
    --headerbg:#f4f4f5; }
  [data-sidebarbg="color_12"][data-theme-version="dark"][data-layout="vertical"] .deznav .menu-title,
  [data-sidebarbg="color_12"][data-layout="vertical"] .deznav .menu-title {
    color: #fff; }
  [data-sidebarbg="color_12"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a i,
  [data-sidebarbg="color_12"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a i {
    color: #fff; }
  [data-sidebarbg="color_12"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box h5, [data-sidebarbg="color_12"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box .h5,
  [data-sidebarbg="color_12"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box p,
  [data-sidebarbg="color_12"][data-layout="vertical"] .deznav .plus-box h5,
  [data-sidebarbg="color_12"][data-layout="vertical"] .deznav .plus-box .h5,
  [data-sidebarbg="color_12"][data-layout="vertical"] .deznav .plus-box p {
    color: #fff; }
  [data-sidebarbg="color_12"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li > a .menu-icon,
  [data-sidebarbg="color_12"][data-layout="vertical"] .deznav .metismenu > li > a .menu-icon {
    background-color: rgba(255, 255, 255, 0.1); }
  [data-sidebarbg="color_12"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon,
  [data-sidebarbg="color_12"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon {
    background-color: var(--primary-light); }
    [data-sidebarbg="color_12"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon svg path,
    [data-sidebarbg="color_12"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon svg path {
      stroke: black; }
  [data-sidebarbg="color_12"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon,
  [data-sidebarbg="color_12"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon {
    background-color: var(--primary-light); }
    [data-sidebarbg="color_12"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon svg path,
    [data-sidebarbg="color_12"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon svg path {
      stroke: black; }
  [data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="nini"][data-layout="horizontal"] .deznav .metismenu > li.mm-active > a .menu-icon,
  [data-sidebarbg="color_12"][data-sidebar-style="nini"][data-layout="horizontal"] .deznav .metismenu > li.mm-active > a .menu-icon {
    background-color: transparent; }
  [data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="nini"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon,
  [data-sidebarbg="color_12"][data-sidebar-style="nini"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon {
    background-color: var(--primary-light); }
  [data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="nini"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon,
  [data-sidebarbg="color_12"][data-sidebar-style="nini"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon {
    background-color: var(--primary-light); }
  [data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu > li:before,
  [data-sidebarbg="color_12"][data-sidebar-style="modern"] .deznav .metismenu > li:before {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 53.12%, rgba(255, 255, 255, 0) 100%); }
  [data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu > li.mm-active > a .menu-icon,
  [data-sidebarbg="color_12"][data-sidebar-style="modern"] .deznav .metismenu > li.mm-active > a .menu-icon {
    background-color: transparent !important; }
    [data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu > li.mm-active > a .menu-icon path fill,
    [data-sidebarbg="color_12"][data-sidebar-style="modern"] .deznav .metismenu > li.mm-active > a .menu-icon path fill {
      stroke: white !important; }
  [data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu li ul,
  [data-sidebarbg="color_12"][data-sidebar-style="modern"] .deznav .metismenu li ul {
    background-color: var(--sidebar-nav-bg); }
  [data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu > li:hover > a .menu-icon svg path, [data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu > li:focus > a .menu-icon svg path,
  [data-sidebarbg="color_12"][data-sidebar-style="modern"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_12"][data-sidebar-style="modern"] .deznav .metismenu > li:focus > a .menu-icon svg path {
    stroke: var(--primary) !important; }
  [data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:before,
  [data-sidebarbg="color_12"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:before {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 53.12%, rgba(255, 255, 255, 0) 100%); }
  [data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li > a .menu-icon,
  [data-sidebarbg="color_12"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li > a .menu-icon {
    background-color: transparent; }
  [data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon svg path,
  [data-sidebarbg="color_12"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon svg path {
    stroke: white; }
  [data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon,
  [data-sidebarbg="color_12"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon {
    background-color: transparent; }
    [data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon svg path,
    [data-sidebarbg="color_12"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon svg path {
      stroke: white; }
  [data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > a,
  [data-sidebarbg="color_12"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > a {
    color: var(--primary); }
    [data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > a .menu-icon svg path,
    [data-sidebarbg="color_12"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > a .menu-icon svg path {
      stroke: white; }
  [data-sidebarbg="color_12"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:hover > ul,
  [data-sidebarbg="color_12"][data-layout="horizontal"] .deznav .metismenu > li:hover > ul {
    background-color: var(--sidebar-nav-bg); }
  [data-sidebarbg="color_12"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu li > ul ul,
  [data-sidebarbg="color_12"][data-layout="horizontal"] .deznav .metismenu li > ul ul {
    background-color: var(--sidebar-nav-bg); }
  [data-sidebarbg="color_12"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon, [data-sidebarbg="color_12"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:focus > a .menu-icon,
  [data-sidebarbg="color_12"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon,
  [data-sidebarbg="color_12"][data-layout="horizontal"] .deznav .metismenu > li:focus > a .menu-icon {
    background-color: transparent !important; }
    [data-sidebarbg="color_12"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon svg path, [data-sidebarbg="color_12"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:focus > a .menu-icon svg path,
    [data-sidebarbg="color_12"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon svg path,
    [data-sidebarbg="color_12"][data-layout="horizontal"] .deznav .metismenu > li:focus > a .menu-icon svg path {
      stroke: var(--primary) !important; }
  [data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li a:hover,
  [data-sidebarbg="color_12"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li a:hover {
    color: #fff; }
  [data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li > a > i,
  [data-sidebarbg="color_12"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li > a > i {
    background: #acc073;
    color: rgba(255, 255, 255, 0.7); }
  [data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a, [data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li:hover > a,
  [data-sidebarbg="color_12"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a,
  [data-sidebarbg="color_12"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li:hover > a {
    box-shadow: none;
    background: transparent !important;
    color: #fff !important; }
    [data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a i, [data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li:hover > a i,
    [data-sidebarbg="color_12"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a i,
    [data-sidebarbg="color_12"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li:hover > a i {
      background: var(--primary);
      color: #fff !important; }
  [data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="modern"][data-sidebarbg="color_13"][data-primary="color_13"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_12"][data-sidebar-style="modern"][data-sidebarbg="color_13"][data-primary="color_13"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: white !important; }
  [data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li > a .menu-icon,
  [data-sidebarbg="color_12"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li > a .menu-icon {
    background-color: rgba(255, 255, 255, 0.1); }
  [data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li:hover .menu-icon,
  [data-sidebarbg="color_12"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li:hover .menu-icon {
    background-color: var(--primary-light) !important; }
    [data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li:hover .menu-icon svg path,
    [data-sidebarbg="color_12"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li:hover .menu-icon svg path {
      stroke: black !important; }
  [data-sidebarbg="color_12"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_12"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: black; }
  [data-sidebarbg="color_12"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu li > ul li a,
  [data-sidebarbg="color_12"][data-layout="horizontal"] .deznav .metismenu li > ul li a {
    padding: 0.5rem 1.25rem 0.5rem 1.25rem; }
  [data-sidebarbg="color_12"][data-theme-version="dark"][data-layout="horizontal"][data-sidebar-style="full"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_12"][data-layout="horizontal"][data-sidebar-style="full"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: var(--primary) !important; }
  [data-sidebarbg="color_12"][data-theme-version="dark"] [data-layout="horizontal"][data-sidebar-style="icon-hover"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_12"] [data-layout="horizontal"][data-sidebar-style="icon-hover"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: var(--primary); }
  [data-sidebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu > li:hover > a .menu-icon, [data-sidebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu > li:focus > a .menu-icon,
  [data-sidebarbg="color_12"] .deznav .metismenu > li:hover > a .menu-icon,
  [data-sidebarbg="color_12"] .deznav .metismenu > li:focus > a .menu-icon {
    background-color: var(--primary-light); }
    [data-sidebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu > li:hover > a .menu-icon svg path, [data-sidebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu > li:focus > a .menu-icon svg path,
    [data-sidebarbg="color_12"] .deznav .metismenu > li:hover > a .menu-icon svg path,
    [data-sidebarbg="color_12"] .deznav .metismenu > li:focus > a .menu-icon svg path {
      stroke: black !important; }
  [data-sidebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu a,
  [data-sidebarbg="color_12"] .deznav .metismenu a {
    color: rgba(255, 255, 255, 0.8) !important; }
  [data-sidebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu li ul a:hover, [data-sidebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu li ul a:focus, [data-sidebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu li ul a.mm-active,
  [data-sidebarbg="color_12"] .deznav .metismenu li ul a:hover,
  [data-sidebarbg="color_12"] .deznav .metismenu li ul a:focus,
  [data-sidebarbg="color_12"] .deznav .metismenu li ul a.mm-active {
    color: #fff !important; }
  [data-sidebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_12"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: var(--primary); }
  [data-sidebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu > li > a,
  [data-sidebarbg="color_12"] .deznav .metismenu > li > a {
    color: rgba(255, 255, 255, 0.85) !important; }
    [data-sidebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu > li > a i,
    [data-sidebarbg="color_12"] .deznav .metismenu > li > a i {
      color: rgba(255, 255, 255, 0.85) !important; }
  [data-sidebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a,
  [data-sidebarbg="color_12"] .deznav .metismenu > li.mm-active > a {
    color: #fff; }
    [data-sidebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a i,
    [data-sidebarbg="color_12"] .deznav .metismenu > li.mm-active > a i {
      color: #fff !important; }
    [data-sidebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a:before,
    [data-sidebarbg="color_12"] .deznav .metismenu > li.mm-active > a:before {
      background: #fff !important; }
  [data-sidebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu .has-arrow:after,
  [data-sidebarbg="color_12"] .deznav .metismenu .has-arrow:after {
    border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85) !important; }
  [data-sidebarbg="color_12"][data-theme-version="dark"] .deznav .help-desk .btn,
  [data-sidebarbg="color_12"] .deznav .help-desk .btn {
    background-color: #bbcb8b;
    border-color: #bbcb8b; }
  [data-sidebarbg="color_12"][data-theme-version="dark"] .deznav .header-info2 span,
  [data-sidebarbg="color_12"] .deznav .header-info2 span {
    color: #fff; }
  [data-sidebarbg="color_12"][data-theme-version="dark"] .deznav .sidebar-info,
  [data-sidebarbg="color_12"] .deznav .sidebar-info {
    color: #fff; }
    [data-sidebarbg="color_12"][data-theme-version="dark"] .deznav .sidebar-info i,
    [data-sidebarbg="color_12"] .deznav .sidebar-info i {
      color: #fff; }
  [data-sidebarbg="color_12"][data-theme-version="dark"] .plus-box,
  [data-sidebarbg="color_12"] .plus-box {
    background-color: #bbcb8b; }
  [data-sidebarbg="color_12"][data-theme-version="dark"] .copyright,
  [data-sidebarbg="color_12"] .copyright {
    color: #fff; }

[data-sidebarbg="color_13"][data-theme-version="dark"],
[data-sidebarbg="color_13"] {
  --sidebar-bg: black;
  --nav-headbg: black;
  --headerbg:   black;
  --sidebar-nav-bg:black;
  /* &[data-sidebar-style="full"][data-layout="vertical"]{
				.deznav .metismenu > li.mm-active > a,
				.deznav .metismenu > li:hover > a{
						background:transparent;
				}	
			} */ }
  [data-sidebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu > li:hover > a .menu-icon svg path, [data-sidebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu > li:focus > a .menu-icon svg path,
  [data-sidebarbg="color_13"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_13"] .deznav .metismenu > li:focus > a .menu-icon svg path {
    stroke: white; }
  [data-sidebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a .menu-icon svg path,
  [data-sidebarbg="color_13"] .deznav .metismenu > li.mm-active > a .menu-icon svg path {
    stroke: white; }
  [data-sidebarbg="color_13"][data-theme-version="dark"] .switch-btn,
  [data-sidebarbg="color_13"] .switch-btn {
    border-color: rgba(255, 255, 255, 0.2); }
    [data-sidebarbg="color_13"][data-theme-version="dark"] .switch-btn svg path,
    [data-sidebarbg="color_13"] .switch-btn svg path {
      stroke: #fff; }
    [data-sidebarbg="color_13"][data-theme-version="dark"] .switch-btn span,
    [data-sidebarbg="color_13"] .switch-btn span {
      color: #fff; }
  [data-sidebarbg="color_13"][data-theme-version="dark"] .nav-header .brand-title path,
  [data-sidebarbg="color_13"] .nav-header .brand-title path {
    fill: #fff; }
  [data-sidebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu ul a:before,
  [data-sidebarbg="color_13"] .deznav .metismenu ul a:before {
    background: rgba(255, 255, 255, 0.5); }
  [data-sidebarbg="color_13"][data-theme-version="dark"] .deznav .menu-icon svg path,
  [data-sidebarbg="color_13"][data-theme-version="dark"] .deznav .menu-icon svg ellipse,
  [data-sidebarbg="color_13"] .deznav .menu-icon svg path,
  [data-sidebarbg="color_13"] .deznav .menu-icon svg ellipse {
    stroke: #fff; }
  [data-sidebarbg="color_13"][data-theme-version="dark"][data-layout="horizontal"][data-primary="color_13"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_13"][data-layout="horizontal"][data-primary="color_13"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: white !important; }
  [data-sidebarbg="color_13"][data-theme-version="dark"][data-layout="horizontal"],
  [data-sidebarbg="color_13"][data-layout="horizontal"] {
    --headerbg:#f4f4f5; }
  [data-sidebarbg="color_13"][data-theme-version="dark"][data-layout="vertical"] .deznav .menu-title,
  [data-sidebarbg="color_13"][data-layout="vertical"] .deznav .menu-title {
    color: #fff; }
  [data-sidebarbg="color_13"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a i,
  [data-sidebarbg="color_13"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a i {
    color: #fff; }
  [data-sidebarbg="color_13"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box h5, [data-sidebarbg="color_13"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box .h5,
  [data-sidebarbg="color_13"][data-theme-version="dark"][data-layout="vertical"] .deznav .plus-box p,
  [data-sidebarbg="color_13"][data-layout="vertical"] .deznav .plus-box h5,
  [data-sidebarbg="color_13"][data-layout="vertical"] .deznav .plus-box .h5,
  [data-sidebarbg="color_13"][data-layout="vertical"] .deznav .plus-box p {
    color: #fff; }
  [data-sidebarbg="color_13"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li > a .menu-icon,
  [data-sidebarbg="color_13"][data-layout="vertical"] .deznav .metismenu > li > a .menu-icon {
    background-color: rgba(255, 255, 255, 0.1); }
  [data-sidebarbg="color_13"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon,
  [data-sidebarbg="color_13"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon {
    background-color: var(--primary-light); }
    [data-sidebarbg="color_13"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon svg path,
    [data-sidebarbg="color_13"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon svg path {
      stroke: black; }
  [data-sidebarbg="color_13"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon,
  [data-sidebarbg="color_13"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon {
    background-color: var(--primary-light); }
    [data-sidebarbg="color_13"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon svg path,
    [data-sidebarbg="color_13"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon svg path {
      stroke: black; }
  [data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="nini"][data-layout="horizontal"] .deznav .metismenu > li.mm-active > a .menu-icon,
  [data-sidebarbg="color_13"][data-sidebar-style="nini"][data-layout="horizontal"] .deznav .metismenu > li.mm-active > a .menu-icon {
    background-color: transparent; }
  [data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="nini"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon,
  [data-sidebarbg="color_13"][data-sidebar-style="nini"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon {
    background-color: var(--primary-light); }
  [data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="nini"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon,
  [data-sidebarbg="color_13"][data-sidebar-style="nini"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon {
    background-color: var(--primary-light); }
  [data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu > li:before,
  [data-sidebarbg="color_13"][data-sidebar-style="modern"] .deznav .metismenu > li:before {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 53.12%, rgba(255, 255, 255, 0) 100%); }
  [data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu > li.mm-active > a .menu-icon,
  [data-sidebarbg="color_13"][data-sidebar-style="modern"] .deznav .metismenu > li.mm-active > a .menu-icon {
    background-color: transparent !important; }
    [data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu > li.mm-active > a .menu-icon path fill,
    [data-sidebarbg="color_13"][data-sidebar-style="modern"] .deznav .metismenu > li.mm-active > a .menu-icon path fill {
      stroke: white !important; }
  [data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu li ul,
  [data-sidebarbg="color_13"][data-sidebar-style="modern"] .deznav .metismenu li ul {
    background-color: var(--sidebar-nav-bg); }
  [data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu > li:hover > a .menu-icon svg path, [data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu > li:focus > a .menu-icon svg path,
  [data-sidebarbg="color_13"][data-sidebar-style="modern"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_13"][data-sidebar-style="modern"] .deznav .metismenu > li:focus > a .menu-icon svg path {
    stroke: var(--primary) !important; }
  [data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:before,
  [data-sidebarbg="color_13"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:before {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 53.12%, rgba(255, 255, 255, 0) 100%); }
  [data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li > a .menu-icon,
  [data-sidebarbg="color_13"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li > a .menu-icon {
    background-color: transparent; }
  [data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon svg path,
  [data-sidebarbg="color_13"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon svg path {
    stroke: white; }
  [data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon,
  [data-sidebarbg="color_13"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon {
    background-color: transparent; }
    [data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon svg path,
    [data-sidebarbg="color_13"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > .has-arrow .menu-icon svg path {
      stroke: white; }
  [data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > a,
  [data-sidebarbg="color_13"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > a {
    color: var(--primary); }
    [data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > a .menu-icon svg path,
    [data-sidebarbg="color_13"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li:hover > a .menu-icon svg path {
      stroke: white; }
  [data-sidebarbg="color_13"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:hover > ul,
  [data-sidebarbg="color_13"][data-layout="horizontal"] .deznav .metismenu > li:hover > ul {
    background-color: var(--sidebar-nav-bg); }
  [data-sidebarbg="color_13"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu li > ul ul,
  [data-sidebarbg="color_13"][data-layout="horizontal"] .deznav .metismenu li > ul ul {
    background-color: var(--sidebar-nav-bg); }
  [data-sidebarbg="color_13"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon, [data-sidebarbg="color_13"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:focus > a .menu-icon,
  [data-sidebarbg="color_13"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon,
  [data-sidebarbg="color_13"][data-layout="horizontal"] .deznav .metismenu > li:focus > a .menu-icon {
    background-color: transparent !important; }
    [data-sidebarbg="color_13"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon svg path, [data-sidebarbg="color_13"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:focus > a .menu-icon svg path,
    [data-sidebarbg="color_13"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon svg path,
    [data-sidebarbg="color_13"][data-layout="horizontal"] .deznav .metismenu > li:focus > a .menu-icon svg path {
      stroke: var(--primary) !important; }
  [data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li a:hover,
  [data-sidebarbg="color_13"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li a:hover {
    color: #fff; }
  [data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li > a > i,
  [data-sidebarbg="color_13"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li > a > i {
    background: #161e33;
    color: rgba(255, 255, 255, 0.7); }
  [data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a, [data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li:hover > a,
  [data-sidebarbg="color_13"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a,
  [data-sidebarbg="color_13"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li:hover > a {
    box-shadow: none;
    background: transparent !important;
    color: #fff !important; }
    [data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a i, [data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li:hover > a i,
    [data-sidebarbg="color_13"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a i,
    [data-sidebarbg="color_13"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu > li:hover > a i {
      background: var(--primary);
      color: #fff !important; }
  [data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="modern"][data-sidebarbg="color_13"][data-primary="color_13"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_13"][data-sidebar-style="modern"][data-sidebarbg="color_13"][data-primary="color_13"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: white !important; }
  [data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li > a .menu-icon,
  [data-sidebarbg="color_13"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li > a .menu-icon {
    background-color: rgba(255, 255, 255, 0.1); }
  [data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li:hover .menu-icon,
  [data-sidebarbg="color_13"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li:hover .menu-icon {
    background-color: var(--primary-light) !important; }
    [data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li:hover .menu-icon svg path,
    [data-sidebarbg="color_13"][data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu > li:hover .menu-icon svg path {
      stroke: black !important; }
  [data-sidebarbg="color_13"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_13"][data-layout="horizontal"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: black; }
  [data-sidebarbg="color_13"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu li > ul li a,
  [data-sidebarbg="color_13"][data-layout="horizontal"] .deznav .metismenu li > ul li a {
    padding: 0.5rem 1.25rem 0.5rem 1.25rem; }
  [data-sidebarbg="color_13"][data-theme-version="dark"][data-layout="horizontal"][data-sidebar-style="full"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_13"][data-layout="horizontal"][data-sidebar-style="full"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: var(--primary) !important; }
  [data-sidebarbg="color_13"][data-theme-version="dark"] [data-layout="horizontal"][data-sidebar-style="icon-hover"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_13"] [data-layout="horizontal"][data-sidebar-style="icon-hover"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: var(--primary); }
  [data-sidebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu > li:hover > a .menu-icon, [data-sidebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu > li:focus > a .menu-icon,
  [data-sidebarbg="color_13"] .deznav .metismenu > li:hover > a .menu-icon,
  [data-sidebarbg="color_13"] .deznav .metismenu > li:focus > a .menu-icon {
    background-color: var(--primary-light); }
    [data-sidebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu > li:hover > a .menu-icon svg path, [data-sidebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu > li:focus > a .menu-icon svg path,
    [data-sidebarbg="color_13"] .deznav .metismenu > li:hover > a .menu-icon svg path,
    [data-sidebarbg="color_13"] .deznav .metismenu > li:focus > a .menu-icon svg path {
      stroke: black !important; }
  [data-sidebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu a,
  [data-sidebarbg="color_13"] .deznav .metismenu a {
    color: rgba(255, 255, 255, 0.8) !important; }
  [data-sidebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu li ul a:hover, [data-sidebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu li ul a:focus, [data-sidebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu li ul a.mm-active,
  [data-sidebarbg="color_13"] .deznav .metismenu li ul a:hover,
  [data-sidebarbg="color_13"] .deznav .metismenu li ul a:focus,
  [data-sidebarbg="color_13"] .deznav .metismenu li ul a.mm-active {
    color: #fff !important; }
  [data-sidebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu > li:hover > a .menu-icon svg path,
  [data-sidebarbg="color_13"] .deznav .metismenu > li:hover > a .menu-icon svg path {
    stroke: var(--primary); }
  [data-sidebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu > li > a,
  [data-sidebarbg="color_13"] .deznav .metismenu > li > a {
    color: rgba(255, 255, 255, 0.85) !important; }
    [data-sidebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu > li > a i,
    [data-sidebarbg="color_13"] .deznav .metismenu > li > a i {
      color: rgba(255, 255, 255, 0.85) !important; }
  [data-sidebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a,
  [data-sidebarbg="color_13"] .deznav .metismenu > li.mm-active > a {
    color: #fff; }
    [data-sidebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a i,
    [data-sidebarbg="color_13"] .deznav .metismenu > li.mm-active > a i {
      color: #fff !important; }
    [data-sidebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a:before,
    [data-sidebarbg="color_13"] .deznav .metismenu > li.mm-active > a:before {
      background: #fff !important; }
  [data-sidebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu .has-arrow:after,
  [data-sidebarbg="color_13"] .deznav .metismenu .has-arrow:after {
    border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85) !important; }
  [data-sidebarbg="color_13"][data-theme-version="dark"] .deznav .help-desk .btn,
  [data-sidebarbg="color_13"] .deznav .help-desk .btn {
    background-color: #202d4c;
    border-color: #202d4c; }
  [data-sidebarbg="color_13"][data-theme-version="dark"] .deznav .header-info2 span,
  [data-sidebarbg="color_13"] .deznav .header-info2 span {
    color: #fff; }
  [data-sidebarbg="color_13"][data-theme-version="dark"] .deznav .sidebar-info,
  [data-sidebarbg="color_13"] .deznav .sidebar-info {
    color: #fff; }
    [data-sidebarbg="color_13"][data-theme-version="dark"] .deznav .sidebar-info i,
    [data-sidebarbg="color_13"] .deznav .sidebar-info i {
      color: #fff; }
  [data-sidebarbg="color_13"][data-theme-version="dark"] .plus-box,
  [data-sidebarbg="color_13"] .plus-box {
    background-color: #202d4c; }
  [data-sidebarbg="color_13"][data-theme-version="dark"] .copyright,
  [data-sidebarbg="color_13"] .copyright {
    color: #fff; }

[data-sidebarbg="color_13"] {
  --sidebar-bg: #111828;
  --nav-headbg: #111828;
  --headerbg: #111828;
  --sidebar-nav-bg: #1A2439; }

[data-headerbg="color_2"][data-theme-version="dark"],
[data-headerbg="color_2"] {
  --headerbg: #A0CF1A;
  /* &[data-headerbg="color_8"]{
					
				.search-area .input-group-text,
				.search-area .form-control,.search-area .form-control::placeholder{
					color:$black;
					background:#F1F1F1;
				}
				.header-left{
					.search-area .form-control,
					.search-area .input-group-text{
						i{
							color:$black;
						}
					}
					.dashboard_bar{
						color:$black;	
					}
					.plus-icon a{
						background-color:$black;	
					}
					
				}
				.hamburger .line{
					background:$black;
					}
				
				.header-right{
				svg{
					path{
						fill:rgba($black,0.4);	
					}
				}
			} */ }
  [data-headerbg="color_2"][data-theme-version="dark"] .header-left .search-area .form-control,
  [data-headerbg="color_2"][data-theme-version="dark"] .header-left .search-area .input-group-text,
  [data-headerbg="color_2"] .header-left .search-area .form-control,
  [data-headerbg="color_2"] .header-left .search-area .input-group-text {
    background-color: #b8e636; }
  [data-headerbg="color_2"][data-theme-version="dark"] .nav-control,
  [data-headerbg="color_2"] .nav-control {
    background-color: #b8e636; }
  [data-headerbg="color_2"][data-theme-version="dark"] .search-area .input-group-text,
  [data-headerbg="color_2"][data-theme-version="dark"] .search-area .form-control, [data-headerbg="color_2"][data-theme-version="dark"] .search-area .form-control::placeholder,
  [data-headerbg="color_2"] .search-area .input-group-text,
  [data-headerbg="color_2"] .search-area .form-control,
  [data-headerbg="color_2"] .search-area .form-control::placeholder {
    color: #fff; }
  [data-headerbg="color_2"][data-theme-version="dark"] .search-area .input-group-append .input-group-text i,
  [data-headerbg="color_2"] .search-area .input-group-append .input-group-text i {
    color: #fff; }
  [data-headerbg="color_2"][data-theme-version="dark"] .header-left .search-area,
  [data-headerbg="color_2"] .header-left .search-area {
    background-color: #b8e636; }
  [data-headerbg="color_2"][data-theme-version="dark"] .header-right .search-area .form-control,
  [data-headerbg="color_2"][data-theme-version="dark"] .header-right .search-area .input-group-text,
  [data-headerbg="color_2"] .header-right .search-area .form-control,
  [data-headerbg="color_2"] .header-right .search-area .input-group-text {
    background-color: #b8e636; }
    [data-headerbg="color_2"][data-theme-version="dark"] .header-right .search-area .form-control i,
    [data-headerbg="color_2"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
    [data-headerbg="color_2"] .header-right .search-area .form-control i,
    [data-headerbg="color_2"] .header-right .search-area .input-group-text i {
      color: #fff; }
    [data-headerbg="color_2"][data-theme-version="dark"] .header-right .search-area .form-control svg path,
    [data-headerbg="color_2"][data-theme-version="dark"] .header-right .search-area .input-group-text svg path,
    [data-headerbg="color_2"] .header-right .search-area .form-control svg path,
    [data-headerbg="color_2"] .header-right .search-area .input-group-text svg path {
      fill: #fff; }
  [data-headerbg="color_2"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link.show,
  [data-headerbg="color_2"] .header-right .notification_dropdown .nav-link.show {
    background-color: #202020; }
  [data-headerbg="color_2"][data-theme-version="dark"] .header-right .nav-item svg path,
  [data-headerbg="color_2"][data-theme-version="dark"] .header-right .nav-item svg circle,
  [data-headerbg="color_2"] .header-right .nav-item svg path,
  [data-headerbg="color_2"] .header-right .nav-item svg circle {
    stroke: #fff !important; }
  [data-headerbg="color_2"][data-theme-version="dark"] .header-right .nav-item.ps-3:hover,
  [data-headerbg="color_2"] .header-right .nav-item.ps-3:hover {
    background: transparent; }
  [data-headerbg="color_2"][data-theme-version="dark"] .header-right .nav-item .ai-icon svg path,
  [data-headerbg="color_2"][data-theme-version="dark"] .header-right .nav-item .ai-icon svg circle,
  [data-headerbg="color_2"] .header-right .nav-item .ai-icon svg path,
  [data-headerbg="color_2"] .header-right .nav-item .ai-icon svg circle {
    stroke: var(--primary) !important; }
  [data-headerbg="color_2"][data-theme-version="dark"] .header-right .header-border .btn,
  [data-headerbg="color_2"] .header-right .header-border .btn {
    background-color: #b8e636;
    border-color: #b8e636; }
  [data-headerbg="color_2"][data-theme-version="dark"] .header-right .header-border:hover,
  [data-headerbg="color_2"] .header-right .header-border:hover {
    background: transparent; }
  [data-headerbg="color_2"][data-theme-version="dark"] .header-right .header-info p,
  [data-headerbg="color_2"] .header-right .header-info p {
    color: #E1E1E1; }
  [data-headerbg="color_2"][data-theme-version="dark"] .header-right .header-info h6, [data-headerbg="color_2"][data-theme-version="dark"] .header-right .header-info .h6,
  [data-headerbg="color_2"] .header-right .header-info h6,
  [data-headerbg="color_2"] .header-right .header-info .h6 {
    color: #fff; }
  [data-headerbg="color_2"][data-theme-version="dark"] .header-left .dashboard_bar,
  [data-headerbg="color_2"] .header-left .dashboard_bar {
    color: #fff; }
  [data-headerbg="color_2"][data-theme-version="dark"] .header-left .search-area .input-group-text a svg path, [data-headerbg="color_2"][data-theme-version="dark"] .header-left .search-area .input-group-text a svg circle,
  [data-headerbg="color_2"] .header-left .search-area .input-group-text a svg path,
  [data-headerbg="color_2"] .header-left .search-area .input-group-text a svg circle {
    stroke: #fff; }
  [data-headerbg="color_2"][data-theme-version="dark"] .header-profile > a.nav-link .header-info small, [data-headerbg="color_2"][data-theme-version="dark"] .header-profile > a.nav-link .header-info .small, [data-headerbg="color_2"][data-theme-version="dark"] .header-profile > a.nav-link .header-info span,
  [data-headerbg="color_2"] .header-profile > a.nav-link .header-info small,
  [data-headerbg="color_2"] .header-profile > a.nav-link .header-info .small,
  [data-headerbg="color_2"] .header-profile > a.nav-link .header-info span {
    color: #fff; }
  [data-headerbg="color_2"][data-theme-version="dark"] .hamburger .line,
  [data-headerbg="color_2"] .hamburger .line {
    background: #fff !important; }
    [data-headerbg="color_2"][data-theme-version="dark"] .hamburger .line svg rect,
    [data-headerbg="color_2"] .hamburger .line svg rect {
      fill: #fff !important; }
  [data-headerbg="color_2"][data-theme-version="dark"] .hamburger svg rect,
  [data-headerbg="color_2"] .hamburger svg rect {
    fill: #fff !important; }
  [data-headerbg="color_2"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .input-group-text,
  [data-headerbg="color_2"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .form-control, [data-headerbg="color_2"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .form-control::placeholder,
  [data-headerbg="color_2"][data-headerbg="color_4"] .search-area .input-group-text,
  [data-headerbg="color_2"][data-headerbg="color_4"] .search-area .form-control,
  [data-headerbg="color_2"][data-headerbg="color_4"] .search-area .form-control::placeholder {
    color: var(--bs-card-color); }

[data-headerbg="color_3"][data-theme-version="dark"],
[data-headerbg="color_3"] {
  --headerbg: #FF7C7C;
  /* &[data-headerbg="color_8"]{
					
				.search-area .input-group-text,
				.search-area .form-control,.search-area .form-control::placeholder{
					color:$black;
					background:#F1F1F1;
				}
				.header-left{
					.search-area .form-control,
					.search-area .input-group-text{
						i{
							color:$black;
						}
					}
					.dashboard_bar{
						color:$black;	
					}
					.plus-icon a{
						background-color:$black;	
					}
					
				}
				.hamburger .line{
					background:$black;
					}
				
				.header-right{
				svg{
					path{
						fill:rgba($black,0.4);	
					}
				}
			} */ }
  [data-headerbg="color_3"][data-theme-version="dark"] .header-left .search-area .form-control,
  [data-headerbg="color_3"][data-theme-version="dark"] .header-left .search-area .input-group-text,
  [data-headerbg="color_3"] .header-left .search-area .form-control,
  [data-headerbg="color_3"] .header-left .search-area .input-group-text {
    background-color: #ffafaf; }
  [data-headerbg="color_3"][data-theme-version="dark"] .nav-control,
  [data-headerbg="color_3"] .nav-control {
    background-color: #ffafaf; }
  [data-headerbg="color_3"][data-theme-version="dark"] .search-area .input-group-text,
  [data-headerbg="color_3"][data-theme-version="dark"] .search-area .form-control, [data-headerbg="color_3"][data-theme-version="dark"] .search-area .form-control::placeholder,
  [data-headerbg="color_3"] .search-area .input-group-text,
  [data-headerbg="color_3"] .search-area .form-control,
  [data-headerbg="color_3"] .search-area .form-control::placeholder {
    color: #fff; }
  [data-headerbg="color_3"][data-theme-version="dark"] .search-area .input-group-append .input-group-text i,
  [data-headerbg="color_3"] .search-area .input-group-append .input-group-text i {
    color: #fff; }
  [data-headerbg="color_3"][data-theme-version="dark"] .header-left .search-area,
  [data-headerbg="color_3"] .header-left .search-area {
    background-color: #ffafaf; }
  [data-headerbg="color_3"][data-theme-version="dark"] .header-right .search-area .form-control,
  [data-headerbg="color_3"][data-theme-version="dark"] .header-right .search-area .input-group-text,
  [data-headerbg="color_3"] .header-right .search-area .form-control,
  [data-headerbg="color_3"] .header-right .search-area .input-group-text {
    background-color: #ffafaf; }
    [data-headerbg="color_3"][data-theme-version="dark"] .header-right .search-area .form-control i,
    [data-headerbg="color_3"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
    [data-headerbg="color_3"] .header-right .search-area .form-control i,
    [data-headerbg="color_3"] .header-right .search-area .input-group-text i {
      color: #fff; }
    [data-headerbg="color_3"][data-theme-version="dark"] .header-right .search-area .form-control svg path,
    [data-headerbg="color_3"][data-theme-version="dark"] .header-right .search-area .input-group-text svg path,
    [data-headerbg="color_3"] .header-right .search-area .form-control svg path,
    [data-headerbg="color_3"] .header-right .search-area .input-group-text svg path {
      fill: #fff; }
  [data-headerbg="color_3"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link.show,
  [data-headerbg="color_3"] .header-right .notification_dropdown .nav-link.show {
    background-color: #202020; }
  [data-headerbg="color_3"][data-theme-version="dark"] .header-right .nav-item svg path,
  [data-headerbg="color_3"][data-theme-version="dark"] .header-right .nav-item svg circle,
  [data-headerbg="color_3"] .header-right .nav-item svg path,
  [data-headerbg="color_3"] .header-right .nav-item svg circle {
    stroke: #fff !important; }
  [data-headerbg="color_3"][data-theme-version="dark"] .header-right .nav-item.ps-3:hover,
  [data-headerbg="color_3"] .header-right .nav-item.ps-3:hover {
    background: transparent; }
  [data-headerbg="color_3"][data-theme-version="dark"] .header-right .nav-item .ai-icon svg path,
  [data-headerbg="color_3"][data-theme-version="dark"] .header-right .nav-item .ai-icon svg circle,
  [data-headerbg="color_3"] .header-right .nav-item .ai-icon svg path,
  [data-headerbg="color_3"] .header-right .nav-item .ai-icon svg circle {
    stroke: var(--primary) !important; }
  [data-headerbg="color_3"][data-theme-version="dark"] .header-right .header-border .btn,
  [data-headerbg="color_3"] .header-right .header-border .btn {
    background-color: #ffafaf;
    border-color: #ffafaf; }
  [data-headerbg="color_3"][data-theme-version="dark"] .header-right .header-border:hover,
  [data-headerbg="color_3"] .header-right .header-border:hover {
    background: transparent; }
  [data-headerbg="color_3"][data-theme-version="dark"] .header-right .header-info p,
  [data-headerbg="color_3"] .header-right .header-info p {
    color: #E1E1E1; }
  [data-headerbg="color_3"][data-theme-version="dark"] .header-right .header-info h6, [data-headerbg="color_3"][data-theme-version="dark"] .header-right .header-info .h6,
  [data-headerbg="color_3"] .header-right .header-info h6,
  [data-headerbg="color_3"] .header-right .header-info .h6 {
    color: #fff; }
  [data-headerbg="color_3"][data-theme-version="dark"] .header-left .dashboard_bar,
  [data-headerbg="color_3"] .header-left .dashboard_bar {
    color: #fff; }
  [data-headerbg="color_3"][data-theme-version="dark"] .header-left .search-area .input-group-text a svg path, [data-headerbg="color_3"][data-theme-version="dark"] .header-left .search-area .input-group-text a svg circle,
  [data-headerbg="color_3"] .header-left .search-area .input-group-text a svg path,
  [data-headerbg="color_3"] .header-left .search-area .input-group-text a svg circle {
    stroke: #fff; }
  [data-headerbg="color_3"][data-theme-version="dark"] .header-profile > a.nav-link .header-info small, [data-headerbg="color_3"][data-theme-version="dark"] .header-profile > a.nav-link .header-info .small, [data-headerbg="color_3"][data-theme-version="dark"] .header-profile > a.nav-link .header-info span,
  [data-headerbg="color_3"] .header-profile > a.nav-link .header-info small,
  [data-headerbg="color_3"] .header-profile > a.nav-link .header-info .small,
  [data-headerbg="color_3"] .header-profile > a.nav-link .header-info span {
    color: #fff; }
  [data-headerbg="color_3"][data-theme-version="dark"] .hamburger .line,
  [data-headerbg="color_3"] .hamburger .line {
    background: #fff !important; }
    [data-headerbg="color_3"][data-theme-version="dark"] .hamburger .line svg rect,
    [data-headerbg="color_3"] .hamburger .line svg rect {
      fill: #fff !important; }
  [data-headerbg="color_3"][data-theme-version="dark"] .hamburger svg rect,
  [data-headerbg="color_3"] .hamburger svg rect {
    fill: #fff !important; }
  [data-headerbg="color_3"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .input-group-text,
  [data-headerbg="color_3"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .form-control, [data-headerbg="color_3"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .form-control::placeholder,
  [data-headerbg="color_3"][data-headerbg="color_4"] .search-area .input-group-text,
  [data-headerbg="color_3"][data-headerbg="color_4"] .search-area .form-control,
  [data-headerbg="color_3"][data-headerbg="color_4"] .search-area .form-control::placeholder {
    color: var(--bs-card-color); }

[data-headerbg="color_4"][data-theme-version="dark"],
[data-headerbg="color_4"] {
  --headerbg: #81A4F9;
  /* &[data-headerbg="color_8"]{
					
				.search-area .input-group-text,
				.search-area .form-control,.search-area .form-control::placeholder{
					color:$black;
					background:#F1F1F1;
				}
				.header-left{
					.search-area .form-control,
					.search-area .input-group-text{
						i{
							color:$black;
						}
					}
					.dashboard_bar{
						color:$black;	
					}
					.plus-icon a{
						background-color:$black;	
					}
					
				}
				.hamburger .line{
					background:$black;
					}
				
				.header-right{
				svg{
					path{
						fill:rgba($black,0.4);	
					}
				}
			} */ }
  [data-headerbg="color_4"][data-theme-version="dark"] .header-left .search-area .form-control,
  [data-headerbg="color_4"][data-theme-version="dark"] .header-left .search-area .input-group-text,
  [data-headerbg="color_4"] .header-left .search-area .form-control,
  [data-headerbg="color_4"] .header-left .search-area .input-group-text {
    background-color: #b2c7fb; }
  [data-headerbg="color_4"][data-theme-version="dark"] .nav-control,
  [data-headerbg="color_4"] .nav-control {
    background-color: #b2c7fb; }
  [data-headerbg="color_4"][data-theme-version="dark"] .search-area .input-group-text,
  [data-headerbg="color_4"][data-theme-version="dark"] .search-area .form-control, [data-headerbg="color_4"][data-theme-version="dark"] .search-area .form-control::placeholder,
  [data-headerbg="color_4"] .search-area .input-group-text,
  [data-headerbg="color_4"] .search-area .form-control,
  [data-headerbg="color_4"] .search-area .form-control::placeholder {
    color: #fff; }
  [data-headerbg="color_4"][data-theme-version="dark"] .search-area .input-group-append .input-group-text i,
  [data-headerbg="color_4"] .search-area .input-group-append .input-group-text i {
    color: #fff; }
  [data-headerbg="color_4"][data-theme-version="dark"] .header-left .search-area,
  [data-headerbg="color_4"] .header-left .search-area {
    background-color: #b2c7fb; }
  [data-headerbg="color_4"][data-theme-version="dark"] .header-right .search-area .form-control,
  [data-headerbg="color_4"][data-theme-version="dark"] .header-right .search-area .input-group-text,
  [data-headerbg="color_4"] .header-right .search-area .form-control,
  [data-headerbg="color_4"] .header-right .search-area .input-group-text {
    background-color: #b2c7fb; }
    [data-headerbg="color_4"][data-theme-version="dark"] .header-right .search-area .form-control i,
    [data-headerbg="color_4"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
    [data-headerbg="color_4"] .header-right .search-area .form-control i,
    [data-headerbg="color_4"] .header-right .search-area .input-group-text i {
      color: #fff; }
    [data-headerbg="color_4"][data-theme-version="dark"] .header-right .search-area .form-control svg path,
    [data-headerbg="color_4"][data-theme-version="dark"] .header-right .search-area .input-group-text svg path,
    [data-headerbg="color_4"] .header-right .search-area .form-control svg path,
    [data-headerbg="color_4"] .header-right .search-area .input-group-text svg path {
      fill: #fff; }
  [data-headerbg="color_4"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link.show,
  [data-headerbg="color_4"] .header-right .notification_dropdown .nav-link.show {
    background-color: #202020; }
  [data-headerbg="color_4"][data-theme-version="dark"] .header-right .nav-item svg path,
  [data-headerbg="color_4"][data-theme-version="dark"] .header-right .nav-item svg circle,
  [data-headerbg="color_4"] .header-right .nav-item svg path,
  [data-headerbg="color_4"] .header-right .nav-item svg circle {
    stroke: #fff !important; }
  [data-headerbg="color_4"][data-theme-version="dark"] .header-right .nav-item.ps-3:hover,
  [data-headerbg="color_4"] .header-right .nav-item.ps-3:hover {
    background: transparent; }
  [data-headerbg="color_4"][data-theme-version="dark"] .header-right .nav-item .ai-icon svg path,
  [data-headerbg="color_4"][data-theme-version="dark"] .header-right .nav-item .ai-icon svg circle,
  [data-headerbg="color_4"] .header-right .nav-item .ai-icon svg path,
  [data-headerbg="color_4"] .header-right .nav-item .ai-icon svg circle {
    stroke: var(--primary) !important; }
  [data-headerbg="color_4"][data-theme-version="dark"] .header-right .header-border .btn,
  [data-headerbg="color_4"] .header-right .header-border .btn {
    background-color: #b2c7fb;
    border-color: #b2c7fb; }
  [data-headerbg="color_4"][data-theme-version="dark"] .header-right .header-border:hover,
  [data-headerbg="color_4"] .header-right .header-border:hover {
    background: transparent; }
  [data-headerbg="color_4"][data-theme-version="dark"] .header-right .header-info p,
  [data-headerbg="color_4"] .header-right .header-info p {
    color: #E1E1E1; }
  [data-headerbg="color_4"][data-theme-version="dark"] .header-right .header-info h6, [data-headerbg="color_4"][data-theme-version="dark"] .header-right .header-info .h6,
  [data-headerbg="color_4"] .header-right .header-info h6,
  [data-headerbg="color_4"] .header-right .header-info .h6 {
    color: #fff; }
  [data-headerbg="color_4"][data-theme-version="dark"] .header-left .dashboard_bar,
  [data-headerbg="color_4"] .header-left .dashboard_bar {
    color: #fff; }
  [data-headerbg="color_4"][data-theme-version="dark"] .header-left .search-area .input-group-text a svg path, [data-headerbg="color_4"][data-theme-version="dark"] .header-left .search-area .input-group-text a svg circle,
  [data-headerbg="color_4"] .header-left .search-area .input-group-text a svg path,
  [data-headerbg="color_4"] .header-left .search-area .input-group-text a svg circle {
    stroke: #fff; }
  [data-headerbg="color_4"][data-theme-version="dark"] .header-profile > a.nav-link .header-info small, [data-headerbg="color_4"][data-theme-version="dark"] .header-profile > a.nav-link .header-info .small, [data-headerbg="color_4"][data-theme-version="dark"] .header-profile > a.nav-link .header-info span,
  [data-headerbg="color_4"] .header-profile > a.nav-link .header-info small,
  [data-headerbg="color_4"] .header-profile > a.nav-link .header-info .small,
  [data-headerbg="color_4"] .header-profile > a.nav-link .header-info span {
    color: #fff; }
  [data-headerbg="color_4"][data-theme-version="dark"] .hamburger .line,
  [data-headerbg="color_4"] .hamburger .line {
    background: #fff !important; }
    [data-headerbg="color_4"][data-theme-version="dark"] .hamburger .line svg rect,
    [data-headerbg="color_4"] .hamburger .line svg rect {
      fill: #fff !important; }
  [data-headerbg="color_4"][data-theme-version="dark"] .hamburger svg rect,
  [data-headerbg="color_4"] .hamburger svg rect {
    fill: #fff !important; }
  [data-headerbg="color_4"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .input-group-text,
  [data-headerbg="color_4"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .form-control, [data-headerbg="color_4"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .form-control::placeholder,
  [data-headerbg="color_4"][data-headerbg="color_4"] .search-area .input-group-text,
  [data-headerbg="color_4"][data-headerbg="color_4"] .search-area .form-control,
  [data-headerbg="color_4"][data-headerbg="color_4"] .search-area .form-control::placeholder {
    color: var(--bs-card-color); }

[data-headerbg="color_5"][data-theme-version="dark"],
[data-headerbg="color_5"] {
  --headerbg: #53D0B2;
  /* &[data-headerbg="color_8"]{
					
				.search-area .input-group-text,
				.search-area .form-control,.search-area .form-control::placeholder{
					color:$black;
					background:#F1F1F1;
				}
				.header-left{
					.search-area .form-control,
					.search-area .input-group-text{
						i{
							color:$black;
						}
					}
					.dashboard_bar{
						color:$black;	
					}
					.plus-icon a{
						background-color:$black;	
					}
					
				}
				.hamburger .line{
					background:$black;
					}
				
				.header-right{
				svg{
					path{
						fill:rgba($black,0.4);	
					}
				}
			} */ }
  [data-headerbg="color_5"][data-theme-version="dark"] .header-left .search-area .form-control,
  [data-headerbg="color_5"][data-theme-version="dark"] .header-left .search-area .input-group-text,
  [data-headerbg="color_5"] .header-left .search-area .form-control,
  [data-headerbg="color_5"] .header-left .search-area .input-group-text {
    background-color: #7bdbc4; }
  [data-headerbg="color_5"][data-theme-version="dark"] .nav-control,
  [data-headerbg="color_5"] .nav-control {
    background-color: #7bdbc4; }
  [data-headerbg="color_5"][data-theme-version="dark"] .search-area .input-group-text,
  [data-headerbg="color_5"][data-theme-version="dark"] .search-area .form-control, [data-headerbg="color_5"][data-theme-version="dark"] .search-area .form-control::placeholder,
  [data-headerbg="color_5"] .search-area .input-group-text,
  [data-headerbg="color_5"] .search-area .form-control,
  [data-headerbg="color_5"] .search-area .form-control::placeholder {
    color: #fff; }
  [data-headerbg="color_5"][data-theme-version="dark"] .search-area .input-group-append .input-group-text i,
  [data-headerbg="color_5"] .search-area .input-group-append .input-group-text i {
    color: #fff; }
  [data-headerbg="color_5"][data-theme-version="dark"] .header-left .search-area,
  [data-headerbg="color_5"] .header-left .search-area {
    background-color: #7bdbc4; }
  [data-headerbg="color_5"][data-theme-version="dark"] .header-right .search-area .form-control,
  [data-headerbg="color_5"][data-theme-version="dark"] .header-right .search-area .input-group-text,
  [data-headerbg="color_5"] .header-right .search-area .form-control,
  [data-headerbg="color_5"] .header-right .search-area .input-group-text {
    background-color: #7bdbc4; }
    [data-headerbg="color_5"][data-theme-version="dark"] .header-right .search-area .form-control i,
    [data-headerbg="color_5"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
    [data-headerbg="color_5"] .header-right .search-area .form-control i,
    [data-headerbg="color_5"] .header-right .search-area .input-group-text i {
      color: #fff; }
    [data-headerbg="color_5"][data-theme-version="dark"] .header-right .search-area .form-control svg path,
    [data-headerbg="color_5"][data-theme-version="dark"] .header-right .search-area .input-group-text svg path,
    [data-headerbg="color_5"] .header-right .search-area .form-control svg path,
    [data-headerbg="color_5"] .header-right .search-area .input-group-text svg path {
      fill: #fff; }
  [data-headerbg="color_5"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link.show,
  [data-headerbg="color_5"] .header-right .notification_dropdown .nav-link.show {
    background-color: #202020; }
  [data-headerbg="color_5"][data-theme-version="dark"] .header-right .nav-item svg path,
  [data-headerbg="color_5"][data-theme-version="dark"] .header-right .nav-item svg circle,
  [data-headerbg="color_5"] .header-right .nav-item svg path,
  [data-headerbg="color_5"] .header-right .nav-item svg circle {
    stroke: #fff !important; }
  [data-headerbg="color_5"][data-theme-version="dark"] .header-right .nav-item.ps-3:hover,
  [data-headerbg="color_5"] .header-right .nav-item.ps-3:hover {
    background: transparent; }
  [data-headerbg="color_5"][data-theme-version="dark"] .header-right .nav-item .ai-icon svg path,
  [data-headerbg="color_5"][data-theme-version="dark"] .header-right .nav-item .ai-icon svg circle,
  [data-headerbg="color_5"] .header-right .nav-item .ai-icon svg path,
  [data-headerbg="color_5"] .header-right .nav-item .ai-icon svg circle {
    stroke: var(--primary) !important; }
  [data-headerbg="color_5"][data-theme-version="dark"] .header-right .header-border .btn,
  [data-headerbg="color_5"] .header-right .header-border .btn {
    background-color: #7bdbc4;
    border-color: #7bdbc4; }
  [data-headerbg="color_5"][data-theme-version="dark"] .header-right .header-border:hover,
  [data-headerbg="color_5"] .header-right .header-border:hover {
    background: transparent; }
  [data-headerbg="color_5"][data-theme-version="dark"] .header-right .header-info p,
  [data-headerbg="color_5"] .header-right .header-info p {
    color: #E1E1E1; }
  [data-headerbg="color_5"][data-theme-version="dark"] .header-right .header-info h6, [data-headerbg="color_5"][data-theme-version="dark"] .header-right .header-info .h6,
  [data-headerbg="color_5"] .header-right .header-info h6,
  [data-headerbg="color_5"] .header-right .header-info .h6 {
    color: #fff; }
  [data-headerbg="color_5"][data-theme-version="dark"] .header-left .dashboard_bar,
  [data-headerbg="color_5"] .header-left .dashboard_bar {
    color: #fff; }
  [data-headerbg="color_5"][data-theme-version="dark"] .header-left .search-area .input-group-text a svg path, [data-headerbg="color_5"][data-theme-version="dark"] .header-left .search-area .input-group-text a svg circle,
  [data-headerbg="color_5"] .header-left .search-area .input-group-text a svg path,
  [data-headerbg="color_5"] .header-left .search-area .input-group-text a svg circle {
    stroke: #fff; }
  [data-headerbg="color_5"][data-theme-version="dark"] .header-profile > a.nav-link .header-info small, [data-headerbg="color_5"][data-theme-version="dark"] .header-profile > a.nav-link .header-info .small, [data-headerbg="color_5"][data-theme-version="dark"] .header-profile > a.nav-link .header-info span,
  [data-headerbg="color_5"] .header-profile > a.nav-link .header-info small,
  [data-headerbg="color_5"] .header-profile > a.nav-link .header-info .small,
  [data-headerbg="color_5"] .header-profile > a.nav-link .header-info span {
    color: #fff; }
  [data-headerbg="color_5"][data-theme-version="dark"] .hamburger .line,
  [data-headerbg="color_5"] .hamburger .line {
    background: #fff !important; }
    [data-headerbg="color_5"][data-theme-version="dark"] .hamburger .line svg rect,
    [data-headerbg="color_5"] .hamburger .line svg rect {
      fill: #fff !important; }
  [data-headerbg="color_5"][data-theme-version="dark"] .hamburger svg rect,
  [data-headerbg="color_5"] .hamburger svg rect {
    fill: #fff !important; }
  [data-headerbg="color_5"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .input-group-text,
  [data-headerbg="color_5"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .form-control, [data-headerbg="color_5"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .form-control::placeholder,
  [data-headerbg="color_5"][data-headerbg="color_4"] .search-area .input-group-text,
  [data-headerbg="color_5"][data-headerbg="color_4"] .search-area .form-control,
  [data-headerbg="color_5"][data-headerbg="color_4"] .search-area .form-control::placeholder {
    color: var(--bs-card-color); }

[data-headerbg="color_6"][data-theme-version="dark"],
[data-headerbg="color_6"] {
  --headerbg: #F4CF0C;
  /* &[data-headerbg="color_8"]{
					
				.search-area .input-group-text,
				.search-area .form-control,.search-area .form-control::placeholder{
					color:$black;
					background:#F1F1F1;
				}
				.header-left{
					.search-area .form-control,
					.search-area .input-group-text{
						i{
							color:$black;
						}
					}
					.dashboard_bar{
						color:$black;	
					}
					.plus-icon a{
						background-color:$black;	
					}
					
				}
				.hamburger .line{
					background:$black;
					}
				
				.header-right{
				svg{
					path{
						fill:rgba($black,0.4);	
					}
				}
			} */ }
  [data-headerbg="color_6"][data-theme-version="dark"] .header-left .search-area .form-control,
  [data-headerbg="color_6"][data-theme-version="dark"] .header-left .search-area .input-group-text,
  [data-headerbg="color_6"] .header-left .search-area .form-control,
  [data-headerbg="color_6"] .header-left .search-area .input-group-text {
    background-color: #f6d93d; }
  [data-headerbg="color_6"][data-theme-version="dark"] .nav-control,
  [data-headerbg="color_6"] .nav-control {
    background-color: #f6d93d; }
  [data-headerbg="color_6"][data-theme-version="dark"] .search-area .input-group-text,
  [data-headerbg="color_6"][data-theme-version="dark"] .search-area .form-control, [data-headerbg="color_6"][data-theme-version="dark"] .search-area .form-control::placeholder,
  [data-headerbg="color_6"] .search-area .input-group-text,
  [data-headerbg="color_6"] .search-area .form-control,
  [data-headerbg="color_6"] .search-area .form-control::placeholder {
    color: #fff; }
  [data-headerbg="color_6"][data-theme-version="dark"] .search-area .input-group-append .input-group-text i,
  [data-headerbg="color_6"] .search-area .input-group-append .input-group-text i {
    color: #fff; }
  [data-headerbg="color_6"][data-theme-version="dark"] .header-left .search-area,
  [data-headerbg="color_6"] .header-left .search-area {
    background-color: #f6d93d; }
  [data-headerbg="color_6"][data-theme-version="dark"] .header-right .search-area .form-control,
  [data-headerbg="color_6"][data-theme-version="dark"] .header-right .search-area .input-group-text,
  [data-headerbg="color_6"] .header-right .search-area .form-control,
  [data-headerbg="color_6"] .header-right .search-area .input-group-text {
    background-color: #f6d93d; }
    [data-headerbg="color_6"][data-theme-version="dark"] .header-right .search-area .form-control i,
    [data-headerbg="color_6"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
    [data-headerbg="color_6"] .header-right .search-area .form-control i,
    [data-headerbg="color_6"] .header-right .search-area .input-group-text i {
      color: #fff; }
    [data-headerbg="color_6"][data-theme-version="dark"] .header-right .search-area .form-control svg path,
    [data-headerbg="color_6"][data-theme-version="dark"] .header-right .search-area .input-group-text svg path,
    [data-headerbg="color_6"] .header-right .search-area .form-control svg path,
    [data-headerbg="color_6"] .header-right .search-area .input-group-text svg path {
      fill: #fff; }
  [data-headerbg="color_6"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link.show,
  [data-headerbg="color_6"] .header-right .notification_dropdown .nav-link.show {
    background-color: #202020; }
  [data-headerbg="color_6"][data-theme-version="dark"] .header-right .nav-item svg path,
  [data-headerbg="color_6"][data-theme-version="dark"] .header-right .nav-item svg circle,
  [data-headerbg="color_6"] .header-right .nav-item svg path,
  [data-headerbg="color_6"] .header-right .nav-item svg circle {
    stroke: #fff !important; }
  [data-headerbg="color_6"][data-theme-version="dark"] .header-right .nav-item.ps-3:hover,
  [data-headerbg="color_6"] .header-right .nav-item.ps-3:hover {
    background: transparent; }
  [data-headerbg="color_6"][data-theme-version="dark"] .header-right .nav-item .ai-icon svg path,
  [data-headerbg="color_6"][data-theme-version="dark"] .header-right .nav-item .ai-icon svg circle,
  [data-headerbg="color_6"] .header-right .nav-item .ai-icon svg path,
  [data-headerbg="color_6"] .header-right .nav-item .ai-icon svg circle {
    stroke: var(--primary) !important; }
  [data-headerbg="color_6"][data-theme-version="dark"] .header-right .header-border .btn,
  [data-headerbg="color_6"] .header-right .header-border .btn {
    background-color: #f6d93d;
    border-color: #f6d93d; }
  [data-headerbg="color_6"][data-theme-version="dark"] .header-right .header-border:hover,
  [data-headerbg="color_6"] .header-right .header-border:hover {
    background: transparent; }
  [data-headerbg="color_6"][data-theme-version="dark"] .header-right .header-info p,
  [data-headerbg="color_6"] .header-right .header-info p {
    color: #E1E1E1; }
  [data-headerbg="color_6"][data-theme-version="dark"] .header-right .header-info h6, [data-headerbg="color_6"][data-theme-version="dark"] .header-right .header-info .h6,
  [data-headerbg="color_6"] .header-right .header-info h6,
  [data-headerbg="color_6"] .header-right .header-info .h6 {
    color: #fff; }
  [data-headerbg="color_6"][data-theme-version="dark"] .header-left .dashboard_bar,
  [data-headerbg="color_6"] .header-left .dashboard_bar {
    color: #fff; }
  [data-headerbg="color_6"][data-theme-version="dark"] .header-left .search-area .input-group-text a svg path, [data-headerbg="color_6"][data-theme-version="dark"] .header-left .search-area .input-group-text a svg circle,
  [data-headerbg="color_6"] .header-left .search-area .input-group-text a svg path,
  [data-headerbg="color_6"] .header-left .search-area .input-group-text a svg circle {
    stroke: #fff; }
  [data-headerbg="color_6"][data-theme-version="dark"] .header-profile > a.nav-link .header-info small, [data-headerbg="color_6"][data-theme-version="dark"] .header-profile > a.nav-link .header-info .small, [data-headerbg="color_6"][data-theme-version="dark"] .header-profile > a.nav-link .header-info span,
  [data-headerbg="color_6"] .header-profile > a.nav-link .header-info small,
  [data-headerbg="color_6"] .header-profile > a.nav-link .header-info .small,
  [data-headerbg="color_6"] .header-profile > a.nav-link .header-info span {
    color: #fff; }
  [data-headerbg="color_6"][data-theme-version="dark"] .hamburger .line,
  [data-headerbg="color_6"] .hamburger .line {
    background: #fff !important; }
    [data-headerbg="color_6"][data-theme-version="dark"] .hamburger .line svg rect,
    [data-headerbg="color_6"] .hamburger .line svg rect {
      fill: #fff !important; }
  [data-headerbg="color_6"][data-theme-version="dark"] .hamburger svg rect,
  [data-headerbg="color_6"] .hamburger svg rect {
    fill: #fff !important; }
  [data-headerbg="color_6"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .input-group-text,
  [data-headerbg="color_6"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .form-control, [data-headerbg="color_6"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .form-control::placeholder,
  [data-headerbg="color_6"][data-headerbg="color_4"] .search-area .input-group-text,
  [data-headerbg="color_6"][data-headerbg="color_4"] .search-area .form-control,
  [data-headerbg="color_6"][data-headerbg="color_4"] .search-area .form-control::placeholder {
    color: var(--bs-card-color); }

[data-headerbg="color_7"][data-theme-version="dark"],
[data-headerbg="color_7"] {
  --headerbg: #50D5DB;
  /* &[data-headerbg="color_8"]{
					
				.search-area .input-group-text,
				.search-area .form-control,.search-area .form-control::placeholder{
					color:$black;
					background:#F1F1F1;
				}
				.header-left{
					.search-area .form-control,
					.search-area .input-group-text{
						i{
							color:$black;
						}
					}
					.dashboard_bar{
						color:$black;	
					}
					.plus-icon a{
						background-color:$black;	
					}
					
				}
				.hamburger .line{
					background:$black;
					}
				
				.header-right{
				svg{
					path{
						fill:rgba($black,0.4);	
					}
				}
			} */ }
  [data-headerbg="color_7"][data-theme-version="dark"] .header-left .search-area .form-control,
  [data-headerbg="color_7"][data-theme-version="dark"] .header-left .search-area .input-group-text,
  [data-headerbg="color_7"] .header-left .search-area .form-control,
  [data-headerbg="color_7"] .header-left .search-area .input-group-text {
    background-color: #7adfe4; }
  [data-headerbg="color_7"][data-theme-version="dark"] .nav-control,
  [data-headerbg="color_7"] .nav-control {
    background-color: #7adfe4; }
  [data-headerbg="color_7"][data-theme-version="dark"] .search-area .input-group-text,
  [data-headerbg="color_7"][data-theme-version="dark"] .search-area .form-control, [data-headerbg="color_7"][data-theme-version="dark"] .search-area .form-control::placeholder,
  [data-headerbg="color_7"] .search-area .input-group-text,
  [data-headerbg="color_7"] .search-area .form-control,
  [data-headerbg="color_7"] .search-area .form-control::placeholder {
    color: #fff; }
  [data-headerbg="color_7"][data-theme-version="dark"] .search-area .input-group-append .input-group-text i,
  [data-headerbg="color_7"] .search-area .input-group-append .input-group-text i {
    color: #fff; }
  [data-headerbg="color_7"][data-theme-version="dark"] .header-left .search-area,
  [data-headerbg="color_7"] .header-left .search-area {
    background-color: #7adfe4; }
  [data-headerbg="color_7"][data-theme-version="dark"] .header-right .search-area .form-control,
  [data-headerbg="color_7"][data-theme-version="dark"] .header-right .search-area .input-group-text,
  [data-headerbg="color_7"] .header-right .search-area .form-control,
  [data-headerbg="color_7"] .header-right .search-area .input-group-text {
    background-color: #7adfe4; }
    [data-headerbg="color_7"][data-theme-version="dark"] .header-right .search-area .form-control i,
    [data-headerbg="color_7"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
    [data-headerbg="color_7"] .header-right .search-area .form-control i,
    [data-headerbg="color_7"] .header-right .search-area .input-group-text i {
      color: #fff; }
    [data-headerbg="color_7"][data-theme-version="dark"] .header-right .search-area .form-control svg path,
    [data-headerbg="color_7"][data-theme-version="dark"] .header-right .search-area .input-group-text svg path,
    [data-headerbg="color_7"] .header-right .search-area .form-control svg path,
    [data-headerbg="color_7"] .header-right .search-area .input-group-text svg path {
      fill: #fff; }
  [data-headerbg="color_7"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link.show,
  [data-headerbg="color_7"] .header-right .notification_dropdown .nav-link.show {
    background-color: #202020; }
  [data-headerbg="color_7"][data-theme-version="dark"] .header-right .nav-item svg path,
  [data-headerbg="color_7"][data-theme-version="dark"] .header-right .nav-item svg circle,
  [data-headerbg="color_7"] .header-right .nav-item svg path,
  [data-headerbg="color_7"] .header-right .nav-item svg circle {
    stroke: #fff !important; }
  [data-headerbg="color_7"][data-theme-version="dark"] .header-right .nav-item.ps-3:hover,
  [data-headerbg="color_7"] .header-right .nav-item.ps-3:hover {
    background: transparent; }
  [data-headerbg="color_7"][data-theme-version="dark"] .header-right .nav-item .ai-icon svg path,
  [data-headerbg="color_7"][data-theme-version="dark"] .header-right .nav-item .ai-icon svg circle,
  [data-headerbg="color_7"] .header-right .nav-item .ai-icon svg path,
  [data-headerbg="color_7"] .header-right .nav-item .ai-icon svg circle {
    stroke: var(--primary) !important; }
  [data-headerbg="color_7"][data-theme-version="dark"] .header-right .header-border .btn,
  [data-headerbg="color_7"] .header-right .header-border .btn {
    background-color: #7adfe4;
    border-color: #7adfe4; }
  [data-headerbg="color_7"][data-theme-version="dark"] .header-right .header-border:hover,
  [data-headerbg="color_7"] .header-right .header-border:hover {
    background: transparent; }
  [data-headerbg="color_7"][data-theme-version="dark"] .header-right .header-info p,
  [data-headerbg="color_7"] .header-right .header-info p {
    color: #E1E1E1; }
  [data-headerbg="color_7"][data-theme-version="dark"] .header-right .header-info h6, [data-headerbg="color_7"][data-theme-version="dark"] .header-right .header-info .h6,
  [data-headerbg="color_7"] .header-right .header-info h6,
  [data-headerbg="color_7"] .header-right .header-info .h6 {
    color: #fff; }
  [data-headerbg="color_7"][data-theme-version="dark"] .header-left .dashboard_bar,
  [data-headerbg="color_7"] .header-left .dashboard_bar {
    color: #fff; }
  [data-headerbg="color_7"][data-theme-version="dark"] .header-left .search-area .input-group-text a svg path, [data-headerbg="color_7"][data-theme-version="dark"] .header-left .search-area .input-group-text a svg circle,
  [data-headerbg="color_7"] .header-left .search-area .input-group-text a svg path,
  [data-headerbg="color_7"] .header-left .search-area .input-group-text a svg circle {
    stroke: #fff; }
  [data-headerbg="color_7"][data-theme-version="dark"] .header-profile > a.nav-link .header-info small, [data-headerbg="color_7"][data-theme-version="dark"] .header-profile > a.nav-link .header-info .small, [data-headerbg="color_7"][data-theme-version="dark"] .header-profile > a.nav-link .header-info span,
  [data-headerbg="color_7"] .header-profile > a.nav-link .header-info small,
  [data-headerbg="color_7"] .header-profile > a.nav-link .header-info .small,
  [data-headerbg="color_7"] .header-profile > a.nav-link .header-info span {
    color: #fff; }
  [data-headerbg="color_7"][data-theme-version="dark"] .hamburger .line,
  [data-headerbg="color_7"] .hamburger .line {
    background: #fff !important; }
    [data-headerbg="color_7"][data-theme-version="dark"] .hamburger .line svg rect,
    [data-headerbg="color_7"] .hamburger .line svg rect {
      fill: #fff !important; }
  [data-headerbg="color_7"][data-theme-version="dark"] .hamburger svg rect,
  [data-headerbg="color_7"] .hamburger svg rect {
    fill: #fff !important; }
  [data-headerbg="color_7"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .input-group-text,
  [data-headerbg="color_7"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .form-control, [data-headerbg="color_7"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .form-control::placeholder,
  [data-headerbg="color_7"][data-headerbg="color_4"] .search-area .input-group-text,
  [data-headerbg="color_7"][data-headerbg="color_4"] .search-area .form-control,
  [data-headerbg="color_7"][data-headerbg="color_4"] .search-area .form-control::placeholder {
    color: var(--bs-card-color); }

[data-headerbg="color_8"][data-theme-version="dark"],
[data-headerbg="color_8"] {
  --headerbg: #D58FE5;
  /* &[data-headerbg="color_8"]{
					
				.search-area .input-group-text,
				.search-area .form-control,.search-area .form-control::placeholder{
					color:$black;
					background:#F1F1F1;
				}
				.header-left{
					.search-area .form-control,
					.search-area .input-group-text{
						i{
							color:$black;
						}
					}
					.dashboard_bar{
						color:$black;	
					}
					.plus-icon a{
						background-color:$black;	
					}
					
				}
				.hamburger .line{
					background:$black;
					}
				
				.header-right{
				svg{
					path{
						fill:rgba($black,0.4);	
					}
				}
			} */ }
  [data-headerbg="color_8"][data-theme-version="dark"] .header-left .search-area .form-control,
  [data-headerbg="color_8"][data-theme-version="dark"] .header-left .search-area .input-group-text,
  [data-headerbg="color_8"] .header-left .search-area .form-control,
  [data-headerbg="color_8"] .header-left .search-area .input-group-text {
    background-color: #e5b8ef; }
  [data-headerbg="color_8"][data-theme-version="dark"] .nav-control,
  [data-headerbg="color_8"] .nav-control {
    background-color: #e5b8ef; }
  [data-headerbg="color_8"][data-theme-version="dark"] .search-area .input-group-text,
  [data-headerbg="color_8"][data-theme-version="dark"] .search-area .form-control, [data-headerbg="color_8"][data-theme-version="dark"] .search-area .form-control::placeholder,
  [data-headerbg="color_8"] .search-area .input-group-text,
  [data-headerbg="color_8"] .search-area .form-control,
  [data-headerbg="color_8"] .search-area .form-control::placeholder {
    color: #fff; }
  [data-headerbg="color_8"][data-theme-version="dark"] .search-area .input-group-append .input-group-text i,
  [data-headerbg="color_8"] .search-area .input-group-append .input-group-text i {
    color: #fff; }
  [data-headerbg="color_8"][data-theme-version="dark"] .header-left .search-area,
  [data-headerbg="color_8"] .header-left .search-area {
    background-color: #e5b8ef; }
  [data-headerbg="color_8"][data-theme-version="dark"] .header-right .search-area .form-control,
  [data-headerbg="color_8"][data-theme-version="dark"] .header-right .search-area .input-group-text,
  [data-headerbg="color_8"] .header-right .search-area .form-control,
  [data-headerbg="color_8"] .header-right .search-area .input-group-text {
    background-color: #e5b8ef; }
    [data-headerbg="color_8"][data-theme-version="dark"] .header-right .search-area .form-control i,
    [data-headerbg="color_8"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
    [data-headerbg="color_8"] .header-right .search-area .form-control i,
    [data-headerbg="color_8"] .header-right .search-area .input-group-text i {
      color: #fff; }
    [data-headerbg="color_8"][data-theme-version="dark"] .header-right .search-area .form-control svg path,
    [data-headerbg="color_8"][data-theme-version="dark"] .header-right .search-area .input-group-text svg path,
    [data-headerbg="color_8"] .header-right .search-area .form-control svg path,
    [data-headerbg="color_8"] .header-right .search-area .input-group-text svg path {
      fill: #fff; }
  [data-headerbg="color_8"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link.show,
  [data-headerbg="color_8"] .header-right .notification_dropdown .nav-link.show {
    background-color: #202020; }
  [data-headerbg="color_8"][data-theme-version="dark"] .header-right .nav-item svg path,
  [data-headerbg="color_8"][data-theme-version="dark"] .header-right .nav-item svg circle,
  [data-headerbg="color_8"] .header-right .nav-item svg path,
  [data-headerbg="color_8"] .header-right .nav-item svg circle {
    stroke: #fff !important; }
  [data-headerbg="color_8"][data-theme-version="dark"] .header-right .nav-item.ps-3:hover,
  [data-headerbg="color_8"] .header-right .nav-item.ps-3:hover {
    background: transparent; }
  [data-headerbg="color_8"][data-theme-version="dark"] .header-right .nav-item .ai-icon svg path,
  [data-headerbg="color_8"][data-theme-version="dark"] .header-right .nav-item .ai-icon svg circle,
  [data-headerbg="color_8"] .header-right .nav-item .ai-icon svg path,
  [data-headerbg="color_8"] .header-right .nav-item .ai-icon svg circle {
    stroke: var(--primary) !important; }
  [data-headerbg="color_8"][data-theme-version="dark"] .header-right .header-border .btn,
  [data-headerbg="color_8"] .header-right .header-border .btn {
    background-color: #e5b8ef;
    border-color: #e5b8ef; }
  [data-headerbg="color_8"][data-theme-version="dark"] .header-right .header-border:hover,
  [data-headerbg="color_8"] .header-right .header-border:hover {
    background: transparent; }
  [data-headerbg="color_8"][data-theme-version="dark"] .header-right .header-info p,
  [data-headerbg="color_8"] .header-right .header-info p {
    color: #E1E1E1; }
  [data-headerbg="color_8"][data-theme-version="dark"] .header-right .header-info h6, [data-headerbg="color_8"][data-theme-version="dark"] .header-right .header-info .h6,
  [data-headerbg="color_8"] .header-right .header-info h6,
  [data-headerbg="color_8"] .header-right .header-info .h6 {
    color: #fff; }
  [data-headerbg="color_8"][data-theme-version="dark"] .header-left .dashboard_bar,
  [data-headerbg="color_8"] .header-left .dashboard_bar {
    color: #fff; }
  [data-headerbg="color_8"][data-theme-version="dark"] .header-left .search-area .input-group-text a svg path, [data-headerbg="color_8"][data-theme-version="dark"] .header-left .search-area .input-group-text a svg circle,
  [data-headerbg="color_8"] .header-left .search-area .input-group-text a svg path,
  [data-headerbg="color_8"] .header-left .search-area .input-group-text a svg circle {
    stroke: #fff; }
  [data-headerbg="color_8"][data-theme-version="dark"] .header-profile > a.nav-link .header-info small, [data-headerbg="color_8"][data-theme-version="dark"] .header-profile > a.nav-link .header-info .small, [data-headerbg="color_8"][data-theme-version="dark"] .header-profile > a.nav-link .header-info span,
  [data-headerbg="color_8"] .header-profile > a.nav-link .header-info small,
  [data-headerbg="color_8"] .header-profile > a.nav-link .header-info .small,
  [data-headerbg="color_8"] .header-profile > a.nav-link .header-info span {
    color: #fff; }
  [data-headerbg="color_8"][data-theme-version="dark"] .hamburger .line,
  [data-headerbg="color_8"] .hamburger .line {
    background: #fff !important; }
    [data-headerbg="color_8"][data-theme-version="dark"] .hamburger .line svg rect,
    [data-headerbg="color_8"] .hamburger .line svg rect {
      fill: #fff !important; }
  [data-headerbg="color_8"][data-theme-version="dark"] .hamburger svg rect,
  [data-headerbg="color_8"] .hamburger svg rect {
    fill: #fff !important; }
  [data-headerbg="color_8"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .input-group-text,
  [data-headerbg="color_8"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .form-control, [data-headerbg="color_8"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .form-control::placeholder,
  [data-headerbg="color_8"][data-headerbg="color_4"] .search-area .input-group-text,
  [data-headerbg="color_8"][data-headerbg="color_4"] .search-area .form-control,
  [data-headerbg="color_8"][data-headerbg="color_4"] .search-area .form-control::placeholder {
    color: var(--bs-card-color); }

[data-headerbg="color_9"][data-theme-version="dark"],
[data-headerbg="color_9"] {
  --headerbg: #7FB9EE;
  /* &[data-headerbg="color_8"]{
					
				.search-area .input-group-text,
				.search-area .form-control,.search-area .form-control::placeholder{
					color:$black;
					background:#F1F1F1;
				}
				.header-left{
					.search-area .form-control,
					.search-area .input-group-text{
						i{
							color:$black;
						}
					}
					.dashboard_bar{
						color:$black;	
					}
					.plus-icon a{
						background-color:$black;	
					}
					
				}
				.hamburger .line{
					background:$black;
					}
				
				.header-right{
				svg{
					path{
						fill:rgba($black,0.4);	
					}
				}
			} */ }
  [data-headerbg="color_9"][data-theme-version="dark"] .header-left .search-area .form-control,
  [data-headerbg="color_9"][data-theme-version="dark"] .header-left .search-area .input-group-text,
  [data-headerbg="color_9"] .header-left .search-area .form-control,
  [data-headerbg="color_9"] .header-left .search-area .input-group-text {
    background-color: #acd2f4; }
  [data-headerbg="color_9"][data-theme-version="dark"] .nav-control,
  [data-headerbg="color_9"] .nav-control {
    background-color: #acd2f4; }
  [data-headerbg="color_9"][data-theme-version="dark"] .search-area .input-group-text,
  [data-headerbg="color_9"][data-theme-version="dark"] .search-area .form-control, [data-headerbg="color_9"][data-theme-version="dark"] .search-area .form-control::placeholder,
  [data-headerbg="color_9"] .search-area .input-group-text,
  [data-headerbg="color_9"] .search-area .form-control,
  [data-headerbg="color_9"] .search-area .form-control::placeholder {
    color: #fff; }
  [data-headerbg="color_9"][data-theme-version="dark"] .search-area .input-group-append .input-group-text i,
  [data-headerbg="color_9"] .search-area .input-group-append .input-group-text i {
    color: #fff; }
  [data-headerbg="color_9"][data-theme-version="dark"] .header-left .search-area,
  [data-headerbg="color_9"] .header-left .search-area {
    background-color: #acd2f4; }
  [data-headerbg="color_9"][data-theme-version="dark"] .header-right .search-area .form-control,
  [data-headerbg="color_9"][data-theme-version="dark"] .header-right .search-area .input-group-text,
  [data-headerbg="color_9"] .header-right .search-area .form-control,
  [data-headerbg="color_9"] .header-right .search-area .input-group-text {
    background-color: #acd2f4; }
    [data-headerbg="color_9"][data-theme-version="dark"] .header-right .search-area .form-control i,
    [data-headerbg="color_9"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
    [data-headerbg="color_9"] .header-right .search-area .form-control i,
    [data-headerbg="color_9"] .header-right .search-area .input-group-text i {
      color: #fff; }
    [data-headerbg="color_9"][data-theme-version="dark"] .header-right .search-area .form-control svg path,
    [data-headerbg="color_9"][data-theme-version="dark"] .header-right .search-area .input-group-text svg path,
    [data-headerbg="color_9"] .header-right .search-area .form-control svg path,
    [data-headerbg="color_9"] .header-right .search-area .input-group-text svg path {
      fill: #fff; }
  [data-headerbg="color_9"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link.show,
  [data-headerbg="color_9"] .header-right .notification_dropdown .nav-link.show {
    background-color: #202020; }
  [data-headerbg="color_9"][data-theme-version="dark"] .header-right .nav-item svg path,
  [data-headerbg="color_9"][data-theme-version="dark"] .header-right .nav-item svg circle,
  [data-headerbg="color_9"] .header-right .nav-item svg path,
  [data-headerbg="color_9"] .header-right .nav-item svg circle {
    stroke: #fff !important; }
  [data-headerbg="color_9"][data-theme-version="dark"] .header-right .nav-item.ps-3:hover,
  [data-headerbg="color_9"] .header-right .nav-item.ps-3:hover {
    background: transparent; }
  [data-headerbg="color_9"][data-theme-version="dark"] .header-right .nav-item .ai-icon svg path,
  [data-headerbg="color_9"][data-theme-version="dark"] .header-right .nav-item .ai-icon svg circle,
  [data-headerbg="color_9"] .header-right .nav-item .ai-icon svg path,
  [data-headerbg="color_9"] .header-right .nav-item .ai-icon svg circle {
    stroke: var(--primary) !important; }
  [data-headerbg="color_9"][data-theme-version="dark"] .header-right .header-border .btn,
  [data-headerbg="color_9"] .header-right .header-border .btn {
    background-color: #acd2f4;
    border-color: #acd2f4; }
  [data-headerbg="color_9"][data-theme-version="dark"] .header-right .header-border:hover,
  [data-headerbg="color_9"] .header-right .header-border:hover {
    background: transparent; }
  [data-headerbg="color_9"][data-theme-version="dark"] .header-right .header-info p,
  [data-headerbg="color_9"] .header-right .header-info p {
    color: #E1E1E1; }
  [data-headerbg="color_9"][data-theme-version="dark"] .header-right .header-info h6, [data-headerbg="color_9"][data-theme-version="dark"] .header-right .header-info .h6,
  [data-headerbg="color_9"] .header-right .header-info h6,
  [data-headerbg="color_9"] .header-right .header-info .h6 {
    color: #fff; }
  [data-headerbg="color_9"][data-theme-version="dark"] .header-left .dashboard_bar,
  [data-headerbg="color_9"] .header-left .dashboard_bar {
    color: #fff; }
  [data-headerbg="color_9"][data-theme-version="dark"] .header-left .search-area .input-group-text a svg path, [data-headerbg="color_9"][data-theme-version="dark"] .header-left .search-area .input-group-text a svg circle,
  [data-headerbg="color_9"] .header-left .search-area .input-group-text a svg path,
  [data-headerbg="color_9"] .header-left .search-area .input-group-text a svg circle {
    stroke: #fff; }
  [data-headerbg="color_9"][data-theme-version="dark"] .header-profile > a.nav-link .header-info small, [data-headerbg="color_9"][data-theme-version="dark"] .header-profile > a.nav-link .header-info .small, [data-headerbg="color_9"][data-theme-version="dark"] .header-profile > a.nav-link .header-info span,
  [data-headerbg="color_9"] .header-profile > a.nav-link .header-info small,
  [data-headerbg="color_9"] .header-profile > a.nav-link .header-info .small,
  [data-headerbg="color_9"] .header-profile > a.nav-link .header-info span {
    color: #fff; }
  [data-headerbg="color_9"][data-theme-version="dark"] .hamburger .line,
  [data-headerbg="color_9"] .hamburger .line {
    background: #fff !important; }
    [data-headerbg="color_9"][data-theme-version="dark"] .hamburger .line svg rect,
    [data-headerbg="color_9"] .hamburger .line svg rect {
      fill: #fff !important; }
  [data-headerbg="color_9"][data-theme-version="dark"] .hamburger svg rect,
  [data-headerbg="color_9"] .hamburger svg rect {
    fill: #fff !important; }
  [data-headerbg="color_9"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .input-group-text,
  [data-headerbg="color_9"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .form-control, [data-headerbg="color_9"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .form-control::placeholder,
  [data-headerbg="color_9"][data-headerbg="color_4"] .search-area .input-group-text,
  [data-headerbg="color_9"][data-headerbg="color_4"] .search-area .form-control,
  [data-headerbg="color_9"][data-headerbg="color_4"] .search-area .form-control::placeholder {
    color: var(--bs-card-color); }

[data-headerbg="color_10"][data-theme-version="dark"],
[data-headerbg="color_10"] {
  --headerbg: #75D089;
  /* &[data-headerbg="color_8"]{
					
				.search-area .input-group-text,
				.search-area .form-control,.search-area .form-control::placeholder{
					color:$black;
					background:#F1F1F1;
				}
				.header-left{
					.search-area .form-control,
					.search-area .input-group-text{
						i{
							color:$black;
						}
					}
					.dashboard_bar{
						color:$black;	
					}
					.plus-icon a{
						background-color:$black;	
					}
					
				}
				.hamburger .line{
					background:$black;
					}
				
				.header-right{
				svg{
					path{
						fill:rgba($black,0.4);	
					}
				}
			} */ }
  [data-headerbg="color_10"][data-theme-version="dark"] .header-left .search-area .form-control,
  [data-headerbg="color_10"][data-theme-version="dark"] .header-left .search-area .input-group-text,
  [data-headerbg="color_10"] .header-left .search-area .form-control,
  [data-headerbg="color_10"] .header-left .search-area .input-group-text {
    background-color: #9bddaa; }
  [data-headerbg="color_10"][data-theme-version="dark"] .nav-control,
  [data-headerbg="color_10"] .nav-control {
    background-color: #9bddaa; }
  [data-headerbg="color_10"][data-theme-version="dark"] .search-area .input-group-text,
  [data-headerbg="color_10"][data-theme-version="dark"] .search-area .form-control, [data-headerbg="color_10"][data-theme-version="dark"] .search-area .form-control::placeholder,
  [data-headerbg="color_10"] .search-area .input-group-text,
  [data-headerbg="color_10"] .search-area .form-control,
  [data-headerbg="color_10"] .search-area .form-control::placeholder {
    color: #fff; }
  [data-headerbg="color_10"][data-theme-version="dark"] .search-area .input-group-append .input-group-text i,
  [data-headerbg="color_10"] .search-area .input-group-append .input-group-text i {
    color: #fff; }
  [data-headerbg="color_10"][data-theme-version="dark"] .header-left .search-area,
  [data-headerbg="color_10"] .header-left .search-area {
    background-color: #9bddaa; }
  [data-headerbg="color_10"][data-theme-version="dark"] .header-right .search-area .form-control,
  [data-headerbg="color_10"][data-theme-version="dark"] .header-right .search-area .input-group-text,
  [data-headerbg="color_10"] .header-right .search-area .form-control,
  [data-headerbg="color_10"] .header-right .search-area .input-group-text {
    background-color: #9bddaa; }
    [data-headerbg="color_10"][data-theme-version="dark"] .header-right .search-area .form-control i,
    [data-headerbg="color_10"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
    [data-headerbg="color_10"] .header-right .search-area .form-control i,
    [data-headerbg="color_10"] .header-right .search-area .input-group-text i {
      color: #fff; }
    [data-headerbg="color_10"][data-theme-version="dark"] .header-right .search-area .form-control svg path,
    [data-headerbg="color_10"][data-theme-version="dark"] .header-right .search-area .input-group-text svg path,
    [data-headerbg="color_10"] .header-right .search-area .form-control svg path,
    [data-headerbg="color_10"] .header-right .search-area .input-group-text svg path {
      fill: #fff; }
  [data-headerbg="color_10"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link.show,
  [data-headerbg="color_10"] .header-right .notification_dropdown .nav-link.show {
    background-color: #202020; }
  [data-headerbg="color_10"][data-theme-version="dark"] .header-right .nav-item svg path,
  [data-headerbg="color_10"][data-theme-version="dark"] .header-right .nav-item svg circle,
  [data-headerbg="color_10"] .header-right .nav-item svg path,
  [data-headerbg="color_10"] .header-right .nav-item svg circle {
    stroke: #fff !important; }
  [data-headerbg="color_10"][data-theme-version="dark"] .header-right .nav-item.ps-3:hover,
  [data-headerbg="color_10"] .header-right .nav-item.ps-3:hover {
    background: transparent; }
  [data-headerbg="color_10"][data-theme-version="dark"] .header-right .nav-item .ai-icon svg path,
  [data-headerbg="color_10"][data-theme-version="dark"] .header-right .nav-item .ai-icon svg circle,
  [data-headerbg="color_10"] .header-right .nav-item .ai-icon svg path,
  [data-headerbg="color_10"] .header-right .nav-item .ai-icon svg circle {
    stroke: var(--primary) !important; }
  [data-headerbg="color_10"][data-theme-version="dark"] .header-right .header-border .btn,
  [data-headerbg="color_10"] .header-right .header-border .btn {
    background-color: #9bddaa;
    border-color: #9bddaa; }
  [data-headerbg="color_10"][data-theme-version="dark"] .header-right .header-border:hover,
  [data-headerbg="color_10"] .header-right .header-border:hover {
    background: transparent; }
  [data-headerbg="color_10"][data-theme-version="dark"] .header-right .header-info p,
  [data-headerbg="color_10"] .header-right .header-info p {
    color: #E1E1E1; }
  [data-headerbg="color_10"][data-theme-version="dark"] .header-right .header-info h6, [data-headerbg="color_10"][data-theme-version="dark"] .header-right .header-info .h6,
  [data-headerbg="color_10"] .header-right .header-info h6,
  [data-headerbg="color_10"] .header-right .header-info .h6 {
    color: #fff; }
  [data-headerbg="color_10"][data-theme-version="dark"] .header-left .dashboard_bar,
  [data-headerbg="color_10"] .header-left .dashboard_bar {
    color: #fff; }
  [data-headerbg="color_10"][data-theme-version="dark"] .header-left .search-area .input-group-text a svg path, [data-headerbg="color_10"][data-theme-version="dark"] .header-left .search-area .input-group-text a svg circle,
  [data-headerbg="color_10"] .header-left .search-area .input-group-text a svg path,
  [data-headerbg="color_10"] .header-left .search-area .input-group-text a svg circle {
    stroke: #fff; }
  [data-headerbg="color_10"][data-theme-version="dark"] .header-profile > a.nav-link .header-info small, [data-headerbg="color_10"][data-theme-version="dark"] .header-profile > a.nav-link .header-info .small, [data-headerbg="color_10"][data-theme-version="dark"] .header-profile > a.nav-link .header-info span,
  [data-headerbg="color_10"] .header-profile > a.nav-link .header-info small,
  [data-headerbg="color_10"] .header-profile > a.nav-link .header-info .small,
  [data-headerbg="color_10"] .header-profile > a.nav-link .header-info span {
    color: #fff; }
  [data-headerbg="color_10"][data-theme-version="dark"] .hamburger .line,
  [data-headerbg="color_10"] .hamburger .line {
    background: #fff !important; }
    [data-headerbg="color_10"][data-theme-version="dark"] .hamburger .line svg rect,
    [data-headerbg="color_10"] .hamburger .line svg rect {
      fill: #fff !important; }
  [data-headerbg="color_10"][data-theme-version="dark"] .hamburger svg rect,
  [data-headerbg="color_10"] .hamburger svg rect {
    fill: #fff !important; }
  [data-headerbg="color_10"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .input-group-text,
  [data-headerbg="color_10"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .form-control, [data-headerbg="color_10"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .form-control::placeholder,
  [data-headerbg="color_10"][data-headerbg="color_4"] .search-area .input-group-text,
  [data-headerbg="color_10"][data-headerbg="color_4"] .search-area .form-control,
  [data-headerbg="color_10"][data-headerbg="color_4"] .search-area .form-control::placeholder {
    color: var(--bs-card-color); }

[data-headerbg="color_11"][data-theme-version="dark"],
[data-headerbg="color_11"] {
  --headerbg: #F6B466;
  /* &[data-headerbg="color_8"]{
					
				.search-area .input-group-text,
				.search-area .form-control,.search-area .form-control::placeholder{
					color:$black;
					background:#F1F1F1;
				}
				.header-left{
					.search-area .form-control,
					.search-area .input-group-text{
						i{
							color:$black;
						}
					}
					.dashboard_bar{
						color:$black;	
					}
					.plus-icon a{
						background-color:$black;	
					}
					
				}
				.hamburger .line{
					background:$black;
					}
				
				.header-right{
				svg{
					path{
						fill:rgba($black,0.4);	
					}
				}
			} */ }
  [data-headerbg="color_11"][data-theme-version="dark"] .header-left .search-area .form-control,
  [data-headerbg="color_11"][data-theme-version="dark"] .header-left .search-area .input-group-text,
  [data-headerbg="color_11"] .header-left .search-area .form-control,
  [data-headerbg="color_11"] .header-left .search-area .input-group-text {
    background-color: #f9cc96; }
  [data-headerbg="color_11"][data-theme-version="dark"] .nav-control,
  [data-headerbg="color_11"] .nav-control {
    background-color: #f9cc96; }
  [data-headerbg="color_11"][data-theme-version="dark"] .search-area .input-group-text,
  [data-headerbg="color_11"][data-theme-version="dark"] .search-area .form-control, [data-headerbg="color_11"][data-theme-version="dark"] .search-area .form-control::placeholder,
  [data-headerbg="color_11"] .search-area .input-group-text,
  [data-headerbg="color_11"] .search-area .form-control,
  [data-headerbg="color_11"] .search-area .form-control::placeholder {
    color: #fff; }
  [data-headerbg="color_11"][data-theme-version="dark"] .search-area .input-group-append .input-group-text i,
  [data-headerbg="color_11"] .search-area .input-group-append .input-group-text i {
    color: #fff; }
  [data-headerbg="color_11"][data-theme-version="dark"] .header-left .search-area,
  [data-headerbg="color_11"] .header-left .search-area {
    background-color: #f9cc96; }
  [data-headerbg="color_11"][data-theme-version="dark"] .header-right .search-area .form-control,
  [data-headerbg="color_11"][data-theme-version="dark"] .header-right .search-area .input-group-text,
  [data-headerbg="color_11"] .header-right .search-area .form-control,
  [data-headerbg="color_11"] .header-right .search-area .input-group-text {
    background-color: #f9cc96; }
    [data-headerbg="color_11"][data-theme-version="dark"] .header-right .search-area .form-control i,
    [data-headerbg="color_11"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
    [data-headerbg="color_11"] .header-right .search-area .form-control i,
    [data-headerbg="color_11"] .header-right .search-area .input-group-text i {
      color: #fff; }
    [data-headerbg="color_11"][data-theme-version="dark"] .header-right .search-area .form-control svg path,
    [data-headerbg="color_11"][data-theme-version="dark"] .header-right .search-area .input-group-text svg path,
    [data-headerbg="color_11"] .header-right .search-area .form-control svg path,
    [data-headerbg="color_11"] .header-right .search-area .input-group-text svg path {
      fill: #fff; }
  [data-headerbg="color_11"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link.show,
  [data-headerbg="color_11"] .header-right .notification_dropdown .nav-link.show {
    background-color: #202020; }
  [data-headerbg="color_11"][data-theme-version="dark"] .header-right .nav-item svg path,
  [data-headerbg="color_11"][data-theme-version="dark"] .header-right .nav-item svg circle,
  [data-headerbg="color_11"] .header-right .nav-item svg path,
  [data-headerbg="color_11"] .header-right .nav-item svg circle {
    stroke: #fff !important; }
  [data-headerbg="color_11"][data-theme-version="dark"] .header-right .nav-item.ps-3:hover,
  [data-headerbg="color_11"] .header-right .nav-item.ps-3:hover {
    background: transparent; }
  [data-headerbg="color_11"][data-theme-version="dark"] .header-right .nav-item .ai-icon svg path,
  [data-headerbg="color_11"][data-theme-version="dark"] .header-right .nav-item .ai-icon svg circle,
  [data-headerbg="color_11"] .header-right .nav-item .ai-icon svg path,
  [data-headerbg="color_11"] .header-right .nav-item .ai-icon svg circle {
    stroke: var(--primary) !important; }
  [data-headerbg="color_11"][data-theme-version="dark"] .header-right .header-border .btn,
  [data-headerbg="color_11"] .header-right .header-border .btn {
    background-color: #f9cc96;
    border-color: #f9cc96; }
  [data-headerbg="color_11"][data-theme-version="dark"] .header-right .header-border:hover,
  [data-headerbg="color_11"] .header-right .header-border:hover {
    background: transparent; }
  [data-headerbg="color_11"][data-theme-version="dark"] .header-right .header-info p,
  [data-headerbg="color_11"] .header-right .header-info p {
    color: #E1E1E1; }
  [data-headerbg="color_11"][data-theme-version="dark"] .header-right .header-info h6, [data-headerbg="color_11"][data-theme-version="dark"] .header-right .header-info .h6,
  [data-headerbg="color_11"] .header-right .header-info h6,
  [data-headerbg="color_11"] .header-right .header-info .h6 {
    color: #fff; }
  [data-headerbg="color_11"][data-theme-version="dark"] .header-left .dashboard_bar,
  [data-headerbg="color_11"] .header-left .dashboard_bar {
    color: #fff; }
  [data-headerbg="color_11"][data-theme-version="dark"] .header-left .search-area .input-group-text a svg path, [data-headerbg="color_11"][data-theme-version="dark"] .header-left .search-area .input-group-text a svg circle,
  [data-headerbg="color_11"] .header-left .search-area .input-group-text a svg path,
  [data-headerbg="color_11"] .header-left .search-area .input-group-text a svg circle {
    stroke: #fff; }
  [data-headerbg="color_11"][data-theme-version="dark"] .header-profile > a.nav-link .header-info small, [data-headerbg="color_11"][data-theme-version="dark"] .header-profile > a.nav-link .header-info .small, [data-headerbg="color_11"][data-theme-version="dark"] .header-profile > a.nav-link .header-info span,
  [data-headerbg="color_11"] .header-profile > a.nav-link .header-info small,
  [data-headerbg="color_11"] .header-profile > a.nav-link .header-info .small,
  [data-headerbg="color_11"] .header-profile > a.nav-link .header-info span {
    color: #fff; }
  [data-headerbg="color_11"][data-theme-version="dark"] .hamburger .line,
  [data-headerbg="color_11"] .hamburger .line {
    background: #fff !important; }
    [data-headerbg="color_11"][data-theme-version="dark"] .hamburger .line svg rect,
    [data-headerbg="color_11"] .hamburger .line svg rect {
      fill: #fff !important; }
  [data-headerbg="color_11"][data-theme-version="dark"] .hamburger svg rect,
  [data-headerbg="color_11"] .hamburger svg rect {
    fill: #fff !important; }
  [data-headerbg="color_11"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .input-group-text,
  [data-headerbg="color_11"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .form-control, [data-headerbg="color_11"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .form-control::placeholder,
  [data-headerbg="color_11"][data-headerbg="color_4"] .search-area .input-group-text,
  [data-headerbg="color_11"][data-headerbg="color_4"] .search-area .form-control,
  [data-headerbg="color_11"][data-headerbg="color_4"] .search-area .form-control::placeholder {
    color: var(--bs-card-color); }

[data-headerbg="color_12"][data-theme-version="dark"],
[data-headerbg="color_12"] {
  --headerbg: #A6BB68;
  /* &[data-headerbg="color_8"]{
					
				.search-area .input-group-text,
				.search-area .form-control,.search-area .form-control::placeholder{
					color:$black;
					background:#F1F1F1;
				}
				.header-left{
					.search-area .form-control,
					.search-area .input-group-text{
						i{
							color:$black;
						}
					}
					.dashboard_bar{
						color:$black;	
					}
					.plus-icon a{
						background-color:$black;	
					}
					
				}
				.hamburger .line{
					background:$black;
					}
				
				.header-right{
				svg{
					path{
						fill:rgba($black,0.4);	
					}
				}
			} */ }
  [data-headerbg="color_12"][data-theme-version="dark"] .header-left .search-area .form-control,
  [data-headerbg="color_12"][data-theme-version="dark"] .header-left .search-area .input-group-text,
  [data-headerbg="color_12"] .header-left .search-area .form-control,
  [data-headerbg="color_12"] .header-left .search-area .input-group-text {
    background-color: #bbcb8b; }
  [data-headerbg="color_12"][data-theme-version="dark"] .nav-control,
  [data-headerbg="color_12"] .nav-control {
    background-color: #bbcb8b; }
  [data-headerbg="color_12"][data-theme-version="dark"] .search-area .input-group-text,
  [data-headerbg="color_12"][data-theme-version="dark"] .search-area .form-control, [data-headerbg="color_12"][data-theme-version="dark"] .search-area .form-control::placeholder,
  [data-headerbg="color_12"] .search-area .input-group-text,
  [data-headerbg="color_12"] .search-area .form-control,
  [data-headerbg="color_12"] .search-area .form-control::placeholder {
    color: #fff; }
  [data-headerbg="color_12"][data-theme-version="dark"] .search-area .input-group-append .input-group-text i,
  [data-headerbg="color_12"] .search-area .input-group-append .input-group-text i {
    color: #fff; }
  [data-headerbg="color_12"][data-theme-version="dark"] .header-left .search-area,
  [data-headerbg="color_12"] .header-left .search-area {
    background-color: #bbcb8b; }
  [data-headerbg="color_12"][data-theme-version="dark"] .header-right .search-area .form-control,
  [data-headerbg="color_12"][data-theme-version="dark"] .header-right .search-area .input-group-text,
  [data-headerbg="color_12"] .header-right .search-area .form-control,
  [data-headerbg="color_12"] .header-right .search-area .input-group-text {
    background-color: #bbcb8b; }
    [data-headerbg="color_12"][data-theme-version="dark"] .header-right .search-area .form-control i,
    [data-headerbg="color_12"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
    [data-headerbg="color_12"] .header-right .search-area .form-control i,
    [data-headerbg="color_12"] .header-right .search-area .input-group-text i {
      color: #fff; }
    [data-headerbg="color_12"][data-theme-version="dark"] .header-right .search-area .form-control svg path,
    [data-headerbg="color_12"][data-theme-version="dark"] .header-right .search-area .input-group-text svg path,
    [data-headerbg="color_12"] .header-right .search-area .form-control svg path,
    [data-headerbg="color_12"] .header-right .search-area .input-group-text svg path {
      fill: #fff; }
  [data-headerbg="color_12"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link.show,
  [data-headerbg="color_12"] .header-right .notification_dropdown .nav-link.show {
    background-color: #202020; }
  [data-headerbg="color_12"][data-theme-version="dark"] .header-right .nav-item svg path,
  [data-headerbg="color_12"][data-theme-version="dark"] .header-right .nav-item svg circle,
  [data-headerbg="color_12"] .header-right .nav-item svg path,
  [data-headerbg="color_12"] .header-right .nav-item svg circle {
    stroke: #fff !important; }
  [data-headerbg="color_12"][data-theme-version="dark"] .header-right .nav-item.ps-3:hover,
  [data-headerbg="color_12"] .header-right .nav-item.ps-3:hover {
    background: transparent; }
  [data-headerbg="color_12"][data-theme-version="dark"] .header-right .nav-item .ai-icon svg path,
  [data-headerbg="color_12"][data-theme-version="dark"] .header-right .nav-item .ai-icon svg circle,
  [data-headerbg="color_12"] .header-right .nav-item .ai-icon svg path,
  [data-headerbg="color_12"] .header-right .nav-item .ai-icon svg circle {
    stroke: var(--primary) !important; }
  [data-headerbg="color_12"][data-theme-version="dark"] .header-right .header-border .btn,
  [data-headerbg="color_12"] .header-right .header-border .btn {
    background-color: #bbcb8b;
    border-color: #bbcb8b; }
  [data-headerbg="color_12"][data-theme-version="dark"] .header-right .header-border:hover,
  [data-headerbg="color_12"] .header-right .header-border:hover {
    background: transparent; }
  [data-headerbg="color_12"][data-theme-version="dark"] .header-right .header-info p,
  [data-headerbg="color_12"] .header-right .header-info p {
    color: #E1E1E1; }
  [data-headerbg="color_12"][data-theme-version="dark"] .header-right .header-info h6, [data-headerbg="color_12"][data-theme-version="dark"] .header-right .header-info .h6,
  [data-headerbg="color_12"] .header-right .header-info h6,
  [data-headerbg="color_12"] .header-right .header-info .h6 {
    color: #fff; }
  [data-headerbg="color_12"][data-theme-version="dark"] .header-left .dashboard_bar,
  [data-headerbg="color_12"] .header-left .dashboard_bar {
    color: #fff; }
  [data-headerbg="color_12"][data-theme-version="dark"] .header-left .search-area .input-group-text a svg path, [data-headerbg="color_12"][data-theme-version="dark"] .header-left .search-area .input-group-text a svg circle,
  [data-headerbg="color_12"] .header-left .search-area .input-group-text a svg path,
  [data-headerbg="color_12"] .header-left .search-area .input-group-text a svg circle {
    stroke: #fff; }
  [data-headerbg="color_12"][data-theme-version="dark"] .header-profile > a.nav-link .header-info small, [data-headerbg="color_12"][data-theme-version="dark"] .header-profile > a.nav-link .header-info .small, [data-headerbg="color_12"][data-theme-version="dark"] .header-profile > a.nav-link .header-info span,
  [data-headerbg="color_12"] .header-profile > a.nav-link .header-info small,
  [data-headerbg="color_12"] .header-profile > a.nav-link .header-info .small,
  [data-headerbg="color_12"] .header-profile > a.nav-link .header-info span {
    color: #fff; }
  [data-headerbg="color_12"][data-theme-version="dark"] .hamburger .line,
  [data-headerbg="color_12"] .hamburger .line {
    background: #fff !important; }
    [data-headerbg="color_12"][data-theme-version="dark"] .hamburger .line svg rect,
    [data-headerbg="color_12"] .hamburger .line svg rect {
      fill: #fff !important; }
  [data-headerbg="color_12"][data-theme-version="dark"] .hamburger svg rect,
  [data-headerbg="color_12"] .hamburger svg rect {
    fill: #fff !important; }
  [data-headerbg="color_12"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .input-group-text,
  [data-headerbg="color_12"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .form-control, [data-headerbg="color_12"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .form-control::placeholder,
  [data-headerbg="color_12"][data-headerbg="color_4"] .search-area .input-group-text,
  [data-headerbg="color_12"][data-headerbg="color_4"] .search-area .form-control,
  [data-headerbg="color_12"][data-headerbg="color_4"] .search-area .form-control::placeholder {
    color: var(--bs-card-color); }

[data-headerbg="color_13"][data-theme-version="dark"],
[data-headerbg="color_13"] {
  --headerbg: #111828;
  /* &[data-headerbg="color_8"]{
					
				.search-area .input-group-text,
				.search-area .form-control,.search-area .form-control::placeholder{
					color:$black;
					background:#F1F1F1;
				}
				.header-left{
					.search-area .form-control,
					.search-area .input-group-text{
						i{
							color:$black;
						}
					}
					.dashboard_bar{
						color:$black;	
					}
					.plus-icon a{
						background-color:$black;	
					}
					
				}
				.hamburger .line{
					background:$black;
					}
				
				.header-right{
				svg{
					path{
						fill:rgba($black,0.4);	
					}
				}
			} */ }
  [data-headerbg="color_13"][data-theme-version="dark"] .header-left .search-area .form-control,
  [data-headerbg="color_13"][data-theme-version="dark"] .header-left .search-area .input-group-text,
  [data-headerbg="color_13"] .header-left .search-area .form-control,
  [data-headerbg="color_13"] .header-left .search-area .input-group-text {
    background-color: #202d4c; }
  [data-headerbg="color_13"][data-theme-version="dark"] .nav-control,
  [data-headerbg="color_13"] .nav-control {
    background-color: #202d4c; }
  [data-headerbg="color_13"][data-theme-version="dark"] .search-area .input-group-text,
  [data-headerbg="color_13"][data-theme-version="dark"] .search-area .form-control, [data-headerbg="color_13"][data-theme-version="dark"] .search-area .form-control::placeholder,
  [data-headerbg="color_13"] .search-area .input-group-text,
  [data-headerbg="color_13"] .search-area .form-control,
  [data-headerbg="color_13"] .search-area .form-control::placeholder {
    color: #fff; }
  [data-headerbg="color_13"][data-theme-version="dark"] .search-area .input-group-append .input-group-text i,
  [data-headerbg="color_13"] .search-area .input-group-append .input-group-text i {
    color: #fff; }
  [data-headerbg="color_13"][data-theme-version="dark"] .header-left .search-area,
  [data-headerbg="color_13"] .header-left .search-area {
    background-color: #202d4c; }
  [data-headerbg="color_13"][data-theme-version="dark"] .header-right .search-area .form-control,
  [data-headerbg="color_13"][data-theme-version="dark"] .header-right .search-area .input-group-text,
  [data-headerbg="color_13"] .header-right .search-area .form-control,
  [data-headerbg="color_13"] .header-right .search-area .input-group-text {
    background-color: #202d4c; }
    [data-headerbg="color_13"][data-theme-version="dark"] .header-right .search-area .form-control i,
    [data-headerbg="color_13"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
    [data-headerbg="color_13"] .header-right .search-area .form-control i,
    [data-headerbg="color_13"] .header-right .search-area .input-group-text i {
      color: #fff; }
    [data-headerbg="color_13"][data-theme-version="dark"] .header-right .search-area .form-control svg path,
    [data-headerbg="color_13"][data-theme-version="dark"] .header-right .search-area .input-group-text svg path,
    [data-headerbg="color_13"] .header-right .search-area .form-control svg path,
    [data-headerbg="color_13"] .header-right .search-area .input-group-text svg path {
      fill: #fff; }
  [data-headerbg="color_13"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link.show,
  [data-headerbg="color_13"] .header-right .notification_dropdown .nav-link.show {
    background-color: #202020; }
  [data-headerbg="color_13"][data-theme-version="dark"] .header-right .nav-item svg path,
  [data-headerbg="color_13"][data-theme-version="dark"] .header-right .nav-item svg circle,
  [data-headerbg="color_13"] .header-right .nav-item svg path,
  [data-headerbg="color_13"] .header-right .nav-item svg circle {
    stroke: #fff !important; }
  [data-headerbg="color_13"][data-theme-version="dark"] .header-right .nav-item.ps-3:hover,
  [data-headerbg="color_13"] .header-right .nav-item.ps-3:hover {
    background: transparent; }
  [data-headerbg="color_13"][data-theme-version="dark"] .header-right .nav-item .ai-icon svg path,
  [data-headerbg="color_13"][data-theme-version="dark"] .header-right .nav-item .ai-icon svg circle,
  [data-headerbg="color_13"] .header-right .nav-item .ai-icon svg path,
  [data-headerbg="color_13"] .header-right .nav-item .ai-icon svg circle {
    stroke: var(--primary) !important; }
  [data-headerbg="color_13"][data-theme-version="dark"] .header-right .header-border .btn,
  [data-headerbg="color_13"] .header-right .header-border .btn {
    background-color: #202d4c;
    border-color: #202d4c; }
  [data-headerbg="color_13"][data-theme-version="dark"] .header-right .header-border:hover,
  [data-headerbg="color_13"] .header-right .header-border:hover {
    background: transparent; }
  [data-headerbg="color_13"][data-theme-version="dark"] .header-right .header-info p,
  [data-headerbg="color_13"] .header-right .header-info p {
    color: #E1E1E1; }
  [data-headerbg="color_13"][data-theme-version="dark"] .header-right .header-info h6, [data-headerbg="color_13"][data-theme-version="dark"] .header-right .header-info .h6,
  [data-headerbg="color_13"] .header-right .header-info h6,
  [data-headerbg="color_13"] .header-right .header-info .h6 {
    color: #fff; }
  [data-headerbg="color_13"][data-theme-version="dark"] .header-left .dashboard_bar,
  [data-headerbg="color_13"] .header-left .dashboard_bar {
    color: #fff; }
  [data-headerbg="color_13"][data-theme-version="dark"] .header-left .search-area .input-group-text a svg path, [data-headerbg="color_13"][data-theme-version="dark"] .header-left .search-area .input-group-text a svg circle,
  [data-headerbg="color_13"] .header-left .search-area .input-group-text a svg path,
  [data-headerbg="color_13"] .header-left .search-area .input-group-text a svg circle {
    stroke: #fff; }
  [data-headerbg="color_13"][data-theme-version="dark"] .header-profile > a.nav-link .header-info small, [data-headerbg="color_13"][data-theme-version="dark"] .header-profile > a.nav-link .header-info .small, [data-headerbg="color_13"][data-theme-version="dark"] .header-profile > a.nav-link .header-info span,
  [data-headerbg="color_13"] .header-profile > a.nav-link .header-info small,
  [data-headerbg="color_13"] .header-profile > a.nav-link .header-info .small,
  [data-headerbg="color_13"] .header-profile > a.nav-link .header-info span {
    color: #fff; }
  [data-headerbg="color_13"][data-theme-version="dark"] .hamburger .line,
  [data-headerbg="color_13"] .hamburger .line {
    background: #fff !important; }
    [data-headerbg="color_13"][data-theme-version="dark"] .hamburger .line svg rect,
    [data-headerbg="color_13"] .hamburger .line svg rect {
      fill: #fff !important; }
  [data-headerbg="color_13"][data-theme-version="dark"] .hamburger svg rect,
  [data-headerbg="color_13"] .hamburger svg rect {
    fill: #fff !important; }
  [data-headerbg="color_13"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .input-group-text,
  [data-headerbg="color_13"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .form-control, [data-headerbg="color_13"][data-theme-version="dark"][data-headerbg="color_4"] .search-area .form-control::placeholder,
  [data-headerbg="color_13"][data-headerbg="color_4"] .search-area .input-group-text,
  [data-headerbg="color_13"][data-headerbg="color_4"] .search-area .form-control,
  [data-headerbg="color_13"][data-headerbg="color_4"] .search-area .form-control::placeholder {
    color: var(--bs-card-color); }

[data-primary="color_1"][data-nav-headerbg="color_1"][data-theme-version="light"][data-layout="horizontal"] .header .header-content,
[data-nav-headerbg="color_1"][data-layout="horizontal"] .header .header-content {
  padding-left: 48px; }

[data-primary="color_1"][data-nav-headerbg="color_1"][data-theme-version="light"][data-sidebar-style="mini"] .color-title, [data-primary="color_1"][data-nav-headerbg="color_1"][data-theme-version="light"][data-sidebar-style="modern"] .color-title, [data-primary="color_1"][data-nav-headerbg="color_1"][data-theme-version="light"][data-sidebar-style="compact"] .color-title,
[data-nav-headerbg="color_1"][data-sidebar-style="mini"] .color-title,
[data-nav-headerbg="color_1"][data-sidebar-style="modern"] .color-title,
[data-nav-headerbg="color_1"][data-sidebar-style="compact"] .color-title {
  display: none !important; }

[data-primary="color_1"][data-nav-headerbg="color_1"][data-theme-version="light"][data-sidebar-style="icon-hover"] .color-title,
[data-nav-headerbg="color_1"][data-sidebar-style="icon-hover"] .color-title {
  display: none !important; }

[data-primary="color_1"][data-nav-headerbg="color_1"][data-theme-version="light"][data-sidebar-style="icon-hover"] .iconhover-toggle .color-title,
[data-nav-headerbg="color_1"][data-sidebar-style="icon-hover"] .iconhover-toggle .color-title {
  display: block !important; }

[data-primary="color_1"][data-nav-headerbg="color_1"][data-theme-version="light"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .color-title,
[data-nav-headerbg="color_1"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .color-title {
  display: block !important; }

[data-primary="color_1"][data-nav-headerbg="color_1"][data-theme-version="light"][data-sidebar-style="morden"][data-layout="horizontal"] .color-title,
[data-nav-headerbg="color_1"][data-sidebar-style="morden"][data-layout="horizontal"] .color-title {
  display: block !important; }

[data-primary="color_1"][data-nav-headerbg="color_1"][data-theme-version="light"][data-sidebar-style="compact"][data-layout="horizontal"] .color-title,
[data-nav-headerbg="color_1"][data-sidebar-style="compact"][data-layout="horizontal"] .color-title {
  display: block !important; }

[data-primary="color_1"][data-nav-headerbg="color_1"][data-theme-version="light"][data-sidebar-style="compact"][data-layout="vertical"] .menu-toggle .color-title,
[data-nav-headerbg="color_1"][data-sidebar-style="compact"][data-layout="vertical"] .menu-toggle .color-title {
  display: block !important; }

@media (min-width: 62rem) {
  [data-container="boxed"] #main-wrapper {
    max-width: 74.9375rem;
    margin: 0 auto;
    box-shadow: 0 0 1.875rem 0 rgba(0, 0, 0, 0.1); }
    [direction="rtl"][data-container="boxed"] #main-wrapper {
      text-align: right; }
  [data-container="boxed"] .content-body {
    padding-right: 0rem; }
  [data-container="boxed"] .invoice-num {
    font-size: 1.375rem; }
  [data-container="boxed"] .email-susb {
    display: none; }
  [data-container="boxed"] .market-previews,
  [data-container="boxed"] .assets-al {
    width: 50%; }
  [data-container="boxed"] .buy-coin .coin-img img {
    width: 332px;
    bottom: -11px; }
  [data-container="boxed"] .dz-side-menu {
    display: none; }
  [data-container="boxed"] .out-footer {
    margin-right: 5.375rem; } }

@media only screen and (min-width: 84.375rem) {
  [data-layout="vertical"][data-sidebar-style="overlay"][data-container="boxed"][data-header-position="fixed"] .header {
    width: 74.9375rem; } }

@media only screen and (min-width: 75rem) and (max-width: 84.3125rem) {
  [data-layout="vertical"][data-sidebar-style="overlay"][data-container="boxed"][data-header-position="fixed"] .header {
    width: 74.9375rem; } }

[data-header-position="fixed"][data-layout="horizontal"][data-container="boxed"] .deznav {
  max-width: 74.9375rem; }

[data-layout="vertical"][data-container="boxed"][data-header-position="fixed"] .header,
[data-layout="horizontal"][data-container="boxed"][data-header-position="fixed"] .header {
  width: 74.9375rem; }

[data-layout="horizontal"][data-container="boxed"][data-header-position="fixed"][data-sidebar-style="mini"] .header {
  width: 74.9375rem; }

[data-container="boxed"] .metismenu.fixed {
  left: auto;
  max-width: 74.9375rem; }

[data-container="boxed"] .page-titles {
  margin-bottom: 3rem;
  padding: 0.9375rem 0.9375rem; }

[data-container="boxed"] .content-body .container-fluid, [data-container="boxed"] .content-body .container-sm, [data-container="boxed"] .content-body .container-md, [data-container="boxed"] .content-body .container-lg, [data-container="boxed"] .content-body .container-xl, [data-container="boxed"] .content-body .container-xxl {
  padding: 2.5rem; }

[data-container="boxed"][data-layout="vertical"] .page-titles {
  margin-left: 0;
  margin-right: 0; }

[data-layout="vertical"][data-container="boxed"][data-sidebar-position="fixed"][data-header-position="static"][data-sidebar-style="overlay"] .nav-header {
  position: absolute; }

[data-layout="vertical"][data-container="boxed"][data-sidebar-position="fixed"][data-header-position="static"][data-sidebar-style="overlay"] .menu-toggle .deznav {
  position: absolute; }

[data-container="boxed"][data-sidebar-position="fixed"][data-layout="horizontal"] .deznav.fixed {
  left: auto;
  max-width: 74.9375rem; }

@media (min-width: 62rem) {
  [data-container="wide-boxed"] #main-wrapper {
    max-width: 92.5rem;
    margin: 0 auto;
    box-shadow: 0 0 1.875rem 0 rgba(0, 0, 0, 0.1); }
    [direction="rtl"][data-container="wide-boxed"] #main-wrapper {
      text-align: right; }
  [data-container="wide-boxed"] .content-body {
    padding-right: 0rem; }
  [data-container="wide-boxed"] .email-susb {
    display: none; }
  [data-container="wide-boxed"] .market-previews,
  [data-container="wide-boxed"] .assets-al {
    width: 50%; }
  [data-container="wide-boxed"] .buy-coin .coin-img img {
    width: 332px;
    bottom: -11px; } }

@media only screen and (min-width: 84.375rem) {
  [data-layout="vertical"][data-sidebar-style="overlay"][data-container="wide-boxed"][data-header-position="fixed"] .header {
    width: 92.5rem; } }

@media only screen and (min-width: 75rem) and (max-width: 100rem) {
  [data-layout="vertical"][data-sidebar-style="overlay"][data-container="wide-boxed"][data-header-position="fixed"] .header {
    width: 92.5rem; } }

[data-sidebar-style="compact"][data-header-position="fixed"][data-container="wide-boxed"][data-layout="vertical"] .header {
  width: 92.5rem; }

[data-header-position="fixed"][data-layout="horizontal"][data-container="wide-boxed"] .deznav {
  max-width: 92.5rem; }

[data-layout="vertical"][data-container="wide-boxed"][data-header-position="fixed"] .header,
[data-layout="horizontal"][data-container="wide-boxed"][data-header-position="fixed"] .header {
  width: 92.5rem; }

[data-layout="horizontal"][data-container="wide-boxed"][data-header-position="fixed"][data-sidebar-style="mini"] .header {
  width: 92.5rem; }

[data-container="wide-boxed"] .metismenu.fixed {
  left: auto;
  max-width: 92.5rem; }

[data-container="wide-boxed"] .page-titles {
  margin-bottom: 3rem;
  padding: 0.9375rem 0.9375rem; }

[data-container="wide-boxed"] .content-body .container-fluid, [data-container="wide-boxed"] .content-body .container-sm, [data-container="wide-boxed"] .content-body .container-md, [data-container="wide-boxed"] .content-body .container-lg, [data-container="wide-boxed"] .content-body .container-xl, [data-container="wide-boxed"] .content-body .container-xxl {
  padding: 2.5rem; }

[data-container="wide-boxed"][data-layout="vertical"] .page-titles {
  margin-left: 0;
  margin-right: 0; }

[data-layout="vertical"][data-container="wide-boxed"][data-sidebar-position="fixed"][data-header-position="static"][data-sidebar-style="overlay"] .nav-header {
  position: absolute; }

[data-layout="vertical"][data-container="wide-boxed"][data-sidebar-position="fixed"][data-header-position="static"][data-sidebar-style="overlay"] .menu-toggle .deznav {
  position: absolute; }

[data-container="wide-boxed"][data-sidebar-position="fixed"][data-layout="horizontal"] .deznav.fixed {
  left: auto;
  max-width: 92.5rem; }

[data-primary="color_2"] {
  --primary: #A0CF1A;
  --primary-hover: #7da214;
  --primary-dark: #374709;
  --rgba-primary-1: rgba(160, 207, 26, 0.1);
  --rgba-primary-2: rgba(160, 207, 26, 0.2);
  --rgba-primary-3: rgba(160, 207, 26, 0.3);
  --rgba-primary-4: rgba(160, 207, 26, 0.4);
  --rgba-primary-5: rgba(160, 207, 26, 0.5);
  --rgba-primary-6: rgba(160, 207, 26, 0.6);
  --rgba-primary-7: rgba(160, 207, 26, 0.7);
  --rgba-primary-8: rgba(160, 207, 26, 0.8);
  --rgba-primary-9: rgba(160, 207, 26, 0.9); }

[data-primary="color_3"] {
  --primary: #FF7C7C;
  --primary-hover: #ff4949;
  --primary-dark: #e20000;
  --rgba-primary-1: rgba(255, 124, 124, 0.1);
  --rgba-primary-2: rgba(255, 124, 124, 0.2);
  --rgba-primary-3: rgba(255, 124, 124, 0.3);
  --rgba-primary-4: rgba(255, 124, 124, 0.4);
  --rgba-primary-5: rgba(255, 124, 124, 0.5);
  --rgba-primary-6: rgba(255, 124, 124, 0.6);
  --rgba-primary-7: rgba(255, 124, 124, 0.7);
  --rgba-primary-8: rgba(255, 124, 124, 0.8);
  --rgba-primary-9: rgba(255, 124, 124, 0.9); }

[data-primary="color_4"] {
  --primary: #81A4F9;
  --primary-hover: #5081f7;
  --primary-dark: #0a46d7;
  --rgba-primary-1: rgba(129, 164, 249, 0.1);
  --rgba-primary-2: rgba(129, 164, 249, 0.2);
  --rgba-primary-3: rgba(129, 164, 249, 0.3);
  --rgba-primary-4: rgba(129, 164, 249, 0.4);
  --rgba-primary-5: rgba(129, 164, 249, 0.5);
  --rgba-primary-6: rgba(129, 164, 249, 0.6);
  --rgba-primary-7: rgba(129, 164, 249, 0.7);
  --rgba-primary-8: rgba(129, 164, 249, 0.8);
  --rgba-primary-9: rgba(129, 164, 249, 0.9); }

[data-primary="color_5"] {
  --primary: #53D0B2;
  --primary-hover: #34bc9c;
  --primary-dark: #1e6c59;
  --rgba-primary-1: rgba(83, 208, 178, 0.1);
  --rgba-primary-2: rgba(83, 208, 178, 0.2);
  --rgba-primary-3: rgba(83, 208, 178, 0.3);
  --rgba-primary-4: rgba(83, 208, 178, 0.4);
  --rgba-primary-5: rgba(83, 208, 178, 0.5);
  --rgba-primary-6: rgba(83, 208, 178, 0.6);
  --rgba-primary-7: rgba(83, 208, 178, 0.7);
  --rgba-primary-8: rgba(83, 208, 178, 0.8);
  --rgba-primary-9: rgba(83, 208, 178, 0.9); }

[data-primary="color_6"] {
  --primary: #F4CF0C;
  --primary-hover: #c4a609;
  --primary-dark: #635404;
  --rgba-primary-1: rgba(244, 207, 12, 0.1);
  --rgba-primary-2: rgba(244, 207, 12, 0.2);
  --rgba-primary-3: rgba(244, 207, 12, 0.3);
  --rgba-primary-4: rgba(244, 207, 12, 0.4);
  --rgba-primary-5: rgba(244, 207, 12, 0.5);
  --rgba-primary-6: rgba(244, 207, 12, 0.6);
  --rgba-primary-7: rgba(244, 207, 12, 0.7);
  --rgba-primary-8: rgba(244, 207, 12, 0.8);
  --rgba-primary-9: rgba(244, 207, 12, 0.9); }

[data-primary="color_7"] {
  --primary: #50D5DB;
  --primary-hover: #2ac7ce;
  --primary-dark: #197579;
  --rgba-primary-1: rgba(80, 213, 219, 0.1);
  --rgba-primary-2: rgba(80, 213, 219, 0.2);
  --rgba-primary-3: rgba(80, 213, 219, 0.3);
  --rgba-primary-4: rgba(80, 213, 219, 0.4);
  --rgba-primary-5: rgba(80, 213, 219, 0.5);
  --rgba-primary-6: rgba(80, 213, 219, 0.6);
  --rgba-primary-7: rgba(80, 213, 219, 0.7);
  --rgba-primary-8: rgba(80, 213, 219, 0.8);
  --rgba-primary-9: rgba(80, 213, 219, 0.9); }

[data-primary="color_8"] {
  --primary: #D58FE5;
  --primary-hover: #c566db;
  --primary-dark: #9829b2;
  --rgba-primary-1: rgba(213, 143, 229, 0.1);
  --rgba-primary-2: rgba(213, 143, 229, 0.2);
  --rgba-primary-3: rgba(213, 143, 229, 0.3);
  --rgba-primary-4: rgba(213, 143, 229, 0.4);
  --rgba-primary-5: rgba(213, 143, 229, 0.5);
  --rgba-primary-6: rgba(213, 143, 229, 0.6);
  --rgba-primary-7: rgba(213, 143, 229, 0.7);
  --rgba-primary-8: rgba(213, 143, 229, 0.8);
  --rgba-primary-9: rgba(213, 143, 229, 0.9); }

[data-primary="color_9"] {
  --primary: #7FB9EE;
  --primary-hover: #52a0e8;
  --primary-dark: #196ebb;
  --rgba-primary-1: rgba(127, 185, 238, 0.1);
  --rgba-primary-2: rgba(127, 185, 238, 0.2);
  --rgba-primary-3: rgba(127, 185, 238, 0.3);
  --rgba-primary-4: rgba(127, 185, 238, 0.4);
  --rgba-primary-5: rgba(127, 185, 238, 0.5);
  --rgba-primary-6: rgba(127, 185, 238, 0.6);
  --rgba-primary-7: rgba(127, 185, 238, 0.7);
  --rgba-primary-8: rgba(127, 185, 238, 0.8);
  --rgba-primary-9: rgba(127, 185, 238, 0.9); }

[data-primary="color_10"] {
  --primary: #75D089;
  --primary-hover: #4fc368;
  --primary-dark: #2c803e;
  --rgba-primary-1: rgba(117, 208, 137, 0.1);
  --rgba-primary-2: rgba(117, 208, 137, 0.2);
  --rgba-primary-3: rgba(117, 208, 137, 0.3);
  --rgba-primary-4: rgba(117, 208, 137, 0.4);
  --rgba-primary-5: rgba(117, 208, 137, 0.5);
  --rgba-primary-6: rgba(117, 208, 137, 0.6);
  --rgba-primary-7: rgba(117, 208, 137, 0.7);
  --rgba-primary-8: rgba(117, 208, 137, 0.8);
  --rgba-primary-9: rgba(117, 208, 137, 0.9); }

[data-primary="color_11"] {
  --primary: #F6B466;
  --primary-hover: #f39c36;
  --primary-dark: #b8690b;
  --rgba-primary-1: rgba(246, 180, 102, 0.1);
  --rgba-primary-2: rgba(246, 180, 102, 0.2);
  --rgba-primary-3: rgba(246, 180, 102, 0.3);
  --rgba-primary-4: rgba(246, 180, 102, 0.4);
  --rgba-primary-5: rgba(246, 180, 102, 0.5);
  --rgba-primary-6: rgba(246, 180, 102, 0.6);
  --rgba-primary-7: rgba(246, 180, 102, 0.7);
  --rgba-primary-8: rgba(246, 180, 102, 0.8);
  --rgba-primary-9: rgba(246, 180, 102, 0.9); }

[data-primary="color_12"] {
  --primary: #A6BB68;
  --primary-hover: #8ea54b;
  --primary-dark: #525f2b;
  --rgba-primary-1: rgba(166, 187, 104, 0.1);
  --rgba-primary-2: rgba(166, 187, 104, 0.2);
  --rgba-primary-3: rgba(166, 187, 104, 0.3);
  --rgba-primary-4: rgba(166, 187, 104, 0.4);
  --rgba-primary-5: rgba(166, 187, 104, 0.5);
  --rgba-primary-6: rgba(166, 187, 104, 0.6);
  --rgba-primary-7: rgba(166, 187, 104, 0.7);
  --rgba-primary-8: rgba(166, 187, 104, 0.8);
  --rgba-primary-9: rgba(166, 187, 104, 0.9); }

[data-primary="color_13"] {
  --primary: #111828;
  --primary-hover: #020304;
  --primary-dark: black;
  --rgba-primary-1: rgba(17, 24, 40, 0.1);
  --rgba-primary-2: rgba(17, 24, 40, 0.2);
  --rgba-primary-3: rgba(17, 24, 40, 0.3);
  --rgba-primary-4: rgba(17, 24, 40, 0.4);
  --rgba-primary-5: rgba(17, 24, 40, 0.5);
  --rgba-primary-6: rgba(17, 24, 40, 0.6);
  --rgba-primary-7: rgba(17, 24, 40, 0.7);
  --rgba-primary-8: rgba(17, 24, 40, 0.8);
  --rgba-primary-9: rgba(17, 24, 40, 0.9); }

[data-primary="color_1"] {
  --primary-light: #EEEEEE; }

[data-primary="color_2"] {
  --primary-light: #CFF859; }

[data-primary="color_3"] {
  --primary-light: #FFCCC0; }

[data-primary="color_4"] {
  --primary-light: #D7E2FF; }

[data-primary="color_5"] {
  --primary-light: #A7FFEA; }

[data-primary="color_6"] {
  --primary-light: #FCEF73; }

[data-primary="color_7"] {
  --primary-light: #B6FAFD; }

[data-primary="color_8"] {
  --primary-light: #F4C7FF; }

[data-primary="color_9"] {
  --primary-light: #AFD9FF; }

[data-primary="color_10"] {
  --primary-light: #CBFFD6; }

[data-primary="color_11"] {
  --primary-light: #FFD8AB; }

[data-primary="color_12"] {
  --primary-light: #D6DDC0; }

[data-primary="color_13"] {
  --primary-light: #DCDFE5; }

[data-secondary="color_2"] {
  --secondary: #A0CF1A;
  --secondary-dark: #7da214; }

[data-secondary="color_3"] {
  --secondary: #FF7C7C;
  --secondary-dark: #ff4949; }

[data-secondary="color_4"] {
  --secondary: #81A4F9;
  --secondary-dark: #5081f7; }

[data-secondary="color_5"] {
  --secondary: #53D0B2;
  --secondary-dark: #34bc9c; }

[data-secondary="color_6"] {
  --secondary: #F4CF0C;
  --secondary-dark: #c4a609; }

[data-secondary="color_7"] {
  --secondary: #50D5DB;
  --secondary-dark: #2ac7ce; }

[data-secondary="color_8"] {
  --secondary: #D58FE5;
  --secondary-dark: #c566db; }

[data-secondary="color_9"] {
  --secondary: #7FB9EE;
  --secondary-dark: #52a0e8; }

[data-secondary="color_10"] {
  --secondary: #75D089;
  --secondary-dark: #4fc368; }

[data-secondary="color_11"] {
  --secondary: #F6B466;
  --secondary-dark: #f39c36; }

[data-secondary="color_12"] {
  --secondary: #A6BB68;
  --secondary-dark: #8ea54b; }

[data-secondary="color_13"] {
  --secondary: #111828;
  --secondary-dark: #020304; }

[data-typography="opensans"] {
  font-family: 'Open Sans', sans-serif; }

[data-typography="poppins"] {
  font-family: 'poppins', sans-serif; }

[data-typography="cairo"] {
  font-family: 'Cairo', sans-serif; }

[data-typography="roboto"] {
  font-family: 'Roboto', sans-serif; }

[data-typography="helvetica"] {
  font-family: 'HelveticaNeue'; }

/* //action button color
$add-btn:blue
$edit-btn:
$delete-btn: */
:root {
  --primary: #A0CF1A;
  --secondary: #111828;
  --secondary-dark: #020304;
  --primary-hover: #7da214;
  --primary-dark: #374709;
  --rgba-primary-1: rgba(160, 207, 26, 0.1);
  --rgba-primary-2: rgba(160, 207, 26, 0.2);
  --rgba-primary-3: rgba(160, 207, 26, 0.3);
  --rgba-primary-4: rgba(160, 207, 26, 0.4);
  --rgba-primary-5: rgba(160, 207, 26, 0.5);
  --rgba-primary-6: rgba(160, 207, 26, 0.6);
  --rgba-primary-7: rgba(160, 207, 26, 0.7);
  --rgba-primary-8: rgba(160, 207, 26, 0.8);
  --rgba-primary-9: rgba(160, 207, 26, 0.9);
  --rgba-secondary-1: rgba(17, 24, 40, 0.1);
  --rgba-secondary-2: rgba(17, 24, 40, 0.2);
  --rgba-secondary-3: rgba(17, 24, 40, 0.3);
  --rgba-secondary-4: rgba(17, 24, 40, 0.4);
  --rgba-secondary-5: rgba(17, 24, 40, 0.5);
  --rgba-secondary-6: rgba(17, 24, 40, 0.6);
  --rgba-secondary-7: rgba(17, 24, 40, 0.7);
  --rgba-secondary-8: rgba(17, 24, 40, 0.8);
  --rgba-secondary-9: rgba(17, 24, 40, 0.9);
  --font-family-base: DM Sans, sans-serif, sans-serif;
  --font-family-title: DM Sans, sans-serif, sans-serif;
  --title: #111828;
  --text-dark: #111828; }

/* $primary-light: lighten($primary, 43%);
$secondary-light: lighten($secondary, 10%);
$success-light: lighten($success, 15%);
$warning-light: lighten($warning, 20%);
$danger-light: lighten($danger, 10%);
$info-light: lighten($info, 20%);
$dark-light: lighten($dark, 50%); */
[data-theme-version="transparent"] {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  position: relative;
  color: #fff; }
  [data-theme-version="transparent"] h1, [data-theme-version="transparent"] .h1,
  [data-theme-version="transparent"] h2,
  [data-theme-version="transparent"] .h2,
  [data-theme-version="transparent"] h3,
  [data-theme-version="transparent"] .h3,
  [data-theme-version="transparent"] h4,
  [data-theme-version="transparent"] .h4,
  [data-theme-version="transparent"] h5,
  [data-theme-version="transparent"] .h5,
  [data-theme-version="transparent"] h6,
  [data-theme-version="transparent"] .h6 {
    color: #fff !important; }
  [data-theme-version="transparent"] a.link {
    color: #ddd; }
  [data-theme-version="transparent"] a.link:focus,
  [data-theme-version="transparent"] a.link:hover {
    color: #81A5F9; }
  [data-theme-version="transparent"] a {
    color: #fff; }
    [data-theme-version="transparent"] a:hover {
      color: #828690; }
  [data-theme-version="transparent"] .border-right {
    border-right: 1px solid #444444 !important; }
  [data-theme-version="transparent"] .border-left {
    border-left: 1px solid #444444 !important; }
  [data-theme-version="transparent"] .border-top {
    border-top: 1px solid #444444 !important; }
  [data-theme-version="transparent"] .border-bottom {
    border-bottom: 1px solid #444444 !important; }
  [data-theme-version="transparent"] .border {
    border: 1px solid #444444 !important; }

[data-theme-version="transparent"] .dropdown-menu {
  background-color: #2c2c2c; }

[data-theme-version="transparent"] .dropdown-item:hover, [data-theme-version="transparent"] .dropdown-item:focus {
  background-color: #1E1E1E;
  color: #fff; }

[data-theme-version="transparent"] .form-control {
  background-color: #1E1E1E;
  border-color: #444444;
  color: #fff; }

[data-theme-version="transparent"] .card {
  background-color: rgba(0, 0, 0, 0.15); }

[data-theme-version="transparent"] .header {
  background-color: rgba(0, 0, 0, 0.15); }

[data-theme-version="transparent"] .header-left input {
  border-color: #444444;
  color: #fff; }
  [data-theme-version="transparent"] .header-left input:focus {
    box-shadow: none;
    border-color: var(--primary); }
  [data-theme-version="transparent"] .header-left input::placeholder {
    color: #fff; }

[data-theme-version="transparent"] .header-right .dropdown .nav-link:hover {
  color: #fff; }

[data-theme-version="transparent"] .header-right .notification_dropdown .dropdown-item a {
  color: #fff; }

[data-theme-version="transparent"] .nav-header,
[data-theme-version="transparent"] .nav-control {
  background-color: rgba(0, 0, 0, 0.15) !important; }

[data-theme-version="transparent"] .nav-control {
  color: #fff; }

[data-theme-version="transparent"] .brand-logo {
  color: #fff; }
  [data-theme-version="transparent"] .brand-logo:hover {
    color: #fff; }

[data-theme-version="transparent"] .nav-header,
[data-theme-version="transparent"] .nav-control {
  background-color: rgba(0, 0, 0, 0.15) !important; }

[data-theme-version="transparent"] .nav-control {
  color: #fff; }

[data-theme-version="transparent"] .brand-logo {
  color: #fff; }
  [data-theme-version="transparent"] .brand-logo:hover {
    color: #fff; }

[data-theme-version="transparent"] .deznav {
  background-color: rgba(0, 0, 0, 0.15) !important; }
  [data-theme-version="transparent"] .deznav .metismenu > li > a {
    color: rgba(0, 0, 0, 0.15); }
  [data-theme-version="transparent"] .deznav .metismenu > li:hover > a, [data-theme-version="transparent"] .deznav .metismenu > li:focus > a, [data-theme-version="transparent"] .deznav .metismenu > li.mm-active > a {
    background-color: rgba(0, 0, 0, 0.15) !important;
    color: #fff; }
    [data-theme-version="transparent"] .deznav .metismenu > li:hover > a::after, [data-theme-version="transparent"] .deznav .metismenu > li:focus > a::after, [data-theme-version="transparent"] .deznav .metismenu > li.mm-active > a::after {
      border-color: transparent transparent #fff transparent; }
  [data-theme-version="transparent"] .deznav .metismenu > li.mm-active ul ul {
    background-color: transparent; }
  [data-theme-version="transparent"] .deznav .metismenu ul {
    background-color: rgba(0, 0, 0, 0.15); }
    [data-theme-version="transparent"] .deznav .metismenu ul a:hover, [data-theme-version="transparent"] .deznav .metismenu ul a:focus, [data-theme-version="transparent"] .deznav .metismenu ul a.mm-active {
      color: #fff; }
  [data-theme-version="transparent"] .deznav .metismenu a {
    color: rgba(0, 0, 0, 0.15); }
  [data-theme-version="transparent"] .deznav .metismenu ul {
    background-color: rgba(0, 0, 0, 0.15) !important; }
  [data-theme-version="transparent"] .deznav .metismenu .has-arrow:after {
    border-color: transparent transparent rgba(0, 0, 0, 0.15) transparent; }

/* //action button color
$add-btn:blue
$edit-btn:
$delete-btn: */
:root {
  --primary: #A0CF1A;
  --secondary: #111828;
  --secondary-dark: #020304;
  --primary-hover: #7da214;
  --primary-dark: #374709;
  --rgba-primary-1: rgba(160, 207, 26, 0.1);
  --rgba-primary-2: rgba(160, 207, 26, 0.2);
  --rgba-primary-3: rgba(160, 207, 26, 0.3);
  --rgba-primary-4: rgba(160, 207, 26, 0.4);
  --rgba-primary-5: rgba(160, 207, 26, 0.5);
  --rgba-primary-6: rgba(160, 207, 26, 0.6);
  --rgba-primary-7: rgba(160, 207, 26, 0.7);
  --rgba-primary-8: rgba(160, 207, 26, 0.8);
  --rgba-primary-9: rgba(160, 207, 26, 0.9);
  --rgba-secondary-1: rgba(17, 24, 40, 0.1);
  --rgba-secondary-2: rgba(17, 24, 40, 0.2);
  --rgba-secondary-3: rgba(17, 24, 40, 0.3);
  --rgba-secondary-4: rgba(17, 24, 40, 0.4);
  --rgba-secondary-5: rgba(17, 24, 40, 0.5);
  --rgba-secondary-6: rgba(17, 24, 40, 0.6);
  --rgba-secondary-7: rgba(17, 24, 40, 0.7);
  --rgba-secondary-8: rgba(17, 24, 40, 0.8);
  --rgba-secondary-9: rgba(17, 24, 40, 0.9);
  --font-family-base: DM Sans, sans-serif, sans-serif;
  --font-family-title: DM Sans, sans-serif, sans-serif;
  --title: #111828;
  --text-dark: #111828; }

/* $primary-light: lighten($primary, 43%);
$secondary-light: lighten($secondary, 10%);
$success-light: lighten($success, 15%);
$warning-light: lighten($warning, 20%);
$danger-light: lighten($danger, 10%);
$info-light: lighten($info, 20%);
$dark-light: lighten($dark, 50%); */
[data-theme-version="dark"] {
  background: #1E1E1E;
  color: #828690;
  --nav-headbg: #2c2c2c;
  --sidebar-bg: #2c2c2c;
  --headerbg: #2c2c2c; }
  [data-theme-version="dark"] h1, [data-theme-version="dark"] .h1,
  [data-theme-version="dark"] h2,
  [data-theme-version="dark"] .h2,
  [data-theme-version="dark"] h3,
  [data-theme-version="dark"] .h3,
  [data-theme-version="dark"] h4,
  [data-theme-version="dark"] .h4,
  [data-theme-version="dark"] h5,
  [data-theme-version="dark"] .h5,
  [data-theme-version="dark"] h6,
  [data-theme-version="dark"] .h6 {
    color: #fff; }
  [data-theme-version="dark"] a.link {
    color: #ddd; }
  [data-theme-version="dark"] a.link:focus,
  [data-theme-version="dark"] a.link:hover {
    color: #81A5F9; }
  [data-theme-version="dark"] a:hover {
    color: #fff; }
  [data-theme-version="dark"] .border-right {
    border-right: 0.0625rem solid #444444 !important; }
  [data-theme-version="dark"] .border-left {
    border-left: 0.0625rem solid #444444 !important; }
  [data-theme-version="dark"] .border-top {
    border-top: 0.0625rem solid #444444 !important; }
  [data-theme-version="dark"] .border-bottom {
    border-bottom: 0.0625rem solid #444444 !important; }
  [data-theme-version="dark"] .border {
    border: 0.0625rem solid #444444 !important; }

[data-theme-version="dark"] {
  --secondary:$white;
  --text-dark:$white; }
  [data-theme-version="dark"] .card {
    background-color: #2c2c2c;
    box-shadow: none;
    border-color: #444444; }
  [data-theme-version="dark"] .dropdown-menu {
    background-color: #2c2c2c;
    box-shadow: 0rem 0rem 0rem 0.0625rem rgba(255, 255, 255, 0.1); }
    [data-theme-version="dark"] .dropdown-menu .dropdown-item {
      color: #777777; }
      [data-theme-version="dark"] .dropdown-menu .dropdown-item.selected, [data-theme-version="dark"] .dropdown-menu .dropdown-item.selected.active, [data-theme-version="dark"] .dropdown-menu .dropdown-item.active, [data-theme-version="dark"] .dropdown-menu .dropdown-item:hover, [data-theme-version="dark"] .dropdown-menu .dropdown-item:focus {
        background-color: #2c2c2c;
        color: #fff; }
  [data-theme-version="dark"] a {
    color: #fff !important; }
  [data-theme-version="dark"] .btn-link g [fill] {
    fill: #fff; }
  [data-theme-version="dark"] .btn-light:active,
  [data-theme-version="dark"] .btn-light:focus,
  [data-theme-version="dark"] .btn-light:hover {
    color: #000; }
  [data-theme-version="dark"] .form-control {
    background-color: transparent;
    border-color: #444444;
    color: #fff; }
  [data-theme-version="dark"] .form-select {
    background-color: #1E1E1E;
    border-color: #444444;
    color: #fff; }
  [data-theme-version="dark"] .toggle-switch.form-check .form-check-input {
    background: #1E1E1E; }
    [data-theme-version="dark"] .toggle-switch.form-check .form-check-input:checked {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
      background: var(--rgba-primary-1); }
  [data-theme-version="dark"] .modal-content {
    background: #2c2c2c; }
  [data-theme-version="dark"] .modal-footer,
  [data-theme-version="dark"] .modal-header {
    border-color: #444444; }
  [data-theme-version="dark"] .close {
    font-weight: 400;
    color: #fff;
    text-shadow: none; }
    [data-theme-version="dark"] .close:hover {
      color: #fff; }
  [data-theme-version="dark"] .star-rating .product-review,
  [data-theme-version="dark"] .new-arrival-content .item {
    color: #fff; }
  [data-theme-version="dark"] .custom-dropdown .dropdown-menu {
    border-color: #444444; }
  [data-theme-version="dark"] .widget-stat .media > span {
    background: #444444;
    border-color: #444444;
    color: #fff; }
  [data-theme-version="dark"] .plus-minus-input .custom-btn {
    background: #1E1E1E;
    border-color: #444444; }
  [data-theme-version="dark"] .dropdown-divider,
  [data-theme-version="dark"] .size-filter ul li {
    border-color: #444444; }
  [data-theme-version="dark"] .custom-select {
    border-color: #444444;
    color: #828690;
    background: #1E1E1E; }
  [data-theme-version="dark"] .nav-tabs {
    border-color: var(--primary); }
    [data-theme-version="dark"] .nav-tabs .nav-link {
      color: #fff; }
  [data-theme-version="dark"] .mail-list .list-group-item.active i {
    color: #fff; }
  [data-theme-version="dark"] hr {
    border-color: #444444; }
  [data-theme-version="dark"] .grid-col {
    background: #1E1E1E; }
  [data-theme-version="dark"] .noUi-target {
    border-color: #444444;
    border-radius: 0.5rem;
    box-shadow: none; }
    [data-theme-version="dark"] .noUi-target .noUi-connects {
      background: #444444; }
  [data-theme-version="dark"] .noUi-marker-large,
  [data-theme-version="dark"] .noUi-marker {
    background: #444444; }
  [data-theme-version="dark"] .input-group-text {
    background: transparent;
    color: #fff;
    border-color: #444444; }
  [data-theme-version="dark"] .note-editor.note-frame {
    border-color: #444444; }
    [data-theme-version="dark"] .note-editor.note-frame .btn {
      color: #fff; }
    [data-theme-version="dark"] .note-editor.note-frame .note-editing-area .note-editable {
      color: #fff; }
  [data-theme-version="dark"] .widget-media .timeline .timeline-panel {
    border-color: #444444; }
  [data-theme-version="dark"] .notification_dropdown .dropdown-menu-right .all-notification {
    border-color: #444444; }
  [data-theme-version="dark"] #user-activity .nav-tabs .nav-link {
    border-color: #444444; }
    [data-theme-version="dark"] #user-activity .nav-tabs .nav-link.active {
      background: #1E1E1E;
      color: #fff; }
  [data-theme-version="dark"] .list-group-item-action {
    color: #888888; }
  [data-theme-version="dark"] .list-group-item-action:focus,
  [data-theme-version="dark"] .list-group-item-action:hover,
  [data-theme-version="dark"] .list-group-item-action:focus {
    background-color: #1E1E1E;
    border-color: #1E1E1E; }
  [data-theme-version="dark"] .list-group-item.active {
    color: black !important;
    border-color: var(--primary); }
    [data-theme-version="dark"] .list-group-item.active:focus, [data-theme-version="dark"] .list-group-item.active:hover, [data-theme-version="dark"] .list-group-item.active:focus {
      background-color: var(--primary);
      border-color: var(--primary);
      color: #fff; }
  [data-theme-version="dark"] .swal2-popup {
    background: #2c2c2c; }
  [data-theme-version="dark"] .form-head .btn-outline-primary {
    border-color: #444444; }
  [data-theme-version="dark"] .form-head .btn-outline-primary:hover {
    border-color: var(--primary); }
  [data-theme-version="dark"] .review-tab.nav-pills li a.nav-link.active {
    background: transparent; }
  [data-theme-version="dark"] .new-arrival-content h4 a, [data-theme-version="dark"] .new-arrival-content .h4 a {
    color: #fff; }
  [data-theme-version="dark"] .text-black {
    color: #fff; }
  [data-theme-version="dark"] .abilities-chart .ct-chart .ct-label {
    fill: #fff; }
  [data-theme-version="dark"] .morris_chart_height text tspan {
    fill: #fff; }
  [data-theme-version="dark"] .btn-link {
    color: #fff; }
  [data-theme-version="dark"] .order-bg {
    background: #1E1E1E; }
  [data-theme-version="dark"] .detault-daterange {
    background: #1E1E1E;
    color: #fff; }
    [data-theme-version="dark"] .detault-daterange .input-group-text {
      background: #2c2c2c;
      border: 0; }
  [data-theme-version="dark"] .dataTablesCard {
    background-color: #2c2c2c; }
  [data-theme-version="dark"] .compose-content .dropzone {
    background: #1E1E1E !important; }
    [data-theme-version="dark"] .compose-content .dropzone .dz-message .dz-button {
      color: #fff; }
  [data-theme-version="dark"] .daterangepicker {
    background: #1E1E1E;
    border-color: var(--primary); }
    [data-theme-version="dark"] .daterangepicker .calendar-table {
      border-color: var(--primary);
      background: #1E1E1E; }
      [data-theme-version="dark"] .daterangepicker .calendar-table .table-condensed td:hover {
        background-color: var(--primary);
        color: #fff; }
    [data-theme-version="dark"] .daterangepicker:after {
      border-bottom: 0.375rem solid #1E1E1E; }
  [data-theme-version="dark"] .daterangepicker select.hourselect, [data-theme-version="dark"] .daterangepicker select.minuteselect, [data-theme-version="dark"] .daterangepicker select.secondselect, [data-theme-version="dark"] .daterangepicker select.ampmselect {
    background: #1E1E1E;
    border: 0.0625rem solid #444444;
    color: #fff; }
  [data-theme-version="dark"] .daterangepicker td.off,
  [data-theme-version="dark"] .daterangepicker td.off.in-range,
  [data-theme-version="dark"] .daterangepicker td.off.start-date,
  [data-theme-version="dark"] .daterangepicker td.off.end-date {
    background-color: #2c2c2c; }
    [data-theme-version="dark"] .daterangepicker td.off:hover,
    [data-theme-version="dark"] .daterangepicker td.off.in-range:hover,
    [data-theme-version="dark"] .daterangepicker td.off.start-date:hover,
    [data-theme-version="dark"] .daterangepicker td.off.end-date:hover {
      background-color: var(--primary);
      color: #fff; }
  [data-theme-version="dark"] .app-fullcalendar .fc-button {
    background-color: #1E1E1E;
    border-color: var(--primary);
    color: #fff;
    text-shadow: none; }
    [data-theme-version="dark"] .app-fullcalendar .fc-button:hover, [data-theme-version="dark"] .app-fullcalendar .fc-button.fc-stat-hover {
      background-color: var(--primary); }
  [data-theme-version="dark"] .dd-handle {
    border-color: #444444;
    background-color: #1E1E1E; }
  [data-theme-version="dark"] .menu-toggle .deznav .metismenu li > ul {
    background: #2c2c2c; }
  [data-theme-version="dark"] .header-right .notification_dropdown .nav-link {
    border-color: #444444; }
  [data-theme-version="dark"] .clockpicker-popover .popover-content {
    background-color: #2c2c2c; }
  [data-theme-version="dark"] .clockpicker-plate {
    background-color: #1E1E1E; }
  [data-theme-version="dark"] .clockpicker-popover .popover-title {
    background-color: #1E1E1E;
    color: #fff; }
  [data-theme-version="dark"] .form-wizard .nav-wizard li .nav-link span {
    background-color: #1E1E1E; }
  [data-theme-version="dark"] .form-wizard .nav-wizard li .nav-link:after {
    background: #1E1E1E; }
  [data-theme-version="dark"] .form-wizard .nav-wizard li .nav-link.active:after, [data-theme-version="dark"] .form-wizard .nav-wizard li .nav-link.done:after {
    background: var(--primary); }
  [data-theme-version="dark"] .form-wizard .nav-wizard li .nav-link.active span, [data-theme-version="dark"] .form-wizard .nav-wizard li .nav-link.done span {
    background: var(--primary-light); }
  [data-theme-version="dark"] .check-switch .custom-control-label:after, [data-theme-version="dark"] .check-switch .custom-control-label:before {
    border-color: var(--primary); }
  [data-theme-version="dark"] .fc-unthemed .fc-today {
    background: #1E1E1E; }
  [data-theme-version="dark"] .fc-unthemed .fc-divider, [data-theme-version="dark"] .fc-unthemed .fc-list-heading td, [data-theme-version="dark"] .fc-unthemed .fc-popover .fc-header {
    background: #444444; }
  [data-theme-version="dark"] .picker__box {
    background: #1E1E1E; }
    [data-theme-version="dark"] .picker__box .picker__button--clear, [data-theme-version="dark"] .picker__box .picker__button--close, [data-theme-version="dark"] .picker__box .picker__button--today {
      background: #2c2c2c;
      color: #fff; }
      [data-theme-version="dark"] .picker__box .picker__button--clear:hover:before, [data-theme-version="dark"] .picker__box .picker__button--close:hover:before, [data-theme-version="dark"] .picker__box .picker__button--today:hover:before {
        color: #fff; }
  [data-theme-version="dark"] .picker {
    color: #999; }
  [data-theme-version="dark"] .dtp > .dtp-content {
    background: #1E1E1E; }
  [data-theme-version="dark"] .dtp table.dtp-picker-days tr > td > a {
    color: #68686a; }
    [data-theme-version="dark"] .dtp table.dtp-picker-days tr > td > a.selected {
      color: #fff; }
  [data-theme-version="dark"] .order-request tbody tr {
    border-color: #444444; }
  [data-theme-version="dark"] .card-list li {
    color: #fff; }
  [data-theme-version="dark"] .card-bx .change-btn:hover {
    color: var(--primary); }
  [data-theme-version="dark"] .invoice-card.bg-warning {
    background-color: #5b3c1f !important; }
  [data-theme-version="dark"] .invoice-card.bg-success {
    background-color: #2a6729 !important; }
  [data-theme-version="dark"] .invoice-card.bg-info {
    background-color: #4c276a !important; }
  [data-theme-version="dark"] .invoice-card.bg-secondary {
    background-color: #1c3e52 !important; }
  [data-theme-version="dark"] .user-list li {
    border-color: #2c2c2c; }
  [data-theme-version="dark"] .toggle-switch {
    color: #fff; }
  [data-theme-version="dark"] .bar-chart .apexcharts-text tspan {
    fill: #969ba0; }
  [data-theme-version="dark"] .bar-chart line {
    stroke: #444444; }
  [data-theme-version="dark"] .accordion.style-1 .accordion-item, [data-theme-version="dark"] .invoice-list {
    border-color: #444444; }
  [data-theme-version="dark"] .accordion.style-1 .accordion-header.collapsed .user-info a, [data-theme-version="dark"] .accordion.style-1 .accordion-header.collapsed .user-info, [data-theme-version="dark"] .accordion.style-1 .accordion-header.collapsed > span {
    color: #fff; }
  [data-theme-version="dark"] .ic-card > a {
    background: #25479f; }
    [data-theme-version="dark"] .ic-card > a:first-child {
      border-color: #25479f; }
  [data-theme-version="dark"] .ic-card span {
    color: #fff; }
  [data-theme-version="dark"] table.dataTable thead th, [data-theme-version="dark"] table.dataTable thead td {
    border-color: #444444 !important; }
  [data-theme-version="dark"] .paging_simple_numbers.dataTables_paginate {
    background: #2c2c2c; }
  [data-theme-version="dark"] .dataTables_info {
    color: #fff; }
  [data-theme-version="dark"] .dataTables_wrapper .dataTables_paginate span .paginate_button.current,
  [data-theme-version="dark"] .dataTables_wrapper .dataTables_paginate span .paginate_button:hover {
    background: #000 !important;
    color: #fff !important; }
  [data-theme-version="dark"] .dashboard-select {
    background: var(--rgba-primary-1);
    color: white;
    border-color: #444444; }
  [data-theme-version="dark"] .dashboard-select .list {
    background: #2c2c2c; }
  [data-theme-version="dark"] .dashboard-select .option:hover, [data-theme-version="dark"] .dashboard-select .option.focus, [data-theme-version="dark"] .dashboard-select .option.selected.focus {
    background: #1E1E1E; }
  [data-theme-version="dark"] .card-tabs.style-1 .nav-tabs {
    background: #2c2c2c; }
  [data-theme-version="dark"] .transaction-details {
    border-color: #444444; }
  [data-theme-version="dark"] .description {
    color: #fff; }
  [data-theme-version="dark"] .transaction-details .amount-bx {
    background: #3f250d; }
    [data-theme-version="dark"] .transaction-details .amount-bx i {
      background: #8d3b0c; }
  [data-theme-version="dark"] .nice-select .option {
    background: #2c2c2c; }
  [data-theme-version="dark"] .nice-select .option:hover, [data-theme-version="dark"] .nice-select .option.focus, [data-theme-version="dark"] .nice-select .option.selected.focus {
    background: #2c2c2c; }
  [data-theme-version="dark"] .card-tabs.style-1 {
    border-color: #444444; }
  [data-theme-version="dark"] .nice-select .list {
    background: #1E1E1E;
    border-color: #444444; }
  [data-theme-version="dark"] .table-search .input-group .input-group-text {
    background: #2c2c2c; }
  [data-theme-version="dark"] .dark-btn svg path {
    stroke: #fff; }
  [data-theme-version="dark"] .bar-chart .apexcharts-legend-text {
    color: #fff !important; }
  [data-theme-version="dark"] .Sales tspan {
    fill: #fff; }
  [data-theme-version="dark"] .timeline-chart rect {
    fill: #1E1E1E; }
  [data-theme-version="dark"] .header-info span {
    color: #fff !important; }
  [data-theme-version="dark"] .smallipop-instance.blue {
    color: #fff; }
  [data-theme-version="dark"] .property-map .smallipop.smallimap-mapicon .circle-marker {
    border-color: #444444; }
  [data-theme-version="dark"] .property-features li {
    color: #fff; }
  [data-theme-version="dark"] .review-tabs-1 {
    background: #2c2c2c; }
  [data-theme-version="dark"] .coin-tabs {
    background: #2E2E40; }
    [data-theme-version="dark"] .coin-tabs .nav-link.active {
      background: var(--primary) !important;
      color: #fff; }
  [data-theme-version="dark"] .separate-row > div {
    border-color: #444444; }
  [data-theme-version="dark"] .bg-white {
    background: #1E1E1E !important; }
  [data-theme-version="dark"] .deznav .header-info2 span {
    color: #fff; }
  [data-theme-version="dark"] .brand-title path {
    fill: #fff; }
  [data-theme-version="dark"] .brand-title tspan {
    fill: #fff; }
  [data-theme-version="dark"] .shapreter-row > div {
    border-color: #444444; }
  [data-theme-version="dark"] .chartBar line {
    stroke: transparent; }
  [data-theme-version="dark"] .search-job .search-dropdown:after {
    background: #444444; }
  [data-theme-version="dark"] .chartjs line {
    stroke: #444444; }
  [data-theme-version="dark"] .form-label {
    color: #fff !important; }
  [data-theme-version="dark"] .datepicker-switch {
    color: #fff; }
  [data-theme-version="dark"] .datepicker.datepicker-dropdown td.day, [data-theme-version="dark"] .datepicker.datepicker-dropdown th.next, [data-theme-version="dark"] .datepicker.datepicker-dropdown th.prev {
    color: #fff; }
  [data-theme-version="dark"] .datepicker.datepicker-dropdown th.dow {
    color: #fff; }
  [data-theme-version="dark"] .datepicker.datepicker-dropdown th.datepicker-switch, [data-theme-version="dark"] .datepicker.datepicker-dropdown th.next, [data-theme-version="dark"] .datepicker.datepicker-dropdown th.prev {
    color: #fff; }
  [data-theme-version="dark"] .btn-close {
    color: #fff !important;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23099'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat; }
  [data-theme-version="dark"] .btn-close i {
    display: block;
    transform: scale(1.5); }
  [data-theme-version="dark"] .bgl-secondary {
    color: #fff; }
  [data-theme-version="dark"] .application tr td .btn {
    color: #fff; }
  [data-theme-version="dark"] .btn-link svg path {
    stroke: #fff; }
  [data-theme-version="dark"] .alert.alert-outline-secondary,
  [data-theme-version="dark"] .alert.alert-outline-dark {
    color: #fff; }
  [data-theme-version="dark"] .form-control-plaintext {
    color: #fff; }
  [data-theme-version="dark"] .timeline-panel .media-body small, [data-theme-version="dark"] .timeline-panel .media-body .small {
    color: #fff; }
  [data-theme-version="dark"] .jobs {
    border-color: #444444; }
  [data-theme-version="dark"] .apexcharts-gridlines-vertical line {
    stroke: #444444; }
  [data-theme-version="dark"] .apexcharts-text tspan {
    fill: #fff; }
  [data-theme-version="dark"] .widget-courses .schedule-icon {
    color: #fff; }
  [data-theme-version="dark"] .card-schedule .up-comming-schedule,
  [data-theme-version="dark"] .upcomming-task-widget {
    background: #2c2c2c; }
  [data-theme-version="dark"] .card-schedule .up-comming-schedule.style-1 {
    background: #1E1E1E; }
    [data-theme-version="dark"] .card-schedule .up-comming-schedule.style-1 .date-box {
      background: #2c2c2c;
      color: #fff; }
  [data-theme-version="dark"] .instructors-box .instructors-media .custome-badge .badge {
    color: #fff; }
  [data-theme-version="dark"] .instructors-box .instructors-media .info-box {
    background: #1E1E1E; }
  [data-theme-version="dark"] .course-details-tab .nav-tabs .nav-link {
    color: #cbc3c3; }
  [data-theme-version="dark"] .message-box,
  [data-theme-version="dark"] .chat-icon,
  [data-theme-version="dark"] .chat-people,
  [data-theme-version="dark"] .chat-tabs .chat-bx,
  [data-theme-version="dark"] .share-files,
  [data-theme-version="dark"] .type-massage,
  [data-theme-version="dark"] .work-ic {
    border-color: #444444; }
  [data-theme-version="dark"] .timeline-active .modulel {
    background: #2c2c2c; }
  [data-theme-version="dark"] .timeline-active li .time {
    background: #2c2c2c; }
  [data-theme-version="dark"] .achievements .achievements-content span,
  [data-theme-version="dark"] .bio .bio-content {
    background: #1E1E1E; }
  [data-theme-version="dark"] .score-active.style-1 .dashboard-select {
    background: #1E1E1E; }
  [data-theme-version="dark"] #activity line {
    stroke: #444444; }
  [data-theme-version="dark"] .pagination-down ul li a {
    background: #1E1E1E; }
    [data-theme-version="dark"] .pagination-down ul li a.active {
      background: var(--primary); }
  [data-theme-version="dark"] .custome-accordion .accordion-button {
    background: #2c2c2c; }
  [data-theme-version="dark"] .custome-accordion .acc-courses {
    background: #1E1E1E; }
  [data-theme-version="dark"] .widget-timeline .side-border h4, [data-theme-version="dark"] .widget-timeline .side-border .h4, [data-theme-version="dark"] .widget-timeline .side-border .h4,
  [data-theme-version="dark"] .widget-timeline .side-border .custom-dropdown {
    background: #2c2c2c; }
  [data-theme-version="dark"] .widget-timeline .side-border:after {
    border-color: #444444; }
  [data-theme-version="dark"] #columnChart line {
    stroke: transparent; }
  [data-theme-version="dark"] #sellingActivity svg path {
    stroke: #2c2c2c; }
  [data-theme-version="dark"] #sellingActivity line {
    stroke: transparent; }
  [data-theme-version="dark"] .review-box {
    background: #2c2c2c; }
  [data-theme-version="dark"] #NewCustomers line {
    stroke: transparent; }
  [data-theme-version="dark"] .students-list .search-area .form-control,
  [data-theme-version="dark"] .students-list .search-area .input-group-text {
    background: #1E1E1E;
    border: #444444; }
  [data-theme-version="dark"] .custome-accordion.style-1 .acc-courses {
    background: #2c2c2c;
    border-color: #444444; }
  [data-theme-version="dark"] .miac,
  [data-theme-version="dark"] .profile-img img, [data-theme-version="dark"] .profile-img span,
  [data-theme-version="dark"] .compose-content .dropzone,
  [data-theme-version="dark"] .fc-theme-standard td, [data-theme-version="dark"] .fc-theme-standard th,
  [data-theme-version="dark"] .fc-theme-standard .fc-scrollgrid.fc-scrollgrid-liquid, [data-theme-version="dark"] .fc-scrollgrid, [data-theme-version="dark"] table {
    border-color: #444444; }
  [data-theme-version="dark"] .btn-icon-end {
    border-color: #444444; }
  [data-theme-version="dark"] .raiting li {
    border-color: #444444; }
  [data-theme-version="dark"] .miac svg path {
    fill: #fff; }
  [data-theme-version="dark"] .type-massage.style-1 {
    background: #1E1E1E; }
    [data-theme-version="dark"] .type-massage.style-1 .input-group {
      background: #1E1E1E; }
  [data-theme-version="dark"] .header .header-content:after {
    background: #444444; }
  [data-theme-version="dark"] .search-coundry .dashboard-select {
    color: #fff;
    background: transparent; }
    [data-theme-version="dark"] .search-coundry .dashboard-select .option {
      color: #fff; }
    [data-theme-version="dark"] .search-coundry .dashboard-select:after {
      border-bottom: 0.125rem solid #fff;
      border-right: 0.125rem solid #fff; }
  [data-theme-version="dark"] .sidebar-right .nice-select .option.selected {
    color: #000; }
  [data-theme-version="dark"] #redial .apexcharts-datalabels-group text {
    fill: #fff; }
  [data-theme-version="dark"] .notification_dropdown .dropdown-menu-end .all-notification {
    border-color: #444444; }
  [data-theme-version="dark"] .app-fullcalendar1 .fc .fc-button-group > .fc-button {
    border-color: #444444; }
  [data-theme-version="dark"] .app-fullcalendar1 .fc-direction-ltr .fc-daygrid-event.fc-event-end, [data-theme-version="dark"] .app-fullcalendar1 .fc-direction-rtl .fc-daygrid-event.fc-event-start {
    background: #2c2c2c !important;
    border-color: #444444; }
  [data-theme-version="dark"] .card-calendar.style-1 .bootstrap-datetimepicker-widget .table-condensed td.day {
    color: #ffffff !important; }
  [data-theme-version="dark"] .dz-side-menu {
    background: #2c2c2c; }
  [data-theme-version="dark"] .nice-select .option.selected {
    color: #fff; }
  [data-theme-version="dark"] .dz-cource,
  [data-theme-version="dark"] .message-box,
  [data-theme-version="dark"] .type-massage {
    background-color: #2c2c2c; }
    [data-theme-version="dark"] .dz-cource .form-control,
    [data-theme-version="dark"] .message-box .form-control,
    [data-theme-version="dark"] .type-massage .form-control {
      background-color: transparent; }
  [data-theme-version="dark"] .card-calendar.style-1 .bootstrap-datetimepicker-widget table .picker-switch {
    color: #fff; }
  [data-theme-version="dark"] .fc .fc-button-group > .fc-button {
    border-color: #444444; }
  [data-theme-version="dark"] .fc-daygrid-dot-event {
    background-color: #1E1E1E;
    border-color: #1E1E1E; }
  [data-theme-version="dark"] #redial .apexcharts-radialbar-hollow {
    fill: #2c2c2c; }
  [data-theme-version="dark"] .chat-tabs .chat-bx.active:after {
    background-color: #1E1E1E; }
  [data-theme-version="dark"] .bootstrap-datetimepicker-widget table td.day:hover, [data-theme-version="dark"] .bootstrap-datetimepicker-widget table td.hour:hover, [data-theme-version="dark"] .bootstrap-datetimepicker-widget table td.minute:hover, [data-theme-version="dark"] .bootstrap-datetimepicker-widget table td.second:hover {
    background: #1E1E1E; }
  [data-theme-version="dark"] .chat-box-area .message-received p,
  [data-theme-version="dark"] .message-send,
  [data-theme-version="dark"] .type-massage .form-control {
    color: #fff;
    background-color: #171717; }
  [data-theme-version="dark"] .chat-icon ul li a,
  [data-theme-version="dark"] .file {
    background-color: rgba(255, 255, 255, 0.1); }
    [data-theme-version="dark"] .chat-icon ul li a svg path,
    [data-theme-version="dark"] .file svg path {
      fill: #fff; }
  [data-theme-version="dark"] .course-dedails-bx .description .user-pic span,
  [data-theme-version="dark"] .course-learn ul li {
    color: #fff; }
  [data-theme-version="dark"] .course-details-tab .user-pic2 p {
    color: #828690; }
  [data-theme-version="dark"] #columnChart .apexcharts-bar-series path {
    stroke: #2c2c2c; }
  [data-theme-version="dark"] .custome-donut tspan {
    fill: #fff; }
  [data-theme-version="dark"] #morris_donught.custome-donut svg path {
    stroke-width: 0px; }
  [data-theme-version="dark"] .card.bg-pink {
    background-color: #EB62D0; }
  [data-theme-version="dark"] #coin-ponent > div {
    background-color: #1E1E1E !important;
    color: #fff !important;
    border-color: #444444 !important; }
    [data-theme-version="dark"] #coin-ponent > div div {
      border-color: #444444 !important;
      background-color: #1E1E1E; }
  [data-theme-version="dark"] #coin-ponent div {
    border-color: #444444 !important;
    background-color: #1E1E1E; }
    [data-theme-version="dark"] #coin-ponent div div {
      border-color: #444444 !important;
      background-color: #1E1E1E; }
  [data-theme-version="dark"] #coin-ponent .cp-select-menu-item {
    border-color: #444444 !important;
    background-color: #1E1E1E; }
    [data-theme-version="dark"] #coin-ponent .cp-select-menu-item:hover {
      border-color: #444444 !important;
      background-color: #1E1E1E; }
  [data-theme-version="dark"] #coin-chart text,
  [data-theme-version="dark"] .revenueMap text {
    fill: white; }
  [data-theme-version="dark"] #coin-chart line,
  [data-theme-version="dark"] .revenueMap line {
    stroke: #444444; }
  [data-theme-version="dark"] .previews-info-list:hover {
    background-color: #1E1E1E; }
  [data-theme-version="dark"] .sell-element .sell-blance .form-control,
  [data-theme-version="dark"] .limit-sell .nav-tabs {
    background-color: #1E1E1E; }
  [data-theme-version="dark"] .text-start span {
    color: #fff !important; }
  [data-theme-version="dark"] .text-secondary {
    color: #fff !important; }
  [data-theme-version="dark"] .card-wiget .reward-earn span {
    color: #fff; }
  [data-theme-version="dark"] .market_chart line,
  [data-theme-version="dark"] #btcStock line {
    stroke: #444444; }
  [data-theme-version="dark"] .apexcharts-datalabels-group {
    stroke: #fff; }
  [data-theme-version="dark"] .btn.tp-btn svg path {
    stroke: #fff; }
  [data-theme-version="dark"] .coin-warpper .nav .nav-item {
    border-color: #444444; }
  [data-theme-version="dark"] .form-wrapper.trade-form .input-group .input-group-text {
    color: #fff; }
  [data-theme-version="dark"] .progress-bar.bg-secondary {
    background-color: #fff !important; }
  [data-theme-version="dark"] .profile-card {
    border-color: #444444; }
  [data-theme-version="dark"] .profile-card .card-footer,
  [data-theme-version="dark"] .author-profile .info-list li {
    border-color: #444444; }
  [data-theme-version="dark"] .email-left-box {
    border-color: #444444; }
  [data-theme-version="dark"] .email-left-box .mail-list .list-group-item i {
    color: #fff; }
  [data-theme-version="dark"] .dropzone .dlab-message {
    margin: 5em 0; }
  [data-theme-version="dark"] .email-list {
    background: #2c2c2c; }
  [data-theme-version="dark"] .email-list .message {
    border-color: #444444; }
  [data-theme-version="dark"] .email-list .message .col-mail-2 .subject {
    color: #fff; }
  [data-theme-version="dark"] .email-list .message:hover {
    background: #1E1E1E; }
  [data-theme-version="dark"] .email-right-box .table-pagenation {
    background: #1E1E1E;
    border-color: #444444; }
  [data-theme-version="dark"] .email-right-box .pagination {
    background: #1E1E1E; }
  [data-theme-version="dark"] .btn.tp-btn-light.btn-secondary,
  [data-theme-version="dark"] .btn.tp-btn.btn-secondary {
    color: white; }
  [data-theme-version="dark"] .list-group-item-secondary {
    color: #fff; }
  [data-theme-version="dark"] .students span {
    color: #fff; }
  [data-theme-version="dark"] .progress .bg-white {
    background-color: #fff !important; }
  [data-theme-version="dark"] .border-end {
    border-color: #444444 !important; }
  [data-theme-version="dark"] .form-check-input {
    border-color: #444444;
    background-color: #1E1E1E; }
  [data-theme-version="dark"] .menu-toggle .logo-color {
    display: none !important; }
  @media only screen and (max-width: 47.9375rem) {
    [data-theme-version="dark"] .menu-toggle .logo-color {
      display: block !important; } }
  [data-theme-version="dark"] #overiewChart line {
    stroke: #444444; }
  [data-theme-version="dark"] #NewCustomers #SvgjsSvg1006 {
    margin-top: 11px; }
  [data-theme-version="dark"] .chart-grd:after {
    background: transparent; }
  [data-theme-version="dark"] .chat-box-area .message-sent p {
    background: #383838;
    color: white; }
  [data-theme-version="dark"] .page-titles,
  [data-theme-version="dark"] .to-dodroup .sub-card,
  [data-theme-version="dark"] .ttl-project,
  [data-theme-version="dark"] .active-country .country-list,
  [data-theme-version="dark"] .active-projects.selling-product tbody tr td,
  [data-theme-version="dark"] .events .event-media,
  [data-theme-version="dark"] .active-projects.style-1 tbody tr td,
  [data-theme-version="dark"] .footer,
  [data-theme-version="dark"] .active-projects.manage-client tbody tr td,
  [data-theme-version="dark"] .task .task-summary,
  [data-theme-version="dark"] .active-projects.task-table tbody td,
  [data-theme-version="dark"] .kanbanPreview-bx .card-body .sub-card {
    border-color: #444444; }
  [data-theme-version="dark"] .avatar-list.avatar-list-stacked .avatar,
  [data-theme-version="dark"] .author-profile .card-footer .form-control,
  [data-theme-version="dark"] .profile-card .card-header,
  [data-theme-version="dark"] .author-profile .upload-link {
    border-color: #444444; }
  [data-theme-version="dark"] .email-left-box .mail-list .list-group-item {
    background: rgba(255, 255, 255, 0.1);
    margin: 13px 0px; }
  [data-theme-version="dark"] .inovice-logo .logo-abbr .react-w {
    fill: #fff;
    stroke: #fff; }
  [data-theme-version="dark"] .demo-right-inner {
    background-color: #2c2c2c; }
  [data-theme-version="dark"] .border-start {
    body-color: #444444; }
  [data-theme-version="dark"] .ttl-project .pr-data:after,
  [data-theme-version="dark"] .active-country:after {
    background: #444444; }
  [data-theme-version="dark"] .to-dodroup .sub-card .d-items,
  [data-theme-version="dark"] .kanbanPreview-bx .card-body .sub-card {
    background-color: #2c2c2c; }
  [data-theme-version="dark"] .to-dodroup .sub-card .d-items .d-items-2 label,
  [data-theme-version="dark"] .earning-tab .nav-item .nav-link.active {
    color: #fff; }
  [data-theme-version="dark"] .active-projects thead tr th,
  [data-theme-version="dark"] .schedules-cal .datepicker-days .dow {
    background-color: transparent; }
  [data-theme-version="dark"] .bootstrap-select .btn {
    background: #2c2c2c !important; }
  [data-theme-version="dark"] .sidebar-right-inner .bootstrap-select .btn {
    background: transparent !important;
    border-color: #E6E6E6 !important; }
  [data-theme-version="dark"] .sidebar-right-inner .dropdown-menu.show {
    background-color: #fff; }
  [data-theme-version="dark"] .sidebar-right-inner .dropdown-menu .dropdown-item.active, [data-theme-version="dark"] .sidebar-right-inner .dropdown-menu .dropdown-item:active {
    color: var(--primary) !important;
    background: var(--rgba-primary-1); }
  [data-theme-version="dark"] .sidebar-right-inner .dropdown-menu .dropdown-item {
    color: #777777 !important; }
  [data-theme-version="dark"] .sidebar-right-inner .dropdown-item:hover, [data-theme-version="dark"] .sidebar-right-inner .dropdown-item:focus {
    background-color: var(--bs-dropdown-link-hover-bg); }
  [data-theme-version="dark"] .badge-outline-dark,
  [data-theme-version="dark"] .badge-outline-secondary {
    border-color: #444444; }
  [data-theme-version="dark"] .light.badge-info {
    border-color: #444444; }
  [data-theme-version="dark"] .email-left-box .btn.text-white.btn-block {
    background-color: var(--primary); }
  [data-theme-version="dark"] .btn-secondary .text-secondary {
    color: #000 !important; }
  [data-theme-version="dark"] .list-group-item {
    color: #fff; }
  [data-theme-version="dark"] #TabWithIcon .nav-link.active {
    background-color: #1E1E1E;
    border-color: #444444;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }
  [data-theme-version="dark"] .ck.ck-editor .ck.ck-button {
    border-color: #444444;
    color: #fff;
    background-color: #1E1E1E; }
  [data-theme-version="dark"] .dark-logo {
    display: block; }
  [data-theme-version="dark"] .light-logo {
    display: none; }
  [data-theme-version="dark"] .deznav,
  [data-theme-version="dark"] .read-content-attachment .attachment > div:not(:last-child) {
    border-color: #444444; }
  [data-theme-version="dark"] #AllProject .apexcharts-series path {
    stroke: #444444; }
  [data-theme-version="dark"] .offcanvas {
    background-color: #1E1E1E; }
    [data-theme-version="dark"] .offcanvas .btn-close {
      background: transparent; }
  [data-theme-version="dark"] .events h6, [data-theme-version="dark"] .events .h6 {
    border-color: #444444;
    border: 0; }
  [data-theme-version="dark"] .task-tab {
    background-color: #2c2c2c; }
  [data-theme-version="dark"] .kanbanPreview-bx .card-body {
    background-color: #1E1E1E; }
  [data-theme-version="dark"] .custom-tab-1 .nav.nav-tabs {
    border-color: #444444; }
  [data-theme-version="dark"] .login-form {
    background-color: transparent; }
  [data-theme-version="dark"] .accordion-primary .accordion-header.collapsed {
    color: var(--primary) !important; }
  [data-theme-version="dark"] .accordion-item {
    background-color: #2c2c2c; }
  [data-theme-version="dark"] .mix-chart-tab .nav-link {
    background: #444444;
    color: #fff; }
    [data-theme-version="dark"] .mix-chart-tab .nav-link.active {
      background: var(--primary); }
  [data-theme-version="dark"] .active-map-main:after {
    background: #444444; }
  [data-theme-version="dark"] .upload-img .dropzone {
    border-color: #444444; }
  [data-theme-version="dark"] .upload-img .dropzone .dlab-message {
    margin: 1em 0; }
  [data-theme-version="dark"] .navbar-nav .active .scroll {
    color: var(--primary) !important; }
  [data-theme-version="dark"] .accordion-primary .accordion-header-text,
  [data-theme-version="dark"] .accordion-danger-solid .accordion-header-text {
    color: #fff; }
  [data-theme-version="dark"] .header-left .search-area {
    background-color: transparent; }
  [data-theme-version="dark"] [data-headerbg="color_4"] .search-area {
    background-color: transparent; }
  [data-theme-version="dark"] .third-post.style-2,
  [data-theme-version="dark"] .c-details ul li {
    border-color: #444444; }
  [data-theme-version="dark"] .dt-filter .dataTables_filter input[type="search"] {
    background: #1E1E1E; }
  [data-theme-version="dark"] .dt-filter .dataTables_filter label {
    background: #1E1E1E; }
  [data-theme-version="dark"] .card-profile .admin-user {
    background-color: rgba(0, 0, 0, 0.9); }
    [data-theme-version="dark"] .card-profile .admin-user .img-wrraper a {
      background-color: black;
      box-shadow: 0 0 6px 3px rgba(255, 255, 255, 0.1); }
  [data-theme-version="dark"] .card-profile .follow-list .follow-num {
    color: black; }
  [data-theme-version="dark"] .card-profile .user-social-links a {
    color: black !important; }
  [data-theme-version="dark"] .profile-accordion .accordion-item .accordion-header .accordion-button {
    color: #fff; }
  [data-theme-version="dark"] .c-list .search-area .form-control {
    border: 0; }
  [data-theme-version="dark"] .chat-left-area.active {
    background: #2c2c2c; }
  [data-theme-version="dark"] .inner-body {
    background-color: #202020;
    border-color: #444444; }
  [data-theme-version="dark"] .header .header-content {
    background-color: #2c2c2c;
    border-color: #444444; }
  [data-theme-version="dark"] .project-media p,
  [data-theme-version="dark"] .project-media span,
  [data-theme-version="dark"] .revenue-date span,
  [data-theme-version="dark"] .progress-box p,
  [data-theme-version="dark"] .avatar-list.avatar-list-stacked .avatar,
  [data-theme-version="dark"] .schedules-cal .datepicker-days .dow,
  [data-theme-version="dark"] .schedules-cal .datepicker-days .day {
    color: #fff; }
  [data-theme-version="dark"] .active-projects table.dataTable tbody td {
    border-color: #444444; }
  [data-theme-version="dark"] .header-right .notification_dropdown .nav-link svg path, [data-theme-version="dark"] .header-right .notification_dropdown .nav-link svg circle {
    stroke: #fff; }
  [data-theme-version="dark"] .header-right .notification_dropdown .nav-link:hover {
    background-color: #000; }
  [data-theme-version="dark"] .schedules-cal .datepicker-days .picker-switch {
    color: #fff; }
  [data-theme-version="dark"] .schedules-cal .datepicker-days .prev i, [data-theme-version="dark"] .schedules-cal .datepicker-days .next i {
    background: var(--primary-light); }
  [data-theme-version="dark"] .impressionbox:before {
    background: #444444; }
  [data-theme-version="dark"] .events .event-media span,
  [data-theme-version="dark"] .friend-list1 .friend-user p {
    color: #fff; }
  [data-theme-version="dark"] .friend-list1 .friend-user:hover {
    background: #000; }
  [data-theme-version="dark"] .apexcharts-legend-text {
    color: #fff !important; }
  [data-theme-version="dark"] #Statistics line {
    stroke: #444444; }
  [data-theme-version="dark"] #volumeChart .apexcharts-series path,
  [data-theme-version="dark"] #ExtraData .apexcharts-series path,
  [data-theme-version="dark"] #TotalEarning .apexcharts-series path {
    stroke: transparent; }
  [data-theme-version="dark"] .icon-box.bg-white svg path {
    fill: #fff !important; }
  [data-theme-version="dark"] .lang-chart li {
    color: #fff; }
  [data-theme-version="dark"] .analytics-card {
    background-color: #2c2c2c !important; }
  [data-theme-version="dark"] .activity-sale .recent-activity:after {
    background: #444444; }
  [data-theme-version="dark"] .blog-post .post-1 {
    border-color: #444444; }
  [data-theme-version="dark"] .chat-p.shaprate,
  [data-theme-version="dark"] .chat-border {
    border-color: #444444; }
  [data-theme-version="dark"] .second-head {
    color: #fff; }
  [data-theme-version="dark"] .btn-primary.sharp svg circle {
    fill: black; }
  [data-theme-version="dark"] .demo-right-inner {
    border-color: #444444; }
  [data-theme-version="dark"] .btn-close {
    background: none; }
  [data-theme-version="dark"] .navbar-nav .active .scroll {
    color: #ffffff !important; }
  [data-theme-version="dark"] .crm-cart-data h6, [data-theme-version="dark"] .crm-cart-data .h6 {
    color: black !important; }
  [data-theme-version="dark"] .crm-cart-data .text-black {
    color: black !important; }
  [data-theme-version="dark"] .crm-cart .badge.text-black {
    color: #fff !important; }
  [data-theme-version="dark"] .c-line:before {
    background: #444444; }
  [data-theme-version="dark"] .sales-bx h4, [data-theme-version="dark"] .sales-bx .h4 {
    color: black !important; }
  [data-theme-version="dark"] .badge i {
    color: black !important; }
  [data-theme-version="dark"] .c-list .search-area .form-control {
    background: black; }
  [data-theme-version="dark"] .c-list .search-area .input-group-text {
    background: black; }
    [data-theme-version="dark"] .c-list .search-area .input-group-text svg circle,
    [data-theme-version="dark"] .c-list .search-area .input-group-text svg path {
      stroke: #fff; }
  [data-theme-version="dark"] [data-sidebarbg="color_1"] .menu-icon svg path {
    stroke: #fff; }
  [data-theme-version="dark"] .heading-data td svg path {
    fill: #fff; }
  [data-theme-version="dark"] .notification_dropdown .dropdown-menu-end .all-notification {
    background: #2c2c2c; }
  [data-theme-version="dark"] .accordion {
    --bs-accordion-bg:$dark-card; }
  [data-theme-version="dark"] .depostit-card .depostit-card-media h3, [data-theme-version="dark"] .depostit-card .depostit-card-media .h3, [data-theme-version="dark"] .depostit-card .depostit-card-media .h3 {
    color: #222B40 !important; }
  [data-theme-version="dark"] .revenue-date h4, [data-theme-version="dark"] .revenue-date .h4, [data-theme-version="dark"] .revenue-date .h4 {
    color: #222B40 !important; }
  [data-theme-version="dark"] .star-rating ul li svg path {
    stroke: white; }
  [data-theme-version="dark"] .progress-box .progress .progress-bar {
    border-color: #444444; }
  [data-theme-version="dark"] .bg-warning-light .add-visit h6, [data-theme-version="dark"] .bg-warning-light .add-visit .h6, [data-theme-version="dark"] .bg-warning-light .add-visit h3, [data-theme-version="dark"] .bg-warning-light .add-visit .h3,
  [data-theme-version="dark"] .bg-primary-light .add-visit h6,
  [data-theme-version="dark"] .bg-primary-light .add-visit .h6,
  [data-theme-version="dark"] .bg-primary-light .add-visit h3,
  [data-theme-version="dark"] .bg-primary-light .add-visit .h3,
  [data-theme-version="dark"] .expenses-card .add-visit h6,
  [data-theme-version="dark"] .expenses-card .add-visit .h6,
  [data-theme-version="dark"] .expenses-card .add-visit h3,
  [data-theme-version="dark"] .expenses-card .add-visit .h3 {
    color: black !important; }
  [data-theme-version="dark"] .analytics-card .ic-n-bx .icon-box i {
    color: black; }
  [data-theme-version="dark"] .post-title .text-black {
    color: #fff !important; }
  [data-theme-version="dark"] .profile-news .media {
    border-color: #444444; }
  [data-theme-version="dark"] .post-details h3, [data-theme-version="dark"] .post-details .h3 {
    color: #fff !important; }
  [data-theme-version="dark"] .compose-wrapper .btn span i {
    color: black; }
  [data-theme-version="dark"] .fc-h-event .fc-event-title {
    color: black; }
  [data-theme-version="dark"] .light.btn-primary span svg circle {
    fill: #000 !important; }
  [data-theme-version="dark"] .list-group-item.active h6, [data-theme-version="dark"] .list-group-item.active .h6 {
    color: black !important; }
  [data-theme-version="dark"] .list-group .active h5, [data-theme-version="dark"] .list-group .active .h5 {
    color: black; }
  [data-theme-version="dark"] .pagination.page-indicator-light .page-item.active .page-link {
    color: black !important; }
  [data-theme-version="dark"] .to-dodroup .sub-card .d-items .form-check-input:checked {
    border-color: black;
    background-color: var(--primary); }
  [data-theme-version="dark"] .widget-stat .media .media-body h3, [data-theme-version="dark"] .widget-stat .media .media-body .h3, [data-theme-version="dark"] .widget-stat .media .media-body .h3 {
    color: black; }
  [data-theme-version="dark"] .card.bg-primary-light .list-group-item, [data-theme-version="dark"] .card.bg-warning-light .list-group-item {
    color: #000; }
  [data-theme-version="dark"] .footer .copyright a {
    color: var(--primary) !important; }
  [data-theme-version="dark"] .schedules-cal .datepicker-days .day {
    color: white !important; }
    [data-theme-version="dark"] .schedules-cal .datepicker-days .day.active, [data-theme-version="dark"] .schedules-cal .datepicker-days .day.today {
      color: #111828 !important; }
  [data-theme-version="dark"] .project-media svg rect {
    fill: #1E1E1E; }
  [data-theme-version="dark"] .revenue-date h6, [data-theme-version="dark"] .revenue-date .h6 {
    color: black; }
  [data-theme-version="dark"] .progress-box p {
    color: black !important; }
  [data-theme-version="dark"] .login-form .text-dark {
    color: white !important; }
  [data-theme-version="dark"] .login-form .login-title:before, [data-theme-version="dark"] .login-form .login-title:after {
    background-color: #444444; }
  [data-theme-version="dark"][data-sidebarbg="color_1"][data-sidebar-style="mini"][data-sidebar-style="full"] .metismenu li > ul {
    background-color: #1E1E1E !important; }
  [data-theme-version="dark"][data-sidebarbg="color_1"][data-sidebar-style="mini"][data-sidebar-style="full"] .deznav .metismenu > li:hover > a .menu-icon {
    background-color: transparent !important; }
    [data-theme-version="dark"][data-sidebarbg="color_1"][data-sidebar-style="mini"][data-sidebar-style="full"] .deznav .metismenu > li:hover > a .menu-icon svg path {
      stroke: var(--primary); }
  [data-theme-version="dark"][data-sidebarbg="color_1"][data-sidebar-style="mini"][data-sidebar-style="full"] .deznav .metismenu > li.mm-active > a .menu-icon svg path {
    stroke: var(--primary); }
  [data-theme-version="dark"][data-sidebarbg="color_1"][data-sidebar-style="icon-hover"] .deznav .metismenu > li:hover > a .menu-icon {
    background-color: transparent; }
    [data-theme-version="dark"][data-sidebarbg="color_1"][data-sidebar-style="icon-hover"] .deznav .metismenu > li:hover > a .menu-icon svg path {
      stroke: var(--primary); }
  [data-theme-version="dark"][data-sidebarbg="color_1"][data-sidebar-style="icon-hover"] .deznav .metismenu > li.mm-active > a .menu-icon svg path {
    stroke: var(--primary); }
  [data-theme-version="dark"][data-sidebarbg="color_1"][data-sidebar-style="modern"] .deznav .metismenu > li:hover > a .menu-icon,
  [data-theme-version="dark"][data-sidebarbg="color_1"][data-sidebar-style="modern"] .deznav .metismenu > li.mm-active > a .menu-icon {
    background-color: transparent; }
    [data-theme-version="dark"][data-sidebarbg="color_1"][data-sidebar-style="modern"] .deznav .metismenu > li:hover > a .menu-icon svg path,
    [data-theme-version="dark"][data-sidebarbg="color_1"][data-sidebar-style="modern"] .deznav .metismenu > li.mm-active > a .menu-icon svg path {
      stroke: var(--primary); }
  [data-theme-version="dark"][data-sidebarbg="color_1"][data-sidebar-style="modern"] .deznav .metismenu > li:before {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #ffffff 53.12%, rgba(255, 255, 255, 0) 100%); }
  [data-theme-version="dark"][data-sidebarbg="color_1"][data-layout="horizontal"] .deznav {
    border-top: 1px solid #444444; }
  [data-theme-version="dark"][data-sidebarbg="color_1"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu > li:hover > a .menu-icon {
    background-color: var(--primary); }
    [data-theme-version="dark"][data-sidebarbg="color_1"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu > li:hover > a .menu-icon svg path {
      stroke: black; }
  [data-theme-version="dark"][data-sidebarbg="color_1"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon svg path {
    stroke: black; }
  [data-theme-version="dark"][data-sidebarbg="color_1"] .menu-icon svg path {
    stroke: white; }
  [data-theme-version="dark"][data-sidebarbg="color_1"] .switch-btn {
    border-color: #444444; }
    [data-theme-version="dark"][data-sidebarbg="color_1"] .switch-btn svg path {
      stroke: white; }
  [data-theme-version="dark"][data-sidebarbg="color_1"] .menu-toggle .deznav .metismenu li > ul {
    background-color: #1E1E1E !important; }
  [data-theme-version="dark"][data-sidebarbg="color_1"][data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu > li > a .menu-icon {
    background-color: rgba(255, 255, 255, 0.2); }
  [data-theme-version="dark"][data-sidebarbg="color_1"][data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon {
    background-color: var(--primary-light); }
    [data-theme-version="dark"][data-sidebarbg="color_1"][data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon svg path {
      stroke: black; }
  [data-theme-version="dark"][data-sidebarbg="color_1"][data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu li:hover a .menu-icon {
    background-color: var(--primary-light); }
    [data-theme-version="dark"][data-sidebarbg="color_1"][data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu li:hover a .menu-icon svg path {
      stroke: black; }
  [data-theme-version="dark"][data-sidebarbg="color_1"][data-sidebar-style="modern"][data-layout="horizontal"] .deznav .metismenu > li:before {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 53.12%, rgba(255, 255, 255, 0) 100%); }
  [data-theme-version="dark"][data-primary="color_13"] .text-primary {
    color: white !important; }
  [data-theme-version="dark"][data-primary="color_13"] .footer .copyright a {
    color: #fff !important; }
  [data-theme-version="dark"][data-primary="color_13"] .active-projects div.dt-buttons .dt-button {
    color: #fff; }
  [data-theme-version="dark"][data-primary="color_13"] .ov-card:hover .icon-box {
    background-color: #fff !important; }
  [data-theme-version="dark"] .analytics-card p {
    color: #fff; }
  [data-theme-version="dark"] .schedules-cal .datepicker-days .day:hover {
    color: black !important; }
  [data-theme-version="dark"] .active-map .worldmap__figure-container, [data-theme-version="dark"] .sales-map .worldmap__figure-container {
    background-color: transparent !important; }
  [data-theme-version="dark"] .react-datepicker {
    background-color: transparent;
    color: #fff; }
  [data-theme-version="dark"] .dz-calender .react-datepicker__header {
    background-color: transparent;
    color: #fff; }
  [data-theme-version="dark"] #VisitorsChart line {
    stroke: #444444; }
  [data-theme-version="dark"] #VisitorsChart .apexcharts-text tspan {
    fill: #666666; }
  [data-theme-version="dark"] .custom-react-select > div:nth-child(4) > div {
    background-color: #2c2c2c; }
    [data-theme-version="dark"] .custom-react-select > div:nth-child(4) > div > div {
      background-color: #2c2c2c;
      -webkit-transition: all 0.5s;
      -ms-transition: all 0.5s;
      transition: all 0.5s; }
      [data-theme-version="dark"] .custom-react-select > div:nth-child(4) > div > div:hover {
        background-color: #2c2c2c;
        color: #fff; }
  [data-theme-version="dark"] .custom-react-select > div:nth-child(3) {
    background-color: #2c2c2c;
    border-color: #444444 !important; }
    [data-theme-version="dark"] .custom-react-select > div:nth-child(3):hover {
      border-color: var(--primary) !important; }
  [data-theme-version="dark"] .custom-react-select > div > div:first-child div {
    color: #fff; }
  [data-theme-version="dark"] .email-left-box .mail-list .list-group-item.active {
    color: #fff !important; }
  [data-theme-version="dark"] .accordion-primary .accordion-button {
    color: #000 !important;
    box-shadow: none; }
  [data-theme-version="dark"] .link-secondary {
    color: #fff !important; }
  [data-theme-version="dark"] .alert.alert-outline-light {
    color: #929696; }
  [data-theme-version="dark"] .badge-light {
    color: var(--text-dark) !important; }
  [data-theme-version="dark"] .css-b62m3t-container > div {
    border-color: rgba(255, 255, 255, 0.3);
    background: #1E1E1E; }
  [data-theme-version="dark"] .css-b62m3t-container [class*="-singleValue"],
  [data-theme-version="dark"] .css-b62m3t-container [class*="-placeholder"] {
    color: #fff; }
  [data-theme-version="dark"] .css-b62m3t-container [class*="-multiValue"] {
    background: #1E1E1E; }
    [data-theme-version="dark"] .css-b62m3t-container [class*="-multiValue"] div {
      color: #fff; }
  [data-theme-version="dark"] .css-b62m3t-container [class*="-IndicatorsContainer"] div {
    color: #fff; }
  [data-theme-version="dark"] .css-b62m3t-container [class*="-menu"] {
    background: #1E1E1E; }
    [data-theme-version="dark"] .css-b62m3t-container [class*="-menu"] > div > *:hover {
      background: var(--primary-light); }
  [data-theme-version="dark"] .sidebar-right .tab-content {
    background: #1E1E1E; }
    [data-theme-version="dark"] .sidebar-right .tab-content .tab-pane .admin-settings p {
      color: #fff; }
  [data-theme-version="dark"] .sidebar-right .sidebar-right-inner h4, [data-theme-version="dark"] .sidebar-right .sidebar-right-inner .h4 {
    background-color: #1E1E1E;
    color: #fff !important; }
  [data-theme-version="dark"] .sidebar-right .sidebar-right-inner .admin-title {
    color: #fff !important; }
  [data-theme-version="dark"] .sidebar-right .card-tabs .nav-tabs {
    background: #1E1E1E; }
    [data-theme-version="dark"] .sidebar-right .card-tabs .nav-tabs .nav-item .nav-link {
      background: rgba(160, 207, 26, 0.6);
      color: #fff; }
      [data-theme-version="dark"] .sidebar-right .card-tabs .nav-tabs .nav-item .nav-link.active {
        background: rgba(160, 207, 26, 0.9); }
  [data-theme-version="dark"] .filter-pagination {
    background: #1E1E1E; }
  [data-theme-version="dark"] .filter-pagination .previous-button {
    background-color: #2c2c2c; }
  [data-theme-version="dark"] .filter-pagination .next-button {
    background-color: #2c2c2c; }

[data-theme-version="dark"][data-layout="vertical"][data-sidebar-position="fixed"] .header {
  border-color: #444444; }

[data-theme-version="dark"] .header {
  border-color: #444444; }

[data-theme-version="dark"] .header-left .dashboard_bar {
  color: #fff; }

[data-theme-version="dark"] .search-area .form-control {
  background: #2c2c2c; }

[data-theme-version="dark"] .search-area .input-group-text {
  border: 0;
  background: #2c2c2c; }
  [data-theme-version="dark"] .search-area .input-group-text a {
    color: #4f7086; }

[data-theme-version="dark"] .header-right .notification_dropdown .nav-link {
  /* background: $d-bg!important; */ }
  [data-theme-version="dark"] .header-right .notification_dropdown .nav-link .badge {
    border-color: #2c2c2c; }

[data-theme-version="dark"] .header-right .dropdown .nav-link {
  color: #fff; }
  [data-theme-version="dark"] .header-right .dropdown .nav-link:hover {
    color: #fff; }

[data-theme-version="dark"] .nav-header .hamburger .line {
  background: #fff !important; }

[data-theme-version="dark"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #fff !important; }

[data-theme-version="dark"] .header-left .search-area .input-group-text {
  background-color: #000; }
  [data-theme-version="dark"] .header-left .search-area .input-group-text svg circle {
    stroke: #fff; }
  [data-theme-version="dark"] .header-left .search-area .input-group-text svg path {
    stroke: #fff; }

[data-theme-version="dark"] .header-left .search-area .form-control {
  background-color: #000; }

[data-theme-version="dark"] .nav-header {
  border-color: #444444; }

[data-theme-version="dark"] .nav-control svg rect {
  fill: #fff; }

[data-theme-version="dark"] .brand-logo {
  color: #fff; }
  [data-theme-version="dark"] .brand-logo:hover {
    color: #fff; }

[data-theme-version="dark"] .svg-title-path {
  fill: #fff; }

[data-theme-version="dark"] .nav-header {
  border-color: #444444; }

[data-theme-version="dark"] .nav-control svg rect {
  fill: #fff; }

[data-theme-version="dark"] .brand-logo {
  color: #fff; }
  [data-theme-version="dark"] .brand-logo:hover {
    color: #fff; }

[data-theme-version="dark"] .svg-title-path {
  fill: #fff; }

[data-theme-version="dark"] .fixed-content-box {
  background-color: #2c2c2c; }
  [data-theme-version="dark"] .fixed-content-box .head-name {
    background: #2c2c2c;
    color: #fff;
    border-color: #444444; }
  [data-theme-version="dark"] .fixed-content-box + .header + .deznav {
    background-color: #1E1E1E; }

[data-theme-version="dark"][data-layout="vertical"][data-sidebar-position="fixed"] .deznav {
  border-color: #444444; }

[data-theme-version="dark"][data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu > li.mm-active > a, [data-theme-version="dark"][data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu > li:hover > a {
  background: transparent; }
  [data-theme-version="dark"][data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu > li.mm-active > a i, [data-theme-version="dark"][data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu > li:hover > a i {
    color: var(--primary);
    background: var(--rgba-primary-1); }

[data-theme-version="dark"][data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu > li a > i {
  color: rgba(255, 255, 255, 0.3); }

[data-theme-version="dark"] .deznav .header-profile > a.nav-link {
  border-color: #444444; }
  [data-theme-version="dark"] .deznav .header-profile > a.nav-link .header-info span {
    color: #fff; }

[data-theme-version="dark"] .deznav .metismenu > li > a {
  color: #b3b3b3; }

[data-theme-version="dark"] .deznav .metismenu > li:hover > a, [data-theme-version="dark"] .deznav .metismenu > li:focus > a, [data-theme-version="dark"] .deznav .metismenu > li.mm-active > a {
  color: #fff !important;
  /* background:var(--rgba-primary-1); */ }
  [data-theme-version="dark"] .deznav .metismenu > li:hover > a i, [data-theme-version="dark"] .deznav .metismenu > li:focus > a i, [data-theme-version="dark"] .deznav .metismenu > li.mm-active > a i {
    color: #fff; }

[data-theme-version="dark"] .deznav .metismenu > li.mm-active ul ul {
  background-color: transparent; }

[data-theme-version="dark"] .deznav .metismenu ul:after {
  background-color: #444444; }

[data-theme-version="dark"] .deznav .metismenu ul a:hover, [data-theme-version="dark"] .deznav .metismenu ul a:focus, [data-theme-version="dark"] .deznav .metismenu ul a.mm-active {
  color: #fff !important; }

[data-theme-version="dark"] .deznav .metismenu ul a:before {
  background-color: #fff; }

[data-theme-version="dark"] .deznav .metismenu a {
  color: #b3b3b3 !important; }

[data-theme-version="dark"] .sidebar-right .card-tabs .nav-tabs {
  border-color: var(--rgba-primary-1) !important; }
  [data-theme-version="dark"] .sidebar-right .card-tabs .nav-tabs .nav-item .nav-link {
    color: #000 !important; }

[data-theme-version="dark"] .sidebar-right .form-control {
  background: #fff;
  color: #000;
  border-color: #cdcdcd; }

[data-theme-version="dark"] .sidebar-right .default-select .list {
  background: #fff; }
  [data-theme-version="dark"] .sidebar-right .default-select .list .option.selected, [data-theme-version="dark"] .sidebar-right .default-select .list .option.focus, [data-theme-version="dark"] .sidebar-right .default-select .list .option:hover {
    background: rgba(0, 0, 0, 0.05) !important; }

[data-theme-version="dark"] .sidebar-right .nice-select .option {
  background: #fff; }

[data-theme-version="dark"] .footer {
  background-color: #2c2c2c; }
  [data-theme-version="dark"] .footer .copyright {
    background-color: transparent; }
    [data-theme-version="dark"] .footer .copyright p {
      color: #fff; }

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
  -moz-tab-size: 16;
  tab-size: 2; }
  pre code.hljs {
    display: block;
    overflow-x: auto;
    color: #fff;
    padding: 0; }

.hljs-tag .hljs-attr,
.hljs-tag .hljs-name {
  color: #78c6ff; }

.hljs-punctuation,
.hljs-tag {
  color: var(--primary); }

.hljs-deletion,
.hljs-number,
.hljs-quote,
.hljs-selector-class,
.hljs-selector-id,
.hljs-string,
.hljs-template-tag,
.hljs-type {
  color: #ffa8a8; }

/* =============
   Calendar
============= */
.app-fullcalender button {
  border-radius: 0rem;
  color: #333333; }

.app-fullcalender td {
  border-color: #E6E6E6; }

.calendar {
  float: left;
  margin-bottom: 0; }

.fc-view {
  margin-top: 1.875rem; }

.fc .fc-button-group {
  margin-bottom: 0.625rem; }

.demo-app-calendar .fc .fc-button-group {
  margin-bottom: 0; }

.fc-toolbar {
  margin-bottom: 0.3125rem;
  margin-top: 0.9375rem; }
  @media (max-width: 575.98px) {
    .fc-toolbar .fc-left {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.625rem;
      float: none; } }
  @media (max-width: 575.98px) {
    .fc-toolbar .fc-right {
      display: flex;
      justify-content: center;
      float: none;
      margin-bottom: 0.3125rem; } }
  @media (max-width: 575.98px) {
    .fc-toolbar .fc-center {
      display: flex;
      justify-content: center; }
      .fc-toolbar .fc-center * {
        float: none; } }

.fc-toolbar h2, .fc-toolbar .h2 {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.875rem;
  text-transform: uppercase; }

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar .ui-state-hover,
.fc-toolbar button:focus,
.fc-toolbar button:hover {
  z-index: 0;
  box-shadow: none; }

.fc-widget-header {
  border: 0.0625rem solid #E6E6E6;
  border-bottom: 0 !important; }

.fc th.fc-widget-header {
  background: #E6E6E6 !important;
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 0.625rem 0;
  text-transform: uppercase; }

.fc-button {
  border: 0.0625rem solid #E6E6E6;
  text-transform: capitalize; }
  .fc-button.active {
    box-shadow: none !important; }

.fc-text-arrow {
  font-family: inherit;
  font-size: 1rem; }

.fc-event,
.external-event {
  border-radius: 0.125rem;
  border: none;
  cursor: move;
  font-size: 0.8125rem;
  margin: 0.3125rem 0.4375rem;
  padding: 0.3125rem;
  text-align: center; }

.external-event {
  cursor: move;
  margin: 0.625rem 0;
  padding: 0.125rem 0; }

.fc-basic-view td.fc-week-number span {
  padding-right: 0.3125rem; }

.fc-basic-view td.fc-day-number {
  padding-right: 0.3125rem; }

#drop-remove {
  margin-right: 0.5rem;
  top: 0.1875rem; }

#add-category .modal-dialog,
#event-modal .modal-dialog {
  max-width: 37.5rem; }

.fc-content {
  color: #fff; }

.fc th.fc-widget-header {
  background: transparent !important; }

.fc-button {
  background: #fff; }

.fc-state-hover {
  background: #fff !important; }

.fc-state-highlight {
  background: #f2f4fa !important; }
  [data-theme-version="dark"] .fc-state-highlight {
    color: #fff !important; }

.fc-cell-overlay {
  background: #fff !important; }

.fc-unthemed .fc-today {
  background: #f2f4fa !important; }

.fc-day-top {
  color: #333333 !important; }
  [data-theme-version="dark"] .fc-day-top {
    color: #fff !important; }

.external-event {
  color: #fff; }
  [data-theme-version="dark"] .external-event {
    color: #fff !important; }

.fc-basic-view .fc-body .fc-row {
  min-height: 1rem; }

.fc-scroller.fc-day-grid-container {
  height: 30.625rem !important; }

.fc-row.fc-week.fc-widget-content.fc-rigid {
  height: 5.0625rem !important; }

@media only screen and (max-width: 90rem) {
  .email_left_pane {
    display: none; } }

#external-events .external-event:before {
  content: "";
  display: block;
  width: 0.875rem;
  min-width: 0.875rem;
  height: 0.875rem;
  border-radius: 50%;
  margin-right: .9rem;
  position: relative;
  top: 0.025rem; }

[data-theme-version="dark"] #external-events [data-class="bg-primary"] {
  color: #000 !important; }

#external-events [data-class="bg-primary"]::before {
  background: var(--primary); }

#external-events [data-class="bg-success"]::before {
  background: #53D0B2; }

#external-events [data-class="bg-warning"]::before {
  background: #F4CF0C; }

#external-events [data-class="bg-dark"]::before {
  background: #333333; }

#external-events [data-class="bg-danger"]::before {
  background: #FF7C7C; }

#external-events [data-class="bg-info"]::before {
  background: #81A5F9; }

#external-events [data-class="bg-pink"]::before {
  background: #e83e8c; }

#external-events [data-class="bg-secondary"]::before {
  background: #111828; }

.fc .fc-row .fc-content-skeleton table,
.fc .fc-row .fc-content-skeleton td,
.fc .fc-row .fc-helper-skeleton td {
  border-color: #E6E6E6; }
  [data-theme-version="dark"] .fc .fc-row .fc-content-skeleton table, [data-theme-version="dark"]
  .fc .fc-row .fc-content-skeleton td, [data-theme-version="dark"]
  .fc .fc-row .fc-helper-skeleton td {
    border-color: #444444; }

[data-theme-version="dark"] .fc-unthemed .fc-content, [data-theme-version="dark"] .fc-unthemed .fc-divider, [data-theme-version="dark"] .fc-unthemed .fc-list-heading td, [data-theme-version="dark"] .fc-unthemed .fc-list-view, [data-theme-version="dark"] .fc-unthemed .fc-popover, [data-theme-version="dark"] .fc-unthemed .fc-row, [data-theme-version="dark"] .fc-unthemed tbody, [data-theme-version="dark"] .fc-unthemed td, [data-theme-version="dark"] .fc-unthemed th, [data-theme-version="dark"] .fc-unthemed thead {
  border-color: #444444; }

.fc-timegrid-event .fc-event-time {
  color: #ff8f8f; }

.email-left-box {
  border-right: 1px solid #fff;
  height: calc(100vh - 90px);
  padding: 1.875rem 1.25rem;
  overflow-y: auto;
  border-right: 1px solid #E6E6E6; }
  @media only screen and (max-width: 35.9375rem) {
    .email-left-box {
      padding-left: 10px !important; } }
  @media only screen and (max-width: 61.9375rem) {
    .email-left-box {
      border-right: 0;
      height: calc(100vh - 354.2px); } }
  .email-left-box .btn.text-white.btn-block {
    background-color: var(--secondary);
    font-weight: 500;
    font-size: 0.938rem;
    white-space: nowrap; }
  @media only screen and (max-width: 47.9375rem) {
    .email-left-box {
      width: 100%;
      float: none;
      border: none;
      padding-bottom: 1.875rem !important; } }
  @media only screen and (max-width: 35.9375rem) {
    .email-left-box {
      padding-bottom: 0rem !important; } }
  .email-left-box .intro-title {
    padding: 1rem;
    margin: 1.875rem 0 1.25rem 0; }
    .email-left-box .intro-title h5, .email-left-box .intro-title .h5 {
      margin-bottom: 0;
      font-size: 1rem;
      font-weight: 500; }
      .email-left-box .intro-title h5 i, .email-left-box .intro-title .h5 i {
        font-size: 0.75rem;
        position: relative;
        bottom: 0.0625rem; }
    .email-left-box .intro-title i {
      color: var(--primary); }
  .email-left-box .mail-list {
    margin-top: 2.188rem; }
    .email-left-box .mail-list a {
      border: 0;
      vertical-align: middle;
      padding: 0.625rem 0.9375rem;
      display: block;
      background: transparent;
      color: #000;
      font-weight: 600;
      z-index: 1; }
    .email-left-box .mail-list .change {
      color: #666666;
      font-weight: 500; }
    .email-left-box .mail-list .list-group-item {
      border-radius: 10px;
      padding: 0.75rem 1.0625rem;
      font-size: 0.938rem;
      font-weight: 500;
      margin-bottom: 0.625rem;
      overflow: hidden; }
      .email-left-box .mail-list .list-group-item i {
        font-size: 1.125rem;
        padding-right: 1.125rem;
        color: #000; }
      .email-left-box .mail-list .list-group-item svg {
        margin-right: 1.125rem; }
      .email-left-box .mail-list .list-group-item.active {
        color: #111828;
        background-color: var(--rgba-secondary-1); }
        .email-left-box .mail-list .list-group-item.active span.badge {
          background-color: var(--secondary); }
        .email-left-box .mail-list .list-group-item.active i {
          color: var(--secondary); }
      .email-left-box .mail-list .list-group-item.change {
        margin-bottom: 0; }
      .email-left-box .mail-list .list-group-item::before {
        content: '';
        display: block;
        background-color: var(--rgba-secondary-1);
        color: var(--secondary);
        position: absolute;
        left: 0;
        top: 0;
        width: 0%;
        height: 100%;
        -webkit-transition: all 0.5s;
        -ms-transition: all 0.5s;
        transition: all 0.5s; }
      .email-left-box .mail-list .list-group-item:hover {
        color: var(--secondary); }
        .email-left-box .mail-list .list-group-item:hover::before {
          width: 100%; }
        .email-left-box .mail-list .list-group-item:hover i {
          color: var(--secondary); }
        .email-left-box .mail-list .list-group-item:hover svg path {
          fill: var(--secondary);
          stroke: var(--secondary); }
  @media only screen and (max-width: 35.9375rem) {
    .email-left-box {
      height: calc(100vh - 160px); } }

input#checkAll.form-check-input {
  background-color: #fff; }
  [data-theme-version="dark"] input#checkAll.form-check-input {
    background-color: #1E1E1E; }

input#checkAll.form-check-input:checked {
  background-color: var(--primary); }

.email-right-box .pagination {
  padding: 0.4rem 0;
  margin-bottom: 0rem;
  background-color: #E6EBEE;
  padding-left: 16px; }

@media (min-width: 576px) and (max-width: 991.98px) {
  .email-right-box {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0 !important;
    clear: both; } }

@media (max-width: 575.98px) {
  .email-right-box {
    padding-left: 0;
    padding-right: 0; } }

.email-right-box .right-box-border {
  border-right: 0.125rem solid var(--rgba-primary-1); }

@media screen and (min-width: 40.5625rem) and (max-width: 75rem) {
  .email-right-box .right-box-padding {
    padding-left: 1.25rem; } }

@media (min-width: 106.25rem) {
  .email-right-box .right-box-padding {
    padding-left: 0.9375rem; } }

@media (min-width: 576px) and (max-width: 991.98px) {
  .email-right-box .right-box-padding {
    padding-left: 1rem; } }

@media only screen and (max-width: 35.9375rem) {
  .email-right-box .right-box-padding {
    padding: 1rem; } }

.email-right-box .table-pagenation {
  background-color: #e6ebee;
  margin-top: 0;
  border: 1px solid #fff;
  border-width: 1px 0w;
  margin-left: -4px;
  padding-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between; }

.toolbar {
  height: 3.563rem; }
  @media only screen and (max-width: 74.9375rem) {
    .toolbar {
      height: auto; } }
  @media only screen and (max-width: 61.9375rem) {
    .toolbar {
      border-top: 1px solid #fff;
      padding-top: 10px; } }
  .toolbar .btn-group .btn.nav-link.active {
    border: 0;
    background-color: transparent;
    font-size: 14px;
    padding: 0.5rem 1rem;
    color: var(--primary);
    box-shadow: unset; }
    @media only screen and (max-width: 74.9375rem) {
      .toolbar .btn-group .btn.nav-link.active {
        padding: 0.5rem 1rem;
        font-size: 0.75rem; } }
    @media only screen and (max-width: 35.9375rem) {
      .toolbar .btn-group .btn.nav-link.active {
        padding: 0.3rem 0.3rem; }
        .toolbar .btn-group .btn.nav-link.active .btn {
          font-size: 0.613rem; } }
    .toolbar .btn-group .btn.nav-link.active::before {
      content: '';
      display: block;
      background-color: var(--primary);
      position: absolute;
      bottom: -0.625rem;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      height: 3px;
      -webkit-transition: all 0.5s;
      -ms-transition: all 0.5s;
      transition: all 0.5s; }
      @media only screen and (max-width: 87.5rem) {
        .toolbar .btn-group .btn.nav-link.active::before {
          display: none; } }
    .toolbar .btn-group .btn.nav-link.active:focus {
      box-shadow: none; }
    .toolbar .btn-group .btn.nav-link.active svg path {
      fill: var(--primary); }
  .toolbar .btn-group .btn.nav-link::before {
    content: '';
    display: block;
    background-color: var(--primary);
    position: absolute;
    bottom: -0.325rem;
    left: 50%;
    transform: translateX(-50%);
    width: 0%;
    height: 3px;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s; }
  .toolbar .btn-group .btn.nav-link:hover::before {
    width: 100%; }
  .toolbar .btn-group .btn.nav-link:hover svg path {
    fill: var(--primary); }
  .toolbar .btn-group .btn.nav-link svg {
    margin-right: 0.5rem;
    margin-bottom: 0.2rem; }
  .toolbar .btn-group input {
    position: relative;
    top: 0.125rem; }

.saprat {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  flex-wrap: wrap; }
  .saprat .btn-group {
    margin-left: 1.125rem; }
    @media only screen and (max-width: 35.9375rem) {
      .saprat .btn-group {
        margin-left: 0; } }
  @media only screen and (max-width: 47.9375rem) {
    .saprat {
      flex-wrap: wrap; } }
  @media only screen and (max-width: 74.9375rem) {
    .saprat .mail-tools {
      margin-left: 3.725rem; } }
  @media only screen and (max-width: 35.9375rem) {
    .saprat .mail-tools {
      margin-left: 2.025rem; } }
  .saprat .mail-tools a:hover svg {
    background: var(--rgba-primary-1);
    border-radius: 0.625rem; }

.read-content textarea {
  height: 9.375rem;
  padding: 0.9375rem 1.25rem;
  border-radius: 0.625rem; }

.read-content-email {
  font-size: 0.875rem; }

.read-content p strong {
  color: #6a707e; }

.read-content-body p {
  margin-bottom: 1.875rem; }

.read-content-attachment {
  padding: 0.5rem 0; }
  .read-content-attachment h6, .read-content-attachment .h6 {
    font-size: 1.125rem;
    color: #6a707e; }
    .read-content-attachment h6 i, .read-content-attachment .h6 i {
      padding-right: 0.3125rem; }
  .read-content-attachment .attachment a {
    color: var(--primary) !important; }
  .read-content-attachment .attachment > div:not(:last-child) {
    border-right: 0.0625rem solid #DDDFE1; }

.compose-wrapper {
  height: calc(100vh - 180px);
  overflow-y: scroll;
  padding: 0 1rem; }
  @media only screen and (max-width: 61.9375rem) {
    .compose-wrapper {
      height: calc(100vh - 294px); } }
  @media only screen and (max-width: 35.9375rem) {
    .compose-wrapper {
      height: calc(100vh - 216px); } }

.read-wapper {
  height: calc(100vh - 180px); }

.compose-content .wysihtml5-toolbar {
  border-color: #fff; }

.compose-content .dropzone {
  background: #fff !important; }

.compose-content h5, .compose-content .h5 {
  font-size: 1.0625rem;
  color: #6a707e; }
  .compose-content h5 i, .compose-content .h5 i {
    font-size: 1.125rem;
    transform: rotate(90deg); }

.compose-content .dropzone {
  border: 0.0625rem dashed #DDDFE1;
  min-height: 13.125rem;
  position: relative;
  border-radius: 0.625rem; }
  .compose-content .dropzone .dz-message {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

.compose-content #email-compose-editor {
  height: unset; }

.email-list {
  display: block;
  padding-left: 0;
  background: #fff;
  height: calc(100vh - 236px);
  overflow-y: scroll; }
  .email-list label {
    margin-bottom: 0px; }
  .email-list .message {
    position: relative;
    display: block;
    height: 3.438rem;
    padding: 0 15px;
    line-height: 3.438rem;
    cursor: default;
    transition-duration: 0.3s;
    border-bottom: 1px solid #fff; }
    .email-list .message a {
      color: var(--primary); }
    .email-list .message-single .custom-checkbox {
      margin-top: 0.125rem; }
    .email-list .message-single i {
      color: #89879f;
      font-size: 1.125rem;
      padding-left: .4rem; }
    .email-list .message .icon {
      position: absolute;
      top: 16px;
      right: 10px;
      opacity: 0;
      transition-duration: 0.3s; }
      @media only screen and (max-width: 35.9375rem) {
        .email-list .message .icon {
          display: none; } }
    .email-list .message:hover {
      transition-duration: 0.05s;
      background: #F3F5F7; }
      .email-list .message:hover .icon {
        opacity: 1; }
      .email-list .message:hover .col-mail-2 .date {
        opacity: 0; }
      .email-list .message:hover .message-single .form-check-input {
        opacity: 1; }
    .email-list .message .col-mail {
      float: left;
      position: relative; }
    .email-list .message .col-mail-1 {
      width: 5.625rem; }
      .email-list .message .col-mail-1 .star-toggle {
        display: block;
        float: left;
        margin-top: 1.125rem;
        font-size: 1rem;
        margin-left: 0.3125rem; }
      .email-list .message .col-mail-1 .email-checkbox {
        display: block;
        float: left;
        margin: 0.9375rem 0.625rem 0 1.25rem; }
      .email-list .message .col-mail-1 .dot {
        display: block;
        float: left;
        border: .4rem solid transparent;
        border-radius: 6.25rem;
        margin: 1.375rem 1.625rem 0;
        height: 0;
        width: 0;
        line-height: 0;
        font-size: 0; }
    .email-list .message .col-mail-2 {
      position: absolute;
      top: 0;
      left: 5.625rem;
      right: 0;
      bottom: 0; }
      .email-list .message .col-mail-2 .subject {
        position: absolute;
        top: 0;
        left: 0;
        right: 5.5rem;
        font-weight: 400;
        color: var(--secondary);
        margin: 0 220px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap; }
        .email-list .message .col-mail-2 .subject span {
          color: #666666; }
      .email-list .message .col-mail-2 .date {
        position: absolute;
        top: 0;
        right: 15px;
        opacity: 1;
        transition-duration: 0.3s;
        font-weight: 500; }
    .email-list .message .message-single .bookmark-btn input {
      position: absolute;
      cursor: pointer;
      height: 0;
      width: 0;
      display: none; }
    .email-list .message .message-single .bookmark-btn .checkmark {
      font-weight: 900;
      font-family: 'font awesome 5 free'; }
      .email-list .message .message-single .bookmark-btn .checkmark:before {
        content: "\f005";
        font-size: 1.25rem;
        border-color: #666666;
        font-weight: 100;
        color: #C4C4C4; }
    .email-list .message .message-single .bookmark-btn input:checked ~ .checkmark:before {
      color: #ff9838;
      font-weight: 900; }
    .email-list .message .message-single .form-check-input {
      margin-top: 0; }
    .email-list .message .hader {
      font-size: 0.938rem;
      font-weight: 400;
      padding: 0 1.25rem; }

.email-checkbox {
  cursor: pointer;
  height: 0.9375rem;
  width: 0.9375rem;
  position: relative;
  display: inline-block;
  border-radius: .1rem;
  position: relative;
  top: 0.3125rem; }
  .email-checkbox input {
    opacity: 0;
    cursor: pointer; }
    .email-checkbox input:checked label {
      opacity: 1; }
  .email-checkbox label {
    position: absolute;
    top: .3rem;
    left: .3rem;
    right: .3rem;
    bottom: .3rem;
    cursor: pointer;
    opacity: 0;
    margin-bottom: 0 !important;
    transition-duration: 0.05s; }

.chat-wrap {
  padding: 1.0625rem 1.875rem; }
  .chat-wrap .media .media-body h6, .chat-wrap .media .media-body .h6 {
    font-size: 1.0625rem;
    color: #6a707e; }
  .chat-wrap .media .media-body p {
    font-size: 0.875rem; }

@media (min-width: 40.5rem) {
  .email-filter {
    padding-left: 1.25rem; } }

@media (min-width: 106.25rem) {
  .email-filter {
    padding-left: 1.875rem; } }

.email-filter .input-group-prepend i {
  font-size: 0.875rem; }

.email-filter .input-group-prepend .input-group-text {
  border: 0;
  border-bottom: 0.0625rem solid #DDDFE1 !important;
  background: transparent; }

.email-filter .input-group .form-control {
  padding: 0 0 0 0.3125rem;
  border: 0;
  font-size: 0.875rem;
  height: 1.875rem;
  border-bottom: 0.0625rem solid #DDDFE1; }
  .email-filter .input-group .form-control::placeholder {
    font-size: 0.875rem; }

.email-filter .input-group > .form-control {
  min-height: 1.875rem; }

.single-mail {
  display: block;
  padding: 1.5625rem 0; }
  .single-mail .media {
    padding-left: 1.25rem;
    padding-right: 1.25rem; }
    @media (min-width: 106.25rem) {
      .single-mail .media {
        padding-left: 1.875rem;
        padding-right: 1.875rem; } }
    .single-mail .media img {
      width: 3.4375rem;
      height: 3.4375rem;
      border-radius: 50%;
      margin-right: 0.9375rem; }
      @media (min-width: 106.25rem) {
        .single-mail .media img {
          margin-right: 1.875rem; } }
    .single-mail .media-body h6, .single-mail .media-body .h6 {
      color: #abafb3; }
    .single-mail .media-body h4, .single-mail .media-body .h4 {
      font-size: 1rem;
      color: #6a707e; }
      .single-mail .media-body h4 button i, .single-mail .media-body .h4 button i {
        font-size: 1.125rem;
        color: #abafb3;
        font-weight: bold;
        transform: rotate(90deg); }
    .single-mail .media-body p {
      font-size: 0.875rem;
      color: #abafb3; }
  .single-mail.active {
    background: var(--primary); }
    .single-mail.active h6, .single-mail.active .h6, .single-mail.active h4, .single-mail.active .h4, .single-mail.active p, .single-mail.active i {
      color: #fff !important; }

[direction="rtl"] .email-right-box {
  padding-left: 1rem;
  padding-right: 15rem; }
  @media only screen and (max-width: 61.9375rem) {
    [direction="rtl"] .email-right-box {
      padding-left: 0;
      padding-right: 0;
      margin-right: 0; } }
  @media only screen and (max-width: 35.9375rem) {
    [direction="rtl"] .email-right-box {
      padding-left: 0;
      padding-right: 0; } }

[direction="rtl"] .email-left-box {
  float: right; }

[direction="rtl"] .email-list .message .col-mail-2 {
  right: 5.625rem;
  left: 0;
  float: right; }
  [direction="rtl"] .email-list .message .col-mail-2 .date {
    right: auto;
    left: 0; }
  [direction="rtl"] .email-list .message .col-mail-2 .subject {
    right: 0;
    left: 5.5rem; }

.inbox-height {
  min-height: 800px !important; }

@media only screen and (max-width: 35.9375rem) {
  .email-left-body {
    width: 18rem;
    position: fixed;
    height: 100vh;
    z-index: 2;
    transition: all 0.5s;
    left: -22rem;
    top: 0px;
    z-index: 999; }
    .email-left-body.active {
      left: 0;
      background: #fff;
      transition: all 0.5s;
      overflow-y: scroll; } }

.email-tools-box {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: var(--rgba-primary-1);
  border-radius: 0.625rem;
  color: var(--primary);
  display: none !important; }
  .email-tools-box.active {
    background-color: #A0CF1A;
    color: #fff; }
  @media only screen and (max-width: 35.9375rem) {
    .email-tools-box {
      display: inline-block !important;
      margin-left: auto; } }
  .email-tools-box.active:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vh;
    height: 100vh;
    background-color: #000;
    opacity: 0.2;
    border-radius: 0.75rem;
    z-index: 4; }

.email-left-box {
  margin-bottom: 20px; }
  @media only screen and (max-width: 74.9375rem) {
    .email-left-box {
      margin-right: -10px; } }
  @media only screen and (max-width: 61.9375rem) {
    .email-left-box {
      margin: 0; } }
  @media only screen and (max-width: 35.9375rem) {
    .email-left-box {
      margin-top: 20px; } }

.photo-content {
  position: relative; }
  .photo-content .cover-photo {
    background: url(../images/profile/cover.jpg);
    background-size: cover;
    background-position: center;
    min-height: 15.625rem;
    width: 100%; }

.profile .profile-photo {
  max-width: 6.25rem;
  position: relative;
  z-index: 1;
  margin-top: -4.5rem;
  margin-right: 0.625rem; }
  @media only screen and (max-width: 35.9375rem) {
    .profile .profile-photo {
      width: 5rem;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 1.25rem; } }

[direction="rtl"] .profile .profile-photo {
  left: auto;
  right: 0;
  margin-right: 0;
  margin-left: 0.9375rem; }
  @media only screen and (max-width: 74.9375rem) {
    [direction="rtl"] .profile .profile-photo {
      right: 0.9375rem; } }
  @media only screen and (max-width: 35.9375rem) {
    [direction="rtl"] .profile .profile-photo {
      width: 5rem;
      right: calc(50% - 2.5rem);
      top: -6.25rem; } }

.profile-info {
  padding: 0.9375rem 1.25rem; }
  @media only screen and (max-width: 35.9375rem) {
    .profile-info {
      padding: 0 0 1.25rem;
      text-align: center; } }
  .profile-info p {
    color: #828690; }
  .profile-info .prf-col {
    min-width: 15.625rem;
    padding: 0.625rem 3.125rem 0; }

.profile-interest .row {
  margin: 0 -0.0437rem; }
  .profile-interest .row .int-col {
    padding: 0 0.0437rem; }
    .profile-interest .row .int-col .interest-cat {
      margin-bottom: 0.0875rem;
      position: relative;
      display: block; }
      .profile-interest .row .int-col .interest-cat:after {
        background: #000;
        bottom: 0;
        content: "";
        left: 0;
        opacity: 0.5;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 0; }
      .profile-interest .row .int-col .interest-cat p {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        padding: 0.3125rem;
        left: 0;
        margin: 0;
        z-index: 1;
        color: white;
        font-size: 0.075rem; }

.profile-tab .nav-item .nav-link {
  font-size: 1rem;
  margin-right: 1.875rem;
  transition: all 0.5s ease-in-out;
  border: none;
  border-bottom: 0.0125rem solid transparent;
  color: #828690; }
  .profile-tab .nav-item .nav-link:hover, .profile-tab .nav-item .nav-link.active {
    border: 0;
    background: transparent;
    background: transparent;
    border-bottom: 0.0125rem solid var(--primary);
    color: var(--primary); }
  @media only screen and (max-width: 35.9375rem) {
    .profile-tab .nav-item .nav-link {
      margin-right: 0rem; } }

.profile-info {
  display: flex; }
  @media only screen and (max-width: 35.9375rem) {
    .profile-info {
      display: block; } }
  .profile-info .profile-details {
    display: flex;
    width: 100%; }
    @media only screen and (max-width: 35.9375rem) {
      .profile-info .profile-details {
        display: block; }
        .profile-info .profile-details .dropdown {
          position: absolute;
          top: 1.875rem;
          right: 1.875rem; } }

.post-input {
  margin-bottom: 1.875rem; }
  .post-input .form-control {
    height: 4.6875rem;
    font-weight: 400;
    margin: 0.9375rem 0; }
  .post-input .btn-social {
    font-size: 1.25rem;
    height: 3.4375rem;
    display: inline-block;
    padding: 0;
    text-align: center;
    border-radius: 0.625rem;
    color: #fff;
    width: 3.4375rem;
    line-height: 3.375rem; }
    .post-input .btn-social.facebook {
      background-color: #3B5998; }
    .post-input .btn-social.google-plus {
      background-color: #DE4E43; }
    .post-input .btn-social.linkedin {
      background-color: #007BB6; }
    .post-input .btn-social.instagram {
      background-color: #8A5A4E; }
    .post-input .btn-social.twitter {
      background-color: #1EA1F3; }
    .post-input .btn-social.youtube {
      background-color: #CE201F; }
    .post-input .btn-social.whatsapp {
      background-color: #01C854; }
    .post-input .btn-social i {
      margin: 0 !important; }

.profile-uoloaded-post img {
  margin-bottom: 1.25rem; }

.profile-uoloaded-post a h4, .profile-uoloaded-post a .h4 {
  margin-bottom: 0.625rem;
  color: #464a53; }

.profile-page {
  height: 60px; }

.card-profile {
  background-image: url("../images/crm-profile.jpg");
  background-size: cover;
  background-position: center center;
  height: 420px;
  padding: 30px; }
  .card-profile .admin-user {
    background-color: #fff;
    padding: 30px;
    max-width: 380px;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
    border-radius: 0.625rem; }
    .card-profile .admin-user .img-wrraper {
      position: relative;
      margin-bottom: 10px; }
      .card-profile .admin-user .img-wrraper img {
        height: 80px;
        width: 80px;
        border: 7px solid rgba(62, 95, 206, 0.08); }
      .card-profile .admin-user .img-wrraper a {
        position: absolute;
        bottom: 0;
        margin: 0 24px;
        height: 30px;
        width: 30px;
        background: #fff;
        color: var(--primary);
        text-align: center;
        line-height: 30px;
        border-radius: 50%;
        box-shadow: 0 0 6px 3px rgba(68, 102, 242, 0.1); }
  .card-profile .user-details .title h4, .card-profile .user-details .title .h4 {
    font-size: 1rem; }
  .card-profile .user-details .title h6, .card-profile .user-details .title .h6 {
    color: #888888;
    margin-bottom: 20px;
    font-weight: 400; }
  .card-profile .user-social-links {
    display: flex;
    align-items: center;
    justify-content: center; }
    .card-profile .user-social-links a {
      color: var(--text-dark);
      display: block;
      height: 30px;
      width: 30px;
      background-color: var(--primary-light);
      text-align: center;
      line-height: 30px;
      margin: 0 4px;
      border-radius: 0.375rem; }
      .card-profile .user-social-links a:hover {
        background: var(--primary);
        color: #fff; }
  .card-profile .follow-list {
    display: flex;
    justify-content: center; }
    .card-profile .follow-list li {
      padding: 14px 20px;
      border-radius: 0.625rem;
      margin: 0 9px;
      margin-top: 44px;
      background-color: var(--primary-light); }
      @media only screen and (max-width: 35.9375rem) {
        .card-profile .follow-list li {
          padding: 5px 10px; } }
      .card-profile .follow-list li span {
        color: #000; }
    .card-profile .follow-list .follow-num {
      font-size: 1rem;
      font-weight: 700;
      color: black; }

.profile-accordion .accordion-item {
  background: transparent; }
  .profile-accordion .accordion-item .accordion-header {
    padding: 0;
    border: 0; }
    .profile-accordion .accordion-item .accordion-header .accordion-button {
      padding: 0;
      background: transparent;
      box-shadow: none;
      font-size: 1rem;
      font-weight: 700;
      color: black;
      border: 0; }
  .profile-accordion .accordion-item .accordion-body {
    padding-left: 0;
    padding-right: 0; }

.about-me ul li {
  display: flex;
  align-items: center;
  margin: 15px 0px;
  margin-top: 0; }
  .about-me ul li h6, .about-me ul li .h6 {
    margin-bottom: 0; }
  .about-me ul li i {
    height: 35px;
    width: 35px;
    background-color: var(--primary-light);
    color: black;
    text-align: center;
    line-height: 35px;
    border-radius: 0.375rem;
    margin-right: 15px; }

.second-head {
  font-size: 1rem;
  font-weight: 500;
  color: black; }

.bg-facebook {
  background: #4867aa;
  color: white; }
  .bg-facebook:hover {
    background: #4867aa;
    color: white; }

.bg-whatsapp {
  background: #1ad03f;
  color: white; }
  .bg-whatsapp:hover {
    background: #1ad03f;
    color: white; }

.bg-skype {
  background: #0194d7;
  color: white; }
  .bg-skype:hover {
    background: #0194d7;
    color: white; }

.bg-linkedin {
  background: #0a66c2;
  color: white; }
  .bg-linkedin:hover {
    background: #0a66c2;
    color: white; }

.profile-interest img {
  border-radius: 0.625rem; }

.post-img img {
  border-radius: 0.625rem;
  width: 100%; }
  @media only screen and (max-width: 87.5rem) {
    .post-img img {
      margin-bottom: 10px;
      margin-left: 0 !important;
      margin-right: 0 !important; } }

.friend-list .avatar {
  margin-left: 9px;
  margin-bottom: 16px; }

.chat-border {
  border-right: 1px solid rgba(136, 136, 136, 0.2); }

.chat-p {
  display: flex;
  justify-content: space-between;
  padding: 1.25rem; }
  .chat-p h6, .chat-p .h6 {
    font-size: 14px; }
  .chat-p span {
    font-size: 12px;
    white-space: nowrap; }
  .chat-p.shaprate {
    border-bottom: 1px solid rgba(136, 136, 136, 0.2); }
  .chat-p.style-1 {
    padding-bottom: 0; }
    .chat-p.style-1 .active {
      position: relative; }
      .chat-p.style-1 .active:after {
        content: "";
        position: absolute;
        height: 10px;
        width: 10px;
        background: #53D0B2;
        bottom: 0;
        left: 33px;
        border-radius: 50%;
        border: 2px solid #fff; }

.c-list {
  padding: 1.25rem; }
  .c-list .search-area {
    background-color: transparent !important; }
    .c-list .search-area .form-control {
      background: #F4F4F5;
      border-color: #F4F4F5; }
    .c-list .search-area .input-group-text {
      background: #F4F4F5;
      border-color: #F4F4F5; }

.people-list {
  height: calc(100vh - 260px);
  margin-bottom: 25px; }

.chat-height {
  min-height: 1000px !important; }

.chat-admin {
  height: 0; }
  .chat-admin .sharp {
    line-height: 1.5; }
  @media only screen and (max-width: 35.9375rem) {
    .chat-admin .icon-box {
      height: 2rem;
      width: 2rem;
      border-radius: 5px;
      line-height: 1.9rem; }
      .chat-admin .icon-box svg {
        width: 14px;
        line-height: 1.8rem; }
    .chat-admin .sharp {
      height: 2rem;
      min-width: 2rem;
      min-height: 2rem;
      padding: 0.2375rem; } }

.chat-box-area.style-2 {
  padding: 1.25rem;
  height: calc(100vh - 260px);
  margin-top: 20px; }
  @media only screen and (max-width: 35.9375rem) {
    .chat-box-area.style-2 {
      height: calc(100vh - 230px); } }

.message-send.style-2 {
  margin: 1.25rem; }

.chat-meadia {
  padding: 1.25rem; }
  @media only screen and (max-width: 74.9375rem) {
    .chat-meadia {
      width: 50%;
      float: left; } }
  @media only screen and (max-width: 35.9375rem) {
    .chat-meadia {
      width: 100%; } }

.image-list {
  margin-top: 10px; }
  .image-list img {
    height: 75px;
    width: 75px;
    object-fit: cover;
    border-radius: 6px;
    margin-left: 10px;
    margin-bottom: 10px; }

.file-list h5, .file-list .h5 {
  margin-top: 10px; }

.file-list .filie-l-icon {
  margin-top: 10px;
  padding: 10px;
  cursor: pointer;
  border-radius: 6px;
  max-width: 164px; }
  @media only screen and (max-width: 74.9375rem) {
    .file-list .filie-l-icon {
      max-width: 100%; } }
  .file-list .filie-l-icon:hover {
    background: var(--rgba-primary-1); }
  .file-list .filie-l-icon img {
    width: 46px;
    height: 45px; }

@media only screen and (max-width: 87.5rem) {
  .file-list {
    height: 300px; } }

@media only screen and (max-width: 35.9375rem) {
  .chat-left-area {
    width: 22rem;
    position: absolute;
    z-index: 2;
    transition: all 0.2s;
    left: -22rem; }
    .chat-left-area.active {
      left: 0;
      background: white; } }

.chat-toggle {
  display: none !important; }
  @media only screen and (max-width: 35.9375rem) {
    .chat-toggle {
      display: inline-block !important; } }
  .chat-toggle:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: -50vw;
    width: 100vw;
    height: 100vh; }
  .chat-toggle.active:before {
    z-index: 1; }

.products {
  display: flex;
  align-items: center; }
  .products img {
    margin-right: 10px; }
  .products h6, .products .h6 {
    margin-bottom: 0; }

.avatar-list-stacked .avatar {
  height: 1.875rem;
  width: 1.875rem;
  margin-inline-end: -13px;
  border: 2px solid #fff; }
  .avatar-list-stacked .avatar:hover {
    z-index: 1; }

.profile-news .media {
  border-bottom: 1px solid #cdcdcd; }
  .profile-news .media:last-child {
    border-bottom: 0; }

.profile-accordion .accordion-button::after {
  right: 0.0625rem; }

.chat-border {
  border-right: 1px solid #000; }

.chat-p {
  display: flex;
  justify-content: space-between;
  padding: 1.25rem; }
  .chat-p h6, .chat-p .h6 {
    font-size: 16px;
    cursor: pointer; }
    .chat-p h6 a, .chat-p .h6 a {
      color: black; }
    @media only screen and (max-width: 35.9375rem) {
      .chat-p h6, .chat-p .h6 {
        font-size: 13px; } }
  .chat-p span {
    font-size: 12px;
    white-space: nowrap; }
  .chat-p.shaprate {
    border-bottom: 1px solid #000; }
  .chat-p.style-1 {
    padding-bottom: 0; }
    .chat-p.style-1 .active {
      position: relative; }
      .chat-p.style-1 .active:after {
        content: "";
        position: absolute;
        height: 10px;
        width: 10px;
        background: #53D0B2;
        bottom: 6px;
        left: 30px;
        border-radius: 50%;
        border: 2px solid #fff; }
        @media only screen and (max-width: 35.9375rem) {
          .chat-p.style-1 .active:after {
            left: 35px; } }
  .chat-p .icon-box {
    height: 40px;
    width: 40px;
    line-height: 38px; }
    @media only screen and (max-width: 35.9375rem) {
      .chat-p .icon-box {
        height: 2rem;
        width: 2rem;
        line-height: 1.9rem; }
        .chat-p .icon-box svg {
          width: 15px;
          height: 15px; } }
  @media only screen and (max-width: 35.9375rem) {
    .chat-p img {
      width: 40px;
      height: 40px; } }

.c-list {
  padding: 1.25rem; }
  .c-list .search-area {
    background-color: transparent !important; }
    .c-list .search-area .form-control {
      background: #F4F4F5;
      border-color: #F4F4F5; }
    .c-list .search-area .input-group-text {
      background: #F4F4F5;
      border-color: #F4F4F5; }

.file-media {
  height: calc(100vh - 195px); }

.people-list {
  height: calc(100vh - 380px);
  margin-bottom: 25px; }

.chat-height {
  min-height: 820px !important; }

.chat-admin {
  height: 0; }
  .chat-admin .sharp {
    line-height: 1.5; }
  .chat-admin .icon-box {
    height: 40px;
    width: 40px;
    line-height: 37px; }
    .chat-admin .icon-box svg {
      width: 15px; }
  @media only screen and (max-width: 35.9375rem) {
    .chat-admin .icon-box {
      height: 2rem;
      width: 2rem;
      border-radius: 5px;
      line-height: 1.9rem; }
      .chat-admin .icon-box svg {
        width: 14px;
        line-height: 1.8rem; }
    .chat-admin .sharp {
      height: 2rem;
      min-width: 2rem;
      min-height: 2rem;
      padding: 0.2375rem;
      border-radius: 5px; } }

.chat-box-area.style-2 {
  padding: 1.25rem;
  height: calc(100vh - 380px);
  margin-top: 20px; }
  @media only screen and (max-width: 35.9375rem) {
    .chat-box-area.style-2 {
      height: calc(100vh - 230px); } }

.type-massage .form-control {
  border: 0;
  resize: none;
  overflow: hidden;
  background: #F3F0EC;
  line-height: 1.6;
  padding: 8px;
  width: 16rem; }
  .type-massage .form-control:hover, .type-massage .form-control:focus {
    background: #F3F0EC; }
  @media only screen and (max-width: 35.9375rem) {
    .type-massage .form-control {
      width: 12rem; } }

.message-send {
  background: #F3F0EC;
  padding: 7px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.625rem; }
  .message-send.style-2 {
    margin: 1.25rem; }

.chat-meadia {
  padding: 1.25rem; }
  @media only screen and (max-width: 74.9375rem) {
    .chat-meadia {
      width: 50%;
      float: left;
      border-top: 1px solid #000; } }
  @media only screen and (max-width: 35.9375rem) {
    .chat-meadia {
      width: 100%; } }

.image-list {
  display: flex;
  flex-wrap: wrap;
  margin: -5px -5px; }
  .image-list li {
    width: 16.6%;
    padding: 5px; }
    .image-list li.all-media {
      position: relative; }
      .image-list li.all-media span {
        position: absolute;
        left: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.8);
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(100% - 10px);
        height: calc(100% - 10px);
        border-radius: 5px;
        margin: 5px;
        font-size: 14px;
        font-weight: 600; }
  .image-list img {
    height: 60px;
    object-fit: cover;
    border-radius: 6px;
    margin-left: 0;
    margin-bottom: 0;
    width: 100%; }
    @media only screen and (max-width: 35.9375rem) {
      .image-list img {
        padding-left: 0; } }

.file-list h5, .file-list .h5 {
  margin-top: 10px;
  font-size: 13px; }

.file-list .filie-l-icon {
  margin-top: 10px;
  padding: 10px;
  cursor: pointer;
  border-radius: 6px;
  max-width: 164px; }
  @media only screen and (max-width: 74.9375rem) {
    .file-list .filie-l-icon {
      max-width: 100%; } }
  .file-list .filie-l-icon:hover {
    background: var(--rgba-primary-1); }
  .file-list .filie-l-icon img {
    width: 46px;
    height: 45px; }

@media only screen and (max-width: 87.5rem) {
  .file-list {
    height: 300px; } }

@media only screen and (max-width: 35.9375rem) {
  .chat-left-area {
    width: 22rem;
    position: absolute;
    z-index: 2;
    transition: all 0.2s;
    left: -22rem; }
    .chat-left-area.active {
      left: 0;
      background: white; } }

.chat-toggle {
  display: none !important; }
  @media only screen and (max-width: 35.9375rem) {
    .chat-toggle {
      display: inline-block !important; } }
  .chat-toggle:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: -50vw;
    width: 100vw;
    height: 100vh;
    z-index: -1; }
  .chat-toggle.active:before {
    z-index: 1; }
  .chat-toggle i {
    transform: scale(1.2) !important; }

.chat-box-area .message-sent p {
  background: #f3f0ec;
  padding: 10px 15px;
  border-radius: 10px 10px 0px 10px;
  color: #000;
  text-align: right;
  font-size: 13px; }

.chat-box-area .message-received p {
  background: #E8F2FF;
  padding: 10px 15px;
  border-radius: 0px 10px 10px 10px;
  color: black;
  font-size: 13px; }

@media only screen and (max-width: 35.9375rem) {
  .message-received img {
    width: 40px;
    height: 40px; } }

.ct-golden-section:before {
  float: none; }

.ct-chart {
  max-height: 15.7rem; }

.ct-chart .ct-label {
  fill: #a3afb7;
  color: #a3afb7;
  font-size: 0.75rem;
  line-height: 1; }

.ct-grid {
  stroke: rgba(49, 58, 70, 0.1); }

.ct-chart.simple-pie-chart-chartist .ct-label {
  color: #ffffff;
  fill: #ffffff;
  font-size: 0.625rem; }

.ct-chart .ct-series.ct-series-a .ct-bar,
.ct-chart .ct-series.ct-series-a .ct-line,
.ct-chart .ct-series.ct-series-a .ct-point,
.ct-chart .ct-series.ct-series-a .ct-slice-donut {
  stroke: var(--primary); }

.ct-chart .ct-series.ct-series-b .ct-bar,
.ct-chart .ct-series.ct-series-b .ct-line,
.ct-chart .ct-series.ct-series-b .ct-point,
.ct-chart .ct-series.ct-series-b .ct-slice-donut {
  stroke: #53D0B2; }

.ct-chart .ct-series.ct-series-c .ct-bar,
.ct-chart .ct-series.ct-series-c .ct-line,
.ct-chart .ct-series.ct-series-c .ct-point,
.ct-chart .ct-series.ct-series-c .ct-slice-donut {
  stroke: #F4CF0C; }

.ct-chart .ct-series.ct-series-d .ct-bar,
.ct-chart .ct-series.ct-series-d .ct-line,
.ct-chart .ct-series.ct-series-d .ct-point,
.ct-chart .ct-series.ct-series-d .ct-slice-donut {
  stroke: #FF7C7C; }

.ct-chart .ct-series.ct-series-e .ct-bar,
.ct-chart .ct-series.ct-series-e .ct-line,
.ct-chart .ct-series.ct-series-e .ct-point,
.ct-chart .ct-series.ct-series-e .ct-slice-donut {
  stroke: #81A5F9; }

.ct-chart .ct-series.ct-series-f .ct-bar,
.ct-chart .ct-series.ct-series-f .ct-line,
.ct-chart .ct-series.ct-series-f .ct-point,
.ct-chart .ct-series.ct-series-f .ct-slice-donut {
  stroke: #333333; }

.ct-chart .ct-series.ct-series-g .ct-bar,
.ct-chart .ct-series.ct-series-g .ct-line,
.ct-chart .ct-series.ct-series-g .ct-point,
.ct-chart .ct-series.ct-series-g .ct-slice-donut {
  stroke: #8d6e63; }

.ct-series-a .ct-area,
.ct-series-a .ct-slice-pie {
  fill: #111828; }

.ct-series-b .ct-area,
.ct-series-b .ct-slice-pie {
  fill: #00A2FF; }

.ct-series-c .ct-area,
.ct-series-c .ct-slice-pie {
  fill: #ff9800; }

.ct-series-d .ct-area,
.ct-series-d .ct-slice-pie {
  fill: #ff9800; }

.chartist-tooltip {
  position: absolute;
  display: inline-block;
  opacity: 0;
  min-width: 0.625rem;
  padding: 0.125rem 0.625rem;
  border-radius: 0.125rem;
  background: #313a46;
  color: #ffffff;
  text-align: center;
  pointer-events: none;
  z-index: 1;
  -webkit-transition: opacity .2s linear;
  -moz-transition: opacity .2s linear;
  -o-transition: opacity .2s linear;
  transition: opacity .2s linear; }

.chartist-tooltip.tooltip-show {
  opacity: 1; }

#donught_graph .ct-series.ct-series-a .ct-slice-donut {
  stroke: #3FC6D4; }

#donught_graph .ct-series.ct-series-b .ct-slice-donut {
  stroke: #333333; }

#donught_graph .ct-series.ct-series-c .ct-slice-donut {
  stroke: #F63465; }

@media only screen and (max-width: 47.9375rem) {
  #pie-chart .ct-label {
    color: #fff;
    fill: #fff; } }

#visitor_graph {
  height: 15.8125rem !important; }

#user_rating_graph {
  height: 17.5rem !important; }

#activity {
  height: 16.875rem !important; }

#visitorOnline {
  height: 4.5rem !important; }

#trendMeter {
  height: 4.5rem !important; }

#widget-revenue1,
#widget-revenue2,
#widget-revenue3 {
  height: 7.3125rem !important; }

#widget-profit1,
#widget-profit2,
#widget-profit3 {
  height: 10rem !important; }

#comparison-rate {
  height: 14.375rem; }

#session_day {
  height: 10.9375rem;
  width: auto !important;
  margin: 0 auto; }

#walet-status {
  height: 8.75rem; }

#bar1 {
  height: 9.375rem; }

#sold-product {
  height: 14.375rem; }

#chart-venue-expenses,
#chart-online-sale,
#chart-gross-sale {
  height: 9.375rem !important; }

#areaChart_3 {
  height: 18.4375rem !important; }

.chart-point {
  display: flex;
  align-items: center; }
  .chart-point .check-point-area {
    width: 6.25rem;
    height: 6.25rem;
    margin-top: -0.625rem;
    margin-left: -0.625rem; }
  .chart-point .chart-point-list {
    margin: 0;
    padding-left: 0.3125rem; }
    .chart-point .chart-point-list li {
      list-style: none;
      font-size: 0.8125rem;
      padding: 0.125rem 0; }
      .chart-point .chart-point-list li i {
        margin-right: 0.3125rem;
        font-size: 0.6875rem;
        position: relative;
        top: -0.0625rem; }

.c3 {
  height: 15.625rem; }

.c3-legend-item {
  fill: #9fabb1; }

.c3 .c3-axis-x path,
.c3 .c3-axis-x line,
.c3 .c3-axis-y path,
.c3 .c3-axis-y line,
.tick text {
  stroke: #fff; }

.flot-chart {
  height: 15.7rem; }

.tooltipflot {
  background-color: transparent;
  font-size: 1.4rem;
  padding: .5rem 1rem;
  color: rgba(255, 255, 255, 0.7);
  border-radius: .2rem; }

.legendColorBox > div {
  border: 0 !important;
  padding: 0 !important; }

.legendLabel {
  font-size: 0.825rem;
  padding-left: .5rem;
  color: #000; }
  [data-theme-version="dark"] .legendLabel {
    color: #fff; }

.flotTip {
  background: #000;
  border: 0.0625rem solid #000;
  color: #fff; }

.legend > div {
  background: transparent !important; }

#balance_graph {
  height: 16.25rem; }

.morris-hover {
  position: absolute;
  z-index: 1;
  background: var(--primary);
  color: #fff; }

.morris-hover .morris-hover-point {
  color: #fff !important;
  margin: 0.1875rem 0;
  text-align: center;
  padding: 0 1.5625rem;
  color: #fff; }

.morris-hover .morris-hover-row-label {
  background-color: #333333;
  text-align: center;
  padding: 0.3125rem;
  color: #fff;
  margin-bottom: 0.3125rem; }

.morris-hover.morris-default-style {
  border-radius: 0.3125rem;
  padding: 0;
  margin: 0;
  border: none;
  overflow: hidden; }

/* svg text {
    font-weight: 600 !important;
} */
#morris_donught,
#morris_donught_2,
#line_chart_2,
#morris_bar,
#morris_bar_stalked,
#morris_bar_2,
#morris_area_2,
#morris_area {
  height: 15rem !important; }

#morris_line {
  height: 17.375rem !important; }

#crypto-btc-card,
#crypto-eth-card,
#crypto-rpl-card,
#crypto-ltc-card {
  height: 9.375rem; }

#daily-sales, #comparison-rate, #usage-chart, #walet-status {
  width: 100%;
  display: block; }
  #daily-sales canvas, #comparison-rate canvas, #usage-chart canvas, #walet-status canvas {
    max-width: 100% !important;
    width: 100% !important; }

#sparkline-composite-chart canvas,
#composite-bar canvas,
#sparkline11 canvas,
#StackedBarChart canvas,
#spark-bar canvas,
#tristate canvas {
  height: 6.25rem !important; }

#sparkline11 canvas {
  width: 6.25rem !important; }

.easy-pie-chart {
  position: relative;
  text-align: center; }
  .easy-pie-chart .inner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: inline-block; }
  .easy-pie-chart img {
    width: 4.6875rem;
    height: 4.6875rem;
    border-radius: 50%; }
  .easy-pie-chart canvas {
    display: block;
    margin: 0 auto; }

#revenue-chart {
  height: 27.7rem; }

#duration-value-axis {
  height: 27.6rem;
  width: 100%; }

#combined-bullet {
  height: 28.125rem;
  width: 100%; }

#zoomable-chart {
  height: 28.125rem;
  width: 100%; }

#chartMap {
  height: 28.125rem;
  width: 100%; }

#professional-candlesticks {
  width: 100%;
  height: 28.125rem; }

#comparing-stock-indices {
  width: 100%;
  height: 28.125rem; }

#multiple-panel-data {
  width: 100%;
  height: 28.125rem; }

#depth-chart {
  width: 100%;
  height: 28.125rem; }

.amcharts-export-menu {
  display: none; }

.amcharts-data-set-selector-div {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  width: 16.875rem;
  margin: 0 auto; }
  .amcharts-data-set-selector-div select {
    border: 0;
    margin-left: 0.625rem;
    background: #ddd;
    color: #000; }

.amChartsInputField {
  border: 0;
  background: var(--primary);
  color: #fff;
  padding: 0.3125rem 0.9375rem;
  margin: 0 0.9375rem; }

.amcharts-data-set-select {
  border: 0;
  background: #ddd;
  color: #000; }

.amcharts-period-input,
.amcharts-period-input-selected {
  border: 0;
  margin-left: 0.625rem;
  background: var(--primary);
  color: #fff;
  padding: 0.3125rem 0.9375rem; }

.amcharts-graph-g2 .amcharts-graph-stroke {
  stroke-dasharray: 0.1875rem 0.1875rem;
  stroke-linejoin: round;
  stroke-linecap: round;
  -webkit-animation: am-moving-dashes 1s linear infinite;
  animation: am-moving-dashes 1s linear infinite; }

@-webkit-keyframes am-moving-dashes {
  100% {
    stroke-dashoffset: -1.9375rem; } }

@keyframes am-moving-dashes {
  100% {
    stroke-dashoffset: -1.9375rem; } }

.lastBullet {
  -webkit-animation: am-pulsating 1s ease-out infinite;
  animation: am-pulsating 1s ease-out infinite; }

@-webkit-keyframes am-pulsating {
  0% {
    stroke-opacity: 1;
    stroke-width: 0px; }
  100% {
    stroke-opacity: 0;
    stroke-width: 3.125rem; } }

@keyframes am-pulsating {
  0% {
    stroke-opacity: 1;
    stroke-width: 0px; }
  100% {
    stroke-opacity: 0;
    stroke-width: 3.125rem; } }

.amcharts-graph-column-front {
  -webkit-transition: all .3s .3s ease-out;
  transition: all .3s .3s ease-out; }

.amcharts-graph-column-front:hover {
  fill: #496375;
  stroke: #496375;
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out; }

@-webkit-keyframes am-draw {
  0% {
    stroke-dashoffset: 500%; }
  100% {
    stroke-dashoffset: 0%; } }

@keyframes am-draw {
  0% {
    stroke-dashoffset: 500%; }
  100% {
    stroke-dashoffset: 0%; } }

@media only screen and (max-width: 61.9375rem) {
  .amChartsPeriodSelector > fieldset > div {
    float: none !important;
    display: block !important;
    margin-bottom: 0.625rem; } }

.highcharts-root text {
  font-weight: 300 !important; }

.highcharts-credits {
  display: none; }

#chart_employee_gender, #chart_employee_status {
  width: auto;
  height: 21.875rem; }

.form-control {
  background: #fff;
  color: #333333;
  line-height: 1.5;
  font-size: 13px;
  height: 2.813rem;
  border-radius: 0.625rem;
  border-color: #cdcdcd; }
  .form-control:hover, .form-control:focus, .form-control.active {
    box-shadow: none;
    background: #fff;
    color: #333333; }
  .form-control:focus {
    border-color: var(--primary); }
  .form-control.solid {
    background: #F4F4F5;
    border-color: #cdcdcd;
    color: #000; }
    .form-control.solid[type="file"] {
      padding: 6px 10px; }
  .form-control[type="file"] {
    padding: 0.175rem 0.75rem;
    line-height: 2.7; }

.input-rounded {
  border-radius: 6.25rem; }

[data-theme-version="light"] .input-primary .form-control,
[data-theme-version="dark"] .input-primary .form-control {
  border-color: var(--primary); }

[data-theme-version="light"] .input-primary .input-group-text,
[data-theme-version="dark"] .input-primary .input-group-text {
  background-color: var(--primary);
  color: #fff; }

[data-theme-version="light"] .input-danger .form-control,
[data-theme-version="dark"] .input-danger .form-control {
  border-color: #FF7C7C; }

[data-theme-version="light"] .input-danger .input-group-text,
[data-theme-version="dark"] .input-danger .input-group-text {
  background-color: #FF7C7C;
  color: #fff; }

[data-theme-version="light"] .input-info .form-control,
[data-theme-version="dark"] .input-info .form-control {
  border-color: #81A5F9; }

[data-theme-version="light"] .input-info .input-group-text,
[data-theme-version="dark"] .input-info .input-group-text {
  background-color: #81A5F9;
  color: #fff; }

[data-theme-version="light"] .input-success .form-control,
[data-theme-version="dark"] .input-success .form-control {
  border-color: #53D0B2; }

[data-theme-version="light"] .input-success .input-group-text,
[data-theme-version="dark"] .input-success .input-group-text {
  background-color: #53D0B2;
  color: #fff; }

[data-theme-version="light"] .input-warning .form-control,
[data-theme-version="dark"] .input-warning .form-control {
  border-color: #F4CF0C; }

[data-theme-version="light"] .input-warning .input-group-text,
[data-theme-version="dark"] .input-warning .input-group-text {
  background-color: #F4CF0C;
  color: #fff; }

[data-theme-version="light"] .input-primary-o .form-control,
[data-theme-version="dark"] .input-primary-o .form-control {
  border-color: var(--primary); }

[data-theme-version="light"] .input-primary-o .input-group-text,
[data-theme-version="dark"] .input-primary-o .input-group-text {
  background-color: transparent;
  border-color: var(--primary);
  color: var(--primary); }

[data-theme-version="light"] .input-danger-o .form-control,
[data-theme-version="dark"] .input-danger-o .form-control {
  border-color: #FF7C7C; }

[data-theme-version="light"] .input-danger-o .input-group-text,
[data-theme-version="dark"] .input-danger-o .input-group-text {
  background-color: transparent;
  border-color: #FF7C7C;
  color: #FF7C7C; }

[data-theme-version="light"] .input-info-o .form-control,
[data-theme-version="dark"] .input-info-o .form-control {
  border-color: #81A5F9; }

[data-theme-version="light"] .input-info-o .input-group-text,
[data-theme-version="dark"] .input-info-o .input-group-text {
  background-color: transparent;
  border-color: #81A5F9;
  color: #81A5F9; }

[data-theme-version="light"] .input-success-o .form-control,
[data-theme-version="dark"] .input-success-o .form-control {
  border-color: #53D0B2; }

[data-theme-version="light"] .input-success-o .input-group-text,
[data-theme-version="dark"] .input-success-o .input-group-text {
  background-color: transparent;
  border-color: #53D0B2;
  color: #53D0B2; }

[data-theme-version="light"] .input-warning-o .form-control,
[data-theme-version="dark"] .input-warning-o .form-control {
  border-color: #F4CF0C; }

[data-theme-version="light"] .input-warning-o .input-group-text,
[data-theme-version="dark"] .input-warning-o .input-group-text {
  background-color: transparent;
  border-color: #F4CF0C;
  color: #F4CF0C; }

.input-group-text {
  min-width: 3.125rem;
  justify-content: center;
  border-radius: 0.625rem;
  border-color: #E6E6E6; }

.form-file-label {
  height: 2.5rem;
  padding: 0.5rem 0.75rem; }

.input-group-prepend .btn,
.input-group-append .btn {
  z-index: 0; }

.custom-select {
  background: none;
  border-color: #E6E6E6;
  color: #333333; }
  .custom-select:focus {
    box-shadow: none;
    border-color: var(--primary);
    color: var(--primary); }

.form-file-label {
  background: #656C73;
  white-space: nowrap;
  color: #fff; }
  [data-theme-version="dark"] .form-file-label {
    background: #444444;
    border-color: #444444;
    color: #888888; }

.custom_file_input .form-file-label::after {
  height: 100%; }

.form-control:disabled,
.form-control[readonly] {
  background: #fff;
  opacity: 1; }

.form-file {
  border: 0.0625rem solid #E6E6E6;
  background: #fff; }
  [data-theme-version="dark"] .form-file {
    background: #1E1E1E;
    border-color: #444444; }

.input-group {
  /* &> .form-file {
		display: flex;
		align-items: center;

		&:not(:last-child) .form-file-label,
		&:not(:last-child) .form-file-label::after { @include border-right-radius(0); }
		&:not(:first-child) .form-file-label { @include border-left-radius(0); }
	  } */ }
  .input-group > .form-control-plaintext,
  .input-group > .form-select,
  .input-group > .form-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  border-radius: 0.625rem; }

.form-file .form-control {
  margin: 0;
  border-radius: 0;
  border: 0;
  height: auto; }

#validationCustomUsername {
  border-radius: 0 0.5rem 0.5rem 0; }

.show-pass {
  border-radius: 0 0.5rem 0.5rem 0 !important; }

.form-file .form-control {
  margin: 0;
  border-radius: 0;
  border: 0;
  height: auto; }

.input-group .input-group-text + .form-file {
  border-radius: 0 0.5rem 0.5rem 0; }
  .input-group .input-group-text + .form-file .form-control {
    border-radius: inherit; }

.custom_file_input .form-file {
  border-radius: 0.5rem; }
  .custom_file_input .form-file .form-control {
    margin: 0;
    border: 0;
    line-height: 2.625rem;
    border-radius: inherit;
    font-size: 16px;
    font-weight: 600; }

.custom_file_input .form-control {
  padding-left: 10px;
  line-height: 45px; }

#username {
  border-radius: 0 0.5rem 0.5rem 0; }

.form-control-lg {
  min-height: 3.75rem;
  padding: 0.5rem 0.75rem;
  font-size: 1.09375rem;
  border-radius: 0.5rem;
  height: calc(1.5em + 1rem + 2px); }

.form-control-sm {
  min-height: 2.5rem;
  padding: 0.25rem 0.5rem;
  font-size: 0.76563rem;
  border-radius: 0.5rem;
  height: calc(1.5em + 0.5rem + 2px); }
  .form-control-sm[type="file"] {
    line-height: 2.5;
    padding: 0.257rem 0.5rem; }

.ck.ck-editor__main > .ck-editor__editable {
  background: #bfbfbf !important; }

.modal-grid {
  background: #F4F4F5; }

.nice-select.wide.form-control-lg {
  line-height: 2.7125rem; }

.nice-select.wide.form-control-sm {
  line-height: 1.8125rem; }

.inline-check {
  line-height: 1.8rem !important; }

.form-check {
  font-size: 14px;
  font-weight: 600; }

.input-full {
  border: 0; }

.border-1 {
  border-radius: 0.5rem 0 0 0.5rem !important; }

.border3 {
  border-radius: 0 0.5rem 0.5rem 0 !important; }

.custome-input .input-group-text {
  border-color: transparent !important; }

.p2p-select .default-select {
  height: 3rem; }

.input-group-lg .input-group-text {
  border-radius: 0.625rem; }

.input-group-sm .input-group-text {
  border-radius: 0.625rem; }

textarea.form-control {
  min-height: auto;
  height: auto !important;
  padding: 15px; }

.form-control[type="file"] {
  line-height: 2.375rem;
  padding-left: 0.75rem; }

.form-control-sm[type="file"] {
  line-height: 2rem;
  padding: 0.25rem;
  padding-left: 8px; }

.form-control-lg[type="file"] {
  line-height: 3.25rem;
  padding-left: 0.625rem; }

.right-radius {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important; }

.left-radius {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important; }

/* //action button color
$add-btn:blue
$edit-btn:
$delete-btn: */
:root {
  --primary: #A0CF1A;
  --secondary: #111828;
  --secondary-dark: #020304;
  --primary-hover: #7da214;
  --primary-dark: #374709;
  --rgba-primary-1: rgba(160, 207, 26, 0.1);
  --rgba-primary-2: rgba(160, 207, 26, 0.2);
  --rgba-primary-3: rgba(160, 207, 26, 0.3);
  --rgba-primary-4: rgba(160, 207, 26, 0.4);
  --rgba-primary-5: rgba(160, 207, 26, 0.5);
  --rgba-primary-6: rgba(160, 207, 26, 0.6);
  --rgba-primary-7: rgba(160, 207, 26, 0.7);
  --rgba-primary-8: rgba(160, 207, 26, 0.8);
  --rgba-primary-9: rgba(160, 207, 26, 0.9);
  --rgba-secondary-1: rgba(17, 24, 40, 0.1);
  --rgba-secondary-2: rgba(17, 24, 40, 0.2);
  --rgba-secondary-3: rgba(17, 24, 40, 0.3);
  --rgba-secondary-4: rgba(17, 24, 40, 0.4);
  --rgba-secondary-5: rgba(17, 24, 40, 0.5);
  --rgba-secondary-6: rgba(17, 24, 40, 0.6);
  --rgba-secondary-7: rgba(17, 24, 40, 0.7);
  --rgba-secondary-8: rgba(17, 24, 40, 0.8);
  --rgba-secondary-9: rgba(17, 24, 40, 0.9);
  --font-family-base: DM Sans, sans-serif, sans-serif;
  --font-family-title: DM Sans, sans-serif, sans-serif;
  --title: #111828;
  --text-dark: #111828; }

/* $primary-light: lighten($primary, 43%);
$secondary-light: lighten($secondary, 10%);
$success-light: lighten($success, 15%);
$warning-light: lighten($warning, 20%);
$danger-light: lighten($danger, 10%);
$info-light: lighten($info, 20%);
$dark-light: lighten($dark, 50%); */
/* input[type="checkbox55"] {
    &:after{
        content: '';
        display: block;
        width: 1rem;
        height: 1rem;
        margin-top: 0px;
        margin-left: -1px;
        border: 1px solid transparent;
        border-radius: 3px;
        background: #d4d7da;
        line-height: 1.3;
    }

    &:checked:after{
        width: 1rem;
        height: 1rem;
        display: block;
        content: "ts";
        font-family: 'FontAwesome';
        color: red;
        font-weight: 50;
        font-size: 12px;
        text-align: center;
        border-radius: 3px;
        background: black;
    }

} */
.form-check-label {
  margin-left: 0.3125rem;
  margin-top: 0.1875rem; }

.form-check-inline .form-check-input {
  margin-right: 0.625rem; }

.form-check-input {
  top: 0.125rem;
  border-width: 1px;
  width: 1.25rem;
  height: 1.25rem;
  border-color: #e7e7e7; }

.rtl .form-check-label:before, .rtl .form-check-label:after {
  right: -1.5rem !important;
  left: inherit; }

.form-check {
  line-height: normal; }

.toggle-switch {
  padding-left: 3.125rem;
  line-height: 1.25;
  display: inline-block;
  color: #000;
  font-weight: 600; }
  .toggle-switch .form-check-input {
    border: 0;
    cursor: pointer;
    background: #d8d8d8;
    width: 2.3125rem;
    border-radius: 1.25rem !important;
    height: 0.875rem;
    position: relative;
    left: -0.3125rem;
    -webkit-transition: all 0.2s;
    -ms-transition: all 0.2s;
    transition: all 0.2s;
    background-image: none; }
    .toggle-switch .form-check-input:focus {
      background-image: none !important; }
    .toggle-switch .form-check-input:checked {
      background: var(--rgba-primary-2);
      background-image: none !important; }
      .toggle-switch .form-check-input:checked:after {
        left: 1.25rem;
        background: var(--primary); }
    .toggle-switch .form-check-input:focus {
      box-shadow: none; }
    .toggle-switch .form-check-input:after {
      width: 1.25rem;
      background: #909090;
      height: 1.25rem;
      content: "";
      position: absolute;
      border-radius: 1.5rem;
      top: -0.1875rem;
      left: 0;
      box-shadow: 0 0 0.3125rem rgba(0, 0, 0, 0.3);
      -webkit-transition: all 0.2s;
      -ms-transition: all 0.2s;
      transition: all 0.2s; }
  .toggle-switch.text-end {
    padding-right: 0rem;
    padding-left: 0; }
    .toggle-switch.text-end .form-check-input {
      left: auto;
      margin-left: 0;
      float: right;
      right: 0rem; }
    .toggle-switch.text-end .form-check-label {
      margin-right: 0.9375rem;
      margin-left: 0; }
  .toggle-switch .form-check-label {
    cursor: pointer; }

.form-check-input:focus ~ .form-check-label::before {
  box-shadow: none !important; }

.form-check-label::before {
  background-color: transparent;
  border-color: #EEEEEE;
  border-width: 0.125rem;
  border-radius: 0.125rem !important; }
  [data-theme-version="dark"] .form-check-label::before {
    background-color: transparent;
    border-color: #444444; }

.check-xs .form-check-input {
  width: 1.125rem;
  height: 1.125rem; }

.check-lg .form-check-input {
  width: 1.5rem;
  height: 1.5rem; }

.check-xl .form-check-input {
  width: 1.75rem;
  height: 1.75rem; }

.checkbox-info .form-check-input:focus {
  border-color: #81A5F9;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(129, 165, 249, 0.25); }

.checkbox-info .form-check-input:checked {
  background-color: #81A5F9;
  border-color: #81A5F9; }

.checkbox-danger .form-check-input:focus {
  border-color: #FF7C7C;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 124, 124, 0.25); }

.checkbox-danger .form-check-input:checked {
  background-color: #FF7C7C;
  border-color: #FF7C7C; }

.checkbox-success .form-check-input:focus {
  border-color: #53D0B2;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(83, 208, 178, 0.25); }

.checkbox-success .form-check-input:checked {
  background-color: #53D0B2 !important;
  border-color: #53D0B2; }

.checkbox-warning .form-check-input:focus {
  border-color: #F4CF0C;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(244, 207, 12, 0.25); }

.checkbox-warning .form-check-input:checked {
  background-color: #F4CF0C;
  border-color: #F4CF0C; }

.checkbox-secondary .form-check-input:focus {
  border-color: #111828;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(17, 24, 40, 0.25); }

.checkbox-secondary .form-check-input:checked {
  background-color: #111828;
  border-color: #111828; }

.check-switch {
  padding-left: 2.5rem; }
  .check-switch .form-check-label {
    line-height: 1.875rem;
    font-weight: 500; }
    .check-switch .form-check-label span {
      line-height: 1; }
    .check-switch .form-check-label:after, .check-switch .form-check-label:before {
      height: 1.5rem;
      width: 1.5rem;
      left: -2rem;
      border-radius: 3rem !important;
      border-color: var(--rgba-primary-3); }
  .check-switch .form-check-input:checked ~ .form-check-label::before {
    background: #fff; }

.form-switch .form-check-input {
  width: 2.5rem; }

.form-check-input {
  background-color: var(--rgba-primary-3); }
  .form-check-input:checked {
    background-color: var(--primary); }
  .form-check-input:focus {
    border-color: var(--primary);
    box-shadow: var(--rgba-primary-1); }

.js-switch + .switchery {
  border-radius: 3.125rem;
  margin-right: 4rem; }
  @media (max-width: 767.98px) {
    .js-switch + .switchery {
      margin-right: 1rem; } }
  .js-switch + .switchery > small, .js-switch + .switchery > .small {
    top: 0.125rem; }

.js-switch.js-switch-lg + .switchery {
  height: 2rem;
  width: 4.5rem; }
  .js-switch.js-switch-lg + .switchery > small, .js-switch.js-switch-lg + .switchery > .small {
    width: 1.75rem;
    height: 1.75rem; }

.js-switch.js-switch-md + .switchery {
  height: 1.5rem;
  width: 3.5rem; }
  .js-switch.js-switch-md + .switchery > small, .js-switch.js-switch-md + .switchery > .small {
    width: 1.25rem;
    height: 1.25rem; }

.js-switch.js-switch-sm + .switchery {
  height: 1rem;
  width: 2.2rem; }
  .js-switch.js-switch-sm + .switchery > small, .js-switch.js-switch-sm + .switchery > .small {
    width: 0.875rem;
    height: 0.875rem;
    top: 0.0625rem; }

.js-switch-square + .switchery {
  border-radius: 0; }
  .js-switch-square + .switchery > small, .js-switch-square + .switchery > .small {
    border-radius: 0;
    top: 0.125rem; }

.js-switch-square.js-switch-lg + .switchery {
  height: 2rem;
  width: 4.5rem; }
  .js-switch-square.js-switch-lg + .switchery > small, .js-switch-square.js-switch-lg + .switchery > .small {
    width: 1.75rem;
    height: 1.75rem; }

.js-switch-square.js-switch-md + .switchery {
  height: 1.5rem;
  width: 3.5rem; }
  .js-switch-square.js-switch-md + .switchery > small, .js-switch-square.js-switch-md + .switchery > .small {
    width: 1.25rem;
    height: 1.25rem; }

.js-switch-square.js-switch-sm + .switchery {
  height: 1rem;
  width: 2.2rem; }
  .js-switch-square.js-switch-sm + .switchery > small, .js-switch-square.js-switch-sm + .switchery > .small {
    width: 0.875rem;
    height: 0.875rem;
    top: 0.0625rem; }

.form-control.is-valid {
  border-color: #53D0B2 !important;
  border-right: 0rem !important; }
  .form-control.is-valid:focus {
    box-shadow: none; }

.form-control.is-warning {
  border-color: #F4CF0C !important;
  border-right: 0rem !important; }
  .form-control.is-warning:focus {
    box-shadow: none; }

.form-control.is-invalid {
  border-color: #FF7C7C !important;
  border-right: 0rem !important; }
  .form-control.is-invalid:focus {
    box-shadow: none; }

.is-valid .input-group-prepend .input-group-text i {
  color: #53D0B2; }

.is-invalid .input-group-prepend .input-group-text i {
  color: var(--rgba-primary-2); }

.show-pass {
  cursor: pointer; }

.bleft {
  border-top-left-radius: 0.625rem !important;
  border-bottom-left-radius: 0.625rem !important; }

.asColorPicker-dropdown {
  max-width: 26rem; }

.asColorPicker-trigger {
  background: none; }

.asColorPicker-trigger {
  border: 0 none;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 2.1875rem; }

[direction="rtl"] .asColorPicker-trigger {
  left: 0;
  right: auto; }

.asColorPicker-trigger span {
  border-radius: 0 0.425rem 0.425rem 0; }

.asColorPicker-clear {
  display: none;
  position: absolute;
  right: 1rem;
  text-decoration: none;
  top: .5rem; }

.daterangepicker td.active {
  background-color: var(--primary); }
  .daterangepicker td.active:hover {
    background-color: var(--primary); }

.daterangepicker button.applyBtn {
  background-color: var(--primary);
  border-color: var(--primary); }

.datepicker.datepicker-dropdown {
  background: #fff;
  border-radius: 0.0625rem;
  border: 0.0625rem solid #EEEEEE; }
  .datepicker.datepicker-dropdown td.day, .datepicker.datepicker-dropdown th.next, .datepicker.datepicker-dropdown th.prev {
    height: 1.875rem;
    width: 1.875rem !important;
    padding: 0;
    text-align: center;
    font-weight: 500;
    border-radius: 0.625rem; }
    .datepicker.datepicker-dropdown td.day:hover, .datepicker.datepicker-dropdown th.next:hover, .datepicker.datepicker-dropdown th.prev:hover {
      box-shadow: unset;
      /*  color: $main-color; */
      background-color: var(--rgba-primary-1); }
  .datepicker.datepicker-dropdown th.datepicker-switch, .datepicker.datepicker-dropdown th.next, .datepicker.datepicker-dropdown th.prev {
    font-weight: 600;
    color: #333; }
  .datepicker.datepicker-dropdown th.dow {
    font-weight: 600; }

.datepicker table tr td.selected, .datepicker table tr td.active.active {
  background-color: var(--primary);
  border-radius: 0.625rem;
  border: 0;
  color: #fff;
  background-image: none;
  position: relative; }

.datepicker table tr td.today {
  border-radius: 0.625rem;
  /* color : $main-color; */ }
  .datepicker table tr td.today:hover {
    background-color: var(--primary);
    box-shadow: 0rem 0rem 1.875rem 0.3125rem rgba(243, 30, 122, 0.3);
    color: #ffffff; }

.datepicker table tr td.today.disabled {
  box-shadow: 0rem 0rem 1.875rem 0.3125rem rgba(243, 30, 122, 0.3);
  color: #ffffff; }
  .datepicker table tr td.today.disabled:hover {
    box-shadow: 0rem 0rem 1.875rem 0.3125rem rgba(243, 30, 122, 0.3);
    color: #ffffff; }

.datepicker table thead tr:first-child th:hover {
  background: var(--rgba-primary-1); }

.datepicker table thead tr th:hover {
  background: var(--rgba-primary-1); }

.datepicker table td.day:hover {
  background: var(--rgba-primary-1);
  border-radius: 0.625rem; }

.picker__select--month, .picker__select--year {
  height: 2.5em; }

.picker__input {
  background-color: transparent !important; }
  [data-theme-version="dark"] .picker__input {
    background-color: transparent !important;
    border: 0.0625rem solid #444444; }

.bootstrap-select.form-control-lg .dropdown-toggle {
  min-height: 3.75rem;
  padding: 0.5rem 1.563rem; }

.bootstrap-select.form-control-sm .dropdown-toggle {
  min-height: 2.5rem;
  padding: 0.25rem 1.563rem; }

.basic-form .form-check-input[type="radio"] + .form-check-label {
  margin-top: 5px; }

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  min-height: 2.5rem;
  padding: 0.25rem 1.1rem;
  border-radius: 0.625rem; }

.input-group.input-group-lg {
  min-height: 3.75rem;
  border-radius: 0.625rem; }

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  border-radius: 0.625rem; }

.clockpicker-popover .popover-title {
  padding: 10px 0px;
  border-radius: 0.625rem; }

.picker-data .color-time-picker .react-time-picker {
  width: 100%; }

.picker-data .color-time-picker .react-time-picker__wrapper {
  border: 0;
  color: #7e7e7e; }
  .picker-data .color-time-picker .react-time-picker__wrapper .react-time-picker__clear-button {
    display: none; }
  .picker-data .color-time-picker .react-time-picker__wrapper .react-time-picker__inputGroup {
    border: 1px solid #b7b7b7;
    border-width: 0 0 1px 0;
    padding: .35rem 0rem; }
  .picker-data .color-time-picker .react-time-picker__wrapper .react-time-picker__inputGroup__input {
    color: #7e7e7e; }
  .picker-data .color-time-picker .react-time-picker__wrapper .react-time-picker__inputGroup__amPm {
    color: #7e7e7e;
    appearance: none; }
  .picker-data .color-time-picker .react-time-picker__wrapper .react-time-picker__clock-button {
    background: #A0CF1A;
    color: #b7b7b7;
    padding: .532rem .75rem;
    border-radius: 0 0.75rem 0.75rem 0;
    min-width: 3.125rem;
    text-align: center;
    line-height: 1; }
    .picker-data .color-time-picker .react-time-picker__wrapper .react-time-picker__clock-button svg {
      stroke: #fff;
      display: inline-block; }

.picker-data .color-time-picker .react-clock__face {
  background: #ededed;
  padding: 0.625rem 0.625rem;
  border: 0.625rem solid #ededed; }

.picker-data .color-time-picker .react-clock__hand__body {
  background-color: #A0CF1A; }

.picker-data .color-time-picker .react-time-picker__clock {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  border: 0 !important; }

.picker-data .color-time-picker.style-1 .react-time-picker__wrapper .react-time-picker__clock-button {
  display: none; }

.color-gradian-tixia .gpw {
  padding: 0; }

.color-gradian-tixia .gpw .trigger .inner {
  height: 1.5rem;
  width: 6.25rem; }

#image {
  max-width: 100%; }

.docs-options .dropdown-menu {
  padding: 1.5rem; }

.docs-preview {
  margin-bottom: 3rem; }
  .docs-preview .img-preview {
    float: left;
    margin-right: .5rem;
    margin-bottom: .5rem;
    overflow: hidden;
    max-width: 100% !important; }
    .docs-preview .img-preview > img {
      max-width: 100% !important; }
    .docs-preview .img-preview.preview-lg {
      width: 16rem;
      height: 9rem; }
    .docs-preview .img-preview.preview-md {
      width: 8rem;
      height: 4.5rem; }
    .docs-preview .img-preview.preview-sm {
      width: 4rem;
      height: 2.25rem; }
    .docs-preview .img-preview.preview-xs {
      width: 2rem;
      height: 1.125rem;
      margin-right: 0; }

.select2-container {
  width: 100% !important; }

.select2-container--default .select2-selection--single {
  border-radius: 0.625rem;
  border: 0.0625rem solid #cdcdcd;
  height: 2.5rem;
  background: #fff; }
  [data-theme-version="dark"] .select2-container--default .select2-selection--single {
    background: #1E1E1E;
    border-color: #444444; }
  .select2-container--default .select2-selection--single:hover, .select2-container--default .select2-selection--single:focus, .select2-container--default .select2-selection--single.active {
    box-shadow: none; }

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 2.313rem;
  color: #888888;
  padding-left: 0.9375rem;
  min-height: 2.813rem; }

.select2-container--default .select2-selection--multiple {
  border-color: #E6E6E6;
  border-radius: 0; }

.select2-dropdown {
  border-radius: 0; }

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: var(--primary); }

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border-color: #c8c8c8;
  background: #fff; }

.select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 0.375rem;
  right: 0.9375rem; }

.select2-container .select2-selection--multiple {
  min-height: 2.5rem;
  color: #888888;
  border-radius: 0.625rem;
  border: 0.0625rem solid #cdcdcd; }
  [data-theme-version="dark"] .select2-container .select2-selection--multiple {
    background: #1E1E1E;
    border-color: #444444; }

[data-theme-version="dark"] .select2-search--dropdown .select2-search__field {
  background: #2c2c2c;
  border-color: #444444; }

.select2-dropdown {
  border-color: #EEEEEE; }
  [data-theme-version="dark"] .select2-dropdown {
    background: #1E1E1E;
    border-color: #444444; }

.swal2-popup .swal2-content {
  color: #888888; }

/* //action button color
$add-btn:blue
$edit-btn:
$delete-btn: */
:root {
  --primary: #A0CF1A;
  --secondary: #111828;
  --secondary-dark: #020304;
  --primary-hover: #7da214;
  --primary-dark: #374709;
  --rgba-primary-1: rgba(160, 207, 26, 0.1);
  --rgba-primary-2: rgba(160, 207, 26, 0.2);
  --rgba-primary-3: rgba(160, 207, 26, 0.3);
  --rgba-primary-4: rgba(160, 207, 26, 0.4);
  --rgba-primary-5: rgba(160, 207, 26, 0.5);
  --rgba-primary-6: rgba(160, 207, 26, 0.6);
  --rgba-primary-7: rgba(160, 207, 26, 0.7);
  --rgba-primary-8: rgba(160, 207, 26, 0.8);
  --rgba-primary-9: rgba(160, 207, 26, 0.9);
  --rgba-secondary-1: rgba(17, 24, 40, 0.1);
  --rgba-secondary-2: rgba(17, 24, 40, 0.2);
  --rgba-secondary-3: rgba(17, 24, 40, 0.3);
  --rgba-secondary-4: rgba(17, 24, 40, 0.4);
  --rgba-secondary-5: rgba(17, 24, 40, 0.5);
  --rgba-secondary-6: rgba(17, 24, 40, 0.6);
  --rgba-secondary-7: rgba(17, 24, 40, 0.7);
  --rgba-secondary-8: rgba(17, 24, 40, 0.8);
  --rgba-secondary-9: rgba(17, 24, 40, 0.9);
  --font-family-base: DM Sans, sans-serif, sans-serif;
  --font-family-title: DM Sans, sans-serif, sans-serif;
  --title: #111828;
  --text-dark: #111828; }

/* $primary-light: lighten($primary, 43%);
$secondary-light: lighten($secondary, 10%);
$success-light: lighten($success, 15%);
$warning-light: lighten($warning, 20%);
$danger-light: lighten($danger, 10%);
$info-light: lighten($info, 20%);
$dark-light: lighten($dark, 50%); */
/*
0 - 600: Phone
600 - 900: Tablet portrait
900 - 1200: Tablet landscape
1200 - 1800: Normal styles
1800+ : Big Desktop
1em = 16px
The smaller device rules always should write below the bigger device rules
Fixing Order => Base + Typography >> General Layout + Grid >> Page Layout + Component
*/
.form-wizard {
  border: 0; }
  .form-wizard .nav-wizard {
    box-shadow: none !important;
    margin-bottom: 2rem; }
    .form-wizard .nav-wizard #RFS-ConnectorContainer {
      display: none; }
    .form-wizard .nav-wizard .nav-link {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      background-color: transparent !important; }
      .form-wizard .nav-wizard .nav-link span {
        border-radius: 3.125rem;
        width: 3rem;
        height: 3rem;
        border: 0.125rem solid var(--primary-light);
        display: block;
        line-height: 3rem;
        color: var(--primary);
        font-size: 1.125rem;
        margin: auto;
        background-color: #fff;
        position: relative;
        z-index: 1; }
      .form-wizard .nav-wizard .nav-link:after {
        content: "" !important;
        position: absolute;
        top: 50%;
        left: 50%;
        height: 0.1875rem;
        transform: translateY(-50%);
        background: #999999 !important;
        z-index: 0;
        width: 100%; }
      .form-wizard .nav-wizard .nav-link.active span {
        background: var(--primary-light);
        color: var(--text-dark); }
      .form-wizard .nav-wizard .nav-link.active:after {
        background: var(--primary) !important; }
      .form-wizard .nav-wizard .nav-link.completed span {
        background-color: var(--primary-light);
        color: var(--text-dark); }
      .form-wizard .nav-wizard .nav-link.completed:after {
        background: var(--primary) !important; }
    .form-wizard .nav-wizard > div:last-child .nav-link:after {
      display: none; }
  .form-wizard .toolbar-bottom .btn {
    border: 0;
    padding: 0.75rem 1.125rem; }
  .form-wizard .tab-content .tab-pane {
    padding: 0; }
  .form-wizard .emial-setup label.mailclinet {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    cursor: pointer;
    background-color: #EEF5F9;
    text-align: center;
    margin: auto; }
    [data-theme-version="dark"] .form-wizard .emial-setup label.mailclinet {
      background-color: #1E1E1E; }
    @media only screen and (max-width: 35.9375rem) {
      .form-wizard .emial-setup label.mailclinet {
        width: 7rem;
        height: 7rem; } }
    .form-wizard .emial-setup label.mailclinet .mail-icon {
      font-size: 3rem;
      display: inline-block;
      line-height: 1;
      margin-top: -1rem; }
      @media only screen and (max-width: 35.9375rem) {
        .form-wizard .emial-setup label.mailclinet .mail-icon {
          font-size: 2rem; } }
    .form-wizard .emial-setup label.mailclinet .mail-text {
      font-size: 1rem;
      text-align: center;
      margin-top: .5rem; }
      @media only screen and (max-width: 35.9375rem) {
        .form-wizard .emial-setup label.mailclinet .mail-text {
          font-size: 0.75rem;
          line-height: 1.25rem; } }
    .form-wizard .emial-setup label.mailclinet input[type="radio"] {
      display: none; }
  @media only screen and (max-width: 47.9375rem) {
    .form-wizard .nav-wizard {
      flex-direction: unset !important; }
    .form-wizard .tab-content {
      height: 100% !important; } }
  @media only screen and (max-width: 35.9375rem) {
    .form-wizard .nav-wizard li .nav-link {
      padding: 0; } }

.wizard-style-one {
  border: 0;
  padding: 0 100px; }
  .wizard-style-one .nav-wizard {
    margin-bottom: 40px;
    box-shadow: none; }
  .wizard-style-one label {
    font-size: 14px;
    font-weight: 500;
    color: #000; }
  .wizard-style-one .tab-content {
    overflow: visible; }

.custom-ekeditor ul {
  padding-left: 1.25rem; }
  .custom-ekeditor ul li {
    list-style: unset; }

.custom-ekeditor ol li {
  list-style: decimal; }

.ql-container {
  height: 25rem; }

#world-datamap {
  padding-bottom: 46% !important; }

.datamaps-hoverover {
  background: #fff;
  padding: 0.3125rem;
  border-radius: 0.3125rem;
  font-family: 'Roboto' !important;
  color: var(--primary);
  border: 0.0625rem solid var(--rgba-primary-3); }

@media only screen and (max-width: 90rem) {
  .world_map_card ul.list-group {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 2.1875rem; } }

.jqvmap-zoomin,
.jqvmap-zoomout {
  height: 1.25rem;
  width: 1.25rem;
  line-height: 0.875rem;
  background-color: var(--primary);
  color: #fff; }

.jqvmap-zoomout {
  top: 2.1875rem; }

#world-map, #usa {
  height: 25rem; }
  @media only screen and (max-width: 61.9375rem) {
    #world-map, #usa {
      height: 21.875rem; } }
  @media only screen and (max-width: 35.9375rem) {
    #world-map, #usa {
      height: 14.375rem; } }

.active-map-main .active-map, .sales-map svg path {
  fill: var(--primary-light);
  stroke: #fff !important;
  stroke-opacity: 1 !important; }

.blockUI.blockMsg.blockPage {
  border: 0 !important; }

#loginForm {
  cursor: auto; }

.blockMsg {
  border: 0rem;
  width: 20% !important; }
  .blockMsg h1, .blockMsg .h1 {
    font-size: 1rem;
    padding: 0.5rem 0;
    margin-bottom: 0; }

.bootstrap-select {
  margin-bottom: 0; }
  .bootstrap-select .btn {
    border: 1px solid #cdcdcd !important;
    background-color: transparent !important;
    font-weight: 400;
    color: #888888 !important; }
    [data-theme-version="dark"] .bootstrap-select .btn {
      border-color: #444444 !important;
      background: #1E1E1E !important; }
    .bootstrap-select .btn:active, .bootstrap-select .btn:focus, .bootstrap-select .btn:hover {
      outline: none !important;
      outline-offset: 0; }
      [data-theme-version="dark"] .bootstrap-select .btn:active, [data-theme-version="dark"] .bootstrap-select .btn:focus, [data-theme-version="dark"] .bootstrap-select .btn:hover {
        color: #888888 !important; }
  .bootstrap-select .dropdown-menu {
    border-color: #E6E6E6 !important;
    box-shadow: 0rem 0rem 2.5rem 0rem rgba(82, 63, 105, 0.1); }
    .bootstrap-select .dropdown-menu .dropdown-item {
      padding: 0.45rem 1rem; }
    [data-theme-version="dark"] .bootstrap-select .dropdown-menu {
      border-color: #E6E6E6 !important; }

.input-group > .bootstrap-select:not(:first-child) .dropdown-toggle {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.input-group > .bootstrap-select:not(:last-child) .dropdown-toggle {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container .select2-search--inline .select2-search__field {
  margin-top: 9px;
  padding: 0px 7px; }

/* //action button color
$add-btn:blue
$edit-btn:
$delete-btn: */
:root {
  --primary: #A0CF1A;
  --secondary: #111828;
  --secondary-dark: #020304;
  --primary-hover: #7da214;
  --primary-dark: #374709;
  --rgba-primary-1: rgba(160, 207, 26, 0.1);
  --rgba-primary-2: rgba(160, 207, 26, 0.2);
  --rgba-primary-3: rgba(160, 207, 26, 0.3);
  --rgba-primary-4: rgba(160, 207, 26, 0.4);
  --rgba-primary-5: rgba(160, 207, 26, 0.5);
  --rgba-primary-6: rgba(160, 207, 26, 0.6);
  --rgba-primary-7: rgba(160, 207, 26, 0.7);
  --rgba-primary-8: rgba(160, 207, 26, 0.8);
  --rgba-primary-9: rgba(160, 207, 26, 0.9);
  --rgba-secondary-1: rgba(17, 24, 40, 0.1);
  --rgba-secondary-2: rgba(17, 24, 40, 0.2);
  --rgba-secondary-3: rgba(17, 24, 40, 0.3);
  --rgba-secondary-4: rgba(17, 24, 40, 0.4);
  --rgba-secondary-5: rgba(17, 24, 40, 0.5);
  --rgba-secondary-6: rgba(17, 24, 40, 0.6);
  --rgba-secondary-7: rgba(17, 24, 40, 0.7);
  --rgba-secondary-8: rgba(17, 24, 40, 0.8);
  --rgba-secondary-9: rgba(17, 24, 40, 0.9);
  --font-family-base: DM Sans, sans-serif, sans-serif;
  --font-family-title: DM Sans, sans-serif, sans-serif;
  --title: #111828;
  --text-dark: #111828; }

/* $primary-light: lighten($primary, 43%);
$secondary-light: lighten($secondary, 10%);
$success-light: lighten($success, 15%);
$warning-light: lighten($warning, 20%);
$danger-light: lighten($danger, 10%);
$info-light: lighten($info, 20%);
$dark-light: lighten($dark, 50%); */
.clipboard-btn {
  transition: all .1s ease-in-out; }
  .clipboard-btn:hover {
    background-color: var(--primary);
    color: #fff; }

/* //action button color
$add-btn:blue
$edit-btn:
$delete-btn: */
:root {
  --primary: #A0CF1A;
  --secondary: #111828;
  --secondary-dark: #020304;
  --primary-hover: #7da214;
  --primary-dark: #374709;
  --rgba-primary-1: rgba(160, 207, 26, 0.1);
  --rgba-primary-2: rgba(160, 207, 26, 0.2);
  --rgba-primary-3: rgba(160, 207, 26, 0.3);
  --rgba-primary-4: rgba(160, 207, 26, 0.4);
  --rgba-primary-5: rgba(160, 207, 26, 0.5);
  --rgba-primary-6: rgba(160, 207, 26, 0.6);
  --rgba-primary-7: rgba(160, 207, 26, 0.7);
  --rgba-primary-8: rgba(160, 207, 26, 0.8);
  --rgba-primary-9: rgba(160, 207, 26, 0.9);
  --rgba-secondary-1: rgba(17, 24, 40, 0.1);
  --rgba-secondary-2: rgba(17, 24, 40, 0.2);
  --rgba-secondary-3: rgba(17, 24, 40, 0.3);
  --rgba-secondary-4: rgba(17, 24, 40, 0.4);
  --rgba-secondary-5: rgba(17, 24, 40, 0.5);
  --rgba-secondary-6: rgba(17, 24, 40, 0.6);
  --rgba-secondary-7: rgba(17, 24, 40, 0.7);
  --rgba-secondary-8: rgba(17, 24, 40, 0.8);
  --rgba-secondary-9: rgba(17, 24, 40, 0.9);
  --font-family-base: DM Sans, sans-serif, sans-serif;
  --font-family-title: DM Sans, sans-serif, sans-serif;
  --title: #111828;
  --text-dark: #111828; }

/* $primary-light: lighten($primary, 43%);
$secondary-light: lighten($secondary, 10%);
$success-light: lighten($success, 15%);
$warning-light: lighten($warning, 20%);
$danger-light: lighten($danger, 10%);
$info-light: lighten($info, 20%);
$dark-light: lighten($dark, 50%); */
.bootstrap-tagsinput {
  flex: 1;
  padding: 0;
  padding-left: .5rem;
  border-radius: 0;
  border-color: #E6E6E6;
  background-color: #fff;
  width: 100%; }
  .bootstrap-tagsinput .tag {
    font-size: 0.875rem;
    background-color: var(--primary);
    border-radius: 0;
    align-items: center;
    border: 0.0625rem solid #E6E6E6; }
  .bootstrap-tagsinput input[type="text"] {
    flex: 1;
    height: 2.1rem;
    border-radius: 0;
    border-color: #E6E6E6; }
  .bootstrap-tagsinput label {
    margin-bottom: .5rem; }

/* //action button color
$add-btn:blue
$edit-btn:
$delete-btn: */
:root {
  --primary: #A0CF1A;
  --secondary: #111828;
  --secondary-dark: #020304;
  --primary-hover: #7da214;
  --primary-dark: #374709;
  --rgba-primary-1: rgba(160, 207, 26, 0.1);
  --rgba-primary-2: rgba(160, 207, 26, 0.2);
  --rgba-primary-3: rgba(160, 207, 26, 0.3);
  --rgba-primary-4: rgba(160, 207, 26, 0.4);
  --rgba-primary-5: rgba(160, 207, 26, 0.5);
  --rgba-primary-6: rgba(160, 207, 26, 0.6);
  --rgba-primary-7: rgba(160, 207, 26, 0.7);
  --rgba-primary-8: rgba(160, 207, 26, 0.8);
  --rgba-primary-9: rgba(160, 207, 26, 0.9);
  --rgba-secondary-1: rgba(17, 24, 40, 0.1);
  --rgba-secondary-2: rgba(17, 24, 40, 0.2);
  --rgba-secondary-3: rgba(17, 24, 40, 0.3);
  --rgba-secondary-4: rgba(17, 24, 40, 0.4);
  --rgba-secondary-5: rgba(17, 24, 40, 0.5);
  --rgba-secondary-6: rgba(17, 24, 40, 0.6);
  --rgba-secondary-7: rgba(17, 24, 40, 0.7);
  --rgba-secondary-8: rgba(17, 24, 40, 0.8);
  --rgba-secondary-9: rgba(17, 24, 40, 0.9);
  --font-family-base: DM Sans, sans-serif, sans-serif;
  --font-family-title: DM Sans, sans-serif, sans-serif;
  --title: #111828;
  --text-dark: #111828; }

/* $primary-light: lighten($primary, 43%);
$secondary-light: lighten($secondary, 10%);
$success-light: lighten($success, 15%);
$warning-light: lighten($warning, 20%);
$danger-light: lighten($danger, 10%);
$info-light: lighten($info, 20%);
$dark-light: lighten($dark, 50%); */
.twitter-typeahead {
  width: 100%; }
  .twitter-typeahead .tt-dataset.tt-dataset-states {
    border: 1px solid #E6E6E6; }
  .twitter-typeahead .tt-menu {
    width: 100%;
    background-color: #fff; }
    .twitter-typeahead .tt-menu .tt-suggestion {
      padding: 0.625rem;
      cursor: pointer; }
      .twitter-typeahead .tt-menu .tt-suggestion:hover {
        background-color: var(--primary);
        color: #fff; }

.weather-one i {
  font-size: 8rem;
  position: relative;
  top: .5rem; }

.weather-one h2, .weather-one .h2 {
  display: inline-block;
  float: right;
  font-size: 4.8rem; }

.weather-one .city {
  position: relative;
  text-align: right;
  top: -2.5rem; }

.weather-one .currently {
  font-size: 1.6rem;
  font-weight: 400;
  position: relative;
  top: 2.5rem; }

.weather-one .celcious {
  text-align: right;
  font-size: 2rem; }

/* //action button color
$add-btn:blue
$edit-btn:
$delete-btn: */
:root {
  --primary: #A0CF1A;
  --secondary: #111828;
  --secondary-dark: #020304;
  --primary-hover: #7da214;
  --primary-dark: #374709;
  --rgba-primary-1: rgba(160, 207, 26, 0.1);
  --rgba-primary-2: rgba(160, 207, 26, 0.2);
  --rgba-primary-3: rgba(160, 207, 26, 0.3);
  --rgba-primary-4: rgba(160, 207, 26, 0.4);
  --rgba-primary-5: rgba(160, 207, 26, 0.5);
  --rgba-primary-6: rgba(160, 207, 26, 0.6);
  --rgba-primary-7: rgba(160, 207, 26, 0.7);
  --rgba-primary-8: rgba(160, 207, 26, 0.8);
  --rgba-primary-9: rgba(160, 207, 26, 0.9);
  --rgba-secondary-1: rgba(17, 24, 40, 0.1);
  --rgba-secondary-2: rgba(17, 24, 40, 0.2);
  --rgba-secondary-3: rgba(17, 24, 40, 0.3);
  --rgba-secondary-4: rgba(17, 24, 40, 0.4);
  --rgba-secondary-5: rgba(17, 24, 40, 0.5);
  --rgba-secondary-6: rgba(17, 24, 40, 0.6);
  --rgba-secondary-7: rgba(17, 24, 40, 0.7);
  --rgba-secondary-8: rgba(17, 24, 40, 0.8);
  --rgba-secondary-9: rgba(17, 24, 40, 0.9);
  --font-family-base: DM Sans, sans-serif, sans-serif;
  --font-family-title: DM Sans, sans-serif, sans-serif;
  --title: #111828;
  --text-dark: #111828; }

/* $primary-light: lighten($primary, 43%);
$secondary-light: lighten($secondary, 10%);
$success-light: lighten($success, 15%);
$warning-light: lighten($warning, 20%);
$danger-light: lighten($danger, 10%);
$info-light: lighten($info, 20%);
$dark-light: lighten($dark, 50%); */
.noUi-target {
  border-color: transparent;
  border-radius: 0; }

.noUi-connect {
  background-color: var(--primary); }
  .noUi-connects {
    background-color: #D2D6DE; }
  .noUi-connect.c-1-color {
    background-color: #53D0B2; }
  .noUi-connect.c-2-color {
    background-color: #81A5F9; }
  .noUi-connect.c-3-color {
    background-color: var(--primary); }
  .noUi-connect.c-4-color {
    background-color: #F4CF0C; }

.noUi-vertical {
  width: 0.375rem; }

.noUi-horizontal {
  height: 0.125rem;
  border: 0;
  margin-bottom: 0.625rem; }

.noUi-horizontal .noUi-handle, .noUi-vertical .noUi-handle {
  height: 0.9375rem;
  width: 0.9375rem;
  border-radius: 3.125rem;
  box-shadow: none;
  border: none;
  background-color: var(--primary); }
  .noUi-horizontal .noUi-handle::after, .noUi-horizontal .noUi-handle::before, .noUi-vertical .noUi-handle::after, .noUi-vertical .noUi-handle::before {
    display: none; }

.noUi-vertical .noUi-handle {
  left: -0.35rem;
  top: -0.375rem; }

.noUi-horizontal .noUi-handle {
  top: -0.4375rem;
  cursor: pointer; }

html:not([dir=rtl]) .noUi-horizontal .noUi-handle {
  right: -0.375rem; }

#slider-toggle {
  height: 3.125rem; }

#slider-toggle.off .noUi-handle {
  border-color: var(--primary); }

.colorpicker-slider .sliders.noUi-target#red, .colorpicker-slider .sliders.noUi-target#green, .colorpicker-slider .sliders.noUi-target#blue {
  margin: 0.625rem;
  display: inline-block;
  height: 12.5rem; }

.colorpicker-slider .sliders.noUi-target#red .noUi-connect {
  background: #c0392b; }

.colorpicker-slider .sliders.noUi-target#green .noUi-connect {
  background: #27ae60; }

.colorpicker-slider .sliders.noUi-target#blue .noUi-connect {
  background: #2980b9; }

.colorpicker-slider #result {
  margin: 3.75rem 1.625rem;
  height: 6.25rem;
  width: 6.25rem;
  display: inline-block;
  vertical-align: top;
  color: #7f7f7f;
  background: #7f7f7f;
  border: 0.0625rem solid #fff;
  box-shadow: 0 0 0.625rem; }

.slider-vertical {
  height: 18rem; }

.nestable-cart {
  overflow: hidden; }

.dd-handle {
  color: var(--text-dark);
  background: var(--primary-light);
  border-radius: 0.3125rem;
  padding: 0.5rem 1rem;
  height: auto;
  border: 1px solid #cdcdcd;
  cursor: all-scroll; }

.dd-handle:hover {
  color: var(--secondary);
  background: #F4F4F5; }

.dd3-content:hover {
  color: var(--secondary);
  background: #EEEEEE; }

.dd3-content {
  color: #fff; }

.dd-item > button {
  line-height: 28px;
  color: #fff; }

.dd-item > button:before {
  color: var(--secondary); }

.pignose-calendar {
  box-shadow: none;
  width: 100%;
  max-width: none;
  border-color: var(--primary); }
  .pignose-calendar .pignose-calendar-top-date {
    background-color: var(--primary); }
    .pignose-calendar .pignose-calendar-top-date .pignose-calendar-top-month {
      color: #fff; }

.pignose-calendar.pignose-calendar-blue .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-active a {
  background-color: var(--primary);
  box-shadow: none; }

.pignose-calendar .pignose-calendar-top {
  box-shadow: none;
  border-bottom: 0; }

.pignose-calendar.pignose-calendar-blue {
  background-color: rgba(0, 0, 0, 0.15); }

.pignose-calendar .pignose-calendar-unit {
  height: 4.8em; }

.cd-h-timeline {
  opacity: 0;
  transition: opacity 0.2s; }

.cd-h-timeline--loaded {
  opacity: 1; }

.cd-h-timeline__container {
  position: relative;
  height: 6.25rem;
  max-width: 50rem; }

.cd-h-timeline__dates {
  position: relative;
  height: 100%;
  margin: 0 2.5rem;
  overflow: hidden; }
  .cd-h-timeline__dates::after, .cd-h-timeline__dates::before {
    content: '';
    position: absolute;
    z-index: 2;
    top: 0;
    height: 100%;
    width: 1.25rem; }
  .cd-h-timeline__dates::before {
    left: 0;
    background: var(--primary); }
  .cd-h-timeline__dates::after {
    right: 0;
    background: var(--primary); }

.cd-h-timeline__line {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 3.0625rem;
  height: 0.125rem;
  background-color: var(--primary);
  transition: transform 0.4s; }

.cd-h-timeline__filling-line {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #53D0B2;
  transform: scaleX(0);
  transform-origin: left center;
  transition: transform 0.3s; }

.cd-h-timeline__date {
  position: absolute;
  bottom: 0;
  z-index: 2;
  text-align: center;
  font-size: 0.8em;
  padding-bottom: var(--space-sm);
  color: var(--cd-color-1);
  user-select: none;
  text-decoration: none; }
  .cd-h-timeline__date::after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -0.3125rem;
    height: 0.75rem;
    width: 0.75rem;
    border-radius: 50%;
    border-width: 0.125rem;
    border-style: solid;
    border-color: var(--rgba-primary-6);
    background-color: var(--primary);
    transition: background-color 0.3s, border-color .3s; }
  .cd-h-timeline__date:hover::after {
    background-color: #53D0B2;
    border-color: #53D0B2; }

.cd-h-timeline__date--selected {
  pointer-events: none; }
  .cd-h-timeline__date--selected::after {
    background-color: #53D0B2;
    border-color: #53D0B2; }

.cd-h-timeline__date--older-event::after {
  border-color: #53D0B2; }

.cd-h-timeline__navigation {
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  height: 2.125rem;
  width: 2.125rem;
  border-radius: 50%;
  border-width: 0.125rem;
  border-style: solid;
  border-color: var(--rgba-primary-6);
  transition: border-color 0.3s; }
  .cd-h-timeline__navigation::after {
    content: '';
    position: absolute;
    height: 1rem;
    width: 1rem;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%); }
  .cd-h-timeline__navigation:hover {
    border-color: #53D0B2; }

.cd-h-timeline__navigation--prev {
  left: 0;
  transform: translateY(-50%) rotate(180deg); }

.cd-h-timeline__navigation--next {
  right: 0; }

.cd-h-timeline__navigation--inactive {
  cursor: not-allowed; }
  .cd-h-timeline__navigation--inactive::after {
    background-position: 0 -1rem; }
  .cd-h-timeline__navigation--inactive:hover {
    border-color: var(--rgba-primary-6); }

.cd-h-timeline__events {
  position: relative;
  width: 100%;
  overflow: hidden;
  transition: height .4s; }

.cd-h-timeline__event {
  position: absolute;
  z-index: 1;
  width: 100%;
  left: 0;
  top: 0;
  transform: translateX(-100%);
  padding: 0.0625rem 5%;
  opacity: 0;
  animation-duration: 0.4s;
  animation-timing-function: ease-in-out; }

.cd-h-timeline__event--selected {
  position: relative;
  z-index: 2;
  opacity: 1;
  transform: translateX(0); }

.cd-h-timeline__event--enter-right,
.cd-h-timeline__event--leave-right {
  animation-name: cd-enter-right; }

.cd-h-timeline__event--enter-left,
.cd-h-timeline__event--leave-left {
  animation-name: cd-enter-left; }

.cd-h-timeline__event--leave-right,
.cd-h-timeline__event--leave-left {
  animation-direction: reverse; }

.cd-h-timeline__event-content {
  max-width: 50rem; }

.cd-h-timeline__event-title {
  color: var(--cd-color-1);
  font-family: var(--font-secondary);
  font-weight: 700;
  font-size: var(--text-xxxl); }

.cd-h-timeline__event-date {
  display: block;
  font-style: italic;
  margin: var(--space-xs) auto; }
  .cd-h-timeline__event-date::before {
    content: '- '; }

@keyframes cd-enter-right {
  0% {
    opacity: 0;
    transform: translateX(100%); }
  100% {
    opacity: 1;
    transform: translateX(0%); } }

@keyframes cd-enter-left {
  0% {
    opacity: 0;
    transform: translateX(-100%); }
  100% {
    opacity: 1;
    transform: translateX(0%); } }

.toast-success {
  background-color: var(--primary); }

.toast-info {
  background-color: #81A5F9; }

.toast-warning {
  background-color: #F4CF0C; }

.toast-error {
  background-color: #FF7C7C; }

#toast-container > div {
  box-shadow: none;
  border-radius: 0;
  width: auto;
  max-width: 15.625rem;
  opacity: 1; }
  [direction="rtl"] #toast-container > div {
    padding: 0.9375rem 3.125rem 0.9375rem 0.9375rem;
    background-position: calc(100% - 0.9375rem);
    text-align: right; }
  #toast-container > div:hover {
    box-shadow: none; }

#toast-container .toast-title {
  margin-bottom: 0.3125rem;
  font-weight: 600; }

#toast-container .toast-message {
  font-size: 0.75rem; }

#toast-container .toast-close-button {
  opacity: 1;
  font-size: 1.25rem;
  font-weight: normal;
  text-shadow: none; }

[direction="rtl"] .toast-top-right.demo_rtl_class {
  left: 0.75rem;
  right: auto; }

/* Light Gallery */
.lg-actions .lg-next,
.lg-actions .lg-prev,
.lg-sub-html,
.lg-toolbar {
  background-color: rgba(30, 30, 30, 0.6); }

.lg-outer .lg-toogle-thumb,
.lg-outer .lg-thumb-outer,
.lg-outer .lg-img-wrap,
.lg-outer .lg-item {
  background-color: transparent; }

.lg-thumb-outer.lg-grab,
.lg-toogle-thumb.lg-icon {
  background-color: rgba(30, 30, 30, 0.6); }

.lg-backdrop {
  background-color: rgba(30, 30, 30, 0.9); }

.lg-outer .lg-toogle-thumb,
.lg-actions .lg-next,
.lg-actions .lg-prev,
.lg-toolbar .lg-icon,
#lg-counter {
  color: #fff; }

.lg-outer .lg-thumb-item.active, .lg-outer .lg-thumb-item:hover {
  border-color: var(--primary); }

.lightimg {
  cursor: pointer; }

.jqvmap-zoomin,
.jqvmap-zoomout {
  position: absolute;
  left: 0.625rem;
  -webkit-border-radius: 0.1875rem;
  -moz-border-radius: 0.1875rem;
  border-radius: 0.1875rem;
  background: #000000;
  padding: 0.1875rem;
  color: white;
  width: 1.0625rem;
  height: 1.0625rem;
  cursor: pointer;
  line-height: 0.625rem;
  text-align: center; }

.jqvmap-zoomin {
  top: 0.625rem; }

.jqvmap-zoomout {
  top: 1.875rem; }

#world-map .rsm-geographies path {
  fill: #eff2f4;
  stroke: #fff; }

.ps__rail-x:hover,
.ps__rail-y:hover,
.ps__rail-x:focus,
.ps__rail-y:focus,
.ps__rail-x.ps--clicking,
.ps__rail-y.ps--clicking {
  background-color: transparent;
  opacity: 0.9; }

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: #DAE2F3;
  width: 0.25rem; }

.ps__thumb-y {
  background-color: #DAE2F3;
  width: 0.25rem; }

.total-average {
  position: relative;
  height: 18.75rem; }

.widget-chat {
  position: relative;
  height: 15.625rem; }

.widget-todo {
  position: relative;
  height: 13.125rem; }

.widget-team {
  position: relative;
  height: 17.8125rem; }

.widget-timeline {
  position: relative; }

.widget-comments {
  position: relative;
  height: 25rem; }

.sidebar-right-inner {
  position: relative;
  height: 100%; }

.widget-team .ps .ps__rail-x:hover,
.widget-team .ps .ps__rail-y:hover,
.widget-team .ps .ps__rail-x:focus,
.widget-team .ps .ps__rail-y:focus,
.widget-team .ps .ps__rail-x.ps--clicking,
.widget-team .ps .ps__rail-y.ps--clicking {
  background-color: transparent !important;
  opacity: 0.9; }

.fc-h-event, .fc-v-event {
  background: var(--primary-light);
  border-radius: .42rem; }

.fc-h-event .fc-event-title {
  color: var(--text-dark); }

.fc-theme-standard td, .fc-theme-standard th {
  border-color: #ebedf3; }

.fc-unthemed .fc-h-event, .fc-unthemed .fc-event-dot {
  padding: 0;
  border-radius: .42rem; }

.fc-theme-standard th {
  padding: .75rem .5rem;
  font-size: 1rem;
  font-weight: 500;
  color: #b5b5c3; }
  @media only screen and (max-width: 35.9375rem) {
    .fc-theme-standard th {
      font-size: 14px;
      font-weight: 400;
      padding: 3px 0px; } }

.fc-theme-standard .fc-scrollgrid.fc-scrollgrid-liquid, .fc-scrollgrid, table {
  border-color: #ebedf3; }

.fc-daygrid-dot-event {
  background: #fff;
  border: 1px solid #ebedf3;
  -webkit-box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.1); }
  .fc-daygrid-dot-event .fc-daygrid-event-dot {
    border-color: var(--primary); }

.fc-daygrid-dot-event .fc-event-title {
  font-weight: 500; }

.fc-event.bg-primary, .fc-event.bg-success, .fc-event.bg-warning, .fc-event.bg-secondary, .fc-event.bg-dark, .fc-event.bg-info {
  color: #fff !important;
  border-radius: 8px; }
  .fc-event.bg-primary .fc-daygrid-event-dot, .fc-event.bg-success .fc-daygrid-event-dot, .fc-event.bg-warning .fc-daygrid-event-dot, .fc-event.bg-secondary .fc-daygrid-event-dot, .fc-event.bg-dark .fc-daygrid-event-dot, .fc-event.bg-info .fc-daygrid-event-dot {
    border-color: #fff; }

.fc .fc-scroller-liquid-absolute, .fc-scroller {
  position: relative;
  overflow: visible !important; }

.fc .fc-button-group > .fc-button {
  color: #b5b5c3;
  background: 0 0;
  border: 1px solid #ebedf3;
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }
  .fc .fc-button-group > .fc-button:focus, .fc .fc-button-group > .fc-button:active, .fc .fc-button-group > .fc-button:hover, .fc .fc-button-group > .fc-button.fc-button-active {
    background: var(--primary-light);
    color: var(--text-dark);
    border-color: var(--primary-light); }

.fc-button.fc-button-primary.fc-today-button {
  background: var(--primary-light);
  color: black;
  border: 0;
  opacity: 1; }

.fc-unthemed .fc-toolbar .fc-button.fc-button-active, .fc-unthemed .fc-toolbar .fc-button:active, .fc-unthemed .fc-toolbar .fc-button:focus {
  background: var(--primary);
  color: #fff;
  border: 1px solid var(--primary);
  -webkit-box-shadow: none;
  box-shadow: none;
  text-shadow: none; }

.fc .fc-toolbar-title {
  font-size: 20px;
  margin: 0; }

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: -0.5em; }

.external-event {
  padding: 8px 10px;
  display: flex;
  align-items: center;
  border-radius: 5px; }
  .external-event:hover:before {
    background: #fff !important; }

.fc-event {
  overflow: hidden; }

.fc .fc-view-harness {
  height: 800px !important;
  overflow-y: auto; }
  @media only screen and (max-width: 35.9375rem) {
    .fc .fc-view-harness {
      height: 450px !important; } }

@media only screen and (max-width: 35.9375rem) {
  .fc .fc-toolbar.fc-header-toolbar {
    display: block; }
    .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk {
      display: flex;
      justify-content: center; }
      .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk:first-child {
        justify-content: space-between; }
    .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-title {
      margin-bottom: 8px; } }

#external-events .external-event {
  text-align: left;
  font-size: 16px; }

@media only screen and (max-width: 35.9375rem) {
  .fc.app-fullcalendar .fc-timegrid-slot-label {
    width: 40px !important;
    font-size: 10px; }
  .fc.app-fullcalendar .fc-event, .fc.app-fullcalendar .external-event {
    font-size: 10px;
    margin: 0;
    padding: 2px 0;
    text-align: center;
    line-height: 1.3; }
  .fc.app-fullcalendar .fc-col-header-cell-cushion {
    display: inline-block;
    padding: 2px 4px;
    font-size: 10px; } }

.ck.ck-editor .ck.ck-toolbar__items .ck.ck-toolbar__separator {
  display: none; }

.ck.ck-editor .ck.ck-button {
  padding: 0.375rem 0.5rem;
  background-color: #fff;
  color: #111828;
  font-weight: 900;
  border: 0.0625rem solid #F0F0F1;
  cursor: pointer; }

.ck.ck-editor .ck.ck-toolbar {
  background-color: #F4F4F5;
  border: 0;
  padding: 0.3125rem 0.625rem; }
  [data-theme-version="dark"] .ck.ck-editor .ck.ck-toolbar {
    background: #1E1E1E !important; }

.ck.ck-editor .ck.ck-toolbar__separator {
  display: none; }

.ck.ck-editor .ck-content {
  background-color: #f7f7f7;
  border-width: 0.0625rem 0 0;
  border-color: rgba(0, 0, 0, 0.1);
  min-height: 12.5rem;
  border: 0.0625rem solid var(--rgba-primary-1) !important; }
  [data-theme-version="dark"] .ck.ck-editor .ck-content {
    border: 0.0625rem solid #444444 !important; }

.ck-editor__editable {
  background-color: #f7f7f7 !important; }
  [data-theme-version="dark"] .ck-editor__editable {
    background: #444444 !important; }

.ck.ck-reset.ck-editor {
  border: 0.0625rem solid var(--rgba-primary-1); }

.ck.ck-editor__main > .ck-editor__editable {
  background: #F4F4F5 !important; }
  [data-theme-version="dark"] .ck.ck-editor__main > .ck-editor__editable {
    background: #1E1E1E !important; }

/*  */
/* .accordion {
	// accordion primary
	&-primary{
		.accordion-header {
			&.collapsed{
				background: var(--rgba-primary-1);
				border-color: var(--rgba-primary-1);
				color:var(--primary);
				box-shadow:none;
				@at-root [data-theme-version="dark"] & {
					background: var(--rgba-primary-1);
					border-color: var(--rgba-primary-1);
					color:$body-color;
				}
			}
			background:var(--primary-light);
			border-color:var(--primary-light);
			color:black;
			box-shadow:0 0.9375rem 1.25rem 0 var(--rgba-primary-1);
		}
	}
	&-primary-solid{
		.accordion-header {
			&.collapsed{
				background: var(--rgba-primary-1);
				border-color: var(--rgba-primary-1);
				color: var(--primary);
				box-shadow:none;
				border-bottom-left-radius:$radius;
				border-bottom-right-radius:$radius;
				@at-root [data-theme-version="dark"] & {
					background: var(--rgba-primary-1);
					border-color: var(--rgba-primary-1);
					color:$body-color;
				}
			}
			background:var(--primary-light);
			border-color:var(--primary-light);
			color:black;
			box-shadow:0 -0.625rem 1.25rem 0 var(--rgba-primary-1);
			border-bottom-left-radius:0;
			border-bottom-right-radius:0;
		}
		.accordion__body {
            border: 0.125rem solid var(--primary);
            border-top: none;
			box-shadow:0 0.9375rem 1.25rem 0 var(--rgba-primary-1);
			border-bottom-left-radius:$radius;
			border-bottom-right-radius:$radius;
		}
	}

	&-danger{
		.accordion-header {
			&.collapsed{
				background: $danger-light;
				border-color: $danger-light;
				color: #211c37;
				box-shadow:none;
			}
			background:$danger;
			border-color:$danger;
			color: $white;
			box-shadow:0 0.9375rem 1.25rem 0 rgba($danger, 0.15);
		}
	}
	&-danger-solid{
		.accordion-header {
			&.collapsed{
				background: $danger-light;
				border-color: $danger-light;
				color: #211c37;
				box-shadow:none;
				border-bottom-left-radius:$radius;
				border-bottom-right-radius:$radius;
				@at-root [data-theme-version="dark"] & {
					background: $danger-opacity;
					border-color: $danger-opacity;
					color:$body-color;
				}
			}
			background:$danger;
			border-color:$danger;
			color: $white;
			box-shadow:0 -0.625rem 1.25rem 0 rgba($danger, 0.15);
			border-bottom-left-radius:0;
			border-bottom-right-radius:0;
		}
		.accordion__body {
            border: 0.125rem solid $danger;
            border-top: none;
			box-shadow:0 0.9375rem 1.25rem 0 rgba($danger, 0.15);
			border-bottom-left-radius:$radius;
			border-bottom-right-radius:$radius;
		}
	}


    //a single accrodion
    &-item {
        margin-bottom: 1.25rem;
		border:0;
    }

    //accrodion header styles
    &-header {
        padding: 0.688rem 0.75rem;
        border: 0.0625rem solid $border;
        cursor: pointer;
        position: relative;
        color: $heading;
        font-weight: 400;
		border-radius:$radius-sm;
		@include transitionMedium;
        @at-root [data-theme-version="dark"] & {
           // color:var(--primary);
            border-color: $d-border;
			color: white;
        }
        
        

        //set the indicator font family
        &-indicator {
            font-family: 'themify';
            position: absolute;
            right: 1.5625rem;
            top: 50%;
            transform: translateY(-50%);
			@at-root [direction="rtl"] & {
				 right:auto;
				 left: 1.5625rem;
			}
            &.indicator_bordered {
                display: inline-block;
                width: 1.5625rem;
                text-align: center;
                height: 1.5625rem;
                border: 0.0625rem solid $border;
                border-radius: 50%;
                line-height: 1.5625rem;
            }
        }

        &:not(.collapsed) {
            .accordion-header-indicator {
                &::before {
                    content: "\e622";
                }

                //icon style
                &.style_two {
                    &::before {
                        content: "\e648";
                    }
                }
            }
        }

        &.collapsed {
            .accordion-header-indicator {
                &::before {
                    content: "\e61a";
                }

                //icon style
                &.style_two {
                    &::before {
                        content: "\e64b";
                    }
                }
            }
        }
    }

    //accordion body styles
    &-body {
        &-text {
            padding: 0.875rem 1.25rem;
        }
        
    }

    //accrodion with border
    &-bordered {
        .accordion__body {
            border: 0.0625rem solid $border;
            border-top: none;
			border-bottom-left-radius:$radius;
			border-bottom-right-radius:$radius;
            @at-root [data-theme-version="dark"] & {
                border-color: $d-border;
            }
        }
		.accordion-header.collapsed{
			border-radius:$radius;
		}
		.accordion-header{
			border-bottom-left-radius:0;
			border-bottom-right-radius:0;
		}
		
    }

    //accrodion without space
    &-no-gutter {
        .accordion-item {
            margin-bottom: 0;

            .accordion-header {
                &.collapsed {
                    border-bottom: none;
                }
            }

            &:last-child {
                .accordion-header {
                    border-bottom: 0.0625rem solid $border;
                    @at-root [data-theme-version="dark"] & {
                        border-color: $d-border;
                    }
                }
            }
            
        }
    }

    //not gutter and bordered
    &-no-gutter.accordion__bordered {
        .accordion-item {
            &:not(:last-child) {
                .accordion__body {
                    border-bottom: none;
                }
            }
        }
    }

    //indicator in left positon
    &-left-indicator {
        .accordion-header {
            &-text {
                padding-left: 2.5rem;
            }

            &-indicator {
                right: auto;
                left: 1.5625rem;
            }
        }
    }

    //with icon
    &-with-icon {
        .accordion-header {
            &-text {
                padding-left: 2.5rem;
				@at-root [direction="rtl"] & {
					padding-left: 0;
					padding-right: 2.5rem;
				}
            }

            &-icon {
                position: absolute;
                right: auto;
                left: 1.5625rem;
                font-family: 'themify';
				@at-root [direction="rtl"] & {
					left: auto;
					right: 1.5625rem;
				}
                &::before {
                    content: "\e645";
                }
            }
        }

    }

    //with header background
    &-header-bg {
        .accordion-header {
            background-color: $light;
            @at-root [data-theme-version="dark"] & {
                background-color: $d-bg;
                color: $white;
            }
            
            &-primary {
                background-color: var(--primary);
                color: $white;
				border-color:var(--primary);
				@at-root [data-theme-version="dark"] & {
					background-color: var(--primary);
				}
            }
            
            &-info {
                background-color: $info;
                color: $white;
				border-color:$info;
				@at-root [data-theme-version="dark"] & {
					background-color: $info;
				}
            }

            &-success {
                background-color: $success;
                color: $white;
				border-color:$success;
				@at-root [data-theme-version="dark"] & {
					background-color: $success;
				}
            }
        }
    }

    //with header background and no space
    &-header-bg.accordion-no-gutter {
        .accordion-header {
            border-color: transparent;
			border-radius: 0;
        }
		.accordion-item{
			&:first-child{
				.accordion-header{
					border-top-left-radius:$radius;
					border-top-right-radius:$radius;
				}
			}
			&:last-child{
				.accordion-header{
					border-bottom-left-radius:$radius;
					border-bottom-right-radius:$radius;
				}
			}
		}
    }
	&.accordion-no-gutter {
        .accordion-header {
            border-radius: 0;
        }
		.accordion-header.collapsed {
			border-radius: 0;
		}
		.accordion__body{
			border-radius: 0;
		}
		.accordion-item{
			&:first-child{
				.accordion-header{
					border-top-left-radius:$radius;
					border-top-right-radius:$radius;
				}
			}
			&:last-child{
				.accordion-header.collapsed{
					border-bottom-left-radius:$radius;
					border-bottom-right-radius:$radius;
				}
				.accordion__body{
					border-bottom-left-radius:$radius;
					border-bottom-right-radius:$radius;
				}
			}
		}
    }
    //with solid background
    &-solid-bg {
        .accordion-header {
            border-color: transparent;
            background-color: var(--rgba-primary-1);
			border-bottom-left-radius:0;
			border-bottom-right-radius:0;
			
            @at-root [data-theme-version="dark"] & {
                background-color: $d-bg;
            }
			&.collapsed{
				border-radius:$radius;
			}
        }
		
        .accordion__body {
            border-color: transparent;
            background-color: var(--rgba-primary-1);
			border-bottom-left-radius:$radius;
			border-bottom-right-radius:$radius;
            @at-root [data-theme-version="dark"] & {
                background-color: $d-bg;
            }
        }
    }

    //active header styles
    &-active-header {
        .accordion-header {
            &:not(.collapsed) {
                background-color: $info;
                border-color: $info;
                color: $white;
            }
        }
    }

    //shadow in header
    &-header-shadow {
        .accordion-header {
            border: none;
            box-shadow: 0 0 0.9375rem -0.1875rem rgba($color: $black, $alpha: .3);
        }
    }

    //stylishly rounded borders
    &-rounded-stylish {
        .accordion-header {
            border-top-left-radius: 0.375rem;
            border-top-right-radius: 0.375rem;
        }

        .accordion__body {
            border-bottom-left-radius: 0.375rem;
            border-bottom-right-radius: 0.375rem;
        }
    }

    //smothly rounded accrodion header
    &-rounded {
        .accordion-header {
            border-radius: 0.3125rem;
        }
    }

    //accordion gradient
    &-gradient {
        .accordion-header {
            color: $white;
            background-image: linear-gradient(to right, rgba(186, 1, 181, 0.85) 0%,rgba(103, 25, 255, .85) 100%);
            border-color: transparent;
			border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
			&.collapsed{
				border-bottom-left-radius: 0.375rem;
				border-bottom-right-radius:0.375rem;
			}
        }

        .accordion__body {
            color: $white;
            background-image: linear-gradient(to right, rgba(186, 1, 181, 0.85) 0%,rgba(103, 25, 255, .85) 100%);
            border-color: transparent;
        }
    }
	
}  */
.accordion-button:not(.collapsed) {
  color: inherit;
  background: inherit; }

.accordion-button.collapsed {
  border-bottom-width: 1px; }

.accordion-button:focus {
  box-shadow: unset; }

.accordion-primary .accordion-button {
  font-size: 1rem;
  background: var(--primary-light);
  border-color: var(--primary-light);
  color: var(--text-dark);
  box-shadow: 0 0.0375rem 0rem 0 var(--primary-light);
  border-radius: 0.625rem 0.625rem 0 0; }
  .accordion-primary .accordion-button.collapsed {
    background: var(--primary-light);
    border-color: var(--primary-light);
    color: var(--text-dark);
    box-shadow: none;
    border-radius: 0.625rem; }
    [data-theme-version="dark"] .accordion-primary .accordion-button.collapsed {
      background: var(--primary-light);
      border-color: var(--primary-light);
      color: var(--text-dark); }

.accordion-primary-solid .accordion-button {
  background: #A0CF1A;
  border-color: #A0CF1A;
  color: #fff;
  box-shadow: 0 -0.625rem 1.25rem 0 var(--primary-light);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }
  .accordion-primary-solid .accordion-button.collapsed {
    background: var(--primary-light);
    border-color: var(--primary-light);
    color: #A0CF1A;
    box-shadow: none; }
    [data-theme-version="dark"] .accordion-primary-solid .accordion-button.collapsed {
      background: var(--primary-light);
      border-color: var(--primary-light);
      color: #888888; }

.accordion-primary-solid .accordion-body {
  border: 0.125rem solid #A0CF1A;
  border-top: none;
  box-shadow: 0 0.9375rem 1.25rem 0 var(--primary-light); }

.accordion-danger .accordion-button {
  background: #FF7C7C;
  border-color: #FF7C7C;
  color: #fff;
  box-shadow: 0 0.9375rem 1.25rem 0 rgba(255, 124, 124, 0.15); }
  .accordion-danger .accordion-button.collapsed {
    background: #FFCCC0;
    border-color: #FFCCC0;
    color: #211c37;
    box-shadow: none; }

.accordion-danger-solid .accordion-button {
  font-size: 1rem;
  background: #FF7C7C;
  border-color: #FF7C7C;
  color: #fff;
  box-shadow: 0 -0.625rem 1.25rem 0 rgba(255, 124, 124, 0.15);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }
  .accordion-danger-solid .accordion-button.collapsed {
    background: #FFCCC0;
    border-color: #FFCCC0;
    color: #211c37;
    box-shadow: none; }
    [data-theme-version="dark"] .accordion-danger-solid .accordion-button.collapsed {
      background: rgba(255, 124, 124, 0.15);
      border-color: rgba(255, 124, 124, 0.15);
      color: #888888; }

.accordion-danger-solid .accordion-body {
  border: 0.125rem solid #FF7C7C;
  border-top: none;
  box-shadow: 0 0.9375rem 1.25rem 0 rgba(255, 124, 124, 0.15); }

.accordion-item {
  margin-bottom: 1.25rem;
  border: 0;
  background-color: transparent; }
  .accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-left-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem; }

.accordion-button {
  padding: 0.688rem 0.75rem;
  border: 0.0625rem solid #E6E6E6;
  cursor: pointer;
  position: relative;
  color: #333;
  font-weight: 400;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s; }
  [data-theme-version="dark"] .accordion-button {
    color: #fff !important;
    border-color: #444444; }
  .accordion-button-indicator.indicator_bordered {
    display: inline-block;
    width: 1.5625rem;
    text-align: center;
    height: 1.5625rem;
    border: 0.0625rem solid #E6E6E6;
    border-radius: 50%;
    line-height: 1.5625rem; }
  .accordion-button:not(.collapsed)::after {
    content: "\e622";
    background-image: none;
    transform: rotate(0) translateY(-50%); }
  .accordion-button:not(.collapsed).style_two::after {
    content: "\e648"; }
  .accordion-button::after {
    content: "\e61a";
    font-family: 'themify';
    position: absolute;
    right: 1.5625rem;
    top: 50%;
    transform: translateY(-50%);
    background-image: none !important;
    width: auto;
    height: auto; }
    [direction="rtl"] .accordion-button::after {
      right: auto;
      left: 1.5625rem; }
  .accordion-button.collapsed.style_two::after {
    content: "\e64b"; }

.accordion-body {
  padding: 0.875rem 1.25rem;
  border-radius: 0 0 0.625rem 0.625rem; }

.accordion-collapse {
  border: 0; }

.accordion-bordered .accordion-body {
  border: 0.0625rem solid #E6E6E6;
  border-top: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }
  [data-theme-version="dark"] .accordion-bordered .accordion-body {
    border-color: #444444; }

.accordion-bordered .accordion-button.collapsed {
  border-radius: 0; }

.accordion-bordered .accordion-button {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.accordion-no-gutter .accordion-item {
  margin-bottom: 0; }
  .accordion-no-gutter .accordion-item .accordion-button.collapsed {
    border-bottom: none; }
  .accordion-no-gutter .accordion-item:last-child .accordion-button {
    border-bottom: 0.0625rem solid #E6E6E6; }
    [data-theme-version="dark"] .accordion-no-gutter .accordion-item:last-child .accordion-button {
      border-color: #444444; }

.accordion-no-gutter.accordion__bordered .accordion-item:not(:last-child) .accordion__body {
  border-bottom: none; }

.accordion-left-indicator .accordion-button-text {
  padding-left: 2.5rem; }

.accordion-left-indicator .accordion-button .accordion-header-indicator {
  right: auto;
  left: 1.5625rem; }

.accordion-with-icon .accordion-button-text {
  padding-left: 2.5rem; }
  [direction="rtl"] .accordion-with-icon .accordion-button-text {
    padding-left: 0;
    padding-right: 2.5rem; }

.accordion-with-icon .accordion-button .accordion-header-icon {
  position: absolute;
  right: auto;
  left: 1.5625rem;
  font-family: 'themify'; }
  [direction="rtl"] .accordion-with-icon .accordion-button .accordion-header-icon {
    left: auto;
    right: 1.5625rem; }
  .accordion-with-icon .accordion-button .accordion-header-icon:before {
    content: "\e645"; }

.accordion-with-icon .accordion-button .accordion-header-text {
  padding-left: 2.5rem; }

.accordion-header-bg .accordion-header .accordion-button {
  background-color: #EEEEEE; }
  [data-theme-version="dark"] .accordion-header-bg .accordion-header .accordion-button {
    background-color: #1E1E1E; }

.accordion-header-bg .accordion-header-primary .accordion-button {
  background-color: #A0CF1A;
  color: #fff;
  border-color: #A0CF1A; }
  [data-theme-version="dark"] .accordion-header-bg .accordion-header-primary .accordion-button {
    background-color: #A0CF1A; }

.accordion-header-bg .accordion-header-info .accordion-button {
  background-color: #81A5F9;
  color: #fff;
  border-color: #81A5F9; }
  [data-theme-version="dark"] .accordion-header-bg .accordion-header-info .accordion-button {
    background-color: #81A5F9; }

.accordion-header-bg .accordion-header-success .accordion-button {
  background-color: #53D0B2;
  color: #fff;
  border-color: #53D0B2; }
  [data-theme-version="dark"] .accordion-header-bg .accordion-header-success .accordion-button {
    background-color: #53D0B2; }

.accordion-header-bg.accordion-no-gutter .accordion-button {
  border-color: transparent;
  border-radius: 0; }

.accordion-header-bg.accordion-no-gutter .accordion-item:first-child .accordion-button {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.accordion-header-bg.accordion-no-gutter .accordion-item:last-child .accordion-button {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.accordion.accordion-no-gutter .accordion-button {
  border-radius: 0;
  font-size: 1rem; }

.accordion.accordion-no-gutter .accordion-button.collapsed {
  border-radius: 0; }

.accordion.accordion-no-gutter .accordion-body {
  border-radius: 0; }

.accordion.accordion-no-gutter .accordion-item:first-child .accordion-button {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.accordion.accordion-no-gutter .accordion-item:last-child .accordion-button.collapsed {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.accordion.accordion-no-gutter .accordion-item:last-child .accordion__body {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.accordion-solid-bg .accordion-button {
  border-color: transparent;
  background-color: var(--primary-light);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }
  [data-theme-version="dark"] .accordion-solid-bg .accordion-button {
    background-color: #1E1E1E; }

.accordion-solid-bg .accordion-body {
  border-color: transparent;
  background-color: var(--primary-light);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }
  [data-theme-version="dark"] .accordion-solid-bg .accordion-body {
    background-color: #1E1E1E; }

.accordion-active-header .accordion-button:not(.collapsed) {
  background-color: #81A5F9;
  border-color: #81A5F9;
  color: #fff;
  box-shadow: unset; }

.accordion-header-shadow .accordion-button {
  border: none;
  box-shadow: 0 0 0.9375rem -0.1875rem rgba(0, 0, 0, 0.3); }

.accordion-rounded-stylish .accordion-button {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.accordion-rounded-stylish .accordion__body {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.accordion-rounded .accordion-button {
  border-radius: 0; }

.accordion-gradient .accordion-button {
  color: #fff;
  background-image: linear-gradient(to right, rgba(186, 1, 181, 0.85) 0%, rgba(103, 25, 255, 0.85) 100%);
  border-color: transparent;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }
  .accordion-gradient .accordion-button.collapsed {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }

.accordion-gradient .accordion-body {
  color: #fff;
  background-image: linear-gradient(to right, rgba(186, 1, 181, 0.85) 0%, rgba(103, 25, 255, 0.85) 100%);
  border-color: transparent; }

.accordion-header-bg .accordion-button,
.accordion-header-bg .accordion-body {
  border-radius: 0; }
  .accordion-header-bg .accordion-button.collapsed,
  .accordion-header-bg .accordion-body.collapsed {
    border-radius: 0; }

.dzm-tabs {
  padding: 4px;
  border-radius: 0.375rem;
  background-color: var(--rgba-primary-1);
  border: none;
  flex-wrap: nowrap; }
  .dzm-tabs .nav-item .nav-link {
    border-radius: 4px;
    color: var(--text-dark); }
    .dzm-tabs .nav-item .nav-link.active {
      background: var(--primary-light) !important;
      color: var(--text-dark);
      border: 1px solid transparent; }
    .dzm-tabs .nav-item .nav-link:hover {
      border-color: transparent; }
  @media only screen and (max-width: 35.9375rem) {
    .dzm-tabs {
      margin-top: 10px; } }

.dz-card .card-header {
  padding: 0.9rem 1.125rem 0.9rem; }
  @media only screen and (max-width: 35.9375rem) {
    .dz-card .card-header {
      padding: 0.4rem 1.125rem 0.4rem; } }

.dz-card .card-body {
  padding: 1.125rem !important;
  border-radius: 0 0 0.625rem 0.625rem; }
  @media only screen and (max-width: 35.9375rem) {
    .dz-card .card-body {
      padding: 1rem; } }
  .dz-card .card-body.code-area {
    background: #222b40; }
    .dz-card .card-body.code-area .language-html {
      background: transparent;
      color: white; }
      .dz-card .card-body.code-area .language-html pre code {
        background: transparent;
        color: white; }

.dz-card .card-footer {
  color: #888888 !important; }

.dz-card .badge-box {
  position: relative;
  background: black;
  opacity: 0.6;
  border-radius: 0 0 0.625rem 0.625rem; }

.unorder-list li {
  list-style: unset; }

@media only screen and (max-width: 61.9375rem) {
  .unorder-list {
    padding-left: 2rem; } }

.order-list li {
  list-style-type: unset; }

.text-justify {
  text-align: justify; }

.accordion-item {
  background-color: transparent; }

.demo-right-inner {
  padding: 0;
  background-color: #fff;
  border-radius: 0.625rem;
  border: 1px solid #000000;
  overflow: hidden; }
  .demo-right-inner h4, .demo-right-inner .h4 {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    padding-bottom: 0;
    margin-bottom: 0; }
  .demo-right-inner li {
    font-size: 1rem;
    position: relative; }
    .demo-right-inner li a {
      display: block;
      padding: 0.5rem 1rem;
      font-size: 14px;
      line-height: 1.5;
      width: 270px;
      cursor: pointer; }
    .demo-right-inner li:after {
      content: "";
      position: absolute;
      background-color: #EEEEEE;
      width: 5px;
      height: 5px;
      border-radius: 100%;
      top: 18px;
      left: -10px; }

.demo-right-tabs {
  margin: 0 -1.625rem;
  padding: 0px 1.625rem;
  padding-top: 5px; }

.navbar-nav .active .scroll {
  background: var(--primary);
  font-weight: 500;
  color: #fff; }

.navbar-nav .active:after {
  background-color: var(--primary); }

.element-area {
  display: flex; }
  .element-area .demo-view {
    width: calc(100% - 270px); }
    @media only screen and (max-width: 100rem) {
      .element-area .demo-view {
        width: calc(100% - 236px); } }
    @media only screen and (max-width: 74.9375rem) {
      .element-area .demo-view {
        width: calc(100% - 224px); } }
    @media only screen and (max-width: 61.9375rem) {
      .element-area .demo-view {
        width: 100%; } }
    @media only screen and (max-width: 47.9375rem) {
      .element-area .demo-view {
        width: 100%; }
        .element-area .demo-view .container-sm {
          padding: 0; } }
  .element-area .demo-right {
    width: 16.875rem;
    position: sticky;
    top: 6rem;
    height: 100%;
    z-index: 2; }
    @media only screen and (max-width: 74.9375rem) {
      .element-area .demo-right {
        width: 18.875rem; } }
    @media only screen and (max-width: 61.9375rem) {
      .element-area .demo-right {
        display: none; } }
    @media only screen and (max-width: 47.9375rem) {
      .element-area .demo-right {
        display: none; } }
  .element-area .demo-right.sticky {
    top: 100px;
    transition: 0.5s; }

.clockpicker-popover {
  position: absolute; }

#lightgallery img {
  border-radius: 0.625rem; }

.list-group-item.active {
  color: var(--text-dark); }

.accordion {
  --bs-accordion-border-width:0; }

.accordion-left-indicator .accordion-header .accordion-button {
  padding-left: 2.5rem; }
  .accordion-left-indicator .accordion-header .accordion-button:after {
    left: 1rem; }

.alert {
  border-radius: 0.625rem;
  padding: 0.75rem 1rem;
  font-size: 15px; }
  .alert p {
    line-height: 1.5; }

.alert-square {
  border-radius: 0; }

.alert-rounded {
  border-radius: 1.875rem; }

.alert-primary {
  background: var(--primary-light);
  border-color: var(--primary-light);
  color: var(--text-dark); }
  [data-theme-version="dark"] .alert-primary {
    background: var(--rgba-primary-1);
    border-color: var(--rgba-primary-1); }

.alert-secondary {
  background: #DCDFE5;
  border-color: #DCDFE5;
  color: var(--text-dark); }
  [data-theme-version="dark"] .alert-secondary {
    background: rgba(17, 24, 40, 0.5);
    border-color: rgba(17, 24, 40, 0.5);
    color: #fff; }

.alert-success {
  background: #A7FFEA;
  border-color: #A7FFEA;
  color: var(--text-dark); }
  [data-theme-version="dark"] .alert-success {
    background: rgba(83, 208, 178, 0.1);
    border-color: rgba(83, 208, 178, 0.1); }

.alert-warning {
  background: #FCEF73;
  border-color: #FCEF73;
  color: var(--text-dark); }
  [data-theme-version="dark"] .alert-warning {
    background: rgba(244, 207, 12, 0.1);
    border-color: rgba(244, 207, 12, 0.1); }

.alert-danger {
  background: #FFCCC0;
  border-color: #FFCCC0;
  color: var(--text-dark); }
  [data-theme-version="dark"] .alert-danger {
    background: rgba(255, 124, 124, 0.15);
    border-color: rgba(255, 124, 124, 0.15); }

.alert-info {
  background: #D7E3FF;
  border-color: #D7E3FF;
  color: var(--text-dark); }
  [data-theme-version="dark"] .alert-info {
    background: rgba(129, 165, 249, 0.1);
    border-color: rgba(129, 165, 249, 0.1); }

.alert-dark {
  background: #999999;
  border-color: #999999;
  color: var(--text-dark); }
  [data-theme-version="dark"] .alert-dark {
    background: rgba(51, 51, 51, 0.35);
    border-color: rgba(51, 51, 51, 0.35);
    color: #fff; }

.alert-light {
  background: #EEEEEE;
  border-color: #EEEEEE;
  color: var(--text-dark); }

.alert .btn-close {
  text-shadow: 0 1px 0 #fff;
  top: -5px;
  opacity: 0.5;
  color: inherit; }

.alert-alt.alert-primary {
  border-left: 0.25rem solid var(--primary); }

.alert-alt.alert-secondary {
  border-left: 0.25rem solid #111828; }

.alert-alt.alert-success {
  border-left: 0.25rem solid #53D0B2; }

.alert-alt.alert-warning {
  border-left: 0.25rem solid #F4CF0C; }

.alert-alt.alert-danger {
  border-left: 0.25rem solid #FF7C7C; }

.alert-alt.alert-info {
  border-left: 0.25rem solid #81A5F9; }

.alert-alt.alert-dark {
  border-left: 0.25rem solid #333333; }

.alert-alt.alert-light {
  border-left: 0.25rem solid #c8c8c8; }

.alert-alt.alert-primary.solid {
  border-left: 0.25rem solid var(--primary-dark) !important; }

.alert-alt.alert-secondary.solid {
  border-left: 0.25rem solid black !important; }

.alert-alt.alert-success.solid {
  border-left: 0.25rem solid #23806a !important; }

.alert-alt.alert-warning.solid {
  border-left: 0.25rem solid #7b6806 !important; }

.alert-alt.alert-danger.solid {
  border-left: 0.25rem solid #fc0000 !important; }

.alert-alt.alert-info.solid {
  border-left: 0.25rem solid #0b50ef !important; }

.alert-alt.alert-dark.solid {
  border-left: 0.25rem solid black !important; }

.alert-alt.alert-light.solid {
  border-left: 0.25rem solid #aeaeae !important; }

.alert-dismissible.solid .close:hover {
  color: #fff;
  opacity: 1; }

.alert.alert-primary.solid {
  background: var(--primary);
  color: #fff;
  border-color: var(--primary); }

.alert.alert-secondary.solid {
  background: #111828;
  color: #fff;
  border-color: #111828; }

.alert.alert-success.solid {
  background: #53D0B2;
  color: #fff;
  border-color: #53D0B2; }

.alert.alert-warning.solid {
  background: #F4CF0C;
  color: #fff;
  border-color: #F4CF0C; }

.alert.alert-danger.solid {
  background: #FF7C7C;
  color: #fff;
  border-color: #FF7C7C; }

.alert.alert-info.solid {
  background: #81A5F9;
  color: #fff;
  border-color: #81A5F9; }

.alert.alert-dark.solid {
  background: #333333;
  color: #fff;
  border-color: #333333; }

.alert.alert-light.solid {
  background: #EEEEEE;
  color: #333333;
  border-color: #EEEEEE; }

.alert-right-icon > span i {
  font-size: 1.125rem;
  margin-right: 0.3125rem; }

.alert-right-icon .close i {
  font-size: 1rem; }

.alert.alert-outline-primary {
  background: transparent;
  color: var(--primary);
  border-color: var(--primary); }

.alert.alert-outline-secondary {
  background: transparent;
  color: #888888;
  border-color: #111828; }

.alert.alert-outline-success {
  background: transparent;
  color: #53D0B2;
  border-color: #53D0B2; }

.alert.alert-outline-info {
  background: transparent;
  color: #81A5F9;
  border-color: #81A5F9; }

.alert.alert-outline-warning {
  background: transparent;
  color: #F4CF0C;
  border-color: #F4CF0C; }

.alert.alert-outline-danger {
  background: transparent;
  color: #FF7C7C;
  border-color: #FF7C7C; }

.alert.alert-outline-dark {
  background: transparent;
  color: #888888;
  border-color: #333333; }

.alert.alert-outline-light {
  background: transparent;
  color: #333333;
  border-color: #EEEEEE; }

.alert-social {
  color: #fff; }
  .alert-social .alert-social-icon {
    align-self: center;
    margin-right: 0.9375rem; }
    .alert-social .alert-social-icon i {
      font-size: 2.625rem; }
  .alert-social.facebook {
    background-color: #3b5998; }
  .alert-social.twitter {
    background-color: #1da1f2; }
  .alert-social.linkedin {
    background-color: #007bb6; }
  .alert-social.google-plus {
    background-color: #db4439; }
  .alert-social .close:hover {
    opacity: 1 !important;
    color: #fff !important; }

.left-icon-big .alert-left-icon-big {
  align-self: center;
  margin-right: 0.9375rem; }
  .left-icon-big .alert-left-icon-big i {
    font-size: 2.1875rem;
    line-height: 1; }

[direction="rtl"] .left-icon-big .alert-left-icon-big,
[direction="rtl"] .alert-social .alert-social-icon {
  margin-right: 0;
  margin-left: 0.9375rem; }

.dz-scroll {
  overflow-y: scroll; }

body > * {
  scrollbar-width: thin;
  scrollbar-color: rgba(111, 133, 147, 0.1) #fff0; }

::-webkit-scrollbar {
  width: 5px;
  opacity: 0; }

::-webkit-scrollbar-thumb {
  background: rgba(111, 133, 147, 0.1); }

.badge {
  line-height: 1.3;
  border-radius: 0.25rem;
  font-size: 12px;
  font-weight: 500;
  padding: 4px 10px;
  border: 0.0625rem solid transparent; }

.badge-rounded {
  border-radius: 1.25rem;
  padding: 0.1875rem 0.8125rem; }

.badge-circle {
  border-radius: 6.25rem;
  padding: 0.1875rem 0.575rem; }

.light.badge-default {
  background: #ADB6C7; }

.light.badge-primary {
  background-color: var(--primary-light);
  border-color: var(--primary-light);
  color: #fff; }

.light.badge-success {
  background-color: #A7FFEA;
  border-color: #A7FFEA;
  color: #53D0B2; }

.light.badge-info {
  background-color: #D7E3FF;
  border-color: #D7E3FF;
  color: #81A5F9; }

.light.badge-secondary {
  background-color: #DCDFE5;
  border-color: #DCDFE5;
  color: #111828; }

.light.badge-warning {
  background-color: #FCEF73;
  border-color: #FCEF73;
  color: #F4CF0C; }

.light.badge-danger {
  background-color: #FFCCC0;
  border-color: #FFCCC0;
  color: #FF7C7C; }

.light.badge-dark {
  background-color: #999999;
  border-color: #999999;
  color: #333333; }

.light.badge-light {
  background-color: #EEEEEE;
  border-color: #EEEEEE;
  color: #333333; }

.badge-outline-primary {
  border: 0.0625rem solid var(--primary);
  color: var(--primary); }
  .badge-outline-primary:hover {
    color: var(--primary); }

.badge-outline-secondary {
  border: 0.0625rem solid #111828;
  color: var(--secondary); }
  .badge-outline-secondary:hover {
    color: var(--secondary); }
  [data-theme-version="dark"] .badge-outline-secondary {
    color: #888888; }

.badge-outline-success {
  border: 0.0625rem solid #53D0B2;
  color: #53D0B2; }
  .badge-outline-success:hover {
    color: #53D0B2; }

.badge-outline-info {
  border: 0.0625rem solid #81A5F9;
  color: #81A5F9; }
  .badge-outline-info:hover {
    color: #81A5F9; }

.badge-outline-warning {
  border: 0.0625rem solid #F4CF0C;
  color: #F4CF0C; }
  .badge-outline-warning:hover {
    color: #F4CF0C; }

.badge-outline-danger {
  border: 0.0625rem solid #FF7C7C;
  color: #FF7C7C; }
  .badge-outline-danger:hover {
    color: #FF7C7C; }

.badge-outline-light {
  border: 0.0625rem solid #E6E6E6;
  color: #333333; }
  .badge-outline-light:hover {
    color: #333333; }
  .badge-outline-light:hover {
    color: #333333; }
  [data-theme-version="dark"] .badge-outline-light {
    color: #888888; }

.badge-outline-dark {
  border: 0.0625rem solid #333333;
  color: #333333; }
  [data-theme-version="dark"] .badge-outline-dark {
    color: #888888; }

.badge-xs {
  font-size: 0.5rem;
  padding: 0rem 0.3125rem;
  line-height: 1.5; }
  @media only screen and (max-width: 100rem) {
    .badge-xs {
      line-height: 2; } }

.badge-sm {
  font-size: 0.6875rem;
  padding: 0.125rem 0.5rem;
  line-height: 13px; }

.badge-lg {
  font-size: 0.875rem;
  padding: 0rem 0.625rem;
  line-height: 1.875rem; }

.badge-xl {
  font-size: 1rem;
  padding: 0.1rem 0.9375rem;
  line-height: 2.1875rem;
  font-weight: 400; }

.badge-default {
  background: #ADB6C7; }

.badge-success {
  background-color: #53D0B2; }
  .badge-success:hover {
    color: #fff; }

.badge-secondary {
  background-color: #111828; }
  .badge-secondary:hover {
    color: #fff; }

.badge-info {
  background-color: #81A5F9; }
  .badge-info:hover {
    color: #fff; }

.badge-primary {
  background-color: var(--primary); }
  .badge-primary:hover {
    color: #fff; }

.badge-warning {
  background-color: #F4CF0C; }
  .badge-warning:hover {
    color: #fff; }

.badge-danger {
  background-color: #FF7C7C; }
  .badge-danger:hover {
    color: #fff; }

.badge-dark {
  background-color: #333333; }
  .badge-dark:hover {
    color: #fff; }

.badge-light {
  background-color: #EEEEEE;
  color: var(--text-dark); }
  .badge-light:hover {
    color: var(--text-dark); }

.light.badge-default {
  background: #ADB6C7; }

.light.badge-success {
  background-color: #A7FFEA;
  color: var(--text-dark); }
  [data-theme-version="dark"] .light.badge-success {
    border-color: transparent;
    color: black !important; }

.light.badge-info {
  background-color: #D7E3FF;
  color: var(--text-dark); }
  [data-theme-version="dark"] .light.badge-info {
    color: black;
    background-color: #D7E3FF; }

.light.badge-primary {
  background-color: var(--primary-light);
  border-color: var(--primary-light);
  color: var(--text-dark); }
  [data-theme-version="dark"] .light.badge-primary {
    border-color: transparent;
    color: black !important;
    background-color: var(--primary-light); }

.light.badge-secondary {
  background-color: #DCDFE5;
  border-color: #DCDFE5;
  color: var(--text-dark); }
  [data-theme-version="dark"] .light.badge-secondary {
    color: #000;
    border-color: transparent;
    color: #000 !important; }

.light.badge-warning {
  background-color: #FCEF73;
  border-color: #FCEF73;
  color: var(--text-dark); }
  [data-theme-version="dark"] .light.badge-warning {
    background-color: #FCEF73;
    border-color: transparent;
    color: #000 !important; }

.light.badge-danger {
  background-color: #FFCCC0;
  border-color: #FFCCC0;
  color: var(--text-dark); }
  [data-theme-version="dark"] .light.badge-danger {
    border-color: transparent;
    color: black; }

.light.badge-dark {
  background-color: #999999;
  border-color: #999999;
  color: var(--text-dark); }
  [data-theme-version="dark"] .light.badge-dark {
    background-color: rgba(51, 51, 51, 0.35);
    color: #888888;
    border-color: transparent; }

.bootstrap-label .label {
  display: inline-block;
  margin-right: 1rem; }
  .bootstrap-label .label:last-child {
    margin-right: 0; }

.badge-demo .badge {
  margin-right: 0.3125rem;
  margin-bottom: 0.3125rem; }
  .badge-demo .badge:last-child {
    margin-right: 0; }

.bootstrap-badge-buttons button {
  margin-right: .2rem;
  margin-bottom: 1rem; }
  .bootstrap-badge-buttons button:last-child {
    margin-right: 0; }

.breadcrumb {
  font-size: 13px; }
  .breadcrumb .breadcrumb-item a svg {
    margin-bottom: 3px;
    margin-right: 4px; }
  .breadcrumb .breadcrumb-item.active a {
    color: #000; }

.page-titles {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.6rem 1.95rem 0.6rem;
  background: #ffffff;
  position: relative;
  z-index: 1;
  border-bottom: 1px solid #E6E6E6;
  flex-flow: wrap; }
  [data-theme-version="dark"] .page-titles {
    background: #2c2c2c; }
  @media only screen and (max-width: 74.9375rem) {
    .page-titles {
      margin-top: 0; } }
  @media only screen and (max-width: 47.9375rem) {
    .page-titles {
      margin-bottom: 0rem; } }
  .page-titles .justify-content-sm-end {
    align-items: center; }
  .page-titles h4, .page-titles .h4 {
    margin-bottom: 0;
    margin-top: 0;
    color: var(--primary);
    font-size: 1.25rem; }
    .page-titles h4 span, .page-titles .h4 span {
      font-size: 0.875rem;
      font-weight: 400; }
  .page-titles .breadcrumb {
    margin-bottom: 0;
    padding: 0;
    background: transparent;
    align-items: center; }
    .page-titles .breadcrumb li {
      margin-top: 0;
      margin-bottom: 0; }
      .page-titles .breadcrumb li a {
        color: #828690;
        font-size: 13px; }
      .page-titles .breadcrumb li.active {
        color: var(--primary);
        font-weight: 500; }
        .page-titles .breadcrumb li.active a {
          color: var(--primary) !important; }
    .page-titles .breadcrumb .breadcrumb-item + .breadcrumb-item:before {
      /* font-family: 'simple-line-icons';
                font-weight: 400;
				position:absolute;
                font-size: 10px; */
      color: var(--primary); }
    .page-titles .breadcrumb-datepicker {
      font-size: 0.75rem;
      color: #89879f; }
      .page-titles .breadcrumb-datepicker__icon {
        font-size: 0.875rem; }
  .page-titles .bc-title {
    margin: 0;
    font-size: 17px;
    margin-right: 2rem;
    color: var(--secondary); }
    @media only screen and (max-width: 35.9375rem) {
      .page-titles .bc-title {
        font-size: 15px; } }

button {
  cursor: pointer; }
  button:focus {
    outline: 0;
    box-shadow: none; }

.btn {
  padding: 11.5px 20px;
  border-radius: 0.625rem;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px; }
  .btn:hover, .btn:focus, .btn:active, .btn.active {
    outline: 0 !important;
    box-shadow: none !important; }
  @media only screen and (max-width: 87.5rem) {
    .btn {
      padding: 0.625rem 1rem;
      font-size: 0.813rem; } }
  .btn.btn-success, .btn.btn-secondary, .btn.btn-warning, .btn.btn-primary, .btn.btn-danger, .btn.btn-info {
    --bs-btn-color: #fff; }
  .btn-transparent {
    background-color: transparent; }

.swal2-popup .swal2-styled:focus {
  box-shadow: none; }

.btn-primary {
  border-color: var(--primary);
  background-color: var(--primary); }
  .btn-primary:active, .btn-primary:focus, .btn-primary:hover {
    border-color: var(--primary-hover);
    background-color: var(--primary-hover);
    color: #fff; }
  .btn-primary:focus {
    box-shadow: 0 0 0 0.25rem var(--rgba-primary-5); }
  .btn-primary:disabled, .btn-primary.disabled {
    background-color: var(--primary);
    border-color: var(--primary); }

.btn-link {
  color: var(--primary);
  text-decoration: none; }
  .btn-link svg circle {
    fill: var(--primary); }
  .btn-link:hover {
    color: var(--primary-hover); }

.btn-outline-primary {
  color: var(--primary);
  border-color: var(--primary); }
  .btn-outline-primary:hover {
    border-color: var(--primary-hover);
    background-color: var(--primary-hover); }

.sharp {
  min-width: 30px;
  padding: 0.4375rem;
  height: 30px;
  line-height: 0px;
  min-height: 30px;
  border-radius: 0.375rem; }
  .sharp.sharp-lg {
    min-width: 40px;
    padding: 0.4375rem;
    height: 40px;
    line-height: 25px;
    min-height: 40px; }

.sharp.btn-xs {
  padding: 0.4175rem;
  width: 1.625rem;
  height: 1.625rem;
  min-width: 1.625rem;
  min-height: 1.625rem; }

.btn-block {
  display: block;
  width: 100%; }

.light.tp-btn {
  background-color: transparent; }

.light.btn-default {
  background: #ADB6C7; }

.light.btn-success {
  background-color: #A7FFEA;
  border-color: #A7FFEA;
  color: var(--text-dark); }
  [data-theme-version="dark"] .light.btn-success {
    background-color: #A7FFEA;
    border-color: transparent; }
  .light.btn-success:hover {
    background-color: #53D0B2;
    border-color: #53D0B2;
    color: #fff; }

.light.btn-info {
  background-color: #D7E3FF;
  border-color: #D7E3FF;
  color: var(--text-dark); }
  [data-theme-version="dark"] .light.btn-info {
    background-color: #D7E3FF;
    border-color: transparent; }
  .light.btn-info:hover {
    background-color: #81A5F9;
    border-color: #81A5F9;
    color: #fff; }

.light.btn-primary {
  background-color: var(--primary-light);
  border-color: var(--primary-light);
  color: var(--text-dark); }
  .light.btn-primary g [fill] {
    fill: black; }
  [data-theme-version="dark"] .light.btn-primary {
    background-color: var(--primary-light);
    border-color: transparent;
    color: black !important; }
  .light.btn-primary:hover {
    background-color: var(--primary);
    border-color: var(--primary);
    color: #fff; }

.light.btn-secondary {
  background-color: #DCDFE5;
  border-color: #DCDFE5;
  color: var(--text-dark); }
  [data-theme-version="dark"] .light.btn-secondary {
    background-color: #DCDFE5;
    border-color: transparent;
    color: black !important; }
  .light.btn-secondary:hover {
    background-color: #111828;
    border-color: #111828;
    color: #fff; }

.light.btn-warning {
  background-color: #FCEF73;
  border-color: #FCEF73;
  color: var(--text-dark); }
  [data-theme-version="dark"] .light.btn-warning {
    background-color: #FCEF73;
    border-color: transparent; }
  .light.btn-warning:hover {
    background-color: #F4CF0C;
    border-color: #F4CF0C;
    color: #fff; }

.light.btn-danger {
  background-color: #FFCCC0;
  border-color: #FFCCC0;
  color: var(--text-dark); }
  [data-theme-version="dark"] .light.btn-danger {
    background-color: #FFCCC0;
    border-color: transparent;
    color: black; }
  .light.btn-danger:hover {
    background-color: #FF7C7C;
    border-color: #FF7C7C;
    color: #fff; }

.light.btn-dark {
  background-color: #999999;
  border-color: #999999;
  color: var(--text-dark); }
  [data-theme-version="dark"] .light.btn-dark {
    background-color: rgba(51, 51, 51, 0.35);
    border-color: transparent;
    color: #fff; }
  .light.btn-dark:hover {
    background-color: #333333;
    border-color: #333333;
    color: #fff; }

.btn.tp-btn {
  background-color: transparent;
  border-color: transparent; }
  .btn.tp-btn.btn-default {
    background: #ADB6C7; }
  .btn.tp-btn.btn-success {
    color: #53D0B2; }
    .btn.tp-btn.btn-success:hover {
      background-color: #53D0B2;
      border-color: #53D0B2;
      color: #fff; }
  .btn.tp-btn.btn-info {
    color: #81A5F9; }
    .btn.tp-btn.btn-info g [fill] {
      fill: #81A5F9; }
    .btn.tp-btn.btn-info:hover {
      background-color: #81A5F9;
      border-color: #81A5F9;
      color: #fff; }
  .btn.tp-btn.btn-primary {
    color: var(--primary); }
    .btn.tp-btn.btn-primary g [fill] {
      fill: var(--primary); }
    .btn.tp-btn.btn-primary:hover {
      background-color: var(--primary);
      border-color: var(--primary);
      color: #fff; }
      .btn.tp-btn.btn-primary:hover svg path {
        fill: #fff; }
  .btn.tp-btn.btn-secondary {
    color: #111828; }
    .btn.tp-btn.btn-secondary g [fill] {
      fill: #111828; }
    .btn.tp-btn.btn-secondary:hover {
      background-color: #111828;
      border-color: #111828;
      color: #fff; }
  .btn.tp-btn.btn-warning {
    color: #F4CF0C; }
    .btn.tp-btn.btn-warning g [fill] {
      fill: #F4CF0C; }
    .btn.tp-btn.btn-warning:hover {
      background-color: #F4CF0C;
      border-color: #F4CF0C;
      color: #fff; }
  .btn.tp-btn.btn-danger {
    color: #FF7C7C; }
    .btn.tp-btn.btn-danger g [fill] {
      fill: #FF7C7C; }
    .btn.tp-btn.btn-danger:hover {
      background-color: #FF7C7C;
      border-color: #FF7C7C;
      color: #fff; }
  .btn.tp-btn.btn-light {
    color: #333333; }
    .btn.tp-btn.btn-light g [fill] {
      fill: #333333; }
    .btn.tp-btn.btn-light:hover {
      background-color: #EEEEEE;
      border-color: #EEEEEE;
      color: #333333; }
  .btn.tp-btn.btn-dark {
    color: #333333; }
    .btn.tp-btn.btn-dark g [fill] {
      fill: #333333; }
    .btn.tp-btn.btn-dark:hover {
      background-color: #333333;
      border-color: #333333;
      color: #fff; }

.btn.tp-btn-light {
  background-color: transparent;
  border-color: transparent; }
  .btn.tp-btn-light.btn-success {
    color: #53D0B2; }
    .btn.tp-btn-light.btn-success g [fill] {
      fill: #53D0B2; }
    .btn.tp-btn-light.btn-success:hover {
      background-color: #A7FFEA;
      border-color: #A7FFEA;
      color: var(--text-dark); }
  .btn.tp-btn-light.btn-info {
    color: #81A4F9; }
    .btn.tp-btn-light.btn-info g [fill] {
      fill: #81A5F9; }
    .btn.tp-btn-light.btn-info:hover {
      background-color: #D7E3FF;
      border-color: #D7E3FF;
      color: var(--text-dark); }
  .btn.tp-btn-light.btn-primary {
    color: var(--primary); }
    .btn.tp-btn-light.btn-primary g [fill] {
      fill: var(--primary); }
    .btn.tp-btn-light.btn-primary:hover {
      background-color: var(--primary-light);
      border-color: var(--primary-light);
      color: var(--text-dark); }
  .btn.tp-btn-light.btn-secondary {
    color: #111828; }
    .btn.tp-btn-light.btn-secondary g [fill] {
      fill: #111828; }
    .btn.tp-btn-light.btn-secondary:hover {
      background-color: #DCDFE5;
      border-color: #DCDFE5;
      color: var(--text-dark); }
  .btn.tp-btn-light.btn-warning {
    color: #F4CF0C; }
    .btn.tp-btn-light.btn-warning g [fill] {
      fill: #F4CF0C; }
    .btn.tp-btn-light.btn-warning:hover {
      background-color: #FCEF73;
      border-color: #FCEF73;
      color: var(--text-dark); }
  .btn.tp-btn-light.btn-danger {
    color: #FF7C7C; }
    .btn.tp-btn-light.btn-danger g [fill] {
      fill: #FF7C7C; }
    .btn.tp-btn-light.btn-danger:hover {
      background-color: #FFCCC0;
      border-color: #FFCCC0;
      color: var(--text-dark); }
  .btn.tp-btn-light.btn-dark {
    color: #333333; }
    .btn.tp-btn-light.btn-dark g [fill] {
      fill: #333333; }
    .btn.tp-btn-light.btn-dark:hover {
      background-color: #999999;
      border-color: #999999;
      color: var(--text-dark); }

.shadow.btn-primary {
  -webkit-box-shadow: 0 0.3125rem 0.9375rem 0 var(--rgba-primary-2) !important;
  box-shadow: 0 0.3125rem 0.9375rem 0 var(--rgba-primary-2) !important; }

.shadow.btn-secondary {
  -webkit-box-shadow: 0 0.3125rem 0.9375rem 0 rgba(17, 24, 40, 0.2) !important;
  box-shadow: 0 0.3125rem 0.9375rem 0 rgba(17, 24, 40, 0.2) !important; }

.shadow.btn-warning {
  -webkit-box-shadow: 0 0.3125rem 0.9375rem 0 rgba(244, 207, 12, 0.2) !important;
  box-shadow: 0 0.3125rem 0.9375rem 0 rgba(244, 207, 12, 0.2) !important; }

.shadow.btn-danger {
  -webkit-box-shadow: 0 0.3125rem 0.9375rem 0 rgba(255, 124, 124, 0.2) !important;
  box-shadow: 0 0.3125rem 0.9375rem 0 rgba(255, 124, 124, 0.2) !important; }

.shadow.btn-info {
  -webkit-box-shadow: 0 0.3125rem 0.9375rem 0 rgba(129, 165, 249, 0.2) !important;
  box-shadow: 0 0.3125rem 0.9375rem 0 rgba(129, 165, 249, 0.2) !important; }

.shadow.btn-success {
  -webkit-box-shadow: 0 0.3125rem 0.9375rem 0 rgba(83, 208, 178, 0.2) !important;
  box-shadow: 0 0.3125rem 0.9375rem 0 rgba(83, 208, 178, 0.2) !important; }

.btn-xxs {
  padding: 0.375rem 0.9375rem;
  font-size: 0.6875rem;
  line-height: 1.3;
  border-radius: 0.25rem; }

.btn-sm, .btn-group-sm > .btn {
  font-size: 0.75rem;
  padding: 0.438rem 1rem;
  font-weight: 600; }

.btn-xs {
  font-size: 0.813rem  !important;
  padding: 5px 12px;
  font-weight: 400;
  border-radius: 0.625rem;
  line-height: 18px; }

.btn-md {
  font-size: 0.875rem  !important;
  padding: 0.875rem 1.25rem; }

.btn-lg, .btn-group-lg > .btn {
  padding: 1rem 2rem;
  font-size: 1.125rem !important; }
  @media only screen and (max-width: 35.9375rem) {
    .btn-lg, .btn-group-lg > .btn {
      padding: 0.75rem 1.25rem; } }

.btn-xl {
  padding: 0.6rem 1rem; }
  .btn-xl.btn-default {
    font-weight: 600; }

.btn-square {
  border-radius: 0; }

.btn-rounded {
  border-radius: 2.5rem !important; }

.btn-icon-end {
  border-left: 0.0625rem solid #EEEEEE;
  display: inline-block;
  margin: -.8rem 0 -.8rem 1rem;
  padding: 0.4375rem 0 0.4375rem 1rem;
  margin: -1rem -0.25rem -1rem 1rem;
  padding: 0.8rem 0 0.8rem 1.25rem; }

.btn-icon-start {
  background: #fff;
  border-radius: 0.425rem;
  display: inline-block;
  margin: -0.3rem 0.35rem -0.3rem -0.788rem;
  padding: 7px 8px;
  line-height: 0px;
  float: left; }
  @media only screen and (max-width: 87.5rem) {
    .btn-icon-start {
      margin: -0.3rem 0.75rem -0.5rem -0.5rem; } }

[direction="rtl"] .btn-icon-start {
  margin: -.5rem 0.5rem -0.5rem -1rem; }
  @media only screen and (max-width: 87.5rem) {
    [direction="rtl"] .btn-icon-start {
      margin: -0.5rem 0.75rem -0.5rem -0.880rem; } }

[direction="rtl"] .btn-icon-end {
  border-left: 0rem solid white;
  display: inline-block;
  margin: -.8rem 1rem -.8rem 0;
  padding: 0.4375rem 1rem 0.4375rem 0;
  border-right: 0.0625rem solid white; }

.toggle-dropdown::after {
  margin-left: 0.755em; }

.social-btn-icon .btn {
  min-width: 7.5rem;
  margin-bottom: 1.5rem; }

.social-icon .btn {
  padding: .7rem 1.4rem; }

.btn-circle {
  height: 5rem;
  width: 5rem;
  border-radius: 50% !important; }
  .btn-circle-sm {
    width: 4.5rem;
    height: 4.5rem;
    font-size: 1.8rem; }
  .btn-circle-md {
    width: 6rem;
    height: 6rem;
    font-size: 2.5rem; }
    .btn-circle-md i {
      font-size: 2.4rem; }
  .btn-circle-lg {
    width: 8rem;
    height: 8rem;
    font-size: 3.2rem; }
    .btn-circle-lg i {
      font-size: 3.1rem; }

.btn-page .btn {
  margin-right: 0.25rem;
  margin-bottom: 0.5rem; }

.size-1 {
  min-width: 10rem !important;
  font-size: 1.5rem;
  padding: 0.68rem 0.75rem; }

.size-2 {
  font-size: 1.25rem;
  min-width: 8.125rem !important;
  padding: 0.57rem 0.75rem; }

.size-3 {
  font-size: 0.875rem;
  min-width: 6.875rem !important;
  padding: 0.536rem 0.75rem; }

.size-4 {
  font-size: 0.875rem;
  min-width: 6.25rem !important; }

.size-5 {
  font-size: 0.875rem;
  min-width: 5.625rem !important;
  padding: .22rem 0.75rem; }

.size-6 {
  font-size: 0.8125rem;
  min-width: 5rem !important;
  padding: 0.097rem 0.75rem; }

.size-7 {
  font-size: 0.75rem;
  min-width: 3.75rem !important;
  padding: 0.001rem 0.75rem; }

.btn-light {
  background: #EEEEEE;
  border-color: #EEEEEE;
  color: var(--text-dark); }
  .btn-light:active, .btn-light:focus, .btn-light:hover {
    background: white;
    color: #333333;
    border-color: white; }

.btn-outline-primary:hover {
  color: #fff; }

.btn-outline-warning:hover {
  color: #fff; }

.btn-outline-light {
  color: #333333; }
  [data-theme-version="dark"] .btn-outline-light {
    color: #888888; }

[data-theme-version="dark"] .btn-outline-secondary {
  color: #888888; }

[data-theme-version="dark"] .btn-outline-dark {
  color: #888888; }

.btn-dark {
  background: #333333;
  border-color: #333333;
  color: #fff; }
  .btn-dark:active, .btn-dark:hover {
    background: #4d4d4d;
    color: #fff;
    border-color: #1a1a1a; }
  .btn-dark:focus {
    background: #4d4d4d; }

.btn-group.btn-rounded .btn:first-child {
  border-top-left-radius: 1.875rem;
  border-bottom-left-radius: 1.875rem; }

.btn-group.btn-rounded .btn:last-child {
  border-top-right-radius: 1.875rem;
  border-bottom-right-radius: 1.875rem; }

.btn-outline-success:hover,
.btn-outline-danger:hover,
.btn-outline-info:hover {
  color: #fff; }

.btn-facebook {
  background: #3b5998;
  border-color: #3b5998;
  color: #fff; }
  .btn-facebook:active, .btn-facebook:focus, .btn-facebook:hover {
    background: #2d4373;
    color: #fff;
    border-color: #2d4373; }

.btn-twitter {
  background: #1da1f2;
  border-color: #1da1f2;
  color: #fff; }
  .btn-twitter:active, .btn-twitter:focus, .btn-twitter:hover {
    background: #0c85d0;
    color: #fff;
    border-color: #0c85d0; }

.btn-youtube {
  background: #FF0000;
  border-color: #FF0000;
  color: #fff; }
  .btn-youtube:active, .btn-youtube:focus, .btn-youtube:hover {
    background: #cc0000;
    color: #fff;
    border-color: #cc0000; }

.btn-instagram {
  background: #c32aa3;
  border-color: #c32aa3;
  color: #fff; }
  .btn-instagram:active, .btn-instagram:focus, .btn-instagram:hover {
    background: #992180;
    color: #fff;
    border-color: #992180; }

.btn-pinterest {
  background: #bd081c;
  border-color: #bd081c;
  color: #fff; }
  .btn-pinterest:active, .btn-pinterest:focus, .btn-pinterest:hover {
    background: #8c0615;
    color: #fff;
    border-color: #8c0615; }

.btn-linkedin {
  background: #007bb6;
  border-color: #007bb6;
  color: #fff; }
  .btn-linkedin:active, .btn-linkedin:focus, .btn-linkedin:hover {
    background: #005983;
    color: #fff;
    border-color: #005983; }

.btn-google-plus {
  background: #db4439;
  border-color: #db4439;
  color: #fff; }
  .btn-google-plus:active, .btn-google-plus:focus, .btn-google-plus:hover {
    background: #be2d23;
    color: #fff;
    border-color: #be2d23; }

.btn-google {
  background: #4285f4;
  border-color: #4285f4;
  color: #fff; }
  .btn-google:active, .btn-google:focus, .btn-google:hover {
    background: #1266f1;
    color: #fff;
    border-color: #1266f1; }

.btn-snapchat {
  background: #fffc00;
  border-color: #fffc00;
  color: #000; }
  .btn-snapchat:active, .btn-snapchat:focus, .btn-snapchat:hover {
    background: #ccca00;
    color: #000;
    border-color: #ccca00; }

.btn-whatsapp {
  background: #25d366;
  border-color: #25d366;
  color: #fff; }
  .btn-whatsapp:active, .btn-whatsapp:focus, .btn-whatsapp:hover {
    background: #1da851;
    color: #fff;
    border-color: #1da851; }

.btn-tumblr {
  background: #35465d;
  border-color: #35465d;
  color: #fff; }
  .btn-tumblr:active, .btn-tumblr:focus, .btn-tumblr:hover {
    background: #222e3d;
    color: #fff;
    border-color: #222e3d; }

.btn-reddit {
  background: #ff4500;
  border-color: #ff4500;
  color: #fff; }
  .btn-reddit:active, .btn-reddit:focus, .btn-reddit:hover {
    background: #cc3700;
    color: #fff;
    border-color: #cc3700; }

.btn-spotify {
  background: #1ed760;
  border-color: #1ed760;
  color: #fff; }
  .btn-spotify:active, .btn-spotify:focus, .btn-spotify:hover {
    background: #18aa4c;
    color: #fff;
    border-color: #18aa4c; }

.btn-yahoo {
  background: #430297;
  border-color: #430297;
  color: #fff; }
  .btn-yahoo:active, .btn-yahoo:focus, .btn-yahoo:hover {
    background: #2d0165;
    color: #fff;
    border-color: #2d0165; }

.btn-dribbble {
  background: #ea4c89;
  border-color: #ea4c89;
  color: #fff; }
  .btn-dribbble:active, .btn-dribbble:focus, .btn-dribbble:hover {
    background: #e51e6b;
    color: #fff;
    border-color: #e51e6b; }

.btn-skype {
  background: #00aff0;
  border-color: #00aff0;
  color: #fff; }
  .btn-skype:active, .btn-skype:focus, .btn-skype:hover {
    background: #008abd;
    color: #fff;
    border-color: #008abd; }

.btn-quora {
  background: #aa2200;
  border-color: #aa2200;
  color: #fff; }
  .btn-quora:active, .btn-quora:focus, .btn-quora:hover {
    background: #771800;
    color: #fff;
    border-color: #771800; }

.btn-vimeo {
  background: #1ab7ea;
  border-color: #1ab7ea;
  color: #fff; }
  .btn-vimeo:active, .btn-vimeo:focus, .btn-vimeo:hover {
    background: #1295bf;
    color: #fff;
    border-color: #1295bf; }

.btn-check:checked + .btn-outline-primary,
.btn-check:active + .btn-outline-primary,
.btn-outline-primary:active,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show {
  background-color: var(--primary-light);
  border-color: var(--primary-light);
  color: black; }

.btn-check:checked + .btn-outline-primary:focus,
.btn-check:active + .btn-outline-primary:focus,
.btn-outline-primary:active:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem var(--rgba-primary-5); }

.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem var(--rgba-primary-5); }

.btn-close:focus {
  box-shadow: none; }

.asColorPicker-trigger {
  background: none; }

.dz-card .nav-link {
  padding: 5px 12px;
  border-radius: 0.625rem; }

.btn.btn-icon-lg {
  padding: 1rem 1.2rem; }

.btn.btn-icon-md {
  padding: 0.719rem 0.919rem; }

.btn.btn-icon-sm {
  padding: 0.438rem 0.6rem; }

.btn.btn-icon-xs {
  padding: 0.375rem 0.5375rem; }

.btn.btn-icon-xxs {
  padding: 0.275rem 0.4375rem; }

.btn-group-light button {
  background-color: var(--primary-light);
  border-color: var(--primary-light);
  color: var(--text-dark); }
  .btn-group-light button:hover {
    background-color: var(--primary);
    border-color: var(--primary);
    color: #fff; }

.card {
  margin-bottom: 20px;
  background-color: #fff;
  transition: all .5s ease-in-out;
  position: relative;
  border: 0rem solid transparent;
  border-radius: 0.625rem;
  height: calc(100% - 20px);
  border: 1px solid #000000; }
  @media only screen and (max-width: 35.9375rem) {
    .card {
      margin-bottom: 0.938rem;
      height: calc(100% - 0.938rem); } }
  .card-body {
    padding: 1.25rem; }
    @media only screen and (max-width: 35.9375rem) {
      .card-body {
        padding: 1rem; } }
  .card-title {
    font-size: 1rem;
    font-weight: 700;
    line-height: 28px;
    color: #252525;
    text-transform: capitalize; }
    .card-title--large {
      font-size: 1.5rem; }
    .card-title--medium {
      font-size: 1rem; }
    .card-title--small {
      font-size: 0.875rem; }
  .card-header {
    border-color: #000000;
    position: relative;
    background: transparent;
    padding: 11px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    [data-theme-version="dark"] .card-header {
      border-color: #444444; }
    .card-header .card-title {
      margin-bottom: 0rem; }
    .card-header .subtitle {
      padding-top: 0.3125rem;
      font-size: 0.875rem;
      line-height: 1.5; }
  .card-footer {
    border-color: #000000;
    background: transparent;
    padding: 1rem 1.25rem 1rem; }
    [data-theme-version="dark"] .card-footer {
      border-color: #444444; }
    @media only screen and (max-width: 35.9375rem) {
      .card-footer {
        padding: 1.25rem 1rem 1.25rem; } }

.transparent-card.card {
  background: transparent;
  border: 0.0625rem solid transparent;
  box-shadow: none; }

.card-action > a {
  display: inline-block;
  width: 1.875rem;
  height: 1.875rem;
  line-height: 1.875rem;
  border-radius: 0.3125rem;
  border-color: transparent;
  text-align: center;
  background: var(--primary-dark);
  color: #fff;
  margin-right: 0.5rem; }
  [data-theme-version="dark"] .card-action > a {
    background: #1E1E1E; }
  .card-action > a:last-child {
    margin-right: 0; }
  .card-action > a:hover, .card-action > a:focus {
    background: var(--primary-dark); }
    [data-theme-version="dark"] .card-action > a:hover, [data-theme-version="dark"] .card-action > a:focus {
      background: #1E1E1E; }
  .card-action > a i, .card-action > a span {
    font-size: 1rem; }

.card-action .dropdown {
  width: 1.875rem;
  height: 1.875rem;
  border-radius: 0.3125rem;
  border-color: transparent;
  text-align: center;
  margin-right: 0.5rem;
  top: -0.125rem;
  position: relative;
  display: inline-block;
  background: var(--primary-dark);
  color: var(--primary); }
  [data-theme-version="dark"] .card-action .dropdown {
    background: #1E1E1E; }
  .card-action .dropdown:hover, .card-action .dropdown:focus {
    background: var(--primary-dark); }
    [data-theme-version="dark"] .card-action .dropdown:hover, [data-theme-version="dark"] .card-action .dropdown:focus {
      background: #1E1E1E; }
  .card-action .dropdown .btn {
    padding: 0;
    line-height: 1.6875rem;
    color: #fff; }
    .card-action .dropdown .btn:focus {
      box-shadow: none; }

.card-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999999;
  overflow: auto; }

.card-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 999; }

.card-loader i {
  margin: 0 auto;
  background: var(--primary-dark);
  padding: 0.625rem;
  border-radius: 50%;
  color: #fff;
  font-size: 1rem; }

.rotate-refresh {
  -webkit-animation: mymove 0.8s infinite linear;
  animation: mymove 0.8s infinite linear;
  display: inline-block; }

.card-header .date_picker {
  display: inline-block;
  padding: 0.5rem;
  border: 0.0625rem solid #E6E6E6;
  cursor: pointer;
  border-radius: .375rem; }

.card-header .border-0 {
  padding-bottom: 0; }

@-webkit-keyframes mymove {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes mymove {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.carousel-custom-prev, .carousel-custom-next {
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  display: inline-block;
  border-radius: 50%;
  background: #fff;
  text-align: center; }
  .carousel-custom-prev i, .carousel-custom-next i {
    font-size: 1rem; }
  .carousel-custom-prev:hover, .carousel-custom-next:hover {
    background: linear-gradient(to right, rgba(245, 60, 121, 0.99) 0%, rgba(246, 104, 47, 0.99) 100%);
    color: #fff; }

.carousel-custom-next {
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%); }

.carousel-custom-prev {
  position: absolute;
  top: 50%;
  left: 30px;
  transform: translateY(-50%); }

.carousel-caption {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.78);
  z-index: 1;
  background: rgba(0, 0, 0, 0.6); }
  .carousel-caption h5, .carousel-caption .h5 {
    color: #fff;
    font-size: 1.8rem; }
  .carousel-caption p {
    margin-bottom: 0; }

.carousel-indicators {
  z-index: 1; }

.dropdown-toggle:focus {
  box-shadow: none !important; }

.dropdown-outline {
  border: 0.1rem solid var(--primary); }

.dropdown-menu {
  font-size: inherit;
  border: 0;
  z-index: 4;
  overflow: hidden;
  border-radius: 0.625rem;
  box-shadow: 0 0 3.125rem 0 rgba(82, 63, 105, 0.15);
  margin-top: 0; }
  .dropdown-menu .dropdown-item {
    font-size: 0.813rem;
    color: #888888;
    padding: 0.5rem 1.25rem; }
    @media only screen and (max-width: 87.5rem) {
      .dropdown-menu .dropdown-item {
        padding: 0.375rem 1rem;
        font-size: 0.875rem; } }
    .dropdown-menu .dropdown-item:hover, .dropdown-menu .dropdown-item:focus, .dropdown-menu .dropdown-item:active, .dropdown-menu .dropdown-item.active {
      color: var(--text-dark);
      background: var(--primary-light); }
    .dropdown-menu .dropdown-item.active, .dropdown-menu .dropdown-item:active {
      color: var(--text-dark);
      background: var(--primary-light); }
  [direction="rtl"] .dropdown-menu {
    right: auto !important; }

.dropdown-toggle-split {
  padding: 0 0.625rem;
  opacity: 0.85; }
  .dropdown-toggle-split:after {
    margin-left: 0 !important; }
  .dropdown-toggle-split:active, .dropdown-toggle-split:focus, .dropdown-toggle-split:hover {
    opacity: 1; }

/* .dropleft .dropdown-toggle:before,
.dropright .dropdown-toggle:before,
.dropup .dropdown-toggle:after,
.dropdown-toggle:after {
	content:"\f107";
	font-family:FontAwesome;
	border: 0;
    vertical-align: middle;
    margin-left: 0.25em;
	line-height: 1;
} */
/* .dropup .dropdown-toggle:after{
	content:"\f106";
} */
/* .dropleft .dropdown-toggle::before {
	content:"\f104";
}
.dropright .dropdown-toggle::before {
	content:"\f105";
} */
.dropright .dropdown-toggle::after {
  content: none; }

.custom-dropdown {
  display: inline-block;
  margin-bottom: 1rem; }
  .custom-dropdown .dropdown-menu {
    border: 0rem;
    min-width: 10rem; }

.card-action .custom-dropdown {
  margin: 0rem;
  background: var(--rgba-primary-1); }
  .card-action .custom-dropdown.show, .card-action .custom-dropdown:focus, .card-action .custom-dropdown:hover {
    background: var(--primary);
    color: #fff; }
  .card-action .custom-dropdown i {
    display: inline-block;
    padding-top: 0.5625rem; }

.dropdown .dropdown-dots {
  position: relative;
  height: 0.3125rem;
  width: 0.3125rem;
  background: rgba(51, 51, 51, 0.4);
  border-radius: 0.3125rem;
  display: block; }
  .dropdown .dropdown-dots:after, .dropdown .dropdown-dots:before {
    content: "";
    height: 0.3125rem;
    width: 0.3125rem;
    background: rgba(51, 51, 51, 0.4);
    position: absolute;
    border-radius: 0.3125rem; }
  .dropdown .dropdown-dots:after {
    right: -0.5rem; }
  .dropdown .dropdown-dots:before {
    left: -0.5rem; }
  .dropdown .dropdown-dots.text-white {
    background: rgba(255, 255, 255, 0.7); }
    .dropdown .dropdown-dots.text-white:after, .dropdown .dropdown-dots.text-white:before {
      background: rgba(255, 255, 255, 0.7); }

.grid-col {
  padding: 0.5rem !important;
  background: #f4f4f5; }

.row.grid {
  margin-bottom: 1.5rem;
  text-align: center; }

.row.grid .grid-col:first-child {
  text-align: left; }

.label {
  display: inline-block;
  text-align: center;
  font-size: 0.75rem;
  padding: .2rem .8rem;
  border-radius: 0.5rem; }
  .label-fixed {
    width: 7.5rem;
    padding: .6rem 0; }
    .label-fixed-lg {
      width: 9.5rem;
      padding: .6rem 0; }
  .label-big {
    width: 16.8rem;
    font-size: 1.4rem;
    padding: 1.1rem 0; }
  .label-xl {
    width: 10.5rem;
    padding: 1.1rem 0;
    font-size: 1.5rem; }
  .label-lg {
    width: 9.5rem;
    padding: 1.1rem 0; }
  .label-md {
    width: 8.5rem;
    padding: 1.1rem 0; }
  .label-sm {
    width: 7.5rem;
    padding: 1.1rem 0; }

.label-default {
  background: #ADB6C7; }

.label-primary {
  background: var(--primary);
  color: #fff; }

.label-secondary {
  background: #111828;
  color: #fff; }

.label-info {
  background: #81A5F9;
  color: #fff; }

.label-success {
  background: #53D0B2;
  color: #fff; }

.label-warning {
  background: #F4CF0C;
  color: #fff; }

.label-danger {
  background: #FF7C7C;
  color: #fff; }

.label-light {
  background: #EEEEEE;
  color: #000; }

.label-dark {
  background: #333333;
  color: #fff; }

code {
  word-break: break-word;
  padding: 0.125rem 0.3125rem;
  border-radius: 0.1875rem;
  background: white;
  color: #FF7C7C; }
  [data-theme-version="dark"] code {
    background: rgba(255, 124, 124, 0.1); }

.heading-labels {
  color: #333333; }
  .heading-labels > * {
    margin-bottom: .8rem; }
  .heading-labels h1 .label, .heading-labels .h1 .label {
    font-size: 1.125rem;
    font-weight: normal;
    padding: .4rem .9rem; }
  .heading-labels h2 .label, .heading-labels .h2 .label {
    font-size: 1rem;
    font-weight: normal;
    padding: 0.3rem .9rem; }
  .heading-labels h3 .label, .heading-labels .h3 .label {
    font-size: 0.875rem;
    font-weight: normal; }
  .heading-labels h4 .label, .heading-labels .h4 .label {
    font-size: 0.75rem;
    font-weight: normal; }
  .heading-labels h5 .label, .heading-labels .h5 .label {
    font-size: 0.75rem;
    font-weight: normal; }
  .heading-labels h6 .label, .heading-labels .h6 .label {
    font-size: 0.75rem;
    font-weight: normal; }

.list-group-item {
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #cdcdcd;
  padding: 1rem 1.5rem; }
  .list-group-item.active {
    background-color: var(--primary-light);
    border-color: var(--primary-light); }
  [data-theme-version="dark"] .list-group-item {
    border-color: #444444; }

.list-group-item.disabled, .list-group-item:disabled {
  color: #fff;
  background-color: #111828;
  border-color: #111828; }

[class*="bg-"] .list-group-item {
  border-color: rgba(255, 255, 255, 0.05); }

.bg-warning .list-group-item {
  border-color: rgba(0, 0, 0, 0.05); }

.swal2-popup .swal2-image {
  max-width: 15%; }

/*  */
.vertical-card__menu:hover {
  box-shadow: none; }

.vertical-card__menu--image {
  text-align: center; }
  .vertical-card__menu--image img {
    width: 100%;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }

.vertical-card__menu--status.closed {
  background: #FF7C7C; }
  .vertical-card__menu--status.closed:after {
    border-top: 0.625rem solid #FF7C7C; }
  .vertical-card__menu--status.closed .ribbon-curve {
    border-top: 0.6875rem solid #FF7C7C;
    border-bottom: 0.625rem solid #FF7C7C; }

.vertical-card__menu--desc p {
  margin-bottom: 0.625rem; }

.vertical-card__menu--price {
  font-size: 1.25rem;
  margin: 0;
  font-weight: 700; }

.vertical-card__menu--rating {
  font-size: 0.75rem; }

.vertical-card__menu--title {
  font-weight: 700; }

.vertical-card__menu--button button {
  font-size: 0.75rem; }

.vertical-card__menu .card-footer {
  padding: 0.9375rem 1.25rem;
  background-color: #E6E6E6;
  border-top: none; }
  @media only screen and (min-width: 75rem) and (max-width: 90rem) {
    .vertical-card__menu .card-footer {
      padding: 0.9375rem; } }
  .vertical-card__menu .card-footer span {
    color: #333333;
    margin-right: 0.3125rem; }

.horizontal-card__menu {
  box-shadow: 0 0 0.4375rem rgba(173, 173, 173, 0.32);
  display: flex;
  flex-direction: row; }
  @media (max-width: 575.98px) {
    .horizontal-card__menu {
      display: block; } }
  .horizontal-card__menu .card-body {
    padding: 0;
    padding: 1.25rem 1.875rem; }
  .horizontal-card__menu--image {
    flex-basis: 30%;
    max-width: 30%; }
    .horizontal-card__menu--image img {
      height: 100%;
      width: 100%; }
    @media (max-width: 575.98px) {
      .horizontal-card__menu--image {
        max-width: unset;
        flex-basis: 100%; } }
  .horizontal-card__menu--title {
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 0.3125rem; }
  .horizontal-card__menu--fav {
    margin-right: 0.5rem; }
  .horizontal-card__menu--price {
    margin: 0;
    font-size: 1rem;
    font-weight: 700; }
  .horizontal-card__menu--rating {
    font-size: 0.625rem; }
  .horizontal-card__menu--footer {
    margin-top: 0.625rem; }

.prev_price {
  text-decoration: line-through;
  font-size: 80%;
  opacity: 0.5; }

.modal-header {
  padding: 1rem 1.875rem; }
  .modal-header .close {
    padding: 0.875rem 1.815rem;
    margin: 0;
    position: absolute;
    right: 0;
    float: none;
    top: 0;
    font-size: 1.875rem;
    font-weight: 100; }

.modal-body {
  padding: 1.875rem; }

.modal-footer {
  padding: 1rem 1.875rem; }

.modal-content {
  border-radius: 0.625rem; }

.modal-backdrop {
  z-index: 10 !important; }

.modal-dialog-center {
  min-height: calc(100% - 3.5rem);
  display: flex;
  align-items: center;
  justify-content: center; }

.pagination .page-item .page-link:hover {
  background: var(--primary);
  border-color: var(--primary);
  color: #fff; }

.pagination .page-item.active .page-link {
  background: var(--primary);
  border-color: var(--primary);
  color: #fff; }

.pagination {
  margin-bottom: 1.25rem; }
  .pagination.page-indicator-light .page-item.active .page-link {
    background-color: var(--primary-light);
    border-color: var(--primary-light);
    color: var(--text-dark); }
  .pagination .page-item.page-indicator .page-link {
    padding: .69rem .8rem;
    font-size: 1.2rem;
    border-top-left-radius: unset;
    border-bottom-left-radius: unset;
    border-radius: 0.625rem; }
  .pagination .page-item.page-indicator:hover .page-link {
    color: #333333; }
  .pagination .page-item .page-link {
    z-index: 1;
    padding: 0;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 0.625rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    background: rgba(255, 255, 255, 0.15);
    color: #333333;
    margin-left: 7px;
    border: 0.0625rem solid #E6E6E6; }
    [data-theme-version="dark"] .pagination .page-item .page-link {
      border-color: #444444;
      color: #828690;
      background: rgba(255, 255, 255, 0); }
    .pagination .page-item .page-link:hover i, .pagination .page-item .page-link span {
      color: #fff; }
    .pagination .page-item .page-link:focus {
      outline: 0;
      box-shadow: none; }
    .pagination .page-item .page-link:hover {
      background: var(--primary);
      color: #fff;
      border-color: var(--primary); }
    @media only screen and (max-width: 35.9375rem) {
      .pagination .page-item .page-link {
        width: 2.2rem;
        height: 2.2rem; } }
  .pagination .page-item.active .page-link {
    background-color: var(--primary);
    border-color: var(--primary);
    color: #fff; }
    [data-theme-version="dark"] .pagination .page-item.active .page-link {
      color: #fff; }
  .pagination .page-item .page-link {
    color: #333333;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s; }
  .pagination .page-item:last-child .page-link {
    margin-right: 0; }
  [direction="rtl"] .pagination .page-item:first-child .page-link {
    margin-right: 0; }
  .pagination.no-bg li:not(.page-indicator):not(.active) .page-link {
    background: #fff;
    border-color: transparent; }
    [data-theme-version="dark"] .pagination.no-bg li:not(.page-indicator):not(.active) .page-link {
      background: #2c2c2c; }
  .pagination.no-bg.pagination-primary li:not(.page-indicator):not(.active):hover .page-link {
    background: var(--primary);
    border-color: var(--primary); }
    [data-theme-version="dark"] .pagination.no-bg.pagination-primary li:not(.page-indicator):not(.active):hover .page-link {
      background: var(--primary);
      border-color: var(--primary); }
  .pagination.no-bg.pagination-danger li:not(.page-indicator):not(.active):hover .page-link {
    background: #FF7C7C;
    border-color: #FF7C7C; }
    [data-theme-version="dark"] .pagination.no-bg.pagination-danger li:not(.page-indicator):not(.active):hover .page-link {
      background: #FF7C7C;
      border-color: #FF7C7C; }
  .pagination.no-bg.pagination-info li:not(.page-indicator):not(.active):hover .page-link {
    background: #81A5F9;
    border-color: #81A5F9; }
    [data-theme-version="dark"] .pagination.no-bg.pagination-info li:not(.page-indicator):not(.active):hover .page-link {
      background: #81A5F9;
      border-color: #81A5F9; }
  .pagination.no-bg.pagination-warning li:not(.page-indicator):not(.active):hover .page-link {
    background: #F4CF0C;
    border-color: #F4CF0C; }
    [data-theme-version="dark"] .pagination.no-bg.pagination-warning li:not(.page-indicator):not(.active):hover .page-link {
      background: #F4CF0C;
      border-color: #F4CF0C; }
  .pagination-primary .page-item .page-link {
    background: var(--rgba-primary-1);
    border-color: var(--rgba-primary-1);
    color: #888888; }
    [data-theme-version="dark"] .pagination-primary .page-item .page-link {
      background: var(--rgba-primary-1);
      border-color: transparent;
      color: var(--primary); }
  .pagination-primary .page-item:hover .page-link, .pagination-primary .page-item.active .page-link {
    background: var(--primary);
    border-color: var(--primary); }
    [data-theme-version="dark"] .pagination-primary .page-item:hover .page-link, [data-theme-version="dark"] .pagination-primary .page-item.active .page-link {
      color: black !important; }
  .pagination-danger .page-item .page-link {
    background: #FFCCC0;
    border-color: #FFCCC0;
    color: var(--text-dark); }
    [data-theme-version="dark"] .pagination-danger .page-item .page-link {
      background: rgba(255, 124, 124, 0.15);
      border-color: transparent;
      color: #FF7C7C; }
  .pagination-danger .page-item:hover .page-link, .pagination-danger .page-item.active .page-link {
    background: #FF7C7C;
    border-color: #FF7C7C;
    box-shadow: 0 0.625rem 1.25rem 0rem rgba(255, 124, 124, 0.2); }
    [data-theme-version="dark"] .pagination-danger .page-item:hover .page-link, [data-theme-version="dark"] .pagination-danger .page-item.active .page-link {
      color: #fff; }
  .pagination-info .page-item .page-link {
    background: #D7E3FF;
    border-color: #D7E3FF;
    color: var(--text-dark); }
    [data-theme-version="dark"] .pagination-info .page-item .page-link {
      background: rgba(129, 165, 249, 0.1);
      border-color: transparent;
      color: #81A5F9; }
  .pagination-info .page-item:hover .page-link, .pagination-info .page-item.active .page-link {
    background: #81A5F9;
    border-color: #81A5F9;
    box-shadow: 0 0.625rem 1.25rem 0rem rgba(129, 165, 249, 0.2); }
  .pagination-warning .page-item .page-link {
    background: #FCEF73;
    border-color: #FCEF73;
    color: var(--text-dark); }
    [data-theme-version="dark"] .pagination-warning .page-item .page-link {
      background: rgba(244, 207, 12, 0.1);
      border-color: transparent;
      color: #F4CF0C; }
  .pagination-warning .page-item:hover .page-link, .pagination-warning .page-item.active .page-link {
    background: #FCEF73;
    border-color: #FCEF73;
    box-shadow: 0 0.625rem 1.25rem 0rem rgba(244, 207, 12, 0.2); }
  .pagination-gutter .page-item {
    margin-right: 0.4375rem; }
    .pagination-gutter .page-item .page-link {
      border-radius: 0.625rem; }
  .pagination-circle .page-item {
    margin-right: 0.4375rem; }
    .pagination-circle .page-item .page-link, .pagination-circle .page-item .page-link {
      width: 2.5rem;
      height: 2.5rem;
      padding: 0;
      line-height: 2.5rem;
      border-radius: 50% !important;
      padding: 0; }
      @media only screen and (max-width: 35.9375rem) {
        .pagination-circle .page-item .page-link, .pagination-circle .page-item .page-link {
          width: 2rem;
          height: 2rem; } }
    .pagination-circle .page-item.page-indicator .page-link {
      width: 2.5rem;
      border: 1px solid var(--primary);
      border-radius: 22px !important;
      line-height: 1.3rem;
      height: 2.5rem;
      background: var(--rgba-primary-1);
      color: var(--primary); }
      .pagination-circle .page-item.page-indicator .page-link:hover {
        color: #fff;
        background: var(--primary); }
      @media only screen and (max-width: 35.9375rem) {
        .pagination-circle .page-item.page-indicator .page-link {
          width: 2rem;
          height: 2rem; } }
  .pagination.pagination-md .page-item .page-link {
    width: 1.875rem;
    height: 1.875rem;
    line-height: 1.875rem;
    font-size: 0.875rem; }
  .pagination.pagination-sm .page-item.page-indicator .page-link {
    font-size: 0.75rem; }
  .pagination.pagination-sm .page-item .page-link {
    padding: 0;
    width: 1.875rem;
    height: 1.875rem;
    line-height: 1.875rem;
    font-size: 1rem;
    border-radius: 0.625rem; }
  .pagination.pagination-xs .page-item.page-indicator .page-link {
    font-size: 0.625rem; }
  .pagination.pagination-xs .page-item .page-link {
    padding: 0;
    width: 24px;
    height: 24px;
    line-height: 24px;
    font-size: 0.75rem;
    border-radius: 0.375rem; }

.card-para-card {
  color: var(--secondary); }

.popover {
  border: 0.125rem solid #111828;
  min-width: 13.125rem;
  box-shadow: 0 0 1.875rem 0 rgba(0, 0, 0, 0.1); }
  [data-theme-version="dark"] .popover {
    background-color: #1E1E1E; }
  .popover-header {
    background: #111828;
    color: #fff;
    font-weight: 300; }
    .popover-header::before {
      border-bottom: 0 !important; }
  .popover-body {
    font-size: 0.75rem; }
  [data-theme-version="dark"] .popover .popover-header {
    border-color: #2c2c2c; }
  @media only screen and (max-width: 47.9375rem) {
    .popover {
      z-index: 1; } }

.bootstrap-popover-wrapper .bootstrap-popover:not(:last-child) {
  margin-right: 0.5rem; }

.bootstrap-popover-wrapper .bootstrap-popover {
  margin-bottom: .5rem; }
  .bootstrap-popover-wrapper .bootstrap-popover button:hover, .bootstrap-popover-wrapper .bootstrap-popover button:focus {
    background: var(--primary);
    color: #fff;
    box-shadow: none; }

.bs-popover-top .arrow::after, .bs-popover-auto[data-popper-placement^="top"] .arrow::after, .bs-popover-auto[x-placement^="top"] .arrow::after {
  bottom: 0rem;
  border-top-color: #111828; }

.bs-popover-left .arrow::after, .bs-popover-auto[x-placement^="left"] .arrow::after {
  right: 0rem;
  border-left-color: #111828; }

.bs-popover-right .arrow::after, .bs-popover-auto[x-placement^="right"] .arrow::after {
  left: 0rem;
  border-right-color: #111828; }

.bs-popover-bottom .arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] .arrow::after, .bs-popover-auto[x-placement^="bottom"] .arrow::after {
  top: 0rem;
  border-bottom-color: #111828; }

.note-popover {
  display: none; }

.toltip-popover .tooltip-inner {
  background: #ffffff;
  padding: 0;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
  border: 0.125rem solid #111828; }
  .toltip-popover .tooltip-inner strong {
    color: #777777; }

.popover-header {
  background: var(--secondary);
  border-top-left-radius: 0.1rem;
  border-top-right-radius: 0.1rem;
  padding: 0.5rem; }

.progress-bar {
  background-color: var(--primary); }

.progress-bar-primary {
  background-color: var(--primary); }

.progress-bar-secondary {
  background-color: var(--secondary); }

.progress-bar-success {
  background-color: #53D0B2; }

.progress-bar-info {
  background-color: #81A5F9; }

.progress-bar-danger {
  background-color: #FF7C7C; }

.progress-bar-warning {
  background-color: #F4CF0C; }

.progress-bar-pink {
  background-color: #e83e8c; }

.progress {
  height: 14px;
  background-color: #f6f6f6;
  overflow: hidden; }
  [data-theme-version="dark"] .progress {
    background-color: #1E1E1E; }
  .progress-bar {
    border-radius: 0.625rem; }

.progress-vertical {
  display: inline-block;
  margin-bottom: 0;
  margin-right: 2rem;
  min-height: 17rem;
  position: relative; }
  @media (max-width: 991.98px) {
    .progress-vertical {
      margin-right: 1rem; } }

.progress-vertical-bottom {
  display: inline-block;
  margin-bottom: 0;
  margin-right: 2rem;
  min-height: 17rem;
  position: relative;
  transform: rotate(180deg); }
  @media (max-width: 991.98px) {
    .progress-vertical-bottom {
      margin-right: 1rem; } }

.progress-animated {
  animation-duration: 5s;
  animation-name: myanimation;
  transition: all 5s ease 0s; }

@keyframes myanimation {
  0% {
    width: 0; } }

@keyframes myanimation {
  0% {
    width: 0; } }

.ribbon {
  position: absolute;
  z-index: 1;
  text-transform: uppercase; }
  .ribbon__one {
    top: 0.9375rem;
    left: -0.6875rem;
    min-height: 1.25rem;
    min-width: 3.25rem;
    text-align: center;
    padding: 0.1875rem 0.625rem;
    background: #3AB54B;
    color: #fff;
    font-size: 0.625rem; }
    .ribbon__one::after {
      position: absolute;
      width: 0;
      height: 0;
      border-top: 0.625rem solid #239132;
      border-left: 0.6875rem solid transparent;
      left: 0;
      content: "";
      bottom: -0.625rem; }
  .ribbon__two {
    width: 3.125rem;
    height: 3.125rem;
    display: inline-block;
    background: #F4CF0C;
    line-height: 3.125rem;
    text-align: center;
    font-size: 1rem;
    color: #fff;
    right: 0.9375rem;
    top: 0.9375rem;
    border-radius: 0.1875rem; }
  .ribbon__three {
    left: -1.875rem;
    top: 0.875rem;
    width: 6.875rem;
    height: 1.5625rem;
    background-color: #FF7C7C;
    clip-path: polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%);
    transform: rotate(-45deg);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.875rem; }
  .ribbon__four {
    left: 0.5rem;
    top: -0.5rem;
    width: 6.875rem;
    height: 3.125rem;
    background-color: var(--primary);
    z-index: auto;
    color: #fff;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center; }
    .ribbon__four:after {
      right: -0.3125rem; }
    .ribbon__four:before {
      left: -0.3125rem; }
    .ribbon__four:after, .ribbon__four:before {
      z-index: -1;
      background-color: var(--rgba-primary-1);
      top: 0.1875rem;
      transform: rotate(45deg);
      content: "";
      height: 0.625rem;
      width: 0.625rem;
      position: absolute; }
  .ribbon__five {
    left: -1.875rem;
    top: 0.625rem;
    width: 6.875rem;
    height: 1.875rem;
    background-color: var(--primary);
    transform: rotate(-45deg);
    font-size: 0.75rem;
    color: #fff;
    padding-bottom: 0.3125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem; }
    .ribbon__five::before {
      position: absolute;
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 3.125rem 3.125rem 3.125rem;
      border-color: transparent transparent var(--primary) transparent;
      left: 0.25rem;
      top: -2.8125rem; }
  .ribbon__six {
    left: 0;
    top: 1.125rem;
    width: 6.875rem;
    height: 2.375rem;
    background-color: var(--primary);
    -webkit-clip-path: polygon(0 0, 100% 0%, 100% 0, 85% 50%, 100% 100%, 100% 100%, 0 100%);
    clip-path: polygon(0 0, 100% 0%, 100% 0, 85% 50%, 100% 100%, 100% 100%, 0 100%);
    display: flex;
    font-size: 1.25rem;
    align-items: center;
    justify-content: center;
    color: #fff; }
  .ribbon-curve {
    position: absolute;
    top: 0;
    right: -0.375rem;
    width: 0.625rem;
    height: 0.6875rem;
    border-top: 0.6875rem solid #3AB54B;
    border-bottom: 0.625rem solid #3AB54B;
    border-right: 0.3125rem solid transparent; }

.jsgrid-grid-body::-webkit-scrollbar,
.dataTables_scrollBody::-webkit-scrollbar,
.table-responsive::-webkit-scrollbar {
  background-color: #f5f5f5;
  width: 0.5rem;
  height: 0.5rem; }

.jsgrid-grid-body::-webkit-scrollbar-track,
.dataTables_scrollBody::-webkit-scrollbar-track,
.table-responsive::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.3);
  border-radius: 0.625rem;
  background-color: #f5f5f5; }

.jsgrid-grid-body::-webkit-scrollbar-thumb,
.dataTables_scrollBody::-webkit-scrollbar-thumb,
.table-responsive::-webkit-scrollbar-thumb {
  border-radius: 0.625rem;
  -webkit-box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.3);
  background-color: var(--primary); }

.multi-steps > li.is-active:before, .multi-steps > li.is-active ~ li:before {
  content: counter(stepNum);
  font-family: inherit;
  font-weight: 400; }

.multi-steps > li.is-active:after, .multi-steps > li.is-active ~ li:after {
  background-color: #E6E6E6; }

.multi-steps {
  display: table;
  table-layout: fixed;
  width: 100%; }
  .multi-steps > li {
    counter-increment: stepNum;
    text-align: center;
    display: table-cell;
    position: relative;
    color: var(--primary); }
    @media (max-width: 575.98px) {
      .multi-steps > li {
        font-size: 0.75rem; } }
    .multi-steps > li:before {
      content: '\2713';
      display: block;
      margin: 0 auto 0.25rem;
      background-color: #fff;
      width: 1.5625rem;
      height: 1.5625rem;
      line-height: 1.375rem;
      text-align: center;
      font-weight: bold;
      position: relative;
      z-index: 1;
      border-width: 0.125rem;
      border-style: solid;
      border-color: var(--primary);
      border-radius: 0.3125rem; }
      @media (max-width: 575.98px) {
        .multi-steps > li:before {
          width: 1.5625rem;
          height: 1.5625rem;
          line-height: 1.3125rem; } }
    .multi-steps > li:after {
      content: '';
      height: 0.125rem;
      width: 100%;
      background-color: var(--primary);
      position: absolute;
      top: 0.75rem;
      left: 50%; }
      [direction="rtl"] .multi-steps > li:after {
        left: auto;
        right: 50%; }
      @media (max-width: 575.98px) {
        .multi-steps > li:after {
          top: 0.75rem; } }
    .multi-steps > li:last-child:after {
      display: none; }
    .multi-steps > li.is-active:before {
      background-color: #fff;
      border-color: var(--primary); }
    .multi-steps > li.is-active ~ li {
      color: #888888; }
      .multi-steps > li.is-active ~ li:before {
        background-color: #E6E6E6;
        border-color: #E6E6E6; }

.nav-pills .nav-link {
  border-radius: 4px;
  padding: 5px 15px;
  font-size: 15px; }

.default-tab .nav-link {
  background: transparent;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  font-weight: 500; }
  .default-tab .nav-link i {
    display: inline-block;
    transform: scale(1.5);
    color: var(--primary); }
  .default-tab .nav-link:focus, .default-tab .nav-link:hover, .default-tab .nav-link.active {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff #ebeef6;
    border-radius: 0.625rem 0.625rem 0 0;
    color: var(--primary);
    border-radius: 0; }
    [data-theme-version="dark"] .default-tab .nav-link:focus, [data-theme-version="dark"] .default-tab .nav-link:hover, [data-theme-version="dark"] .default-tab .nav-link.active {
      background-color: var(--rgba-primary-1);
      border-color: transparent transparent #444444 transparent; }

.custom-tab-1 .nav-link {
  background: transparent;
  border-radius: 0rem;
  font-weight: 500;
  border-bottom: 0.1875rem solid transparent;
  border-width: 0 0rem 0.1875rem 0;
  margin: 0 3px; }
  .custom-tab-1 .nav-link i {
    display: inline-block;
    transform: scale(1.5);
    color: var(--primary); }
  .custom-tab-1 .nav-link:focus, .custom-tab-1 .nav-link:hover, .custom-tab-1 .nav-link.active {
    color: #495057;
    background-color: #fff;
    border-color: var(--primary);
    border-radius: 0;
    color: var(--primary);
    border-width: 0 0rem 0.1875rem 0; }
    [data-theme-version="dark"] .custom-tab-1 .nav-link:focus, [data-theme-version="dark"] .custom-tab-1 .nav-link:hover, [data-theme-version="dark"] .custom-tab-1 .nav-link.active {
      background-color: var(--rgba-primary-1); }

.nav-pills.light .nav-link.active,
.nav-pills.light .show > .nav-link {
  background: var(--rgba-primary-1);
  color: var(--primary);
  box-shadow: none; }
  [data-theme-version="dark"] .nav-pills.light .nav-link.active, [data-theme-version="dark"]
  .nav-pills.light .show > .nav-link {
    background: var(--rgba-primary-1); }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: var(--primary); }

.tab-with-icon .nav-tabs .nav-link {
  border-radius: 6px; }
  .tab-with-icon .nav-tabs .nav-link.active, .tab-with-icon .nav-tabs .nav-link:hover {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }

.tooltip-wrapper button:not(:last-child) {
  margin-right: 0.5rem; }

.tooltip-wrapper button:hover {
  background: var(--primary);
  color: #fff; }

.tooltip-wrapper button {
  margin-bottom: .5rem; }
  .tooltip-wrapper button:focus {
    box-shadow: none; }

.tooltip-inner {
  border-radius: 0;
  background: #333333;
  font-size: 0.75rem;
  font-weight: 300;
  padding: 0.35rem 0.7rem; }

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[data-popper-placement^="bottom"] .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #333333; }

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #333333; }

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #333333; }

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[data-popper-placement^="top"] .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #333333; }

.widget-timeline .timeline {
  list-style: none;
  position: relative; }
  .widget-timeline .timeline:before {
    top: 1.25rem;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 0.188rem;
    left: 0.5625rem;
    margin-right: -0.0938rem;
    background: #E6E6E6; }
    [data-theme-version="dark"] .widget-timeline .timeline:before {
      background: #444444; }
    [direction="rtl"] .widget-timeline .timeline:before {
      left: auto;
      right: 0.5625rem;
      margin-right: auto;
      margin-left: -0.0938rem; }
  .widget-timeline .timeline > li {
    margin-bottom: 0.9375rem;
    position: relative; }
    .widget-timeline .timeline > li:before {
      content: " ";
      display: table; }
    .widget-timeline .timeline > li:after {
      content: " ";
      display: table;
      clear: both; }
    .widget-timeline .timeline > li > .timeline-panel {
      border-radius: 0.625rem;
      padding: 0.9375rem 1.25rem;
      position: relative;
      display: block;
      margin-left: 2.5rem;
      border-width: 0.0625rem;
      border-style: solid; }
      .widget-timeline .timeline > li > .timeline-panel span {
        font-size: 0.75rem;
        display: block;
        margin-bottom: 0.3125rem;
        opacity: 0.8;
        letter-spacing: 0.0625rem;
        color: var(--text-dark); }
      .widget-timeline .timeline > li > .timeline-panel p {
        font-size: 0.75rem;
        line-height: 1.125rem; }
      .widget-timeline .timeline > li > .timeline-panel h6, .widget-timeline .timeline > li > .timeline-panel .h6 {
        font-size: 13px; }
      .widget-timeline .timeline > li > .timeline-panel:after {
        content: "";
        width: 0.625rem;
        height: 0.625rem;
        background: inherit;
        border-color: inherit;
        border-style: solid;
        border-width: 0rem 0rem 0.0625rem 0.0625rem;
        display: block;
        position: absolute;
        left: -0.3125rem;
        transform: rotate(45deg);
        top: 0.9375rem; }
    .widget-timeline .timeline > li > .timeline-badge {
      border-radius: 50%;
      height: 1.275rem;
      left: 0;
      position: absolute;
      top: 0.625rem;
      width: 1.275rem;
      border-width: 0.125rem;
      border-style: solid;
      background: #fff;
      padding: 0.25rem; }
      [data-theme-version="dark"] .widget-timeline .timeline > li > .timeline-badge {
        background-color: #2c2c2c; }
      .widget-timeline .timeline > li > .timeline-badge:after {
        content: "";
        width: 0.525rem;
        height: 0.525rem;
        border-radius: 100%;
        display: block; }
      [direction="rtl"] .widget-timeline .timeline > li > .timeline-badge {
        left: auto;
        right: 1.1875rem; }

.widget-timeline .timeline-body > p {
  font-size: 0.75rem; }

.widget-timeline .timeline-badge.primary {
  border-color: var(--primary-light); }
  [data-theme-version="dark"] .widget-timeline .timeline-badge.primary {
    border-color: var(--rgba-primary-1); }
  .widget-timeline .timeline-badge.primary:after {
    background-color: var(--primary);
    box-shadow: 0 0.3125rem 0.625rem 0 var(--rgba-primary-2); }
  .widget-timeline .timeline-badge.primary + .timeline-panel {
    background: var(--primary-light);
    border-color: var(--primary-light); }
    [data-theme-version="dark"] .widget-timeline .timeline-badge.primary + .timeline-panel {
      border-color: transparent;
      background-color: var(--rgba-primary-1); }
  .widget-timeline .timeline-badge.primary span {
    color: var(--text-dark); }

.widget-timeline .timeline-badge.success {
  border-color: #A7FFEA; }
  [data-theme-version="dark"] .widget-timeline .timeline-badge.success {
    border-color: rgba(83, 208, 178, 0.1); }
  .widget-timeline .timeline-badge.success:after {
    background-color: #53D0B2 !important;
    box-shadow: 0 0.3125rem 0.625rem 0 rgba(83, 208, 178, 0.2); }
  .widget-timeline .timeline-badge.success + .timeline-panel {
    background: #A7FFEA;
    border-color: #A7FFEA; }
    [data-theme-version="dark"] .widget-timeline .timeline-badge.success + .timeline-panel {
      background-color: rgba(83, 208, 178, 0.1);
      border-color: transparent; }

.widget-timeline .timeline-badge.warning {
  border-color: #FCEF73; }
  [data-theme-version="dark"] .widget-timeline .timeline-badge.warning {
    border-color: rgba(244, 207, 12, 0.1); }
  .widget-timeline .timeline-badge.warning:after {
    background-color: #F4CF0C !important;
    box-shadow: 0 0.3125rem 0.625rem 0 rgba(244, 207, 12, 0.2); }
  .widget-timeline .timeline-badge.warning + .timeline-panel {
    background: #FCEF73;
    border-color: #FCEF73; }
    [data-theme-version="dark"] .widget-timeline .timeline-badge.warning + .timeline-panel {
      background-color: rgba(244, 207, 12, 0.1);
      border-color: transparent; }

.widget-timeline .timeline-badge.danger {
  border-color: #FFCCC0; }
  [data-theme-version="dark"] .widget-timeline .timeline-badge.danger {
    border-color: rgba(255, 124, 124, 0.15); }
  .widget-timeline .timeline-badge.danger:after {
    background-color: #FF7C7C !important;
    box-shadow: 0 0.3125rem 0.625rem 0 rgba(255, 124, 124, 0.2); }
  .widget-timeline .timeline-badge.danger + .timeline-panel {
    background: #FFCCC0;
    border-color: #FFCCC0; }
    [data-theme-version="dark"] .widget-timeline .timeline-badge.danger + .timeline-panel {
      background-color: rgba(255, 124, 124, 0.15);
      border-color: transparent; }

.widget-timeline .timeline-badge.info {
  border-color: #D7E3FF; }
  [data-theme-version="dark"] .widget-timeline .timeline-badge.info {
    border-color: rgba(129, 165, 249, 0.1); }
  .widget-timeline .timeline-badge.info:after {
    background-color: #81A5F9 !important;
    box-shadow: 0 0.3125rem 0.625rem 0 rgba(129, 165, 249, 0.2); }
  .widget-timeline .timeline-badge.info + .timeline-panel {
    background: #D7E3FF;
    border-color: #D7E3FF; }
    [data-theme-version="dark"] .widget-timeline .timeline-badge.info + .timeline-panel {
      background-color: rgba(129, 165, 249, 0.1);
      border-color: transparent; }

.widget-timeline .timeline-badge.dark {
  border-color: #999999; }
  [data-theme-version="dark"] .widget-timeline .timeline-badge.dark {
    border-color: rgba(51, 51, 51, 0.35); }
  .widget-timeline .timeline-badge.dark:after {
    background-color: #333333 !important;
    box-shadow: 0 0.3125rem 0.625rem 0 rgba(51, 51, 51, 0.2); }
  .widget-timeline .timeline-badge.dark + .timeline-panel {
    background: #999999;
    border-color: #999999; }
    [data-theme-version="dark"] .widget-timeline .timeline-badge.dark + .timeline-panel {
      background-color: rgba(51, 51, 51, 0.35);
      border-color: transparent; }

.widget-timeline.style-1 .timeline-panel {
  background: transparent; }

.widget-timeline.style-1 .timeline .timeline-badge.timeline-badge + .timeline-panel {
  background: transparent !important;
  border-style: solid;
  border-width: 0 0 0 0.2125rem;
  border-radius: 0;
  padding: 0.3125rem 0.625rem 0.3125rem 0.9375rem; }
  .widget-timeline.style-1 .timeline .timeline-badge.timeline-badge + .timeline-panel:after {
    border: 0;
    left: -0.3625rem;
    width: 0.3375rem;
    height: 0.3375rem; }

.widget-timeline.style-1 .timeline .timeline-badge.primary + .timeline-panel {
  border-color: var(--primary); }
  .widget-timeline.style-1 .timeline .timeline-badge.primary + .timeline-panel:after {
    background: var(--primary); }

.widget-timeline.style-1 .timeline .timeline-badge.success + .timeline-panel {
  border-color: #53D0B2; }
  .widget-timeline.style-1 .timeline .timeline-badge.success + .timeline-panel:after {
    background: #53D0B2; }

.widget-timeline.style-1 .timeline .timeline-badge.warning + .timeline-panel {
  border-color: #F4CF0C; }
  .widget-timeline.style-1 .timeline .timeline-badge.warning + .timeline-panel:after {
    background: #F4CF0C; }

.widget-timeline.style-1 .timeline .timeline-badge.danger + .timeline-panel {
  border-color: #FF7C7C; }
  .widget-timeline.style-1 .timeline .timeline-badge.danger + .timeline-panel:after {
    background: #FF7C7C; }

.widget-timeline.style-1 .timeline .timeline-badge.info + .timeline-panel {
  border-color: #81A5F9; }
  .widget-timeline.style-1 .timeline .timeline-badge.info + .timeline-panel:after {
    background: #81A5F9; }

.widget-timeline.style-1 .timeline .timeline-badge.dark + .timeline-panel {
  border-color: #333333; }
  .widget-timeline.style-1 .timeline .timeline-badge.dark + .timeline-panel:after {
    background: #333333; }

#chart_widget_4 {
  height: 15.9375rem !important; }

#chart_widget_1 {
  margin-top: -5px; }

#chart_widget_5 .ct-series-a .ct-line,
#chart_widget_5 .ct-series-a .ct-point {
  stroke: #46ffc8; }

#chart_widget_5 .ct-line {
  stroke-width: 0.0625rem; }

#chart_widget_5 .ct-point {
  stroke-width: 0.125rem; }

#chart_widget_5 .ct-series-a .ct-area {
  fill: #20dea6; }

#chart_widget_5 .ct-area {
  fill-opacity: 1; }

#chart_widget_6 .ct-series-a .ct-line,
#chart_widget_6 .ct-series-a .ct-point {
  stroke: #81A5F9; }

#chart_widget_6 .ct-line {
  stroke-width: 0.125rem; }

#chart_widget_6 .ct-point {
  stroke-width: 0.3125rem; }

#chart_widget_6 .ct-series-a .ct-area {
  fill: #81A5F9; }

#chart_widget_6 .ct-area {
  fill-opacity: .5; }

#chart_widget_8 {
  height: 15.9375rem; }
  #chart_widget_8 .ct-series-a .ct-line,
  #chart_widget_8 .ct-series-a .ct-point {
    stroke: #81A5F9; }
  #chart_widget_8 .ct-line {
    stroke-width: 0.125rem; }
  #chart_widget_8 .ct-point {
    stroke-width: 0.3125rem; }
  #chart_widget_8 .ct-series-a .ct-area {
    fill: #81A5F9; }
  #chart_widget_8 .ct-area {
    fill-opacity: .5; }

#chart_widget_9, #chart_widget_10 {
  height: 15.625rem !important; }

#chart_widget_11 .ct-slice-donut, #chart_widget_12 .ct-slice-donut, #chart_widget_13 .ct-slice-donut {
  stroke-width: 1.5625rem !important; }

#chart_widget_11 {
  height: 16.875rem !important; }

#chart_widget_17 {
  height: 9.375rem !important; }

.chart_widget_tab_one .nav-link {
  border: 0.0625rem solid #ddd; }
  .chart_widget_tab_one .nav-link.active {
    background-color: var(--primary);
    border: 0.0625rem solid var(--primary);
    color: #fff; }
    .chart_widget_tab_one .nav-link.active:hover {
      border: 0.0625rem solid var(--primary); }
  .chart_widget_tab_one .nav-link:hover {
    border: 0.0625rem solid #ddd; }

[data-theme-version="dark"] .ccc-widget > div {
  background: #2c2c2c !important;
  border-color: #444444 !important; }

.list-group-flush .list-group-item {
  border: 0; }

.social-icon {
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  border-radius: 0.25rem;
  text-align: center;
  background: #F4F4F5;
  margin-bottom: 0.5rem;
  font-size: 1.25rem; }
  .social-icon i {
    color: #fff; }
  .social-icon.youtube {
    background: #FF0000; }
  .social-icon.facebook {
    background: #3b5998; }
  .social-icon.twitter {
    background: #1da1f2; }

.social-graph-wrapper {
  text-align: center;
  padding: 1.25rem;
  position: relative;
  color: #fff;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem; }
  .social-graph-wrapper.widget-facebook {
    background-color: #3b5998; }
  .social-graph-wrapper.widget-twitter {
    background-color: #1da1f2; }
  .social-graph-wrapper.widget-linkedin {
    background-color: #007bb6; }
  .social-graph-wrapper.widget-googleplus {
    background-color: #db4439; }
  .social-graph-wrapper .s-icon {
    font-size: 1.5rem;
    position: relative;
    padding: 0 0.625rem; }

.social-icon2 a {
  border: 0.1rem solid var(--primary);
  display: block;
  padding: 1rem;
  margin-top: 1.5rem;
  margin-bottom: .5rem;
  border-radius: .3rem;
  font-size: 1.6rem; }

.social-icon2 i {
  font-size: 0.75rem;
  color: var(--primary); }

.social-icon3 ul li {
  display: inline-block; }
  .social-icon3 ul li a {
    display: block; }
    .social-icon3 ul li a i {
      font-size: 1.4rem;
      padding: 1rem .7rem;
      color: #464a53; }
    .social-icon3 ul li a:hover i {
      color: var(--primary); }

.social-icons-muted ul li a i {
  color: #89879f; }

.social-links a {
  padding: .5rem; }

.widget-stat .media {
  padding: 0rem 0;
  align-items: center; }
  .widget-stat .media > span {
    height: 5.3125rem;
    width: 5.3125rem;
    border-radius: 3.125rem;
    padding: 0.625rem 0.75rem;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #464a53;
    min-width: 5.3125rem; }
  .widget-stat .media .media-body p {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 0.875rem; }
    [data-theme-version="dark"] .widget-stat .media .media-body p {
      color: #fff; }
  .widget-stat .media .media-body h3, .widget-stat .media .media-body .h3 {
    font-size: 2.5rem;
    font-weight: 600;
    margin: 0;
    line-height: 1.2; }
  .widget-stat .media .media-body h4, .widget-stat .media .media-body .h4 {
    font-size: 1.5rem;
    display: inline-block;
    vertical-align: middle; }
  .widget-stat .media .media-body span {
    margin-left: 0.3125rem; }

.widget-stat[class*="bg-"] .media > span {
  background-color: rgba(255, 255, 255, 0.25);
  color: #fff; }

.widget-stat[class*="bg-"] .progress {
  background-color: rgba(255, 255, 255, 0.25) !important; }

[direction="rtl"] .widget-stat .media .media-body span {
  margin-left: 0;
  margin-right: 0.625rem; }

.dez-widget-1 .card {
  background: #FFE7DB; }
  .dez-widget-1 .card .card-body p {
    color: #f87533; }

.dez-widget-1 .icon {
  float: right;
  width: 3.125rem;
  height: 3.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.375rem;
  font-size: 1.75rem; }

.bgl-primary {
  background: #CFF859;
  border-color: #CFF859; }
  [data-theme-version="dark"] .bgl-primary {
    background-color: var(--rgba-primary-1);
    border-color: var(--rgba-primary-1); }

.bgl-secondary {
  background: #DCDFE5;
  border-color: #DCDFE5; }
  [data-theme-version="dark"] .bgl-secondary {
    background-color: rgba(17, 24, 40, 0.5);
    border-color: rgba(17, 24, 40, 0.5); }

.bgl-success {
  background: #A7FFEA;
  border-color: #A7FFEA; }
  [data-theme-version="dark"] .bgl-success {
    background-color: rgba(83, 208, 178, 0.1);
    border-color: rgba(83, 208, 178, 0.1); }

.bgl-warning {
  background: #FCEF73;
  border-color: #FCEF73; }
  [data-theme-version="dark"] .bgl-warning {
    background-color: rgba(244, 207, 12, 0.1);
    border-color: rgba(244, 207, 12, 0.1); }

.bgl-danger {
  background: #FFCCC0;
  border-color: #FFCCC0; }
  [data-theme-version="dark"] .bgl-danger {
    background-color: rgba(255, 124, 124, 0.15);
    border-color: rgba(255, 124, 124, 0.15); }

.bgl-info {
  background: #D7E3FF;
  border-color: #D7E3FF; }
  [data-theme-version="dark"] .bgl-info {
    background-color: rgba(129, 165, 249, 0.1);
    border-color: rgba(129, 165, 249, 0.1); }

.bg-primary-light {
  background-color: var(--primary-light) !important; }

.bg-secondary-light {
  background-color: #DCDFE5 !important; }

.bg-success-light {
  background-color: #A7FFEA !important; }

.bg-warning-light {
  background-color: #FCEF73 !important; }

.bg-danger-light {
  background-color: #FFCCC0 !important; }

.bg-info-light {
  background-color: #D7E3FF !important; }

.bgl-dark {
  background: #999999;
  border-color: #999999; }

.bgl-light {
  background: #EEEEEE;
  border-color: #EEEEEE; }

.overlay-box {
  position: relative;
  z-index: 1; }
  .overlay-box:after {
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    opacity: 0.85;
    background: var(--primary-light);
    z-index: -1; }

.rating-bar {
  font-size: 0.8125rem; }

.tdl-holder {
  margin: 0 auto; }
  .tdl-holder ul {
    list-style: none;
    margin: 0;
    padding: 0; }
  .tdl-holder li {
    background-color: #fff;
    border-bottom: 0.0625rem solid #E6E6E6;
    list-style: none none;
    margin: 0;
    padding: 0; }
    .tdl-holder li span {
      margin-left: 2.1875rem;
      font-size: 1rem;
      vertical-align: middle;
      transition: all 0.2s linear; }
      [direction="rtl"] .tdl-holder li span {
        margin-left: auto;
        margin-right: 2.1875rem; }
  .tdl-holder label {
    cursor: pointer;
    display: block;
    line-height: 3.125rem;
    padding-left: 1.5rem;
    position: relative;
    margin: 0 !important; }
    [direction="rtl"] .tdl-holder label {
      padding-left: 0;
      padding-right: 1.5rem; }
    .tdl-holder label:hover {
      background-color: #eef1f6;
      color: #333333; }
      .tdl-holder label:hover a {
        color: #FF7C7C; }
    .tdl-holder label a {
      color: #fff;
      display: inline-block;
      line-height: normal;
      height: 100%;
      text-align: center;
      text-decoration: none;
      width: 3.125rem;
      -webkit-transition: all .2s linear;
      -moz-transition: all .2s linear;
      -o-transition: all .2s linear;
      transition: all 0.2s linear;
      padding: 1.125rem 0rem;
      font-size: 1.125rem;
      position: absolute;
      right: 0; }
      [direction="rtl"] .tdl-holder label a {
        right: auto;
        left: 0; }
  .tdl-holder input[type=checkbox] {
    cursor: pointer;
    opacity: 0;
    position: absolute; }
    .tdl-holder input[type=checkbox] + i {
      background-color: #fff;
      border: 0.0625rem solid #e7e7e7;
      display: block;
      height: 1.25rem;
      position: absolute;
      top: 0.9375rem;
      width: 1.25rem;
      z-index: 1;
      border-radius: 0rem;
      font-size: 0.6875rem;
      border-radius: 0.25rem; }
    .tdl-holder input[type=checkbox]:checked + i {
      background: var(--primary);
      border-color: transparent; }
    .tdl-holder input[type=checkbox]:checked + i::after {
      content: "\f00c";
      font-family: 'fontAwesome';
      display: block;
      left: 0.125rem;
      position: absolute;
      top: -0.875rem;
      z-index: 2;
      color: #fff; }
    .tdl-holder input[type=checkbox]:checked ~ span {
      text-decoration: line-through;
      position: relative; }
  .tdl-holder input[type=text] {
    background-color: #fff;
    height: 3.125rem;
    margin-top: 1.8125rem;
    border-radius: 0.1875rem; }
    .tdl-holder input[type=text]::placeholder {
      color: #333333; }

.widget-message p {
  font-size: 0.875rem;
  line-height: 1.5; }

.picker .picker__frame {
  min-width: 28.125rem;
  max-width: 28.125rem; }
  .picker .picker__frame .picker__box {
    padding: 1.25rem;
    border: 0;
    box-shadow: 0 0.3125rem 0.625rem rgba(0, 0, 0, 0.1); }
    .picker .picker__frame .picker__box .picker__header {
      margin-top: 0; }
      .picker .picker__frame .picker__box .picker__header .picker__year {
        color: var(--primary);
        font-style: inherit;
        font-size: 1.25rem; }
      .picker .picker__frame .picker__box .picker__header .picker__nav--prev,
      .picker .picker__frame .picker__box .picker__header .picker__nav--next {
        width: 2.5rem;
        height: 2.5rem;
        padding: 0;
        line-height: 2.5rem;
        border-radius: 0.125rem; }
        .picker .picker__frame .picker__box .picker__header .picker__nav--prev:before,
        .picker .picker__frame .picker__box .picker__header .picker__nav--next:before {
          content: none !important; }
        .picker .picker__frame .picker__box .picker__header .picker__nav--prev:after,
        .picker .picker__frame .picker__box .picker__header .picker__nav--next:after {
          font-family: fontawesome;
          border: 0;
          margin: 0 auto;
          font-size: 1.375rem; }
        .picker .picker__frame .picker__box .picker__header .picker__nav--prev:hover,
        .picker .picker__frame .picker__box .picker__header .picker__nav--next:hover {
          background-color: var(--primary);
          color: #fff; }
      .picker .picker__frame .picker__box .picker__header .picker__nav--prev {
        left: 0; }
        .picker .picker__frame .picker__box .picker__header .picker__nav--prev:after {
          content: "\f0d9"; }
      .picker .picker__frame .picker__box .picker__header .picker__nav--next {
        right: 0; }
        .picker .picker__frame .picker__box .picker__header .picker__nav--next:after {
          content: "\f0da"; }
    .picker .picker__frame .picker__box .picker__table .picker__weekday {
      padding: 0.625rem 0;
      font-size: 1rem; }
    .picker .picker__frame .picker__box .picker__table .picker__day {
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 3.125rem;
      padding: 0 !important;
      font-size: 1rem;
      line-height: 2.5rem;
      margin: auto;
      border: 0 !important; }
      .picker .picker__frame .picker__box .picker__table .picker__day.picker__day--today:before {
        content: none !important; }
      .picker .picker__frame .picker__box .picker__table .picker__day.picker__day--highlighted {
        border: 0 !important;
        padding: 0;
        background-color: var(--primary);
        color: #fff !important; }
      .picker .picker__frame .picker__box .picker__table .picker__day:hover {
        background-color: var(--primary);
        color: #fff !important; }
    .picker .picker__frame .picker__box .picker__footer .picker__button--today {
      background-color: var(--primary);
      color: #fff !important; }
      .picker .picker__frame .picker__box .picker__footer .picker__button--today:before {
        content: none !important; }
    .picker .picker__frame .picker__box .picker__footer .picker__button--clear,
    .picker .picker__frame .picker__box .picker__footer .picker__button--close,
    .picker .picker__frame .picker__box .picker__footer .picker__button--today {
      border: 0;
      border-radius: 0.125rem;
      font-size: 1rem; }
      .picker .picker__frame .picker__box .picker__footer .picker__button--clear:hover,
      .picker .picker__frame .picker__box .picker__footer .picker__button--close:hover,
      .picker .picker__frame .picker__box .picker__footer .picker__button--today:hover {
        background-color: var(--primary);
        color: #fff !important; }
  @media only screen and (max-width: 35.9375rem) {
    .picker .picker__frame {
      min-width: 100% !important;
      max-width: 100% !important; }
      .picker .picker__frame .picker__box {
        padding: 0.9375rem;
        margin: 0 0.625rem; } }

.card-list {
  overflow: unset;
  height: calc(100% - 50px); }
  .card-list .card-header {
    padding-top: 0;
    padding-bottom: 0; }
    .card-list .card-header .photo {
      overflow: hidden;
      border-radius: 0.3125rem;
      width: 100%; }
  @media only screen and (max-width: 74.9375rem) {
    .card-list {
      height: calc(100% - 40px); } }
  .card-list.list-left {
    margin-top: 0.9375rem;
    margin-left: 0.9375rem; }
    .card-list.list-left .card-header {
      padding-left: 0; }
      .card-list.list-left .card-header .photo {
        margin: -0.9375rem 0.9375rem 0 -0.9375rem; }
  .card-list.list-right {
    margin-top: 0.9375rem;
    margin-right: 0.9375rem;
    text-align: right; }
    .card-list.list-right .card-header {
      padding-right: 0; }
      .card-list.list-right .card-header .photo {
        margin: -0.9375rem -0.9375rem 0 0.9375rem; }
        .card-list.list-right .card-header .photo img {
          width: 100%; }
  .card-list.list-center {
    margin-top: 0.9375rem; }
    .card-list.list-center .card-header .photo {
      margin: -0.9375rem 0 0 0; }
  .card-list .photo img {
    width: 100%; }

.card-profile .profile-photo {
  margin-top: -1.25rem; }

.widget-media .timeline .timeline-panel {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  padding: 0.8rem 0.75rem;
  margin: 0 -5px;
  border-radius: 0.625rem;
  position: relative;
  transition: all 0.5s;
  /* &:after{
				position:absolute;
				content:"";
				background-color:var(--primary);
				width:0.5rem;
				height:0.5rem;
				border-radius:100%;
				top:0.875rem;
				right:0.875rem;
			} */ }
  .widget-media .timeline .timeline-panel:hover {
    background-color: rgba(13, 153, 255, 0.05);
    cursor: pointer; }
  .widget-media .timeline .timeline-panel .media {
    width: 2.188rem;
    height: 2.188rem;
    background: #eee;
    border-radius: 100%;
    overflow: hidden;
    font-size: 0.813rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    align-self: start; }
    .widget-media .timeline .timeline-panel .media-primary {
      background: var(--primary-light);
      color: var(--text-dark); }
      [data-theme-version="dark"] .widget-media .timeline .timeline-panel .media-primary {
        background-color: var(--rgba-primary-1); }
    .widget-media .timeline .timeline-panel .media-info {
      background: #D7E3FF;
      color: var(--text-dark); }
      [data-theme-version="dark"] .widget-media .timeline .timeline-panel .media-info {
        background-color: rgba(129, 165, 249, 0.1); }
    .widget-media .timeline .timeline-panel .media-warning {
      background: #FCEF73;
      color: var(--text-dark); }
      [data-theme-version="dark"] .widget-media .timeline .timeline-panel .media-warning {
        background-color: rgba(244, 207, 12, 0.1); }
    .widget-media .timeline .timeline-panel .media-danger {
      background: #FFCCC0;
      color: var(--text-dark); }
      [data-theme-version="dark"] .widget-media .timeline .timeline-panel .media-danger {
        background-color: rgba(255, 124, 124, 0.15); }
    .widget-media .timeline .timeline-panel .media-success {
      background: #A7FFEA;
      color: var(--text-dark); }
      [data-theme-version="dark"] .widget-media .timeline .timeline-panel .media-success {
        background-color: rgba(83, 208, 178, 0.1); }
  .widget-media .timeline .timeline-panel .media-body span {
    border: 1px solid #E6E6E6;
    font-size: 0.75rem;
    border-radius: 0.625rem;
    padding: 0.313rem 0.625rem; }
  .widget-media .timeline .timeline-panel .media-body h6, .widget-media .timeline .timeline-panel .media-body .h6 {
    font-size: 0.813rem;
    line-height: 1rem; }
  .widget-media .timeline .timeline-panel .media-body p {
    font-size: 0.75rem; }
  .widget-media .timeline .timeline-panel .media-body .attc {
    display: flex;
    align-items: flex-start;
    border: 1px solid #f1f1f1;
    border-radius: 0.625rem;
    padding: 0.313rem 0.625rem; }
    .widget-media .timeline .timeline-panel .media-body .attc .media.style-1 {
      border-radius: 0.625rem;
      border: 1px solid #E6E6E6; }

.widget-media .timeline li:last-child .timeline-panel {
  margin-bottom: 0;
  border-bottom: 0;
  padding-bottom: 0; }

.card[class*="bg-"] .timeline .timeline-panel {
  border-color: rgba(255, 255, 255, 0.2) !important; }

.donut-chart-sale small, .donut-chart-sale .small, .donut-chart-sale .small {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.125rem;
  font-weight: 600; }

.students h4, .students .h4, .students h5, .students .h5, .students span {
  color: #fff; }

.table {
  color: strong;
    color-color: #333333; }
  .table th, .table td {
    border-color: #E6E6E6;
    padding: 0.9375rem 0.625rem; }
    [data-theme-version="dark"] .table th, [data-theme-version="dark"] .table td {
      border-color: #444444;
      color: #fff !important; }
  .table.table-striped tbody tr:nth-of-type(odd),
  .table.table-hover tr:hover {
    background-color: #F4F4F5; }
    [data-theme-version="dark"] .table.table-striped tbody tr:nth-of-type(odd), [data-theme-version="dark"]
    .table.table-hover tr:hover {
      background-color: #1E1E1E; }
  .table.shadow-hover tbody tr:hover {
    background-color: #fff;
    box-shadow: 0 0rem 1.875rem var(--rgba-primary-2); }
    [data-theme-version="dark"] .table.shadow-hover tbody tr:hover {
      background-color: #1E1E1E; }
  .table.tr-rounded tr td:first-child, .table.tr-rounded tr th:first-child {
    border-radius: 2.8125rem 0 0 2.8125rem; }
  .table.tr-rounded tr td:last-child, .table.tr-rounded tr th:last-child {
    border-radius: 0 2.8125rem 2.8125rem 0; }
  .table.border-hover tr td {
    border-width: 0.0625rem 0 0.0625rem 0;
    border-bottom: 0.0625rem solid transparent;
    border-color: transparent; }
    .table.border-hover tr td:first-child {
      border-width: 0.0625rem 0 0.0625rem 0.0625rem; }
    .table.border-hover tr td:last-child {
      border-width: 0.0625rem 0.0625rem 0.0625rem 0; }
  .table.border-hover tr:hover td {
    border-color: #cdcdcd; }
  .table.bg-primary-hover th, .table.bg-primary-hover td {
    border: none;
    font-weight: 500; }
  .table.bg-primary-hover td {
    color: #000; }
    [data-theme-version="dark"] .table.bg-primary-hover td {
      color: #fff; }
  .table.bg-primary-hover th {
    color: #6C6C6C; }
  .table.bg-primary-hover tr:hover td, .table.bg-primary-hover tr:hover th {
    background: var(--primary);
    color: #fff; }
  .table.bg-secondary-hover th, .table.bg-secondary-hover td {
    border: none;
    font-weight: 500; }
  .table.bg-secondary-hover td {
    color: #000; }
    [data-theme-version="dark"] .table.bg-secondary-hover td {
      color: #fff; }
  .table.bg-secondary-hover th {
    color: #6C6C6C; }
  .table.bg-secondary-hover tr:hover td, .table.bg-secondary-hover tr:hover th {
    background: #111828;
    color: #fff !important; }
  .table.bg-info-hover th, .table.bg-info-hover td {
    border: none;
    font-weight: 500; }
  .table.bg-info-hover td {
    color: #000; }
  .table.bg-info-hover th {
    color: #6C6C6C; }
  .table.bg-info-hover tr:hover td, .table.bg-info-hover tr:hover th {
    background: #81A5F9;
    color: #fff !important; }
  .table.bg-warning-hover th, .table.bg-warning-hover td {
    border: none;
    font-weight: 500; }
  .table.bg-warning-hover td {
    color: #000; }
    [data-theme-version="dark"] .table.bg-warning-hover td {
      color: #fff; }
  .table.bg-warning-hover th {
    color: #6C6C6C; }
  .table.bg-warning-hover tr:hover td, .table.bg-warning-hover tr:hover th {
    background: #F4CF0C;
    color: #fff !important; }
  .table.border-no td {
    border: 0; }
  .table.short-one tr td:first-child, .table.short-one tr th:first-child {
    width: 3.75rem; }
  .table thead th {
    border-bottom: 0.0625rem solid #E6E6E6;
    text-transform: capitalize;
    font-size: 1rem;
    white-space: nowrap;
    font-weight: 500;
    border-color: #E6E6E6 !important; }
    [data-theme-version="dark"] .table thead th {
      border-color: #444444 !important; }
  .table tbody tr td {
    vertical-align: middle;
    border-color: #E6E6E6;
    white-space: nowrap; }
  .table:not(.table-bordered) thead th {
    border-top: none; }
  .table .thead-primary th {
    background-color: var(--primary-light);
    color: var(--text-dark); }
  .table .thead-info th {
    background-color: #D7E3FF;
    color: var(--text-dark); }
  .table .thead-warning th {
    background-color: #F4CF0C;
    color: #fff; }
  .table .thead-danger th {
    background-color: #FF7C7C;
    color: #fff; }
  .table .thead-success th {
    background-color: #53D0B2;
    color: #fff; }
  .table.primary-table-bordered {
    border: 0.0625rem solid #E6E6E6; }
    [data-theme-version="dark"] .table.primary-table-bordered {
      border-color: #444444; }
  .table.primary-table-bg-hover thead th {
    background-color: var(--primary);
    color: #fff;
    border-bottom: none; }
  .table.primary-table-bg-hover tbody tr {
    background-color: var(--primary-light);
    color: var(--text-dark);
    transition: all .1s ease; }
    .table.primary-table-bg-hover tbody tr:hover {
      background-color: var(--rgba-primary-8); }
    .table.primary-table-bg-hover tbody tr:not(:last-child) td, .table.primary-table-bg-hover tbody tr:not(:last-child) th {
      border-bottom: 0.0625rem solid var(--primary-dark); }
  .table-responsive-tiny {
    min-width: 18.75rem; }
  .table-responsive-sm {
    min-width: 30rem !important; }
  .table-responsive-md {
    min-width: 36rem !important; }
  .table-responsive-lg {
    min-width: 60.9375rem !important; }
  .table-responsive-xl {
    min-width: 70.9375rem !important; }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: var(--primary-light);
  color: var(--text-dark); }
  [data-theme-version="dark"] .table-primary, [data-theme-version="dark"]
  .table-primary > th, [data-theme-version="dark"]
  .table-primary > td {
    background-color: var(--rgba-primary-1); }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #A7FFEA;
  color: var(--text-dark); }
  [data-theme-version="dark"] .table-success, [data-theme-version="dark"]
  .table-success > th, [data-theme-version="dark"]
  .table-success > td {
    background-color: rgba(83, 208, 178, 0.1); }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #D7E3FF;
  color: var(--text-dark); }
  [data-theme-version="dark"] .table-info, [data-theme-version="dark"]
  .table-info > th, [data-theme-version="dark"]
  .table-info > td {
    background-color: rgba(129, 165, 249, 0.1); }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #FCEF73;
  color: var(--text-dark); }
  [data-theme-version="dark"] .table-warning, [data-theme-version="dark"]
  .table-warning > th, [data-theme-version="dark"]
  .table-warning > td {
    background-color: rgba(244, 207, 12, 0.1); }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #FFCCC0;
  color: var(--text-dark); }
  [data-theme-version="dark"] .table-danger, [data-theme-version="dark"]
  .table-danger > th, [data-theme-version="dark"]
  .table-danger > td {
    background-color: rgba(255, 124, 124, 0.15); }

.table-active,
.table-active > th,
.table-active > td {
  background-color: #f2f4fa; }
  [data-theme-version="dark"] .table-active, [data-theme-version="dark"]
  .table-active > th, [data-theme-version="dark"]
  .table-active > td {
    background-color: #1E1E1E; }

.card-table th:first-child, .card-table td:first-child {
  padding-left: 1.875rem; }
  @media only screen and (max-width: 35.9375rem) {
    .card-table th:first-child, .card-table td:first-child {
      padding-left: 0.9375rem; } }

.card-table th:last-child, .card-table td:last-child {
  padding-right: 1.875rem; }
  @media only screen and (max-width: 35.9375rem) {
    .card-table th:last-child, .card-table td:last-child {
      padding-right: 0.9375rem; } }

.table-sell tbody tr td {
  border-color: #ffffff66; }

.table-sell thead th {
  border-color: #ffffff66 !important; }

.table-sell tr td {
  padding: 0.670rem 0.625rem; }

.table-sell tr th {
  padding: 0.670rem 0.625rem;
  font-size: 1rem; }

.order-tbl tbody tr:hover {
  border-radius: 0.625rem; }

.dataTablemarket tbody tr td {
  text-align: center; }

[data-theme-version="dark"] .table-sell tbody tr td {
  border-color: #ffffff66 !important; }

[data-theme-version="dark"] .table-sell thead th {
  border-color: #ffffff66 !important; }

.dark-logo {
  display: none; }

.light-logo {
  display: block; }

.table-action-icon .btn-xs {
  line-height: 0; }

.bootgrid-header {
  padding: 0 !important;
  margin: 0; }
  @media only screen and (max-width: 35.9375rem) {
    .bootgrid-header .actionBar {
      padding: 0; }
    .bootgrid-header .search {
      margin: 0 0 0.625rem 0; } }

table#example {
  padding: 0rem 0 2rem 0; }

table.dataTable {
  font-size: 0.875rem; }

#example2_wrapper .dataTables_scrollBody {
  max-height: 33.25rem !important; }

#employees, #custommers {
  padding: .5rem 0 1rem 0; }

.dataTables_wrapper .dataTables_paginate {
  padding-top: 0.75em;
  padding-bottom: 0.75em; }

table.dataTable thead th, table.dataTable thead td {
  border-bottom: 0.125rem solid #cdcdcd;
  border-top: 0; }

table.dataTable tfoot th, table.dataTable tfoot td {
  border-top: 0; }

table.dataTable tbody tr, table.dataTable tbody td {
  background: transparent !important; }

table.dataTable thead th {
  color: #111828;
  white-space: nowrap;
  font-size: 1rem;
  text-transform: capitalize;
  font-weight: 500;
  padding: 1.25rem 0.9375rem; }
  [data-theme-version="dark"] table.dataTable thead th {
    color: #fff; }
  @media only screen and (max-width: 87.5rem) {
    table.dataTable thead th {
      font-size: 1rem; } }

table.dataTable tbody td {
  padding: 1.25rem 0.9375rem;
  font-size: 15px;
  font-weight: 400;
  border-bottom: 0; }
  @media only screen and (max-width: 35.9375rem) {
    table.dataTable tbody td {
      padding: 0.5rem 0.3125rem; } }
  @media only screen and (max-width: 87.5rem) {
    table.dataTable tbody td {
      font-size: 0.875rem;
      padding: 0.5rem 0.9375rem; } }

table.dataTable tfoot th {
  color: #333333;
  font-weight: 600; }
  [data-theme-version="dark"] table.dataTable tfoot th {
    color: #fff; }

td.text-ov {
  white-space: nowrap; }

.dataTables_wrapper .dataTables_paginate {
  align-items: center;
  display: flex;
  flex-flow: wrap; }
  .dataTables_wrapper .dataTables_paginate .paginate_button.previous, .dataTables_wrapper .dataTables_paginate .paginate_button.next {
    font-size: 13px;
    height: 24px;
    width: 24px;
    background: var(--primary-light);
    border-radius: 0.375rem;
    padding: 0 0.45rem;
    line-height: 24px;
    margin: 0 0.625rem;
    display: inline-block;
    color: black !important;
    box-shadow: none !important; }
    .dataTables_wrapper .dataTables_paginate .paginate_button.previous.current:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.previous.previous:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.previous.next:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.next.current:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.next.previous:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.next.next:hover {
      color: black !important;
      background: var(--rgba-primary-1) !important;
      border-color: transparent; }
    .dataTables_wrapper .dataTables_paginate .paginate_button.previous.disabled, .dataTables_wrapper .dataTables_paginate .paginate_button.next.disabled {
      color: #888888 !important; }
      .dataTables_wrapper .dataTables_paginate .paginate_button.previous.disabled:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.next.disabled:hover {
        background: var(--primary-light) !important;
        color: black !important; }
  .dataTables_wrapper .dataTables_paginate span .paginate_button {
    height: 24px;
    width: 24px;
    padding: 0;
    margin-left: 0rem;
    margin: 0 0.125rem;
    line-height: 25px;
    text-align: center;
    font-size: 13px;
    border-radius: 0.375rem;
    border: 0 !important;
    color: black !important;
    background: var(--primary-light);
    cursor: pointer;
    box-shadow: none !important; }
    .dataTables_wrapper .dataTables_paginate span .paginate_button.current {
      color: #fff !important;
      background: var(--secondary) !important; }
      .dataTables_wrapper .dataTables_paginate span .paginate_button.current:hover {
        color: #fff !important;
        background: var(--primary) !important; }
    .dataTables_wrapper .dataTables_paginate span .paginate_button:hover, .dataTables_wrapper .dataTables_paginate span .paginate_button:active {
      color: black !important;
      background: var(--rgba-primary-1) !important; }

.dataTables_wrapper input[type="search"], .dataTables_wrapper input[type="text"], .dataTables_wrapper select {
  border: 0.0625rem solid #e2e2e2;
  padding: .3rem 0.5rem;
  color: #715d5d;
  border-radius: 0.3125rem; }
  [data-theme-version="dark"] .dataTables_wrapper input[type="search"], [data-theme-version="dark"] .dataTables_wrapper input[type="text"], [data-theme-version="dark"] .dataTables_wrapper select {
    background: #1E1E1E;
    border-color: #444444;
    color: #fff; }

.dataTables_wrapper .dataTables_length {
  margin-bottom: 0.9375rem; }
  .dataTables_wrapper .dataTables_length .bootstrap-select {
    width: 5rem;
    margin: 0 0.3125rem;
    width: 70px !important; }

table.dataTable.no-footer {
  border-bottom: 0; }

.rounded-lg {
  min-width: 1.875rem; }

.dataTables_scroll {
  padding: 1rem 0; }
  .dataTables_scrollFoot {
    padding-top: 1rem; }

.dataTablesCard {
  background-color: #fff;
  border-radius: 0.625rem; }
  .dataTablesCard.border-no td {
    border-top: 0 !important; }

@media (max-width: 991.98px) {
  .dataTables_wrapper .dataTables_length, .dataTables_wrapper .dataTables_filter {
    text-align: left; } }

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  color: #888888 !important; }

.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter,
.dataTables_wrapper .dataTables_processing,
.dataTables_wrapper .dataTables_paginate {
  color: #888888;
  border-radius: 1rem;
  padding: 0.125rem 0;
  margin-bottom: 0.35rem; }

.paging_simple_numbers.dataTables_paginate {
  padding: 0.6125rem 0.3125rem; }
  @media only screen and (max-width: 35.9375rem) {
    .paging_simple_numbers.dataTables_paginate {
      padding: 0.8125rem 0.3125rem; } }

.dataTables_wrapper .dataTables_paginate .paginate_button {
  color: #888888 !important; }

table.dataTable.row-border tbody th,
table.dataTable.row-border tbody td,
table.dataTable.display tbody th,
table.dataTable.display tbody td {
  border-color: #E6E6E6; }
  [data-theme-version="dark"] table.dataTable.row-border tbody th, [data-theme-version="dark"]
  table.dataTable.row-border tbody td, [data-theme-version="dark"]
  table.dataTable.display tbody th, [data-theme-version="dark"]
  table.dataTable.display tbody td {
    border-color: #444444; }

.dataTables_wrapper .dataTables_length .bootstrap-select .dropdown-toggle {
  font-size: 0.813rem !important;
  padding: 0.625rem 1rem; }

table.tb-transaction tbody td {
  padding: 0.75rem 1.875rem; }

table.tickettable tbody td {
  padding: 1.25rem 1.875rem;
  color: var(--secondary); }

table.tickettable thead th {
  font-size: 1rem !important;
  padding: 1.65rem 1.875rem;
  color: var(--secondary);
  border-bottom: 1px solid #DBDBDB; }

.table.tickettable.table-striped tbody tr:first-child:hover {
  background-color: transparent; }

.table.tickettable.table-hover thead tr:first-child:hover {
  background-color: transparent; }

.table.table-sell.table-hover thead tr:first-child:hover {
  background-color: transparent; }

.table.table-sell.table-hover tbody tr:hover {
  background-color: var(--secondary); }

.orderbookTable thead tr th {
  font-size: 1rem;
  border: 0; }

.orderbookTable tbody tr td {
  border: 0;
  padding: 0.7375rem 0.625rem; }

.dataTabletrade .dataTables_wrapper .dataTables_length {
  display: none; }

.dataTabletrade .dataTables_wrapper .dataTables_filter {
  display: none; }

.dataTabletrade .dataTables_wrapper #example {
  padding: 0rem 0 0.5rem 0; }

.dataTabletrade .dataTables_wrapper .dataTable thead tr th {
  font-size: 1rem;
  border-bottom: 1px solid #eee; }

.dataTabletrade .dataTables_wrapper .dataTable tbody tr td {
  font-size: 0.9rem;
  padding: 1.05rem 0.9375rem; }

.dataTabletrade .dataTables_wrapper .dataTables_paginate {
  margin-bottom: 0; }

.dataTabletrade .dataTables_wrapper .sorting_asc {
  background-image: unset; }

.dataTabletrade .dataTables_wrapper .sorting {
  background-image: unset; }

.dataTablehistory .dataTables_wrapper .dataTable thead tr th {
  font-size: 1rem; }

.dataTablehistory .dataTables_wrapper .dataTable tbody tr td {
  font-size: 0.8rem;
  padding: 1.05rem 0.9375rem; }

.dataTablehistory .dataTables_wrapper .dataTables_length {
  display: none; }

.dataTablehistory .dataTables_wrapper .dataTables_filter {
  display: none; }

.dataTablehistory .dataTables_wrapper table#example {
  padding: 0rem 0 0.6rem 0; }

.dataTablehistory .dataTables_wrapper .sorting_asc {
  background-image: unset; }

.dataTablehistory .dataTables_wrapper .sorting {
  background-image: unset; }

.dataTablemarket .dataTables_wrapper .dataTables_length {
  display: none; }

.dataTablemarket .dataTables_wrapper .dataTables_filter {
  margin-bottom: 0;
  margin-top: 0;
  float: right;
  display: none; }

.dataTablemarket .dataTables_wrapper #example {
  padding: 0rem 0 0.5rem 0; }

.dataTablemarket .dataTables_wrapper .dataTable thead tr th {
  font-size: 0.9rem;
  padding: 0.75rem 0.6375rem;
  border-bottom: 1px solid #eee; }

.dataTablemarket .dataTables_wrapper .dataTable tbody tr td {
  font-size: 1rem;
  padding: 1.05rem 0.9375rem;
  color: var(--secondary);
  font-weight: 400; }
  [data-theme-version="dark"] .dataTablemarket .dataTables_wrapper .dataTable tbody tr td {
    color: #fff; }
  .dataTablemarket .dataTables_wrapper .dataTable tbody tr td a {
    font-size: 1rem;
    font-size: 400;
    color: var(--secondary); }

.dataTablemarket .dataTables_wrapper .dataTables_paginate {
  margin-bottom: 0; }

.dataTablemarket .dataTables_wrapper .sorting_asc {
  background-image: unset; }

.dataTablemarket .dataTables_wrapper .sorting {
  background-image: unset; }

.table.dataTable thead .sorting_desc {
  background-image: unset; }

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc:after {
  content: "";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  background-image: none;
  margin-left: 10px;
  font-size: calc(100% - 4px);
  opacity: 0.5; }

table.dataTable thead .sorting:after {
  content: "\f0dc"; }

table.dataTable thead .sorting_asc:after {
  content: "\f0de";
  opacity: 1; }

table.dataTable thead .sorting_desc:after {
  content: "\f0dd";
  opacity: 1; }

.order-tbl td {
  padding: 0.5375rem 0.625rem; }

table.dataTable thead .sorting {
  background-image: none; }

table.dataTable thead .sorting_desc {
  background-image: none; }

table.dataTable thead .sorting_asc {
  background-image: none; }

#example2_wrapper #example2_filter {
  float: left; }

.dataTables_scrollBody thead {
  display: none; }

#example5_wrapper table.dataTable thead .sorting_asc .custom-checkbox .form-check-input:checked {
  border: 2px solid #fff; }

.tbl-report thead tr th {
  font-weight: 600;
  font-size: 1rem;
  color: #374557; }

/* .table-responsive{
	.dataTables_filter{
		float: right;
        margin-top: -3.313rem;
	}
} */
/* #example_wrapper,
#example2_wrapper,
#example3_wrapper,
#example4_wrapper{
	.dataTables_paginate span .paginate_button{
		padding: 2.8px 9.6px;
	}
} */
/* .dataTables_wrapper {
    input[type="search"], input[type="text"], select {
        border: 1px solid $border !important;
        padding: .3rem 0.5rem;
		//background-color:var(--rgba-primary-5);
        //color: $main-color;
        border-radius: $radius  !important;
		margin-left: 14px;
        @at-root [data-theme-version="dark"] & {
            background: $d-bg;
            border-color: $d-border!important;
            color: $white;
        }
    }
	.dataTables_length{
		margin-bottom: 15px;
		.bootstrap-select{
			width: 70px!important;
			margin: 0 5px;

			.dropdown-toggle::after{
				border-top: 0.4em solid;
				border-right: 0.4em solid transparent;
				border-left: 0.4em solid transparent;
			}
		}
	}
} */
/*  .paging_simple_numbers.dataTables_paginate{
	border: none;
    float: right;
	//margin-top:-1.938rem;

} */
#example3 .sorting_asc:after {
  content: "" !important; }

#example3 .sorting_desc:after {
  content: "" !important; }

.fooicon {
  font-size: 1.25rem;
  color: #333333; }

.jsgrid-table .jsgrid-header-row > .jsgrid-header-cell {
  color: #333333; }

.jsgrid-table > tbody > tr > td {
  padding: 1.2em; }

.jsgrid-table .jsgrid-insert-row input, .jsgrid-table .jsgrid-insert-row select, .jsgrid-table .jsgrid-edit-row input, .jsgrid-table .jsgrid-edit-row select {
  border: 0.0625rem solid #DDDFE1; }

.jsgrid .jsgrid-button {
  border: 0 !important;
  margin-left: 0.625rem; }

@media only screen and (max-width: 61.9375rem) {
  .error-page {
    text-align: center; } }

.error-page .error-text {
  font-size: 150px;
  line-height: 1;
  font-weight: 700;
  margin-bottom: 0; }
  @media only screen and (max-width: 61.9375rem) {
    .error-page .error-text {
      font-size: 130px; } }
  @media only screen and (max-width: 35.9375rem) {
    .error-page .error-text {
      font-size: 80px; } }

.error-page h4, .error-page .h4 {
  font-size: 44px;
  margin-bottom: 0;
  line-height: 1.2; }
  @media only screen and (max-width: 61.9375rem) {
    .error-page h4, .error-page .h4 {
      font-size: 37px; } }
  @media only screen and (max-width: 47.9375rem) {
    .error-page h4, .error-page .h4 {
      font-size: 27px; } }
  @media only screen and (max-width: 35.9375rem) {
    .error-page h4, .error-page .h4 {
      font-size: 20px; } }

.error-page p {
  font-size: 18px;
  color: #c2c2c2;
  margin-bottom: 30px; }
  @media only screen and (max-width: 47.9375rem) {
    .error-page p {
      font-size: 1rem; } }
  @media only screen and (max-width: 35.9375rem) {
    .error-page p {
      font-size: 14px; } }

@media only screen and (max-width: 74.9375rem) {
  .error-media {
    width: 100%; } }

.move-1 {
  -webkit-animation: move1 5s infinite;
  animation: move1 5s infinite; }

.move-2 {
  -webkit-animation: move2 5s infinite;
  animation: move2 5s infinite; }

.move-3 {
  -webkit-animation: move3 5s infinite;
  animation: move3 5s infinite; }

@-webkit-keyframes move1 {
  0% {
    -webkit-transform: rotate(-1deg) translate(-2px, -2px);
    transform: rotate(-1deg) translate(-2px, -2px); }
  50% {
    -webkit-transform: rotate(1deg) translate(2px, 2px);
    transform: rotate(1deg) translate(2px, 2px); }
  100% {
    -webkit-transform: rotate(-1deg) translate(-2px, -2px);
    transform: rotate(-1deg) translate(-2px, -2px); } }

@keyframes move1 {
  0% {
    -webkit-transform: rotate(-1deg) translate(-2px, -2px);
    transform: rotate(-1deg) translate(-2px, -2px); }
  50% {
    -webkit-transform: rotate(1deg) translate(2px, 2px);
    transform: rotate(1deg) translate(2px, 2px); }
  100% {
    -webkit-transform: rotate(-1deg) translate(-2px, -2px);
    transform: rotate(-1deg) translate(-2px, -2px); } }

@-webkit-keyframes move2 {
  0% {
    -webkit-transform: rotate(1deg) translate(2px, 2px);
    transform: rotate(1deg) translate(2px, 2px); }
  50% {
    -webkit-transform: rotate(-1deg) translate(-2px, -2px);
    transform: rotate(-1deg) translate(-2px, -2px); }
  100% {
    -webkit-transform: rotate(1deg) translate(2px, 2px);
    transform: rotate(1deg) translate(2px, 2px); } }

@-webkit-keyframes move3 {
  0% {
    transform: translate(0, 0); }
  20% {
    transform: translate(5px, 0); }
  40% {
    transform: translate(5px, 5px); }
  65% {
    transform: translate(0, 5px); }
  65% {
    transform: translate(5px, 0); }
  100% {
    transform: translate(0, 0); } }

.flex-row-fluid {
  -webkit-box-flex: 1;
  flex: 1 auto;
  -ms-flex: 1 0 0px;
  min-width: 0; }

.authincation {
  display: flex;
  min-height: 100vh; }
  .authincation .login-aside {
    background: #fff;
    padding-top: 80px;
    max-width: 560px;
    width: 100%;
    z-index: 1;
    position: relative; }
    .authincation .login-aside:after {
      content: "";
      clip-path: polygon(0% 100%, 100% 0%, 0% 0%);
      width: 140px;
      height: 100%;
      position: absolute;
      right: -140px;
      z-index: -1;
      top: 0;
      background: #fff;
      box-shadow: 2px 0px 30px rgba(0, 0, 0, 0.15); }
    .authincation .login-aside .aside-image {
      min-height: 450px;
      margin: auto 0;
      min-width: 0;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center; }
  @media only screen and (max-width: 87.5rem) {
    .authincation .login-aside {
      max-width: 360px; } }
  @media only screen and (max-width: 61.9375rem) {
    .authincation .login-aside {
      max-width: 100%;
      padding-top: 0; }
      .authincation .login-aside:after {
        content: none; } }
  @media only screen and (max-width: 35.9375rem) {
    .authincation .login-aside .aside-image {
      min-height: 300px; } }

.auth-form {
  padding: 50px 50px; }
  @media only screen and (max-width: 35.9375rem) {
    .auth-form {
      padding: 30px; } }
  .auth-form .btn {
    height: 50px;
    font-weight: 700; }
  .auth-form .page-back {
    display: inline-block;
    margin-bottom: 15px; }

.pages-left {
  background-color: #fff; }

.login-media {
  margin-top: 80px; }
  .login-media img {
    width: 90%; }
    @media only screen and (max-width: 61.9375rem) {
      .login-media img {
        width: 80%; } }
  @media only screen and (max-width: 35.9375rem) {
    .login-media {
      margin-top: 20px; } }

.login-content {
  padding-top: 70px;
  text-align: center; }
  .login-content p {
    font-size: 20px;
    color: #000;
    line-height: 1.5;
    margin-left: auto;
    margin-right: auto;
    max-width: 500px; }
    @media only screen and (max-width: 74.9375rem) {
      .login-content p {
        font-size: 20px; } }
    @media only screen and (max-width: 35.9375rem) {
      .login-content p {
        font-size: 1rem; } }
  @media only screen and (max-width: 61.9375rem) {
    .login-content {
      padding-top: 40px; } }
  @media only screen and (max-width: 35.9375rem) {
    .login-content {
      padding-top: 14px; } }

.login-form {
  padding: 50px 200px;
  max-width: 900px;
  margin: 0 auto; }
  .login-form .login-title {
    text-align: center;
    position: relative;
    margin-bottom: 48px;
    z-index: 1;
    display: flex;
    align-items: center; }
    .login-form .login-title:before, .login-form .login-title:after {
      content: "";
      height: 1px;
      flex: 1 1;
      left: 0;
      background-color: black;
      top: 50%;
      z-index: -1;
      margin: 0;
      padding: 0; }
  .login-form .btn-facebook,
  .login-form .btn-google-plus,
  .login-form .btn-linkedin,
  .login-form .btn-twitter {
    width: 2.5rem;
    height: 2.5rem;
    display: inline-block;
    text-align: center;
    line-height: 2.5rem;
    border-radius: 100%; }
  @media only screen and (max-width: 74.9375rem) {
    .login-form {
      padding: 25px 45px; } }
  @media only screen and (max-width: 61.9375rem) {
    .login-form {
      padding: 45px; } }
  @media only screen and (max-width: 35.9375rem) {
    .login-form {
      padding: 30px; } }

.eye {
  position: absolute;
  top: 38px;
  right: 20px; }
  @media only screen and (max-width: 100rem) {
    .eye {
      top: 34px; } }

:root {
  --right-space:27.188rem; }

.heart {
  width: 3.75rem;
  height: 3.75rem;
  display: inline-block;
  background: url("../images/like.png");
  cursor: pointer;
  margin: -1.563rem -0.938rem; }

.heart-blast {
  background-position: -1680px 0 !important;
  transition: background 1s steps(28); }

.ps--active-x > .ps__rail-x, .ps--active-y > .ps__rail-y {
  z-index: -1; }

.avatar {
  height: 1.875rem;
  width: 1.875rem;
  display: inline-block;
  position: relative;
  object-fit: cover;
  border-radius: 0.625rem; }
  .avatar.avatar-md {
    height: 2.375rem;
    width: 2.375rem; }
  .avatar.avatar-lg {
    height: 3rem;
    width: 3rem; }

.avatar-list.avatar-list-stacked .avatar {
  margin-inline-end: -13px;
  border: 2px solid #fff;
  line-height: 2.9;
  font-weight: 500; }
  .avatar-list.avatar-list-stacked .avatar:hover {
    z-index: 1; }
  @media only screen and (max-width: 87.5rem) {
    .avatar-list.avatar-list-stacked .avatar {
      line-height: 2.7; } }

.head-title {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 1rem; }

.wallet-bar {
  position: fixed;
  right: 16px;
  background: red;
  width: 436px;
  height: 90%;
  top: 0;
  z-index: 0; }

.icon-box {
  height: 1.75rem;
  width: 1.75rem;
  position: relative;
  display: inline-block;
  text-align: center;
  line-height: 1.55rem;
  border-radius: 0.375rem; }
  .icon-box.icon-box-md {
    height: 2.5rem;
    width: 2.5rem;
    line-height: 2.4rem; }

.depostit-card .depostit-card-media h3, .depostit-card .depostit-card-media .h3 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 0;
  color: var(--secondary) !important; }

.depostit-card .depostit-card-media h6, .depostit-card .depostit-card-media .h6 {
  color: black !important; }

.progress-box p {
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 8px;
  color: var(--secondary) !important; }

.progress-box .progress {
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid rgba(0, 0, 0, 0.5); }
  .progress-box .progress .progress-bar {
    /* background: linear-gradient(to right, #069952, black); */
    border-right: 1px solid black; }

.progress-box.tbl-progress-box {
  display: flex;
  align-items: center; }
  .progress-box.tbl-progress-box .progress {
    height: 12px;
    flex: 1;
    margin-right: 5px; }

.same-card-chart .depostit-card-media {
  padding: 18px 20px; }
  .same-card-chart .depostit-card-media .icon-box {
    background: rgba(255, 255, 255, 0.2); }

.avatar-list span {
  color: #222B40;
  font-size: 12px;
  display: inline-flex;
  justify-content: center; }

.revenue-date span {
  color: black !important; }

.revenue-date h4, .revenue-date .h4 {
  font-size: 24px;
  font-weight: 700;
  color: var(--secondary) !important; }

.chartBar {
  margin-left: -20px; }

.card.expenses-card {
  background-color: #D7E3FF !important; }

.mix-chart-tab .nav-item .nav-link {
  padding: 4px 15px;
  color: #111828;
  border-radius: 0.375rem;
  font-weight: 500;
  background: #E6E6E6;
  margin: 0 2px;
  font-size: 13px; }
  .mix-chart-tab .nav-item .nav-link.active {
    background: var(--primary-light); }

.project-media {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0; }
  .project-media p {
    font-size: 14px;
    color: #000;
    display: flex;
    align-items: center; }
  .project-media span {
    font-size: 14px;
    font-weight: 800;
    color: #000; }
  .project-media:last-child {
    padding-bottom: 0; }

.project-chart {
  display: flex;
  justify-content: center; }

.active-map-main #world-map {
  height: 300px; }

.wickly-update h6, .wickly-update .h6 {
  font-size: 17px;
  font-weight: 700; }

.wickly-update small, .wickly-update .small {
  font-size: 14px; }

.impression {
  margin-left: -20px; }

.impressionbox {
  position: relative; }
  .impressionbox:before {
    content: "";
    position: absolute;
    background: black;
    height: 135%;
    width: 1px;
    top: -74px;
    left: -10px; }

.mandal-map .left-title {
  margin-top: -38px;
  padding-bottom: 12px; }
  @media only screen and (max-width: 35.9375rem) {
    .mandal-map .left-title {
      border-top: 1px solid black; } }

.active-projects div.dt-buttons {
  float: right;
  position: absolute;
  right: 18px;
  top: -43px; }
  @media only screen and (max-width: 35.9375rem) {
    .active-projects div.dt-buttons {
      top: -39px; } }
  .active-projects div.dt-buttons .dt-button {
    background: none;
    padding: 5px 8px;
    border-radius: 0.375rem;
    color: var(--primary); }

.active-projects .tbl-caption {
  padding: 11px 20px;
  border-bottom: 1px solid #000000; }

.active-projects table.dataTable thead th {
  padding: 0.75rem 0.9375rem;
  font-size: 13px;
  color: #222B40;
  background: #F0F4F9; }

.active-projects table.dataTable tbody td {
  padding: 0.45rem 0.8375rem;
  font-size: 13px;
  font-weight: 400;
  border-bottom: 1px solid #eeee; }

.active-projects table.dataTable tbody tr td:first-child {
  color: black;
  font-weight: 500; }

.active-projects .dataTables_info {
  margin-left: 12px; }

.to-dodroup {
  height: 348px; }
  .to-dodroup .sub-card {
    border-bottom: 1px solid #E6E6E6; }
    .to-dodroup .sub-card .d-items {
      padding: 10px 20px;
      background-color: #FFF; }
      .to-dodroup .sub-card .d-items span {
        font-weight: 500; }
      .to-dodroup .sub-card .d-items .d-items-2 {
        display: flex;
        align-items: baseline; }
        .to-dodroup .sub-card .d-items .d-items-2 svg {
          margin-right: 10px; }
        .to-dodroup .sub-card .d-items .d-items-2 label {
          font-size: 14px;
          font-weight: 400;
          color: #111828; }
      .to-dodroup .sub-card .d-items .icon-box {
        cursor: pointer; }
      .to-dodroup .sub-card .d-items .form-check-input:checked {
        border-color: black; }
      .to-dodroup .sub-card .d-items .form-check-input:checked[type="checkbox"] {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e"); }
    .to-dodroup .sub-card:last-child {
      border-bottom: 0; }
      .to-dodroup .sub-card:last-child .d-items {
        padding-bottom: 0; }

.schedules-cal .datepicker-days .picker-switch {
  font-size: 15px;
  font-weight: 600;
  color: #111828;
  pointer-events: none; }

.schedules-cal .datepicker-days .prev i,
.schedules-cal .datepicker-days .next i {
  height: 32px;
  width: 32px;
  border-radius: 0.625rem;
  background: var(--primary-light);
  color: #111828;
  line-height: 32px; }

.schedules-cal .datepicker-days .prev:hover,
.schedules-cal .datepicker-days .next:hover {
  background: transparent; }

.schedules-cal .datepicker-days .dow {
  font-weight: 400;
  border-radius: 0;
  color: #111828; }

.schedules-cal .datepicker-days tr .day.weekend:first-child {
  color: var(--bs-body-color); }

.schedules-cal .datepicker-days .day {
  color: #111828;
  height: 46px;
  width: 34px;
  line-height: 20px;
  position: relative; }
  .schedules-cal .datepicker-days .day:hover {
    box-shadow: none; }
  .schedules-cal .datepicker-days .day.old, .schedules-cal .datepicker-days .day.new {
    opacity: 0.3; }

.schedules-cal .datepicker-days td,
.schedules-cal .datepicker-days .active,
.schedules-cal .datepicker-days .today {
  background: transparent !important;
  color: #111828 !important;
  position: relative;
  z-index: 1;
  font-weight: 600; }
  .schedules-cal .datepicker-days td:before,
  .schedules-cal .datepicker-days .active:before,
  .schedules-cal .datepicker-days .today:before {
    content: "";
    background: var(--primary-light);
    width: 40px;
    height: 40px;
    border: 0;
    border-radius: 0.625rem;
    z-index: -1;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -20px;
    margin-left: -20px;
    transition: all 0.2s;
    opacity: 1; }

.schedules-cal .datepicker-days td:before {
  opacity: 0; }

.schedules-cal .datepicker-days td:hover:before {
  opacity: 1; }

.schedules-cal .datepicker table thead tr th:hover {
  background: transparent; }

.schedules-cal .bootstrap-datetimepicker-widget a[data-action] {
  display: none; }

.schedules-cal .bootstrap-datetimepicker-widget table td span, .schedules-cal .bootstrap-datetimepicker-widget table td i {
  width: 35px;
  height: 23px;
  line-height: 23px; }

.schedules-cal .bootstrap-datetimepicker-widget table td.weekend {
  height: 40px;
  width: 40px; }

.events {
  position: relative; }
  .events h6, .events .h6 {
    font-size: 16px;
    color: var(--secondary);
    padding: 11px 0px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding-bottom: 0;
    margin-bottom: 0;
    text-transform: capitalize; }
  .events .event-box {
    height: 55px;
    width: 50px;
    line-height: 9px;
    border-radius: 0.625rem;
    background: var(--primary-light);
    text-align: center;
    padding: 4px 0; }
    .events .event-box h5, .events .event-box .h5 {
      color: var(--secondary);
      font-weight: 600;
      font-size: 20px; }
    .events .event-box span {
      color: #666666; }
  .events .event-data h5, .events .event-data .h5 {
    font-size: 15px; }
    .events .event-data h5 a, .events .event-data .h5 a {
      color: var(--secondary); }
  .events .event-data span {
    font-size: 13px; }
  @media only screen and (max-width: 74.9375rem) {
    .events .event-data h5, .events .event-data .h5 {
      font-size: 12px; } }
  .events .event-media {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #eee; }
    .events .event-media span {
      color: #111828;
      font-size: 13px; }
    .events .event-media:last-child {
      border-bottom: 0; }

.event-scroll {
  padding: 0 1.25rem;
  padding-top: 26px; }

.user-comment {
  font-size: 16px;
  margin-bottom: 0;
  line-height: 1.5; }

.review-slider h6, .review-slider .h6 {
  font-size: 15px;
  font-weight: 500; }

.box-warpper {
  padding-right: 26.2rem; }
  @media only screen and (max-width: 100rem) {
    .box-warpper {
      padding-right: 0; } }

.calendar-warpper {
  position: absolute;
  width: var(--right-space);
  right: 1px;
  top: 6.25rem;
  height: calc(100vh - 5.7rem);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s; }
  .calendar-warpper .card {
    border-right: 0;
    border-top: 0;
    border-bottom: 0;
    border-radius: 0 0 20px 0px; }
  @media only screen and (max-width: 100rem) {
    .calendar-warpper {
      right: -27rem; }
      .calendar-warpper.active {
        right: 0;
        z-index: 3; } }

.group-list {
  padding: 25px 0px;
  padding-bottom: 7px; }

.friend-list1 .friend-user {
  text-align: center;
  padding: 14px 16px;
  margin-right: 11px;
  border-radius: 10px; }
  .friend-list1 .friend-user p {
    font-size: 13px;
    font-weight: 500;
    color: #252525;
    margin-bottom: 0;
    margin-top: 12px; }
  .friend-list1 .friend-user:hover {
    background: #eee; }
  @media only screen and (max-width: 35.9375rem) {
    .friend-list1 .friend-user {
      margin-right: 0; } }

.grouth {
  margin-top: 2.4rem; }

.header-profile2 {
  position: relative; }
  .header-profile2 .profile-box {
    position: absolute;
    background: #000;
    width: 326px;
    right: -18px;
    padding: 15px 20px;
    border-radius: 20px 0 0px 20px;
    top: 73px;
    opacity: 0;
    visibility: hidden; }
    .header-profile2 .profile-box.active {
      opacity: 1;
      transition: opacity 0.5s linear;
      visibility: visible; }
    @media only screen and (max-width: 100rem) {
      .header-profile2 .profile-box {
        top: 66px; } }
    @media only screen and (max-width: 35.9375rem) {
      .header-profile2 .profile-box {
        width: 260px; } }
  .header-profile2 .account-setting .ai-icon {
    display: block;
    padding: 16px 0;
    font-size: 16px;
    color: white; }
  .header-profile2 .products {
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    display: flex;
    align-items: center;
    padding-bottom: 1rem; }
    .header-profile2 .products .border-img {
      position: relative; }
      .header-profile2 .products .border-img:after {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        background-image: url(../images/round.svg);
        background-repeat: no-repeat;
        height: 100%;
        width: 100%; }
    .header-profile2 .products img {
      height: 65px;
      width: 65px;
      border-radius: 50%;
      padding: 6px; }
    .header-profile2 .products .badge {
      background: #FFBF25;
      font-size: 11px;
      font-weight: 700;
      padding: 3px 8px; }
    .header-profile2 .products h6, .header-profile2 .products .h6 {
      color: #fff; }

.dz-layout {
  background: #38445C;
  width: 54px;
  min-width: 54px;
  height: 30px;
  border-radius: 16px;
  display: flex;
  position: relative;
  justify-content: space-around;
  padding: 0 2px;
  align-items: center;
  z-index: 1;
  cursor: pointer; }
  .dz-layout.light .sun {
    color: black; }
  .dz-layout.light .moon {
    color: white; }
  .dz-layout.light:after {
    transform: translateX(0px); }
  .dz-layout.dark .sun {
    color: white; }
  .dz-layout.dark .moon {
    color: black; }
  .dz-layout.dark:after {
    transform: translateX(24px); }
  .dz-layout i {
    display: block;
    line-height: 28px;
    text-align: center;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s; }
  .dz-layout:after {
    background: var(--primary-light);
    border-radius: 24px;
    content: "";
    left: 3px;
    position: absolute;
    z-index: -1;
    top: 3px;
    height: 24px;
    width: 24px;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s; }

@media only screen and (max-width: 47.9375rem) {
  .header-info {
    display: none; } }

.show-more-btn {
  padding-left: 0rem !important; }

.btn-close i {
  display: none; }

.open-cal {
  display: none; }
  @media only screen and (max-width: 100rem) {
    .open-cal {
      display: block; } }

@media only screen and (max-width: 35.9375rem) {
  #overiewChart {
    margin-left: -6px; } }

.diposit-bg .icon-box {
  background-color: rgba(255, 255, 255, 0.5); }
  @media only screen and (max-width: 100rem) {
    .diposit-bg .icon-box {
      line-height: 1.85rem; } }

.switch-btn {
  padding: 40px 30px;
  border-top: 1px solid rgba(33, 44, 69, 0.2);
  font-size: 16px;
  font-weight: 400; }
  .switch-btn a svg {
    margin-right: 30px;
    margin-top: -4px; }
  .switch-btn a:hover span {
    color: var(--primary); }
  .switch-btn a:hover svg path {
    stroke: var(--primary); }
  .switch-btn button svg {
    margin-right: 30px;
    margin-top: -4px; }
  .switch-btn button:hover span {
    color: var(--primary); }
  .switch-btn button:hover svg path {
    stroke: var(--primary); }
  .switch-btn .logout-side-btn {
    background-color: transparent;
    border: 0; }
    .switch-btn .logout-side-btn:hover {
      color: #fff; }

.border-progress .progress {
  height: 14px !important; }

.table {
  --bs-table-striped-bg:$white; }

[data-primary="color_13"] .d-items .form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e") !important; }

#projectChart .apexcharts-tooltip.apexcharts-theme-dark {
  color: black; }

[data-sidebarbg="color_1"] .logo-abbr .logo-react {
  fill: var(--primary); }

[data-sidebarbg="color_1"] .logo-abbr path {
  fill: #fff; }

[data-sidebarbg="color_1"] .brand-title path {
  fill: var(--secondary); }

.mySwiper .swiper-slide {
  height: auto; }

@media only screen and (max-width: 35.9375rem) {
  .active-projects .paging_simple_numbers.dataTables_paginate {
    margin-left: 6rem; } }

#marketChart .apexcharts-text tspan,
#customerChart .apexcharts-text tspan,
#Statistics .apexcharts-text tspan,
#chartBarRunning .apexcharts-text tspan {
  fill: #666666;
  font-size: 12px; }

.market-update tbody tr:last-child td {
  border-bottom: 0;
  padding-bottom: 0; }

.dz-calender {
  position: relative;
  z-index: 1; }
  .dz-calender .react-datepicker {
    border: unset; }
    .dz-calender .react-datepicker__header {
      border: unset;
      background-color: #fff; }
      .dz-calender .react-datepicker__header .react-datepicker__day-names {
        color: var(--secondary);
        margin-bottom: -15px; }
    .dz-calender .react-datepicker .react-datepicker__triangle:before {
      box-shadow: 0 0 16px 0px rgba(0, 0, 0, 0.09);
      border-bottom-color: transparent; }
    .dz-calender .react-datepicker__navigation {
      top: 12px;
      line-height: 20px;
      background: var(--primary-light);
      border-radius: 0.625rem; }
    .dz-calender .react-datepicker__navigation--previous {
      left: 10px; }
    .dz-calender .react-datepicker__navigation--next {
      right: 10px; }
    .dz-calender .react-datepicker__navigation-icon:before {
      border-color: var(--secondary);
      border-width: 2px 2px 0 0; }
    .dz-calender .react-datepicker__current-month {
      padding: 10px 0px;
      margin: 0 20px;
      font-size: 15px;
      font-weight: 600;
      color: var(--secondary);
      pointer-events: none; }
  .dz-calender .react-datepicker__day-name, .dz-calender .react-datepicker__day, .dz-calender .react-datepicker__time-name {
    width: 50px;
    height: 40px;
    line-height: 44px;
    font-size: 13px;
    color: var(--secondary);
    margin: 0;
    font-family: 'poppins', sans-serif; }
  .dz-calender .react-datepicker__day--selected {
    background: var(--primary-light) !important;
    color: var(--secondary) !important; }
  .dz-calender .react-datepicker__day {
    font-weight: 600; }
  @media only screen and (max-width: 47.9375rem) {
    .dz-calender {
      display: none; } }
  .dz-calender svg path {
    fill: var(--primary); }
  .dz-calender .react-datepicker-wrapper {
    width: auto; }

.custom-react-select > div:nth-child(4) > div {
  background-color: #fff; }
  .custom-react-select > div:nth-child(4) > div > div {
    background-color: #fff;
    cursor: pointer;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s; }
    .custom-react-select > div:nth-child(4) > div > div:hover {
      background-color: var(--rgba-primary-1);
      color: #000; }

.custom-react-select > div:nth-child(3) {
  height: 3rem;
  background-color: #fff;
  border-radius: .5rem;
  border-color: #e9e2f8 !important; }
  @media only screen and (max-width: 87.5rem) {
    .custom-react-select > div:nth-child(3) {
      height: 2.5rem; } }

.custom-react-select > div:nth-child(4) {
  border-radius: 10px;
  min-width: 150px;
  overflow: hidden;
  box-shadow: 0px 10px 40px 0px rgba(21, 50, 93, 0.1); }

.custom-react-select > div > div:first-child div {
  font-size: 0.875rem;
  color: #6e6e6e; }

.custom-react-select > div > div:nth-child(2) > span {
  display: none; }

.btn-small {
  font-size: 0.813rem;
  padding: 5px 12px;
  font-weight: 400;
  border-radius: 0.25rem;
  line-height: 18px;
  border-radius: 0.25rem; }

.photo-content {
  position: relative; }
  .photo-content .cover-photo {
    background: url(../images/profile/12.jpg);
    background-size: cover;
    background-position: center;
    min-height: 15.625rem;
    width: 100%;
    border-top-left-radius: 0.625rem;
    border-top-right-radius: 0.625rem; }

.user .user-info {
  display: flex;
  padding: 0.9375rem 1.25rem 1.5rem; }
  @media only screen and (max-width: 47.9375rem) {
    .user .user-info {
      flex-wrap: wrap; } }
  @media only screen and (max-width: 35.9375rem) {
    .user .user-info {
      display: block; } }
  .user .user-info .user-details {
    display: flex;
    justify-content: space-between;
    width: 100%; }
    @media only screen and (max-width: 35.9375rem) {
      .user .user-info .user-details {
        display: block; } }
    .user .user-info .user-details .user-contact {
      display: flex;
      align-items: center;
      padding-top: 1.125rem;
      flex-wrap: wrap; }
      .user .user-info .user-details .user-contact .user-number {
        display: flex;
        align-items: center;
        margin-right: 1.5rem;
        margin-bottom: 0.625rem; }
        @media only screen and (max-width: 35.9375rem) {
          .user .user-info .user-details .user-contact .user-number {
            margin-bottom: 0.875rem; } }
      .user .user-info .user-details .user-contact .user-email {
        display: flex;
        align-items: center;
        margin-bottom: 0.625rem; }
        @media only screen and (max-width: 35.9375rem) {
          .user .user-info .user-details .user-contact .user-email {
            margin-bottom: 0.875rem; } }
      .user .user-info .user-details .user-contact h4, .user .user-info .user-details .user-contact .h4 {
        margin: 0; }

.user-photo {
  min-width: 150px;
  height: 150px;
  position: relative;
  z-index: 1;
  margin-top: -4.5rem;
  margin-right: 0.625rem; }
  .user-photo img {
    border: 4px solid #fff;
    width: 100%;
    height: 100%;
    object-fit: cover; }
  @media only screen and (max-width: 35.9375rem) {
    .user-photo {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 1.25rem;
      margin-top: -6.5rem;
      width: 150px; } }

[direction="rtl"] .user-photo {
  left: auto;
  right: 0;
  margin-right: 0;
  margin-left: 0.9375rem; }
  @media only screen and (max-width: 74.9375rem) {
    [direction="rtl"] .user-photo {
      right: 0.9375rem; } }
  @media only screen and (max-width: 35.9375rem) {
    [direction="rtl"] .user-photo {
      width: 5rem;
      right: calc(50% - 2.5rem);
      top: -6.25rem; } }

.profile-interest .row {
  margin: 0 -0.0437rem; }
  .profile-interest .row .int-col {
    padding: 0 0.0437rem; }
    .profile-interest .row .int-col .interest-cat {
      margin-bottom: 0.0875rem;
      position: relative;
      display: block; }
      .profile-interest .row .int-col .interest-cat:after {
        background: #000;
        bottom: 0;
        content: "";
        left: 0;
        opacity: 0.5;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 0; }
      .profile-interest .row .int-col .interest-cat p {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        padding: 0.3125rem;
        left: 0;
        margin: 0;
        z-index: 1;
        color: white;
        font-size: 0.075rem; }

.profile-tab .nav-item .nav-link {
  font-size: 1rem;
  margin-right: 1.875rem;
  transition: all 0.5s ease-in-out;
  border: none;
  border-bottom: 0.0125rem solid transparent;
  color: #828690; }
  .profile-tab .nav-item .nav-link:hover, .profile-tab .nav-item .nav-link.active {
    border: 0;
    background: transparent;
    background: transparent;
    border-bottom: 0.0125rem solid var(--primary);
    color: var(--primary); }
  @media only screen and (max-width: 35.9375rem) {
    .profile-tab .nav-item .nav-link {
      margin-right: 0rem; } }

.side-detail {
  display: flex; }
  .side-detail .edit-profiil {
    margin-right: 0.625rem; }

.post-input {
  margin-bottom: 1.875rem; }
  .post-input .form-control {
    height: 47px;
    font-weight: 400;
    margin: 0.9375rem 0; }

.btn-social {
  font-size: 1.25rem;
  height: 3.4375rem;
  display: inline-block;
  padding: 0;
  text-align: center;
  border-radius: 0.625rem;
  color: #fff;
  width: 3.4375rem;
  line-height: 3.375rem; }
  .btn-social.facebook {
    background-color: #3B5998; }
  .btn-social.google-plus {
    background-color: #DE4E43; }
  .btn-social.linkedin {
    background-color: #007BB6; }
  .btn-social.instagram {
    background-color: #8A5A4E; }
  .btn-social.twitter {
    background-color: #1EA1F3; }
  .btn-social.youtube {
    background-color: #CE201F; }
  .btn-social.whatsapp {
    background-color: #01C854; }
  .btn-social i {
    margin: 0 !important; }

.profile-uoloaded-post img {
  margin-bottom: 1.25rem; }

.profile-uoloaded-post a h4, .profile-uoloaded-post a .h4 {
  margin-bottom: 0.625rem;
  color: #464a53; }

.prot-blog {
  padding: 30px 20px;
  background-color: var(--primary);
  position: relative;
  overflow: hidden;
  z-index: 0;
  border-radius: 0.625rem; }
  .prot-blog .post .text {
    font-size: 18px;
    font-weight: 400;
    color: white; }
  .prot-blog .fill .text {
    font-size: 20px;
    font-weight: 600;
    color: white; }
  .prot-blog .fill a {
    background-color: white;
    padding: 8px 15px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 0.625rem;
    color: #2A353A; }
    @media only screen and (max-width: 78.125rem) {
      .prot-blog .fill a {
        padding: 5px 5px; } }
    @media only screen and (max-width: 35.9375rem) {
      .prot-blog .fill a {
        padding: 8px 17px; } }
  .prot-blog .text-bla {
    color: white !important;
    font-size: 14px;
    font-weight: 500; }
  .prot-blog p {
    color: white;
    font-weight: 400; }
  .prot-blog .shape {
    position: absolute;
    bottom: -70px;
    right: -50px;
    z-index: -1; }
    .prot-blog .shape svg path {
      fill: #3ab5ff; }

.messages .input-group.search-area {
  flex-direction: row-reverse;
  margin-right: 0.625rem; }
  .messages .input-group.search-area input.form-control {
    border-left: none;
    background-color: #f3f3f3;
    border: 0;
    overflow: hidden;
    height: 3rem;
    padding-left: 0; }
  .messages .input-group.search-area .input-group-text {
    border-right: none;
    background-color: #f3f3f3;
    border: 0;
    height: 3rem;
    padding: 0; }

.messages .add {
  background-color: var(--primary);
  display: block;
  width: 3rem;
  height: 3rem;
  border-radius: 0.625rem;
  display: flex;
  justify-content: center;
  align-items: center; }

.messages .student {
  border-bottom: 1px solid #E6E6E6; }

.pie-chart2 .chart-group {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  @media only screen and (max-width: 85.375rem) {
    .pie-chart2 .chart-group .radius-bar {
      width: 50%; } }
  @media only screen and (max-width: 74.9375rem) {
    .pie-chart2 .chart-group .radius-bar {
      flex: 0 0 50%;
      margin-bottom: 0.875rem; } }
  @media only screen and (max-width: 35.9375rem) {
    .pie-chart2 .chart-group .radius-bar {
      flex: 0 0 100%; } }
  .pie-chart2 .chart-group .donut-chart-sale {
    margin-bottom: 0.625rem; }
    .pie-chart2 .chart-group .donut-chart-sale small, .pie-chart2 .chart-group .donut-chart-sale .small {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 1.125rem;
      font-weight: 600; }

.pie-chart2 .chart-info {
  margin-top: 1rem;
  padding-top: 0.625rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 3px dashed #6666663d; }
  @media only screen and (max-width: 35.9375rem) {
    .pie-chart2 .chart-info {
      flex-wrap: wrap; }
      .pie-chart2 .chart-info .btn {
        margin-top: 0.625rem; } }
  .pie-chart2 .chart-info p {
    margin-bottom: 0; }

.profile-form .form-control,
.profile-form .bootstrap-select .dropdown-toggle {
  height: 45px;
  font-size: 13px;
  border-radius: 0.625rem;
  border-color: #cdcdcd; }
  .profile-form .form-control:active, .profile-form .form-control:focus, .profile-form .form-control:hover,
  .profile-form .bootstrap-select .dropdown-toggle:active,
  .profile-form .bootstrap-select .dropdown-toggle:focus,
  .profile-form .bootstrap-select .dropdown-toggle:hover {
    border-color: var(--primary); }

.profile-form .bootstrap-select > .dropdown-toggle {
  background-color: #fff;
  padding: 12px 20px; }
  @media only screen and (max-width: 35.9375rem) {
    .profile-form .bootstrap-select > .dropdown-toggle {
      padding: 16px 20px; } }
  .profile-form .bootstrap-select > .dropdown-toggle:after {
    font-size: 0.7rem; }

.profile-form .form-label {
  color: #333333;
  font-size: 15px;
  margin-bottom: 12px;
  font-weight: 400; }

.profile-form .btn-light:hover, .profile-form .btn-light:focus {
  color: #888888; }

.profile-card {
  border-radius: 0.625rem; }
  .profile-card .card-header .title {
    position: relative;
    margin-bottom: 0;
    color: #333333; }
    .profile-card .card-header .title:after {
      content: "";
      height: 3px;
      width: 100%;
      background-color: var(--primary);
      position: absolute;
      bottom: -31px;
      left: 0; }
  .profile-card .m-b30 {
    margin-bottom: 30px; }

.author-profile {
  text-align: center; }
  .author-profile .card-body {
    padding: 0; }
  .author-profile .author-media {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    display: inline-block; }
    .author-profile .author-media img {
      width: 130px;
      border-radius: 100%;
      height: 130px;
      object-fit: cover; }
  .author-profile .author-info .title {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 0; }
  .author-profile .author-info span {
    display: block;
    color: #888888; }
  .author-profile .info-list li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 15px;
    border-top: 1px solid #cdcdcd;
    padding: 18px 30px;
    color: #333333; }
    .author-profile .info-list li a {
      color: #333333; }
    .author-profile .info-list li span {
      color: #888888;
      font-weight: 500; }
  .author-profile .card-footer {
    padding: 30px;
    display: block; }
    .author-profile .card-footer .form-control {
      height: 45px;
      border-color: #cdcdcd;
      overflow: hidden;
      line-height: 31px; }
  .author-profile .upload-link {
    position: absolute;
    width: 35px;
    height: 35px;
    line-height: 32px;
    background: var(--primary-light);
    bottom: 0;
    right: 0px;
    box-shadow: 0 0 10px 0 rgba(0, 24, 128, 0.1);
    border-radius: 100%;
    color: var(--text-dark);
    overflow: hidden;
    border: 2px solid #cdcdcd; }
    .author-profile .upload-link .update-flie {
      position: absolute;
      opacity: 0;
      z-index: 0;
      width: 100%;
      cursor: pointer;
      left: 0;
      height: 100%; }

.draggable-mirror {
  padding: 0.938rem 0.938rem !important; }

.logo-header {
  margin-bottom: 2rem; }
  .logo-header .logo-white {
    margin-left: 0.625rem; }

.login-form .forget-pass {
  display: block;
  margin-top: 20px;
  text-align: center; }

.dz-social {
  display: flex;
  justify-content: space-between; }

.dz-social-icon {
  display: inline-flex;
  margin: 0 -3px;
  padding: 0;
  text-align: center; }
  .dz-social-icon li {
    font-size: 12px;
    margin-right: 0.2rem; }
    .dz-social-icon li a {
      color: #ffffff;
      font-size: 14px;
      height: 35px;
      line-height: 34px;
      padding: 0;
      width: 35px;
      border-radius: 4px; }

div#mCSB_1 {
  margin-top: 100px; }

.card-use-box {
  text-align: center; }
  .card-use-box .crd-bx-img {
    position: relative;
    width: 100px;
    margin: auto; }
    .card-use-box .crd-bx-img .active {
      width: 15px;
      height: 15px;
      background: #53D0B2;
      border-radius: 50%;
      left: 6rem;
      position: absolute;
      bottom: 0.4rem; }
      .card-use-box .crd-bx-img .active.deactive {
        background: #FF7C7C; }
    .card-use-box .crd-bx-img img {
      border: 7px solid rgba(62, 95, 206, 0.08); }

.card__info {
  display: flex;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center; }
  .card__info li .card__info__stats {
    display: block;
    color: var(--primary);
    font-size: 1.2rem;
    font-weight: 500;
    padding: 0 30px; }
    @media only screen and (max-width: 87.5rem) {
      .card__info li .card__info__stats {
        padding: 0 25px; } }

.post-pos {
  text-align: center;
  margin: 18px; }
  .post-pos .card__info__stats {
    color: var(--primary); }
  .post-pos li {
    margin-bottom: 10px; }

.user-m-tabe {
  margin-top: 0px; }
  .user-m-tabe .nav-link {
    padding: 3px 8px; }

.personal-info li {
  font-size: 0.9rem;
  font-weight: 500;
  padding: 12px 20px; }
  .personal-info li:hover {
    background: var(--rgba-primary-1); }

.action-button .btn.btn-icon-xxs {
  padding: 0.075rem 0.2375rem; }
  .action-button .btn.btn-icon-xxs i {
    transform: scale(0.8); }

.all_user {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 20px;
  background: var(--rgba-primary-1); }

.all_user1 {
  display: flex;
  justify-content: space-between;
  padding: 9px 30px; }
  .all_user1 .member {
    margin-right: 4rem; }
    .all_user1 .member .heading {
      padding: 0 10px; }

.right-check .custom-checkbox {
  margin-right: 5rem; }
  @media only screen and (max-width: 35.9375rem) {
    .right-check .custom-checkbox {
      margin-right: 1rem; } }

.manage {
  padding: 0 30px;
  margin-top: -10px; }

.role-tble tbody tr td:not(:first-child) .custom-checkbox {
  display: flex;
  justify-content: end; }

.role-tble.table tbody tr td {
  padding: 0.4375rem 0.625rem;
  border: 0; }

.role-tble.table thead tr th {
  padding: 0.4375rem 0.625rem; }

.c-details ul li {
  border-bottom: 1px solid #E6E6E6;
  padding: 10px 0; }
  .c-details ul li p {
    color: black;
    margin: 0; }

.c-social {
  display: flex;
  margin-top: 15px; }
  .c-social li a {
    padding: 5px 10px;
    margin: 0 7px;
    border-radius: 0.25rem; }
    .c-social li a:first-child {
      margin-left: 0; }

.c-busiess .avatar {
  height: 60px;
  width: 60px;
  margin-right: 10px; }

.c-card-details ul li {
  display: flex;
  padding: 6px 0px; }
  .c-card-details ul li h6, .c-card-details ul li .h6 {
    margin-left: 5px;
    margin-bottom: 0;
    font-size: 13px; }

.c-work {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .c-work .c-task {
    padding: 7px 20px;
    border-radius: 0.25rem;
    margin: 0 6px; }
    .c-work .c-task p {
      margin-bottom: 0;
      color: white;
      display: flex;
      align-items: center; }
    .c-work .c-task span {
      color: white; }
    @media only screen and (max-width: 35.9375rem) {
      .c-work .c-task {
        padding: 7px 12px; } }
    .c-work .c-task:first-child {
      margin-left: 0; }

.c-primium li {
  display: flex;
  justify-content: space-between;
  padding: 6px 0px; }
  .c-primium li h6, .c-primium li .h6 {
    margin-bottom: 0;
    font-size: 13px; }

.c-status i {
  transform: scale(0.5);
  margin-right: 5px; }

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .contect-card {
    width: 33.33%; } }

.add-visit h3, .add-visit .h3 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0; }
  @media only screen and (max-width: 100rem) {
    .add-visit h3, .add-visit .h3 {
      font-size: 16px; } }

.sale-card .card-footer .tag {
  border-bottom-right-radius: 18px;
  border-top-left-radius: 80px;
  bottom: 0;
  cursor: pointer;
  height: 40px;
  position: absolute;
  right: 0;
  width: 40px; }
  .sale-card .card-footer .tag svg {
    color: #000;
    height: 22px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: 11px;
    width: 22px; }
  .sale-card .card-footer .tag.bg-danger-light svg {
    transform: rotate(164deg); }

.active-projects thead tr th {
  padding: 0.75rem 0.9375rem;
  font-size: 13px;
  color: #222B40;
  background: #F0F4F9; }

.active-projects tbody tr td {
  padding: 0.45rem 0.8375rem; }

.card-use-box {
  text-align: center; }
  .card-use-box .crd-bx-img {
    position: relative;
    width: 100px;
    margin: auto; }
    .card-use-box .crd-bx-img .active {
      width: 15px;
      height: 15px;
      background: #53D0B2;
      border-radius: 50%;
      left: 5.4rem;
      position: absolute;
      bottom: 0.4rem; }
      .card-use-box .crd-bx-img .active.deactive {
        background: #FF7C7C; }
    .card-use-box .crd-bx-img img {
      border: 7px solid rgba(62, 95, 206, 0.08); }
  @media only screen and (min-width: 1400px) and (max-width: 1500px) {
    .card-use-box .btn {
      padding: 0.625rem 1rem; } }

.sales-map {
  height: 18rem !important; }

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .same-card {
    width: 100%; } }

.c-con h4 img, .c-con .h4 img {
  width: 22px;
  margin-left: 8px; }

.c-con-prise {
  margin-top: 18px; }
  .c-con-prise h3, .c-con-prise .h3 {
    font-weight: 600; }

.c-con-3d {
  position: relative; }
  .c-con-3d img {
    position: absolute;
    right: 0px;
    top: -28px;
    width: 172px; }
    @media only screen and (max-width: 1600px) {
      .c-con-3d img {
        right: -7px;
        top: -10px;
        width: 150px; } }
    @media only screen and (max-width: 1500px) {
      .c-con-3d img {
        width: 111px; } }
    @media only screen and (max-width: 87.5rem) {
      .c-con-3d img {
        width: 73px; } }
    @media only screen and (max-width: 74.9375rem) {
      .c-con-3d img {
        width: 145px; } }
    @media only screen and (max-width: 626px) {
      .c-con-3d img {
        width: 100px; } }

.crm-cart {
  height: 200px;
  overflow: hidden; }
  .crm-cart .card-header span i {
    transform: scale(0.8); }
  .crm-cart #columnChart {
    margin-top: -61px;
    margin-left: -21px; }
  .crm-cart h4, .crm-cart .h4 {
    font-size: 24px;
    font-weight: 700; }
    .crm-cart h4 small, .crm-cart .h4 small, .crm-cart h4 .small, .crm-cart .h4 .small {
      font-size: 13px; }

.crm-cart-data p {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 0;
  color: black; }

.lang-chart {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem; }
  .lang-chart li {
    font-weight: 400;
    color: black; }
    .lang-chart li i {
      color: var(--secondary);
      margin-right: 10px; }

.crm-p-list {
  display: flex;
  justify-content: space-between;
  margin-top: -59px;
  margin-bottom: 26px; }
  @media only screen and (max-width: 47.9375rem) {
    .crm-p-list {
      margin-top: 0; } }
  @media only screen and (max-width: 35.9375rem) {
    .crm-p-list {
      margin-bottom: 12px; } }

.c-line {
  position: relative; }
  .c-line:before {
    position: absolute;
    content: "";
    top: 0;
    left: -10px;
    background: #000;
    height: 141%;
    top: -89px;
    width: 1px; }
  @media only screen and (max-width: 47.9375rem) {
    .c-line {
      margin-bottom: 25px; } }

.p-list {
  display: flex;
  margin-bottom: 14px; }
  .p-list .icon-box {
    line-height: 1.85rem; }
  .p-list h6, .p-list .h6 {
    font-size: 15px;
    font-weight: 500; }
  .p-list span {
    font-size: 12px; }

.project-scroll {
  height: 250px; }

.sociallinks li {
  margin-bottom: 20px; }
  .sociallinks li:last-child {
    margin-bottom: 0; }

.crm-ads-list li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px; }
  .crm-ads-list li h6, .crm-ads-list li .h6 {
    margin-bottom: 0; }
  .crm-ads-list li span {
    font-weight: 500; }

.dots-crm {
  display: flex;
  align-items: center; }
  .dots-crm .dots {
    display: inline-block;
    width: 0.625rem;
    height: 0.625rem;
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 5px; }

#ExtraData {
  margin-top: -43px; }

.payment-tbl thead tr th {
  font-size: 13px;
  padding: 0.625rem 1.25rem;
  font-weight: 500;
  background-color: var(--rgba-primary-1);
  border: 0;
  vertical-align: middle;
  color: var(--secondary); }

.payment-tbl tbody tr td {
  font-size: 13px;
  font-weight: 400;
  padding: 0.625rem 1.25rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
  .payment-tbl tbody tr td h6, .payment-tbl tbody tr td .h6 {
    margin-bottom: 0; }

@media only screen and (min-width: 1199px) and (max-width: 1600px) {
  .clm-chart {
    display: none; } }

.any-card {
  display: flex;
  justify-content: space-between; }
  .any-card .harry-img {
    height: 200px;
    transform: scale(1.1);
    position: absolute;
    right: 32px;
    bottom: 0; }
    @media only screen and (max-width: 1600px) {
      .any-card .harry-img {
        height: 165px; } }
    @media only screen and (max-width: 1326px) {
      .any-card .harry-img {
        height: 140px; } }
    @media only screen and (max-width: 35.9375rem) {
      .any-card .harry-img {
        height: 97px;
        right: 14px;
        bottom: 6px; } }
  .any-card .c-con {
    width: 50%; }
    .any-card .c-con p {
      margin-bottom: 44px; }
    @media only screen and (max-width: 1326px) {
      .any-card .c-con p {
        margin-bottom: 12px; } }
    @media only screen and (max-width: 35.9375rem) {
      .any-card .c-con {
        width: 64%; } }

.sales-bx {
  text-align: center;
  border-radius: 0.625rem;
  background: rgba(255, 255, 255, 0.4) !important;
  min-width: 140px; }
  .sales-bx img {
    width: 45px; }
  .sales-bx h4, .sales-bx .h4 {
    margin-bottom: 0;
    margin-top: 6px;
    font-weight: 600;
    color: black; }
  .sales-bx span {
    color: black; }

.analytics-card .ic-n-bx {
  text-align: center;
  margin-top: -38px;
  margin-bottom: 20px; }
  @media only screen and (max-width: 35.9375rem) {
    .analytics-card .ic-n-bx {
      margin-top: -33px;
      margin-bottom: 6px; } }
  .analytics-card .ic-n-bx .icon-box {
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
    line-height: 2.575rem; }
    .analytics-card .ic-n-bx .icon-box i {
      transform: scale(1.3);
      color: var(--secondary); }

.analytics-card .ana-box {
  text-align: center; }
  .analytics-card .ana-box .anta-data h3, .analytics-card .ana-box .anta-data .h3 {
    margin-top: 24px;
    margin-bottom: 0;
    font-weight: 700; }

@media only screen and (max-width: 74.9375rem) {
  .analytics-card .mt-4 {
    margin-top: 0 !important; } }

.analytics-card p {
  color: black; }

.ov-card {
  cursor: pointer; }
  .ov-card:hover {
    background: var(--primary-light) !important;
    border-color: var(--primary-light); }
    .ov-card:hover h5, .ov-card:hover .h5, .ov-card:hover span, .ov-card:hover h3, .ov-card:hover .h3 {
      color: #000; }
    .ov-card:hover .icon-box {
      background-color: #000 !important; }
      .ov-card:hover .icon-box i {
        color: var(--primary) !important; }

.country-sale {
  height: 355px; }
  .country-sale .country-flag img {
    width: 40px; }
  .country-sale li {
    margin-bottom: 12px;
    padding: 0 1.25rem; }

.recent-activity .active-data h5, .recent-activity .active-data .h5 {
  font-size: 14px; }

.recent-activity .active-data span {
  font-size: 12px; }

.activity-sale {
  height: 350px;
  padding: 0 20px; }
  .activity-sale .recent-activity {
    margin-bottom: 12px;
    position: relative;
    align-items: center; }
    .activity-sale .recent-activity:after {
      position: absolute;
      content: "";
      top: 1.888rem;
      left: 0.3rem;
      width: 0.063rem;
      height: 100%;
      background: #e4e4e4; }
      @media only screen and (max-width: 61.9375rem) {
        .activity-sale .recent-activity:after {
          top: 2.788rem;
          left: 0.4rem; } }
    .activity-sale .recent-activity:last-child:after {
      content: none; }

.chartBar1 {
  margin-top: -31px;
  margin-left: -21px; }

.sales-data h4, .sales-data .h4 {
  font-size: 15px; }

.sales-data p {
  line-height: 1.3; }

.earning-chart {
  margin-top: -31px;
  margin-left: 60px; }

@media only screen and (max-width: 74.9375rem) {
  .social-cards {
    margin-bottom: 10px; } }

.blog-card {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s; }
  .blog-card h4, .blog-card .h4 {
    font-size: 20px; }
    @media only screen and (max-width: 35.9375rem) {
      .blog-card h4, .blog-card .h4 {
        font-size: 16px; } }
  .blog-card img {
    margin-bottom: 10px; }
    @media only screen and (max-width: 35.9375rem) {
      .blog-card img {
        width: 45px; } }
  .blog-card:hover {
    transform: translate(0, -10px); }

@media only screen and (max-width: 35.9375rem) {
  .c-busiess {
    margin-bottom: 10px; } }

.blog-img {
  position: relative; }
  .blog-img .blk-img {
    width: 100%;
    height: 575px;
    object-fit: cover;
    border-radius: 0.625rem; }
    @media only screen and (max-width: 35.9375rem) {
      .blog-img .blk-img {
        height: 350px; } }
  .blog-img .blk-img2 {
    width: 100%;
    height: 400px;
    object-fit: cover;
    border-radius: 0.625rem; }
    @media only screen and (max-width: 35.9375rem) {
      .blog-img .blk-img2 {
        height: 300px; } }
  .blog-img .blog-text {
    position: absolute;
    bottom: 0;
    padding: 20px 15px; }
    .blog-img .blog-text h2, .blog-img .blog-text .h2 {
      font-size: 30px;
      line-height: 1.1;
      color: white;
      font-weight: 600; }
      @media only screen and (max-width: 74.9375rem) {
        .blog-img .blog-text h2, .blog-img .blog-text .h2 {
          font-size: 20px; } }
  .blog-img.style-1 .blk-img {
    height: 275px; }
  .blog-img.style-1 .blog-text h2, .blog-img.style-1 .blog-text .h2 {
    font-size: 16px;
    font-weight: 500; }

.blog-post .post-1 {
  display: flex;
  align-items: center;
  padding-bottom: 11px;
  padding-top: 11px;
  border-bottom: 1px solid #eee; }
  .blog-post .post-1 .custome-avatar {
    height: 90px;
    width: 90px;
    border-radius: 50%; }
    @media only screen and (max-width: 74.9375rem) {
      .blog-post .post-1 .custome-avatar {
        height: 70px;
        width: 70px; } }
  .blog-post .post-1 .post-data h4, .blog-post .post-1 .post-data .h4 {
    font-size: 15px;
    margin-top: 5px;
    margin-bottom: 7px; }
    @media only screen and (max-width: 35.9375rem) {
      .blog-post .post-1 .post-data h4, .blog-post .post-1 .post-data .h4 {
        font-size: 14px; } }
    .blog-post .post-1 .post-data h4 a, .blog-post .post-1 .post-data .h4 a {
      color: black; }
  .blog-post .post-1 .post-data span {
    font-size: 12px;
    color: var(--text-dark); }

@media only screen and (max-width: 74.9375rem) {
  .blog-post {
    margin-top: 10px; } }

.small-post {
  height: 1.275rem !important;
  width: 1.275rem !important; }

.seconday-post {
  margin-top: 25px;
  display: flex; }
  @media only screen and (max-width: 35.9375rem) {
    .seconday-post {
      flex-flow: wrap; } }
  .seconday-post img {
    width: 160px;
    height: 130px;
    border-radius: 0.625rem;
    margin-right: 18px;
    object-fit: cover; }
    @media only screen and (max-width: 35.9375rem) {
      .seconday-post img {
        width: 100%;
        height: 200px; } }
  .seconday-post h4, .seconday-post .h4 {
    font-size: 13px;
    margin-top: 6px; }
  .seconday-post span {
    font-size: 12px;
    color: var(--text-dark); }
  @media only screen and (max-width: 35.9375rem) {
    .seconday-post .post-data {
      margin-top: 15px; } }

.third-post {
  display: flex;
  align-items: self-start;
  margin-bottom: 21px; }
  @media only screen and (max-width: 74.9375rem) {
    .third-post {
      flex-flow: wrap;
      align-items: center; } }
  .third-post img {
    width: 300px;
    height: 180px;
    border-radius: 0.375rem;
    margin-right: 20px;
    object-fit: cover; }
    @media only screen and (max-width: 74.9375rem) {
      .third-post img {
        width: 100%;
        height: 220px;
        margin-right: 0px; } }
  .third-post h4, .third-post .h4 {
    font-size: 15px;
    margin-top: 6px; }
    .third-post h4 a, .third-post .h4 a {
      color: black; }
  .third-post.style-1 {
    margin-top: 14px; }
    .third-post.style-1 img {
      width: 90px;
      height: 80px; }
      @media only screen and (max-width: 74.9375rem) {
        .third-post.style-1 img {
          margin-right: 10px; } }
    .third-post.style-1 h4, .third-post.style-1 .h4 {
      margin-top: 0;
      margin-bottom: 0; }
  .third-post.style-2 {
    margin-bottom: 15px;
    border-bottom: 1px solid #cdcdcd; }
    .third-post.style-2 .av-post {
      height: 40px;
      width: 40px; }
    .third-post.style-2 .post-data {
      margin-bottom: 14px; }
    @media only screen and (max-width: 35.9375rem) {
      .third-post.style-2 {
        margin-bottom: 0; } }
    .third-post.style-2:last-child {
      border-bottom: 0; }
  @media only screen and (max-width: 74.9375rem) {
    .third-post .post-data {
      margin-top: 15px; } }

.notifiy {
  background-color: var(--rgba-primary-1);
  position: relative;
  margin-top: 40px; }
  .notifiy .icon-box {
    position: absolute;
    top: -12px;
    left: 46%; }
    @media only screen and (max-width: 35.9375rem) {
      .notifiy .icon-box {
        height: 40px;
        width: 40px;
        line-height: 40px; } }
  .notifiy .notify-data {
    text-align: center;
    padding: 20px 0;
    padding-bottom: 0; }
    .notifiy .notify-data span {
      font-size: 12px; }

.c-social {
  display: flex;
  margin-top: 8px; }
  .c-social li a {
    padding: 5px 10px;
    margin: 0 7px;
    border-radius: 0.25rem; }
  .c-social li:hover {
    transform: scale(1.2);
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s; }

.btn-reveal-trigger .avatar {
  display: flex;
  align-items: center; }

.header-info {
  margin-left: 8px;
  margin-top: 8px; }

/*
===================================
    Product detail
===================================*/
.produtct-detail-tag {
  display: inline-block; }
  .produtct-detail-tag a {
    font-style: 0.8125rem;
    color: #333333; }

.product-detail-content .item-tag {
  background: #828690;
  border-radius: 0.375rem;
  display: inline-block;
  font-size: 0.75rem;
  margin-right: 0.25rem;
  padding: 0.125rem 0.75rem;
  color: #fff; }

.filtaring-area h4, .filtaring-area .h4 {
  color: #1d1d1d;
  font-size: 1rem;
  font-weight: 400;
  text-transform: lowercase; }

.plus-minus-input .input-icon {
  font-size: 0.8125rem;
  color: #333333; }

.plus-minus-input {
  display: flex;
  width: 7.5rem; }
  .plus-minus-input .custom-btn {
    border-radius: 0;
    height: 2.5rem;
    padding: 0.75rem 0.5rem;
    background: #fff;
    border: 0.0625rem solid #E6E6E6; }
  .plus-minus-input .form-control:hover, .plus-minus-input .form-control:focus, .plus-minus-input .form-control:active {
    border: 0.0625rem solid #E6E6E6; }

.btn-reveal-trigger .avatar-xl {
  min-width: 1.875rem; }

.share-view {
  display: inline-block; }
  .share-view ul li {
    display: inline-block; }
  .share-view .share-icon {
    width: 2.5rem;
    height: 2.5rem;
    display: inline-block;
    border: 0.0625rem solid #E6E6E6;
    text-align: center;
    line-height: 2.5rem;
    font-style: 1rem;
    color: #E6E6E6;
    margin-right: 0.5rem; }

/*--------tab----------*/
.veritical-line {
  padding: 1.25rem 1.875rem;
  border-top: 0.0625rem solid #E6E6E6;
  border-right: 0.0625rem solid #E6E6E6;
  border-bottom: 0.0625rem solid #E6E6E6;
  position: relative; }
  .veritical-line:before {
    background: #E6E6E6;
    bottom: 0;
    content: "";
    height: 100%;
    left: -0.0625rem;
    max-height: 40%;
    position: absolute;
    width: 0.0625rem; }

.tab-content-text p {
  color: #333333;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5rem;
  margin-bottom: 1.5625rem; }

.tab-item-list li a {
  background: #fff;
  border-top: 0.0625rem solid #E6E6E6;
  border-left: 0.0625rem solid #E6E6E6;
  border-right: 0.0625rem solid #E6E6E6;
  color: #333333;
  display: block;
  font-size: 1rem;
  padding: 1rem;
  text-transform: uppercase; }
  .tab-item-list li a:hover, .tab-item-list li a:focus {
    background: #fff;
    color: #333333;
    border-right: 0rem; }

.tab-item-list li:last-child {
  border-bottom: 0.0625rem solid #E6E6E6; }

.tab-list li {
  margin-bottom: 0.4375rem;
  font-size: 0.8125rem; }
  .tab-list li i {
    font-size: 0.8125rem;
    margin-right: 0.875rem; }

.slide-item-list {
  text-align: center;
  margin: 0 -0.3125rem;
  border: 0; }
  .slide-item-list li {
    display: inline-block;
    flex: 0 0 25%;
    width: 25%;
    padding: 0 0.3125rem; }
    .slide-item-list li a {
      display: inline-block;
      padding-left: 0;
      padding-right: 0;
      padding-top: 0;
      background: transparent;
      padding-bottom: 0rem; }
      .slide-item-list li a:hover, .slide-item-list li a:focus {
        background: transparent; }
      .slide-item-list li a img {
        width: 100%;
        border-radius: 0.625rem; }
  .slide-item-list .nav-link {
    border: 0;
    border-radius: 0.625rem; }
    .slide-item-list .nav-link.active {
      border: 0;
      border-radius: 0.625rem; }

.product-detail-text {
  padding-top: 1.75rem;
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  padding-bottom: 4.375rem; }

.star-rating .product-review {
  font-style: 0.8125rem;
  color: #333333;
  font-weight: 400;
  text-decoration: underline !important; }

.product-detail .tab-content img {
  display: inline-block;
  width: 100%; }

.popular-tag ul {
  margin: 0rem;
  padding: 0rem; }
  .popular-tag ul li {
    display: inline-block;
    padding: 0.5rem 0.9375rem;
    background: #f8f8f8;
    font-size: 0.8125rem;
    color: #fff;
    margin-right: 0.625rem;
    margin-bottom: 0.625rem; }

.size-filter ul li {
  display: inline-block; }

.intro {
  border: 0.0625rem solid red;
  color: #1d1d1d; }

#listResults .slider {
  margin: 1.5625rem 0; }

#listResults .slider-box {
  width: 90%;
  margin: 1.5625rem auto; }

#listResults input {
  width: 10%; }

#listResults label {
  border: none;
  display: inline-block;
  margin-right: -0.25rem;
  vertical-align: top;
  width: 30%; }

.plus-minus-input .input-icon {
  font-size: 0.8125rem;
  color: #aaaaaa; }

.plus-minus-input .custom-btn {
  border-radius: 0;
  height: 2.5rem;
  padding: 0.5rem 0.75rem;
  background: #ffffff;
  border: 0.0625rem solid #c8c8c8; }
  .plus-minus-input .custom-btn:hover, .plus-minus-input .custom-btn:focus, .plus-minus-input .custom-btn.active {
    box-shadow: none;
    outline: none; }

.plus-minus-input .form-control {
  height: 2.5rem;
  border-top: 0.0625rem solid #c8c8c8;
  border-bottom: 0.0625rem solid #c8c8c8;
  border-left: 0rem solid #c8c8c8;
  border-right: 0.0625rem solid #c8c8c8; }
  .plus-minus-input .form-control:hover, .plus-minus-input .form-control:focus, .plus-minus-input .form-control:active {
    border-top: 0.0625rem solid #c8c8c8;
    border-bottom: 0.0625rem solid #c8c8c8;
    border-left: 0rem solid #c8c8c8;
    border-right: 0rem solid #c8c8c8; }

.new-arrival-product .new-arrivals-img-contnent {
  overflow: hidden;
  position: relative; }
  .new-arrival-product .new-arrivals-img-contnent img {
    width: 100%;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
    border-radius: 0.625rem; }
  .new-arrival-product .new-arrivals-img-contnent:after {
    content: "";
    position: absolute;
    top: 0;
    left: -85%;
    z-index: 2;
    display: block;
    content: '';
    width: 50%;
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
    -webkit-transform: skewX(-25deg);
    transform: skewX(-25deg); }

.new-arrival-product:hover .new-arrivals-img-contnent:after {
  webkit-animation: shine .75s;
  animation: shine .75s; }

@-webkit-keyframes shine {
  100% {
    left: 125%; } }

@keyframes shine {
  100% {
    left: 125%; } }

.input-number {
  line-height: 1.8; }

.star-rating {
  margin-bottom: 0.625rem; }

.select-size .btn:not(:first-child), .select-size .btn:not(last-child) {
  margin: 0 5px;
  box-shadow: none; }

/*
===================================
    list view
===================================*/
.new-arrival-content .item {
  font-size: 0.75rem;
  color: #333333; }

.new-arrival-content h4, .new-arrival-content .h4 {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.625rem; }
  .new-arrival-content h4 a, .new-arrival-content .h4 a {
    color: #000; }

.new-arrival-content .price {
  font-weight: 600;
  color: var(--primary);
  font-size: 1.5rem;
  margin-bottom: 0;
  float: right; }
  @media only screen and (max-width: 35.9375rem) {
    .new-arrival-content .price {
      float: none;
      margin-top: 0.625rem; } }

.new-arrival-content p {
  font-size: 0.875rem;
  color: #828690;
  margin-bottom: 0.375rem;
  line-height: 1.5rem; }

.new-arrival-content .text-content {
  margin-top: 1.125rem; }

.new-arrival-content.text-center .price {
  float: unset !important; }

.success-icon {
  color: #53D0B2;
  font-size: 1rem; }

.comment-review {
  margin-bottom: 0.9375rem;
  display: table;
  width: 100%; }
  .comment-review .client-review {
    color: #828690;
    padding-right: 1.25rem;
    text-decoration: underline !important;
    font-size: 0.875rem; }
  .comment-review .span {
    color: #828690;
    font-size: 0.875rem; }
  @media only screen and (max-width: 35.9375rem) {
    .comment-review {
      margin-bottom: 0; } }

.star-rating li {
  display: inline-block; }
  .star-rating li i {
    color: gold; }

.order {
  font-weight: 500; }

.rtl {
  text-align: right;
  direction: rtl; }
  .rtl .nav {
    padding-right: 0; }
  .rtl .navbar-nav .nav-item {
    float: right; }
  .rtl .navbar-nav .nav-item + .nav-item {
    margin-right: 1rem;
    margin-left: inherit; }
  .rtl th {
    text-align: right; }
  .rtl .alert-dismissible {
    padding-right: 1.25rem;
    padding-left: 4rem; }
  .rtl .dropdown-menu {
    right: 0;
    text-align: right; }
  .rtl .checkbox label {
    padding-right: 1.25rem;
    padding-left: inherit; }
  .rtl .btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-radius: 0 0.75rem 0.75rem 0; }
  .rtl .btn-group > .btn:last-child:not(:first-child),
  .rtl .btn-group > .dropdown-toggle:not(:first-child) {
    border-radius: 0.75rem 0 0 0.75rem; }
  .rtl .btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child {
    border-radius: 0.75rem 0 0 0.75rem; }
  .rtl .custom-control-label::after,
  .rtl .custom-control-label::before {
    right: 0;
    left: inherit; }
  .rtl .custom-select {
    padding: 0.375rem 0.75rem 0.375rem 1.75rem;
    background: #F4F4F5 url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat left 0.75rem center;
    background-size: 8px 10px; }
  .rtl .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
  .rtl .input-group > .input-group-append:last-child > .input-group-text:not(:last-child),
  .rtl .input-group > .input-group-append:not(:last-child) > .btn,
  .rtl .input-group > .input-group-append:not(:last-child) > .input-group-text,
  .rtl .input-group > .input-group-prepend > .btn,
  .rtl .input-group > .input-group-prepend > .input-group-text {
    border-radius: 0 0.75rem 0.75rem 0; }
  .rtl .input-group > .input-group-append > .btn,
  .rtl .input-group > .input-group-append > .input-group-text,
  .rtl .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
  .rtl .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child),
  .rtl .input-group > .input-group-prepend:not(:first-child) > .btn,
  .rtl .input-group > .input-group-prepend:not(:first-child) > .input-group-text {
    border-radius: 0.75rem 0 0 0.75rem; }
  .rtl .input-group > .custom-select:not(:first-child),
  .rtl .input-group > .form-control:not(:first-child) {
    border-radius: 0.75rem 0 0 0.75rem; }
  .rtl .input-group > .custom-select:not(:last-child),
  .rtl .input-group > .form-control:not(:last-child) {
    border-radius: 0 0.75rem 0.75rem 0; }
  .rtl .input-group > .custom-select:not(:last-child):not(:first-child),
  .rtl .input-group > .form-control:not(:last-child):not(:first-child) {
    border-radius: 0; }
  .rtl .custom-control {
    padding-right: 1.5rem;
    padding-left: inherit;
    margin-right: inherit;
    margin-left: 1rem; }
  .rtl .custom-control-indicator {
    right: 0;
    left: inherit; }
  .rtl .custom-file-label::after {
    right: initial;
    left: -1px;
    border-radius: .25rem 0 0 .25rem; }
  .rtl .radio input,
  .rtl .radio-inline,
  .rtl .checkbox input,
  .rtl .checkbox-inline input {
    margin-right: -1.25rem;
    margin-left: inherit; }
  .rtl .list-group {
    padding-right: 0;
    padding-left: 40px; }
  .rtl .close {
    float: left; }
  .rtl .modal-header .close {
    margin: -15px auto -15px -15px; }
  .rtl .modal-footer > :not(:first-child) {
    margin-right: .25rem; }
  .rtl .alert-dismissible .close {
    right: inherit;
    left: 0; }
  .rtl .dropdown-toggle::after {
    margin-right: .255em;
    margin-left: 0; }
  .rtl .form-check-input {
    margin-right: -1.25rem;
    margin-left: inherit; }
  .rtl .form-check-label {
    padding-right: 1.25rem;
    padding-left: inherit; }
  .rtl .offset-1 {
    margin-right: 8.33333%;
    margin-left: 0; }
  .rtl .offset-2 {
    margin-right: 16.66667%;
    margin-left: 0; }
  .rtl .offset-3 {
    margin-right: 25%;
    margin-left: 0; }
  .rtl .offset-4 {
    margin-right: 33.33333%;
    margin-left: 0; }
  .rtl .offset-5 {
    margin-right: 41.66667%;
    margin-left: 0; }
  .rtl .offset-6 {
    margin-right: 50%;
    margin-left: 0; }
  .rtl .offset-7 {
    margin-right: 58.33333%;
    margin-left: 0; }
  .rtl .offset-8 {
    margin-right: 66.66667%;
    margin-left: 0; }
  .rtl .offset-9 {
    margin-right: 75%;
    margin-left: 0; }
  .rtl .offset-10 {
    margin-right: 83.33333%;
    margin-left: 0; }
  .rtl .offset-11 {
    margin-right: 91.66667%;
    margin-left: 0; }
  @media (min-width: 576px) {
    .rtl .offset-sm-0 {
      margin-right: 0;
      margin-left: 0; }
    .rtl .offset-sm-1 {
      margin-right: 8.33333%;
      margin-left: 0; }
    .rtl .offset-sm-2 {
      margin-right: 16.66667%;
      margin-left: 0; }
    .rtl .offset-sm-3 {
      margin-right: 25%;
      margin-left: 0; }
    .rtl .offset-sm-4 {
      margin-right: 33.33333%;
      margin-left: 0; }
    .rtl .offset-sm-5 {
      margin-right: 41.66667%;
      margin-left: 0; }
    .rtl .offset-sm-6 {
      margin-right: 50%;
      margin-left: 0; }
    .rtl .offset-sm-7 {
      margin-right: 58.33333%;
      margin-left: 0; }
    .rtl .offset-sm-8 {
      margin-right: 66.66667%;
      margin-left: 0; }
    .rtl .offset-sm-9 {
      margin-right: 75%;
      margin-left: 0; }
    .rtl .offset-sm-10 {
      margin-right: 83.33333%;
      margin-left: 0; }
    .rtl .offset-sm-11 {
      margin-right: 91.66667%;
      margin-left: 0; } }
  @media (min-width: 768px) {
    .rtl .offset-md-0 {
      margin-right: 0;
      margin-left: 0; }
    .rtl .offset-md-1 {
      margin-right: 8.33333%;
      margin-left: 0; }
    .rtl .offset-md-2 {
      margin-right: 16.66667%;
      margin-left: 0; }
    .rtl .offset-md-3 {
      margin-right: 25%;
      margin-left: 0; }
    .rtl .offset-md-4 {
      margin-right: 33.33333%;
      margin-left: 0; }
    .rtl .offset-md-5 {
      margin-right: 41.66667%;
      margin-left: 0; }
    .rtl .offset-md-6 {
      margin-right: 50%;
      margin-left: 0; }
    .rtl .offset-md-7 {
      margin-right: 58.33333%;
      margin-left: 0; }
    .rtl .offset-md-8 {
      margin-right: 66.66667%;
      margin-left: 0; }
    .rtl .offset-md-9 {
      margin-right: 75%;
      margin-left: 0; }
    .rtl .offset-md-10 {
      margin-right: 83.33333%;
      margin-left: 0; }
    .rtl .offset-md-11 {
      margin-right: 91.66667%;
      margin-left: 0; } }
  @media (min-width: 992px) {
    .rtl .offset-lg-0 {
      margin-right: 0;
      margin-left: 0; }
    .rtl .offset-lg-1 {
      margin-right: 8.33333%;
      margin-left: 0; }
    .rtl .offset-lg-2 {
      margin-right: 16.66667%;
      margin-left: 0; }
    .rtl .offset-lg-3 {
      margin-right: 25%;
      margin-left: 0; }
    .rtl .offset-lg-4 {
      margin-right: 33.33333%;
      margin-left: 0; }
    .rtl .offset-lg-5 {
      margin-right: 41.66667%;
      margin-left: 0; }
    .rtl .offset-lg-6 {
      margin-right: 50%;
      margin-left: 0; }
    .rtl .offset-lg-7 {
      margin-right: 58.33333%;
      margin-left: 0; }
    .rtl .offset-lg-8 {
      margin-right: 66.66667%;
      margin-left: 0; }
    .rtl .offset-lg-9 {
      margin-right: 75%;
      margin-left: 0; }
    .rtl .offset-lg-10 {
      margin-right: 83.33333%;
      margin-left: 0; }
    .rtl .offset-lg-11 {
      margin-right: 91.66667%;
      margin-left: 0; } }
  @media (min-width: 1200px) {
    .rtl .offset-xl-0 {
      margin-right: 0;
      margin-left: 0; }
    .rtl .offset-xl-1 {
      margin-right: 8.33333%;
      margin-left: 0; }
    .rtl .offset-xl-2 {
      margin-right: 16.66667%;
      margin-left: 0; }
    .rtl .offset-xl-3 {
      margin-right: 25%;
      margin-left: 0; }
    .rtl .offset-xl-4 {
      margin-right: 33.33333%;
      margin-left: 0; }
    .rtl .offset-xl-5 {
      margin-right: 41.66667%;
      margin-left: 0; }
    .rtl .offset-xl-6 {
      margin-right: 50%;
      margin-left: 0; }
    .rtl .offset-xl-7 {
      margin-right: 58.33333%;
      margin-left: 0; }
    .rtl .offset-xl-8 {
      margin-right: 66.66667%;
      margin-left: 0; }
    .rtl .offset-xl-9 {
      margin-right: 75%;
      margin-left: 0; }
    .rtl .offset-xl-10 {
      margin-right: 83.33333%;
      margin-left: 0; }
    .rtl .offset-xl-11 {
      margin-right: 91.66667%;
      margin-left: 0; } }
  @media (min-width: 1440) {
    .rtl .offset-xxl-0 {
      margin-right: 0;
      margin-left: 0; }
    .rtl .offset-xxl-1 {
      margin-right: 8.33333%;
      margin-left: 0; }
    .rtl .offset-xxl-2 {
      margin-right: 16.66667%;
      margin-left: 0; }
    .rtl .offset-xxl-3 {
      margin-right: 25%;
      margin-left: 0; }
    .rtl .offset-xxl-4 {
      margin-right: 33.33333%;
      margin-left: 0; }
    .rtl .offset-xxl-5 {
      margin-right: 41.66667%;
      margin-left: 0; }
    .rtl .offset-xxl-6 {
      margin-right: 50%;
      margin-left: 0; }
    .rtl .offset-xxl-7 {
      margin-right: 58.33333%;
      margin-left: 0; }
    .rtl .offset-xxl-8 {
      margin-right: 66.66667%;
      margin-left: 0; }
    .rtl .offset-xxl-9 {
      margin-right: 75%;
      margin-left: 0; }
    .rtl .offset-xxl-10 {
      margin-right: 83.33333%;
      margin-left: 0; }
    .rtl .offset-xxl-11 {
      margin-right: 91.66667%;
      margin-left: 0; } }
  .rtl .mr-0,
  .rtl .mx-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .rtl .ml-0,
  .rtl .mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .rtl .mr-1,
  .rtl .mx-1 {
    margin-right: 0 !important;
    margin-left: 0.25rem !important; }
  .rtl .ml-1,
  .rtl .mx-1 {
    margin-left: 0 !important;
    margin-right: 0.25rem !important; }
  .rtl .mr-2,
  .rtl .mx-2 {
    margin-right: 0 !important;
    margin-left: 0.5rem !important; }
  .rtl .ml-2,
  .rtl .mx-2 {
    margin-left: 0 !important;
    margin-right: 0.5rem !important; }
  .rtl .mr-3,
  .rtl .mx-3 {
    margin-right: 0 !important;
    margin-left: 1rem !important; }
  .rtl .ml-3,
  .rtl .mx-3 {
    margin-left: 0 !important;
    margin-right: 1rem !important; }
  .rtl .mr-4,
  .rtl .mx-4 {
    margin-right: 0 !important;
    margin-left: 1.5rem !important; }
  .rtl .ml-4,
  .rtl .mx-4 {
    margin-left: 0 !important;
    margin-right: 1.5rem !important; }
  .rtl .mr-5,
  .rtl .mx-5 {
    margin-right: 0 !important;
    margin-left: 3rem !important; }
  .rtl .ml-5,
  .rtl .mx-5 {
    margin-left: 0 !important;
    margin-right: 3rem !important; }
  .rtl .pr-0,
  .rtl .px-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .rtl .pl-0,
  .rtl .px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .rtl .pr-1,
  .rtl .px-1 {
    padding-right: 0 !important;
    padding-left: 0.25rem !important; }
  .rtl .pl-1,
  .rtl .px-1 {
    padding-left: 0 !important;
    padding-right: 0.25rem !important; }
  .rtl .pr-2,
  .rtl .px-2 {
    padding-right: 0 !important;
    padding-left: 0.5rem !important; }
  .rtl .pl-2,
  .rtl .px-2 {
    padding-left: 0 !important;
    padding-right: 0.5rem !important; }
  .rtl .pr-3,
  .rtl .px-3 {
    padding-right: 0 !important;
    padding-left: 1rem !important; }
  .rtl .pl-3,
  .rtl .px-3 {
    padding-left: 0 !important;
    padding-right: 1rem !important; }
  .rtl .pr-4,
  .rtl .px-4 {
    padding-right: 0 !important;
    padding-left: 1.5rem !important; }
  .rtl .pl-4,
  .rtl .px-4 {
    padding-left: 0 !important;
    padding-right: 1.5rem !important; }
  .rtl .pr-5,
  .rtl .px-5 {
    padding-right: 0 !important;
    padding-left: 3rem !important; }
  .rtl .pl-5,
  .rtl .px-5 {
    padding-left: 0 !important;
    padding-right: 3rem !important; }
  .rtl .mr-auto {
    margin-right: 0 !important;
    margin-left: auto !important; }
  .rtl .ml-auto {
    margin-right: auto !important;
    margin-left: 0 !important; }
  .rtl .mx-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  @media (min-width: 576px) {
    .rtl .mr-sm-0,
    .rtl .mx-sm-0 {
      margin-right: 0 !important;
      margin-left: 0 !important; }
    .rtl .ml-sm-0,
    .rtl .mx-sm-0 {
      margin-left: 0 !important;
      margin-right: 0 !important; }
    .rtl .mr-sm-1,
    .rtl .mx-sm-1 {
      margin-right: 0 !important;
      margin-left: 0.25rem !important; }
    .rtl .ml-sm-1,
    .rtl .mx-sm-1 {
      margin-left: 0 !important;
      margin-right: 0.25rem !important; }
    .rtl .mr-sm-2,
    .rtl .mx-sm-2 {
      margin-right: 0 !important;
      margin-left: 0.5rem !important; }
    .rtl .ml-sm-2,
    .rtl .mx-sm-2 {
      margin-left: 0 !important;
      margin-right: 0.5rem !important; }
    .rtl .mr-sm-3,
    .rtl .mx-sm-3 {
      margin-right: 0 !important;
      margin-left: 1rem !important; }
    .rtl .ml-sm-3,
    .rtl .mx-sm-3 {
      margin-left: 0 !important;
      margin-right: 1rem !important; }
    .rtl .mr-sm-4,
    .rtl .mx-sm-4 {
      margin-right: 0 !important;
      margin-left: 1.5rem !important; }
    .rtl .ml-sm-4,
    .rtl .mx-sm-4 {
      margin-left: 0 !important;
      margin-right: 1.5rem !important; }
    .rtl .mr-sm-5,
    .rtl .mx-sm-5 {
      margin-right: 0 !important;
      margin-left: 3rem !important; }
    .rtl .ml-sm-5,
    .rtl .mx-sm-5 {
      margin-left: 0 !important;
      margin-right: 3rem !important; }
    .rtl .pr-sm-0,
    .rtl .px-sm-0 {
      padding-right: 0 !important;
      padding-left: 0 !important; }
    .rtl .pl-sm-0,
    .rtl .px-sm-0 {
      padding-left: 0 !important;
      padding-right: 0 !important; }
    .rtl .pr-sm-1,
    .rtl .px-sm-1 {
      padding-right: 0 !important;
      padding-left: 0.25rem !important; }
    .rtl .pl-sm-1,
    .rtl .px-sm-1 {
      padding-left: 0 !important;
      padding-right: 0.25rem !important; }
    .rtl .pr-sm-2,
    .rtl .px-sm-2 {
      padding-right: 0 !important;
      padding-left: 0.5rem !important; }
    .rtl .pl-sm-2,
    .rtl .px-sm-2 {
      padding-left: 0 !important;
      padding-right: 0.5rem !important; }
    .rtl .pr-sm-3,
    .rtl .px-sm-3 {
      padding-right: 0 !important;
      padding-left: 1rem !important; }
    .rtl .pl-sm-3,
    .rtl .px-sm-3 {
      padding-left: 0 !important;
      padding-right: 1rem !important; }
    .rtl .pr-sm-4,
    .rtl .px-sm-4 {
      padding-right: 0 !important;
      padding-left: 1.5rem !important; }
    .rtl .pl-sm-4,
    .rtl .px-sm-4 {
      padding-left: 0 !important;
      padding-right: 1.5rem !important; }
    .rtl .pr-sm-5,
    .rtl .px-sm-5 {
      padding-right: 0 !important;
      padding-left: 3rem !important; }
    .rtl .pl-sm-5,
    .rtl .px-sm-5 {
      padding-left: 0 !important;
      padding-right: 3rem !important; }
    .rtl .mr-sm-auto {
      margin-right: 0 !important;
      margin-left: auto !important; }
    .rtl .ml-sm-auto {
      margin-right: auto !important;
      margin-left: 0 !important; }
    .rtl .mx-sm-auto {
      margin-right: auto !important;
      margin-left: auto !important; } }
  @media (min-width: 768px) {
    .rtl .mr-md-0,
    .rtl .mx-md-0 {
      margin-right: 0 !important;
      margin-left: 0 !important; }
    .rtl .ml-md-0,
    .rtl .mx-md-0 {
      margin-left: 0 !important;
      margin-right: 0 !important; }
    .rtl .mr-md-1,
    .rtl .mx-md-1 {
      margin-right: 0 !important;
      margin-left: 0.25rem !important; }
    .rtl .ml-md-1,
    .rtl .mx-md-1 {
      margin-left: 0 !important;
      margin-right: 0.25rem !important; }
    .rtl .mr-md-2,
    .rtl .mx-md-2 {
      margin-right: 0 !important;
      margin-left: 0.5rem !important; }
    .rtl .ml-md-2,
    .rtl .mx-md-2 {
      margin-left: 0 !important;
      margin-right: 0.5rem !important; }
    .rtl .mr-md-3,
    .rtl .mx-md-3 {
      margin-right: 0 !important;
      margin-left: 1rem !important; }
    .rtl .ml-md-3,
    .rtl .mx-md-3 {
      margin-left: 0 !important;
      margin-right: 1rem !important; }
    .rtl .mr-md-4,
    .rtl .mx-md-4 {
      margin-right: 0 !important;
      margin-left: 1.5rem !important; }
    .rtl .ml-md-4,
    .rtl .mx-md-4 {
      margin-left: 0 !important;
      margin-right: 1.5rem !important; }
    .rtl .mr-md-5,
    .rtl .mx-md-5 {
      margin-right: 0 !important;
      margin-left: 3rem !important; }
    .rtl .ml-md-5,
    .rtl .mx-md-5 {
      margin-left: 0 !important;
      margin-right: 3rem !important; }
    .rtl .pr-md-0,
    .rtl .px-md-0 {
      padding-right: 0 !important;
      padding-left: 0 !important; }
    .rtl .pl-md-0,
    .rtl .px-md-0 {
      padding-left: 0 !important;
      padding-right: 0 !important; }
    .rtl .pr-md-1,
    .rtl .px-md-1 {
      padding-right: 0 !important;
      padding-left: 0.25rem !important; }
    .rtl .pl-md-1,
    .rtl .px-md-1 {
      padding-left: 0 !important;
      padding-right: 0.25rem !important; }
    .rtl .pr-md-2,
    .rtl .px-md-2 {
      padding-right: 0 !important;
      padding-left: 0.5rem !important; }
    .rtl .pl-md-2,
    .rtl .px-md-2 {
      padding-left: 0 !important;
      padding-right: 0.5rem !important; }
    .rtl .pr-md-3,
    .rtl .px-md-3 {
      padding-right: 0 !important;
      padding-left: 1rem !important; }
    .rtl .pl-md-3,
    .rtl .px-md-3 {
      padding-left: 0 !important;
      padding-right: 1rem !important; }
    .rtl .pr-md-4,
    .rtl .px-md-4 {
      padding-right: 0 !important;
      padding-left: 1.5rem !important; }
    .rtl .pl-md-4,
    .rtl .px-md-4 {
      padding-left: 0 !important;
      padding-right: 1.5rem !important; }
    .rtl .pr-md-5,
    .rtl .px-md-5 {
      padding-right: 0 !important;
      padding-left: 3rem !important; }
    .rtl .pl-md-5,
    .rtl .px-md-5 {
      padding-left: 0 !important;
      padding-right: 3rem !important; }
    .rtl .mr-md-auto {
      margin-right: 0 !important;
      margin-left: auto !important; }
    .rtl .ml-md-auto {
      margin-right: auto !important;
      margin-left: 0 !important; }
    .rtl .mx-md-auto {
      margin-right: auto !important;
      margin-left: auto !important; } }
  @media (min-width: 992px) {
    .rtl .mr-lg-0,
    .rtl .mx-lg-0 {
      margin-right: 0 !important;
      margin-left: 0 !important; }
    .rtl .ml-lg-0,
    .rtl .mx-lg-0 {
      margin-left: 0 !important;
      margin-right: 0 !important; }
    .rtl .mr-lg-1,
    .rtl .mx-lg-1 {
      margin-right: 0 !important;
      margin-left: 0.25rem !important; }
    .rtl .ml-lg-1,
    .rtl .mx-lg-1 {
      margin-left: 0 !important;
      margin-right: 0.25rem !important; }
    .rtl .mr-lg-2,
    .rtl .mx-lg-2 {
      margin-right: 0 !important;
      margin-left: 0.5rem !important; }
    .rtl .ml-lg-2,
    .rtl .mx-lg-2 {
      margin-left: 0 !important;
      margin-right: 0.5rem !important; }
    .rtl .mr-lg-3,
    .rtl .mx-lg-3 {
      margin-right: 0 !important;
      margin-left: 1rem !important; }
    .rtl .ml-lg-3,
    .rtl .mx-lg-3 {
      margin-left: 0 !important;
      margin-right: 1rem !important; }
    .rtl .mr-lg-4,
    .rtl .mx-lg-4 {
      margin-right: 0 !important;
      margin-left: 1.5rem !important; }
    .rtl .ml-lg-4,
    .rtl .mx-lg-4 {
      margin-left: 0 !important;
      margin-right: 1.5rem !important; }
    .rtl .mr-lg-5,
    .rtl .mx-lg-5 {
      margin-right: 0 !important;
      margin-left: 3rem !important; }
    .rtl .ml-lg-5,
    .rtl .mx-lg-5 {
      margin-left: 0 !important;
      margin-right: 3rem !important; }
    .rtl .pr-lg-0,
    .rtl .px-lg-0 {
      padding-right: 0 !important;
      padding-left: 0 !important; }
    .rtl .pl-lg-0,
    .rtl .px-lg-0 {
      padding-left: 0 !important;
      padding-right: 0 !important; }
    .rtl .pr-lg-1,
    .rtl .px-lg-1 {
      padding-right: 0 !important;
      padding-left: 0.25rem !important; }
    .rtl .pl-lg-1,
    .rtl .px-lg-1 {
      padding-left: 0 !important;
      padding-right: 0.25rem !important; }
    .rtl .pr-lg-2,
    .rtl .px-lg-2 {
      padding-right: 0 !important;
      padding-left: 0.5rem !important; }
    .rtl .pl-lg-2,
    .rtl .px-lg-2 {
      padding-left: 0 !important;
      padding-right: 0.5rem !important; }
    .rtl .pr-lg-3,
    .rtl .px-lg-3 {
      padding-right: 0 !important;
      padding-left: 1rem !important; }
    .rtl .pl-lg-3,
    .rtl .px-lg-3 {
      padding-left: 0 !important;
      padding-right: 1rem !important; }
    .rtl .pr-lg-4,
    .rtl .px-lg-4 {
      padding-right: 0 !important;
      padding-left: 1.5rem !important; }
    .rtl .pl-lg-4,
    .rtl .px-lg-4 {
      padding-left: 0 !important;
      padding-right: 1.5rem !important; }
    .rtl .pr-lg-5,
    .rtl .px-lg-5 {
      padding-right: 0 !important;
      padding-left: 3rem !important; }
    .rtl .pl-lg-5,
    .rtl .px-lg-5 {
      padding-left: 0 !important;
      padding-right: 3rem !important; }
    .rtl .mr-lg-auto {
      margin-right: 0 !important;
      margin-left: auto !important; }
    .rtl .ml-lg-auto {
      margin-right: auto !important;
      margin-left: 0 !important; }
    .rtl .mx-lg-auto {
      margin-right: auto !important;
      margin-left: auto !important; } }
  @media (min-width: 1200px) {
    .rtl .mr-xl-0,
    .rtl .mx-xl-0 {
      margin-right: 0 !important;
      margin-left: 0 !important; }
    .rtl .ml-xl-0,
    .rtl .mx-xl-0 {
      margin-left: 0 !important;
      margin-right: 0 !important; }
    .rtl .mr-xl-1,
    .rtl .mx-xl-1 {
      margin-right: 0 !important;
      margin-left: 0.25rem !important; }
    .rtl .ml-xl-1,
    .rtl .mx-xl-1 {
      margin-left: 0 !important;
      margin-right: 0.25rem !important; }
    .rtl .mr-xl-2,
    .rtl .mx-xl-2 {
      margin-right: 0 !important;
      margin-left: 0.5rem !important; }
    .rtl .ml-xl-2,
    .rtl .mx-xl-2 {
      margin-left: 0 !important;
      margin-right: 0.5rem !important; }
    .rtl .mr-xl-3,
    .rtl .mx-xl-3 {
      margin-right: 0 !important;
      margin-left: 1rem !important; }
    .rtl .ml-xl-3,
    .rtl .mx-xl-3 {
      margin-left: 0 !important;
      margin-right: 1rem !important; }
    .rtl .mr-xl-4,
    .rtl .mx-xl-4 {
      margin-right: 0 !important;
      margin-left: 1.5rem !important; }
    .rtl .ml-xl-4,
    .rtl .mx-xl-4 {
      margin-left: 0 !important;
      margin-right: 1.5rem !important; }
    .rtl .mr-xl-5,
    .rtl .mx-xl-5 {
      margin-right: 0 !important;
      margin-left: 3rem !important; }
    .rtl .ml-xl-5,
    .rtl .mx-xl-5 {
      margin-left: 0 !important;
      margin-right: 3rem !important; }
    .rtl .pr-xl-0,
    .rtl .px-xl-0 {
      padding-right: 0 !important;
      padding-left: 0 !important; }
    .rtl .pl-xl-0,
    .rtl .px-xl-0 {
      padding-left: 0 !important;
      padding-right: 0 !important; }
    .rtl .pr-xl-1,
    .rtl .px-xl-1 {
      padding-right: 0 !important;
      padding-left: 0.25rem !important; }
    .rtl .pl-xl-1,
    .rtl .px-xl-1 {
      padding-left: 0 !important;
      padding-right: 0.25rem !important; }
    .rtl .pr-xl-2,
    .rtl .px-xl-2 {
      padding-right: 0 !important;
      padding-left: 0.5rem !important; }
    .rtl .pl-xl-2,
    .rtl .px-xl-2 {
      padding-left: 0 !important;
      padding-right: 0.5rem !important; }
    .rtl .pr-xl-3,
    .rtl .px-xl-3 {
      padding-right: 0 !important;
      padding-left: 1rem !important; }
    .rtl .pl-xl-3,
    .rtl .px-xl-3 {
      padding-left: 0 !important;
      padding-right: 1rem !important; }
    .rtl .pr-xl-4,
    .rtl .px-xl-4 {
      padding-right: 0 !important;
      padding-left: 1.5rem !important; }
    .rtl .pl-xl-4,
    .rtl .px-xl-4 {
      padding-left: 0 !important;
      padding-right: 1.5rem !important; }
    .rtl .pr-xl-5,
    .rtl .px-xl-5 {
      padding-right: 0 !important;
      padding-left: 3rem !important; }
    .rtl .pl-xl-5,
    .rtl .px-xl-5 {
      padding-left: 0 !important;
      padding-right: 3rem !important; }
    .rtl .mr-xl-auto {
      margin-right: 0 !important;
      margin-left: auto !important; }
    .rtl .ml-xl-auto {
      margin-right: auto !important;
      margin-left: 0 !important; }
    .rtl .mx-xl-auto {
      margin-right: auto !important;
      margin-left: auto !important; } }
  @media (min-width: 1440) {
    .rtl .mr-xxl-0,
    .rtl .mx-xxl-0 {
      margin-right: 0 !important;
      margin-left: 0 !important; }
    .rtl .ml-xxl-0,
    .rtl .mx-xxl-0 {
      margin-left: 0 !important;
      margin-right: 0 !important; }
    .rtl .mr-xxl-1,
    .rtl .mx-xxl-1 {
      margin-right: 0 !important;
      margin-left: 0.25rem !important; }
    .rtl .ml-xxl-1,
    .rtl .mx-xxl-1 {
      margin-left: 0 !important;
      margin-right: 0.25rem !important; }
    .rtl .mr-xxl-2,
    .rtl .mx-xxl-2 {
      margin-right: 0 !important;
      margin-left: 0.5rem !important; }
    .rtl .ml-xxl-2,
    .rtl .mx-xxl-2 {
      margin-left: 0 !important;
      margin-right: 0.5rem !important; }
    .rtl .mr-xxl-3,
    .rtl .mx-xxl-3 {
      margin-right: 0 !important;
      margin-left: 1rem !important; }
    .rtl .ml-xxl-3,
    .rtl .mx-xxl-3 {
      margin-left: 0 !important;
      margin-right: 1rem !important; }
    .rtl .mr-xxl-4,
    .rtl .mx-xxl-4 {
      margin-right: 0 !important;
      margin-left: 1.5rem !important; }
    .rtl .ml-xxl-4,
    .rtl .mx-xxl-4 {
      margin-left: 0 !important;
      margin-right: 1.5rem !important; }
    .rtl .mr-xxl-5,
    .rtl .mx-xxl-5 {
      margin-right: 0 !important;
      margin-left: 3rem !important; }
    .rtl .ml-xxl-5,
    .rtl .mx-xxl-5 {
      margin-left: 0 !important;
      margin-right: 3rem !important; }
    .rtl .pr-xxl-0,
    .rtl .px-xxl-0 {
      padding-right: 0 !important;
      padding-left: 0 !important; }
    .rtl .pl-xxl-0,
    .rtl .px-xxl-0 {
      padding-left: 0 !important;
      padding-right: 0 !important; }
    .rtl .pr-xxl-1,
    .rtl .px-xxl-1 {
      padding-right: 0 !important;
      padding-left: 0.25rem !important; }
    .rtl .pl-xxl-1,
    .rtl .px-xxl-1 {
      padding-left: 0 !important;
      padding-right: 0.25rem !important; }
    .rtl .pr-xxl-2,
    .rtl .px-xxl-2 {
      padding-right: 0 !important;
      padding-left: 0.5rem !important; }
    .rtl .pl-xxl-2,
    .rtl .px-xxl-2 {
      padding-left: 0 !important;
      padding-right: 0.5rem !important; }
    .rtl .pr-xxl-3,
    .rtl .px-xxl-3 {
      padding-right: 0 !important;
      padding-left: 1rem !important; }
    .rtl .pl-xxl-3,
    .rtl .px-xxl-3 {
      padding-left: 0 !important;
      padding-right: 1rem !important; }
    .rtl .pr-xxl-4,
    .rtl .px-xxl-4 {
      padding-right: 0 !important;
      padding-left: 1.5rem !important; }
    .rtl .pl-xxl-4,
    .rtl .px-xxl-4 {
      padding-left: 0 !important;
      padding-right: 1.5rem !important; }
    .rtl .pr-xxl-5,
    .rtl .px-xxl-5 {
      padding-right: 0 !important;
      padding-left: 3rem !important; }
    .rtl .pl-xxl-5,
    .rtl .px-xxl-5 {
      padding-left: 0 !important;
      padding-right: 3rem !important; }
    .rtl .mr-xxl-auto {
      margin-right: 0 !important;
      margin-left: auto !important; }
    .rtl .ml-xxl-auto {
      margin-right: auto !important;
      margin-left: 0 !important; }
    .rtl .mx-xxl-auto {
      margin-right: auto !important;
      margin-left: auto !important; } }
  .rtl .text-right {
    text-align: left !important; }
  .rtl .text-left {
    text-align: right !important; }
  @media (min-width: 576px) {
    .rtl .text-sm-right {
      text-align: left !important; }
    .rtl .text-sm-left {
      text-align: right !important; } }
  @media (min-width: 768px) {
    .rtl .text-md-right {
      text-align: left !important; }
    .rtl .text-md-left {
      text-align: right !important; } }
  @media (min-width: 992px) {
    .rtl .text-lg-right {
      text-align: left !important; }
    .rtl .text-lg-left {
      text-align: right !important; } }
  @media (min-width: 1200px) {
    .rtl .text-xl-right {
      text-align: left !important; }
    .rtl .text-xl-left {
      text-align: right !important; } }
  @media (min-width: 1440) {
    .rtl .text-xxl-right {
      text-align: left !important; }
    .rtl .text-xxl-left {
      text-align: right !important; } }

.rtl .mx-0 {
  margin-right: auto;
  margin-left: 0 !important; }

.rtl .mx-0 {
  margin-left: auto;
  margin-right: 0 !important; }

.rtl .mx-1 {
  margin-right: auto;
  margin-left: 0.25rem !important; }

.rtl .mx-1 {
  margin-left: auto;
  margin-right: 0.25rem !important; }

.rtl .mx-2 {
  margin-right: auto;
  margin-left: 0.5rem !important; }

.rtl .mx-2 {
  margin-left: auto;
  margin-right: 0.5rem !important; }

.rtl .mx-3 {
  margin-right: auto;
  margin-left: 1rem !important; }

.rtl .mx-3 {
  margin-left: auto;
  margin-right: 1rem !important; }

.rtl .mx-4 {
  margin-right: auto;
  margin-left: 1.5rem !important; }

.rtl .mx-4 {
  margin-left: auto;
  margin-right: 1.5rem !important; }

.rtl .mx-5 {
  margin-right: auto;
  margin-left: 3rem !important; }

.rtl .mx-5 {
  margin-left: auto;
  margin-right: 3rem !important; }

.rtl .px-0 {
  padding-right: auto;
  padding-left: 0 !important; }

.rtl .px-0 {
  padding-left: auto;
  padding-right: 0 !important; }

.rtl .px-1 {
  padding-right: auto;
  padding-left: 0.25rem !important; }

.rtl .px-1 {
  padding-left: auto;
  padding-right: 0.25rem !important; }

.rtl .px-2 {
  padding-right: auto;
  padding-left: 0.5rem !important; }

.rtl .px-2 {
  padding-left: auto;
  padding-right: 0.5rem !important; }

.rtl .px-3 {
  padding-right: auto;
  padding-left: 1rem !important; }

.rtl .px-3 {
  padding-left: auto;
  padding-right: 1rem !important; }

.rtl .px-4 {
  padding-right: auto;
  padding-left: 1.5rem !important; }

.rtl .px-4 {
  padding-left: auto;
  padding-right: 1.5rem !important; }

.rtl .px-5 {
  padding-right: auto;
  padding-left: 3rem !important; }

.rtl .px-5 {
  padding-left: auto;
  padding-right: 3rem !important; }

.rtl .mr-auto {
  margin-right: 0 !important;
  margin-left: auto !important; }

.rtl .mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.rtl .ml-auto {
  margin-right: auto !important;
  margin-left: 0 !important; }

.rtl .mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

@media (min-width: 576px) {
  .rtl .mx-sm-0 {
    margin-right: auto;
    margin-left: 0 !important; }
  .rtl .mx-sm-0 {
    margin-left: auto;
    margin-right: 0 !important; }
  .rtl .mx-sm-1 {
    margin-right: auto;
    margin-left: 0.25rem !important; }
  .rtl .mx-sm-1 {
    margin-left: auto;
    margin-right: 0.25rem !important; }
  .rtl .mx-sm-2 {
    margin-right: auto;
    margin-left: 0.5rem !important; }
  .rtl .mx-sm-2 {
    margin-left: auto;
    margin-right: 0.5rem !important; }
  .rtl .mx-sm-3 {
    margin-right: auto;
    margin-left: 1rem !important; }
  .rtl .mx-sm-3 {
    margin-left: auto;
    margin-right: 1rem !important; }
  .rtl .mx-sm-4 {
    margin-right: auto;
    margin-left: 1.5rem !important; }
  .rtl .mx-sm-4 {
    margin-left: auto;
    margin-right: 1.5rem !important; }
  .rtl .mx-sm-5 {
    margin-right: auto;
    margin-left: 3rem !important; }
  .rtl .mx-sm-5 {
    margin-left: auto;
    margin-right: 3rem !important; }
  .rtl .px-sm-0 {
    padding-right: auto;
    padding-left: 0 !important; }
  .rtl .px-sm-0 {
    padding-left: auto;
    padding-right: 0 !important; }
  .rtl .px-sm-1 {
    padding-right: auto;
    padding-left: 0.25rem !important; }
  .rtl .px-sm-1 {
    padding-left: auto;
    padding-right: 0.25rem !important; }
  .rtl .px-sm-2 {
    padding-right: auto;
    padding-left: 0.5rem !important; }
  .rtl .px-sm-2 {
    padding-left: auto;
    padding-right: 0.5rem !important; }
  .rtl .px-sm-3 {
    padding-right: auto;
    padding-left: 1rem !important; }
  .rtl .px-sm-3 {
    padding-left: auto;
    padding-right: 1rem !important; }
  .rtl .px-sm-4 {
    padding-right: auto;
    padding-left: 1.5rem !important; }
  .rtl .px-sm-4 {
    padding-left: auto;
    padding-right: 1.5rem !important; }
  .rtl .px-sm-5 {
    padding-right: auto;
    padding-left: 3rem !important; }
  .rtl .px-sm-5 {
    padding-left: auto;
    padding-right: 3rem !important; }
  .rtl .mr-sm-auto {
    margin-right: 0 !important;
    margin-left: auto !important; }
  .rtl .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .rtl .ml-sm-auto {
    margin-right: auto !important;
    margin-left: 0 !important; }
  .rtl .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .rtl .mx-md-0 {
    margin-right: auto;
    margin-left: 0 !important; }
  .rtl .mx-md-0 {
    margin-left: auto;
    margin-right: 0 !important; }
  .rtl .mx-md-1 {
    margin-right: auto;
    margin-left: 0.25rem !important; }
  .rtl .mx-md-1 {
    margin-left: auto;
    margin-right: 0.25rem !important; }
  .rtl .mx-md-2 {
    margin-right: auto;
    margin-left: 0.5rem !important; }
  .rtl .mx-md-2 {
    margin-left: auto;
    margin-right: 0.5rem !important; }
  .rtl .mx-md-3 {
    margin-right: auto;
    margin-left: 1rem !important; }
  .rtl .mx-md-3 {
    margin-left: auto;
    margin-right: 1rem !important; }
  .rtl .mx-md-4 {
    margin-right: auto;
    margin-left: 1.5rem !important; }
  .rtl .mx-md-4 {
    margin-left: auto;
    margin-right: 1.5rem !important; }
  .rtl .mx-md-5 {
    margin-right: auto;
    margin-left: 3rem !important; }
  .rtl .mx-md-5 {
    margin-left: auto;
    margin-right: 3rem !important; }
  .rtl .px-md-0 {
    padding-right: auto;
    padding-left: 0 !important; }
  .rtl .px-md-0 {
    padding-left: auto;
    padding-right: 0 !important; }
  .rtl .px-md-1 {
    padding-right: auto;
    padding-left: 0.25rem !important; }
  .rtl .px-md-1 {
    padding-left: auto;
    padding-right: 0.25rem !important; }
  .rtl .px-md-2 {
    padding-right: auto;
    padding-left: 0.5rem !important; }
  .rtl .px-md-2 {
    padding-left: auto;
    padding-right: 0.5rem !important; }
  .rtl .px-md-3 {
    padding-right: auto;
    padding-left: 1rem !important; }
  .rtl .px-md-3 {
    padding-left: auto;
    padding-right: 1rem !important; }
  .rtl .px-md-4 {
    padding-right: auto;
    padding-left: 1.5rem !important; }
  .rtl .px-md-4 {
    padding-left: auto;
    padding-right: 1.5rem !important; }
  .rtl .px-md-5 {
    padding-right: auto;
    padding-left: 3rem !important; }
  .rtl .px-md-5 {
    padding-left: auto;
    padding-right: 3rem !important; }
  .rtl .mr-md-auto {
    margin-right: 0 !important;
    margin-left: auto !important; }
  .rtl .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .rtl .ml-md-auto {
    margin-right: auto !important;
    margin-left: 0 !important; }
  .rtl .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .rtl .mx-lg-0 {
    margin-right: auto;
    margin-left: 0 !important; }
  .rtl .mx-lg-0 {
    margin-left: auto;
    margin-right: 0 !important; }
  .rtl .mx-lg-1 {
    margin-right: auto;
    margin-left: 0.25rem !important; }
  .rtl .mx-lg-1 {
    margin-left: auto;
    margin-right: 0.25rem !important; }
  .rtl .mx-lg-2 {
    margin-right: auto;
    margin-left: 0.5rem !important; }
  .rtl .mx-lg-2 {
    margin-left: auto;
    margin-right: 0.5rem !important; }
  .rtl .mx-lg-3 {
    margin-right: auto;
    margin-left: 1rem !important; }
  .rtl .mx-lg-3 {
    margin-left: auto;
    margin-right: 1rem !important; }
  .rtl .mx-lg-4 {
    margin-right: auto;
    margin-left: 1.5rem !important; }
  .rtl .mx-lg-4 {
    margin-left: auto;
    margin-right: 1.5rem !important; }
  .rtl .mx-lg-5 {
    margin-right: auto;
    margin-left: 3rem !important; }
  .rtl .mx-lg-5 {
    margin-left: auto;
    margin-right: 3rem !important; }
  .rtl .px-lg-0 {
    padding-right: auto;
    padding-left: 0 !important; }
  .rtl .px-lg-0 {
    padding-left: auto;
    padding-right: 0 !important; }
  .rtl .px-lg-1 {
    padding-right: auto;
    padding-left: 0.25rem !important; }
  .rtl .px-lg-1 {
    padding-left: auto;
    padding-right: 0.25rem !important; }
  .rtl .px-lg-2 {
    padding-right: auto;
    padding-left: 0.5rem !important; }
  .rtl .px-lg-2 {
    padding-left: auto;
    padding-right: 0.5rem !important; }
  .rtl .px-lg-3 {
    padding-right: auto;
    padding-left: 1rem !important; }
  .rtl .px-lg-3 {
    padding-left: auto;
    padding-right: 1rem !important; }
  .rtl .px-lg-4 {
    padding-right: auto;
    padding-left: 1.5rem !important; }
  .rtl .px-lg-4 {
    padding-left: auto;
    padding-right: 1.5rem !important; }
  .rtl .px-lg-5 {
    padding-right: auto;
    padding-left: 3rem !important; }
  .rtl .px-lg-5 {
    padding-left: auto;
    padding-right: 3rem !important; }
  .rtl .mr-lg-auto {
    margin-right: 0 !important;
    margin-left: auto !important; }
  .rtl .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .rtl .ml-lg-auto {
    margin-right: auto !important;
    margin-left: 0 !important; }
  .rtl .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .rtl .mx-xl-0 {
    margin-right: auto;
    margin-left: 0 !important; }
  .rtl .mx-xl-0 {
    margin-left: auto;
    margin-right: 0 !important; }
  .rtl .mx-xl-1 {
    margin-right: auto;
    margin-left: 0.25rem !important; }
  .rtl .mx-xl-1 {
    margin-left: auto;
    margin-right: 0.25rem !important; }
  .rtl .mx-xl-2 {
    margin-right: auto;
    margin-left: 0.5rem !important; }
  .rtl .mx-xl-2 {
    margin-left: auto;
    margin-right: 0.5rem !important; }
  .rtl .mx-xl-3 {
    margin-right: auto;
    margin-left: 1rem !important; }
  .rtl .mx-xl-3 {
    margin-left: auto;
    margin-right: 1rem !important; }
  .rtl .mx-xl-4 {
    margin-right: auto;
    margin-left: 1.5rem !important; }
  .rtl .mx-xl-4 {
    margin-left: auto;
    margin-right: 1.5rem !important; }
  .rtl .mx-xl-5 {
    margin-right: auto;
    margin-left: 3rem !important; }
  .rtl .mx-xl-5 {
    margin-left: auto;
    margin-right: 3rem !important; }
  .rtl .px-xl-0 {
    padding-right: auto;
    padding-left: 0 !important; }
  .rtl .px-xl-0 {
    padding-left: auto;
    padding-right: 0 !important; }
  .rtl .px-xl-1 {
    padding-right: auto;
    padding-left: 0.25rem !important; }
  .rtl .px-xl-1 {
    padding-left: auto;
    padding-right: 0.25rem !important; }
  .rtl .px-xl-2 {
    padding-right: auto;
    padding-left: 0.5rem !important; }
  .rtl .px-xl-2 {
    padding-left: auto;
    padding-right: 0.5rem !important; }
  .rtl .px-xl-3 {
    padding-right: auto;
    padding-left: 1rem !important; }
  .rtl .px-xl-3 {
    padding-left: auto;
    padding-right: 1rem !important; }
  .rtl .px-xl-4 {
    padding-right: auto;
    padding-left: 1.5rem !important; }
  .rtl .px-xl-4 {
    padding-left: auto;
    padding-right: 1.5rem !important; }
  .rtl .px-xl-5 {
    padding-right: auto;
    padding-left: 3rem !important; }
  .rtl .px-xl-5 {
    padding-left: auto;
    padding-right: 3rem !important; }
  .rtl .mr-xl-auto {
    margin-right: 0 !important;
    margin-left: auto !important; }
  .rtl .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .rtl .ml-xl-auto {
    margin-right: auto !important;
    margin-left: 0 !important; }
  .rtl .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; } }

@media (min-width: 1440) {
  .rtl .mx-xxl-0 {
    margin-right: auto;
    margin-left: 0 !important; }
  .rtl .mx-xxl-0 {
    margin-left: auto;
    margin-right: 0 !important; }
  .rtl .mx-xxl-1 {
    margin-right: auto;
    margin-left: 0.25rem !important; }
  .rtl .mx-xxl-1 {
    margin-left: auto;
    margin-right: 0.25rem !important; }
  .rtl .mx-xxl-2 {
    margin-right: auto;
    margin-left: 0.5rem !important; }
  .rtl .mx-xxl-2 {
    margin-left: auto;
    margin-right: 0.5rem !important; }
  .rtl .mx-xxl-3 {
    margin-right: auto;
    margin-left: 1rem !important; }
  .rtl .mx-xxl-3 {
    margin-left: auto;
    margin-right: 1rem !important; }
  .rtl .mx-xxl-4 {
    margin-right: auto;
    margin-left: 1.5rem !important; }
  .rtl .mx-xxl-4 {
    margin-left: auto;
    margin-right: 1.5rem !important; }
  .rtl .mx-xxl-5 {
    margin-right: auto;
    margin-left: 3rem !important; }
  .rtl .mx-xxl-5 {
    margin-left: auto;
    margin-right: 3rem !important; }
  .rtl .px-xxl-0 {
    padding-right: auto;
    padding-left: 0 !important; }
  .rtl .px-xxl-0 {
    padding-left: auto;
    padding-right: 0 !important; }
  .rtl .px-xxl-1 {
    padding-right: auto;
    padding-left: 0.25rem !important; }
  .rtl .px-xxl-1 {
    padding-left: auto;
    padding-right: 0.25rem !important; }
  .rtl .px-xxl-2 {
    padding-right: auto;
    padding-left: 0.5rem !important; }
  .rtl .px-xxl-2 {
    padding-left: auto;
    padding-right: 0.5rem !important; }
  .rtl .px-xxl-3 {
    padding-right: auto;
    padding-left: 1rem !important; }
  .rtl .px-xxl-3 {
    padding-left: auto;
    padding-right: 1rem !important; }
  .rtl .px-xxl-4 {
    padding-right: auto;
    padding-left: 1.5rem !important; }
  .rtl .px-xxl-4 {
    padding-left: auto;
    padding-right: 1.5rem !important; }
  .rtl .px-xxl-5 {
    padding-right: auto;
    padding-left: 3rem !important; }
  .rtl .px-xxl-5 {
    padding-left: auto;
    padding-right: 3rem !important; }
  .rtl .mr-xxl-auto {
    margin-right: 0 !important;
    margin-left: auto !important; }
  .rtl .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .rtl .ml-xxl-auto {
    margin-right: auto !important;
    margin-left: 0 !important; }
  .rtl .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important; } }

.rtl .float-left {
  float: right !important; }

.rtl .float-right {
  float: left !important; }

.rtl .float-none {
  float: none !important; }

@media (min-width: 576px) {
  .rtl .float-sm-left {
    float: right !important; }
  .rtl .float-sm-right {
    float: left !important; }
  .rtl .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .rtl .float-md-left {
    float: right !important; }
  .rtl .float-md-right {
    float: left !important; }
  .rtl .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .rtl .float-lg-left {
    float: right !important; }
  .rtl .float-lg-right {
    float: left !important; }
  .rtl .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .rtl .float-xl-left {
    float: right !important; }
  .rtl .float-xl-right {
    float: left !important; }
  .rtl .float-xl-none {
    float: none !important; } }

@media (min-width: 1440) {
  .rtl .float-xxl-left {
    float: right !important; }
  .rtl .float-xxl-right {
    float: left !important; }
  .rtl .float-xxl-none {
    float: none !important; } }

[direction="rtl"][data-theme-version="dark"] .border, [direction="rtl"][data-theme-version="dark"] .border-left, [direction="rtl"][data-theme-version="dark"] .border-right {
  border-color: #444444 !important; }

[direction="rtl"] .text-right {
  text-align: left !important; }

[direction="rtl"] .text-left {
  text-align: right !important; }

[direction="rtl"] .border-right {
  border-left: 0.0625rem solid #E6E6E6 !important;
  border-right: 0 !important; }

[direction="rtl"] .border-left {
  border-right: 0.0625rem solid #E6E6E6 !important;
  border-left: 0 !important; }

[direction="rtl"] .dropdown-menu {
  left: auto; }

[direction="rtl"] .dropdown-menu-right {
  left: 0;
  right: auto; }
  @media only screen and (max-width: 35.9375rem) {
    [direction="rtl"] .dropdown-menu-right {
      left: 0.9375rem; } }

[direction="rtl"] .notification_dropdown .dropdown-menu-right .media > span {
  margin-left: 0.625rem;
  margin-right: 0; }

[direction="rtl"]:not([data-container="boxed"]) .nav-header {
  left: auto;
  right: 0; }

[direction="rtl"][data-container="wide-boxed"] .nav-header {
  left: auto;
  right: auto; }

[direction="rtl"] .nav-header {
  text-align: right;
  right: auto; }
  [direction="rtl"] .nav-header .brand-title {
    margin-left: 0;
    margin-right: 0.9375rem; }
  [direction="rtl"] .nav-header .brand-logo {
    padding-left: 0;
    padding-right: 1.75rem; }
    [data-sidebar-style="compact"][direction="rtl"] .nav-header .brand-logo {
      padding-right: 0; }
    [data-sidebar-style="compact"][direction="rtl"] .nav-header .brand-logo[data-layout="horizontal"] {
      padding-right: 1.875rem; }
    [data-sidebar-style="mini"][direction="rtl"] .nav-header .brand-logo {
      padding-right: 0; }
    [data-sidebar-style="modern"][direction="rtl"] .nav-header .brand-logo {
      padding-right: 0; }
      [data-layout="horizontal"][data-sidebar-style="modern"][direction="rtl"] .nav-header .brand-logo {
        padding-right: 1.875rem; }
    @media (max-width: 767.98px) {
      [direction="rtl"] .nav-header .brand-logo {
        padding-right: 0; } }

[direction="rtl"] .nav-control {
  right: auto;
  left: -4.0625rem; }
  @media (max-width: 767.98px) {
    [direction="rtl"] .nav-control {
      left: -4.0625rem; } }
  @media (max-width: 575.98px) {
    [direction="rtl"] .nav-control {
      left: -2.0625rem; } }

[direction="rtl"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active {
  right: 0; }

[direction="rtl"][data-sidebar-style="compact"][data-layout="horizontal"] .nav-header .brand-logo {
  padding-right: 2.5rem; }

[direction="rtl"][data-sidebar-style="modern"][data-layout="horizontal"] .nav-header {
  width: 16rem; }

[direction="rtl"] .header {
  padding: 0;
  padding-right: 21.563rem; }
  @media (max-width: 767.98px) {
    [direction="rtl"] .header {
      padding-right: 5rem;
      padding-left: 0; } }
  [direction="rtl"] .header .header-content {
    padding-left: 1.875rem;
    padding-right: 5.3125rem; }
    @media only screen and (max-width: 35.9375rem) {
      [direction="rtl"] .header .header-content {
        padding-right: 3.5rem;
        padding-left: 0.938rem; } }
    [data-sidebar-style="compact"][direction="rtl"] .header .header-content {
      padding-right: 1.875rem; }
    [data-sidebar-style="modern"][direction="rtl"] .header .header-content {
      padding-right: 5.3125rem; }
    [data-sidebar-style="overlay"][direction="rtl"] .header .header-content {
      padding-right: 5.3125rem; }
      @media only screen and (max-width: 35.9375rem) {
        [data-sidebar-style="overlay"][direction="rtl"] .header .header-content {
          padding-right: 0.5rem; } }
  [direction="rtl"] .header .nav-control {
    right: 0.4375rem;
    left: auto; }

[direction="rtl"] .header-right > li:not(:first-child) {
  padding-left: 0;
  padding-right: 1.25rem;
  margin-right: 0 !important; }
  @media only screen and (max-width: 47.9375rem) {
    [direction="rtl"] .header-right > li:not(:first-child) {
      padding-right: 0.5rem; } }

[direction="rtl"] .header-right .search-area .input-group-append .input-group-text {
  padding-right: auto;
  padding-left: 1.25rem; }

[direction="rtl"] .header-right .search-area .form-control {
  padding-left: auto;
  padding-right: 1.25rem; }

[direction="rtl"] .header-right .header-profile > a.nav-link {
  margin-left: auto;
  padding-left: auto;
  margin-right: 0.9375rem;
  padding-right: 1.875rem;
  border-right: 0.0625rem solid #cdcdcd;
  border-left: 0; }
  [direction="rtl"] .header-right .header-profile > a.nav-link .header-info {
    padding-right: 1.25rem;
    padding-left: auto;
    text-align: right; }
    @media only screen and (max-width: 87.5rem) {
      [direction="rtl"] .header-right .header-profile > a.nav-link .header-info {
        padding-right: 0.625rem; } }
  @media only screen and (max-width: 87.5rem) {
    [direction="rtl"] .header-right .header-profile > a.nav-link {
      margin-right: 0.625rem;
      padding-right: 1.25rem; } }
  @media only screen and (max-width: 35.9375rem) {
    [direction="rtl"] .header-right .header-profile > a.nav-link {
      margin-right: 0rem;
      padding-right: 0rem;
      border-right: 0; } }

[direction="rtl"] .header-left .search_bar .dropdown-menu,
[direction="rtl"] .header-left .search_bar .dropdown-menu.show {
  right: 2.5rem !important; }
  @media only screen and (max-width: 35.9375rem) {
    [direction="rtl"] .header-left .search_bar .dropdown-menu,
    [direction="rtl"] .header-left .search_bar .dropdown-menu.show {
      right: -6.25rem !important; } }

[direction="rtl"] .header-left .search_bar .search_icon {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5rem;
  border-bottom-right-radius: 5rem; }
  @media only screen and (max-width: 35.9375rem) {
    [direction="rtl"] .header-left .search_bar .search_icon {
      border-radius: 5rem; } }

[direction="rtl"][data-layout="horizontal"] .header {
  padding: 0;
  padding-right: 21.563rem; }
  [direction="rtl"][data-layout="horizontal"] .header .header-content {
    padding-right: 2.5rem;
    padding-left: 2.5rem; }

[direction="rtl"][data-layout="horizontal"][data-sidebar-style="full"] .nav-header .brand-logo {
  padding-right: 2.5rem; }

[direction="rtl"][data-layout="horizontal"][data-sidebar-style="mini"] .header {
  padding-right: 7.75rem; }

[direction="rtl"][data-sidebar-style="mini"] .header {
  padding-right: 6.25rem; }

[direction="rtl"][data-sidebar-style="compact"] .header {
  padding: 0 0.9375rem;
  padding-right: 11.25rem; }

[direction="rtl"][data-sidebar-style="compact"][data-layout="horizontal"] .header {
  padding: 0;
  padding-right: 21.563rem; }

[direction="rtl"][data-sidebar-style="modern"] .header {
  padding: 0 0.9375rem;
  padding-right: 10.625rem; }

[direction="rtl"][data-sidebar-style="modern"][data-layout="horizontal"] .header {
  padding: 0;
  padding-right: 16rem; }

[direction="rtl"] {
  text-align: right; }
  [direction="rtl"] .deznav {
    text-align: right; }
    [direction="rtl"] .deznav .metismenu ul:after {
      left: auto;
      right: 1.5625rem; }
    [direction="rtl"] .deznav .metismenu ul a:before {
      left: auto;
      right: 2.625rem; }
    [data-sidebar-style="full"][direction="rtl"] .deznav .metismenu li > a i {
      padding: 0 0 0 0;
      margin-right: 0;
      margin-left: 0.625rem; }
    [direction="rtl"] .deznav .metismenu li > a svg {
      margin-left: 0.3125rem;
      margin-right: 0; }
      [data-sidebar-style="compact"][direction="rtl"] .deznav .metismenu li > a svg {
        left: auto;
        margin-left: auto;
        margin-right: auto; }
      [data-sidebar-style="icon-hover"][direction="rtl"] .deznav .metismenu li > a svg {
        margin-left: 0; }
    [direction="rtl"] .deznav .metismenu li ul a {
      padding-right: 6rem;
      padding-left: 0.625rem; }
    [direction="rtl"] .deznav .metismenu li.active > .has-arrow:after {
      transform: rotate(45deg) translateY(-50%); }
    [direction="rtl"] .deznav .metismenu .has-arrow:after {
      left: 1.5625rem;
      right: auto; }
      [data-layout="horizontal"][direction="rtl"] .deznav .metismenu .has-arrow:after {
        left: 1.125rem; }
      [data-sidebar-style="modern"][direction="rtl"] .deznav .metismenu .has-arrow:after {
        -webkit-transform: rotate(-45deg) translateY(-50%);
        transform: rotate(-45deg) translateY(-50%); }
  [direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu > li .has-arrow:after {
    left: 1.5rem;
    right: auto; }
  [direction="rtl"][data-sidebar-style="mini"] .deznav .metismenu > li > a > i {
    padding: 0; }
  [direction="rtl"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li > ul a.has-arrow:after {
    left: 1.5625rem;
    right: auto; }
  [direction="rtl"][data-sidebar-style="compact"] .deznav .metismenu li > a i {
    padding: 0;
    margin-left: auto;
    margin-right: auto; }
  [direction="rtl"][data-sidebar-style="compact"] .deznav .metismenu li ul a {
    padding-right: 0.625rem;
    padding-left: 0.625rem; }
  [direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li > ul li:hover ul {
    right: 11.8125rem;
    left: 0; }

[direction="rtl"] {
  /* ===== Light gallery ===== */ }
  [direction="rtl"] .select2-container--default .select2-selection--single .select2-selection__arrow {
    left: 15px;
    right: auto; }
  [direction="rtl"] .input-group > .bootstrap-select:not(:first-child) .dropdown-toggle {
    border-radius: 0.75rem 0   0 0.75rem; }
  [direction="rtl"] .list-group {
    padding-left: 0; }
  [direction="rtl"] .form-check-input {
    margin-left: -1.25rem;
    margin-right: inherit; }
  [direction="rtl"] .form-check-inline .form-check-input {
    margin-right: 0;
    margin-left: 10px; }
  [direction="rtl"] .radio input,
  [direction="rtl"] .radio-inline,
  [direction="rtl"] .checkbox input,
  [direction="rtl"] .checkbox-inline input {
    margin-left: 0;
    margin-right: 0; }
  [direction="rtl"] .content-body {
    margin-right: 21.563rem;
    margin-left: auto; }
    [data-sidebar-style="modern"][direction="rtl"] .content-body {
      margin-right: 9.375rem; }
    [direction="rtl"] .content-body .page-titles {
      text-align: right; }
  [direction="rtl"] .doctor-info-details .media-body span i,
  [direction="rtl"] .recovered-chart-deta .col [class*="bg-"] {
    margin-right: 0;
    margin-left: 10px; }
  [direction="rtl"] .patients-chart-deta .col,
  [direction="rtl"] .patients-chart-deta .col [class*="bg-"],
  [direction="rtl"] .recovered-chart-deta .col {
    margin-right: 0;
    margin-left: 15px; }
  [direction="rtl"] .best-doctor .timeline .timeline-panel .media .number {
    left: auto;
    right: -13px; }
  [direction="rtl"] .doctor-info-details .media i {
    right: 0;
    left: -15px; }
  [direction="rtl"] .review-table .disease {
    border-left: 0;
    border-right: 1px solid #eee;
    padding-left: 0;
    padding-right: 20px; }
  [direction="rtl"] .apexcharts-legend-text {
    margin: 4px; }
  [direction="rtl"] .doctor-info-details .media-body {
    padding-left: 0;
    padding-right: 40px; }
  [direction="rtl"] .custom-control {
    margin-left: 0; }
  [direction="rtl"] .review-tab.nav-pills li:first-child a.nav-link {
    border-radius: 0 0.75rem 0 0; }
  [direction="rtl"] .review-tab.nav-pills li:last-child a.nav-link {
    border-radius: 0.75rem 0 0 0; }
  [direction="rtl"] .form-head .btn i {
    margin-left: 5px;
    margin-right: 0; }
  [direction="rtl"] .iconbox {
    padding-left: 0;
    padding-right: 70px; }
    [direction="rtl"] .iconbox i {
      left: auto;
      right: 0; }
  [direction="rtl"] .table.tr-rounded tr td:first-child, [direction="rtl"] .table.tr-rounded tr th:first-child {
    border-radius: 0 0.625rem 0.625rem 0; }
  [direction="rtl"] .table.tr-rounded tr td:last-child, [direction="rtl"] .table.tr-rounded tr th:last-child {
    border-radius: 0.625rem 0 0 0.625rem; }
  [direction="rtl"] .custom-switch.toggle-switch.text-right {
    padding-left: 48px;
    padding-right: 0; }
  [direction="rtl"] .toggle-switch.text-right .custom-control-label:before {
    right: auto !important;
    left: -47px; }
  [direction="rtl"] .toggle-switch.text-right .custom-control-label:after {
    right: auto !important;
    left: -28px; }
  [direction="rtl"] .toggle-switch.text-right .custom-control-input:checked ~ .custom-control-label:after {
    left: -62px;
    right: auto !important; }
  [direction="rtl"] .check-switch {
    padding-right: 40px; }
    [direction="rtl"] .check-switch .custom-control-label:after, [direction="rtl"] .check-switch .custom-control-label:before {
      right: -35px !important; }
  [direction="rtl"] .bar-chart .apexcharts-yaxis {
    transform: translatex(101%); }
  [direction="rtl"] .detault-daterange .input-group-text {
    padding: 0.532rem 0.75rem;
    padding-right: auto;
    padding-left: 0; }
  [direction="rtl"] .form-wrapper .input-group .form-control {
    text-align: left; }
  [direction="rtl"] .timeline-chart .apexcharts-yaxis {
    transform: translateX(0); }
  [direction="rtl"] .card-table td:first-child {
    padding-right: 30px;
    padding-left: 10px; }
  [direction="rtl"] .card-table td:last-child {
    padding-left: 30px;
    padding-right: 10px; }
  [direction="rtl"] .chatbox .img_cont {
    margin-right: 0px;
    margin-left: 10px; }
  [direction="rtl"] .profile-tab .nav-item .nav-link {
    margin-right: 0;
    margin-left: 30px; }
    @media only screen and (max-width: 35.9375rem) {
      [direction="rtl"] .profile-tab .nav-item .nav-link {
        margin-left: 0px; } }
  [direction="rtl"] .widget-timeline .timeline > li > .timeline-panel {
    margin-left: 0px;
    margin-right: 40px; }
  [direction="rtl"] .widget-timeline.style-1 .timeline .timeline-badge.timeline-badge + .timeline-panel {
    padding: 5px 15px 5px 10px;
    border-width: 0px 5px 0px 0px; }
  [direction="rtl"] .widget-timeline.style-1 .timeline .timeline-badge.timeline-badge + .timeline-panel:after {
    border: 0;
    right: -9px;
    width: 7px;
    left: auto;
    height: 7px; }
  [direction="rtl"] .widget-timeline .timeline > li > .timeline-badge {
    left: auto;
    right: 0px; }
  [direction="rtl"] .widget-timeline .timeline > li > .timeline-panel:after {
    left: auto;
    right: -5px; }
  [direction="rtl"] .chatbox .msg_cotainer {
    margin-left: 0;
    margin-right: 10px; }
  [direction="rtl"] .new-arrival-content .price {
    float: left; }
  [direction="rtl"] .input-group > .bootstrap-select:not(:first-child) .dropdown-toggle {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0.625rem;
    border-bottom-left-radius: 0.625rem; }
  [direction="rtl"] .input-group > .bootstrap-select:not(:last-child) .dropdown-toggle {
    border-top-right-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  [direction="rtl"] .breadcrumb-item + .breadcrumb-item {
    padding-right: 0.5rem;
    padding-left: 0; }
  [direction="rtl"] .breadcrumb-item + .breadcrumb-item::before {
    padding-right: 0;
    padding-left: 0.5rem; }
  [direction="rtl"] .chatbox .chatbox-close {
    left: 340px;
    right: auto; }
    @media only screen and (max-width: 35.9375rem) {
      [direction="rtl"] .chatbox .chatbox-close {
        left: 280px; } }
  [direction="rtl"] .separator {
    margin-right: 0;
    margin-left: 9px; }
  [direction="rtl"] .card-tabs .nav-tabs {
    padding-right: 5px; }
  [direction="rtl"] .chatbox .msg_cotainer {
    margin-left: 0;
    margin-right: 10px;
    border-radius: 1.375rem 0 1.375rem 1.375rem; }
    [direction="rtl"] .chatbox .msg_cotainer:after {
      left: auto;
      right: -10px;
      transform: rotate(-90deg); }
  [direction="rtl"] .chatbox .type_msg .input-group .input-group-append .btn {
    border-top-right-radius: 38px !important;
    border-bottom-right-radius: 38px !important; }
  [direction="rtl"] .chatbox .msg_cotainer_send {
    margin-right: 0px;
    margin-left: 10px;
    border-radius: 0 6px 6px 6px; }
    [direction="rtl"] .chatbox .msg_cotainer_send:after {
      right: auto;
      left: -10px;
      transform: rotate(90deg); }
  [direction="rtl"] .new-arrival-content .price {
    float: left; }
  [direction="rtl"] .trending-menus .tr-row .num {
    margin-right: 0;
    margin-left: 15px; }
  [direction="rtl"] .default-select.style-2 .btn:after {
    margin-left: 0;
    margin-right: 0.5em; }
  [direction="rtl"] .widget-timeline-icon li .icon {
    left: auto;
    right: -2px; }
  [direction="rtl"] .widget-timeline-icon li a {
    padding: 25px 0px 0px 50px; }
  @media only screen and (max-width: 35.9375rem) {
    [direction="rtl"] .widget-timeline-icon .timeline {
      margin-left: 0;
      margin-right: 10px; }
    [direction="rtl"] .widget-timeline-icon li {
      border-left: 0;
      border-right: 6px solid var(--primary); }
      [direction="rtl"] .widget-timeline-icon li a {
        padding: 0 30px 30px 0; }
      [direction="rtl"] .widget-timeline-icon li .icon {
        right: -12px; }
      [direction="rtl"] .widget-timeline-icon li:last-child {
        border-color: transparent; } }
  [direction="rtl"] #revenueMap .apexcharts-yaxis, [direction="rtl"] #customerMapkm .apexcharts-yaxis {
    transform: translateX(0px); }
  [direction="rtl"] .mail-list .list-group-item i {
    padding-right: 0;
    padding-left: 0.625rem; }
  [direction="rtl"] .dz-demo-panel {
    right: auto;
    left: -380px; }
    [direction="rtl"] .dz-demo-panel.show {
      right: unset;
      left: 0; }
    [direction="rtl"] .dz-demo-panel .dz-demo-trigger {
      left: 100%;
      right: auto;
      border-radius: 0 5px 5px 0;
      box-shadow: 5px 3px 5px 0px rgba(119, 119, 119, 0.15); }
  [direction="rtl"][data-layout="horizontal"] .content-body {
    margin-right: 0; }
  [direction="rtl"][data-layout="horizontal"] .deznav .metismenu li li .has-arrow:after {
    -webkit-transform: rotate(-4deg) translateY(-50%);
    transform: rotate(-45deg) translateY(-50%); }
  [direction="rtl"][data-sidebar-style="mini"]:not([data-layout="horizontal"]) .content-body {
    margin-right: 6.25rem; }
  [direction="rtl"][data-sidebar-style="compact"]:not([data-layout="horizontal"]) .content-body {
    margin-right: 11.25rem; }
  [direction="rtl"][data-sidebar-style="overlay"] .content-body {
    margin-right: 0; }
  [direction="rtl"] #external-events .external-event:before {
    margin-right: 0;
    margin-left: .9rem; }
  [direction="rtl"] .post-input a i {
    margin-left: 15px;
    margin-right: 0; }
  [direction="rtl"][data-sidebar-style="compact"] .deznav .metismenu .has-arrow:after {
    -webkit-transform: rotate(-45deg) translateY(-50%);
    transform: rotate(-45deg) translateY(-50%); }
  [direction="rtl"] .deznav .metismenu .has-arrow:after {
    -webkit-transform: rotate(-135deg) translateY(-50%);
    transform: rotate(-135deg) translateY(-50%); }
  [direction="rtl"] .deznav .metismenu .has-arrow[aria-expanded=true]:after,
  [direction="rtl"] .deznav .metismenu .mm-active > .has-arrow:after {
    -webkit-transform: rotate(-135deg) translateY(-50%);
    transform: rotate(-135deg); }
  [direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu .has-arrow[aria-expanded=true]:after,
  [direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu .mm-active > .has-arrow:after {
    -webkit-transform: rotate(-45deg) translateY(-50%);
    transform: rotate(-45deg); }
  [direction="rtl"] .chatbox {
    left: -500px;
    right: auto; }
  [direction="rtl"] .chatbox.active {
    left: 0;
    right: auto; }
  @media only screen and (max-width: 575px) {
    [direction="rtl"] .best-doctor .timeline .timeline-panel .media {
      float: right;
      margin-right: 0 !important;
      margin-left: 15px !important; } }
  [direction="rtl"] .default-select.style-1 .btn:after {
    margin-left: 0;
    margin-right: 0.5em; }
  [direction="rtl"] .pagination .page-indicator {
    transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg); }
  [direction="rtl"] .lg-outer.lg-visible {
    direction: ltr; }
  [direction="rtl"] .chart-point .chart-point-list {
    margin: 0;
    padding-right: 20px; }
  [direction="rtl"] .noUi-target {
    direction: rtl; }
  [direction="rtl"] .noUi-vertical .noUi-pips-vertical {
    left: -20px; }
  [direction="rtl"] .noUi-vertical .noUi-value-vertical {
    padding-left: 0;
    padding-right: 25px; }
  [direction="rtl"] .sidebar-right .ps--active-x > .ps__rail-x {
    display: none; }
  [direction="rtl"] .form-wizard .nav-wizard li .nav-link:after {
    right: 50%;
    left: auto; }
  [direction="rtl"] .dtp > .dtp-content {
    right: 50%;
    left: auto; }
  [direction="rtl"] .modal-header .close {
    margin: 0;
    left: 0;
    top: 0px;
    right: auto; }
  [direction="rtl"] .input-group-prepend .btn + .btn {
    border-radius: 0 !important; }
  [direction="rtl"] .form-control + .input-group-append .btn:first-child {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important; }
  [direction="rtl"] .input-group > .custom-file:not(:first-child) .custom-file-label {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0.75rem;
    border-top-left-radius: 0.75rem; }
  [direction="rtl"] .custom-file-label::after {
    border-radius: .75rem 0 0 .75rem; }
  [direction="rtl"] .input-group > .custom-file:not(:last-child) .custom-file-label {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem; }
  [direction="rtl"] .input-group > .custom-file:not(:last-child) .custom-file-label::after {
    border-radius: 0; }

@media only screen and (max-width: 1350px) and (min-width: 1200px) {
  [direction="rtl"] .content-body {
    margin-right: 17rem; } }

[direction="rtl"] .sidebar-right {
  right: auto;
  left: -50rem; }
  [direction="rtl"] .sidebar-right.show {
    left: 5.25rem;
    right: unset; }
  [direction="rtl"] .sidebar-right .sidebar-right-trigger {
    left: 100%;
    right: auto;
    border-radius: 0 5px 5px 0;
    box-shadow: 5px 3px 5px 0px rgba(119, 119, 119, 0.15); }
  [direction="rtl"] .sidebar-right .sidebar-close-trigger {
    right: auto;
    left: -48px; }

[direction="rtl"] .bootstrap-select .dropdown-toggle .filter-option {
  text-align: right; }

html[dir="rtl"] [direction="rtl"] .footer {
  padding-right: 17.1875rem;
  padding-left: 0; }
  @media (max-width: 767.98px) {
    html[dir="rtl"] [direction="rtl"] .footer {
      padding-right: 0; } }

html[dir="rtl"] [direction="rtl"][data-sidebar-style="overlay"] .footer {
  padding-right: 0; }

html[dir="rtl"] [direction="rtl"] .menu-toggle .footer {
  padding-right: 3.75rem; }

html[dir="rtl"] [direction="rtl"][data-container="boxed"] .footer {
  padding-right: 0; }

html[dir="rtl"] [direction="rtl"][data-sidebar-style="mini"]:not([data-layout="horizontal"]) .footer {
  padding-right: 3.75rem; }

html[dir="rtl"] [direction="rtl"][data-sidebar-style="compact"]:not([data-layout="horizontal"]) .footer {
  padding-right: 9.375rem; }

/* //action button color
$add-btn:blue
$edit-btn:
$delete-btn: */
:root {
  --primary: #A0CF1A;
  --secondary: #111828;
  --secondary-dark: #020304;
  --primary-hover: #7da214;
  --primary-dark: #374709;
  --rgba-primary-1: rgba(160, 207, 26, 0.1);
  --rgba-primary-2: rgba(160, 207, 26, 0.2);
  --rgba-primary-3: rgba(160, 207, 26, 0.3);
  --rgba-primary-4: rgba(160, 207, 26, 0.4);
  --rgba-primary-5: rgba(160, 207, 26, 0.5);
  --rgba-primary-6: rgba(160, 207, 26, 0.6);
  --rgba-primary-7: rgba(160, 207, 26, 0.7);
  --rgba-primary-8: rgba(160, 207, 26, 0.8);
  --rgba-primary-9: rgba(160, 207, 26, 0.9);
  --rgba-secondary-1: rgba(17, 24, 40, 0.1);
  --rgba-secondary-2: rgba(17, 24, 40, 0.2);
  --rgba-secondary-3: rgba(17, 24, 40, 0.3);
  --rgba-secondary-4: rgba(17, 24, 40, 0.4);
  --rgba-secondary-5: rgba(17, 24, 40, 0.5);
  --rgba-secondary-6: rgba(17, 24, 40, 0.6);
  --rgba-secondary-7: rgba(17, 24, 40, 0.7);
  --rgba-secondary-8: rgba(17, 24, 40, 0.8);
  --rgba-secondary-9: rgba(17, 24, 40, 0.9);
  --font-family-base: DM Sans, sans-serif, sans-serif;
  --font-family-title: DM Sans, sans-serif, sans-serif;
  --title: #111828;
  --text-dark: #111828; }

/* $primary-light: lighten($primary, 43%);
$secondary-light: lighten($secondary, 10%);
$success-light: lighten($success, 15%);
$warning-light: lighten($warning, 20%);
$danger-light: lighten($danger, 10%);
$info-light: lighten($info, 20%);
$dark-light: lighten($dark, 50%); */
[data-theme-version="dark"] {
  background: #1E1E1E;
  color: #828690;
  --nav-headbg: #2c2c2c;
  --sidebar-bg: #2c2c2c;
  --headerbg: #2c2c2c; }
  [data-theme-version="dark"] h1, [data-theme-version="dark"] .h1,
  [data-theme-version="dark"] h2,
  [data-theme-version="dark"] .h2,
  [data-theme-version="dark"] h3,
  [data-theme-version="dark"] .h3,
  [data-theme-version="dark"] h4,
  [data-theme-version="dark"] .h4,
  [data-theme-version="dark"] h5,
  [data-theme-version="dark"] .h5,
  [data-theme-version="dark"] h6,
  [data-theme-version="dark"] .h6 {
    color: #fff; }
  [data-theme-version="dark"] a.link {
    color: #ddd; }
  [data-theme-version="dark"] a.link:focus,
  [data-theme-version="dark"] a.link:hover {
    color: #81A5F9; }
  [data-theme-version="dark"] a:hover {
    color: #fff; }
  [data-theme-version="dark"] .border-right {
    border-right: 0.0625rem solid #444444 !important; }
  [data-theme-version="dark"] .border-left {
    border-left: 0.0625rem solid #444444 !important; }
  [data-theme-version="dark"] .border-top {
    border-top: 0.0625rem solid #444444 !important; }
  [data-theme-version="dark"] .border-bottom {
    border-bottom: 0.0625rem solid #444444 !important; }
  [data-theme-version="dark"] .border {
    border: 0.0625rem solid #444444 !important; }

[data-theme-version="dark"] {
  --secondary:$white;
  --text-dark:$white; }
  [data-theme-version="dark"] .card {
    background-color: #2c2c2c;
    box-shadow: none;
    border-color: #444444; }
  [data-theme-version="dark"] .dropdown-menu {
    background-color: #2c2c2c;
    box-shadow: 0rem 0rem 0rem 0.0625rem rgba(255, 255, 255, 0.1); }
    [data-theme-version="dark"] .dropdown-menu .dropdown-item {
      color: #777777; }
      [data-theme-version="dark"] .dropdown-menu .dropdown-item.selected, [data-theme-version="dark"] .dropdown-menu .dropdown-item.selected.active, [data-theme-version="dark"] .dropdown-menu .dropdown-item.active, [data-theme-version="dark"] .dropdown-menu .dropdown-item:hover, [data-theme-version="dark"] .dropdown-menu .dropdown-item:focus {
        background-color: #2c2c2c;
        color: #fff; }
  [data-theme-version="dark"] a {
    color: #fff !important; }
  [data-theme-version="dark"] .btn-link g [fill] {
    fill: #fff; }
  [data-theme-version="dark"] .btn-light:active,
  [data-theme-version="dark"] .btn-light:focus,
  [data-theme-version="dark"] .btn-light:hover {
    color: #000; }
  [data-theme-version="dark"] .form-control {
    background-color: transparent;
    border-color: #444444;
    color: #fff; }
  [data-theme-version="dark"] .form-select {
    background-color: #1E1E1E;
    border-color: #444444;
    color: #fff; }
  [data-theme-version="dark"] .toggle-switch.form-check .form-check-input {
    background: #1E1E1E; }
    [data-theme-version="dark"] .toggle-switch.form-check .form-check-input:checked {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
      background: var(--rgba-primary-1); }
  [data-theme-version="dark"] .modal-content {
    background: #2c2c2c; }
  [data-theme-version="dark"] .modal-footer,
  [data-theme-version="dark"] .modal-header {
    border-color: #444444; }
  [data-theme-version="dark"] .close {
    font-weight: 400;
    color: #fff;
    text-shadow: none; }
    [data-theme-version="dark"] .close:hover {
      color: #fff; }
  [data-theme-version="dark"] .star-rating .product-review,
  [data-theme-version="dark"] .new-arrival-content .item {
    color: #fff; }
  [data-theme-version="dark"] .custom-dropdown .dropdown-menu {
    border-color: #444444; }
  [data-theme-version="dark"] .widget-stat .media > span {
    background: #444444;
    border-color: #444444;
    color: #fff; }
  [data-theme-version="dark"] .plus-minus-input .custom-btn {
    background: #1E1E1E;
    border-color: #444444; }
  [data-theme-version="dark"] .dropdown-divider,
  [data-theme-version="dark"] .size-filter ul li {
    border-color: #444444; }
  [data-theme-version="dark"] .custom-select {
    border-color: #444444;
    color: #828690;
    background: #1E1E1E; }
  [data-theme-version="dark"] .nav-tabs {
    border-color: var(--primary); }
    [data-theme-version="dark"] .nav-tabs .nav-link {
      color: #fff; }
  [data-theme-version="dark"] .mail-list .list-group-item.active i {
    color: #fff; }
  [data-theme-version="dark"] hr {
    border-color: #444444; }
  [data-theme-version="dark"] .grid-col {
    background: #1E1E1E; }
  [data-theme-version="dark"] .noUi-target {
    border-color: #444444;
    border-radius: 0.5rem;
    box-shadow: none; }
    [data-theme-version="dark"] .noUi-target .noUi-connects {
      background: #444444; }
  [data-theme-version="dark"] .noUi-marker-large,
  [data-theme-version="dark"] .noUi-marker {
    background: #444444; }
  [data-theme-version="dark"] .input-group-text {
    background: transparent;
    color: #fff;
    border-color: #444444; }
  [data-theme-version="dark"] .note-editor.note-frame {
    border-color: #444444; }
    [data-theme-version="dark"] .note-editor.note-frame .btn {
      color: #fff; }
    [data-theme-version="dark"] .note-editor.note-frame .note-editing-area .note-editable {
      color: #fff; }
  [data-theme-version="dark"] .widget-media .timeline .timeline-panel {
    border-color: #444444; }
  [data-theme-version="dark"] .notification_dropdown .dropdown-menu-right .all-notification {
    border-color: #444444; }
  [data-theme-version="dark"] #user-activity .nav-tabs .nav-link {
    border-color: #444444; }
    [data-theme-version="dark"] #user-activity .nav-tabs .nav-link.active {
      background: #1E1E1E;
      color: #fff; }
  [data-theme-version="dark"] .list-group-item-action {
    color: #888888; }
  [data-theme-version="dark"] .list-group-item-action:focus,
  [data-theme-version="dark"] .list-group-item-action:hover,
  [data-theme-version="dark"] .list-group-item-action:focus {
    background-color: #1E1E1E;
    border-color: #1E1E1E; }
  [data-theme-version="dark"] .list-group-item.active {
    color: black !important;
    border-color: var(--primary); }
    [data-theme-version="dark"] .list-group-item.active:focus, [data-theme-version="dark"] .list-group-item.active:hover, [data-theme-version="dark"] .list-group-item.active:focus {
      background-color: var(--primary);
      border-color: var(--primary);
      color: #fff; }
  [data-theme-version="dark"] .swal2-popup {
    background: #2c2c2c; }
  [data-theme-version="dark"] .form-head .btn-outline-primary {
    border-color: #444444; }
  [data-theme-version="dark"] .form-head .btn-outline-primary:hover {
    border-color: var(--primary); }
  [data-theme-version="dark"] .review-tab.nav-pills li a.nav-link.active {
    background: transparent; }
  [data-theme-version="dark"] .new-arrival-content h4 a, [data-theme-version="dark"] .new-arrival-content .h4 a {
    color: #fff; }
  [data-theme-version="dark"] .text-black {
    color: #fff; }
  [data-theme-version="dark"] .abilities-chart .ct-chart .ct-label {
    fill: #fff; }
  [data-theme-version="dark"] .morris_chart_height text tspan {
    fill: #fff; }
  [data-theme-version="dark"] .btn-link {
    color: #fff; }
  [data-theme-version="dark"] .order-bg {
    background: #1E1E1E; }
  [data-theme-version="dark"] .detault-daterange {
    background: #1E1E1E;
    color: #fff; }
    [data-theme-version="dark"] .detault-daterange .input-group-text {
      background: #2c2c2c;
      border: 0; }
  [data-theme-version="dark"] .dataTablesCard {
    background-color: #2c2c2c; }
  [data-theme-version="dark"] .compose-content .dropzone {
    background: #1E1E1E !important; }
    [data-theme-version="dark"] .compose-content .dropzone .dz-message .dz-button {
      color: #fff; }
  [data-theme-version="dark"] .daterangepicker {
    background: #1E1E1E;
    border-color: var(--primary); }
    [data-theme-version="dark"] .daterangepicker .calendar-table {
      border-color: var(--primary);
      background: #1E1E1E; }
      [data-theme-version="dark"] .daterangepicker .calendar-table .table-condensed td:hover {
        background-color: var(--primary);
        color: #fff; }
    [data-theme-version="dark"] .daterangepicker:after {
      border-bottom: 0.375rem solid #1E1E1E; }
  [data-theme-version="dark"] .daterangepicker select.hourselect, [data-theme-version="dark"] .daterangepicker select.minuteselect, [data-theme-version="dark"] .daterangepicker select.secondselect, [data-theme-version="dark"] .daterangepicker select.ampmselect {
    background: #1E1E1E;
    border: 0.0625rem solid #444444;
    color: #fff; }
  [data-theme-version="dark"] .daterangepicker td.off,
  [data-theme-version="dark"] .daterangepicker td.off.in-range,
  [data-theme-version="dark"] .daterangepicker td.off.start-date,
  [data-theme-version="dark"] .daterangepicker td.off.end-date {
    background-color: #2c2c2c; }
    [data-theme-version="dark"] .daterangepicker td.off:hover,
    [data-theme-version="dark"] .daterangepicker td.off.in-range:hover,
    [data-theme-version="dark"] .daterangepicker td.off.start-date:hover,
    [data-theme-version="dark"] .daterangepicker td.off.end-date:hover {
      background-color: var(--primary);
      color: #fff; }
  [data-theme-version="dark"] .app-fullcalendar .fc-button {
    background-color: #1E1E1E;
    border-color: var(--primary);
    color: #fff;
    text-shadow: none; }
    [data-theme-version="dark"] .app-fullcalendar .fc-button:hover, [data-theme-version="dark"] .app-fullcalendar .fc-button.fc-stat-hover {
      background-color: var(--primary); }
  [data-theme-version="dark"] .dd-handle {
    border-color: #444444;
    background-color: #1E1E1E; }
  [data-theme-version="dark"] .menu-toggle .deznav .metismenu li > ul {
    background: #2c2c2c; }
  [data-theme-version="dark"] .header-right .notification_dropdown .nav-link {
    border-color: #444444; }
  [data-theme-version="dark"] .clockpicker-popover .popover-content {
    background-color: #2c2c2c; }
  [data-theme-version="dark"] .clockpicker-plate {
    background-color: #1E1E1E; }
  [data-theme-version="dark"] .clockpicker-popover .popover-title {
    background-color: #1E1E1E;
    color: #fff; }
  [data-theme-version="dark"] .form-wizard .nav-wizard li .nav-link span {
    background-color: #1E1E1E; }
  [data-theme-version="dark"] .form-wizard .nav-wizard li .nav-link:after {
    background: #1E1E1E; }
  [data-theme-version="dark"] .form-wizard .nav-wizard li .nav-link.active:after, [data-theme-version="dark"] .form-wizard .nav-wizard li .nav-link.done:after {
    background: var(--primary); }
  [data-theme-version="dark"] .form-wizard .nav-wizard li .nav-link.active span, [data-theme-version="dark"] .form-wizard .nav-wizard li .nav-link.done span {
    background: var(--primary-light); }
  [data-theme-version="dark"] .check-switch .custom-control-label:after, [data-theme-version="dark"] .check-switch .custom-control-label:before {
    border-color: var(--primary); }
  [data-theme-version="dark"] .fc-unthemed .fc-today {
    background: #1E1E1E; }
  [data-theme-version="dark"] .fc-unthemed .fc-divider, [data-theme-version="dark"] .fc-unthemed .fc-list-heading td, [data-theme-version="dark"] .fc-unthemed .fc-popover .fc-header {
    background: #444444; }
  [data-theme-version="dark"] .picker__box {
    background: #1E1E1E; }
    [data-theme-version="dark"] .picker__box .picker__button--clear, [data-theme-version="dark"] .picker__box .picker__button--close, [data-theme-version="dark"] .picker__box .picker__button--today {
      background: #2c2c2c;
      color: #fff; }
      [data-theme-version="dark"] .picker__box .picker__button--clear:hover:before, [data-theme-version="dark"] .picker__box .picker__button--close:hover:before, [data-theme-version="dark"] .picker__box .picker__button--today:hover:before {
        color: #fff; }
  [data-theme-version="dark"] .picker {
    color: #999; }
  [data-theme-version="dark"] .dtp > .dtp-content {
    background: #1E1E1E; }
  [data-theme-version="dark"] .dtp table.dtp-picker-days tr > td > a {
    color: #68686a; }
    [data-theme-version="dark"] .dtp table.dtp-picker-days tr > td > a.selected {
      color: #fff; }
  [data-theme-version="dark"] .order-request tbody tr {
    border-color: #444444; }
  [data-theme-version="dark"] .card-list li {
    color: #fff; }
  [data-theme-version="dark"] .card-bx .change-btn:hover {
    color: var(--primary); }
  [data-theme-version="dark"] .invoice-card.bg-warning {
    background-color: #5b3c1f !important; }
  [data-theme-version="dark"] .invoice-card.bg-success {
    background-color: #2a6729 !important; }
  [data-theme-version="dark"] .invoice-card.bg-info {
    background-color: #4c276a !important; }
  [data-theme-version="dark"] .invoice-card.bg-secondary {
    background-color: #1c3e52 !important; }
  [data-theme-version="dark"] .user-list li {
    border-color: #2c2c2c; }
  [data-theme-version="dark"] .toggle-switch {
    color: #fff; }
  [data-theme-version="dark"] .bar-chart .apexcharts-text tspan {
    fill: #969ba0; }
  [data-theme-version="dark"] .bar-chart line {
    stroke: #444444; }
  [data-theme-version="dark"] .accordion.style-1 .accordion-item, [data-theme-version="dark"] .invoice-list {
    border-color: #444444; }
  [data-theme-version="dark"] .accordion.style-1 .accordion-header.collapsed .user-info a, [data-theme-version="dark"] .accordion.style-1 .accordion-header.collapsed .user-info, [data-theme-version="dark"] .accordion.style-1 .accordion-header.collapsed > span {
    color: #fff; }
  [data-theme-version="dark"] .ic-card > a {
    background: #25479f; }
    [data-theme-version="dark"] .ic-card > a:first-child {
      border-color: #25479f; }
  [data-theme-version="dark"] .ic-card span {
    color: #fff; }
  [data-theme-version="dark"] table.dataTable thead th, [data-theme-version="dark"] table.dataTable thead td {
    border-color: #444444 !important; }
  [data-theme-version="dark"] .paging_simple_numbers.dataTables_paginate {
    background: #2c2c2c; }
  [data-theme-version="dark"] .dataTables_info {
    color: #fff; }
  [data-theme-version="dark"] .dataTables_wrapper .dataTables_paginate span .paginate_button.current,
  [data-theme-version="dark"] .dataTables_wrapper .dataTables_paginate span .paginate_button:hover {
    background: #000 !important;
    color: #fff !important; }
  [data-theme-version="dark"] .dashboard-select {
    background: var(--rgba-primary-1);
    color: white;
    border-color: #444444; }
  [data-theme-version="dark"] .dashboard-select .list {
    background: #2c2c2c; }
  [data-theme-version="dark"] .dashboard-select .option:hover, [data-theme-version="dark"] .dashboard-select .option.focus, [data-theme-version="dark"] .dashboard-select .option.selected.focus {
    background: #1E1E1E; }
  [data-theme-version="dark"] .card-tabs.style-1 .nav-tabs {
    background: #2c2c2c; }
  [data-theme-version="dark"] .transaction-details {
    border-color: #444444; }
  [data-theme-version="dark"] .description {
    color: #fff; }
  [data-theme-version="dark"] .transaction-details .amount-bx {
    background: #3f250d; }
    [data-theme-version="dark"] .transaction-details .amount-bx i {
      background: #8d3b0c; }
  [data-theme-version="dark"] .nice-select .option {
    background: #2c2c2c; }
  [data-theme-version="dark"] .nice-select .option:hover, [data-theme-version="dark"] .nice-select .option.focus, [data-theme-version="dark"] .nice-select .option.selected.focus {
    background: #2c2c2c; }
  [data-theme-version="dark"] .card-tabs.style-1 {
    border-color: #444444; }
  [data-theme-version="dark"] .nice-select .list {
    background: #1E1E1E;
    border-color: #444444; }
  [data-theme-version="dark"] .table-search .input-group .input-group-text {
    background: #2c2c2c; }
  [data-theme-version="dark"] .dark-btn svg path {
    stroke: #fff; }
  [data-theme-version="dark"] .bar-chart .apexcharts-legend-text {
    color: #fff !important; }
  [data-theme-version="dark"] .Sales tspan {
    fill: #fff; }
  [data-theme-version="dark"] .timeline-chart rect {
    fill: #1E1E1E; }
  [data-theme-version="dark"] .header-info span {
    color: #fff !important; }
  [data-theme-version="dark"] .smallipop-instance.blue {
    color: #fff; }
  [data-theme-version="dark"] .property-map .smallipop.smallimap-mapicon .circle-marker {
    border-color: #444444; }
  [data-theme-version="dark"] .property-features li {
    color: #fff; }
  [data-theme-version="dark"] .review-tabs-1 {
    background: #2c2c2c; }
  [data-theme-version="dark"] .coin-tabs {
    background: #2E2E40; }
    [data-theme-version="dark"] .coin-tabs .nav-link.active {
      background: var(--primary) !important;
      color: #fff; }
  [data-theme-version="dark"] .separate-row > div {
    border-color: #444444; }
  [data-theme-version="dark"] .bg-white {
    background: #1E1E1E !important; }
  [data-theme-version="dark"] .deznav .header-info2 span {
    color: #fff; }
  [data-theme-version="dark"] .brand-title path {
    fill: #fff; }
  [data-theme-version="dark"] .brand-title tspan {
    fill: #fff; }
  [data-theme-version="dark"] .shapreter-row > div {
    border-color: #444444; }
  [data-theme-version="dark"] .chartBar line {
    stroke: transparent; }
  [data-theme-version="dark"] .search-job .search-dropdown:after {
    background: #444444; }
  [data-theme-version="dark"] .chartjs line {
    stroke: #444444; }
  [data-theme-version="dark"] .form-label {
    color: #fff !important; }
  [data-theme-version="dark"] .datepicker-switch {
    color: #fff; }
  [data-theme-version="dark"] .datepicker.datepicker-dropdown td.day, [data-theme-version="dark"] .datepicker.datepicker-dropdown th.next, [data-theme-version="dark"] .datepicker.datepicker-dropdown th.prev {
    color: #fff; }
  [data-theme-version="dark"] .datepicker.datepicker-dropdown th.dow {
    color: #fff; }
  [data-theme-version="dark"] .datepicker.datepicker-dropdown th.datepicker-switch, [data-theme-version="dark"] .datepicker.datepicker-dropdown th.next, [data-theme-version="dark"] .datepicker.datepicker-dropdown th.prev {
    color: #fff; }
  [data-theme-version="dark"] .btn-close {
    color: #fff !important;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23099'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat; }
  [data-theme-version="dark"] .btn-close i {
    display: block;
    transform: scale(1.5); }
  [data-theme-version="dark"] .bgl-secondary {
    color: #fff; }
  [data-theme-version="dark"] .application tr td .btn {
    color: #fff; }
  [data-theme-version="dark"] .btn-link svg path {
    stroke: #fff; }
  [data-theme-version="dark"] .alert.alert-outline-secondary,
  [data-theme-version="dark"] .alert.alert-outline-dark {
    color: #fff; }
  [data-theme-version="dark"] .form-control-plaintext {
    color: #fff; }
  [data-theme-version="dark"] .timeline-panel .media-body small, [data-theme-version="dark"] .timeline-panel .media-body .small {
    color: #fff; }
  [data-theme-version="dark"] .jobs {
    border-color: #444444; }
  [data-theme-version="dark"] .apexcharts-gridlines-vertical line {
    stroke: #444444; }
  [data-theme-version="dark"] .apexcharts-text tspan {
    fill: #fff; }
  [data-theme-version="dark"] .widget-courses .schedule-icon {
    color: #fff; }
  [data-theme-version="dark"] .card-schedule .up-comming-schedule,
  [data-theme-version="dark"] .upcomming-task-widget {
    background: #2c2c2c; }
  [data-theme-version="dark"] .card-schedule .up-comming-schedule.style-1 {
    background: #1E1E1E; }
    [data-theme-version="dark"] .card-schedule .up-comming-schedule.style-1 .date-box {
      background: #2c2c2c;
      color: #fff; }
  [data-theme-version="dark"] .instructors-box .instructors-media .custome-badge .badge {
    color: #fff; }
  [data-theme-version="dark"] .instructors-box .instructors-media .info-box {
    background: #1E1E1E; }
  [data-theme-version="dark"] .course-details-tab .nav-tabs .nav-link {
    color: #cbc3c3; }
  [data-theme-version="dark"] .message-box,
  [data-theme-version="dark"] .chat-icon,
  [data-theme-version="dark"] .chat-people,
  [data-theme-version="dark"] .chat-tabs .chat-bx,
  [data-theme-version="dark"] .share-files,
  [data-theme-version="dark"] .type-massage,
  [data-theme-version="dark"] .work-ic {
    border-color: #444444; }
  [data-theme-version="dark"] .timeline-active .modulel {
    background: #2c2c2c; }
  [data-theme-version="dark"] .timeline-active li .time {
    background: #2c2c2c; }
  [data-theme-version="dark"] .achievements .achievements-content span,
  [data-theme-version="dark"] .bio .bio-content {
    background: #1E1E1E; }
  [data-theme-version="dark"] .score-active.style-1 .dashboard-select {
    background: #1E1E1E; }
  [data-theme-version="dark"] #activity line {
    stroke: #444444; }
  [data-theme-version="dark"] .pagination-down ul li a {
    background: #1E1E1E; }
    [data-theme-version="dark"] .pagination-down ul li a.active {
      background: var(--primary); }
  [data-theme-version="dark"] .custome-accordion .accordion-button {
    background: #2c2c2c; }
  [data-theme-version="dark"] .custome-accordion .acc-courses {
    background: #1E1E1E; }
  [data-theme-version="dark"] .widget-timeline .side-border h4, [data-theme-version="dark"] .widget-timeline .side-border .h4, [data-theme-version="dark"] .widget-timeline .side-border .h4,
  [data-theme-version="dark"] .widget-timeline .side-border .custom-dropdown {
    background: #2c2c2c; }
  [data-theme-version="dark"] .widget-timeline .side-border:after {
    border-color: #444444; }
  [data-theme-version="dark"] #columnChart line {
    stroke: transparent; }
  [data-theme-version="dark"] #sellingActivity svg path {
    stroke: #2c2c2c; }
  [data-theme-version="dark"] #sellingActivity line {
    stroke: transparent; }
  [data-theme-version="dark"] .review-box {
    background: #2c2c2c; }
  [data-theme-version="dark"] #NewCustomers line {
    stroke: transparent; }
  [data-theme-version="dark"] .students-list .search-area .form-control,
  [data-theme-version="dark"] .students-list .search-area .input-group-text {
    background: #1E1E1E;
    border: #444444; }
  [data-theme-version="dark"] .custome-accordion.style-1 .acc-courses {
    background: #2c2c2c;
    border-color: #444444; }
  [data-theme-version="dark"] .miac,
  [data-theme-version="dark"] .profile-img img, [data-theme-version="dark"] .profile-img span,
  [data-theme-version="dark"] .compose-content .dropzone,
  [data-theme-version="dark"] .fc-theme-standard td, [data-theme-version="dark"] .fc-theme-standard th,
  [data-theme-version="dark"] .fc-theme-standard .fc-scrollgrid.fc-scrollgrid-liquid, [data-theme-version="dark"] .fc-scrollgrid, [data-theme-version="dark"] table {
    border-color: #444444; }
  [data-theme-version="dark"] .btn-icon-end {
    border-color: #444444; }
  [data-theme-version="dark"] .raiting li {
    border-color: #444444; }
  [data-theme-version="dark"] .miac svg path {
    fill: #fff; }
  [data-theme-version="dark"] .type-massage.style-1 {
    background: #1E1E1E; }
    [data-theme-version="dark"] .type-massage.style-1 .input-group {
      background: #1E1E1E; }
  [data-theme-version="dark"] .header .header-content:after {
    background: #444444; }
  [data-theme-version="dark"] .search-coundry .dashboard-select {
    color: #fff;
    background: transparent; }
    [data-theme-version="dark"] .search-coundry .dashboard-select .option {
      color: #fff; }
    [data-theme-version="dark"] .search-coundry .dashboard-select:after {
      border-bottom: 0.125rem solid #fff;
      border-right: 0.125rem solid #fff; }
  [data-theme-version="dark"] .sidebar-right .nice-select .option.selected {
    color: #000; }
  [data-theme-version="dark"] #redial .apexcharts-datalabels-group text {
    fill: #fff; }
  [data-theme-version="dark"] .notification_dropdown .dropdown-menu-end .all-notification {
    border-color: #444444; }
  [data-theme-version="dark"] .app-fullcalendar1 .fc .fc-button-group > .fc-button {
    border-color: #444444; }
  [data-theme-version="dark"] .app-fullcalendar1 .fc-direction-ltr .fc-daygrid-event.fc-event-end, [data-theme-version="dark"] .app-fullcalendar1 .fc-direction-rtl .fc-daygrid-event.fc-event-start {
    background: #2c2c2c !important;
    border-color: #444444; }
  [data-theme-version="dark"] .card-calendar.style-1 .bootstrap-datetimepicker-widget .table-condensed td.day {
    color: #ffffff !important; }
  [data-theme-version="dark"] .dz-side-menu {
    background: #2c2c2c; }
  [data-theme-version="dark"] .nice-select .option.selected {
    color: #fff; }
  [data-theme-version="dark"] .dz-cource,
  [data-theme-version="dark"] .message-box,
  [data-theme-version="dark"] .type-massage {
    background-color: #2c2c2c; }
    [data-theme-version="dark"] .dz-cource .form-control,
    [data-theme-version="dark"] .message-box .form-control,
    [data-theme-version="dark"] .type-massage .form-control {
      background-color: transparent; }
  [data-theme-version="dark"] .card-calendar.style-1 .bootstrap-datetimepicker-widget table .picker-switch {
    color: #fff; }
  [data-theme-version="dark"] .fc .fc-button-group > .fc-button {
    border-color: #444444; }
  [data-theme-version="dark"] .fc-daygrid-dot-event {
    background-color: #1E1E1E;
    border-color: #1E1E1E; }
  [data-theme-version="dark"] #redial .apexcharts-radialbar-hollow {
    fill: #2c2c2c; }
  [data-theme-version="dark"] .chat-tabs .chat-bx.active:after {
    background-color: #1E1E1E; }
  [data-theme-version="dark"] .bootstrap-datetimepicker-widget table td.day:hover, [data-theme-version="dark"] .bootstrap-datetimepicker-widget table td.hour:hover, [data-theme-version="dark"] .bootstrap-datetimepicker-widget table td.minute:hover, [data-theme-version="dark"] .bootstrap-datetimepicker-widget table td.second:hover {
    background: #1E1E1E; }
  [data-theme-version="dark"] .chat-box-area .message-received p,
  [data-theme-version="dark"] .message-send,
  [data-theme-version="dark"] .type-massage .form-control {
    color: #fff;
    background-color: #171717; }
  [data-theme-version="dark"] .chat-icon ul li a,
  [data-theme-version="dark"] .file {
    background-color: rgba(255, 255, 255, 0.1); }
    [data-theme-version="dark"] .chat-icon ul li a svg path,
    [data-theme-version="dark"] .file svg path {
      fill: #fff; }
  [data-theme-version="dark"] .course-dedails-bx .description .user-pic span,
  [data-theme-version="dark"] .course-learn ul li {
    color: #fff; }
  [data-theme-version="dark"] .course-details-tab .user-pic2 p {
    color: #828690; }
  [data-theme-version="dark"] #columnChart .apexcharts-bar-series path {
    stroke: #2c2c2c; }
  [data-theme-version="dark"] .custome-donut tspan {
    fill: #fff; }
  [data-theme-version="dark"] #morris_donught.custome-donut svg path {
    stroke-width: 0px; }
  [data-theme-version="dark"] .card.bg-pink {
    background-color: #EB62D0; }
  [data-theme-version="dark"] #coin-ponent > div {
    background-color: #1E1E1E !important;
    color: #fff !important;
    border-color: #444444 !important; }
    [data-theme-version="dark"] #coin-ponent > div div {
      border-color: #444444 !important;
      background-color: #1E1E1E; }
  [data-theme-version="dark"] #coin-ponent div {
    border-color: #444444 !important;
    background-color: #1E1E1E; }
    [data-theme-version="dark"] #coin-ponent div div {
      border-color: #444444 !important;
      background-color: #1E1E1E; }
  [data-theme-version="dark"] #coin-ponent .cp-select-menu-item {
    border-color: #444444 !important;
    background-color: #1E1E1E; }
    [data-theme-version="dark"] #coin-ponent .cp-select-menu-item:hover {
      border-color: #444444 !important;
      background-color: #1E1E1E; }
  [data-theme-version="dark"] #coin-chart text,
  [data-theme-version="dark"] .revenueMap text {
    fill: white; }
  [data-theme-version="dark"] #coin-chart line,
  [data-theme-version="dark"] .revenueMap line {
    stroke: #444444; }
  [data-theme-version="dark"] .previews-info-list:hover {
    background-color: #1E1E1E; }
  [data-theme-version="dark"] .sell-element .sell-blance .form-control,
  [data-theme-version="dark"] .limit-sell .nav-tabs {
    background-color: #1E1E1E; }
  [data-theme-version="dark"] .text-start span {
    color: #fff !important; }
  [data-theme-version="dark"] .text-secondary {
    color: #fff !important; }
  [data-theme-version="dark"] .card-wiget .reward-earn span {
    color: #fff; }
  [data-theme-version="dark"] .market_chart line,
  [data-theme-version="dark"] #btcStock line {
    stroke: #444444; }
  [data-theme-version="dark"] .apexcharts-datalabels-group {
    stroke: #fff; }
  [data-theme-version="dark"] .btn.tp-btn svg path {
    stroke: #fff; }
  [data-theme-version="dark"] .coin-warpper .nav .nav-item {
    border-color: #444444; }
  [data-theme-version="dark"] .form-wrapper.trade-form .input-group .input-group-text {
    color: #fff; }
  [data-theme-version="dark"] .progress-bar.bg-secondary {
    background-color: #fff !important; }
  [data-theme-version="dark"] .profile-card {
    border-color: #444444; }
  [data-theme-version="dark"] .profile-card .card-footer,
  [data-theme-version="dark"] .author-profile .info-list li {
    border-color: #444444; }
  [data-theme-version="dark"] .email-left-box {
    border-color: #444444; }
  [data-theme-version="dark"] .email-left-box .mail-list .list-group-item i {
    color: #fff; }
  [data-theme-version="dark"] .dropzone .dlab-message {
    margin: 5em 0; }
  [data-theme-version="dark"] .email-list {
    background: #2c2c2c; }
  [data-theme-version="dark"] .email-list .message {
    border-color: #444444; }
  [data-theme-version="dark"] .email-list .message .col-mail-2 .subject {
    color: #fff; }
  [data-theme-version="dark"] .email-list .message:hover {
    background: #1E1E1E; }
  [data-theme-version="dark"] .email-right-box .table-pagenation {
    background: #1E1E1E;
    border-color: #444444; }
  [data-theme-version="dark"] .email-right-box .pagination {
    background: #1E1E1E; }
  [data-theme-version="dark"] .btn.tp-btn-light.btn-secondary,
  [data-theme-version="dark"] .btn.tp-btn.btn-secondary {
    color: white; }
  [data-theme-version="dark"] .list-group-item-secondary {
    color: #fff; }
  [data-theme-version="dark"] .students span {
    color: #fff; }
  [data-theme-version="dark"] .progress .bg-white {
    background-color: #fff !important; }
  [data-theme-version="dark"] .border-end {
    border-color: #444444 !important; }
  [data-theme-version="dark"] .form-check-input {
    border-color: #444444;
    background-color: #1E1E1E; }
  [data-theme-version="dark"] .menu-toggle .logo-color {
    display: none !important; }
  @media only screen and (max-width: 47.9375rem) {
    [data-theme-version="dark"] .menu-toggle .logo-color {
      display: block !important; } }
  [data-theme-version="dark"] #overiewChart line {
    stroke: #444444; }
  [data-theme-version="dark"] #NewCustomers #SvgjsSvg1006 {
    margin-top: 11px; }
  [data-theme-version="dark"] .chart-grd:after {
    background: transparent; }
  [data-theme-version="dark"] .chat-box-area .message-sent p {
    background: #383838;
    color: white; }
  [data-theme-version="dark"] .page-titles,
  [data-theme-version="dark"] .to-dodroup .sub-card,
  [data-theme-version="dark"] .ttl-project,
  [data-theme-version="dark"] .active-country .country-list,
  [data-theme-version="dark"] .active-projects.selling-product tbody tr td,
  [data-theme-version="dark"] .events .event-media,
  [data-theme-version="dark"] .active-projects.style-1 tbody tr td,
  [data-theme-version="dark"] .footer,
  [data-theme-version="dark"] .active-projects.manage-client tbody tr td,
  [data-theme-version="dark"] .task .task-summary,
  [data-theme-version="dark"] .active-projects.task-table tbody td,
  [data-theme-version="dark"] .kanbanPreview-bx .card-body .sub-card {
    border-color: #444444; }
  [data-theme-version="dark"] .avatar-list.avatar-list-stacked .avatar,
  [data-theme-version="dark"] .author-profile .card-footer .form-control,
  [data-theme-version="dark"] .profile-card .card-header,
  [data-theme-version="dark"] .author-profile .upload-link {
    border-color: #444444; }
  [data-theme-version="dark"] .email-left-box .mail-list .list-group-item {
    background: rgba(255, 255, 255, 0.1);
    margin: 13px 0px; }
  [data-theme-version="dark"] .inovice-logo .logo-abbr .react-w {
    fill: #fff;
    stroke: #fff; }
  [data-theme-version="dark"] .demo-right-inner {
    background-color: #2c2c2c; }
  [data-theme-version="dark"] .border-start {
    body-color: #444444; }
  [data-theme-version="dark"] .ttl-project .pr-data:after,
  [data-theme-version="dark"] .active-country:after {
    background: #444444; }
  [data-theme-version="dark"] .to-dodroup .sub-card .d-items,
  [data-theme-version="dark"] .kanbanPreview-bx .card-body .sub-card {
    background-color: #2c2c2c; }
  [data-theme-version="dark"] .to-dodroup .sub-card .d-items .d-items-2 label,
  [data-theme-version="dark"] .earning-tab .nav-item .nav-link.active {
    color: #fff; }
  [data-theme-version="dark"] .active-projects thead tr th,
  [data-theme-version="dark"] .schedules-cal .datepicker-days .dow {
    background-color: transparent; }
  [data-theme-version="dark"] .bootstrap-select .btn {
    background: #2c2c2c !important; }
  [data-theme-version="dark"] .sidebar-right-inner .bootstrap-select .btn {
    background: transparent !important;
    border-color: #E6E6E6 !important; }
  [data-theme-version="dark"] .sidebar-right-inner .dropdown-menu.show {
    background-color: #fff; }
  [data-theme-version="dark"] .sidebar-right-inner .dropdown-menu .dropdown-item.active, [data-theme-version="dark"] .sidebar-right-inner .dropdown-menu .dropdown-item:active {
    color: var(--primary) !important;
    background: var(--rgba-primary-1); }
  [data-theme-version="dark"] .sidebar-right-inner .dropdown-menu .dropdown-item {
    color: #777777 !important; }
  [data-theme-version="dark"] .sidebar-right-inner .dropdown-item:hover, [data-theme-version="dark"] .sidebar-right-inner .dropdown-item:focus {
    background-color: var(--bs-dropdown-link-hover-bg); }
  [data-theme-version="dark"] .badge-outline-dark,
  [data-theme-version="dark"] .badge-outline-secondary {
    border-color: #444444; }
  [data-theme-version="dark"] .light.badge-info {
    border-color: #444444; }
  [data-theme-version="dark"] .email-left-box .btn.text-white.btn-block {
    background-color: var(--primary); }
  [data-theme-version="dark"] .btn-secondary .text-secondary {
    color: #000 !important; }
  [data-theme-version="dark"] .list-group-item {
    color: #fff; }
  [data-theme-version="dark"] #TabWithIcon .nav-link.active {
    background-color: #1E1E1E;
    border-color: #444444;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }
  [data-theme-version="dark"] .ck.ck-editor .ck.ck-button {
    border-color: #444444;
    color: #fff;
    background-color: #1E1E1E; }
  [data-theme-version="dark"] .dark-logo {
    display: block; }
  [data-theme-version="dark"] .light-logo {
    display: none; }
  [data-theme-version="dark"] .deznav,
  [data-theme-version="dark"] .read-content-attachment .attachment > div:not(:last-child) {
    border-color: #444444; }
  [data-theme-version="dark"] #AllProject .apexcharts-series path {
    stroke: #444444; }
  [data-theme-version="dark"] .offcanvas {
    background-color: #1E1E1E; }
    [data-theme-version="dark"] .offcanvas .btn-close {
      background: transparent; }
  [data-theme-version="dark"] .events h6, [data-theme-version="dark"] .events .h6 {
    border-color: #444444;
    border: 0; }
  [data-theme-version="dark"] .task-tab {
    background-color: #2c2c2c; }
  [data-theme-version="dark"] .kanbanPreview-bx .card-body {
    background-color: #1E1E1E; }
  [data-theme-version="dark"] .custom-tab-1 .nav.nav-tabs {
    border-color: #444444; }
  [data-theme-version="dark"] .login-form {
    background-color: transparent; }
  [data-theme-version="dark"] .accordion-primary .accordion-header.collapsed {
    color: var(--primary) !important; }
  [data-theme-version="dark"] .accordion-item {
    background-color: #2c2c2c; }
  [data-theme-version="dark"] .mix-chart-tab .nav-link {
    background: #444444;
    color: #fff; }
    [data-theme-version="dark"] .mix-chart-tab .nav-link.active {
      background: var(--primary); }
  [data-theme-version="dark"] .active-map-main:after {
    background: #444444; }
  [data-theme-version="dark"] .upload-img .dropzone {
    border-color: #444444; }
  [data-theme-version="dark"] .upload-img .dropzone .dlab-message {
    margin: 1em 0; }
  [data-theme-version="dark"] .navbar-nav .active .scroll {
    color: var(--primary) !important; }
  [data-theme-version="dark"] .accordion-primary .accordion-header-text,
  [data-theme-version="dark"] .accordion-danger-solid .accordion-header-text {
    color: #fff; }
  [data-theme-version="dark"] .header-left .search-area {
    background-color: transparent; }
  [data-theme-version="dark"] [data-headerbg="color_4"] .search-area {
    background-color: transparent; }
  [data-theme-version="dark"] .third-post.style-2,
  [data-theme-version="dark"] .c-details ul li {
    border-color: #444444; }
  [data-theme-version="dark"] .dt-filter .dataTables_filter input[type="search"] {
    background: #1E1E1E; }
  [data-theme-version="dark"] .dt-filter .dataTables_filter label {
    background: #1E1E1E; }
  [data-theme-version="dark"] .card-profile .admin-user {
    background-color: rgba(0, 0, 0, 0.9); }
    [data-theme-version="dark"] .card-profile .admin-user .img-wrraper a {
      background-color: black;
      box-shadow: 0 0 6px 3px rgba(255, 255, 255, 0.1); }
  [data-theme-version="dark"] .card-profile .follow-list .follow-num {
    color: black; }
  [data-theme-version="dark"] .card-profile .user-social-links a {
    color: black !important; }
  [data-theme-version="dark"] .profile-accordion .accordion-item .accordion-header .accordion-button {
    color: #fff; }
  [data-theme-version="dark"] .c-list .search-area .form-control {
    border: 0; }
  [data-theme-version="dark"] .chat-left-area.active {
    background: #2c2c2c; }
  [data-theme-version="dark"] .inner-body {
    background-color: #202020;
    border-color: #444444; }
  [data-theme-version="dark"] .header .header-content {
    background-color: #2c2c2c;
    border-color: #444444; }
  [data-theme-version="dark"] .project-media p,
  [data-theme-version="dark"] .project-media span,
  [data-theme-version="dark"] .revenue-date span,
  [data-theme-version="dark"] .progress-box p,
  [data-theme-version="dark"] .avatar-list.avatar-list-stacked .avatar,
  [data-theme-version="dark"] .schedules-cal .datepicker-days .dow,
  [data-theme-version="dark"] .schedules-cal .datepicker-days .day {
    color: #fff; }
  [data-theme-version="dark"] .active-projects table.dataTable tbody td {
    border-color: #444444; }
  [data-theme-version="dark"] .header-right .notification_dropdown .nav-link svg path, [data-theme-version="dark"] .header-right .notification_dropdown .nav-link svg circle {
    stroke: #fff; }
  [data-theme-version="dark"] .header-right .notification_dropdown .nav-link:hover {
    background-color: #000; }
  [data-theme-version="dark"] .schedules-cal .datepicker-days .picker-switch {
    color: #fff; }
  [data-theme-version="dark"] .schedules-cal .datepicker-days .prev i, [data-theme-version="dark"] .schedules-cal .datepicker-days .next i {
    background: var(--primary-light); }
  [data-theme-version="dark"] .impressionbox:before {
    background: #444444; }
  [data-theme-version="dark"] .events .event-media span,
  [data-theme-version="dark"] .friend-list1 .friend-user p {
    color: #fff; }
  [data-theme-version="dark"] .friend-list1 .friend-user:hover {
    background: #000; }
  [data-theme-version="dark"] .apexcharts-legend-text {
    color: #fff !important; }
  [data-theme-version="dark"] #Statistics line {
    stroke: #444444; }
  [data-theme-version="dark"] #volumeChart .apexcharts-series path,
  [data-theme-version="dark"] #ExtraData .apexcharts-series path,
  [data-theme-version="dark"] #TotalEarning .apexcharts-series path {
    stroke: transparent; }
  [data-theme-version="dark"] .icon-box.bg-white svg path {
    fill: #fff !important; }
  [data-theme-version="dark"] .lang-chart li {
    color: #fff; }
  [data-theme-version="dark"] .analytics-card {
    background-color: #2c2c2c !important; }
  [data-theme-version="dark"] .activity-sale .recent-activity:after {
    background: #444444; }
  [data-theme-version="dark"] .blog-post .post-1 {
    border-color: #444444; }
  [data-theme-version="dark"] .chat-p.shaprate,
  [data-theme-version="dark"] .chat-border {
    border-color: #444444; }
  [data-theme-version="dark"] .second-head {
    color: #fff; }
  [data-theme-version="dark"] .btn-primary.sharp svg circle {
    fill: black; }
  [data-theme-version="dark"] .demo-right-inner {
    border-color: #444444; }
  [data-theme-version="dark"] .btn-close {
    background: none; }
  [data-theme-version="dark"] .navbar-nav .active .scroll {
    color: #ffffff !important; }
  [data-theme-version="dark"] .crm-cart-data h6, [data-theme-version="dark"] .crm-cart-data .h6 {
    color: black !important; }
  [data-theme-version="dark"] .crm-cart-data .text-black {
    color: black !important; }
  [data-theme-version="dark"] .crm-cart .badge.text-black {
    color: #fff !important; }
  [data-theme-version="dark"] .c-line:before {
    background: #444444; }
  [data-theme-version="dark"] .sales-bx h4, [data-theme-version="dark"] .sales-bx .h4 {
    color: black !important; }
  [data-theme-version="dark"] .badge i {
    color: black !important; }
  [data-theme-version="dark"] .c-list .search-area .form-control {
    background: black; }
  [data-theme-version="dark"] .c-list .search-area .input-group-text {
    background: black; }
    [data-theme-version="dark"] .c-list .search-area .input-group-text svg circle,
    [data-theme-version="dark"] .c-list .search-area .input-group-text svg path {
      stroke: #fff; }
  [data-theme-version="dark"] [data-sidebarbg="color_1"] .menu-icon svg path {
    stroke: #fff; }
  [data-theme-version="dark"] .heading-data td svg path {
    fill: #fff; }
  [data-theme-version="dark"] .notification_dropdown .dropdown-menu-end .all-notification {
    background: #2c2c2c; }
  [data-theme-version="dark"] .accordion {
    --bs-accordion-bg:$dark-card; }
  [data-theme-version="dark"] .depostit-card .depostit-card-media h3, [data-theme-version="dark"] .depostit-card .depostit-card-media .h3, [data-theme-version="dark"] .depostit-card .depostit-card-media .h3 {
    color: #222B40 !important; }
  [data-theme-version="dark"] .revenue-date h4, [data-theme-version="dark"] .revenue-date .h4, [data-theme-version="dark"] .revenue-date .h4 {
    color: #222B40 !important; }
  [data-theme-version="dark"] .star-rating ul li svg path {
    stroke: white; }
  [data-theme-version="dark"] .progress-box .progress .progress-bar {
    border-color: #444444; }
  [data-theme-version="dark"] .bg-warning-light .add-visit h6, [data-theme-version="dark"] .bg-warning-light .add-visit .h6, [data-theme-version="dark"] .bg-warning-light .add-visit h3, [data-theme-version="dark"] .bg-warning-light .add-visit .h3,
  [data-theme-version="dark"] .bg-primary-light .add-visit h6,
  [data-theme-version="dark"] .bg-primary-light .add-visit .h6,
  [data-theme-version="dark"] .bg-primary-light .add-visit h3,
  [data-theme-version="dark"] .bg-primary-light .add-visit .h3,
  [data-theme-version="dark"] .expenses-card .add-visit h6,
  [data-theme-version="dark"] .expenses-card .add-visit .h6,
  [data-theme-version="dark"] .expenses-card .add-visit h3,
  [data-theme-version="dark"] .expenses-card .add-visit .h3 {
    color: black !important; }
  [data-theme-version="dark"] .analytics-card .ic-n-bx .icon-box i {
    color: black; }
  [data-theme-version="dark"] .post-title .text-black {
    color: #fff !important; }
  [data-theme-version="dark"] .profile-news .media {
    border-color: #444444; }
  [data-theme-version="dark"] .post-details h3, [data-theme-version="dark"] .post-details .h3 {
    color: #fff !important; }
  [data-theme-version="dark"] .compose-wrapper .btn span i {
    color: black; }
  [data-theme-version="dark"] .fc-h-event .fc-event-title {
    color: black; }
  [data-theme-version="dark"] .light.btn-primary span svg circle {
    fill: #000 !important; }
  [data-theme-version="dark"] .list-group-item.active h6, [data-theme-version="dark"] .list-group-item.active .h6 {
    color: black !important; }
  [data-theme-version="dark"] .list-group .active h5, [data-theme-version="dark"] .list-group .active .h5 {
    color: black; }
  [data-theme-version="dark"] .pagination.page-indicator-light .page-item.active .page-link {
    color: black !important; }
  [data-theme-version="dark"] .to-dodroup .sub-card .d-items .form-check-input:checked {
    border-color: black;
    background-color: var(--primary); }
  [data-theme-version="dark"] .widget-stat .media .media-body h3, [data-theme-version="dark"] .widget-stat .media .media-body .h3, [data-theme-version="dark"] .widget-stat .media .media-body .h3 {
    color: black; }
  [data-theme-version="dark"] .card.bg-primary-light .list-group-item, [data-theme-version="dark"] .card.bg-warning-light .list-group-item {
    color: #000; }
  [data-theme-version="dark"] .footer .copyright a {
    color: var(--primary) !important; }
  [data-theme-version="dark"] .schedules-cal .datepicker-days .day {
    color: white !important; }
    [data-theme-version="dark"] .schedules-cal .datepicker-days .day.active, [data-theme-version="dark"] .schedules-cal .datepicker-days .day.today {
      color: #111828 !important; }
  [data-theme-version="dark"] .project-media svg rect {
    fill: #1E1E1E; }
  [data-theme-version="dark"] .revenue-date h6, [data-theme-version="dark"] .revenue-date .h6 {
    color: black; }
  [data-theme-version="dark"] .progress-box p {
    color: black !important; }
  [data-theme-version="dark"] .login-form .text-dark {
    color: white !important; }
  [data-theme-version="dark"] .login-form .login-title:before, [data-theme-version="dark"] .login-form .login-title:after {
    background-color: #444444; }
  [data-theme-version="dark"][data-sidebarbg="color_1"][data-sidebar-style="mini"][data-sidebar-style="full"] .metismenu li > ul {
    background-color: #1E1E1E !important; }
  [data-theme-version="dark"][data-sidebarbg="color_1"][data-sidebar-style="mini"][data-sidebar-style="full"] .deznav .metismenu > li:hover > a .menu-icon {
    background-color: transparent !important; }
    [data-theme-version="dark"][data-sidebarbg="color_1"][data-sidebar-style="mini"][data-sidebar-style="full"] .deznav .metismenu > li:hover > a .menu-icon svg path {
      stroke: var(--primary); }
  [data-theme-version="dark"][data-sidebarbg="color_1"][data-sidebar-style="mini"][data-sidebar-style="full"] .deznav .metismenu > li.mm-active > a .menu-icon svg path {
    stroke: var(--primary); }
  [data-theme-version="dark"][data-sidebarbg="color_1"][data-sidebar-style="icon-hover"] .deznav .metismenu > li:hover > a .menu-icon {
    background-color: transparent; }
    [data-theme-version="dark"][data-sidebarbg="color_1"][data-sidebar-style="icon-hover"] .deznav .metismenu > li:hover > a .menu-icon svg path {
      stroke: var(--primary); }
  [data-theme-version="dark"][data-sidebarbg="color_1"][data-sidebar-style="icon-hover"] .deznav .metismenu > li.mm-active > a .menu-icon svg path {
    stroke: var(--primary); }
  [data-theme-version="dark"][data-sidebarbg="color_1"][data-sidebar-style="modern"] .deznav .metismenu > li:hover > a .menu-icon,
  [data-theme-version="dark"][data-sidebarbg="color_1"][data-sidebar-style="modern"] .deznav .metismenu > li.mm-active > a .menu-icon {
    background-color: transparent; }
    [data-theme-version="dark"][data-sidebarbg="color_1"][data-sidebar-style="modern"] .deznav .metismenu > li:hover > a .menu-icon svg path,
    [data-theme-version="dark"][data-sidebarbg="color_1"][data-sidebar-style="modern"] .deznav .metismenu > li.mm-active > a .menu-icon svg path {
      stroke: var(--primary); }
  [data-theme-version="dark"][data-sidebarbg="color_1"][data-sidebar-style="modern"] .deznav .metismenu > li:before {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #ffffff 53.12%, rgba(255, 255, 255, 0) 100%); }
  [data-theme-version="dark"][data-sidebarbg="color_1"][data-layout="horizontal"] .deznav {
    border-top: 1px solid #444444; }
  [data-theme-version="dark"][data-sidebarbg="color_1"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu > li:hover > a .menu-icon {
    background-color: var(--primary); }
    [data-theme-version="dark"][data-sidebarbg="color_1"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu > li:hover > a .menu-icon svg path {
      stroke: black; }
  [data-theme-version="dark"][data-sidebarbg="color_1"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon svg path {
    stroke: black; }
  [data-theme-version="dark"][data-sidebarbg="color_1"] .menu-icon svg path {
    stroke: white; }
  [data-theme-version="dark"][data-sidebarbg="color_1"] .switch-btn {
    border-color: #444444; }
    [data-theme-version="dark"][data-sidebarbg="color_1"] .switch-btn svg path {
      stroke: white; }
  [data-theme-version="dark"][data-sidebarbg="color_1"] .menu-toggle .deznav .metismenu li > ul {
    background-color: #1E1E1E !important; }
  [data-theme-version="dark"][data-sidebarbg="color_1"][data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu > li > a .menu-icon {
    background-color: rgba(255, 255, 255, 0.2); }
  [data-theme-version="dark"][data-sidebarbg="color_1"][data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon {
    background-color: var(--primary-light); }
    [data-theme-version="dark"][data-sidebarbg="color_1"][data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a .menu-icon svg path {
      stroke: black; }
  [data-theme-version="dark"][data-sidebarbg="color_1"][data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu li:hover a .menu-icon {
    background-color: var(--primary-light); }
    [data-theme-version="dark"][data-sidebarbg="color_1"][data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu li:hover a .menu-icon svg path {
      stroke: black; }
  [data-theme-version="dark"][data-sidebarbg="color_1"][data-sidebar-style="modern"][data-layout="horizontal"] .deznav .metismenu > li:before {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 53.12%, rgba(255, 255, 255, 0) 100%); }
  [data-theme-version="dark"][data-primary="color_13"] .text-primary {
    color: white !important; }
  [data-theme-version="dark"][data-primary="color_13"] .footer .copyright a {
    color: #fff !important; }
  [data-theme-version="dark"][data-primary="color_13"] .active-projects div.dt-buttons .dt-button {
    color: #fff; }
  [data-theme-version="dark"][data-primary="color_13"] .ov-card:hover .icon-box {
    background-color: #fff !important; }
  [data-theme-version="dark"] .analytics-card p {
    color: #fff; }
  [data-theme-version="dark"] .schedules-cal .datepicker-days .day:hover {
    color: black !important; }
  [data-theme-version="dark"] .active-map .worldmap__figure-container, [data-theme-version="dark"] .sales-map .worldmap__figure-container {
    background-color: transparent !important; }
  [data-theme-version="dark"] .react-datepicker {
    background-color: transparent;
    color: #fff; }
  [data-theme-version="dark"] .dz-calender .react-datepicker__header {
    background-color: transparent;
    color: #fff; }
  [data-theme-version="dark"] #VisitorsChart line {
    stroke: #444444; }
  [data-theme-version="dark"] #VisitorsChart .apexcharts-text tspan {
    fill: #666666; }
  [data-theme-version="dark"] .custom-react-select > div:nth-child(4) > div {
    background-color: #2c2c2c; }
    [data-theme-version="dark"] .custom-react-select > div:nth-child(4) > div > div {
      background-color: #2c2c2c;
      -webkit-transition: all 0.5s;
      -ms-transition: all 0.5s;
      transition: all 0.5s; }
      [data-theme-version="dark"] .custom-react-select > div:nth-child(4) > div > div:hover {
        background-color: #2c2c2c;
        color: #fff; }
  [data-theme-version="dark"] .custom-react-select > div:nth-child(3) {
    background-color: #2c2c2c;
    border-color: #444444 !important; }
    [data-theme-version="dark"] .custom-react-select > div:nth-child(3):hover {
      border-color: var(--primary) !important; }
  [data-theme-version="dark"] .custom-react-select > div > div:first-child div {
    color: #fff; }
  [data-theme-version="dark"] .email-left-box .mail-list .list-group-item.active {
    color: #fff !important; }
  [data-theme-version="dark"] .accordion-primary .accordion-button {
    color: #000 !important;
    box-shadow: none; }
  [data-theme-version="dark"] .link-secondary {
    color: #fff !important; }
  [data-theme-version="dark"] .alert.alert-outline-light {
    color: #929696; }
  [data-theme-version="dark"] .badge-light {
    color: var(--text-dark) !important; }
  [data-theme-version="dark"] .css-b62m3t-container > div {
    border-color: rgba(255, 255, 255, 0.3);
    background: #1E1E1E; }
  [data-theme-version="dark"] .css-b62m3t-container [class*="-singleValue"],
  [data-theme-version="dark"] .css-b62m3t-container [class*="-placeholder"] {
    color: #fff; }
  [data-theme-version="dark"] .css-b62m3t-container [class*="-multiValue"] {
    background: #1E1E1E; }
    [data-theme-version="dark"] .css-b62m3t-container [class*="-multiValue"] div {
      color: #fff; }
  [data-theme-version="dark"] .css-b62m3t-container [class*="-IndicatorsContainer"] div {
    color: #fff; }
  [data-theme-version="dark"] .css-b62m3t-container [class*="-menu"] {
    background: #1E1E1E; }
    [data-theme-version="dark"] .css-b62m3t-container [class*="-menu"] > div > *:hover {
      background: var(--primary-light); }
  [data-theme-version="dark"] .sidebar-right .tab-content {
    background: #1E1E1E; }
    [data-theme-version="dark"] .sidebar-right .tab-content .tab-pane .admin-settings p {
      color: #fff; }
  [data-theme-version="dark"] .sidebar-right .sidebar-right-inner h4, [data-theme-version="dark"] .sidebar-right .sidebar-right-inner .h4 {
    background-color: #1E1E1E;
    color: #fff !important; }
  [data-theme-version="dark"] .sidebar-right .sidebar-right-inner .admin-title {
    color: #fff !important; }
  [data-theme-version="dark"] .sidebar-right .card-tabs .nav-tabs {
    background: #1E1E1E; }
    [data-theme-version="dark"] .sidebar-right .card-tabs .nav-tabs .nav-item .nav-link {
      background: rgba(160, 207, 26, 0.6);
      color: #fff; }
      [data-theme-version="dark"] .sidebar-right .card-tabs .nav-tabs .nav-item .nav-link.active {
        background: rgba(160, 207, 26, 0.9); }
  [data-theme-version="dark"] .filter-pagination {
    background: #1E1E1E; }
  [data-theme-version="dark"] .filter-pagination .previous-button {
    background-color: #2c2c2c; }
  [data-theme-version="dark"] .filter-pagination .next-button {
    background-color: #2c2c2c; }

[data-theme-version="dark"][data-layout="vertical"][data-sidebar-position="fixed"] .header {
  border-color: #444444; }

[data-theme-version="dark"] .header {
  border-color: #444444; }

[data-theme-version="dark"] .header-left .dashboard_bar {
  color: #fff; }

[data-theme-version="dark"] .search-area .form-control {
  background: #2c2c2c; }

[data-theme-version="dark"] .search-area .input-group-text {
  border: 0;
  background: #2c2c2c; }
  [data-theme-version="dark"] .search-area .input-group-text a {
    color: #4f7086; }

[data-theme-version="dark"] .header-right .notification_dropdown .nav-link {
  /* background: $d-bg!important; */ }
  [data-theme-version="dark"] .header-right .notification_dropdown .nav-link .badge {
    border-color: #2c2c2c; }

[data-theme-version="dark"] .header-right .dropdown .nav-link {
  color: #fff; }
  [data-theme-version="dark"] .header-right .dropdown .nav-link:hover {
    color: #fff; }

[data-theme-version="dark"] .nav-header .hamburger .line {
  background: #fff !important; }

[data-theme-version="dark"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #fff !important; }

[data-theme-version="dark"] .header-left .search-area .input-group-text {
  background-color: #000; }
  [data-theme-version="dark"] .header-left .search-area .input-group-text svg circle {
    stroke: #fff; }
  [data-theme-version="dark"] .header-left .search-area .input-group-text svg path {
    stroke: #fff; }

[data-theme-version="dark"] .header-left .search-area .form-control {
  background-color: #000; }

[data-theme-version="dark"] .nav-header {
  border-color: #444444; }

[data-theme-version="dark"] .nav-control svg rect {
  fill: #fff; }

[data-theme-version="dark"] .brand-logo {
  color: #fff; }
  [data-theme-version="dark"] .brand-logo:hover {
    color: #fff; }

[data-theme-version="dark"] .svg-title-path {
  fill: #fff; }

[data-theme-version="dark"] .nav-header {
  border-color: #444444; }

[data-theme-version="dark"] .nav-control svg rect {
  fill: #fff; }

[data-theme-version="dark"] .brand-logo {
  color: #fff; }
  [data-theme-version="dark"] .brand-logo:hover {
    color: #fff; }

[data-theme-version="dark"] .svg-title-path {
  fill: #fff; }

[data-theme-version="dark"] .fixed-content-box {
  background-color: #2c2c2c; }
  [data-theme-version="dark"] .fixed-content-box .head-name {
    background: #2c2c2c;
    color: #fff;
    border-color: #444444; }
  [data-theme-version="dark"] .fixed-content-box + .header + .deznav {
    background-color: #1E1E1E; }

[data-theme-version="dark"][data-layout="vertical"][data-sidebar-position="fixed"] .deznav {
  border-color: #444444; }

[data-theme-version="dark"][data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu > li.mm-active > a, [data-theme-version="dark"][data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu > li:hover > a {
  background: transparent; }
  [data-theme-version="dark"][data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu > li.mm-active > a i, [data-theme-version="dark"][data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu > li:hover > a i {
    color: var(--primary);
    background: var(--rgba-primary-1); }

[data-theme-version="dark"][data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu > li a > i {
  color: rgba(255, 255, 255, 0.3); }

[data-theme-version="dark"] .deznav .header-profile > a.nav-link {
  border-color: #444444; }
  [data-theme-version="dark"] .deznav .header-profile > a.nav-link .header-info span {
    color: #fff; }

[data-theme-version="dark"] .deznav .metismenu > li > a {
  color: #b3b3b3; }

[data-theme-version="dark"] .deznav .metismenu > li:hover > a, [data-theme-version="dark"] .deznav .metismenu > li:focus > a, [data-theme-version="dark"] .deznav .metismenu > li.mm-active > a {
  color: #fff !important;
  /* background:var(--rgba-primary-1); */ }
  [data-theme-version="dark"] .deznav .metismenu > li:hover > a i, [data-theme-version="dark"] .deznav .metismenu > li:focus > a i, [data-theme-version="dark"] .deznav .metismenu > li.mm-active > a i {
    color: #fff; }

[data-theme-version="dark"] .deznav .metismenu > li.mm-active ul ul {
  background-color: transparent; }

[data-theme-version="dark"] .deznav .metismenu ul:after {
  background-color: #444444; }

[data-theme-version="dark"] .deznav .metismenu ul a:hover, [data-theme-version="dark"] .deznav .metismenu ul a:focus, [data-theme-version="dark"] .deznav .metismenu ul a.mm-active {
  color: #fff !important; }

[data-theme-version="dark"] .deznav .metismenu ul a:before {
  background-color: #fff; }

[data-theme-version="dark"] .deznav .metismenu a {
  color: #b3b3b3 !important; }

[data-theme-version="dark"] .sidebar-right .card-tabs .nav-tabs {
  border-color: var(--rgba-primary-1) !important; }
  [data-theme-version="dark"] .sidebar-right .card-tabs .nav-tabs .nav-item .nav-link {
    color: #000 !important; }

[data-theme-version="dark"] .sidebar-right .form-control {
  background: #fff;
  color: #000;
  border-color: #cdcdcd; }

[data-theme-version="dark"] .sidebar-right .default-select .list {
  background: #fff; }
  [data-theme-version="dark"] .sidebar-right .default-select .list .option.selected, [data-theme-version="dark"] .sidebar-right .default-select .list .option.focus, [data-theme-version="dark"] .sidebar-right .default-select .list .option:hover {
    background: rgba(0, 0, 0, 0.05) !important; }

[data-theme-version="dark"] .sidebar-right .nice-select .option {
  background: #fff; }

[data-theme-version="dark"] .footer {
  background-color: #2c2c2c; }
  [data-theme-version="dark"] .footer .copyright {
    background-color: transparent; }
    [data-theme-version="dark"] .footer .copyright p {
      color: #fff; }

