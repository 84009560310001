.any-card{
	display:flex;
	justify-content:space-between;
	.harry-img{
		height:200px;
		transform: scale(1.1);
		position: absolute;
		right: 32px;
        bottom: 0;
		@include custommq($max:1600px){
			height:165px;
		}
		@include custommq($max:1326px){
			height: 140px;
		}
		@include respond ('phone'){
			height: 97px;
			right: 14px;
			bottom: 6px;
		}
	}
	.c-con{
		width:50%;
		p{
			margin-bottom: 44px;
		}
		@include custommq($max:1326px){
			p{
				margin-bottom: 12px;
			}
		}
		@include respond ('phone'){
			width: 64%;
		}
	}
}
.sales-bx{
    text-align: center;
    border-radius:$radius;
	background: rgba(255,255,255,0.4)!important;
	min-width: 140px;
	img{
		width:45px;
	}
	h4{
		margin-bottom: 0;
		margin-top: 6px;
		font-weight: 600;
		color:black;
	}
	span{
		color:black;	
	}
}
.analytics-card{
	.ic-n-bx{
		text-align:center;
		margin-top: -38px;
        margin-bottom: 20px;
		@include respond ('phone'){
			margin-top: -33px;
			margin-bottom: 6px;
		}
		.icon-box {
			@include transitionMedium;
			i{
				transform: scale(1.3);
				color:var(--secondary);
	
			}
			line-height: 2.575rem;
		}	
	}
	.ana-box{
		text-align:center;
		.anta-data{
			h3{
	 			margin-top: 24px;
				margin-bottom: 0;
				font-weight: 700;
			}
		}
	}
	@include respond ('tab-land'){
		.mt-4{
			margin-top:0!important;
		}
	}
	p{
		color:black;
	}
	
}
.ov-card{
	cursor:pointer;
	&:hover{
		background: var(--primary-light)!important;
		border-color: var(--primary-light);
		h5,span,h3{
			color:#000;
		}
		.icon-box{
			background-color:#000!important;
			i{
				color:var(--primary)!important;
			}
		}
	}
}
.country-sale{
	.country-flag{
		img{
			width:40px;
		}
	}
	li{
		margin-bottom:12px;
		padding:0 1.25rem;
	}
	height: 355px;
}
.recent-activity{
	.active-data{
		h5{
			font-size: 14px;
		}
		span{
			font-size: 12px;
		}
	}
}
.activity-sale{
	height: 350px;
	padding: 0 20px;
	.recent-activity{
		margin-bottom:12px;
		position:relative;
		align-items: center;
		&:after{
			position: absolute;
			content: "";
			top: 1.888rem;
			left: 0.3rem;
			width: 0.063rem;
			height: 100%;
			background: #e4e4e4;
			@include respond ('tab-port'){
				top: 2.788rem;
				left: 0.4rem;
			}
		}
		&:last-child{
			&:after{
				content:none;
			}
		}
		
	}
}
.chartBar1{
	margin-top: -31px;
    margin-left: -21px;
}
.sales-data{
	h4{
		font-size: 15px;
	}
	p{
		line-height:1.3;
	}
}
.earning-chart{
	margin-top: -31px;
	margin-left: 60px;
}
.social-cards{
	@include respond ('tab-land'){
		margin-bottom: 10px;
	}
}