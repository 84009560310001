.jqvmap-zoomin,
.jqvmap-zoomout {
    height: 1.25rem;
    width: 1.25rem;
    line-height: 0.875rem;
    background-color: var(--primary);
    color: $white;
}

.jqvmap-zoomout {
    top: 2.1875rem;
}

#world-map, #usa {
    height: 25rem;
	@include respond ('tab-port'){
		height:21.875rem;
	}
	@include respond ('phone'){
		height:14.375rem;
	}
}

.active-map-main .active-map, .sales-map svg path{
    fill: var(--primary-light);
    stroke: $white !important;
    stroke-opacity: 1!important;
}
