@media (min-width:47.9375rem) {
    [data-sidebar-style="modern"] {
		.deznav .metismenu > li > a .menu-icon{
			margin-right: 0;
			margin-bottom: 0px;
			background: transparent;
		}
		.deznav .metismenu ul li:before{
			display:none;
		}
        .nav-header {
            width: 10.625rem;
			.brand-logo{
				    justify-content: center;
			}
        }
        .deznav {
			//background:var(--sidebar-bg);
			.header-profile{
				margin-bottom: 0.3125rem;
				&>a.nav-link{
					display:block;
					text-align:center;
					margin: 0 -0.625rem 0.9375rem;
					padding: 0.9375rem 0.625rem;
					border-radius: $radius;
					img{
						margin-bottom:0.3125rem;
					}
					.header-info{
						margin-left:0!important;
						text-align:center;
						small{
							text-align:center!important;
						}
						display:none;
					}
				}
			}
            .metismenu {
				
                &>li {
                    text-align: center;
					
					
                    &>a {
                        padding: 1.25rem 0.9375rem 1.25rem 0.9375rem;
						margin: 0.125rem 0;
                        // font-weight: 500;
						@include transitionMedium;
						
						
                        &::after {
                            display: none;
                        }

                        &:hover, 
                        &:focus, 
                        &:active, 
                        &.mm-active {
                            &>a {
                                background-color: var(--primary-dark);
                            }
                        }
                    }
					&:hover,
					&.mm-active{
						padding:0;
						&>a{
							//background: var(--rgba-primary-1);
							color:black;
							//border-radius:$radius;
							box-shadow:none;
							
							i{
								color:$white;
							}
							.nav-text{
								//color:var(--primary);
							}
						}
						ul{
							li{
								a{
									padding: 0.5rem 1rem;
								}
							}
						}
					}
                    li {
                        text-align: left;

                        @at-root [direction="rtl"]#{&} {
                            text-align: right;
                        }
                    }
                }

                li {

                    a {
                        padding: 0.625rem 0.9375rem 0.625rem 0.9375rem;
						font-size: 1rem;
						.badge{
							display:none;
						}
                    }
					ul:after{
						content:none;
					}

                    &>ul {
                        height: auto !important;
                    }
                }
                .nav-label{
                    display: none;
                }
            }

            .nav-label {
                display: none;
            }
            

            .nav-text {
                display: block;
                margin-top: 0.3125rem;
            }
			.plus-box,
			.copyright,
			.help-desk{
				display:none;
			}
			.sidebar-info{
				display:none!important;	
			}
			.menu-title{
				display:none;
			}
        }
		.switch-btn{
			padding: 40px 74px;
			span{
				display:none;
			}
		}
        .footer {
            padding-left: 11.9rem;
        }
        .content-body {
            margin-left: 0rem;
        }
		.outer-body{
			padding-left: 10.65rem;
			@include respond ('laptop'){
				padding-left: 10.55rem;
			}
		}
		
		&[data-layout="horizontal"]{
			.deznav {
				.metismenu {
					 li {
						&>a i{
							padding:0;
							margin:0;
						}
					 }
				}	
			}
		}
    }
    [data-sidebar-style="modern"][data-layout="vertical"]{
        .deznav {
            width: 10.625rem;
            left: 0;
			z-index: 3;
			position: absolute!important;
			@at-root [direction="rtl"]#{&} {
				left: auto;
				right: 0;
			}  
			
            .slimScrollDiv, 
            .deznav-scroll {
                overflow: visible !important;
            }
        
            .metismenu {
				padding: 0.625rem 0;
                &>li {
					position:relative;
                    &>a{
						padding: 1.375rem 0.6em 0.9375rem;
                        i{
							padding: 0;
							height:auto;
							width:auto;
							margin:0;
							line-height:1;
							margin-bottom: 0.3125rem;
                        }
                    }

                    &>ul {
                        display: none;
                        padding: 1.875rem 0.9375rem;
                    }
					&:before{
						content:"";
						position:absolute;
						bottom						:0;
						right:0;
						background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #1A2439 53.12%, rgba(255, 255, 255, 0) 100%);
						width: 100%;
						height: 1px;
						opacity: 0.3;
					}
					&:last-child{
						&:before{
							display:none;
						}
					}
					
                }

                li {
                    position: relative;

                    a {
                        padding:1rem 1rem;
						&:before{
							content:none;
						}
						.badge{
							display:none;
						}
                    }

                    ul {
                        position: absolute;
                        left: 105%;
                        top: 0;
                        bottom: auto;
						background-color: white;
                        border: 0.0625rem solid $border;
                        width: 12.5rem;
						box-shadow: 1rem 0.75rem 1.25rem 0 rgba(82, 63, 105, 0.1);
						
						
						@at-root [data-theme-version="dark"]#{&} {
							background:$dark-card;
							box-shadow: 0rem 0rem 0.8125rem 0rem rgba(0, 0, 0, 0.1);
						}

                        @at-root [direction="rtl"]#{&} {
                            left: auto;
                            right: 105%;
                        }
						li{
							ul{
								border-radius:$radius!important;
							}
						}
                    }

                    &:hover {
                        &>ul {
							display: block;
							left: 100%;
							padding: 1rem 0;
							margin-left: 0;
							border: 0;
							box-shadow: 1rem 0.75rem 1.25rem 0 rgba(82, 63, 105, 0.1);
							border-radius:0 2rem 2rem 0;
							
							
							
							@at-root [data-theme-version="dark"]#{&} {
								box-shadow: 0.3125rem 0.3125rem 1.875rem 0rem rgba(20, 0, 30, 0.10);
							}
							
                            @at-root [direction="rtl"]#{&} {
                                left: auto;
                                right: 100%;
								box-shadow: -0.3125rem 0.3125rem 1.875rem 0rem rgba(20, 0, 30, 0.10);
                            }  
                        }
                    }
                }
            }
            

            .nav-label {
                display: none;
            }

            .nav-text {
                display: block;
                margin-top: 0;
            }
			.header-profile2{
				padding:0 2.5rem;	
			}
        }
		
		.nav-control{
			right: -5.35rem;
		}
		.header .header-content{
			padding-left: 6rem;
		}
		
        .menu-toggle {
            .deznav,.nav-header {
                left: -10.625rem;
            }
			.header{
				padding-left:0;
			}
            .outer-body {
                padding-left: 1px;
            }
        }
    }
		
    [data-sidebar-style="modern"][data-layout="horizontal"] {
		.nav-header {
			width: 18.563rem;
		}
		.header {
			padding-left: 18.563rem;
		}
		.footer,
		.content-body {
			margin-left:0;
			//padding-top: 5.5rem;
		}
		.calendar-warpper{
			top: 11.95rem;
			height: calc(100vh - 11.4rem);
		}
        .deznav {
            .metismenu {
                &>li {
					position:relative;
                    &>a {
                        padding: 0.7375rem 1.875rem;
						margin: 0 0.125rem;
						.menu-icon{
							margin-right: 0;
							background-color:transparent;
						}
                    }	
					&:before{
						content:"";
						position:absolute;
						top:0;
						right:0;
						background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #1A2439 53.12%, rgba(255, 255, 255, 0) 100%);
						width: 1px;
						height: 100%;
						opacity: 0.3;
					}

                    &>ul {
                      // top: 6.8rem;
                    }
                }
            }
        }
		
        &[data-container="boxed"] {
            .deznav {
                .metismenu {
    
                    &>li {
                        &>a {
                            padding: 0.8125rem 1.25rem;
                        }
                    }
                }
            }
        }
    }
}
[data-nav-headerbg="color_1"][data-headerbg="color_1"][data-sidebar-style="modern"][data-layout="horizontal"]{
	.nav-header{
		.color-title{
			display:block!important;
		}
	}
}
[data-sidebar-style="modern"][data-layout="horizontal"][data-sidebar-position="static"][data-header-position="static"]{
	.outer-body{
		padding-top: 10px;
		.content-body{
			    padding-top: 182px;
		}
	}
	.calendar-warpper{
		top: 192px;
	}
}
[data-sidebar-style="modern"][data-layout="horizontal"][data-sidebar-position="static"][data-header-position="fixed"]{
	.outer-body{
		padding-top: 10px;
		top: 0;
		.content-body{
			padding-top: 180px;
		}
	}
	.calendar-warpper{
		top: 11.9rem;
	}
	
}
[data-sidebar-style="modern"][data-header-position="static"][data-sidebar-position="fixed"][data-layout="horizontal"]{
	.outer-body{
		top: 0;
		padding-top: 10px;
		.content-body{
			padding-top: 11.2rem;
		}
	}
	.calendar-warpper{
		top: 11.2rem;
	}
}
